import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import { useProperties } from "../../../swr/properties";
import unavailableImage from "../../../assets/property_unavailable.png";
import PropertyCard from "../../PropertyCard";
import propertyData from "../../PropertyCard/defaultData";
import styles from "./related.module.scss";
import { Property } from "../../../types/Property";

const RelatedProperties: React.FC<any> = ({
  isMortgageProp,
}: {
  isMortgageProp: boolean;
}) => {
  const token = localStorage.getItem("token") || undefined;
  const { properties, isLoading, isError } = useProperties(token, 1, 1000000);
  const [properts, setProperts] = useState<Property[] | [] | undefined>([]);

  let rand1 = properts?.length
    ? Math.floor(Math.random() * properts?.length)
    : 0;
  let rand2 = properts?.length
    ? Math.floor(Math.random() * properts?.length)
    : 0;

  useEffect(() => {
    if (isMortgageProp === true) {
      const mortgageProperties = properties
        ? properties.filter((item) => item.status === "Mortgage")
        : [];
      setProperts(mortgageProperties);
    }else{
        setProperts(properties)
    }
  }, [isMortgageProp]);

  return (
    <div className="row">
      {(!properts || properts.length === 0) && !isLoading && (
        <div className="col-xl-12">
          <div className={styles.unavailable}>
            <img src={unavailableImage} alt="No properties available" />
          </div>
        </div>
      )}
      {isLoading &&
        [1, 2].map((item) => (
          <div className="col-xl-6 col-sm-6 col-12">
            <Skeleton variant="rectangular" width="100%">
              <PropertyCard property={propertyData} />
            </Skeleton>
          </div>
        ))}
      {}
      {properts && properts.length > 0 && (
        <>
          <div className="col-xl-6 col-sm-6 col-12">
            <PropertyCard property={properts[rand1]} />
          </div>
          <div className="col-xl-6 col-sm-6 col-12">
            <PropertyCard property={properts[rand2]} />
          </div>
        </>
      )}
    </div>
  );
};

export default RelatedProperties;
