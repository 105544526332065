import { NavMenuItem } from "../components/NavigationBar";

const otherServices: NavMenuItem[] = [
    { label: 'Utility Bill Payment', href: '/utility-bills' },
]

const servicesSubItems = [
    { label: 'Save For Rent', href: '/save-for-rent' },
    { label: 'UtilityBills', href: '/utility-bills' },
    { label: 'Mortgage', href: '/mortgage' },
    { label: 'Insurance', href: '/insurance' }
]

export const menuItems: NavMenuItem[] = [
    { label: ' About Us', href: '/about-us' },
    { label: 'Listings', href: '/properties' },
    // { label: 'Marketplace', href: '/market-place' },
     // { label: 'Marketplace', href: '#' },
    { label: 'Our Services', href: '', subItems: servicesSubItems },
    { label: 'Contact', href: '/contact' }, 
]

// export const menuItems: NavMenuItem[] = [
//     { label: ' About Us', href: '/about-us' },
//     { label: 'Listings', href: '/properties' },
//     // { label: 'Marketplace', href: '/market-place' },
//     { label: 'Save For Rent', href: '/save-for-rent' },
//     // { label: 'Marketplace', href: '#' },
//     { label: 'UtilityBills', href: '/utility-bills' },
//     { label: 'Mortgage', href: '/mortgage' },
//     // { label: 'Mortgage', href: '/mortgage' },
//     { label: 'Contact', href: '/contact' },
// ]

export const menuItemsII: NavMenuItem[] = [
    { label: 'Listings', href: '/properties' },
    // { label: 'Marketplace', href: '/market-place' },
    { label: 'Save For Rent', href: '/save-for-rent' },
    // { label: 'Marketplace', href: '#' },
    { label: 'UtilityBills', href: '/utility-bills' },
    { label: 'Mortgage', href: '/mortgage' },
    // { label: 'Mortgage', href: '/mortgage' },
    { label: 'Contact', href: '/contact' },
    { label: 'Our Services', href: '/our-services' },
    { label: 'Add Listing', href: '/add-listing' },
    { label: 'Insurance', href: '/insurance' }
]