import React from 'react'
import Layout from '../../components/Layout'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'
import NavHeader from '../../components/NavHeader'
import AllRentInsurance from '../../views/Insurance/AdminInsurance/AllRentInsurance'

const AllInsurancesPage = () => {
  return (
    <Layout>
    <ScrollToTop />
    <SessionCheck shouldRedirect />
    <NavHeader pageTitle="View Rent Insurance Applicants" routeName="View Insurances" />
    <AllRentInsurance />
  </Layout>
  )
}

export default AllInsurancesPage