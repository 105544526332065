import React, { useContext, useEffect, useState } from "react";
import styles from "./artisans.module.scss";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { Alert, Button, CircularProgress } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import LoadingContext from "../../contexts/LoadingContext";
import ToastContext from "../../contexts/ToastContext";
import { useHistory } from "react-router";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Modal from "react-modal";

const libraries: (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[] = ["places"];

const CustomRadio = styled(Radio)<RadioProps>(({ theme }) => ({
  "&.Mui-checked": {
    color: "var(--ltn__secondary-color)",
  },
}));

let isFullSub: boolean;
let isPartialSub: boolean;
let noTime: boolean = false;
let notFuture: boolean = false;
let noAddress = false;
let noSub = false;
let pinErr = false;
let paymentMeth = false;

const EnterAddress: React.FC = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: libraries,
  });
  const [autocomplete, setAutoComplete] =
    React.useState<google.maps.places.Autocomplete>();
  const [address, setAddress] = React.useState<string | undefined>();

  const [value, setValue] = React.useState<Dayjs | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoComplete(autocomplete);
  };

  function isDateInFuture(dt: any): boolean {
    const date = dayjs(dt);
    const currentDate = dayjs();
    return date.isAfter(currentDate);
  }

  const onPlaceChanged = () => {
    if (autocomplete) {
      setAddress(autocomplete.getPlace().formatted_address);
    } else {
    }
  };

  const [cleared, setCleared] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!value) {
      noTime = true;
    } else {
      noTime = false;
    }
    if (value !== null && !isDateInFuture(value)) {
      notFuture = true;
    } else {
      notFuture = false;
    }
    if (!address) {
      noAddress = true;
    } else {
      noAddress = false;
    }
    if (cleared) {
      const timeout: any = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared, value, address]);

  return (
    <div className={styles.form}>
      <div className={styles.input} style={{ marginBottom: "1em" }}>
        <div className={styles.inputTitle} style={{ marginTop: "3em" }}>
          Address
        </div>
        {isLoaded ? (
          <Autocomplete
            className={styles.auto}
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              name={"Address"}
              value={address}
              placeholder="Address"
              style={{ height: "5em" }}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              required
            />
          </Autocomplete>
        ) : (
          <CircularProgress color="success" />
        )}
      </div>

      <div className={styles.input}>
        <div className={styles.inputTitle}>Select date and time</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
            orientation="landscape"
            sx={{
              outline: "none",
              border: "none",
              height: "20em",
              position: "relative",
            }}
            slotProps={{
              field: { clearable: true, onClear: () => setCleared(true) },
            }}
          />
        </LocalizationProvider>
      </div>

      {value !== null && !isDateInFuture(value) && (
        <Alert sx={{ alignSelf: "flex-start" }} severity="error">
          Enter future date
        </Alert>
      )}
    </div>
  );
};

const Subscription: React.FC = () => {
  const { setLoading } = useContext(LoadingContext);
  const { openSuccess, openError } = useContext(ToastContext);
  const [currId, setcurrId] = useState<any>();
  const goNext = (id: number) => {
    if (!id) {
      openError("Select an option");
      return;
    }
    setcurrId(id);
    if (id === 1) {
      isFullSub = true;
    }
    if (id === 2) {
      isFullSub = false;
    }
  };

  useEffect(() => {
    if (!currId) {
      noSub = true;
    } else {
      noSub = false;
    }
  }, [currId]);
  return (
    <div className={styles.sub}>
      <div
        className={styles.subcard}
        style={{
          border: currId === 1 ? "1px solid rgba(145, 234, 145, 1)" : "none",
        }}
        role="button"
        onClick={() => goNext(1)}
      >
        <WorkspacesIcon />
        <p style={{ fontWeight: "bold" }}>Get Annual Subscription</p>
        <span>This package covers all our services</span>
        <p>₦40,000</p>
      </div>
      <div
        className={styles.subcard}
        style={{
          border: currId === 2 ? "1px solid rgba(145, 234, 145, 1)" : "none",
        }}
        role="button"
        onClick={() => goNext(2)}
      >
        <HourglassTopIcon />
        <p style={{ fontWeight: "bold" }}>Pay As You Go</p>
        <span>Pay for each service</span>
        <p>₦5,000/Service</p>
      </div>
    </div>
  );
};

const MakePayment: React.FC = () => {
  const [isCard, setIsCard] = useState(false);
  const [isWallet, setIsWallet] = useState(false);
  const [pin, setPin] = useState("");

  const handleCard = () => {
    setIsCard(true);
    setIsWallet(false);
  };

  const handleWallet = () => {
    setIsCard(false);
    setIsWallet(true);
  };

  useEffect(() => {
    if (!pin || pin.length !== 4) {
      pinErr = true;
    } else {
      pinErr = false;
    }

    if (!isCard && !isWallet) {
      paymentMeth = true;
    } else {
      paymentMeth = false;
    }
  }, [pin, isWallet, isCard]);
  return (
    <div className={styles.payment}>
      <div className={styles.options}>
        <div className={styles.cardArea} onClick={handleCard}>
          <p>BY CARD</p>
          <CustomRadio checked={isCard} onClick={handleCard} />
        </div>
        <div className={styles.cardArea} onClick={handleWallet}>
          <p>BY WALLET</p>
          <CustomRadio checked={isWallet} onClick={handleWallet} />
        </div>
      </div>

      <div className={styles.paymentmaincard}>
        <div className={styles.pmcrow}>
          <span style={{ marginRight: "50%" }}>Total Amount:</span>
          <span>{isFullSub ? "₦40,000" : "₦5,000"}</span>
        </div>

        <input
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          style={{
            width: "50%",
            height: "17%",
            marginRight: "auto",
            border: "0.5px solid #CCCCCC",
            padding: "1em",
            marginLeft: "auto",
            alignSelf: "flex-end",
            marginBottom: "1.5em",
          }}
          placeholder="Enter 4-digit Pin"
          autoComplete="off"
        />
      </div>
    </div>
  );
};

const ShowSuccess: React.FC = () => {
  const history = useHistory();
  const closeModal = () => {
    history.push("/");
  };
  return (
    <div className={styles.container}>
      <div className={styles.headerCancel}>
        <span>Congratulations</span>
        <CheckCircleIcon
          fontSize="large"
          sx={{ color: "var(--ltn__secondary-color)" }}
        />
      </div>

      <div className={styles.body}>
        Your payment is successful. You will be contacted and provided your
        selected service.
      </div>

      <div className={styles.buttons}>
        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__secondary-color)",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
            // paddingTop: "1.8em",
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
            width: "90%",
          }}
          onClick={() => closeModal()}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

const RenderShowSuccess: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const closeModal = () => {
    setIsOpen(false);
  };

  const history = useHistory();
  const closeModali = () => {
    history.push("/");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Use 'auto' for instant scrolling
    });

    if (!isOpen) {
      closeModali();
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          top: "54%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "0.7em",
          zIndex: "9999",
          boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
        },
        overlay: {
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "9999",
        },
      }}
      contentLabel="isFeatured Modal"
    >
      <ShowSuccess />
    </Modal>
  );
};

const components = [
  {
    id: 1,
    component: EnterAddress,
  },
  {
    id: 2,
    component: Subscription,
  },
  {
    id: 3,
    component: MakePayment,
  },
  {
    id: 4,
    component: RenderShowSuccess,
  },
];

const BookArtisanView = () => {
  const { openSuccess, openError } = useContext(ToastContext);
  const [currIndex, setCurrIndex] = useState(0);
  const Component = components[currIndex].component;
  const goBack = () => {
    if (currIndex === 0) {
      return;
    } else {
      setCurrIndex((prev) => prev - 1);
    }
  };
  const goNext = () => {
    if (currIndex === 0) {
      if (noTime) {
        openError("Please select a time");
        return;
      }
      if (notFuture) {
        openError("Please select a future date");
        return;
      }
      if (noAddress) {
        openError("Please fill address");
        return;
      }
    }

    if (currIndex === 1) {
      if (noSub) {
        openError("Please select a subscription");
        return;
      }
    }

    if (currIndex === 2) {
      if (pinErr) {
        openError("Please enter 4-digit pin");
        return;
      }

      if (paymentMeth) {
        openError("Please select a payment method");
        return;
      }
    }

    if (currIndex === 3) {
      return;
    } else {
      setCurrIndex((prev) => prev + 1);
    }
  };

  return (
    <section className={styles.overallContainer}>
      {currIndex !== 0 && (
        <div className={styles.back}>
          <Button
            type="button"
            variant="contained"
            sx={{
              textAlign: "center",
              backgroundColor: "var(--ltn__secondary-color)",
              "&:hover": {
                backgroundColor: "var(--ltn__paragraph-color)",
              },
              height: "3em",
              width: "7em",
              display: "flex",
              alignItems: "center",
              marginTop: "0.5em",
              justifyContent: "center",
              marginBottom: "0.5em",
            }}
            onClick={() => goBack()}
          >
            Back
          </Button>
        </div>
      )}

      <Component />

      {currIndex !== 3 && (
        <div className={styles.next}>
          <Button
            type="button"
            variant="contained"
            sx={{
              textAlign: "center",
              backgroundColor: "var(--ltn__secondary-color)",
              "&:hover": {
                backgroundColor: "var(--ltn__paragraph-color)",
              },
              height: "3em",
              width: "7em",
              display: "flex",
              alignItems: "center",
              marginTop: "0.5em",
              justifyContent: "center",
              marginBottom: "0.5em",
            }}
            onClick={() => goNext()}
          >
            Next
          </Button>
        </div>
      )}
    </section>
  );
};

export default BookArtisanView;
