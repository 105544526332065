import Button from '../Button';
import Input from '../Input';
import FormLink from '../FormLink';

interface IForgotPasswordAccount {
    handleChange: () => void;
    onSubmit: (e: React.FormEvent) => void;
    formValues: { [key: string]: string };
    loading: boolean;
    link: string;
    email: string;
}

const ForgotPasswordAccount: React.FC<IForgotPasswordAccount> = ({ handleChange, onSubmit, formValues, loading, link, email }) => {
    
    return (
        <>
            <div className="row ">
                <div className="col-lg-6">
                    <div className="account-login-inner">
                        <form onSubmit={onSubmit} action="#" className="ltn__form-box contact-form-box">
                            <Input type="email" name="email" placeholder="Email*" required={true} onChange={handleChange} value={formValues.email} />
                            <Button label="RESET PASSWORD" classes={'theme-btn-1 btn btn-block '} disabled={loading} />
                          
                        </form>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="account-create text-center pt-30">
                    <h4>KINDLY LOGIN</h4>
                        <p>
                            Get access to convenient payment options for your housing needs, or list properties by signing in
                        </p>
                        <div className="btn-wrapper">
                            <FormLink link={link} classes={'theme-btn-1 btn black-btn'} text="BACK TO LOGIN" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordAccount;
