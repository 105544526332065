import React from "react";
import { PropertyStatus, PropertyType } from "../../../types/Property";
import { formatAmount } from "../../../utils/propertyUtils";
import styles from './detail.module.scss';

export interface DetailElementProps{
    id?: string | number;
    size?: string | number;
    bathrooms?: string | number;
    bedrooms?: string | number;
    price?: number;
    otherPrices?: number;
    status?: PropertyStatus;
    type?: PropertyType;
}

const Detail: React.FC<DetailElementProps> = ({
    id = 'HZ29', size = 120, bedrooms = 0,
    bathrooms = 0, price = 300000, status ='Rent',
    type = 'Residential', otherPrices = ''
})=>{
    return(
        <div className={`property-detail-info-list section-bg-1 clearfix mb-60`}>                          
            <ul>
            <li><label>Lot dimensions:</label> <span>{size} sqft</span></li>
                <li><label>Home Area: </label> <span>{size} sqft</span></li>
                <li><label>Bedrooms:</label> <span>{bedrooms}</span></li>
                <li><label>Bathrooms:</label> <span>{bathrooms}</span></li>
            </ul>
            <ul>
                <li><label>Legal/Other:</label> <span>₦ {formatAmount(otherPrices.toString())}</span></li>
                <li><label>Price:</label> <span>₦ {formatAmount(price.toString())}</span></li>
                <li><label>Property Type:</label> <span>{type === 'Flats' ? 'Flats & Apartments' : type}</span></li>
                <li><label>Property Status:</label> <span>For {status}</span></li>
            </ul>
        </div>
    )
}

export default Detail;