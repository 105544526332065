import React, { useContext, useEffect, useState } from "react";
import styles from "../ElectricBillForm/wasteBill.module.scss";
import Dropdown, { DropdownOption } from "../../FormElements/Dropdown";
import TextInput from "../../FormElements/TextInput";
import TextArea from "../../FormElements/TextArea";
import {
  BillResponseData,
  Customer,
  CustomerLookUpRequestBody,
  CustomerLookupRequest,
  PackageResponseData,
} from "../../../types/Billings";
import {
  InitiatePayment,
  ProcessPayment,
  customerLookUpApi,
  fetchAllBillersPackages,
  fetchEachBillerPackages,
} from "../../../swr/bills";
import Select from "react-select";
import { getWalletId } from "../../../utils/generalUtils";
import ToastContext from "../../../contexts/ToastContext";
import LoadingContext from "../../../contexts/LoadingContext";
import Modal from "react-modal";
import OtpModal from "./OtpModal";
import { useHistory } from "react-router";
import { Button } from "@mui/material";
import CustomerModal from "./CustomerModal";
import SuccessModal from "./SuccessModal";
import SuccessModalCard from "./SuccessModal";

interface DiscoBillProps {
  Bills?: BillResponseData[];
  selectedBiller: BillResponseData;
}

const customStyles = {
  content: {
    top: "54%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    //   border: '1px solid white',
    //   borderRadius: "0",
    zIndex: "1",
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
  },
};
const customStylesI = {
  content: {
    top: "54%",
    left: "10%",
    right: "10%",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    //   border: '1px solid white',
    //   borderRadius: "0",
    zIndex: "1",
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
  },
};

const DiscoFormBill: React.FC<DiscoBillProps> = ({ Bills, selectedBiller }) => {
  const { openError, openSuccess } = React.useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();

  const formRef = React.createRef<HTMLFormElement>();
  const [billerId, setBillerId] = useState<string | any>("");
  const [subPackage, setSubPackage] = useState<string | any>("");
  const [packages, setPackages] = useState<BillResponseData[]>([]);
  const [allPackages, setAllPackages] = useState<PackageResponseData[]>([]);
  const token = localStorage.getItem("token") as string;
  const walletId = getWalletId(token) as string;
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [customerId, setCustomerId] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentRef, setPaymentRef] = useState("");
  const [minPayableAmount, setMinPayableAmount] = useState<number | null>(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [SuccessModal, setSuccessModal] = useState(false);
  const [processPaymentRes, setProcessPaymentRes] = useState("");

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openCustomer = () => {
    setCustomerOpen(true);
  };

  const closeCustomer = () => {
    setCustomerOpen(false);
  };

  const openSuccessModal = () => {
    setSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setSuccessModal(false);
  };

  const mapToDropdowOptions = (
    data?: BillResponseData[] | PackageResponseData[]
  ) => {
    let result: DropdownOption[] = [];

    if (data) {
      result = data.map((item) => ({
        value: item.id,
        label: item.name,
        slug: item.slug ? item.slug : "",
      }));
    }

    return result;
  };

  const fetchUtilityPackages = async () => {
    setLoading(true);
    const response = await fetchAllBillersPackages(
      token,
      selectedBiller.id.toString()
    );
    setPackages(response.response_data);
    setLoading(false);
  };

  const fetchUtilityPackage = async (id: string) => {
    setLoading(true);
    const response = await fetchEachBillerPackages(token, id);
    setAllPackages(response.response_data);
    setLoading(false);
  };

  const handleSubPackage = async () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const sub = formData.get("packages") as string;
      setSubPackage(sub);

    }
  };

  const customerLookUp = async (slug: string, id: string) => {
    setLoading(true);
    const response = await customerLookUpApi(token, {
      customer_id: id,
      product_name: slug,
      biller_slug: billerId.slug,
    });

    if (response.response_code === "00") {
      if (response.response_data && response.response_data.customer) {
        setMinPayableAmount(
          response.response_data.minPayableAmount &&
            response.response_data.minPayableAmount
        );
        setCustomer(response.response_data.customer);
        setLoading(false);
      }
    } else {
      setLoading(false);
      openError(response.response_message);
      return;
    }
    setLoading(false);
  };

  const onAmountFocus = () => {
    if (formRef.current) {

      const formData = new FormData(formRef.current);
      const id = formData.get("customerId") as string;
      if (!id) {
        return;
      }
      setCustomerId(id);
    }
  };

  const initiatePayment = async () => {
    setLoading(true);
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const amt = formData.get("balance") as string;
      if (!amt) {
        openError("Please fill in amount");
        setLoading(false);
        return;
      }
      if(Number(amt) < 100){
        openError('Enter amount more than ₦100')
        setLoading(false)
        return
      }
      setAmount(amt);
    }
    const response = await InitiatePayment(token, {
      customer_id: customerId,
      account_number: walletId,
    });

    if (response.response_code !== "00") {
      openError(response.response_message);
      setLoading(false);
      return;
    }

    if (response.response_data) {
      setEmail(response.response_data.email);
      setPhone(response.response_data.phone_number);
    }

    if (response.paymentReference) {
      setPaymentRef(response.paymentReference);
    }
    openSuccess(response.response_message);
    setLoading(false);
    openModal();
  };

  const goToTransactionHistory = () => {
    closeSuccessModal();
    history.push("/account#liton_tab_1_6");
  };

  const processPayment = async () => {
    setLoading(true);
    const response = await ProcessPayment(token, {
      customer_id: customerId,
      account_number: walletId,
      package_slug: subPackage.slug,
      amount,
      customer_name: customer && customer.customerName && customer.customerName,
      phone_number: phone,
      email,
      payment_reference: paymentRef,
      otp: otp,
    });

    if (response.response_code === "00") {
      setProcessPaymentRes(
        response &&
          response.response_data &&
          response.response_data.customerMessage
      );
      openSuccess(response.response_message);
      closeModal();
      openSuccessModal();
    } else {
      openError(response.response_message);
    }
    setLoading(false);
    return;
  };



  useEffect(() => {
    setShowAmount(false);
    fetchUtilityPackages();
  }, []);

  useEffect(() => {
    if (billerId) {
      fetchUtilityPackage(billerId.value);
    }
    if (subPackage && customerId) {
      if (
        customer !== null &&
        customer !== undefined &&
        Object.entries(customer).length !== 0
      ) {
        openCustomer();
      } else {
        customerLookUp(subPackage.slug, customerId);
      }
    }
  }, [billerId, subPackage, customer, customerId]);

  const [showAmount, setShowAmount] = useState(false);

  const handleShowAmount = () => {
    if (!subPackage) {
      openError("Please pick package group");
      return;
    }
    if (!billerId) {
      openError("Please pick package");
      return;
    }
    if (formRef.current) {

      const formData = new FormData(formRef.current);
      const id = formData.get("customerId") as string;
      if (!id) {
        openError("Please enter meter number");
        return;
      }
      setCustomerId(id);
    }

    setShowAmount(true);
  };

  interface ErrorFormState {
    customerId: boolean;
    balance: boolean;
  }

  let initErrorState: ErrorFormState = {
    customerId: false,
    balance: false,
  };

  const [errorState, setErrorState] =
    React.useState<ErrorFormState>(initErrorState);

  const unhighlight = (event: React.ChangeEvent<any>) => {
    setErrorState((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
  };
  return (
    <form
      ref={formRef}
      onSubmit={(e) => e.preventDefault()}
      style={{ marginTop: "2em" }}
    >
      <div className="row">
        <div className="col-md-6">
          <label className={styles.label}>Select Package</label>
          <Select
            defaultValue={null}
            options={packages ? mapToDropdowOptions(packages) : []}
            onChange={setBillerId}
          />
        </div>

        {
          <div className="col-md-6">
            <label className={styles.label}>Select Group</label>
            <Select
              defaultValue={null}
              options={allPackages ? mapToDropdowOptions(allPackages) : []}
              onChange={setSubPackage}
              isDisabled={!billerId}
            />
          </div>
        }

        <div className="col-md-6">
          <label className={styles.label}>Meter Number</label>
          <TextInput
            hideDefaultAdornment
            name="customerId"
            error={errorState.customerId}
            onChange={unhighlight}
            required={errorState.customerId}
            className={styles.input}
          />
        </div>

        <div className="col-md-6" onClick={() => onAmountFocus()}>
          <label className={styles.label}>Amount</label>
          {showAmount ? (
            <TextInput
              hideDefaultAdornment
              name="balance"
              variant="amount"
              error={errorState.balance}
              onChange={unhighlight}
              required={errorState.balance}
              className={styles.input}
              onFocus={() => onAmountFocus}
            />
          ) : (
            <Button
              type="button"
              variant="contained"
              sx={{
                textAlign: "center",
                backgroundColor: "var(--ltn__secondary-color)",
                "&:hover": {
                  backgroundColor: "var(--ltn__paragraph-color)",
                },
                height: "3em",
                display: "flex",
                alignItems: "center",
                marginTop: "0.5em",
                justifyContent: "center",
              }}
              onClick={() => handleShowAmount()}
            >
              Enter Amount
            </Button>
          )}
        </div>

        <div className="col-md-12">
          <label className={styles.label}>Remarks</label>
          <TextArea
            hideDefaultAdornment
            name="remarks"
            onChange={unhighlight}
          />
        </div>
      </div>
      <button
        className="btn theme-btn-1 btn-effect-1 text-uppercase"
        type="submit"
        onClick={() => initiatePayment()}
        style={{
          zIndex: 0,
        }}
      >
        Pay
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "30%",
            left: "30%",
            right: "30%",
            bottom: "auto",
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            backgroundColor: "whitesmoke",
            height: "50%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Otp Modal"
      >
        <OtpModal
          closeModal={closeModal}
          otp={otp}
          setOtp={setOtp}
          processPayment={processPayment}
        />
      </Modal>
      <Modal
        isOpen={customerOpen}
        onRequestClose={closeCustomer}
        style={{
          content: {
            top: "30%",
            left: "30%",
            right: "30%",
            bottom: "auto",
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            backgroundColor: "whitesmoke",
            height: "50%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Customer Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <CustomerModal
          closeModal={closeCustomer}
          customer={customer}
          minPayable={minPayableAmount}
        />
      </Modal>
      <Modal
        isOpen={SuccessModal}
        onRequestClose={closeSuccessModal}
        style={{
          content: {
            top: "20%",
            left: "30%",
            right: "30%",
            bottom: "auto",
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            backgroundColor: "whitesmoke",
            height: "60%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Success Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <SuccessModalCard
          goToTransactionHistory={goToTransactionHistory}
          response={processPaymentRes}
        />
      </Modal>
    </form>
  );
};

export default DiscoFormBill;
