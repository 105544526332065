import React from "react";
import styles from "./admin-rent.module.scss";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegImages } from "react-icons/fa";

const ViewDocuments = ({ docs }: { docs: string[] | undefined }) => {
  const renderDocs = docs?.filter((doc) => doc !== "");

  const openDoc = (doc: string, type: "pdf" | "img") => {
    const link = document.createElement("a");
    link.href = doc;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download =
      type === "pdf" ? "rentInsuranceDoc.pdf" : "rentInsuranceDoc.jpeg"; 
    link.click();
  };

  return (
    <div className={styles["viwer-doc-bk"]}>
      {renderDocs &&
        renderDocs.length > 0 &&
        renderDocs.map((doc, index) =>
          doc.endsWith("pdf") ? (
            <div
              className={styles["view-doc-pdf"]}
              key={index}
              role="button"
              onClick={() => openDoc(doc, "pdf")}
            >
              <IoDocumentTextOutline fontSize={"3em"} color="#4D4D4D" />
              <span>View Document</span>
            </div>
          ) : (
            <div
              className={styles["view-doc-img-cont"]}
              key={index}
              role="button"
              onClick={() => openDoc(doc, "img")}
            >
              <div className={styles["img-icon-doc"]}>
                <FaRegImages fontSize={"2em"} color="#4D4D4D" />
              </div>
              <img
                src={doc}
                alt="document as image"
                className={styles["view-doc-img"]}
              />
            </div>
          )
        )}
    </div>
  );
};

export default ViewDocuments;
