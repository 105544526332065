import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { getPensionApplicatApi, markPensionApi } from "../../../swr/properties";
import { PensionApplicant } from "../../../types/Pension";
import ToastContext from "../../../contexts/ToastContext";
import LoadingContext from "../../../contexts/LoadingContext";
import styles from "../viewpension.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "../../../assets/avatar.jpeg";
import { statesForSearch } from "../../../types/states";

const ViewPensionApplicant = () => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const [applicant, setApplicant] = useState<PensionApplicant>(
    {} as PensionApplicant
  );
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const token = localStorage.getItem("token") as string;
  const getPensionApplicantById = async (applicantId: string) => {
    setLoading(true);
    const { data, error } = await getPensionApplicatApi(applicantId, token);

    if (data && data.succeeded && data.data) {
      setApplicant(data.data);
      setLoading(false);
      return;
    }

    if (error) {
      setLoading(false);
      openError("Could not load resource");
      return;
    }
    setLoading(false);
    openError("Could not load resource");
    return;
  };

  const markApplication = async (
    applicantId: string,
    tokenA: string,
    attended: boolean
  ) => {
    setLoading(true);
    const { data, error } = await markPensionApi(applicantId, tokenA, attended);

    if (data && data.succeeded) {
      openSuccess("Successfully marked as read");
      setLoading(false);
      return;
    }

    if (error) {
      setLoading(false);
      openError("Could not load resource");
      return;
    }
    setLoading(false);
    openError("Could not load resource");
    return;
  };

  useEffect(() => {
    getPensionApplicantById(params.id);
  }, []);

  const imgUrl =
    applicant && applicant.user && applicant.user.imageUrl
      ? applicant.user.imageUrl
      : Avatar;

  const returnGenderStr = (gender: number) => {
    switch (gender) {
      case 1:
        return "MALE";
      case 2:
        return "FEMALE";
      default:
        return "OTHER";
    }
  };

  const renderMaritalStr = (value: number) => {
    switch (value) {
      case 1:
        return "SINGLE";
      case 2:
        return "MARRIED";
      default:
        return "OTHER";
    }
  };

  const returnTitleStr = (title: number) => {
    switch (title) {
      case 1:
        return "MR";
      case 2:
        return "MRS";
      case 3:
        return "MISS";
      default:
        return "MASTER";
    }
  };

  function formatDate(dateString: Date): string {
    // Parse the date string into a Date object
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // Function to get the ordinal suffix for a given day
    function getOrdinalSuffix(day: number): string {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    // Concatenate the day with its ordinal suffix
    const formattedDate = `${day}${getOrdinalSuffix(
      day
    ).toUpperCase()} ${month.toUpperCase()} ${year}`;
    return formattedDate;
  }

  return (
    <div className={styles["profile-container"]}>
      <div className={styles["profile-back"]}>
        <div
          className={styles["profile-back-cont"]}
          onClick={() => history.push("/viewpensions")}
          role="button"
        >
          <ArrowBackIcon />
        </div>
      </div>

      <div className={styles["profile-main"]}>
        <div className={styles["profile-info-main"]}>
          <div className={styles["profile-info-1"]}>
            <div className={styles["profile-info-1-img"]}>
              <img src={imgUrl} alt="Applicant's image" />
            </div>

            <div className={styles["profile-info-1-info"]}>
              <p>Profile</p>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Title</div>
                <span>{returnTitleStr(applicant && applicant.title)}</span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>First Name</div>
                <span>{applicant && applicant.firstName}</span>
              </div>

              {applicant && applicant.middleName && (
                <div className={styles["profile-info-1-info-each"]}>
                  <div>Middle Name</div>
                  <span>{applicant && applicant.middleName}</span>
                </div>
              )}

              <div className={styles["profile-info-1-info-each"]}>
                <div>Last Name</div>
                <span>{applicant && applicant.lastName}</span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Gender</div>
                <span>
                  {applicant &&
                    applicant.gender &&
                    returnGenderStr(applicant.gender)}
                </span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Marital Status</div>
                <span>
                  {applicant &&
                    applicant.maritalStatus &&
                    renderMaritalStr(applicant.maritalStatus)}
                </span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Birth Date</div>
                <span>
                  {applicant &&
                    applicant.dateOfBirth &&
                    formatDate(applicant.dateOfBirth)}
                </span>
              </div>

              {applicant && applicant.attendedTo === true ? (
                <div
                  className={styles["mark-treated"]}
                  role="button"
                  onClick={() => markApplication(params.id, token, false)}
                >
                  <span>Mark As Pending</span>
                </div>
              ) : (
                <div
                  className={styles["mark-treated"]}
                  role="button"
                  onClick={() => markApplication(params.id, token, true)}
                >
                  <span>Mark As Treated</span>
                </div>
              )}
            </div>
          </div>
          <div className={styles["profile-info-2"]}>
            <div className={styles["profile-info-2A"]}>
              <p>Contact</p>
              <div className={styles.line}></div>
              <div className={styles.contact}>
                <ul>
                  <li>{applicant && applicant.email}</li>
                  <li>{applicant && applicant.phoneNumber}</li>
                  <li>{applicant && applicant.address}</li>
                  <li>
                    {applicant &&
                      applicant.state &&
                      statesForSearch[applicant.state] &&
                      statesForSearch[applicant.state].toUpperCase()}
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles["profile-info-2B"]}>
              <p>Other Information</p>
              <div className={styles.line}></div>

              {applicant && applicant.currentPFA && (
                <div className={styles.each}>
                  <span>Pension Admin</span>
                  <div>{applicant && applicant.currentPFA}</div>
                </div>
              )}

              {applicant && applicant.hasRSAPIN === true && applicant.rsapin ? (
                <div className={styles.each}>
                  <span>RSA PIN</span>
                  <div>{applicant && applicant.rsapin}</div>
                </div>
              ) : (
                <div className={styles.each}>
                  <div className={styles.norsa}></div>
                  <div>{`${
                    applicant && applicant.firstName
                  } has no RSA PIN`}</div>
                </div>
              )}

              <div className={styles.each}>
                <span>Employer's Name</span>
                <div>{applicant && applicant.employerName}</div>
              </div>

              <div className={styles.each}>
                <span>Employer's Address</span>
                <div>{applicant && applicant.employerAddress}</div>
              </div>

              <div className={styles.each}>
                <span>Date Of Application</span>
                <div>
                  {applicant &&
                    applicant.createdAt &&
                    formatDate(applicant.createdAt)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPensionApplicant;
