import React from 'react'
import Layout from '../../components/Layout'
import ScrollToTop from '../../components/ScrollToTop'
import NavHeader from '../../components/NavHeader'
import BookArtisanView from '../../views/Artisans/BookArtisanView'

const BookArtisan = () => {
  return (
    <Layout>
    <ScrollToTop />
    <NavHeader pageTitle="Hire An Artisan" routeName="book-artisan" />
    <BookArtisanView />
  </Layout>
  )
}

export default BookArtisan