import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import React from 'react';
import { Property } from '../../types/Property';
import styles from './mortgage.module.scss';
import defaultHouseImg from '../../img/product-3/2.jpg'
import CardActionArea from '@mui/material/CardActionArea';
import { formatAmount } from '../../utils/propertyUtils';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DownPaymentIcon from '../../svg/DownPaymentIcon';
import TotalCostIcon from '../../svg/TotalCostIcon';

export interface LoanSectionProps{
    property?: Property;
}

const LoanSection: React.FC<LoanSectionProps> = ({ property })=>{

    let houseImage = defaultHouseImg;

    if (property?.attachments) {
        houseImage = property.attachments.length > 0 ? property.attachments[0].imageUrl : defaultHouseImg;
    }

    // Dummy prices
    let totalCost: number = 0;
    let rent: number = 0;
    let interest: number = 0;
    let serviceFee: number = 0;
    let AgentAgreement: number = 0;
    let paymentNow: number = 0

    if (property?.price) {
        let { price } = property
        rent = price;
        interest = price * 0.15;
        serviceFee = price * 0.02;
        AgentAgreement = price * 0.1;
        paymentNow = price * 35;
        totalCost = price + interest + serviceFee + AgentAgreement
    }


    interface BillItem{ label: string, price: number };
    const billItems: BillItem[] = [
        { label: 'Rent', price: rent },
        { label: 'Interest paid', price: interest },
        { label: 'Service Fee', price: serviceFee },
        { label: 'Agent and Agreement', price: AgentAgreement },
    ]


    // Elements
    const priceBreakdown = (
        <div className={styles.breakdown}>
            <ul className={styles.breakdownList}>
                {
                    billItems.map((item)=>(
                        <li>
                            <span>{item.label}</span>
                            <span><span>₦ </span>{formatAmount(item.price.toString())}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
    );

    const downPaymentSummary = (
        <div className={styles.downPayment}>
            {/** Icon */}
            <div className={styles.icon}><DownPaymentIcon/></div>
            <div>Down Payment</div>
            <div><span>₦ </span>{formatAmount(property?.price.toString() || '0' )}</div>
        </div>
    )

    const totalCostSummary = (
        <div className={styles.downPayment}>
            {/** Icon */}
            <div className={styles.icon}> <TotalCostIcon/></div>
            <div>Total Property Cost</div>
            <div><span>₦ </span>{formatAmount(totalCost.toString())}</div>
        </div>
    )

    return(
        <section>
            <div className={styles.container}>

                {/** Image Card */}
                <Card sx={{ flex: '35%', height: '100%' }} elevation={0}>
                    <CardMedia
                        component="img"
                        //height="194"
                        image={houseImage}
                        alt="Paella dish"
                    />
                    <CardActionArea>
                    <div className={styles.productPrice}>
                        <span>
                            {'₦ '}{formatAmount(property?.price.toString() || '300000')}
                        </span>
                    </div>
                    </CardActionArea>
                </Card>

                {/** Terms */}
               <Card sx={{ flex: '60%', padding:'10px', marginLeft: '30px', borderRadius: 2 }} elevation={0} >
                   <CardHeader
                        title="Your Terms*"
                    />
                    <CardContent>
                        <div className={styles.cardContent}>
                            { priceBreakdown }
                            { downPaymentSummary }
                            { totalCostSummary }
                        </div>
                    </CardContent>
               </Card>
            </div>
        </section>
    )
}

export default LoanSection;