import Skeleton from "@mui/material/Skeleton";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import {
  CreateSavingsRequest,
  GetTotalSavingsDatas,
  SavingsTowardsRent,
  getTotalSavingsResponse,
  savingsTransaction,
} from "../../types/Savings";
import { formatAmount, formatDate, genMsgId } from "../../utils/propertyUtils";
import styles from "../../views/UserAccountTargets/savingPlans.module.scss";
import style1 from "./savingsHistory.module.scss";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { ReleaseSavings, getTotalSavings } from "../../swr/saveForRent";
import { getWalletId } from "../../utils/generalUtils";
import ToastContext from "../../contexts/ToastContext";
import LoadingContext from "../../contexts/LoadingContext";
import { ValidationErrors } from "../../swr/wallet";

export interface UserAccountTargetsProps {
  savingsTowardsRent?: SavingsTowardsRent[];
  loading?: boolean;
  onSelect?: (savingPlan?: SavingsTowardsRent) => void;
}

const UserSavingHistory: React.FC<UserAccountTargetsProps> = ({
  savingsTowardsRent,
  loading,
  onSelect,
}) => {
  const history = useHistory();
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = React.useContext(LoadingContext);

  const [aggregateSavings, setAggregateSavings] = useState(0);
  const [savingHistory, setSavingHistory] = useState<GetTotalSavingsDatas>();
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedSaving, setSelectedSaving] = useState<any>();

  const token = localStorage.getItem("token") as string;
  const walletId = getWalletId(token) as string;
  const email = localStorage.getItem("email") as string;
  const hold_id = "RENT";

  const HandleReleaseSuccess = () => {
    setLoading(false);
    openSuccess("savings released successfully");
    setAmount("");
    // window.location.reload()
    history.push("/account#liton_tab_1_6");
  };

  const HandleReleaseFailure = (msg?: string) => {
    setLoading(false);
    setAmount("");
    openError(msg ? msg : "an error occurred");
  };

  const [Amount, setAmount] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputAmount = e.target.value;

  //   for(let i = 0; i < inputAmount.length; i++){
  //     if(!'0123456789.,'.split('').includes(inputAmount[i])){
  //       openError("please enter a valid number")
  //       return;
  //     }
  //   }

  //   // Remove existing commas from the input Amount
  //   const sanitizedAmount = inputAmount.replace(/,/g, "");

  //   const [integerPart, decimalPart] = sanitizedAmount.split(".");

  // // Format the integer part with commas
  //   let formattedAmount = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // // Add the decimal part if it exists
  //   if (decimalPart) {
  //     formattedAmount += "." + decimalPart;
  //   }

  // setAmount(formattedAmount);

  //   // Format the Amount with commas
  //   // console.log(Number(sanitizedAmount));

  //   // const formattedAmount = Number(sanitizedAmount).toLocaleString();

  //   // setAmount(formattedAmount);
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAmount = e.target.value;

    for (let i = 0; i < inputAmount.length; i++) {
      if (!"0123456789.,".split("").includes(inputAmount[i])) {
        openError("Please enter a valid number");
        return;
      }
    }

    // Remove non-digit characters except decimal point
    const sanitizedAmount = inputAmount.replace(/[^\d.]/g, "");

    // Format the Amount with commas
    // const formattedAmount = Number(sanitizedAmount).toLocaleString();

    if (sanitizedAmount.indexOf(".") !== sanitizedAmount.lastIndexOf(".")) {
      openError("Please enter a valid number");
      return;
    }

    if (!sanitizedAmount.includes(".")) {
      const formattedAmount = Number(sanitizedAmount).toLocaleString();
      setAmount(formattedAmount);
      return;
    }
    setAmount(sanitizedAmount);
  };

  const setDescriptionGeneralToNull = (option: any | string) => {
    if (option === "GENERAL") {
      return "";
    } else {
      return option;
    }
  };

  const releaseSavingsReq = {
    release_amount: Amount && Amount.split(",").join(""),
    release_remark: "",
    msg_id: genMsgId(),
    hold_id:
      selectedSaving &&
      selectedSaving.typeValue &&
      setDescriptionGeneralToNull(selectedSaving.typeValue),
  };

  const handleReleaseSubmit = async () => {
    try {
      if (!Amount || Amount === (0 || "0")) {
        openError("please enter amount");
        return;
      }
      setLoading(true);
      const res = await ReleaseSavings(releaseSavingsReq, token);
      if (res && res.succeeded === true) {
        HandleReleaseSuccess();
      } else {
        HandleReleaseFailure(res.message);
      }
    } catch (error) {
      // @ts-ignore
      let err: AxiosError<ValidationErrors> = error; // cast the error for access
      if (!err.response) {
        throw err;
      }
      setLoading(false);
      openError(err.response.data.message ?? err.response.data.Message);
    }
  };

  const getAggregrateSavings = async () => {
    try {
      const response = await getTotalSavings(token, walletId);
      if (response && response.succeeded === true) {
        setAggregateSavings(
          response.data &&
            response.data.overallBalance &&
            response.data.overallBalance
        );
        setSavingHistory(response && response.data);
        setSuccess(true);
      }
    } catch (err) {
      //@ts-ignore
      let error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      setLoading(false);
      openError(error.response.data.message ?? error.response.data.Message);
    }
  };

  useEffect(() => {
    getAggregrateSavings();
    // console.log(savingHistory);
  }, []);

  const savingsArray = [
    {
      n: 1,
      type: "Rent",
      amount:
        savingHistory && savingHistory.rentBalance && savingHistory.rentBalance,
      typeValue: "RENT",
    },
    {
      n: 2,
      type: "Property Purchase",
      amount:
        savingHistory &&
        savingHistory.propertyPurchaseBalance &&
        savingHistory &&
        savingHistory.propertyPurchaseBalance,
      typeValue: "PROPERTY_PURCHASE",
    },
    {
      n: 3,
      type: "Monthly Bills",
      amount:
        savingHistory &&
        savingHistory.monthlyBillsBalance &&
        savingHistory.monthlyBillsBalance,
      typeValue: "MONTHLY_BILLS",
    },
    {
      n: 4,
      type: "General",
      amount:
        savingHistory &&
        savingHistory.generalBalance &&
        savingHistory.generalBalance,
      typeValue: "GENERAL",
    },
  ];

  const selectClassName = (id: string) => {
    if (id === selectedTab) {
      return style1.selected;
    } else {
      return style1.tab;
    }
  };

  // Handlers
  const handleSelect = (id: string) => {
    if (id === selectedTab) {
      setSelectedTab("");
    } else {
      setSelectedTab(id);
      setSelectedSaving(savingsArray.find((item) => item.n.toString() === id));
    }
  };

  // let pageCount = savingHistory && Math.ceil(savingHistory.length / 10);
  // const firstTenSavings = savingHistory
  //   ? (savingHistory.slice(0, 10) as savingsTransaction[])
  //   : [];

  // const [page, setPage] = useState(1);

  // const tenPerPage = (n: number) => {
  //   return { first: n * 10 - 10, last: n * 10 - 1 };
  // };

  // const [paginatedSavings, setpaginatedSavings] = useState<
  //   savingsTransaction | any
  // >([]);

  // const handlePageChange = (
  //   event: React.ChangeEvent<unknown>,
  //   newPage: number
  // ) => {
  //   setPage(newPage);
  //   const { first, last } = tenPerPage(newPage);
  //   let l: number =
  //     savingHistory && savingHistory.length ? savingHistory.length : 1;

  //   if (last > l) {
  //     let savings = savingHistory && savingHistory.slice(first);
  //     setpaginatedSavings(savings);
  //     return;
  //   }
  //   let savings = savingHistory && savingHistory.slice(first, last);
  //   setpaginatedSavings(savings);
  // };

  // You need the 'active show' classes
  return (
    <>
      {success === true ? (
        <div className="tab-pane fade active show" id="liton_tab_1_2">
          <div className="ltn__myaccount-tab-content-inner">
            <div className="table-responsive">
              <div className={styles.display}>
                <div className={styles.balanceDisplay}>
                  {window.innerWidth <= 768 ? "Total:" : "Total Savings:"}{" "}
                  <h4>₦{aggregateSavings ? aggregateSavings : 0}</h4>
                </div>

                {selectedTab !== (null || "") ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                      height: "100%",
                      width: "60%",
                    }}
                  >
                    <div
                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      style={{
                        height: "17%",
                        width: "26%",
                        ...(window.innerWidth <= 768 && {
                          width: "45%",
                        }),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => handleReleaseSubmit()}
                    >
                      <span>withdraw</span>
                    </div>

                    <input
                      style={{
                        height: "3.8em",
                        width: "10em",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        marginBottom: "0em",
                        ...(window.innerWidth <= 768 && {
                          width: "7.5em",
                          fontWeight: "thin",
                        }),
                      }}
                      pattern="[0-9]*"
                      inputMode="numeric"
                      placeholder="Amount"
                      type="text"
                      value={Amount}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  </div>
                ) : (
                  <>
                    {aggregateSavings && aggregateSavings > 0 && (
                      <b>Click Saving To Withdraw</b>
                    )}
                  </>
                )}
              </div>

              <table className="table">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Type</th>
                    <th>Amount</th>
                    {/* <th>Balance</th> */}
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {loading &&
                    [0, 1, 2, 3, 4, 5].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                          ></Skeleton>{" "}
                        </td>
                        {/* <td>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                          ></Skeleton>{" "}
                        </td> */}
                        <td>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                          ></Skeleton>{" "}
                        </td>
                        <td>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                          ></Skeleton>{" "}
                        </td>
                      </tr>
                    ))}

                  {savingsArray ? (
                    savingsArray
                      .filter((saving) => saving.amount! > 0)
                      .map((saving) => (
                        <tr
                          key={saving.n}
                          onClick={() => handleSelect(saving.n.toString())}
                          title="click, enter withdraw amount"
                          role="button"
                        >
                          <td className={selectClassName(saving.n.toString())}>
                            {saving.n}
                          </td>
                          <td className={selectClassName(saving.n.toString())}>
                            {saving.type}
                          </td>
                          <td className={selectClassName(saving.n.toString())}>
                            ₦{" "}
                            {formatAmount(
                              saving.amount ? saving.amount.toString() : "0"
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <b>{""}</b>
                  )}
                </tbody>
              </table>

              {/* {savingHistory && savingHistory.length > 0 && (
                <Stack
                  spacing={2}
                  sx={{ marginBottom: "30px", alignItems: "center" }}
                >
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handlePageChange}
                    size={"large"}
                    variant="outlined"
                    color="standard"
                  />
                </Stack>
              )} */}

              <Link to={"/save-for-rent"} style={{ color: "green" }}>
                {" "}
                <button
                  className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                >
                  create savings
                </button>{" "}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        // setLoading(true)
        <b>No savings</b>
      )}
    </>
  );
};

export default UserSavingHistory;

// import React from 'react';
// import Dropdown from '../../components/FormElements/Dropdown';
// import TextInput, { TextInputProps } from '../../components/FormElements/TextInput';
// import { Cities } from '../../types/cities';
// import { States } from '../../types/states';

// const UserSavingHistory: React.FC<any> = ()=>{

//     // Inputs
//     interface BillingInput extends TextInputProps{
//         label: string;
//     }

//     const personalInfoInputs: TextInputProps[] = [
//         {
//             placeholder: 'First name', className: 'input-item input-item-name ltn__custom-icon',
//             name: 'first_name'
//         },
//         {
//             placeholder: 'Last name', className: 'input-item input-item-name ltn__custom-icon',
//             name: 'last_name'
//         },
//         {
//             placeholder: 'email address', className: 'input-item input-item-email ltn__custom-icon',
//             name: 'email'
//         },
//         {
//             placeholder: 'phone number', className: 'input-item input-item-phone ltn__custom-icon',
//             name: 'phone_number'
//         },
//     ];

//     const addressInputs: TextInputProps[] = [
//         {
//             placeholder: 'House number and street name', name: 'address1'
//         },
//         {
//             placeholder: 'Apartment, suite, unit etc. (optional)', name: 'address2'
//         },
//     ]

//     // Elememts
//     const billingDetails = (
//         <div className="ltn__checkout-single-content">
//             <h4 className="title-2">Billing Details</h4>
//             <div className="ltn__checkout-single-content-info">
//                 <form action="#">

//                     {/** Personal Info */}
//                     <h6>Personal Information</h6>
//                     <div className="row">
//                         {
//                             personalInfoInputs.map((item, index)=>(
//                                 <div className="col-md-6" key={`bill${index}`}>
//                                     <TextInput
//                                         //useValue={item.value}
//                                         hideDefaultAdornment
//                                         name={item.name}
//                                         type={item.type}
//                                         placeholder={item.placeholder}
//                                         containerClass={item.className}
//                                     />
//                                 </div>
//                             ))
//                         }
//                     </div>

//                     {/** Address Info */}
//                     <div className="row">
//                         <div className="col-lg-12 col-md-12">
//                             <h6>Address</h6>
//                             <div className="row">
//                                 {
//                                     addressInputs.map((item, index)=>(
//                                         <div className="col-md-6" key={`add${index}`}>
//                                             <TextInput
//                                                 //useValue={item.value}
//                                                 hideDefaultAdornment
//                                                 name={item.name}
//                                                 type={item.type}
//                                                 placeholder={item.placeholder}
//                                             />
//                                         </div>
//                                     ))
//                                 }
//                             </div>
//                         </div>

//                         {/** Town, state, and Zip */}
//                         <div className="col-lg-4 col-md-6">
//                             <h6>Town / City</h6>
//                             <div className="input-item">
//                                 <Dropdown placeholder={'City'}
//                                     options={Object.values(Cities)}
//                                     name={'city'}
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-lg-4 col-md-6">
//                             <h6>State </h6>
//                             <div className="input-item">
//                                 <Dropdown placeholder={'State'}
//                                     options={Object.values(States)}
//                                     name={'state'}
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-lg-4 col-md-6">
//                             <h6>Zip</h6>
//                             <div className="input-item">
//                                 <TextInput
//                                     placeholder='Zip'
//                                 />
//                             </div>
//                         </div>
//                     </div>

//                     {/** Checkbox to Create Account */}
//                     <p>
//                         <label className="input-info-save mb-0">
//                             <input type="checkbox" name="agree"/>
//                             Create an account?
//                         </label>
//                     </p>

//                     {/** Order notes */}
//                     <h6>Order Notes (optional)</h6>
//                     <div className="input-item input-item-textarea ltn__custom-icon">
//                         <textarea name="order_message"
//                             placeholder="Notes about your order, e.g. special notes for delivery.">
//                         </textarea>
//                     </div>

//                     <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Save</button>

//                 </form>
//             </div>
//         </div>
//     );

//     const accountBilling = (
//         <div className="tab-pane fade active show" id="liton_tab_1_4">
//             <div className="ltn__myaccount-tab-content-inner">
//                 <p>The following addresses will be used on the checkout page by default.</p>
//                 <div className="row">
//                     <div className="col-md-6 col-12 learts-mb-30">
//                         <h4>Billing Address <small><a href="#">edit</a></small></h4>
//                         <address>
//                             <p><strong>Alex Tuntuni</strong></p>
//                             <p>1355 Market St, Suite 900 <br/>
//                                 San Francisco, CA 94103</p>
//                             <p>Mobile: (123) 456-7890</p>
//                         </address>
//                     </div>
//                     <div className="col-md-6 col-12 learts-mb-30">
//                         <h4>Shipping Address <small><a href="#">edit</a></small></h4>
//                         <address>
//                             <p><strong>Alex Tuntuni</strong></p>
//                             <p>1355 Market St, Suite 900 <br/>
//                                 San Francisco, CA 94103</p>
//                             <p>Mobile: (123) 456-7890</p>
//                         </address>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )

//     return(
//         billingDetails
//     )
// }

// export default UserSavingHistory;
