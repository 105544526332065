import React, { useEffect } from "react";
import PropertyCard from "../../components/PropertyCard";
import PropertyToolbar, {
  PageOption,
  SortingOption,
} from "../../components/PropertyToolbar";
import { Property } from "../../types/Property";
import styles from "./propertyGrid.module.scss";
import unavailableImage from "../../assets/property_unavailable.png";
import Skeleton from "@mui/material/Skeleton";
import propertyData from "../../components/PropertyCard/defaultData";
import FilterWidget, { FilterOption } from "../../components/Widgets/Filter";
import ProductSidebar from "../../components/ProductSidebar";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

export interface PropertyGridProps {
  properties: Property[];
  loading?: boolean;
}

const PropertyGrid: React.FC<PropertyGridProps> = ({ properties, loading }) => {
  // State
  const [page, setPage] = React.useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(12);
  const [pageCount, setPageCount] = React.useState(0);
  const [sortedList, setSortedList] = React.useState(properties);
  const [sortProperty, setSortProperty] = React.useState<any>(null);

  // Hashes
  const pageOptionHash = {
    "Per Page: 12": 12,
    "Per Page: 20": 20,
    "Per Page: 30": 30,
    "Per Page: 50": 50,
    "Per Page: 100": 100,
  };
  const sortOptionsHash = {
    "Default Sorting": null,
    "Sort by popularity": "rating",
    "Sort by new arrivals": "dateAdded",
    "Sort by price: low to high": "ascending",
    "Sort by price: high to low": "descending",
  };

  let paginationProperties =
    sortedList && sortedList.length > 0 ? sortedList : properties;

  useEffect(() => {
    if (propertyData.availability === true){
      if (sortedList.length) {
        setPageCount(Math.ceil(paginationProperties.length / itemsPerPage));
      }
    }
  }, [sortedList.length]);

  // Handlers
  const handleScrollToTop = () => {
    window.scrollTo({ top: 200, behavior: 'smooth' });
    // window.scrollTo(100, 0);
  };

  useEffect(() => {
    handleScrollToTop()
  }, [page])

  const handlePageChange = (event: React.ChangeEvent<any>, newPage: number) => {
    event.preventDefault()
    setPage(newPage);
  };
  const hadleToolbarChange = (
    sortOption?: SortingOption,
    pageOption?: PageOption
  ) => {
    if (pageOption) {
      //console.log(pageOptionHash[pageOption]);
      setItemsPerPage(pageOptionHash[pageOption]);
      handleScrollToTop()
    }
    if (sortOption) {
      setSortProperty(sortOptionsHash[sortOption]);
      handleScrollToTop()
    }
  };
  const handleFilterChange = (state: FilterOption) => {
    let newList: Property[] = properties;

    // Filter for amenities
    if (state.amenities && state.amenities.length > 0) {
      newList = properties.filter((item) => {
        if (state.amenities && item.amenities) {
          for (let i = 0; i < state.amenities.length; i++) {
            const amenity = state.amenities[i];
            const amenityWithCount = item.amenities.find(
              (amty) => amty.amenity === amenity
            );
            if (!amenityWithCount) {
              return;
            }
          }
          return item;
        }
      });
      //setSortedList(newList);
    }

    // Filter for property type
    if (state.types && state.types.length > 0) {
      newList = newList.filter((item) => {
        if (state.types?.includes(item.type)) {
          return item;
        }
      });
    }

    // Filter for price range
    if (state.priceRange && state.priceRange.length > 0) {
      newList = newList.filter((item) => {
        if (state.priceRange) {
          if (
            item.price >= state.priceRange[0] &&
            item.price <= state.priceRange[1]
          ) {
            return item;
          }
        }
      });
    }

    // Filter for category (or status)
    if (state.status && state.status.length > 0) {
      newList = newList.filter((item) => {
        if (state.status?.includes(item.status)) {
          return item;
        }
      });
    }

    // Reset if no filters
    if (!state.amenities && !state.types && !state.priceRange) {
      newList = properties;
    }

    setSortedList(newList);
  };

  console.log(sortedList.length, "sorted list");
  console.log(properties.length, "properties list");

  // Sorters
  const descendingComparator = (a: any, b: any, orderBy: any) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  console.log(pageCount, "pageCount===----=-=-=-=-=-=-=");

  // Effect
  React.useEffect(() => {
    if (properties) {
      setPageCount(Math.ceil(paginationProperties.length / itemsPerPage));
      setSortedList(properties);

      if (sortProperty) {
        switch (sortProperty) {
          case "ascending":
            let arrayForSort = [...properties];
            setSortedList(
              arrayForSort.sort((a, b) => -descendingComparator(a, b, "price"))
            );
            break;

          case "descending":
            let arrayForSort2 = [...properties];
            setSortedList(
              arrayForSort2.sort((a, b) => descendingComparator(a, b, "price"))
            );
            break;

          case "dateAdded":
            let arrayForSort3 = [...properties];
            setSortedList(
              arrayForSort3.sort((a, b) =>
                descendingComparator(a, b, "dateAdded")
              )
            );
            break;

          case "rating":
            let arrayForSort4 = [...properties];
            setSortedList(
              arrayForSort4.sort((a, b) => descendingComparator(a, b, "rating"))
            );
            break;

          default:
            setSortedList(properties);
            break;
        }
      }
    }
  }, [itemsPerPage, properties, sortProperty]);

  return (
    <div className="ltn_product-area ltn_product-gutter mb-120">
      <div className="container">
        <div className="row">
          {/** Results Column */}
          <div className="col-lg-8">
            {/** Toolbar */}
            <PropertyToolbar onStateChange={hadleToolbarChange} />

            {/** Grid */}
            <div className="tab-content">
              <div
                className="tab-pane fade active show"
                id="liton_product_grid"
              >
                <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                  <div className="row">
                    {(!properties || properties.length === 0) && !loading && (
                      <div className="col-xl-12">
                        <div className={styles.unavailable}>
                          <img
                            src={unavailableImage}
                            alt="No properties available"
                          />
                        </div>
                      </div>
                    )}
                    {loading &&
                      [1, 2].map((item) => (
                        <div className="col-xl-6 col-sm-6 col-12">
                          <Skeleton variant="rectangular" width="100%">
                            <PropertyCard property={propertyData} />
                          </Skeleton>
                        </div>
                      ))}
                    {properties &&
                      properties.length > 0 &&
                      !loading &&
                      sortedList
                        .slice(
                          (page - 1) * itemsPerPage,
                          (page - 1) * itemsPerPage + itemsPerPage
                        )
                        .map((prop) => (
                          <div className="col-xl-6 col-sm-6 col-12">
                            <PropertyCard property={prop} />
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>

            {/** Pagination */}
            {(paginationProperties && paginationProperties).length > 0 && (
              <Stack
                spacing={2}
                sx={{ marginBottom: "30px", alignItems: "center" }}
              >
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  size={"large"}
                  variant="outlined"
                  color="standard"
                />
              </Stack>
            )}
          </div>

          {/** Amenities Filter */}
          <div className="col-lg-4">
            <ProductSidebar>
              <h3 className="mb-10 animated fadeIn">Advance Information</h3>
              {/* <label className="mb-30"><small>filter results</small></label> */}
              <FilterWidget
                onStateChange={handleFilterChange}
                properties={properties}
              />
            </ProductSidebar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyGrid;
