import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import SideNav, { DrawerHeader } from '../SideNav/SIdeNav2';
import { IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import List from '@mui/material/List';


const drawerWidth = 245;

// const openedMixin = (theme: Theme): CSSObject => ({
//   width: drawerWidth,
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: 'hidden',
// });

// const closedMixin = (theme: Theme): CSSObject => ({
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: 'hidden',
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up('sm')]: {
//     width: `calc(${theme.spacing(9)} + 1px)`,
//   },
// });

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-start',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     width: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: 'nowrap',
//     boxSizing: 'border-box',
//     ...(open && {
//       ...openedMixin(theme),
//       '& .MuiDrawer-paper': openedMixin(theme),
//     }),
//     ...(!open && {
//       ...closedMixin(theme),
//       '& .MuiDrawer-paper': closedMixin(theme),
//     }),
//   }),
// );

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export interface DashboardLayoutProps{
    children?: React.ReactNode | React.ReactNode[];
    backgroundColor?: string;
}
export default function DashboardLayout({ children, backgroundColor = 'white' }: DashboardLayoutProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const openDrawer = ()=>{
    setOpen(true);
  }
  const handleCloseDrawer = ()=>{
    setOpen(false);
  }

  const header = (
    <AppBar position='fixed' open={open}>
      <Toolbar>
        <IconButton
          onClick={openDrawer}
          color="inherit" aria-label="open drawer" edge="start"
          sx={{
            marginRight: '36px', ...(open && { display: 'none' })
          }}
        >
          <MenuIcon/>
        </IconButton>
        <Typography variant="h6" noWrap component="div"
        >
          Access Homes Admin Dashboard
        </Typography>
      </Toolbar>
    </AppBar>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      { header }
      {/* { drawer } */}
      <SideNav extend={open} onCloseDrawer={handleCloseDrawer}/>
        <Box component="main" 
            sx={{ 
                display: 'flex',  flexDirection: 'column', justifyContent: 'space-between',
                flexGrow: 1, p: 3, minHeight: '100vh',
                backgroundColor: backgroundColor 
            }}
        >
        <DrawerHeader />
        { children }
      </Box>
    </Box>
  );
}
// d-flex flex-column justify-content-between min-vh-100