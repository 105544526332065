import React, { useContext, useEffect, useState } from "react";
import LoadingContext from "../../contexts/LoadingContext";
import styles from "./marketpdetails.module.scss";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import { Button } from "@mui/material";
import ToastContext from "../../contexts/ToastContext";
import { useHistory } from "react-router";

interface PaymentModalProps {
  price: number;
  close: () => void
}

const CustomRadio = styled(Radio)<RadioProps>(({ theme }) => ({
  "&.Mui-checked": {
    color: "var(--ltn__secondary-color)",
  },
}));

const PaymentModal: React.FC<PaymentModalProps> = ({ price, close }) => {
  const { setLoading } = useContext(LoadingContext);
  const {openSuccess, openError} = useContext(ToastContext)
  const [isCard, setIsCard] = useState(false);
  const [isWallet, setIsWallet] = useState(false);
  const [priceN, setPriceN] = useState(price)
  const [pin, setPin] = useState('')
  const [qty, setQty] = useState(Number('1'))
  const token = localStorage.getItem('token')
  const history = useHistory()

  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setQty(Number(value))
    setPriceN(price * qty)
  }

  const handleCard = () => {
    setIsCard(true);
    setIsWallet(false);
  };

  const handleWallet = () => {
    setIsCard(false);
    setIsWallet(true);
  };

  const handlePayment = () => {
    if(!pin){
        openError('Please enter Pin')
        return
    }
    if(!token){
        openError('Please sign in to make payment')
        return
    }
    close()
    setLoading(true)
    setTimeout(() => {
        setLoading(false)
        openSuccess('Payment Successful')
        history.goBack()
    }, 5000)
  }

  useEffect(() => {
    if(qty > 0){
        setPriceN(price * qty)
    }
  }, [qty])

  return (
    <div className={styles.modal}>
      <div className={styles.cancel} onClick={() => close()}>
        <div className="icon-cancel"></div>
      </div>

      <div className={styles.options}>
        <div className={styles.cardArea} onClick={handleCard}>
          <p>BY CARD</p>
          <CustomRadio checked={isCard} onClick={handleCard} />
        </div>
        <div className={styles.cardArea} onClick={handleWallet}>
          <p>BY WALLET</p>
          <CustomRadio checked={isWallet} onClick={handleWallet} />
        </div>
      </div>

      {isCard && (
        <div style={{ height: "13em" }}>
          <span>Currently Unavailable</span>
        </div>
      )}

      {isWallet && (
        <>
         <div className={styles.options}>
          <div className={styles.inputContainer}>
            <span>Price</span>
            <input className={styles.input} value={priceN > 0 ? priceN : price} disabled/>
          </div>
          <div className={styles.inputContainer}>
            <span>Quantity</span>
            <input className={styles.input} value={(qty)} onChange={handleQtyChange}/>
          </div>
        </div>
         <div className={styles.options}>
          <div className={styles.inputContainer}>
            <span>Enter Pin</span>
            <input className={styles.input} value={pin} onChange={(e) => setPin(e.target.value)} />
          </div>
          <Button
            type="button"
            variant="contained"
            sx={{
              textAlign: "center",
              backgroundColor: "var(--ltn__secondary-color)",
              "&:hover": {
                backgroundColor: "var(--ltn__paragraph-color)",
              },
              height: "3em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: 'flex-end',
              width: '30%'
            }}
            onClick={() => handlePayment()}
          >
            PAY
          </Button>
        </div>
        </>
      )}
    </div>
  );
};

export default PaymentModal;
