export enum FundingType{
    Card,
    'Direct Debit',
    'Virtual Account',
}

export enum CardIssuer{
    Visa,
    Mastercard,
    Verve
}