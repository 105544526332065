import React, { useEffect, useState } from "react";
import { Transaction } from "../../types/Transaction";
import styles from "./transTable.module.scss";
import { Button } from "@mui/material";
import { toDateString } from "../../utils/generalUtils";
import { GetBillPaymentDescription } from "../../swr/wallet";
import ToastContext from "../../contexts/ToastContext";

type TransactionModalProps = {
  closeModal: () => void;
  transaction: Transaction | null;
  token: string;
};

const TransactionModal: React.FC<TransactionModalProps> = ({
  closeModal,
  transaction,
  token,
}) => {
  const { openSuccess } = React.useContext(ToastContext);
  const [transactionDescription, setTransactionDescription] = useState("");
  const [discoToken, setToken] = useState("");

  const getToken = (param: string) => {
    const token = param.split("Token: ")[1]?.slice(0, 21).split('').join('');
    token && setToken(token)
  }

  const getAndCopyToken = (param: string) => {
    navigator.clipboard
      .writeText(param)
      .then(() => {
        param && openSuccess("Token Copied");
      })
      .catch((err) => {
        console.log('');
      });
  };
  
  const getBillPaymentHistory = async () => {
    if (transaction?.transactionType === "BillPayment") {
      const response = await GetBillPaymentDescription({
        token,
        paymentReference: transaction?.messageID!,
      });
      if (response && response.packageSlug) {
        setTransactionDescription(response.packageSlug);
      }
    }
  };

  useEffect(() => {
    getBillPaymentHistory();
    if (transaction && transaction.description) {
      getToken(transaction.description);
    }
  }, [transactionDescription]);

  return (
    <div className={styles.modalContainer}>
      <h4 className={styles.headingTransaction}>Transaction Summary</h4>
      <div className={styles.inputContainer}>
        <div className={styles.inputAndHeader}>
          <span className={styles.inputHeading}> Amount </span>
          <input
            disabled
            className={styles.eachInput}
            value={transaction?.amount}
          />
        </div>
        <div className={styles.inputAndHeader}>
          <span className={styles.inputHeading}> Date </span>
          <input
            disabled
            className={styles.eachInput}
            value={toDateString(transaction?.transactionDate!)}
          />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputAndHeader}>
          <span className={styles.inputHeading}> Type </span>
          <input
            disabled
            className={styles.eachInput}
            value={transaction?.transactionType}
          />
        </div>
        <div className={styles.inputAndHeader}>
          <span className={styles.inputHeading}> Description </span>
          <input
            disabled
            className={styles.eachInput}
            value={
              transaction?.description
                ? transaction.description
                : transactionDescription
                ? transactionDescription
                : ""
            }
          />
        </div>
      </div>

      <div className={styles.buttonContainer}>
        {discoToken && (
          <Button
            type="button"
            variant="contained"
            sx={{
              textAlign: "center",
              backgroundColor: "var(--ltn__secondary-color)",
              "&:hover": {
                backgroundColor: "var(--ltn__paragraph-color)",
              },
              height: "3em",
              display: "flex",
              alignItems: "center",
              marginTop: "0.5em",
              justifyContent: "center",
              alignSelf: "flex-start",
            }}
            onClick={() => getAndCopyToken(discoToken)}
          >
            Copy Token
          </Button>
        )}

        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__secondary-color)",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
            alignSelf: "flex-start",
          }}
          onClick={() => closeModal()}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default TransactionModal;
