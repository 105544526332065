import { createUseStyles } from "react-jss";
import { breakpoints } from "../../utils/breakpoints";

const useStyles = createUseStyles({

    section:{
        paddingTop: 115,
        paddingBottom: 70
    },

    container: {
        paddingRight: '15px',
        paddingLeft: '15px',
    },

    row:{
        marginRight: '-15px',
        marginLeft: '-15px',
    },

    col:{},

    titleArea:{
        marginBottom: '50px'
    },

    subtitle:{
        fontWeight: 600,
        fontFamily: 'Nunito Sans',
        color: 'var(--ltn_secondary-color)',
    },

    sectionTitle:{
        fontSize: '44px',
        fontWeight: 700,
        lineHeight: 1.3,
    },

    // Responsive Layout
    [breakpoints.lg('up')]:{
        container:{
            maxWidth: '960px'
        }
    },

    [breakpoints.xl('up')]:{
        container:{
            maxWidth: '1200px'
        }
    },

    [breakpoints.lg()]:{
        sectionTitle:{
            fontSize: '30px'
        },
    },

    [breakpoints.xl()]:{
        col:{
            flex: '0 0 auto',
            width: '25%'
        },
    }
    
})

export default useStyles;