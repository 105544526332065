import React from 'react';
import Dialog from '@mui/material/Dialog';
import successSvg from '../../../assets/done.svg';
import styles from './paymentSuccess.module.scss';
import { useHistory } from 'react-router-dom';

export interface PaymentSuccessDialogProps{
    open?: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
}

const PaymentSuccessDialog: React.FC<PaymentSuccessDialogProps> = ({
    open = false, onClose,
})=>{

    const history = useHistory();
    
    const handleGoBackHome = ()=>{
        history.push('/');
    }

    return(
        <Dialog
            //maxWidth='md'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx:{ width: '100%'},
            }}
        >
           <div className={styles.content}>
                <div className={styles.image}>
                    <img src={successSvg} alt="Payment successful" />
                </div>
                <div className={styles.text}>
                    <h4>Your payment was successful</h4>
                    <p>
                        Thank you for your payment. 
                        An automated payment receipt will be sent to your registered email
                    </p>
                </div>
                <div className={styles.btn}>
                    <button onClick={handleGoBackHome}>
                        Back to Home
                    </button>
                </div>
           </div>
        
        </Dialog>
    )
}

export default PaymentSuccessDialog;