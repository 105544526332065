import React from 'react';
import styles from './pinInput.module.scss';

export interface PinInputState{
    pin1: string;
    pin2: string;
    pin3: string;
    pin4: string;
}

export interface PinInputProps{
    onInputChange?: (value: PinInputState)=> any;
}

const PinInput: React.FC<PinInputProps> = ({ onInputChange }) =>{

    const pin1Ref = React.createRef<HTMLInputElement>();
    const pin2Ref = React.createRef<HTMLInputElement>();
    const pin3Ref = React.createRef<HTMLInputElement>();
    const pin4Ref = React.createRef<HTMLInputElement>();

    let initState: PinInputState = {
        pin1: '',
        pin2: '',
        pin3: '',
        pin4: ''
    }

    const [state, setState] = React.useState<PinInputState>(initState);

    React.useEffect(()=>{
        pin1Ref.current?.focus()
    },[])


    const forwardAutoFocus = (name: string)=>{
        switch (name) {
            case 'pin1':
                if (state[name] === '') {
                    pin2Ref.current?.focus()
                }
                break;
            
            case 'pin2':
                if (state[name] === '') {
                    pin3Ref.current?.focus()
                }
                break;

            case 'pin3':
                if (state[name] === '') {
                    pin4Ref.current?.focus()
                }
                break;
        
            default:
                break;
        }
    }

    const backwardAutoFocus = (name: string)=>{
        switch (name) {
            case 'pin2':
                pin1Ref.current?.focus();
                break;

            case 'pin3':
                pin2Ref.current?.focus();
                break;

            case 'pin4':
                pin3Ref.current?.focus();
                break;
        
            default:
                break;
        }
    }

    const hanldeKeyPress = (keyValue: string, name: string)=>{
     
        if (keyValue === 'Backspace') {
            backwardAutoFocus(name);
        }
    }

    const handlePinChange = (pinInput: string, name: string)=>{
        setState((prevState)=>({
            ...prevState,
            [name]: pinInput
        }));
        onInputChange && onInputChange({...state, [name]: pinInput})
        forwardAutoFocus(name);
    }

    return(
        <div className={styles.container}>
            <input 
                ref={pin1Ref}
                type="password" 
                maxLength={1}
                onKeyUp={(event)=> hanldeKeyPress(event.key, 'pin1')}
                onChange={(e)=> handlePinChange(e.target.value, 'pin1')} 
                autoComplete="off"
            />
             <input 
                ref={pin2Ref}
                type="password" 
                maxLength={1}
                onKeyUp={(event)=> hanldeKeyPress(event.key, 'pin2')}
                onChange={(e)=> handlePinChange(e.target.value, 'pin2')}  
                autoComplete="off" 
            />
            <input
                ref={pin3Ref} 
                type="password" 
                maxLength={1}
                onKeyUp={(event)=> hanldeKeyPress(event.key, 'pin3')}
                onChange={(e)=> handlePinChange(e.target.value, 'pin3')} 
                autoComplete="off"  
            />
            <input
                ref={pin4Ref} 
                type="password" 
                maxLength={1}
                onKeyUp={(event)=> hanldeKeyPress(event.key, 'pin4')}
                onChange={(e)=> handlePinChange(e.target.value, 'pin4')}  
                autoComplete="off" 
            />
        </div>
    )
}

export default PinInput;