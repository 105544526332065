import image21 from '../../img/icons/icon-img/24.png';
import image22 from '../../img/icons/icon-img/25.png';
import image23 from '../../img/icons/icon-img/26.png';

const FeatureArea = () => {
    return (
        // <!-- FEATURE AREA START ( Feature - 6) -->
        <div className="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Services</h6>
                            <h1 className="section-title">Our Main Focus</h1>
                        </div>
                    </div>
                </div>
                <div className="row ltn__custom-gutter--- justify-content-center">
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                                {/* <!-- <span><i className="flaticon-house"></i></span> --> */}
                                <img src={image21} alt="#" />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <a href="/properties">Buy a home</a>
                                </h3>
                                <p>Homes for sale available on the website, we can match you with a house you will want to call home.</p>
                                <a className="ltn__service-btn" href="/properties">
                                    Find A Home <i className="flaticon-right-arrow"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                                {/* <!-- <span><i className="flaticon-house-3"></i></span> --> */}
                                <img src={image22} alt="#" />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <a href="/properties">Rent a home</a>
                                </h3>
                                <p>Properties have been verified for customer’s comfort. You can pay your rent with convenience.</p>
                                <a className="ltn__service-btn" href="/properties">
                                    Find A Home <i className="flaticon-right-arrow"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                                {/* <!-- <span><i className="flaticon-deal-1"></i></span> --> */}
                                <img src={image23} alt="#" />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <a href="/save-for-rent">Save Towards Rent</a>
                                </h3>
                                <p>Save up for your next rent by setting up a target and a standing instruction to sweep out funds from your wallet at intervals.</p>
                                <a className="ltn__service-btn" href="/save-for-rent">
                                    Save For Rent <i className="flaticon-right-arrow"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FEATURE AREA END --> */}
        </div>
    );
};

export default FeatureArea;
