import React from 'react';
import Layout from '../components/Layout';
import NavHeader from '../components/NavHeader';
import ScrollToTop from '../components/ScrollToTop';
import Contact from '../sections/Contact';
import GetAQuote from '../sections/GetAQuote';
import GoogleMapArea from '../sections/GoogleMapArea';

const ContactUsPage: React.FC<any> = ()=>{

    return(
        <React.Fragment>
            <ScrollToTop/>
            <Layout>
                <NavHeader
                    pageTitle="Contact Us"
                    routeName="Contact"
                />
                <Contact/>
                {/* <GetAQuote/> */}
                {/* <GoogleMapArea/> */}
            </Layout>
        </React.Fragment>
    )
}

export default ContactUsPage;