import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ProductImageCarousel from '../components/Carousels/ImageCarousel';
import Layout from '../components/Layout';
import LoadingScreen from '../components/LoadingScreen';
import NavHeader from '../components/NavHeader';
import propertyData from '../components/PropertyCard/defaultData';
import ScrollToTop from '../components/ScrollToTop';
import GeneralNotFound from '../sections/NotFound';
import ProductDetail from '../sections/ProductDetail';
import { AppDispatch, RootState } from '../state/store';
import { fetchPropertyById } from '../state/thunks/property';
import { Property } from '../types/Property';

const ProductPage: React.FC<any> = ()=>{

    let params: { id: string } = useParams();

    const dispatch = useDispatch <AppDispatch>();
    const { property, error, updateResponse, loading } = useSelector((state: RootState)=> state.property);
    const [product, setProduct] = useState(undefined as Property | undefined)
    const [localProductLoading, setLocalProductLoading] = useState(false)

    // Fetch properties on page load
    React.useEffect(()=>{
        setLocalProductLoading(true)
        let oldProd = localStorage.getItem('property')
        let oldProduct =  oldProd ? JSON.parse(oldProd) : undefined
        if(oldProduct !== undefined){
            setProduct(oldProduct)
            setLocalProductLoading(false)
            localStorage.removeItem('property')
            window.scrollTo(0, 1000);
        }else{
            setLocalProductLoading(false)
            dispatch(fetchPropertyById({ id: params.id, token: localStorage.getItem('token') || undefined }));
            window.scrollTo(0, 0);
        }

        
        setLocalProductLoading(false)
        
    },[updateResponse]);

    

    return(
        <React.Fragment>
            <ScrollToTop/>
            <Layout>
                <NavHeader
                    pageTitle="Product Details"
                    routeName="Product Details"
                />
                {(loading || localProductLoading) && <LoadingScreen/>}
                {error && <GeneralNotFound title="Property not Found!"/>}
                {!error && !loading && !localProductLoading && <ProductDetail property={product !== undefined ? product : property}/>}
                {/* <ProductDetail property={propertyData}/> */}
            </Layout>
        </React.Fragment>
    )
}

export default ProductPage;