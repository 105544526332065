import React from 'react';
import Carousel from "react-multi-carousel";
import { Property } from '../../../types/Property';
import PropertyCard from '../../PropertyCard';
import "react-multi-carousel/lib/styles.css";
import styles from './product.module.scss';
import unavailableImage from '../../../assets/property_unavailable.png';
import Skeleton from '@mui/material/Skeleton';
import propertyData from '../../PropertyCard/defaultData';

export interface ProductImageCarouselProps{
    properties?: Property[];
    loading?: boolean;
    width?: string | number;
}

const ProductCarousel: React.FC<ProductImageCarouselProps> = ({ properties, width, loading })=>{

    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1200, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    };
    
    return(
        <Carousel responsive={responsive} containerClass={styles.container}
          infinite={true} 
        >
          {
              (!properties || properties.length === 0) && !loading &&
              <div className={styles.unavailable}>
                  <img src={unavailableImage} alt="No properties available" />
              </div>
          }
          {
              loading &&
              [1,2,3].map((item)=>(
                <div className={styles.itemDiv}>
                    <Skeleton variant="rectangular" width="100%" >
                        <PropertyCard property={propertyData}/>
                    </Skeleton>
                </div>
              ))
          }
          {
              (properties && properties.length > 0) && !loading &&
                properties.map((prop, index)=>{
                  if (index < 8) {
                    return(
                      <div className={styles.itemDiv} key={prop.id}>
                          <PropertyCard property={prop}/>
                      </div>
                    )
                  }
                })
          }
        </Carousel>
    )
}

export default ProductCarousel;