import React from "react";
import styles from "./marketmeta.module.scss";
import { PropertyStatus } from "../../types/Property";
import { PropertyType } from "../../types/propertyType";
import { formatDate, simulateStatus } from "../../utils/propertyUtils";

export interface PropertyMetadataProps {
  category?: string;
  status?: string | PropertyStatus;
  date?: Date;
  comments?: string | number;
  id?: number;
  verified?: boolean;
  manufacturer?: string;
}

const Index: React.FC<PropertyMetadataProps> = ({
  category = "Residential",
  status = "Rent",
  comments = 0,
  date = new Date(),
  id,
  verified,
  manufacturer,
}) => {
  return (
    <div className="ltn__blog-meta">
      <ul>
        <li className="ltn__blog-category">
          <a href="#">{category}</a>
        </li>
        <li className="ltn__blog-category">
          <a className="bg-orange" href="#">
            {manufacturer}
          </a>
        </li>
        <li className="ltn__blog-category">
          <a className={styles["green"]} href="#">
            {"verified"}
          </a>
        </li>
        <li className="ltn__blog-date">
          <i className="far fa-calendar-alt"></i>
          {formatDate(date)}
        </li>
        {/* <li>
                    <a href="#"><i className="far fa-comments"></i>{`${comments} Comments`}</a>
                </li> */}
      </ul>
    </div>
  );
};

export default Index;
