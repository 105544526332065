import React, { useState } from "react";
import {
  PaymentFrequency,
  SavingsPurpose,
  SavingsTowardsRentRequest,
} from "../../../types/Savings";
import Dropdown from "../../FormElements/Dropdown";
import TextArea from "../../FormElements/TextArea";
import TextInput from "../../FormElements/TextInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { withStyles } from "@mui/styles";
import styles from "./saveForRent.module.scss";
import { useFundingSource } from "../../../swr/funding";
import jwt, { Jwt, JwtPayload } from "jsonwebtoken";
import Skeleton from "@mui/material/Skeleton";
import CardPaymentDisplay from "../../CardPaymentDisplay";
import { fetchImage, fetchLastDigits } from "../../../utils/cardUtils";
import { FundingSource } from "../../../types/FundingSource";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import LoadingContext from "../../../contexts/LoadingContext";
import { isEmpty } from "../../../utils/formUtils";
import ToastContext from "../../../contexts/ToastContext";
import { generatePaymentSchedule } from "../../../utils/fundingSourceUtils";
import BankTransferDisplay from "../../BankTransferDisplay";
import { FundingType } from "../../../types/FundingType";
import {
  getWalletId,
  toDateStringRestriction,
} from "../../../utils/generalUtils";
import { CreateSavings } from "../../../swr/saveForRent";
import { useHistory } from "react-router";
import { ReactComponent as EmpChk } from "../../../assets/emptyCheck.svg";
import { ReactComponent as FullChk } from "../../../assets/filledCheck.svg";

export interface SaveForRentFormProps {
  onSubmit?: (
    state: SavingsTowardsRentRequest,
    cb: any,
    event?: React.FormEvent
  ) => void;
}

interface ErrorFormState {
  targetedAmount: boolean;
  savingsAmount: boolean;
  paymentStartDate: boolean;
  name: boolean;
}

const SaveForRentForm: React.FC<SaveForRentFormProps> = ({ onSubmit }) => {
  // Contexts
  const { setLoading } = React.useContext(LoadingContext);
  const { openError, openSuccess } = React.useContext(ToastContext);
  const [checked, setChecked] = useState(false);
  const history = useHistory();

  // Banks (For Debug)
  const banks = {
    "044": "Access Bank",
    "058": "Guaranty Trust Bank",
    "035": "WEMA Bank Plc",
    "011": "First Bank of Nigeria Plc",
    "039": "Stanbic IBTC Plc",
    "232": "Sterling Bank Plc",
  };

  // Refs
  const formRef = React.createRef<HTMLFormElement>();

  // Token details
  let uid = "";
  let email = "";
  const token = localStorage.getItem("token") as string;
  const walletid = getWalletId(token);

  if (token) {
    let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
    if (decodedToken) {
      const { payload } = decodedToken as JwtPayload;
      uid = payload.uid;
      email = payload.email;
    }
  }
  const { data, isLoading, isError, mutate } = useFundingSource(uid);

  let frequencyOptions = Object.values(PaymentFrequency).filter(
    (item) => typeof item === "string"
  );
  let purposeOfSavings = Object.values(SavingsPurpose).filter(
    (item) => typeof item === "string"
  );
  // State
  const [chosenCard, setChosenCard] = React.useState<number>();
  const [chosenBank, setChosenBank] = React.useState<number>();
  const [chosenFundingSource, setFundingSource] =
    React.useState<FundingSource>();
  const [method, setMethod] = React.useState<"card" | "account">("card");

  let initErrorState: ErrorFormState = {
    targetedAmount: false,
    savingsAmount: false,
    paymentStartDate: false,
    name: false,
  };
  const [errorState, setErrorState] =
    React.useState<ErrorFormState>(initErrorState);

  // Handlers
  const handleCardSelect = (id?: number) => {
    setChosenCard(id);
    let source = data?.data?.find((item) => item.id === id);
    setFundingSource(source);
  };

  const cancelCardSelect = () => {
    setChosenCard(undefined);
    setFundingSource(undefined);
  };

  const handleCardMethodSelect = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (checked) {
      setMethod("card");
    }
  };
  const handleAccountMethodSelect = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (checked) {
      setMethod("account");
    }
  };

  const handleBankSelect = (id?: number) => {
    setChosenBank(id);
    let source = data?.data?.find((item) => item.id === id);
    setFundingSource(source);
  };

  const cancelBankSelect = () => {
    setChosenBank(undefined);
    setFundingSource(undefined);
  };

  const unhighlight = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorState((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
  };

  const validateForm = (fdata: FormData): "passed" | "failed" => {
    let result: "passed" | "failed" = "passed";

    // Check if any field is empty
    for (const pairs of fdata.entries()) {
      //console.log(pairs);
      if (isEmpty(pairs[1].toString())) {
        setErrorState((prevState) => ({ ...prevState, [pairs[0]]: true }));
        result = "failed";
      }
    }

    // Check for funding source -- depracated
    // if (!chosenFundingSource) {
    //     openError('Please choose a source of payment');
    //     result = 'failed';
    // }

    return result;
  };

  const setDescriptionGeneralToNull = (option: any | string) => {
    if (option === "GENERAL") {
      return "";
    } else {
      return option;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!checked) {
      openError("Please check the consent box");
      return;
    }
    setLoading(true);
    if (formRef.current) {
      const fdata = new FormData(formRef.current);

      if (validateForm(fdata) === "passed") {
        // Some formatted values
        let pFreq =
          PaymentFrequency[
            fdata
              .get("paymentFrequency")
              ?.toString() as keyof typeof PaymentFrequency
          ];
        let tAmount = fdata.get("targetedAmount")?.toString().replace(/,/g, "");
        let sAmount = fdata.get("savingsAmount")?.toString().replace(/,/g, "");
        let startDate = fdata.get("paymentStartDate")?.toString();

        let paymentScheduleArray = generatePaymentSchedule({
          targetedAmount: parseFloat(tAmount || "0"),
          savingsAmount: parseFloat(sAmount || "0"),
          paymentFrequency: pFreq,
          startDate: startDate ? new Date(startDate) : new Date(),
          creditAccount: walletid,
        });

        let request: SavingsTowardsRentRequest = {
          name: fdata.get("name")?.toString() || "",
          description:
            setDescriptionGeneralToNull(fdata.get("description")?.toString()) ||
            "",
          userId: uid,
          userEmail: email,
          fundingSourceId: chosenFundingSource?.id || 0,
          paymentFrequency: pFreq,
          creditAccount: walletid,
          targetedAmount: parseFloat(tAmount || "0"),
          savingsAmount: parseFloat(sAmount || "0"),
          paymentStartDate: startDate ? new Date(startDate) : new Date(),
          paymentScheduleArray: paymentScheduleArray,
        };

        if(request.targetedAmount % request.savingsAmount !== 0){
            openError('Target amount must be an exact multiple of saving amount')
            setLoading(false)
            return
        }

        onSubmit && onSubmit(request, e);
      } else {
        openError("Please fill all fields");
        setLoading(false);
        return;
      }
    }
  };

  // Custom Components
  const MyRadio = withStyles({
    root: {
      "&$checked": {
        color: "var(--ltn__secondary-color)",
      },
    },
    checked: {},
  })(Radio);
  const MyFormControlLabel = withStyles({
    root: {
      fontSize: "0.5rem",
    },
    label: {},
  })(FormControlLabel);

  // Deprecated element
  const fundingOptionsEL = (
    <div className="col-md-6">
      <label>Choose your method of payment</label>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        sx={{
          "& span": {
            fontSize: "0.9rem",
          },
        }}
        value={method}
        // onChange={handleChange}
      >
        <FormControlLabel
          value="card"
          control={<MyRadio />}
          label="Card Payment"
          onChange={handleCardMethodSelect}
        />
        <FormControlLabel
          value="account"
          control={<MyRadio />}
          label="Direct Debit"
          onChange={handleAccountMethodSelect}
        />
      </RadioGroup>

      {/** Payment Methods */}
      {/** Card */}
      {!chosenCard && method === "card" && (
        <Fade in>
          <div className={styles.cardBody}>
            {isLoading && (
              <Skeleton variant="rectangular" width="100%">
                <CardPaymentDisplay />
              </Skeleton>
            )}
            {data?.data?.map((item, index) => {
              if (item.fundingType === FundingType.Card) {
                return (
                  <CardPaymentDisplay
                    issuerToken={item.issuerToken}
                    holderName={"Ikemefuna Ezechukwu"}
                    cardId={item.id}
                    onSelect={handleCardSelect}
                    key={`cdo${index}`}
                  />
                );
              }
            })}
          </div>
        </Fade>
      )}

      {/** Selected Card */}
      {chosenCard && method === "card" && (
        <div className={styles.selectedPayment}>
          <div className={styles.cardLogo}>
            {/** Logo */}
            <img
              src={fetchImage(chosenFundingSource?.issuerToken || "10")}
              alt="card logo"
            />

            {/** Number and Name */}
            <div className={styles.nameNumber}>
              <p>
                **** {fetchLastDigits(chosenFundingSource?.issuerToken || "10")}
              </p>
            </div>
          </div>
          <div>
            <IconButton onClick={cancelCardSelect}>
              <CloseIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </div>
        </div>
      )}

      {/** Account Number */}
      {!chosenBank && method === "account" && (
        <Fade in>
          <div className={styles.cardBody}>
            {isLoading && (
              <Skeleton variant="rectangular" width="100%">
                <BankTransferDisplay />
              </Skeleton>
            )}
            {data?.data?.map((item, index) => {
              if (item.fundingType === FundingType["Direct Debit"]) {
                return (
                  <BankTransferDisplay
                    accountName={item.accountName}
                    accountNumber={item.accountNumber}
                    bankCode={item.bankCode}
                    debitId={item.id}
                    onSelect={handleBankSelect}
                    key={`bdo${index}`}
                  />
                );
              }
            })}
          </div>
        </Fade>
      )}

      {/** Selected Account Number */}
      {chosenBank && method === "account" && (
        <div className={styles.selectedPayment}>
          <div className={styles.cardLogo}>
            <h6>
              {chosenFundingSource?.bankCode &&
                banks[chosenFundingSource.bankCode as keyof typeof banks]}
            </h6>

            {/** Number and Name */}
            <div className={styles.nameNumber}>
              <p>{chosenFundingSource?.accountNumber}</p>
              <p>{chosenFundingSource?.accountName}</p>
            </div>
          </div>
          <div>
            <IconButton onClick={cancelBankSelect}>
              <CloseIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );

  const isResponsive = window.innerWidth <= 768;

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <label>Title</label>
          <TextInput
            name="name"
            hideDefaultAdornment
            error={errorState.name}
            required={errorState.name}
            onChange={unhighlight}
          />
        </div>
        <div className="col-md-6">
          <label>Overall Target Amount</label>
          <TextInput
            name="targetedAmount"
            hideDefaultAdornment
            variant="amount"
            error={errorState.targetedAmount}
            required={errorState.targetedAmount}
            onChange={unhighlight}
          />
        </div>
        {/* <div className="col-md-6">
                    <label>Wallet Id</label>
                    <TextInput 
                        name="creditAccount"
                        hideDefaultAdornment
                        error={errorState.targetedAmount}
                        required={errorState.targetedAmount}
                        onChange={unhighlight}
                    />
                </div> */}
        <div className="col-md-6">
          <label>What's this for ?</label>
          <Dropdown
            placeholder=""
            name="description"
            options={purposeOfSavings}
            selectProps={{ required: true }}
          />
          {/* <TextArea 
                        name="description"
                    /> */}
        </div>
        <div className="col-md-6">
          <label>How frequent do you want to make paymenyts ?</label>
          <Dropdown
            placeholder=""
            name="paymentFrequency"
            options={frequencyOptions}
            selectProps={{ required: true }}
          />
        </div>
        <div className="col-md-6">
          <label>How much do you want saved each period ?</label>
          <TextInput
            name="savingsAmount"
            hideDefaultAdornment
            variant="amount"
            error={errorState.savingsAmount}
            required={errorState.savingsAmount}
            onChange={unhighlight}
          />
        </div>
        <div className="col-md-6">
          <label>When do you want to start ?</label>
          <TextInput
            name="paymentStartDate"
            hideDefaultAdornment
            type="date"
            min={toDateStringRestriction(new Date())}
            className="mb-30"
            error={errorState.paymentStartDate}
            required={errorState.paymentStartDate}
            onChange={unhighlight}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: isResponsive ? "100%" : "100%",
          height: "3em",
          marginBottom: isResponsive ? "5em" : "2em",
          alignItems: isResponsive ? "flex-start" : "center",
          alignSelf: "center",
          justifyContent: isResponsive ? "space-between" : "flex-start",
        }}
      >
        {checked ? (
          <FullChk
            onClick={() => setChecked(!checked)}
            style={{ fill: "#77c720", width: "50", height: "30" }}
          />
        ) : (
          // <img src={fullChk} onClick={()=> setChecked(!checked)} className='colored' style={{fill: 'green'}}/>
          <EmpChk
            onClick={() => setChecked(!checked)}
            style={{ fill: "#77c720", width: "50", height: "30" }}
          />
          // <img src={empChk} onClick={()=> setChecked(!checked)} style={{fill: 'green'}}/>
        )}
        <span
          style={
            {
              // fontSize: isResponsive ? '8' : 'auto'
            }
          }
        >
          I accept that my wallet account should be debited on due date for
          savings towards my rent
        </span>
      </div>

      <button
        className="btn theme-btn-1 btn-effect-1 text-uppercase"
        type="submit"
      >
        Submit
      </button>
    </form>
  );
};

export default SaveForRentForm;
