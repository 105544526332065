import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface UpdateProductDialogProps{
    open?: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
    children?: React.ReactNode | React.ReactNode[];
    title?: string;
}

const UpdateProductDialog: React.FC<UpdateProductDialogProps> = ({
    open = false, onClose, children, title
})=>{

    return(
        <Dialog
            //fullScreen={fullScreen}
            sx={{ zIndex: 1000 }}
            maxWidth= 'lg'
            PaperProps={{
                sx: {
                    width: '100%',
                    height: '100%'
                }
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="update-dialog-dialog-title"
            aria-describedby="update-dialog-description"
        >
            {
                title &&
                <DialogTitle id="alert-dialog-title">
                    { title }
                </DialogTitle>
            }
            <DialogContent sx={{ padding: 0}}>
                { children }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        
        </Dialog>
    )
}

export default UpdateProductDialog;