import React from 'react';
import { PaymentFrequency, SavingsTowardsRent, SavingsTowardsRentRequest } from '../../types/Savings';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './userPayment.module.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { formatAmount } from '../../utils/propertyUtils';
import Grid from '@mui/material/Grid';
import ScheduleOverview from '../../components/ScheduleOverview';
import Button from '@mui/material/Button';
import { generatePaymentSchedule } from '../../utils/fundingSourceUtils';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { updateSaveForRent } from '../../swr/saveForRent';
import ToastContext from '../../contexts/ToastContext';
import { useSWRConfig } from 'swr';
import LoadingScreen from '../../components/LoadingScreen';

export interface UserPaymentScheduleProps{
    savingPlan?: SavingsTowardsRent;
    onDismiss?: React.MouseEventHandler<HTMLButtonElement>;
}

const UserPaymentSchedule: React.FC<UserPaymentScheduleProps> = ({ 
    savingPlan, onDismiss
 })=>{
    // Variables
    let progress = (savingPlan?.totalPaid || 0)/ (savingPlan?.targetedAmount || 1) * 100;

    // Context
    const { openSuccess, openError } = React.useContext(ToastContext);

    // Hooks
    const { mutate } = useSWRConfig();

    // Refs
    const titleInputRef = React.useRef<any>();
    const descriptionRef = React.useRef<any>();

    // State
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isTitleEdit, setIsTitleEdit] = React.useState<boolean>(false);
    const [isDescriptionEdit, setIsDescriptionEdit] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>(savingPlan?.name || 'Untitled');
    const [description, setDescription] = React.useState<string>(savingPlan?.description || '');

    // Effects
    React.useEffect(()=>{
        if (savingPlan && savingPlan.name) {
            setTitle(savingPlan.name)
        }
        if (savingPlan && savingPlan.description) {
            setDescription(savingPlan.description)
        }
    }, [savingPlan]);

    // Handlers
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setTitle(e.target.value);
    }
    const handleTitleEdit = ()=>{
        setIsTitleEdit(true);
    }
    const handleTitleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>)=>{
       if (e.key === 'Enter') {
           setIsTitleEdit(false);
       }
    }
    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>{
        setDescription(e.target.value);
    }
    const handleDescriptionEdit = ()=>{
        setIsDescriptionEdit(true);
    }

    // Clickaway listener
    const handleClickOutside = (event: MouseEvent ) => {
        if (titleInputRef.current && !titleInputRef.current.contains(event.target)) {
            setIsTitleEdit(false);
        }
        if (descriptionRef.current && !descriptionRef.current.contains(event.target)) {
            setIsDescriptionEdit(false);
        }
    };

    React.useEffect(()=>{
        document.addEventListener('click', handleClickOutside, true);

        return ()=>{
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, [isTitleEdit])

    // Generate paymentSchedule array
    let paymentScheduleArray = generatePaymentSchedule({
        targetedAmount: savingPlan?.targetedAmount || 0,
        savingsAmount: savingPlan?.savingsAmount || 0,
        paymentFrequency: savingPlan?.paymentFrequency || PaymentFrequency.Monthly,
        startDate: savingPlan?.paymentStartDate ? new Date(savingPlan.paymentStartDate)  : new Date()
    })

    // Save changes
    const handleSaveChanges = async ()=>{
        setLoading(true);
        let token = localStorage.getItem('token') || undefined;
        let request: SavingsTowardsRentRequest = {
            id: savingPlan?.id,
            name: title,
            description: description,
            userId: savingPlan?.userId || '',
            userEmail: savingPlan?.userEmail || '',
            creditAccount: savingPlan?.creditAccount || "",
            fundingSourceId: savingPlan?.fundingSourceId || 0,
            paymentFrequency: savingPlan?.paymentFrequency || 0,
            targetedAmount: savingPlan?.targetedAmount || 0,
            savingsAmount: savingPlan?.savingsAmount || 0,
            paymentStartDate: savingPlan?.paymentStartDate || new Date(),
            paymentScheduleArray: paymentScheduleArray
        }
        
        const { data, error } = await updateSaveForRent(request, token);

        if (data) {
            setLoading(false);
            openSuccess("Changes saved");
            mutate(`/api/SavingsTowardsRent/get-savingstowardsrent?userId=${savingPlan?.userId}`)
        }

        if (error) {
            setLoading(false);
            openError('An error occurred while saving changes');
        }
    
    }

    return(
        <div>
            { loading && <LoadingScreen/> }
            {/** Title */}
            <div className={styles.titleSection}>
                <div className={styles.title}>
                    {
                        !isTitleEdit &&
                        <>
                        <h4>{title}</h4>
                        <Tooltip title="Edit title">
                            <IconButton onClick={handleTitleEdit}>
                                <EditIcon sx={{ fontSize: '12px' }}/>
                            </IconButton>
                        </Tooltip>
                        </>
                    }
                    {
                        isTitleEdit &&
                        <input type="text"
                            ref={titleInputRef} 
                            value={title}
                            onChange={handleTitleChange}
                            onKeyPress={handleTitleEnterKeyPress}
                            autoComplete="off"
                        />
                    }
                </div>
                <Button 
                    onClick={onDismiss}
                    sx={{ color: 'var(--ltn__secondary-color)' }}
                >
                    <i className="fa fa-arrow-left"></i>
                    Back
                </Button>
            </div>

            {/** Description and progress */}
            <p className={styles.description}>
                {
                    isDescriptionEdit &&
                    <textarea cols={30} rows={10}
                        ref={descriptionRef}
                        value={description}
                        onChange={handleDescriptionChange}
                    ></textarea>
                }
                <CircularProgressbar value={progress}
                    text={`${progress}%`}
                    styles={{
                        root:{
                            height: '200px',
                            width: '200px',
                            float: 'right',
                            //backgroundColor: 'blue',
                        },

                        path:{
                            transition: 'stroke-dashoffset 1s ease 0s',
                            stroke: `var(--ltn__secondary-color)`,
                        },

                        text:{
                            fill: 'var(--ltn__secondary-color)'
                        }
                    }}
                />
                {
                    !isDescriptionEdit &&
                    <>
                    { description }
                    <Tooltip title="Edit description">
                        <IconButton onClick={handleDescriptionEdit}>
                            <EditIcon sx={{ fontSize: '12px' }}/>
                        </IconButton>
                    </Tooltip>
                    </>
                }
            </p>

            {/** Amount saved and Target Amount */}
            <Grid container sx={{ padding: '10px'}} spacing={2}>
                <Grid item xs>
                    <Card elevation={3}>
                       <CardContent>
                            <div className={styles.amount}>
                                <h1>₦ {formatAmount(((savingPlan?.totalPaid || 0)).toString())}</h1>
                                <p>Amount saved</p>
                            </div>
                       </CardContent>
                    </Card>
                </Grid>
               
                <Grid item xs>
                    <Card elevation={3} sx={{ height: '100%' }}>
                        <CardContent>
                            <div className={`${styles.amount} ${styles.targetAmount}`}>
                                <h1>₦ {formatAmount((savingPlan?.targetedAmount || 0).toString())}</h1>
                                <p>Target Amount</p>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/** Overview */}
            <div className={styles.overview}>
                <h3>Overview</h3>
                <ScheduleOverview paymentScheduleArray={paymentScheduleArray}/>
            </div>

            {/** Actions */}
            <div className={styles.actions}>
                {/* <Button variant="outlined" color="error">
                    Delete plan
                </Button> */}
                {
                    (savingPlan?.name !== title || savingPlan.description !== description)
                    &&
                    <Button variant="outlined" sx={{ marginLeft: 5 }}
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </Button>
                }
            </div>
        </div>
    )
}

export default UserPaymentSchedule;