import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

//const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api.homebuddy.ng';

export interface TempUser {
  succeeded: boolean
  message: string
  errors: string | null
  data: string
  meta: string | null
}

export interface User {
  id: string
  userName: string
  email: string
  roles: string[] | ''
  isVerified: boolean
  jwToken: string
  expiresIn: number
  refreshToken: string
}

export interface AuthState {
  error: boolean
  status: 'idle' | 'loading' | 'failed'
  tempUser: TempUser
  user: User
  token: string
}

export const authState: AuthState = {
  error: false,
  status: 'idle',
  tempUser: {
    succeeded: false,
    message: '',
    errors: null,
    data: '',
    meta: null,
  },
  user: {
    id: '',
    userName: '',
    email: '',
    roles: '',
    isVerified: false,
    jwToken: '',
    expiresIn: 0,
    refreshToken: '',
  },
  token: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    loadingStart: (state) => {
      state.status = 'loading'
    },
    setError: (state) => {
      state.error = true
      state.status = 'failed'
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTempUser: (state, action: PayloadAction<TempUser>) => {
      state.tempUser = action.payload
      state.status = 'idle'
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
      state.status = 'idle'
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
})

export const { loadingStart, setError, setTempUser, setUser, setToken } =
  authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.auth.user

// export const TransactionsHistory = createAsyncThunk(
//     "TransactionsHistory",
//     async (query:TransactionHistoryRequest, setState:any, { dispatch }) => {
//       try {
//         dispatch(loadingStart);
//         const response = await Axios.post(
//          baseUrl + `/api/Payment/TransactionHistory?PageNumber=${query.pageNumber}&PageSize=${query.pageSize}`,
//              );
//         dispatch(setUser(response.data));
//         console.log(response.data)
//         setTimeout(() => {
//           window.location.href = "/dashboard";
//         }, 2000);
//       } catch (error: any) {
//         console.log(error.response.data.error);
//         dispatch(setError());
//       }
//     }
//   );

const authReducer = authSlice.reducer

export default authReducer
