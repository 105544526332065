import React, { useContext, useEffect, useState } from "react";
import styles from "../ElectricBillForm/wasteBill.module.scss";
import Dropdown, { DropdownOption } from "../../FormElements/Dropdown";
import TextInput from "../../FormElements/TextInput";
import TextArea from "../../FormElements/TextArea";
import {
  BillResponseData,
  CustomerLookUpRequestBody,
  CustomerLookupRequest,
  PackageResponseData,
} from "../../../types/Billings";
import {
  InitiatePayment,
  ProcessPayment,
  customerLookUpApi,
  fetchAllBillersPackages,
  fetchEachBillerPackages,
} from "../../../swr/bills";
import Select from "react-select";
import { getWalletId } from "../../../utils/generalUtils";
import ToastContext from "../../../contexts/ToastContext";
import LoadingContext from "../../../contexts/LoadingContext";
import Modal from "react-modal";
import OtpModal from "./OtpModal";
import { useHistory } from "react-router";
import { Button } from "@mui/material";
import CustomerModal from "./CustomerModal";

interface DiscoBillProps {
  Bills?: BillResponseData[];
  selectedBiller: BillResponseData;
}

const customStyles = {
  content: {
    top: "54%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    //   border: '1px solid white',
    //   borderRadius: "0",
    zIndex: "1",
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
  },
};

const PaidTvForm: React.FC<DiscoBillProps> = ({ Bills, selectedBiller }) => {
  const { openError, openSuccess } = React.useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();

  const formRef = React.createRef<HTMLFormElement>();
  const [billerId, setBillerId] = useState<string | any>("");
  const [subPackage, setSubPackage] = useState<string | any>("");
  const [packages, setPackages] = useState<BillResponseData[]>([]);
  const [allPackages, setAllPackages] = useState<PackageResponseData[]>([]);
  const token = localStorage.getItem("token") as string;
  const walletId = getWalletId(token) as string;
  const [customer, setCustomer] = useState<any>(null);
  const [customerId, setCustomerId] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentRef, setPaymentRef] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [minPayableAmount, setMinPayableAmount] = useState<number | null>(0);

  const openCustomer = () => {
    setCustomerOpen(true);
  };

  const closeCustomer = () => {
    setCustomerOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  type PackagesDataType = PackageResponseData | BillResponseData;
  const mapToDropdowOptions = (data?: PackagesDataType[]) => {
    let result: DropdownOption[] = [];

    if (data) {
      result = data.map((item) => ({
        value: item.id,
        label: item.name,
        slug: item.slug ? item.slug : "",
        amount: item.amount,
      }));
    }

    return result;
  };

  const fetchUtilityPackages = async () => {
    setLoading(true);
    const response = await fetchAllBillersPackages(
      token,
      selectedBiller.id.toString()
    );
    setPackages(response.response_data);
    setLoading(false);
  };

  const fetchUtilityPackage = async (id: string) => {
    // if(formRef.current){
    // const formData = new FormData(formRef.current)
    // const utility = formData.get('utility') as string;
    // setBillerId(utility)
    setLoading(true);
    const response = await fetchEachBillerPackages(token, id);
    setAllPackages(response.response_data);
    setLoading(false);
    // }
  };

  const handleSubPackage = async () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const sub = formData.get("packages") as string;
      setSubPackage(sub);

      // const response = await fetchEachBillerPackages(token, utility)
      // setAllPackages(response.response_data)
    }
  };

  const customerLookUp = async (slug: string, id: string) => {
    setLoading(true);
    const response = await customerLookUpApi(token, {
      customer_id: id,
      product_name: slug,
      biller_slug: billerId.slug,
    });

    if (response.response_code === "00") {
      if (response.response_data && response.response_data.customer) {
        // setMinPayableAmount(response.response_data.minPayableAmount && response.response_data.minPayableAmount);
        setCustomer(response.response_data.customer);
        setLoading(false);
      }
    } else {
      setLoading(false);
      openError(response.response_message);
      return;
    }
    setLoading(false);
  };

  const onAmountFocus = () => {
    if (formRef.current) {

      const formData = new FormData(formRef.current);
      const id = formData.get("customerId") as string;
      if (!id) {
        return;
      }
      setCustomerId(id);
    }
  };

  const initiatePayment = async () => {
    setLoading(true);
    // if (formRef.current) {
    //   const formData = new FormData(formRef.current);
    //   const amt = formData.get("balance") as string;
    //   if (!amt) {
    //     openError("Please fill in amount");
    //     setLoading(false);
    //     return;
    //   }
    //   if(Number(amt) !== subPackage.amount){
    //     openError('Please enter specified amount')
    //     return;
    //   }
    //   setAmount(amt);
    // }
    const response = await InitiatePayment(token, {
      customer_id: customerId,
      account_number: walletId,
    });

    if (response.response_code !== "00") {
      openError(response.response_message);
      setLoading(false);
      return;
    }

    if (response.response_data) {
      setEmail(response.response_data.email);
      setPhone(response.response_data.phone_number);
    }

    if (response.paymentReference) {
      setPaymentRef(response.paymentReference);
    }
    openSuccess(response.response_message);
    setLoading(false);
    openModal();
  };

  const processPayment = async () => {
    setLoading(true);
    const response = await ProcessPayment(token, {
      customer_id: customerId,
      account_number: walletId,
      package_slug: subPackage && subPackage.slug && subPackage.slug,
      amount: subPackage.amount,
      customer_name: customer && customer.customerName && customer.customerName,
      phone_number: phone,
      email,
      payment_reference: paymentRef,
      otp: otp,
    });

    if (response.response_code === "00") {
      openSuccess(response.response_message);
      closeModal();
      history.push("/account#liton_tab_1_6");
    } else {
      openError(response.response_message);
    }
    setLoading(false);
    return;
  };

  const [showAmount, setShowAmount] = useState(false);

  const handleShowAmount = () => {
    if (!subPackage) {
      openError("Please pick package group");
      return;
    }
    if (!billerId) {
      openError("Please pick package");
      return;
    }
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const id = formData.get("customerId") as string;
      if (!id) {
        openError("Please enter decoder(IUC) number");
        return;
      }
      setCustomerId(id);
    }

    setShowAmount(true);
  };

  useEffect(() => {
    setShowAmount(false);
    fetchUtilityPackages();
    if (billerId) {
      fetchUtilityPackage(billerId.value);
    }
  }, [billerId]);

  useEffect(() => {
    if (subPackage && customerId && customerId.length === 10) {
      if (
        customer !== null &&
        customer !== undefined &&
        Object.entries(customer).length !== 0
      ) {
        openCustomer();
      } else {
        customerLookUp(subPackage.slug, customerId);
      }
    }
  }, [billerId, subPackage, customer, customerId]);

  interface ErrorFormState {
    customerId: boolean;
    balance: boolean;
  }

  let initErrorState: ErrorFormState = {
    customerId: false,
    balance: false,
  };

  const [errorState, setErrorState] =
    React.useState<ErrorFormState>(initErrorState);

  const unhighlight = (event: React.ChangeEvent<any>) => {
    setErrorState((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
  };

  return (
    <form
      ref={formRef}
      onSubmit={(e) => e.preventDefault()}
      style={{ marginTop: "2em" }}
    >
      <div className="row">
        <div className="col-md-6">
          <label className={styles.label}>Select TV Package</label>
          {/* <Dropdown
                placeholder=""
                name="utility"
                options={packages ? mapToDropdowOptions(packages) : []}
                selectProps={{ required: true }}
                valueCollector={fetchUtilityPackage}
              /> */}
          <Select
            defaultValue={null}
            options={packages ? mapToDropdowOptions(packages) : []}
            onChange={setBillerId}
          />
          {/* <Dropdown
                placeholder=''
                selectProps={{ required: true }}
                useObjectValues
                optionsObj={mapToDropdowOptions(billData)}
                valueCollector={handleDiscoSelect}
                name='biller'
            /> */}
        </div>
        {
          <div className="col-md-6">
            <label className={styles.label}>Select Pack</label>
            {/* <Dropdown
                    placeholder=''
                    selectProps={{
                        required: true, 
                        disabled: packages.length === 0 
                    }}
                    name='packages'
                    options={allPackages ? mapToDropdowOptions(allPackages) : []}
                    // useObjectValues
                    // optionsObj={mapToDropdowOptions(discoPackages)}
                    valueCollector={handleSubPackage}
                /> */}
            <Select
              defaultValue={null}
              options={allPackages ? mapToDropdowOptions(allPackages) : []}
              onChange={setSubPackage}
              isDisabled={!billerId}
              //   defaultInputValue={!billerId ? "Select Package First" : ''}
            />
            {subPackage && subPackage.amount && (
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                ₦{subPackage.amount}
              </div>
            )}
          </div>
        }

        <div className="col-md-6">
          <div
            style={{
              width: "100%",
              height: "20%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignSelf: "flex-start",
              marginTop: "5%",
            }}
          >
            <label className={styles.label}>Decoder Number</label>
            <span className={styles.label}>
              {customerId && customerId.length === 10
                ? ""
                : "10 digits expected"}
            </span>
          </div>

          <TextInput
            hideDefaultAdornment
            name="customerId"
            error={errorState.customerId}
            onChange={(e) => setCustomerId(e.target.value)}
            required={errorState.customerId}
            className={styles.input1}
          />
        </div>

        {/* <div className="col-md-6" onClick={() => onAmountFocus()}>
          <label className={styles.label}>Amount</label>
          {showAmount ? (
            <TextInput
              hideDefaultAdornment
              name="balance"
              variant="amount"
              error={errorState.balance}
              onChange={unhighlight}
              required={errorState.balance}
              className={styles.input}
              onFocus={() => onAmountFocus}
              // disabled={!customerId || !subPackage || !billerId}
            />
          ) : (
            <Button
              type="button"
              variant="contained"
              sx={{
                textAlign: "center",
                backgroundColor: "var(--ltn__secondary-color)",
                "&:hover": {
                  backgroundColor: "var(--ltn__paragraph-color)",
                },
                // paddingTop: "1.8em",
                height: "3em",
                display: "flex",
                alignItems: "center",
                marginTop: "0.5em",
                justifyContent: "center",
              }}
              onClick={() => handleShowAmount()}
            >
              Enter Amount
            </Button>
          )}
        </div> */}
        <div className="col-md-12">
          <label className={styles.label}>Remarks</label>
          <TextArea
            hideDefaultAdornment
            name="remarks"
            onChange={unhighlight}
          />
        </div>
      </div>
      <button
        className="btn theme-btn-1 btn-effect-1 text-uppercase"
        type="submit"
        onClick={() => initiatePayment()}
        style={{
          zIndex: 0,
        }}
      >
        Pay
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "30%",
            left: "30%",
            right: "30%",
            bottom: "auto",
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            backgroundColor: "whitesmoke",
            height: "50%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Otp Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <OtpModal
          closeModal={closeModal}
          otp={otp}
          setOtp={setOtp}
          processPayment={processPayment}
        />
      </Modal>
      <Modal
        isOpen={customerOpen}
        onRequestClose={closeCustomer}
        style={{
          content: {
            top: "30%",
            left: "30%",
            right: "30%",
            bottom: "auto",
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            backgroundColor: "whitesmoke",
            height: "50%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Otp Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <CustomerModal
          closeModal={closeCustomer}
          customer={customer}
          minPayable={minPayableAmount}
        />
      </Modal>
    </form>
  );
};

export default PaidTvForm;
