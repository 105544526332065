import React, { useContext } from 'react';
import jwt, { Jwt, JwtPayload } from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import SessionContext from '../../contexts/SessionContext';

export interface SessionCheckProps{
    shouldRedirect?: boolean;
}

const SessionCheck: React.FC<SessionCheckProps> = ({ shouldRedirect })=>{

    const history = useHistory();
    const { setIsExpired } = useContext(SessionContext)

    const checkSessionExpiration = ()=>{
        const token = localStorage.getItem('token');
        let today = new Date();
        if (token) {
            
            let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
            if(decodedToken){
                const payload = decodedToken.payload as JwtPayload;
                if (payload.exp){
                    if (payload.exp * 1000 < today.getTime()) {
                        setIsExpired(true);
                        if (shouldRedirect) {
                            history.push('/');
                        }
                    }
                }
            }
        }
    }

    React.useEffect(()=>{
        let myInterval = setInterval(checkSessionExpiration, (15000)); // Check every 15 seconds
        return ()=> clearInterval(myInterval);
    }, [])

    return null
}

export default SessionCheck;