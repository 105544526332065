import React from 'react';
import useStyles from './aminity-jss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";

export interface AmenityCardProps{
    icon?: React.ReactNode;
    title?: string;
    description?: string;
    link?: string;
    index?: number | string;
    maxWidth?: string | number;
}

const AmenityCard: React.FC<AmenityCardProps> = ({

    icon =  <FontAwesomeIcon icon={["fas", "car"]} color={'#FF5A3C'}/>,
    title = 'Parking Space',
    description = 'Enimad minim veniam quis no exercitation ullamco lab',
    link = '#',
    index = 1,
    maxWidth = 'auto'
})=>{

    const styles = useStyles();

    return(
        <div className={styles.card} style={{ maxWidth: maxWidth}}>
            <a href={link}>
                <span className={styles.icon}>{icon}</span>
                <span className={styles.index}>{parseInt(index.toString()) < 10 ? `0${index}` : index}</span>
                <span className={styles.title}>{title}</span>
                <span className={styles.description}>{description}</span>
            </a>
        </div>
    )
}

export default AmenityCard;