import Video from '../../media/3.mp4';
import HeroSearchFilter from '../HeroSearchFilter';
import React from 'react';
import ImageSlider from '../ImageSlider/ImageSlider';
import jp from '../../assets/home1.jpg'
import { useHistory } from 'react-router';

const SliderArea = () => {

    const history = useHistory()
    const imagesForSlider = [
        '../../assets/home1.jpg',
        '../../assets/home1.jpg',
        '../../assets/home1.jpg',
        '../../assets/home1.jpg',
        '../../assets/home1.jpg',
        '../../assets/home1.jpg',
        '../../assets/home1.jpg',
        '../../assets/home1.jpg',
        '../../assets/home1.jpg',
    ]

    // States
    const [rentActive, setRentActive] = React.useState<boolean>(true);
    const [saleActive, setSaleActive] = React.useState<boolean>(false);

    // Handlers
    const handleRentClick = ()=>{
        setSaleActive(false);
        setRentActive(true);
        history.push(`/properties?status=Rent`)
    }
    const handleSaleClick = ()=>{
        setRentActive(false);
        setSaleActive(true);
        history.push('/properties?status=Sale')
    }
    const setStatusQuery = ()=>{
        if (rentActive) {
            return '?status="Rent'
        }
        if (saleActive) {
            return '?status="Sale'
        }
        return ''
    }


    return (
        // <!-- SLIDER AREA START (slider-4) -->
        <div className="ltn__slider-area ltn__slider-4 position-relative  ltn__primary-bg fix">
            {/* <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active"> */}
                {/* <!-- HTML5 VIDEO --> */}
                {/* <video autoPlay muted loop id="myVideo">
                    <source src={Video} type="video/mp4" />
                </video> */}
                


                {/* <!-- YouTube VIDEO --> */}
                <div className="ltn__youtube-video-background">
                <ImageSlider images={imagesForSlider}/>
                {/* <iframe frameBorder="0" height="100%" width="100%"
                  src="https://www.youtube.com/embed/eySTo2GgvoY?playlist=eySTo2GgvoY&loop=1&controls=0&showinfo=0&autoplay=1&mute=1">
                </iframe> */}
                {/* <iframe frameBorder="0" height="100%" width="100%"
                  src="https://www.youtube.com/embed/eySTo2GgvoY?playlist=eySTo2GgvoY&loop=1&controls=0&showinfo=0&autoplay=1&mute=1">
                </iframe> */}
            </div> 

                {/* <!-- ltn__slide-item --> */}
                <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-30" data-bs-bg="img/slider/41.jpg">
                    <div className="ltn__slide-item-inner text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-car-dealer-form">
                                        <div className="section-title-area ltn__section-title-2 text-center">
                                            <h1 className="section-title  text-color-white">
                                                Find Your <span className="ltn__secondary-color-3">Perfect</span> Home
                                            </h1>
                                        </div>
                                        <div className="ltn__car-dealer-form-tab">
                                            <div className="ltn__tab-menu  text-uppercase text-center">
                                                <div className="nav">
                                                    <a className={`${rentActive && 'active show'}`} data-bs-toggle="tab" onClick={handleRentClick}>
                                                        <i className="fas fa-home"></i>Rent Home
                                                    </a>
                                                    <a data-bs-toggle="tab" className={`${saleActive && 'active show'}`} onClick={handleSaleClick}>
                                                        <i className="fas fa-home"></i>Buy Home
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            {/** Filter */}
                                            <HeroSearchFilter rentActive={rentActive} saleActive={saleActive}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            {/* <!-- SLIDER AREA END --> */}
        </div>
    );
};

export default SliderArea;
