import { FundingSource } from './FundingSource'
import { User } from './User'

export enum PaymentFrequency {
  Daily = 0,
  Weekly = 1,
  Biweekly = 2,
  Monthly = 3,
  Quarterly = 4,
}

export enum SavingsPurpose {
  RENT,
  PROPERTY_PURCHASE,
  MONTHLY_BILLS,
  GENERAL,
}

export interface PaymentSchedule {
  debitAmount: number
  creditAccount: string
  currency: number
  debitDate: Date
}

export interface CreateSavingsRequest {
  description: string
  savings_amount: string
  savings_remark: string
}

export interface ReleaseSavingsRequest {
  release_amount: string
  msg_id: string
  hold_id: string
  release_remark: string
}

// export interface ReleaseSavingsRequest{
//   "wallet_id": string;
//   "release_amount": string;
//   "msg_id": string;
//   "hold_id": string;
//   "release_remark": string;
//   "email": string;
//   }

export interface CreateScheduledSavingsRequest {
  name?: string;
  description: string;
  userEmail: string;
  paymentFrequency: number;
  targetedAmount: number;
  savingsAmount: number;
  paymentStartDate: string | Date;
  creditAccount: string;
  currency?: any;
  // token: string;
}

export interface TargetSaving {
  userId: string,
  title: string,
  frequency: number,
  amountPerCycle: number,
  totalAmount: number,
  startDate: string,
  withDrawalDate: string,
  amountSaved: number,
  savingsType: string,
  savings_Id: string,
  idForUpdate: number
}


export interface SavingsTowardsRentRequest {
  id?: number
  name: string
  description: string
  userId: string
  userEmail: string
  fundingSourceId?: number
  paymentFrequency: PaymentFrequency
  creditAccount: string
  targetedAmount: number
  savingsAmount: number
  paymentStartDate: Date
  paymentScheduleArray: PaymentSchedule[]
}

export interface SavingsTowardsRent {
  id: number
  name?: string
  description: string
  userId: string
  userEmail: string
  user?: User
  creditAccount: string
  fundingSourceId: number
  fundingSource?: FundingSource
  paymentFrequency: number
  targetedAmount: number
  savingsAmount: number
  paymentStartDate: Date
  status: number
  recordDate: Date
  lastUpdateDate: Date
  savingsId?: any
  totalPaid: number
}

export interface updateSavingsRequest {
  id?: number
  name: string
  description: string
  userId: string
  userEmail: string
  fundingSourceId?: number
  paymentFrequency: string | undefined
  creditAccount: string
  targetedAmount: number
  savingsAmount: number
  paymentStartDate: Date | string
  paymentScheduleArray: PaymentSchedule[]
}

// export interface getTotalSavingsResponse{
//   response_code: string;
//   response_message:  string;
//   response_data: {
//     transactions: savingsTransaction[];
//  },
// }


export interface GetTotalSavingsDatas {
  transactions: savingsTransaction[],
  "rentBalance": number,
"propertyPurchaseBalance": number,
"generalBalance": number,
"monthlyBillsBalance": number,
"overallBalance": number
}

export interface getTotalSavingsResponse{
  "succeeded": boolean,
  "message": string,
  "errors": unknown,
  "data": GetTotalSavingsDatas,
  "meta": null,
  "rentBalance": number,
  "propertyPurchaseBalance": number,
  "generalBalance": number,
  "monthlyBillsBalance": number,
  "overallBalance": number
}
 export interface savingsTransaction{
  "id": number,
  "messageID": string,
  "accountNumber": string,
  "destinationAccountNumber": any,
  "amount": number,
  "transactionId": string,
  "debitCreditIndicator": any,
  "transactionDate": string,
  "responseCode": string,
  "transactionType": number,
  "transactionTypeValue": string,
  "holdId": any
}