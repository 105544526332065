import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Layout from '../components/Layout';
import LoadingScreen from '../components/LoadingScreen';
import NavHeader from '../components/NavHeader';
import propertyData from '../components/PropertyCard/defaultData';
import SessionCheck from '../components/SessionCheck';
import { useQuery } from '../hooks/useQuery';
import MortgageSection from '../sections/Mortgage';
import GeneralNotFound from '../sections/NotFound';
import ProductDetail from '../sections/ProductDetail';
import { RootState } from '../state/store';
import { fetchPropertyById } from '../state/thunks/property';
import { useProduct } from '../swr/properties';


const LoanPage: React.FC<any> = ()=>{

    let query = useQuery();
    let history =  useHistory();

    let propertyId = query.get('property');

    const dispatch = useDispatch();
    //const { product, isLoading, isError } = useProduct(propertyId || '');

    React.useEffect(()=> console.log(''), []);

    return(
        <React.Fragment>
            <SessionCheck shouldRedirect/>
            {/* {isLoading &&  <LoadingScreen/>} */}
            <Layout>
                <NavHeader
                    pageTitle="Loan"
                    routeName="Loan"
                />
                <MortgageSection property={propertyData}/>
            </Layout>
        </React.Fragment>
    )
}

export default LoanPage;