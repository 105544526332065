import React from 'react'
import Layout from '../../../components/Layout'
import ScrollToTop from '../../../components/ScrollToTop'
import SessionCheck from '../../../components/SessionCheck'
import NavHeader from '../../../components/NavHeader'
import ViewPensionApplicant from './page'

const ViewPensionsPage = () => {
  return (
    <Layout>
    <ScrollToTop />
    <SessionCheck shouldRedirect />
    <NavHeader pageTitle="View Pension Applicant" routeName="View Pension" />
    <ViewPensionApplicant />
  </Layout>
  )
}

export default ViewPensionsPage