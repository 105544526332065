import Button from "@mui/material/Button";
import React from "react";
import LoadingContext from "../../contexts/LoadingContext";
import ToastContext from "../../contexts/ToastContext";
import { createWallet, getWalletDetails } from "../../swr/wallet";
import { CreateWalletRequest } from "../../types/Wallet";
import { getUserEmail } from "../../utils/generalUtils";
import styles from "./createWallet.module.scss";
import { ValidationErrors } from "../../swr/wallet";
import { useHistory } from "react-router";

const UserAccountCreateWallet: React.FC<any> = () => {
  const token = localStorage.getItem("token") || "";

  // Context
  const { openSuccess, openError } = React.useContext(ToastContext);
  const { setLoading } = React.useContext(LoadingContext);

  // State
  const [transactionPin, setTransactionPin] = React.useState("");
  const [confirmTransactionPin, setConfirmTransactionPin] = React.useState("");
  const [bvn, setBvn] = React.useState("");
  const history = useHistory()

  // Handlers
  const handleCreate = async () => {
    if (!transactionPin || !confirmTransactionPin || !bvn) {
      openError("all fields are required");
      return;
    }

    if (transactionPin.length !== 4) {
      openError("transaction pin must be 4-digit");
    }

    if (transactionPin !== confirmTransactionPin) {
      openError("pins do not match");
      return;
    }

    function containsNonNumericChars(inputString: string): boolean {
      return /[^\d]/.test(inputString);
    }

    const isContainValid = containsNonNumericChars(bvn);

    if (isContainValid === true) {
      openError("Please enter valid bvn");
      return;
    }

    setLoading(true);
    let request: CreateWalletRequest = {
      pin: transactionPin,
      bvn: bvn,
    };

    // Create wallet
    try {
      const { data, error } = await createWallet(request, token);

      if (data) {
        if (data.succeeded === true) {
          setLoading(false);

          // Store wallet ID in local storage
          if (data.data) {
            localStorage.setItem("homezone_wallet", data.data.walletId);
            openSuccess("Wallet successfully created");
            window.location.reload()
          } else {
            /**
             * This happens because the wallet_id is part of the info provided by the token.
             * So prompting the user to sign in again will allow the new token to
             * carry the wallet_id
             */
            localStorage.removeItem("token");
            openSuccess(
              "Wallet successfully created. Sign back in to continue"
            );
          }
        } else {
          setLoading(false);
          openError(data.message);
        }
      }
      if (error) {
        // @ts-ignore
        let err: AxiosError<ValidationErrors> = error; // cast the error for access
        if (!error.response) {
          throw err;
        }
        setLoading(false);
        openError(err.response.data.message || err.response.data.Message);
      }
    } catch (err) {
      setLoading(false);
      // @ts-ignore
      let error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      openError(error.response.data.message || error.response.data.Message);
      return;
    }
  };

  return (
    <div className={styles.display}>
      <p>
        Looks like you <strong>do not</strong> have a wallet yet. Please enter
        your bvn and preferred transaction pin below to create one
      </p>

      {/** Input fields */}
      <div>
        <label htmlFor="BVN">BVN:</label>
        <input
          type="text"
          id="bvn"
          value={bvn}
          onChange={(e) => setBvn(e.target.value)}
          autoComplete="off"
        />
      </div>
      <div>
        <label htmlFor="transactionPin">Transaction Pin (4-digit):</label>
        <input
          type="password"
          id="transactionPin"
          value={transactionPin}
          onChange={(e) => setTransactionPin(e.target.value)}
          autoComplete="off"
        />
      </div>

      <div>
        <label htmlFor="transactionPin">Confirm Pin:</label>
        <input
          type="password"
          id="transactionPin"
          value={confirmTransactionPin}
          onChange={(e) => setConfirmTransactionPin(e.target.value)}
          autoComplete="off"
        />
      </div>
      {/** Create button */}
      <div>
        <Button
          variant="contained"
          onClick={handleCreate}
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__paragraph-color)",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
          }}
        >
          Create a wallet
        </Button>
      </div>
    </div>
  );
};

export default UserAccountCreateWallet;
