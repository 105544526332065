import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react';

export interface DeleteProductDialogProps{
    open?: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
    children?: React.ReactNode | React.ReactNode[];
    title?: string;
    onDelete: (arg?: any) => any;
    context?: string;
}

const DeleteProductDialog: React.FC<DeleteProductDialogProps> = ({
    open = false, onClose, children, title, onDelete, context
})=>{

    return(
        <Dialog
            maxWidth= 'lg'
            open={open}
            onClose={onClose}
            aria-labelledby="delete-dialog-dialog-title"
            aria-describedby="delete-dialog-description"
        >
            {
                title &&
                <DialogTitle id="delete-dialog-dialog-title">
                    { title }
                </DialogTitle>
            }
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { context }
                </DialogContentText>
                
                { children }
            </DialogContent>
            <DialogActions>
                <Button onClick={()=> onDelete()}>Delete</Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        
        </Dialog>
    )
}

export default DeleteProductDialog;