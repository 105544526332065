import React from "react";
import { useHistory } from "react-router-dom";
import ToastContext from "../../../contexts/ToastContext";
import { placeBooking } from "../../../swr/inspection";
import { InspectionRequest } from "../../../types/Inspection";
import { isEmpty } from "../../../utils/formUtils";
import {
  combineDateTime,
  hasWallet,
  toDateStringRestriction,
} from "../../../utils/generalUtils";
import CustomToast from "../../CustomToast";
import PromptDialog from "../../Dialogs/PromptDialog";
import TextArea from "../../FormElements/TextArea";
import TextInput from "../../FormElements/TextInput";
import LoadingScreen from "../../LoadingScreen";
import styles from "./booking.module.scss";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

export interface BookingWidgetProps {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  id?: number;
}

interface ErrorFormState {
  yourname: boolean;
  youremail: boolean;
  yourmessage: boolean;
  yourdate: boolean;
}

const BookingWidget: React.FC<BookingWidgetProps> = ({
  onSubmit = (event) => event?.preventDefault(),
  id,
}) => {
  const [value, setValue] = React.useState<Dayjs | null>( null
    // dayjs("2023-12-17T15:30")
  );
  
  // Context
  const { openSuccess, openError } = React.useContext(ToastContext);

  // State
  let initErrorState: ErrorFormState = {
    yourname: false,
    youremail: false,
    yourmessage: false,
    yourdate: false,
  };

  const [errorState, setErrorState] =
    React.useState<ErrorFormState>(initErrorState);
  const [bookLoading, setBookLoading] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [clear, setClear] = React.useState<boolean>(false);
  const [promptOpen, setPropmtOpen] = React.useState<boolean>(false);

  // Refs
  const formRef = React.createRef<HTMLFormElement>();

  // Token
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email") as string;

  // Hooks
  let history = useHistory();

  // Validation
  const validateForm = (fdata: FormData): "passed" | "failed" => {
    let result: "passed" | "failed" = "passed";

    // Check if any field is empty
    for (const pairs of fdata.entries()) {
      //console.log(pairs);
      if (isEmpty(pairs[1].toString())) {
        setErrorState((prevState) => ({ ...prevState, [pairs[0]]: true }));
        result = "failed";
      }
    }

    return result;
  };
  const unhighlight = (event: React.ChangeEvent<any>) => {
    setErrorState((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
  };

  const animatePopup = () => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  const handlePromptAction = () => {
    setPropmtOpen(false);
    history.push("/account#liton_tab_1_6");
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!token) {
      animatePopup();
      return;
    }
    // Check if user has a wallet
    if (!hasWallet(token as string)) {
      setPropmtOpen(true);
      return;
    }
    if (formRef.current) {
      const fdata = new FormData(formRef.current);

      let selectedDate = fdata.get("yourdate")?.toString();

      if (validateForm(fdata) === "passed") {
        if(!selectedDate){
          openError('Please pick a date')
          return
        }
        setBookLoading(true);
        // The Request
        let request: InspectionRequest = {
          propertiesId: id,
          name: fdata.get("yourname")?.toString(),
          email,
          comments: fdata.get("yourmessage")?.toString().trim(),
          date: combineDateTime(value, new Date(selectedDate))
        };

        const { data, error } = await placeBooking(request, token);
        if (data) {
          setBookLoading(false);
          openSuccess("Your message for booking has been sent");
          setValue(null)
          setClear(true);
          setClear(false);
        }
        if (error) {
          setBookLoading(false);
          openError("An error occurred while sending your message");
        }
      }
    }
  };

  const isMobile = window.innerWidth < 768;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {bookLoading && <LoadingScreen />}
      <PromptDialog
        title="This service requires a wallet"
        open={promptOpen}
        onProceed={handlePromptAction}
        proceedText="Proceed"
      >
        It looks like you do not have a wallet. Please create one before using
        this service
      </PromptDialog>
      <div className="widget ltn__form-widget">
        <h4 className="ltn__widget-title ltn__widget-title-border-2">
          Book A Tour
        </h4>
        <form onSubmit={handleSubmit} ref={formRef} className={styles.form}>
          <TextInput
            name="yourname"
            placeholder="Your Name*"
            hideDefaultAdornment
            error={errorState.yourname}
            required={errorState.yourname}
            onChange={unhighlight}
            useValue=""
            clear={clear}
          />
          <TextInput
            type="date"
            name="yourdate"
            min={toDateStringRestriction(new Date())}
            hideDefaultAdornment
            placeholder="Inspection Date..."
            error={errorState.yourdate}
            required={errorState.yourdate}
            onChange={unhighlight}
            clear={clear}
            className="mb-30"
          />
          <TextArea
            name="yourmessage"
            hideDefaultAdornment
            placeholder="Write Message..."
            error={errorState.yourmessage}
            required={errorState.yourmessage}
            onChange={unhighlight}
            clear={clear}
          />

          <div
            style={{
              width: "100%",
              height: "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {/* <DemoContainer components={["TimePicker"]}> */}
              <TimePicker
                label="Pick Time"
                value={value}
                onChange={(newValue) => setValue(newValue)}
                className={styles.timepickerContainer}
              />
            {/* </DemoContainer> */}

            <button
              style={{ marginTop: "5%" }}
              type="submit"
              className="btn theme-btn-1"
            >
              Send Message
            </button>
          </div>

          <span className={`${styles.popuptext} ${show && styles.show}`}>
            Sign in to book a viewing
          </span>
        </form>
      </div>
    </LocalizationProvider>
  );
};

export default BookingWidget;
