import { createUseStyles } from "react-jss";
import { breakpoints } from "../../utils/breakpoints";

const useStyles = createUseStyles({

    card:{
        border: 'none',
        boxShadow: 'var(--ltn__box-shadow-4)',
        borderRadius: 10,
        transition: 'all 0.3s ease 0s',
        backgroundColor: 'white',
        marginBottom: '50px',
        marginTop: '2px',

        '& a':{
            padding: '40px 30px 35px',
            display: 'block',
            position: 'relative'
        },

        '& span':{
            display: 'block',
        },

        '&:hover':{
            backgroundColor: 'var(--ltn_secondary-color)',
            color: 'white',
        },

        '&:hover $title':{
            color: 'white',
        },

        '&:hover $description':{
            color: 'white',
        },

        '&:hover $index':{
            color: 'white',
        },

    },

    icon:{
        marginLeft: 'auto',
        marginBottom: '20px',
        textAlign: 'center',
        width: '100px',
        height: '100px',
        lineHeight: '100px',
        // fontSize: '45px',
        borderRadius: '100%',
        // backgroundColor: '#FFF2F0',
        backgroundColor: '#E5FFE5',
        color: 'var(--ltn_secondary-color)',
    },

    index:{
        transition: 'all 0.3s ease 0s',
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'Nunito Sans',
        color: 'var(--ltn_secondary-color)',
    },

    title:{
        fontSize: 22,
        fontWeight: 600,
        fontFamily: 'Poppins',
        color: 'var(--ltn__heading-color)',
        transition: 'all 0.3s ease 0s',
        marginBottom: 10,
    },

    description:{
        transition: 'all 0.3s ease 0s',
        color: 'var(--ltn_paragraph-color)',
        fontSize: 14
    },

    [breakpoints.xl()]:{
        card:{
            '& a':{
                padding: '40px 20px 35px',
            }
        },

        title:{
            fontSize: 18
        },
    }

    
})

export default useStyles;