import {
  Inspection,
  InspectionBool,
  InspectionRequest,
  InspectionResponse,
} from '../types/Inspection'
import Axios, { AxiosError, AxiosResponse } from 'axios'
import useSwr from 'swr'
import api from '../axios'

const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api.homebuddy.ng'

export interface InspectionResult {
  data?: InspectionResponse
  error?: AxiosError
}

export const placeBooking = (booking: InspectionRequest, token?: string) => {
  const willPlaceBooking = new Promise<InspectionResult>((resolve) => {
    api.post('/api/Inspection/add-inspection', booking, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((respone: AxiosResponse<InspectionResponse>) => {
        resolve({ data: respone.data })
      })
      .catch((err: AxiosError) => {
        resolve({ error: err })
      })
  })

  return willPlaceBooking
}

export const useBookings = (token?: string) => {
  const fetcher = (url: string) =>
    api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: AxiosResponse<InspectionResponse>) => res.data.data)

  const { data, error } = useSwr(
    `/api/Inspection/get-inspection-by-id`,
    fetcher
  )
  return {
    bookings: data,
    isLoading: !error && !data,
    isError: error,
  }
}


export const inspectionBool = async (
  request: InspectionBool, token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const { data } = await api.post(
    `/api/Inspection/Accept-inspection`,
    request,
    config
  )
  return data
}

export const getUserInspections = async (
  token: string,
): Promise<any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.get(
      `/api/Inspection/Get-Inspection-By-User`,
      config,
    );
    return response;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};