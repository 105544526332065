import Axios, { AxiosError, AxiosResponse } from "axios";
import { GeneralOperationResult } from "../types/GeneralResponse";
import api from "../axios";
import {
  BlobFormatImprovised,
  CreatePolicyRequest,
  CreatePolicyRequestResponseData,
  CreateQuoteResponseData,
  DocumentsUploadHomeOwners,
  DocumentsUploadHouseHold,
  GetInsuranceResponse,
  GetInsuranceResponseData,
  GetRentInsuranceDocs,
  GetRentInsuranceDocsResponse,
  GetSingleInsuranceResponse,
  HomeOwnerCreateQuoteRequestBody,
  InsuranceObj,
  MakePaymentHomeOwners,
  RentDefaultInsuranceRequest,
  RentInsuranceRequestBody,
  SingleInsurance,
} from "../types/Insurance";
import { changeBlobsToFile, returnRandomStr } from "../utils/generalUtils";
import {
  HouseholdCreateQuoteRequestBody,
  MakePaymentHouseHold,
} from "../types/Insurance";
import { landLordDetailsImgKeys } from "../views/Insurance/RentDefault/RentForm/GetQuoteSuccess";

export const homeOwnersCreateQuoteApi = (
  request: HomeOwnerCreateQuoteRequestBody,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willCreateQuote = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      api
        .post(`/api/Insurance/HomeOwner-CreateQuote`, request, config)
        .then((response: AxiosResponse<CreateQuoteResponseData>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );

  return willCreateQuote;
};

export const homeOwnersCreatePolicyApi = (
  request: CreatePolicyRequest,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willCreatePolicy = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      api
        .post(`/api/Insurance/HomeOwner-CreatePolicy`, request, config)
        .then((response: AxiosResponse<CreatePolicyRequestResponseData>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );

  return willCreatePolicy;
};

export const homeOwnersMakePaymentApi = (
  request: MakePaymentHomeOwners,
  pin: string,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willMakePayment = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      api
        .post(`/api/Insurance/HomeOwner-MakePayment?pin=${pin}`, request, config)
        .then((response: AxiosResponse<any>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );

  return willMakePayment;
};

export const homeOwnersDocUploadApi = (
  request: DocumentsUploadHomeOwners,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  formData.append("FileName", request.FileName);

  if (Array.isArray(request.ImageFile)) {
    for (let i = 0; i < request.ImageFile.length; i++) {
      formData.append("ImageFile", request.ImageFile[i], returnRandomStr());
    }
  } else {
    formData.append("ImageFile", request.ImageFile);
  }
  formData.append("QuoteInfoId", request.QuoteInfoId as string);
  const willUploadDocs = new Promise<GeneralOperationResult<any>>((resolve) => {
    api
      .post(`/api/Insurance/HomeOwner-DocumentUpload`, formData, config)
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });

  return willUploadDocs;
};

export const rentDefaultInsuranceDocUpload = async (
  RentDefaultInsuranceId: string,
  ImageFile: File[],
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  const lastIndex = ImageFile.length - 1;
  const beforeLast = ImageFile.length - 2;
  for (let i = 0; i < ImageFile.length; i++) {
    formData.append(
      "ImageFile",
      ImageFile[i],
      i === lastIndex || i === beforeLast
        ? `TENANT${ImageFile[i].name}`
        : `LANDLORD${ImageFile[i].name}`
    );
  }

  const willUploadDocs = new Promise<GeneralOperationResult<any>>((resolve) => {
    api
      .post(
        `/api/Insurance/RentDefaultInsuranceImages?RentDefaultInsuranceId=${RentDefaultInsuranceId}&FileName=${"RentInsuranceDocs"}`,
        formData,
        config
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });

  return willUploadDocs;
};

export const rentDefaultGenerateQuoteApi = (
  AnnualRentalIncome: number,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willGenerateQuote = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      api
        .post(
          `/api/Insurance/RentDefaultInsuranceGenerateQuote?AnnualRentalIncome=${AnnualRentalIncome}`,
          {},
          config
        )
        .then((response: AxiosResponse<any>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );
  return willGenerateQuote;
};

export const rentDefaultFinalApi = (
  request: RentDefaultInsuranceRequest,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willRentDefault = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      api
        .post(`/api/Insurance/RentDefaultInsurance?pin=${request.pin}`, request, config)
        .then((response: AxiosResponse<any>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );
  return willRentDefault;
};

export const householdCreateQuoteApi = (
  request: HouseholdCreateQuoteRequestBody,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willCreateQuote = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      api
        .post(`/api/Insurance/HouseHolder-CreateQuote`, request, config)
        .then((response: AxiosResponse<CreateQuoteResponseData>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );

  return willCreateQuote;
};

export const householdCreatePolicyApi = (
  request: CreatePolicyRequest,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willCreatePolicy = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      api
        .post(`/api/Insurance/HouseHolder-CreatePolicy`, request, config)
        .then((response: AxiosResponse<CreatePolicyRequestResponseData>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );

  return willCreatePolicy;
};

export const householdMakePaymentApi = (
  request: MakePaymentHouseHold,
  pin: string,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willMakePayment = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      api
        .post(`/api/Insurance/HouseHolder-MakePayment?pin=${pin}`, request, config)
        .then((response: AxiosResponse<any>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );

  return willMakePayment;
};

export const houseHoldDocUploadApi = (
  request: DocumentsUploadHouseHold,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  formData.append("FileName", request.FileName);

  if (Array.isArray(request.ImageFile)) {
    for (let i = 0; i < request.ImageFile.length; i++) {
      formData.append("ImageFile", request.ImageFile[i], returnRandomStr());
    }
  } else {
    formData.append("ImageFile", request.ImageFile);
  }
  formData.append("QuoteInfoId", request.QuoteInfoId as string);
  const willUploadDocs = new Promise<GeneralOperationResult<any>>((resolve) => {
    api
      .post(`/api/Insurance/HouseHolder-DocumentUpload`, formData, config)
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });

  return willUploadDocs;
};

export const getAllInsurance = (
  pagination: { PageNumber: number; PageSize: number },
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { PageNumber, PageSize } = pagination;
  const willGetInsurance = new Promise<GetInsuranceResponse>((resolve) => {
    api
      .get(
        `/api/Insurance/ViewInsurance?PageNumber=${PageNumber}&PageSize=${PageSize}`,
        config
      )
      .then((response: AxiosResponse<GetInsuranceResponseData>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return willGetInsurance;
};

export const getAllRentInsurance = (
  pagination: { PageNumber: number; PageSize: number },
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { PageNumber, PageSize } = pagination;
  const willGetInsurance = new Promise<GetInsuranceResponse>((resolve) => {
    api
      .get(
        `/api/Insurance/ViewRentDefaultInsurance?PageNumber=${PageNumber}&PageSize=${PageSize}`,
        config
      )
      .then((response: AxiosResponse<GetInsuranceResponseData>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return willGetInsurance;
};

export const getSingleRentInsurance = (
  id: string,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const willGetInsurance = new Promise<GetSingleInsuranceResponse>((resolve) => {
    api
      .post(
        `/api/Insurance/GetRentDefaultInsuranceById?id=${id}`,
        {},
        config
      )
      .then((response: AxiosResponse<GeneralOperationResult<SingleInsurance>>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return willGetInsurance;
};

export const getRentInsuranceDocs = (
  id: string,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const willGetInsuranceDocs = new Promise<GetRentInsuranceDocsResponse>((resolve) => {
    api
      .post(
        `/api/Insurance/GetRentDefaultInsuranceDocumentsById?id=${id}`,
        {},
        config
      )
      .then((response: AxiosResponse<GeneralOperationResult<GetRentInsuranceDocs[]>>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return willGetInsuranceDocs;
};

export const AttendRentInsurance = (
  id: string,
  status: boolean,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const willAttendInsurance = new Promise<GeneralOperationResult<any>>((resolve) => {
    api
      .post(
        `/api/Insurance/AttendToRentDefaultInsurance?id=${id}&status=${status}`,
        {},
        config
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return willAttendInsurance;
};
