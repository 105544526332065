import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React, { useContext, useEffect, useState } from "react";
import {
  LandlordDetails,
  RentDropDown,
  TenantDetailsType,
} from "../../../../types/Insurance";
import styles from "./rent-form.module.scss";
import EmptyDropDown from "../../../../components/Input/EmptyDropDown";
import {
  genderArray,
  maritalStatusArray,
  religionArray,
  sectorArray,
} from "../../../../utils/generalUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import PopUp from "../../../../components/Insurance/PopUp";
import ModalLayout from "../../../../components/ModalLayout";
import {
  setRentDropValue,
  setTenantDetails,
} from "../../../../state/reducers/insuranceSlice";
import EmptyDropCalendar from "../../../../components/Input/EmptyDropCalendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UploadDocs, {
  LandlordOrTenantType,
} from "../../../../components/Insurance/UploadDocs";
import ToastContext from "../../../../contexts/ToastContext";

type DocsUpload = {
  buttonText: string;
  texts: string[];
  accept: string;
  name: keyof LandlordDetails;
};

const titleArr = ["Mr", "Mrs", "Miss", "Master"];

const notRequiredFields = ["MiddleName"]

const filterCompulsory = (data: TenantDetailsType): TenantDetailsType => {
  const filteredData: TenantDetailsType = { ...data };

  for (const key in filteredData) {
    if (notRequiredFields.includes(key)) {
      delete filteredData[key as keyof TenantDetailsType];
    }
  }

  return filteredData;
};

const TenantDetailsComp = ({
  setRentStep,
  closeAllDropDowns,
  rentDropDownValue,
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
  closeAllDropDowns: () => void;
  rentDropDownValue: RentDropDown;
}) => {
  const dispatch = useDispatch();
  const { tenantDetails } = useSelector((state: RootState) => state.insurance);
  const { openError } = useContext(ToastContext);

  const [tenantDetailsReq, setTenantDetailsReq] = useState<TenantDetailsType>({
    Gender: 10,
    DateOfBirth: undefined,
    MaritalStatus: 10,
    HomeAddress: "",
    City: "",
    State: "",
    Occupation: "",
    Sector: 10,
    PassportPhoto: undefined,
    IdCard: undefined,
    Title: 10,
    FirstName: "",
    LastName: "",
    MiddleName: "",
    Email: "",
    PhoneNumber: "",
  });

  const checkIfAnyUndefinedOrTenOrEmptyString = (
    obj: TenantDetailsType
  ): boolean => {
    obj = filterCompulsory(obj)
    // Check if any key is undefined
    const hasUndefined = Object.values(obj).some(
      (value) => value === undefined
    );

    // Check if any key of type number is 10
    const hasNumberTen = Object.values(obj).some(
      (value) => typeof value === "number" && value === 10
    );

    // Check if any key is an empty string
    const hasEmptyString = Object.values(obj).some(
      (value) => typeof value === "string" && value === ""
    );

    // Return true if any of the conditions are true, otherwise return false
    return hasUndefined || hasNumberTen || hasEmptyString;
  };

  const openDropDown = (val: RentDropDown) => {
    dispatch(setRentDropValue(val));
  };

  const handleTypedOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTenantDetailsReq((prev: any) => ({
      ...prev,
      [name]: value.toUpperCase(),
    }));
  };

  const handleGenderSelection = (value: string) => {
    setTenantDetailsReq((prev) => ({
      ...prev,
      Gender: genderArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleMaritalSelection = (value: string) => {
    setTenantDetailsReq((prev) => ({
      ...prev,
      MaritalStatus: maritalStatusArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleTitleSelection = (value: string) => {
    setTenantDetailsReq((prev) => ({
      ...prev,
      Title: titleArr.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleSectorSelection = (value: string) => {
    setTenantDetailsReq((prev) => ({
      ...prev,
      Sector: sectorArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleDateChange = (val: any) => {
    setTenantDetailsReq((prev: any) => ({
      ...prev,
      DateOfBirth: val,
    }));
  };

  const returnFormattedDate = (p: Date) => {
    return new Date(p).toLocaleString("NG").split(",")[0];
  };

  useEffect(() => {
    if (Object.keys(tenantDetails).length !== 0) {
      setTenantDetailsReq({ ...tenantDetails });
    }
  }, []);

  const documents: DocsUpload[] = [
    {
      buttonText: "Upload Your Passport Photo",
      texts: [
        "Only jpg and png are allowed",
        "Large files might take longer to be processed",
        "Upload at most one photo, minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.jpeg,.png",
      name: "PassportPhoto",
    },
    {
      buttonText: "Upload Your ID",
      texts: [
        "Only jpg, png and pdf are allowed",
        "You may only upload NIN, Voter's card, or International Passport as valid ID",
        "Large files might take longer to be processed",
        "If uploading a photo, it must be a minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.png,.pdf,.jpeg",
      name: "IdCard",
    },
  ];

  const handleDocsChange = (name: LandlordOrTenantType, value: File) => {
    setTenantDetailsReq((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const removeDoc = (name: LandlordOrTenantType) => {
    setTenantDetailsReq((prev) => ({
      ...prev,
      [name]: undefined,
    }));
  };

  const saveAndContinue = () => {
    const isValidationErr =
      checkIfAnyUndefinedOrTenOrEmptyString(tenantDetailsReq);
    if (isValidationErr === true) {
      openError("Please fill all required fields");
      return;
    }
    
    dispatch(setTenantDetails(tenantDetailsReq));
    dispatch(setRentStep(5));
  };

  const goBack = () => {
    dispatch(setRentStep(3));
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.header}>
          <div className={styles.headerblock}></div>
          <div className={styles.headertext}>Tenant Details</div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              Gender<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  genderArray[tenantDetailsReq.Gender ?? tenantDetails.Gender]
                }
                click={() => openDropDown("Gender")}
              />
            </div>
          </div>
          <div className={styles.inputcolumn}>
            <span>
              Date Of Birth<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop-date"]}>
              <span>
                {tenantDetailsReq.DateOfBirth
                  ? returnFormattedDate(tenantDetailsReq.DateOfBirth)
                  : tenantDetails.DateOfBirth
                  ? returnFormattedDate(tenantDetails.DateOfBirth)
                  : ""}
              </span>
              <EmptyDropCalendar
                select={tenantDetailsReq.DateOfBirth}
                click={handleDateChange}
              />

              <ArrowDropDownIcon
                sx={{
                  position: "absolute",
                  right: "5%",
                  fontSize: 35,
                  zIndex: 50,
                }}
              />
            </div>
          </div>
          <div className={styles.inputcolumn}>
            <span>
              Marital Status<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  maritalStatusArray[
                    tenantDetailsReq.MaritalStatus ??
                      tenantDetails.MaritalStatus
                  ]
                }
                click={() => openDropDown("MaritalStatus")}
              />
            </div>
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Title<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={titleArr[tenantDetailsReq.Title ?? tenantDetails.Title]}
                click={() => openDropDown("Title")}
              />
            </div>
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              First Name<span className={styles.red}>*</span>
            </span>
            <input
              name="FirstName"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.FirstName || tenantDetails.FirstName}
              defaultValue={tenantDetails.FirstName}
            />
          </div>
          <div className={styles.inputcolumn}>
            <span>
              Last Name<span className={styles.red}>*</span>
            </span>
            <input
              name="LastName"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.LastName || tenantDetails.LastName}
              defaultValue={tenantDetails.LastName}
            />
          </div>
          <div className={styles.inputcolumn}>
            <span>Middle Name</span>
            <input
              name="MiddleName"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.MiddleName || tenantDetails.MiddleName}
              defaultValue={tenantDetails.MiddleName}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles["input2column"]}>
            <span>
              Email Address<span className={styles.red}>*</span>
            </span>
            <input
              name="Email"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.Email || tenantDetails.Email}
              defaultValue={tenantDetails.Email}
            />
          </div>
          <div className={styles["input2column"]}>
            <span>
              Phone Number<span className={styles.red}>*</span>
            </span>
            <input
              name="PhoneNumber"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.PhoneNumber || tenantDetails.PhoneNumber}
              defaultValue={tenantDetails.PhoneNumber}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Home Address<span className={styles.red}>*</span>
            </span>
            <input
              name="HomeAddress"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.HomeAddress || tenantDetails.HomeAddress}
              defaultValue={tenantDetails.HomeAddress}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              City<span className={styles.red}>*</span>
            </span>
            <input
              name="City"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.City || tenantDetails.City}
              defaultValue={tenantDetails.City}
            />
          </div>

          <div className={styles["inputcolumn-65"]}>
            <span>
              State<span className={styles.red}>*</span>
            </span>
            <input
              name="State"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.State || tenantDetails.State}
              defaultValue={tenantDetails.State}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles["inputcolumn-65"]}>
            <span>
              Occupation<span className={styles.red}>*</span>
            </span>
            <input
              name="Occupation"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={tenantDetailsReq.Occupation || tenantDetails.Occupation}
              defaultValue={tenantDetails.Occupation}
            />
          </div>

          <div className={styles.inputcolumn}>
            <span>
              Sector<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  sectorArray[tenantDetailsReq.Sector ?? tenantDetails.Sector]
                }
                click={() => openDropDown("Sector")}
              />
            </div>
          </div>
        </div>

        <span className={styles["doc-header"]}>
          Media (Tenant)<span className={styles.red}>*</span>
        </span>
        {documents.map((doc) => (
          <UploadDocs
            key={doc.buttonText}
            name={doc.name}
            buttonTxt={doc.buttonText}
            instructions={doc.texts}
            accept={doc.accept}
            handleDocsChange={handleDocsChange}
            removeDoc={removeDoc}
            tenantDetails={tenantDetails}
            landLordDetails={undefined}
          />
        ))}

        <div className={styles["container-bottom-butt"]}>
          <span onClick={() => goBack()} role="button">
            Back
          </span>

          <div
            className={styles["continue-butt"]}
            role="button"
            onClick={() => saveAndContinue()}
          >
            <span>Save & Continue</span>
          </div>
        </div>
      </div>

      <ModalLayout modalOpen={rentDropDownValue === "Gender"}>
        <PopUp options={genderArray} click={handleGenderSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "MaritalStatus"}>
        <PopUp options={maritalStatusArray} click={handleMaritalSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Title"}>
        <PopUp options={titleArr} click={handleTitleSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Sector"}>
        <PopUp options={sectorArray} click={handleSectorSelection} />
      </ModalLayout>
    </>
  );
};

export default TenantDetailsComp;
