import React from "react";
import Dropdown, {
  DropdownOption,
} from "../../components/FormElements/Dropdown";
import CardDebitForm, {
  CardDebitFormState,
} from "../../components/Forms/CardDebitForm";
import { formatAmount } from "../../utils/propertyUtils";
import styles from "./installment.module.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularLoader } from "../../components/LoadingScreen";
import TextInput from "../../components/FormElements/TextInput";

export interface InstallmentState {
  cardVerified: boolean;
  repayment: number;
  startDate: string;
}

export interface AdditionalFee {
  deposit?: number;
  ourFee?: number;
}

export interface InstallmentProps {
  onStateChange?: (state: InstallmentState) => void;
  additionalFee?: AdditionalFee;
  price?: number;
  repaymentPeriod?: number;
  downPayment?: number;
  confirmPaymentCall: () => Promise<void>
}

const Installment: React.FC<InstallmentProps> = ({
  onStateChange,
  additionalFee = { deposit: 0, ourFee: 0 },
  price = 0,
  repaymentPeriod,
  downPayment,
  confirmPaymentCall
}) => {
  let balance =
    price + (additionalFee?.deposit || 0) + (additionalFee?.ourFee || 0);

    let bal = (+price - +(downPayment ?? 0)).toFixed(2)

  // State
  const [cardVerified, setCardVerified] = React.useState<boolean>(false);
  const [repayment, setRepayment] = React.useState<number>(0);
  const [startDate, setStartDate] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  // Repayment Options
  let options: DropdownOption[] = [
    { value: 12, label: "Monthly Repayment" },
    { value: 4, label: "Quaterly Repayment" },
  ];

  // Handlers
  const handleCardSubmit = (state: CardDebitFormState) => {
    // Simulate verification
    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      setCardVerified(true);
      let installment: InstallmentState = {
        cardVerified: true,
        repayment,
        startDate,
      };
      onStateChange && onStateChange(installment);
    }, 1500);
  };
  const handleRepaymentSelection = (value: any) => {
    setRepayment(value);
    let state: InstallmentState = {
      cardVerified,
      repayment: value,
      startDate,
    };
    onStateChange && onStateChange(state);
  };
  const handleDateChange = (value: any) => {
    setStartDate(value);
    let state: InstallmentState = {
      cardVerified,
      repayment,
      startDate: value,
    };
    onStateChange && onStateChange(state);
  };

  return (
    <div className={styles.container}>
      <div className={styles.saleContainer}>
        <p>{`Please make down-payment of ₦${downPayment && formatAmount(downPayment.toString())} to the account details below:`}</p>

        <div className={styles.saleTransfer}>
          <span className={styles.leftItem}>Account Name:</span>

          <span>HOMEBUDDY PAYMENT COLLECTION</span>
        </div>
        <div className={styles.saleTransfer}>
          <span className={styles.leftItem}>Bank:</span>

          <span>Access Bank</span>
        </div>
        <div className={styles.saleTransfer}>
          <span className={styles.leftItem}>Account Number:</span>

          <span>1855777206</span>
        </div>

        <div className={styles["confirm-button"]}>
          <div className={styles["confirm-but"]} role="button" onClick={() => confirmPaymentCall()}>
            confirm-payment
          </div>
        </div>
        <div className={styles.saleTransfer}>
          {/* <span className={styles.leftItem}>Account Number:</span> */}

          {/* <span>{`The balance would be spread across the next ${repaymentPeriod} months`} </span> */}
        </div>
      </div>
      {/* <div className="row"> */}
        {/** Card Info */}
        {/* <div className={`col-lg-6 col-sm-6 col-xs-12`}> */}
          {/* <div>Payments by installments coming soon</div> */}
          {/* <div className={styles.cardTitle}>
                       {!cardVerified && <DoDisturbOnOutlinedIcon />}
                       {cardVerified && <CheckCircleIcon sx={{ color: 'var(--correct)' }} />}
                       <h6>Verify the card that will be debited</h6>
                   </div> */}
          {/* <div className={styles.card}>
                    {loading && <CircularLoader/>}
                    {cardVerified && <div className={styles.tableOverlay}></div>}
                        <CardDebitForm
                            inputClass={styles.input}
                            labelClass={styles.label}
                            buttonClass={styles.btn}
                            onSubmit={handleCardSubmit}
                        />
                    </div> */}
        {/* </div> */}

        {/** Breakdown */}
        {/* <div className={`col-lg-6 col-sm-6 col-xs-12`}>
                    <div className={styles.cardTitle}>
                        {!(repayment && startDate) && <DoDisturbOnOutlinedIcon />}
                        {(repayment && startDate) && <CheckCircleIcon sx={{ color: 'var(--correct)' }} />}
                       <h6>Select how frequently you want to be paying</h6>
                   </div>
                    <div className={styles.breakdown}>
                        {!cardVerified && <div className={styles.tableOverlay}></div>}
                        <table className={`${styles.table} ${!cardVerified && styles.bluryText}`}>
                            <tbody>
                            <tr>
                                <td><b>Rent / Purchase:</b></td>
                                <td>₦ {formatAmount((price).toString())}</td>
                            </tr>
                            <tr>
                                <td><b>Refundable deposit (Caution fee for landlord):</b> </td>
                                <td>₦ {formatAmount((additionalFee.deposit || 0).toString())}</td>
                            </tr>
                            <tr>
                                <td><b>Our Fee: </b></td>
                                <td>₦ {formatAmount((additionalFee.ourFee || 0).toString())}</td>
                            </tr>
                            <tr className={styles.balanceRow}>
                                <td><b>Total:</b> </td>
                                <td>
                                    ₦ { formatAmount(balance.toString()) }
                                </td>
                            </tr>
                            <tr className={styles.paymentRow}>
                                <td>
                                    <Dropdown
                                        valueCollector={handleRepaymentSelection}
                                        useObjectValues
                                        optionsObj={options}
                                        className={`${styles.dropdown} ${!cardVerified && styles.bluryText}`}
                                    />
                                </td>
                                <td>
                                    <b>
                                    ₦ {
                                        repayment > 0 ?
                                        ( 
                                            balance % repayment === 0 ?
                                            formatAmount((balance/repayment).toString())
                                            :
                                            formatAmount((balance/repayment).toFixed(2).toString())
                                        )
                                        :
                                        0
                                    }
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Select repayment date: </b></td>
                                <td>
                                <TextInput 
                                    name="paymentStartDate"
                                    hideDefaultAdornment
                                    type='date'
                                    //useValue={startDate}
                                    onValueChange={handleDateChange}
                                    //className="mb-30"
                                />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
      {/* </div> */}
    </div>
  );
};

export default Installment;
