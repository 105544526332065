import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import LoadingContext from "../../contexts/LoadingContext";
import {
  updateSaveForRent,
  useSavingsTowardsRent,
  DeleteSavings,
  ReleaseSavings,
  getTargetSavings,
} from "../../swr/saveForRent";
import {
  PaymentFrequency,
  ReleaseSavingsRequest,
  SavingsTowardsRentRequest,
  TargetSaving,
  updateSavingsRequest,
} from "../../types/Savings";
import { generatePaymentSchedule } from "../../utils/fundingSourceUtils";
import { getWalletId, toDateStringRestriction } from "../../utils/generalUtils";
import Dropdown from "../FormElements/Dropdown";
import TextInput from "../FormElements/TextInput";
import NavHeader from "../NavHeader";
import ToastContext from "../../contexts/ToastContext";
import { genMsgId } from "../../utils/propertyUtils";
import styles from "./UpdateSaving.module.scss";
import PaymentScheduleSummaryDialog from "../Dialogs/PaymentScheduleSummary";

const UpdateSavings = () => {
  let frequencyOptions = Object.values(PaymentFrequency).filter(
    (item) => typeof item === "string"
  );

  const { openError, openSuccess } = useContext(ToastContext);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  let uid = localStorage.getItem("id") as string;
  const token = localStorage.getItem("token") as string;
  const email = localStorage.getItem("email") as string;
  const savingsResult = useSavingsTowardsRent(uid, token);
  const walletId = getWalletId(token);
  let savings: any | undefined;


  // console.log(savings);

  const [targetSavings, setTargetSavings] = useState<TargetSaving[] | undefined>()

  if (targetSavings) {
    savings = targetSavings.find((item) => item.idForUpdate.toString() === id);
  }

  const GetTargetSavings = async () => {
    const response = await getTargetSavings(token)
    if(!response){
      openError('Error fetching target savings')
      return
    }
    if(response.status !== 200 || !response.data){
      openError(response.Message ?? (response.data && response.data.message))
      return
    }
    if(response.data){
      const res = response.data;
      setTargetSavings(res.data)
    }
  }

  useEffect(() => {
    GetTargetSavings()
  }, [])

  const getFrequencyEnum = () => {
    const options = ["Daily", "Weekly", "Biweekly", "Monthly", "Quarterly"];
    for (let i = 0; i < options.length; i++) {
      if (savings && savings.frequency === 0) {
        return "Daily";
      }
      if (savings && savings.frequency === 1) {
        return "Weekly";
      }
      if (savings && savings.frequency === 2) {
        return "Biweekly";
      }
      if (savings && savings.frequency === 3) {
        return "Monthly";
      }
      if (savings && savings.frequency === 4) {
        return "Quarterly";
      }
    }
  };

  const [open, setOpen] = useState(false);
  const [request, setRequest] = React.useState<updateSavingsRequest | null>(
    null
  );

  const [fomData, setFormData] = useState<ReleaseSavingsRequest>({
    release_amount: "",
    release_remark: "",
    msg_id: genMsgId(),
    hold_id: savings && savings.description && savings.description,
  });

  const HandleSuccess = () => {
    setLoading(false);
    openSuccess("savings released successfully");
    history.push("save-for-rent");
  };

  const HandleFailure = () => {
    setLoading(false);
    openError("savings released successfully");
  };

  const HandleSubmit = async () => {
    setLoading(true);
    const res = await ReleaseSavings(fomData, token);
    if (res && res.message) {
      let msg = res.message;
      msg === "Successful Transaction" ? HandleSuccess() : HandleFailure();
    } else {
      HandleFailure();
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const res = initializeReq();
    if (res === "true") {
      setOpen(true);
    }
  };

  const [formData, setformData] = React.useState<updateSavingsRequest>({
    id: Number(id),
    name: savings && savings.title ? savings.title : "",
    description: savings && savings.savingsType ? savings.savingsType : "",
    userId: savings && savings.userId ? savings.userId : '',
    userEmail: email ? email : "",
    fundingSourceId: 0,
    paymentFrequency: getFrequencyEnum(),
    creditAccount: walletId,
    targetedAmount:
      savings && savings.totalAmount ? savings.totalAmount : "",
    savingsAmount:
      savings && savings.amountPerCycle ? savings.amountPerCycle : "",
    paymentStartDate:
      savings && savings.startDate ? savings.startDate : "",
    paymentScheduleArray: [] || "",
  });

  const { setLoading } = React.useContext(LoadingContext);

  const onChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSuccess = (msg: string) => {
    setLoading(false);
    openSuccess(msg);
    history.push("/account");
  };
  const handleFailure = (msg: string) => {
    setLoading(false);
    openError(msg);
  };

  const FormRef = React.useRef<HTMLFormElement | null>(null);

  const initializeReq = () => {
    let fData;
    FormRef.current ? (fData = new FormData(FormRef.current)) : new FormData();
    const payFreq = fData?.get("paymentFrequency");
    let pFreq =
      PaymentFrequency[
        fData
          ?.get("paymentFrequency")
          ?.toString() as keyof typeof PaymentFrequency
      ];
    let paymentScheduleArray = generatePaymentSchedule({
      targetedAmount: Number(
        formData.targetedAmount
          ? formData.targetedAmount
          : savings && savings.totalAmount
      ),
      savingsAmount: Number(
        formData.savingsAmount ? formData.savingsAmount : savings && savings.amountPerCycle
      ),
      startDate: formData.paymentStartDate
        ? formData.paymentStartDate
        : savings && savings.startDate,
      paymentFrequency: payFreq ? pFreq : savings && savings.frequency,
      creditAccount: walletId,
    });

    let request: any = {
      id,
      name: formData.name ? formData.name : savings.title,
      description: formData.description
        ? formData.description
        : savings && savings.savingsType,
      // userId: uid,
      userEmail: email,
      //fundingSourceId: chosenFundingSource?.id || 0,
      paymentFrequency: payFreq ? pFreq : savings && savings.frequency,
      targetedAmount: formData.targetedAmount
        ? formData.targetedAmount
        : savings && savings.totalAmount,
      savingsAmount: formData.savingsAmount
        ? formData.savingsAmount
        : savings && savings.amountPerCycle,
      paymentStartDate: formData.paymentStartDate
        ? formData.paymentStartDate.toString()
        : savings && savings.startDate,
      paymentScheduleArray: paymentScheduleArray,
    };
    setRequest(request);
    if (request) {
      return "true";
    }
  };

  const handleSubmit = async () => {
    handleDialogClose();
    setLoading(true);
    const response = await updateSaveForRent(request, token);
    // {"succeeded":true,"message":"Successfully updated Savings towards rent.","errors":null,"data":null,"meta":null}
    if (response && response.data) {
      let res = response.data;
      res.succeeded === true &&
      res.message === "Successfully updated Savings towards rent."
        ? handleSuccess("Successfully updated savings")
        : handleFailure(res.message);
    } else {
      handleFailure("an error occured while updating saving");
    }
  };

  const handleDelete = async () => {
    const response = await DeleteSavings(id, uid, token);
    //{"succeeded":true,"message":"Successfully deleted Savings towards rent.","errors":null,"data":null,"meta":null}
    if (response && response.data) {
      let res = response.data;
      res.succeeded === true &&
      res.message === "Successfully deleted Savings towards rent."
        ? handleSuccess("Successfully deleted savings")
        : openError(res.message ? res.message : "there's an error deleting savings");
    }else{
      //@ts-ignore
      openError(response.message ? response.message : response.Message ? response.Message : "there's an error deleting savings")
    }
  };

  const goBack = () => {
    history.push("/account");
  };

  const [Amount, setAmount] = React.useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAmount = e.target.value;

    // Remove existing commas from the input Amount
    const sanitizedAmount = inputAmount.replace(/,/g, "");

    // Format the Amount with commas
    const formattedAmount = Number(sanitizedAmount).toLocaleString();

    setAmount(formattedAmount);
  };

  const HandleReleaseSuccess = () => {
    setLoading(false);
    openSuccess("savings released successfully");
    setAmount("");
    history.push("/account");
  };

  const HandleReleaseFailure = (msg?: string) => {
    setLoading(false);
    setAmount("");
    openError(msg ? msg : "an error occurred");
  };

  const releaseSavingsReq = {
    release_amount: Amount && Amount.split(",").join(""),
    release_remark: "",
    msg_id: genMsgId(),
    email,
    wallet_id: walletId,
    hold_id: savings && savings.description && savings.description,
  };

  const handleReleaseSubmit = async () => {
    setLoading(true);
    const res = await ReleaseSavings(releaseSavingsReq, token);
    if (res && res.message) {
      let msg = res.message;
      msg === "Successful Request"
        ? HandleReleaseSuccess()
        : HandleReleaseFailure(msg);
    } else {
      HandleReleaseFailure();
    }
  };

  return (
    <div
      style={{
        marginBottom: "3em",
      }}
    >
      <PaymentScheduleSummaryDialog
        open={open}
        paymentSchedules={request?.paymentScheduleArray}
        onClose={handleDialogClose}
        onConfirm={handleSubmit}
        state={request}
      />
      <NavHeader pageTitle={"Savings"} centerNav= {true} />
      <button
        className="btn theme-btn-1 btn-effect-1 text-uppercase"
        type="button"
        style={{ marginLeft: "6.5em", marginBottom: "3em",  ...(window.innerWidth <= 768 && {
          marginLeft: '5em',
        }), }}
        onClick={goBack}
      >
        Go Back
      </button>

      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: "65%",
          width: "30%",
          height: '2em',
          // marginRight: '3%',
          marginBottom: "1em",
          ...(window.innerWidth <= 768 && {
            marginLeft: "12%",
          }),
        }}
      >
        <div
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          style={{
            height: "100%",
            width: "55%",
            marginBottom: '0em',
            ...(window.innerWidth <= 768 && {
              marginLeft: "20%",
            }),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => handleReleaseSubmit()}
        >
          <span>release</span>
        </div>

        <input
          style={{
            height: "100%",
            width: "10em",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginBottom: '0em',
            ...(window.innerWidth <= 768 && {
              width: "7.5em",
              fontWeight: "thin",
            }),
          }}
          placeholder="Amount"
          type="text"
          value={Amount}
          onChange={handleInputChange}
        />
      </div> */}
      {savings ? (
        <>
          <section style={{ width: "75%", margin: "auto",  ...(window.innerWidth <= 768 && {
              width: '65%'
            }), }}>
            <form ref={FormRef}>
              <div className="row">
                <div className="col-md-6">
                  <label>Title</label>
                  <TextInput
                    name="name"
                    hideDefaultAdornment
                    onChange={onChange}
                    defaultValue={
                      savings && savings.title ? savings.title : formData.name
                    }
                  />
                </div>

                <div className="col-md-6">
                  {" "}
                  <label>Overall Target Amount</label>
                  <TextInput
                    name="targetedAmount"
                    hideDefaultAdornment
                    variant="amount"
                    defaultValue={savings && savings.totalAmount}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>How frequent do you want to make paymenyts ?</label>
                  <Dropdown
                    placeholder=""
                    name="paymentFrequency"
                    options={frequencyOptions}
                    selectProps={{ required: true }}
                    value={getFrequencyEnum()}
                  />
                </div>
                <div className="col-md-6">
                  <label>How much do you want saved each period ?</label>
                  <TextInput
                    name="savingsAmount"
                    hideDefaultAdornment
                    variant="amount"
                    defaultValue={savings && savings.amountPerCycle}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <p>
                    Current Start Date:{" "}
                    <b>
                      {savings &&
                        savings.startDate.toString().slice(0, 10)}
                    </b>
                  </p>
                  <label>When do you now want to start ?</label>
                  <TextInput
                    name="paymentStartDate"
                    hideDefaultAdornment
                    type="date"
                    min={toDateStringRestriction(new Date())}
                    className="mb-30"
                    defaultValue={savings && savings.startDate}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div style={{
                ...(window.innerWidth <= 768 && {
                  display: 'flex',
                  justifyContent: 'space-between'
                }),
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <button
                  className="btn theme-btn-1 btn-effect-1 text-uppercase"
                  type="button"
                  onClick={handleConfirm}
                  style={{
                    ...(window.innerWidth <= 768 && {
                      height: '3em'
                    }),
                  }}
                >
                  {window.innerWidth <= 768 ? 'Update' : 'Update Savings'}
                  
                </button>
                <button
                  className="btn theme-btn-1 btn-effect-1 text-uppercase"
                  type="button"
                  onClick={() => handleDelete()}
                  style={{
                    ...(window.innerWidth <= 768 && {
                      height: '3em'
                    }),
                  }}
                >
                  {window.innerWidth <= 768 ? 'Delete' : 'Delete Savings'}
                </button>
              </div>
            </form>
          </section>
        </>
      ) : (
        <b
        style={{
          margin: 'auto'
        }}
        >Saving does not exist</b>
      )}
    </div>
  );
};

export default UpdateSavings;
