import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Layout from '../components/Layout';
import NavHeader from '../components/NavHeader';
import ScrollToTop from '../components/ScrollToTop';
import SessionCheck from '../components/SessionCheck';
import SaveForRent from '../sections/SaveForRent';

const SaveForRentPage: React.FC<any> = ()=>{

    const dispatch = useDispatch();

    return(
        <React.Fragment>
            <ScrollToTop/>
            <Layout>
                <SessionCheck shouldRedirect/>
                <NavHeader
                    pageTitle="Add Savings"
                    routeName="Add Savings"
                />
               <SaveForRent/>
            </Layout>
        </React.Fragment>
    )
}

export default SaveForRentPage;