import Button from '@mui/material/Button';
import React from 'react';
import { PaymentSchedule } from '../../types/Savings';
import { toScheduleDateString } from '../../utils/generalUtils';
import { formatAmount } from '../../utils/propertyUtils';
import styles from './schedule.module.scss';

export interface ScheduleOverviewProps{
    paymentScheduleArray?: PaymentSchedule[]
}

const ScheduleOverview: React.FC<ScheduleOverviewProps> = ({ paymentScheduleArray = [] })=>{

    // For later - fa-times

    // Shortened array
    const findSliceIndex = ()=>{
        let index = 1;
        for (let i = 0; i < paymentScheduleArray.length; i++) {
            const schedule = paymentScheduleArray[i];

            if (new Date(schedule.debitDate).getTime() > new Date().getTime()) {
                index+=i;
                break;
            }
            
        }

        return index;
    }
    const shortenedArray = paymentScheduleArray.length > 0 ? paymentScheduleArray.slice(0,findSliceIndex()) : [];

    // State
    const [seeFullList, setSeeFullList] = React.useState<boolean>(false);

    // Handlers
    const handleSeeMore = ()=>{
        setSeeFullList(true)
    }
    const handleSeeLess = ()=>{
        setSeeFullList(false)
    }

    return(
        <>
        <ul className={styles.list}>
            {   !seeFullList &&
                shortenedArray?.map((schedule, index)=>{
                    if (new Date(schedule.debitDate).getTime() < new Date().getTime() ) {
                        return(
                            <div className={styles.schedule}>
                                <div className={styles.date}>
                                { toScheduleDateString(new Date(schedule.debitDate)) }  
                                </div>
                                <li className={styles.stepperItem}>
                                    <div className={styles.circleArea}>
                                        <div className={`${styles.circle} ${styles.paid}`}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                        </div>
                                        <div className={styles.connector}></div>
                                    </div>
                                    <div className={styles.stepContent}>
                                        <h6>Payment {index + 1}</h6>
                                        <p>₦ {formatAmount(schedule.debitAmount.toString())}</p>
                                    </div>
                                </li>
                            </div>
                        )
                    }
                    else{
                        return(
                            <div className={styles.schedule}>
                                <div className={styles.date}>
                                { toScheduleDateString(new Date(schedule.debitDate)) }  
                                </div>
                                <li className={styles.stepperItem}>
                                    <div className={styles.circleArea}>
                                        <div className={styles.circle}>
                                            <i className="fa fa-circle" aria-hidden="true"></i>
                                        </div>
                                        <div className={styles.connector}></div>
                                    </div>
                                    <div className={styles.stepContent}>
                                        <h6>Payment {index + 1}</h6>
                                        <p>₦ {formatAmount(schedule.debitAmount.toString())}</p>
                                    </div>
                                </li>
                            </div>
            
                        )
                    }
                })
            }
            {
                seeFullList &&
                paymentScheduleArray?.map((schedule, index)=>{
                    if (new Date(schedule.debitDate).getTime() < new Date().getTime() ) {
                        return(
                            <div className={styles.schedule}>
                                <div className={styles.date}>
                                { toScheduleDateString(new Date(schedule.debitDate)) }  
                                </div>
                                <li className={styles.stepperItem}>
                                    <div className={styles.circleArea}>
                                        <div className={`${styles.circle} ${styles.paid}`}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                        </div>
                                        <div className={styles.connector}></div>
                                    </div>
                                    <div className={styles.stepContent}>
                                        <h6>Payment {index + 1}</h6>
                                        <p>₦ {formatAmount(schedule.debitAmount.toString())}</p>
                                    </div>
                                </li>
                            </div>
                        )
                    }
                    else{
                        return(
                            <div className={styles.schedule}>
                                <div className={styles.date}>
                                { toScheduleDateString(new Date(schedule.debitDate)) }  
                                </div>
                                <li className={styles.stepperItem}>
                                    <div className={styles.circleArea}>
                                        <div className={styles.circle}>
                                            <i className="fa fa-circle" aria-hidden="true"></i>
                                        </div>
                                        <div className={styles.connector}></div>
                                    </div>
                                    <div className={styles.stepContent}>
                                        <h6>Payment {index + 1}</h6>
                                        <p>₦ {formatAmount(schedule.debitAmount.toString())}</p>
                                    </div>
                                </li>
                            </div>
            
                        )
                    }
                })
            }

            
        </ul>
        <div>
            {!seeFullList && <Button
                onClick={handleSeeMore} 
                sx={{ 
                    textTransform: 'none', 
                    fontSize: '16px',
                    color: 'var(--section-bg-2)' 
                }}
            >
                ... See more
            </Button>}
            {seeFullList &&<Button
                onClick={handleSeeLess} 
                sx={{ 
                    textTransform: 'none', 
                    fontSize: '16px',
                    color: 'var(--section-bg-2)' 
                }}
            >
                ... See less
            </Button>}
        </div>
        </>
    )
}

export default ScheduleOverview;