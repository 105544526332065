import React from "react";
import styles from "./transTable.module.scss";
import { Button } from "@mui/material";

const FundWalletModal = ({
  closeModal,
  walletId,
}: {
  closeModal: () => void;
  walletId: string | undefined;
}) => {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.fund}>
        <span style={{
            alignSelf: 'center', margin: '1em'
        }}>{`To fund your wallet, transfer to ${walletId}. Select ACCESS BANK as the destination bank.`}</span>
        <Button
        type="button"
        variant="contained"
        sx={{
          textAlign: "center",
          backgroundColor: "var(--ltn__secondary-color)",
          "&:hover": {
            backgroundColor: "var(--ltn__paragraph-color)",
          },
          // paddingTop: "1.8em",
          height: "3em",
          display: "flex",
          alignItems: "center",
          marginTop: "0.5em",
          justifyContent: "center",
          marginBottom: '0.5em'
        //   alignSelf: "flex-start",
        }}
        onClick={() => closeModal()}
      >
        Close
      </Button>
      </div>
      
    </div>
  );
};

export default FundWalletModal;
