import React from 'react'
import Layout from '../../components/Layout'
import NavHeader from '../../components/NavHeader'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'
import SingleRentInsurance from '../../views/Insurance/AdminInsurance/SingleRentInsurance'

const SingleInsurance = () => {
  return (
      <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader pageTitle="View Rent Insurance Applicants" routeName="View Insurances" />
      <SingleRentInsurance />
    </Layout>
  )
}

export default SingleInsurance