import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import { ResetWalletPin, generateOtp } from "../../../swr/wallet";
import { useQuery } from "../../../hooks/useQuery";
import { useHistory, useParams } from "react-router-dom";
import NavHeader from "../../NavHeader";
import reset from "./Resetpin.module.scss";
import ToastContext from "../../../contexts/ToastContext";
import Modal from "react-modal";
import OtpResetPin from "../OtpForPinReset";
import { getWalletId } from "../../../utils/generalUtils";
import LoadingContext from "../../../contexts/LoadingContext";
import { log } from "console";

// import {useNavigate} from 'react-router-dom';

interface ResetPinProps {
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
}

const customStyles = {
  content: {
    top: "54%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    //   border: '1px solid white',
    //   borderRadius: "0",
    zIndex: "1",
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
  },
};

const ResetPin: React.FC<ResetPinProps> = ({ onClose, setIsOpen }) => {
  // const [email, setEmail] = useState('');
  const { setLoading } = useContext(LoadingContext);
  const { openError, openSuccess } = useContext(ToastContext);
  const email = localStorage.getItem("email") as string;
  const token = localStorage.getItem("token") as string;
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const walletId = getWalletId(token) as string;

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const history = useHistory();
  const [otp, setOtp] = useState("");

  const params: { propertyId: string } = useParams();

  let propertyId = params.propertyId;

  const handleOtpSent = () => {
    setLoading(false);
    openSuccess("Check your email for OTP");
    openModal();
  };

  const handleOtpFailure = () => {
    setLoading(false);
    openError("Error sending OTP");
  };

  const handleInitialSubmit = async () => {
    setLoading(true);
    try {
      const response = await generateOtp(token);
      if (response && response.succeeded) {
        // let msg = response.response_data
        // msg.sms_response_message &&
        response.succeeded === true ? handleOtpSent() : handleOtpFailure();
      }
    } catch (error) {
      handleOtpFailure();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!pin || !confirmPin) {
      openError("All fields are required");
      return;
    }
    if (pin.length !== 4) {
      openError("Pin can only be four digits");
      return;
    }
    if (pin !== confirmPin) {
      openError("Pin does not match");
      return;
    }

    handleInitialSubmit();
  };

  const goBack = () => {
    history.push(`/account#liton_tab_1_6`);
  };

  const handleResetSuccess = () => {
    openSuccess("Pin Reset Successful");
    setLoading(false);
    if (propertyId) {
      history.push(`/payment?property=${propertyId}`);
    } else {
      history.push("/account");
    }
  };

  const handleErrorPinReset = (err: string | undefined) => {
    setLoading(false);
    openError(err ? err : "error resetting pin");
  };

  const resetWalletPin = async () => {
    ResetWalletPin({
      email,
      pin,
      token,
      otp,
    })
      .then((res) => {
        res.data?.succeeded === true
          ? handleResetSuccess()
          : handleErrorPinReset(
              res.data?.message ?? "an error occurred resetting pin"
            );
      })
      .catch((err) => {
        handleErrorPinReset(err.data.Message ?? err.data.message);
      });
    // try {
    //   const res = await ResetWalletPin({
    //     email,
    //     pin,
    //     token,
    //     otp
    //   });
    //   if(!res){
    //     openError('an error occurred')
    //     return
    //   }
    //   if (res && res.data) {
    //     console.log('first', res.error?.response?.data.response_message);

    //     let response = res.data;

    //   } else {
    //     handleErrorPinReset(res.error?.response?.data.Message ?? res.error?.response?.data.message);
    //   }
    // } catch (err) {
    //   // @ts-ignore
    //   let error: AxiosError<ValidationErrors> = err;
    //   if (!error.response) {
    //     throw err;
    //   }
    //   console.log(error.response.data);
    //   handleErrorPinReset(error.response.data.Message);
    // }
  };

  return (
    <>
      <NavHeader pageTitle="Reset Pin" />
      <button
        className="btn theme-btn-1 btn-effect-1 text-uppercase"
        type="button"
        style={{
          marginLeft: "12.1em",
          marginBottom: "4em",
          marginTop: "0em",
          ...(window.innerWidth <= 768 && {
            marginLeft: "2.1em",
          }),
        }}
        onClick={goBack}
      >
        Go Back
      </button>
      <section className={reset.section}>
        <form onSubmit={handleSubmit} className={reset.form}>
          <div>
            <label htmlFor="pin">New Transaction Pin(4-digit):</label>
            <input
              type="password"
              // id="pin"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              className={reset.input}
              autoComplete="off"
            />
          </div>
          <div>
            <label htmlFor="transactionPin">Confirm Transaction Pin:</label>
            <input
              type="password"
              // id="transactionPin"
              value={confirmPin}
              onChange={(e) => setConfirmPin(e.target.value)}
              className={reset.input}
              autoComplete="off"
            />
          </div>
          <div>
            <Button
              type="submit"
              variant="contained"
              sx={{
                textAlign: "center",
                backgroundColor: "var(--ltn__paragraph-color)",
                "&:hover": {
                  backgroundColor: "var(--ltn__secondary-color)",
                },
              }}
            >
              Submit
            </Button>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            // appElement={document.getElementById('root') as HTMLElement}
          >
            <OtpResetPin
              closeModal={closeModal}
              resetPin={resetWalletPin}
              email={email}
              otp={otp}
              setOtp={setOtp}
            />
          </Modal>
        </form>
      </section>
    </>
  );
};

export default ResetPin;
