import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AttendRentInsurance,
  getRentInsuranceDocs,
  getSingleRentInsurance,
} from "../../../swr/insurance";
import { InsuranceObj, SingleInsurance } from "../../../types/Insurance";
import ToastContext from "../../../contexts/ToastContext";
import LoadingContext from "../../../contexts/LoadingContext";
import {
  formatAmount,
  returnMsgFromAxiosError,
} from "../../../utils/generalUtils";
import styles from "./admin-rent.module.scss";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setRentInsuranceDocs } from "../../../state/reducers/insuranceSlice";
import { RootState } from "../../../state/store";
import ViewDocuments from "./ViewDocuments";

const returnTitleStr = (title: number) => {
  switch (title) {
    case 1:
      return "MR";
    case 2:
      return "MRS";
    case 3:
      return "MISS";
    default:
      return "MASTER";
  }
};

const returnGenderStr = (gender?: number) => {
  if (!gender) {
    return;
  }
  switch (gender) {
    case 1:
      return "MALE";
    case 2:
      return "FEMALE";
    default:
      return "OTHER";
  }
};

const SingleRentInsurance = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { rentInsuranceDocs } = useSelector(
    (state: RootState) => state.insurance
  );
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const [insurance, setInsurance] = useState({} as SingleInsurance);
  const token = localStorage.getItem("token") as string;

  const getRentInsuranceById = async (id: string, token: string) => {
    const { data, error } = await getSingleRentInsurance(id, token);
    if (data && data.data) {
      setLoading(false);
      setInsurance(data.data);
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "Unable to fetch insurance policy")
      );
      return;
    }

    openError("Unable to fetch insurance policy");
    setLoading(false);
    return;
  };

  const getRentInsuranceDocsById = async (id: string, token: string) => {
    const { data, error } = await getRentInsuranceDocs(id, token);

    if (data) {
      dispatch(setRentInsuranceDocs(data.data));
      return;
    }

    if (error) {
      openError(
        returnMsgFromAxiosError(error, "Unable to fetch insurance documents")
      );
      return;
    }

    openError("Unable to fetch insurance documents");
  };

  const goBack = () => {
    history.push("/viewrentinsurance");
  };

  const markAsRead = async (id: string, status: boolean, token: string) => {
    setLoading(true);
    const { data, error } = await AttendRentInsurance(id, status, token);
    if (data) {
      setLoading(false);
      openSuccess("Update Successful");
      setTimeout(() => {
        goBack();
      }, 500);
      return;
    }

    if (error) {
      setLoading(false);
      openError(returnMsgFromAxiosError(error, "Unable to complete update"));
      return;
    }

    openError("Unable to complete update");
    setLoading(false);
    return;
  };

  const landLordDocuments =
    rentInsuranceDocs &&
    rentInsuranceDocs.map((doc) => {
      if (doc.imageTitle.startsWith("LANDLORD")) {
        return doc.imageUrl;
      } else {
        return "";
      }
    });

  const tenantDocuments =
    rentInsuranceDocs &&
    rentInsuranceDocs.map((doc) => {
      if (doc.imageTitle.startsWith("TENANT")) {
        return doc.imageUrl;
      } else {
        return "";
      }
    });

  useEffect(() => {
    if (id !== undefined) {
      getRentInsuranceById(id, token);
      getRentInsuranceDocsById(id, token);
    }
  }, [id]);

  return (
    <div className={styles["single-cont"]}>
      <div className={styles.header}>
        <div className={styles.headerblock}></div>
        <div className={styles.headertext}>Landlord Details</div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles.inputcolumn}>
          <span>Title</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={returnTitleStr(insurance && insurance?.landLordTitle)}
            readOnly
          />
        </div>
        <div className={styles["inputcolumn-65"]}>
          <span>Date Created</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={
              insurance.dateCreated
                ? new Date(insurance?.dateCreated).toLocaleString("NG")
                : ""
            }
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles.inputcolumn}>
          <span>First Name</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={
              insurance?.landLordFirstName || insurance?.user?.firstName
            }
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Middle Name</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.landLordMiddleName}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Last Name</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={
              insurance?.landLordLastName || insurance?.user?.lastName
            }
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles.inputcolumn}>
          <span>Gender</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={returnGenderStr(insurance?.landLordGender)}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Email</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.landLordEmail || insurance?.user?.email}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Phone Number</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={
              insurance?.landLordPhoneNumber || insurance?.user?.phoneNumber
            }
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles["inputcolumn-65"]}>
          <span>Address</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.landLordAddress}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Occupation</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.landLordOccupation}
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles["input-column-full"]}>
          <span>Date Of Birth</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={
              insurance.landLordDateofBirth
                ? new Date(insurance?.landLordDateofBirth).toLocaleString()
                : ""
            }
            readOnly
          />
        </div>
      </div>

      <div className={styles.header}>
        <div className={styles.headerblock}></div>
        <div className={styles.headertext}>Tenant Details</div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles.inputcolumn}>
          <span>Title</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={returnTitleStr(insurance?.tenantTitle)}
            readOnly
          />
        </div>
        <div className={styles["inputcolumn-65"]}>
          <span>Date Of Birth</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={
              insurance.tenantDateofBirth
                ? new Date(insurance?.tenantDateofBirth).toLocaleString()
                : ""
            }
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles.inputcolumn}>
          <span>First Name</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.tenantFirstName}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Middle Name</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.tenantMiddleName}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Last Name</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.tenantLastName}
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles.inputcolumn}>
          <span>Gender</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={returnGenderStr(insurance?.tenantGender)}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Email</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.tenantEmail}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Phone Number</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.tenantPhoneNumber}
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles["inputcolumn-65"]}>
          <span>Address</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.tenantAddress}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Occupation</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.tenantOccupation}
            readOnly
          />
        </div>
      </div>

      <div className={styles.header}>
        <div className={styles.headerblock}></div>
        <div className={styles.headertext}>Product Details</div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles.inputcolumn}>
          <span>Product Purchased</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.productPurchased}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Quote (₦)</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.quote ? formatAmount(insurance.quote) : ""}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Duration</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.duration === 0 ? "3 months" : "6 months"}
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles.inputcolumn}>
          <span>Property Title</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.propertyTitle}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Units</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.units}
            readOnly
          />
        </div>
        <div className={styles.inputcolumn}>
          <span>Annual Rent Income (₦)</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={
              insurance?.annualRentalIncome
                ? formatAmount(insurance.annualRentalIncome)
                : ""
            }
            readOnly
          />
        </div>
      </div>

      <div className={styles.columngrid}>
        <div className={styles["input-column-full"]}>
          <span>Property Address</span>
          <input
            className={styles.inputfield}
            autoComplete="off"
            value={insurance?.propertyAddress}
            readOnly
          />
        </div>
      </div>

      {landLordDocuments && landLordDocuments.length ? (
        <>
          <div className={styles.header}>
            <div className={styles.headerblock}></div>
            <div className={styles.headertext}>Landlord Documents</div>
          </div>
          <ViewDocuments docs={landLordDocuments} />
        </>
      ) : null}

      {tenantDocuments && tenantDocuments.length ? (
        <>
          <div className={styles.header}>
            <div className={styles.headerblock}></div>
            <div className={styles.headertext}>Tenant Documents</div>
          </div>
          <ViewDocuments docs={tenantDocuments} />
        </>
      ) : null}

      <div className={styles["container-bottom-butt"]}>
        <span onClick={() => goBack()} role="button">
          Back
        </span>

        <div
          className={styles["continue-butt"]}
          role="button"
          onClick={() => markAsRead(id, true, token)}
        >
          <span>Mark As Completed</span>
        </div>
      </div>
    </div>
  );
};

export default SingleRentInsurance;
