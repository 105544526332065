import React, { SetStateAction, useState } from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router";
import styles from "./bookingTable.module.scss";
import { TiTick } from "react-icons/ti";
import { TiTimes } from "react-icons/ti";
import { useProduct } from "../../swr/properties";
import { Inspection } from "../../types/Inspection";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RoomIcon from "@mui/icons-material/Room";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import { formatDate } from "../../utils/propertyUtils";
import Overlay from "./CommentOverlay";
import { useBookings } from "../../swr/inspection";

interface CommentModalProps {
  sendInspectionRes: (
    id: number | undefined,
    email: string | undefined,
    accept: boolean,
    propertyId: string | undefined,
    comment?: string | undefined
  ) => Promise<string>;
  closeModal: () => void;
  item: Inspection;
  status: string | undefined;
  openConfirmReject: () => void;
  comments: string;
  setComments: React.Dispatch<SetStateAction<string>>
}

const CommentModal: React.FC<CommentModalProps> = ({
  item,
  sendInspectionRes,
  status,
  closeModal,
  openConfirmReject,
  comments,
  setComments
}) => {
  const [onFocus, setonFocus] = useState(false);
  const history = useHistory();
  const token = localStorage.getItem("token") as string;

  const [reject, setReject] = useState(true)

  const handleInputClick = () => {
    setonFocus(true);
  };

  const { product } = useProduct(item.propertiesId);

  useBookings(token)

  const handleSubmit = async (action: boolean) => {
    closeModal();
    const response = await sendInspectionRes(
      item.id,
      item.email,
      action,
      item.propertiesId.toString(),
      comments
    );
    if (response === "success") {
      
      history.push("/account#liton_tab_1_7");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-start",
          fontFamily: "sans-serif",
        }}
      >
        <div
          className={styles.backButton}
          role="button"
          onClick={() => closeModal()}
        >
          <KeyboardBackspaceIcon sx={{ color: "#3A3A3A" }} fontSize="small" />
          <span>Back</span>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.cardData}>
          <div className={styles.propTitleContainer}>
            <span className={styles.propTitle}>{product && product.title}</span>
            <div className={styles.address}>
              <RoomIcon
                sx={{ color: "#91EA91", marginTop: "0.2em" }}
                fontSize="small"
              />
              <span>{product && product.address}</span>
            </div>
          </div>

          <div className={styles.meta}>
            <div className={styles.metadata}>
              <span>{product && product.type ? product.type : "Houses"}</span>
            </div>
            <div className={styles.metadata}>
              <span>{`For ${product && product.status}`}</span>
            </div>
            <div className={styles.metadata}>
              <span>
                {product && (product.isEnabled ? "Verified" : "Unverified")}
              </span>
            </div>
          </div>

          <div className={styles.meta2}>
            <div className={styles.meta2data}>
              <DateRangeIcon sx={{ color: "#91EA91" }} fontSize="small" />
              <span>
                {product &&
                  product.dateAdded &&
                  formatDate(new Date(product.dateAdded))}
              </span>
            </div>
            <div className={styles.meta2data}>
              <MarkChatReadIcon sx={{ color: "#91EA91" }} fontSize="small" />
              <span>
                {product &&
                  `${
                    product.comments?.length ? product.comments.length : 0
                  } Comments`}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.imgContainer}>
          {product &&
          product.attachments &&
          product.attachments[0] &&
          product.attachments[0].imageUrl ? (
            <img
              src={product.attachments[0].imageUrl}
              alt="property image"
              loading="lazy"
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <div className={styles.message}>
        <div className={styles.msghead}>
          <div className={styles.bar}></div>
          <span>
            Message from{" "}
            {item && item.user && item.user.firstName
              ? item.user.firstName
              : item && item.name
              ? item.name
              : ""}
          </span>
        </div>

        <div className={styles.msgTxt}>
          <span>{item && item.comments}</span>
        </div>
      </div>

      {status !== "Accepted" ? (
        <>
          <div className={styles.message}>
            <div className={styles.msghead}>
              <div className={styles.bar}></div>
              <span>Your reply</span>
            </div>

            <div className={styles.input}>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                style={{
                  height: "100%",
                  border: "1px solid hsl(0, 5%, 80%)",
                  backgroundColor: onFocus ? "hsl(0, 5%, 98%)" : "",
                  borderRadius: "1em",
                  outline: "none",
                }}
                onFocus={handleInputClick}
                className={onFocus ? "highlight" : ""}
                autoComplete="off"
              />
            </div>

            <div className={styles.buttons}>
              <div className={styles.buttonInner}>
                <div
                  className={styles.button}
                  role="button"
                  onClick={() => handleSubmit(true)}
                >
                  <span>Accept</span>
                </div>
                <div
                  className={styles.button1}
                  role="button"
                  // onClick={() => handleSubmit(false)}
                  onClick={() => openConfirmReject()}
                >
                  <span>Reject</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.accDiv}>
          <div className={styles.bar}></div>

          <div className={styles.acceptedTxt}>
            <span>You have accepted this request</span>
          </div>
        </div>
      )}

{/* {
       reject === true && 
        <Overlay>
          <p>Game</p>
        </Overlay>
      } */}
    </>
  );
};

export default CommentModal;
