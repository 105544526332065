import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { Transaction, TransactionType } from "../../types/Transaction";
import { toDateString } from "../../utils/generalUtils";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import styles from "./transTable.module.scss";
import { formatAmount } from "../../utils/propertyUtils";
import { useHistory } from "react-router";
import Modal from "react-modal";
import TransactionModal from "./TransactionModal";
import FundWalletModal from "./FundWalletModal";
import { Button } from "@mui/material";
// import { getTransactionHistoryById } from '../../swr/wallet';

export interface TransactionTableProps {
  transactions?: Transaction[];
  title?: string;
  walletID?: string;
}

const TransactionsTable: React.FC<TransactionTableProps> = ({
  transactions,
  title,
  walletID,
}) => {
  // State
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [transaction, setTransaction] = useState<any>([]); // Rename state variable to "transactions"
  const history = useHistory();

  // Handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const token = localStorage.getItem("token") as string;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goToResetPin = () => {
    history.push("/resetpin");
  };

  // Cell Render Function
  const renderAmountCell = (
    amount: number,
    type: TransactionType
  ) => {
    let colorClass = type === "C" ? styles.credit : styles.debit;
    let prefix = type === "C" ? "+" : "-";

    return (
      <TableCell
        align="right"
        sx={{
          "& p": {
            margin: "0px !important",
          },
        }}
      >
        <p className={colorClass}>
          {`${prefix} ₦${formatAmount(amount.toString())}`}
        </p>
      </TableCell>
    );
  };

  // Columns
  interface Column {
    label: string;
    align?: "right" | "left" | "inherit" | "center" | "justify";
  }
  const columns: Column[] = [
    { label: "Date" },
    { label: "Description", align: 'center' },
    { label: "Amount", align: "right" },
    // { label: "Status", align: "right"}
  ];

  const [viewedTransaction, setViewedTransaction] =
    useState<Transaction | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fundIsOpen, setFundIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const viewTransaction = (transaction: Transaction | null) => {
    setViewedTransaction(transaction);
    openModal();
  };

  const openFundModal = () => {
    setFundIsOpen(true);
  };

  const closeFundModal = () => {
    setFundIsOpen(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "2%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__secondary-color)",
            "&:hover": {
              backgroundColor: "white",
              color: "black",
              border: 1.5,
              borderColor: "#4AB34A"
            },
            // paddingTop: "1.8em",
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
            alignSelf: "flex-start",
          }}
          onClick={() => openFundModal()}
        >
          Fund Wallet
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className={styles.title}>
          <h3>Transactions</h3>
        </div>
      </div>

      <Box sx={{ width: "100%", overflow: "hidden", borderRadius: "8px" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((c, index) => (
                  <TableCell
                    key={`cell${index}`}
                    sx={{
                      // backgroundColor: "var(--ltn__secondary-color)",
                      backgroundColor: "var(--section-bg-2)",
                      color: "white",
                      fontSize: "16px",
                    }}
                    align={c.align}
                  >
                    {c.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions &&
                transactions
                  ?.sort(
                    (a, b) =>
                      new Date(b.transactionDate).getTime() -
                      new Date(a.transactionDate).getTime()
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((t, index) => (
                    <TableRow
                      hover
                      key={index}
                      role="button"
                      onClick={() => viewTransaction(t)}
                    >
                      <TableCell>{toDateString(t.transactionDate)}</TableCell>
                      <TableCell align="center">
                        <div className={styles.lineClamp}>
                          {t.transactionType}
                        </div>
                      </TableCell>
                      {/* <TableCell align="left"> */}
                      {renderAmountCell(
                        t.amount,
                        t.debitCreditIndicator!,
                      )}
                      {/* </TableCell> */}

                      {/* <TableCell align="right">
                        {t.successful === true ? 'successful' : 'failed'}
                      </TableCell> */}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            "& p": {
              margin: "0px",
            },
          }}
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={transactions?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "54%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "var(--ltn__secondary-color)",
            // border: '3px solid rgb(120, 248, 120);',
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            width: "30em",
            height: "20em",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Transaction Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <TransactionModal
          closeModal={closeModal}
          transaction={viewedTransaction}
          token={token}
        />
      </Modal>
      <Modal
        isOpen={fundIsOpen}
        onRequestClose={closeFundModal}
        style={{
          content: {
            top: "54%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "var(--ltn__secondary-color)",
            // border: '3px solid rgb(120, 248, 120);',
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            width: "30em",
            height: "12em",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Transaction Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <FundWalletModal closeModal={closeFundModal} walletId={walletID} />
      </Modal>
    </>
  );
};

export default TransactionsTable;
