import React from 'react';
import Dropdown from '../FormElements/Dropdown';

export type SortingOption ="Default Sorting" |"Sort by popularity"| "Sort by new arrivals"|
    "Sort by price: low to high"| "Sort by price: high to low";
    
export type PageOption = "Per Page: 12"| "Per Page: 20" | "Per Page: 30" |
    "Per Page: 50"| "Per Page: 100"

export interface PropertyToolbarProps{
    onStateChange?: (sortingOption?: SortingOption, pageOption?: PageOption) => any;
}

const PropertyToolbar: React.FC<PropertyToolbarProps> = ({ onStateChange })=>{

    const [sortOption, setSortOption] = React.useState<SortingOption>();
    const [pageOption, setPageOption] = React.useState<PageOption>();

    // Value collectors
    const sortValueCollector = (value: any) =>{
        setSortOption(value);
        onStateChange && onStateChange(value, pageOption);
    }
    const pageValueCollector = (value: any) =>{
        setPageOption(value);
        onStateChange && onStateChange(sortOption, value);
    }

    const sortingOptions = [
        "Default Sorting", "Sort by popularity", "Sort by new arrivals",
        "Sort by price: low to high", "Sort by price: high to low"
    ];

    const perPageOptions = [
        "Per Page: 12", "Per Page: 20", "Per Page: 30",
        "Per Page: 50", "Per Page: 100"
    ]
    return(
        <div className="ltn__shop-options">
            <ul className="justify-content-start">
                <li>
                    <div className="ltn__grid-list-tab-menu ">
                        <div className="nav">
                            <a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large"></i></a>
                            <a data-bs-toggle="tab" href="#liton_product_list"><i className="fas fa-list"></i></a>
                        </div>
                    </div>
                </li>
                
                <li>
                    <Dropdown options={sortingOptions} value={sortingOptions[0]} 
                        variant={2} valueCollector={sortValueCollector}
                    />
                </li>
                <li>
                    <Dropdown options={perPageOptions} value={perPageOptions[0]} variant={2}
                        valueCollector={pageValueCollector}
                    />
                </li>
            </ul>
        </div>
    )
}

export default PropertyToolbar;