import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
//import Logo from '../Logo';
import styles from './mobileMenu.module.scss';
import siteLogo from '../../assets/HomeBuddy_RequestedColour_PrimaryLockup.svg';
import { menuItems, menuItemsII } from "../../data/menuItems";

interface IUtilizeMobileMenuProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}


export const UtilizeMobileMenu: React.FC<IUtilizeMobileMenuProps> = ({open, setOpen}) => {
    return (
        <>
        {/* <!-- Utilize Mobile Menu Start --> */}
        <div id="ltn__utilize-mobile-menu" className={ `${open ? "ltn__utilize-open" : ""} ltn__utilize ltn__utilize-mobile-menu`}>
            <div className="ltn__utilize-menu-inner ltn__scrollbar">
                <div className="ltn__utilize-menu-head">
                <div className="site-logo">
                    <Link to="/">
                        <div className={`d-flex align-items-center pb-2 pt-2`}>
                            <div className={styles.logoImage} ><img src={siteLogo} alt={'Access Homes logo'} /></div>
                            {/* <div className={styles.logoLabel} ><h3>Home Zone</h3></div> */}
                        </div>
                    </Link>
                </div>
                    <button onClick={()=>setOpen(!open)} className="ltn__utilize-close">×</button>
                </div>
                <div className="ltn__utilize-menu-search-form">
                    <form action="#">
                        <input type="text" placeholder="Search..." autoComplete="off"/>
                        <button><i className="fas fa-search"></i></button>
                    </form>
                </div>
                <div className="ltn__utilize-menu">
                    <ul>
                    {
                        menuItemsII && menuItemsII.map((item, index)=>{

                            if (item.subItems) {
                                return(
                                    <li key={`men${index}`}>
                                        <Link to={item.href}>{item.label}</Link>
                                        <ul className="sub-menu menu-pages-img-show">
                                            {
                                                item.subItems?.map((s, index)=>(
                                                    <li key={index}>
                                                        <Link to={s.href}>{s.label}</Link>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                )
                            }

                            return(
                                <li key={`men${index}`}>
                                    <Link to={item.href}>{item.label}</Link>
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
                <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
                    <ul>
                        <li>
                            <a href="/account" title="My Account">
                                <span className="utilize-btn-icon">
                                    <i className="far fa-user"></i>
                                </span>
                                My Account
                            </a>
                        </li>
                        {/* <li>
                            <a href="wishlist.html" title="Wishlist">
                                <span className="utilize-btn-icon">
                                    <i className="far fa-heart"></i>
                                    <sup>3</sup>
                                </span>
                                Wishlist
                            </a>
                        </li> */}
                        {/* <li>
                            <a href="cart.html" title="Shoping Cart">
                                <span className="utilize-btn-icon">
                                    <i className="fas fa-shopping-cart"></i>
                                    <sup>5</sup>
                                </span>
                                Shoping Cart
                            </a>
                        </li> */}
                    </ul>
                </div>
                <div className="ltn__social-media-2">
                    <ul>
                        <li><a href="#" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#" title="Linkedin"><i className="fab fa-linkedin"></i></a></li>
                        <li><a href="#" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        {/* <!-- Utilize Mobile Menu End --> */}
    
        <div className="ltn__utilize-overlay" onClick={()=>setOpen(!open)} style={{display: open ? 'block' : "none"}}></div>
        </>
    )
}
