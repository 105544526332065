import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styles from "./payment.module.scss";
import { styled } from "@mui/material/styles";
import StepIcon, { StepIconProps } from "@mui/material/StepIcon";
import PaymentPlans, { Plan } from "../../views/PaymentPlans";
import PaymentBreakdown from "../../views/PaymentBreakdown";
import Installment, {
  AdditionalFee,
  InstallmentState,
} from "../../views/Installment";
import ToastContext from "../../contexts/ToastContext";
import PaymentConfirmation from "../../views/PaymentConfirmation";
import PaymentConfirmDialog from "../../components/Dialogs/PaymentConfirmDialog";
import { useProduct } from "../../swr/properties";
import { useQuery } from "../../hooks/useQuery";
import propertyData from "../../components/PropertyCard/defaultData";
import PaymentSuccessDialog from "../../components/Dialogs/PaymentSuccessDialog";
import { useHistory } from "react-router-dom";
import PromptDialog from "../../components/Dialogs/PromptDialog";
import { getWalletId, hasWallet } from "../../utils/generalUtils";
import { GetWalletDetailsResponseData } from "../../types/Wallet";
import { confirmPaymentApi, getWalletDetails } from "../../swr/wallet";
import LoadingContext from "../../contexts/LoadingContext";

// Custom styles
// const useStyles = createUseStyles({
//     active:{
//         color: 'red'
//     }
// })

// Custom Step Label
const CustomStepIcon = styled(StepIcon)<StepIconProps>(({ theme }) => ({
  "&.Mui-active": {
    color: "var(--ltn__secondary-color)",
  },
  "&.Mui-completed": {
    color: "var(--ltn__secondary-color)",
  },
}));

interface PaymentState {
  activeIndex: number;
  plan?: "one-off" | "installment";
  repayment?: number;
  startDate?: string;
  cardVerified?: boolean;
}
interface WalletInfo {
  walletDetails?: GetWalletDetailsResponseData;
}

const PaymentSection: React.FC<any> = () => {
  const token = localStorage.getItem("token") || "";

  // Context
  const { openError, openSuccess } = React.useContext(ToastContext);
  const { setLoading } = React.useContext(LoadingContext);

  // Query
  const query = useQuery();
  const history = useHistory();
  let propertyId = query.get("property") || 0;
  const { product, isLoading, isError } = useProduct(propertyId);

  // Effect
  React.useEffect(() => {
    if (!query.get("property")) {
      history.replace("/notfound");
    }
  }, []);

  // Globals
  let additionalFee: AdditionalFee = {
    deposit: Number(product?.price) * 0.1,
    ourFee: Number(product?.price) * 0.02,
  };

  // State
  const [activeStep, setActiveStep] = React.useState(0);
  const [plan, setPlan] = React.useState<Plan>();
  const [installmentState, setInstallmentState] =
    React.useState<InstallmentState>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [successOpen, setSuccessOpen] = React.useState<boolean>(false);
  const [promptOpen, setPropmtOpen] = React.useState<boolean>(false);
  const [currentBalance, setcurrentBalance] = React.useState(0);

  const [isPropertyForSale, setIsPropertyForSale] = React.useState(false);

  // Local Storage
  const storeProgress = (
    activeIndex: number,
    selectedPlan?: "one-off" | "installment"
  ) => {
    let paymentState: PaymentState = {
      activeIndex: activeIndex,
      plan: selectedPlan,
      repayment: installmentState?.repayment,
      startDate: installmentState?.startDate,
      cardVerified: installmentState?.cardVerified,
    };
    localStorage.setItem("paymentState", JSON.stringify(paymentState));
  };

  const isBalanceSufficient = () => {
    if (product) {
      if (currentBalance < product.price) {
        return false;
      }
      return true;
    }
    return null;
  };

  // Handlers
  const handlePromptClose = () => {
    setPropmtOpen(false);
    history.push("/account#liton_tab_1_6");
  };
  const handleNext = () => {
    if (!hasWallet(token)) {
      setPropmtOpen(true);
      return;
    }

    if (activeStep === 1 && plan === "installment") {
      if (!installmentState?.cardVerified) {
        openError("Please verify your card");
      }
      if (!installmentState?.repayment) {
        openError("Please select your repayment frequency");
      }
      if (!installmentState?.startDate) {
        openError("Please select when you want to start paying");
      }

      if (
        installmentState?.cardVerified &&
        installmentState.repayment &&
        installmentState.startDate
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        storeProgress(activeStep + 1, plan);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      storeProgress(activeStep + 1, plan);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    storeProgress(activeStep - 1, plan);
  };

  const handlePlanSelect = (plan?: "one-off" | "installment") => {
    setPlan(plan);
    storeProgress(activeStep, plan);
  };

  const handleInstallmentChange = (state: InstallmentState) => {
    setInstallmentState(state);
  };

  const handleDialogOpen = () => {
    const isBalSufficient = isBalanceSufficient!();

    if (isBalSufficient === false) {
      openError("Your balance is insufficient");
      return;
    }

    if (isBalSufficient === null) {
      openError("currently unable to retrieve wallet balance");
      return;
    }
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleClose = (
    event?: any,
    reason?: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick") return;
    setSuccessOpen(false);
  };

  const handleOnSuccess = () => {
    setSuccessOpen(true);
    localStorage.removeItem("paymentState");
  };

  const confirmPaymentCall = async () => {
    setLoading(true)
    const {data, error} = await confirmPaymentApi(propertyId.toString(), token)
    if(data && data.succeeded){
      setLoading(false)
      openSuccess('Your Payment Confirmation is being processed')
      return
    }

    if(error){
      setLoading(false)
      openError('an error occurred confirming payment')
      return
    }

    setLoading(false)
    openError('an error occurred confirming payment')
  }

  // Effect
  React.useEffect(() => {
    if (product && product.status === "Sale") {
      setIsPropertyForSale(true);
    } else {
      setIsPropertyForSale(false);
    }

    // Load state from local storage

    let localState = localStorage.getItem("paymentState");

    if (localState) {
      let parsedState: PaymentState = JSON.parse(localState);
      let { repayment, startDate, cardVerified } = parsedState;
      setActiveStep(parsedState.activeIndex);
      setPlan(parsedState.plan);

      if (repayment && cardVerified && startDate) {
        setInstallmentState({
          repayment,
          cardVerified,
          startDate: startDate,
        });
      }
    }
  }, [product?.status]);

  React.useEffect(() => {
    getWalletDetails(token).then((result) => {
      if (result.data) {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.available_balance &&
          result.data.data.net_balance
        ) {
          setcurrentBalance(result.data.data.net_balance);
        }
      } else if (result.error) {
        let res = result.error;
        if (res.response?.status === 404) {
          openError("Please create a wallet and fund it to make payment");
          history.push("/account#liton_tab_1_6");
          return;
        }
        openError("An error occured while fetching wallet details");
      }
    });
  }, []);

  const steps = ["Select payment plan", "Payment", "Confirmation"];

  const stepsSale = ["Select payment plan", "Payment"];

  return (
    <section className="container pb-100">
      <PromptDialog
        title="This service requires a wallet"
        open={promptOpen}
        onProceed={handlePromptClose}
        proceedText="Proceed"
      >
        It looks like you do not have a wallet. Please create one before using
        this service
      </PromptDialog>
      <PaymentSuccessDialog open={successOpen} onClose={handleClose} />
      <PaymentConfirmDialog
        open={open}
        onClose={handleDialogClose}
        onSuccess={handleOnSuccess}
        product={product}
        isBalanceSufficient={isBalanceSufficient}
      />
      <div className="row">
        <div className="col-lg-12">
          <h4 className="title-2">Make payment for property </h4>

          {/** Stepper */}
          <Stepper activeStep={activeStep}>
            {(isPropertyForSale === true ? stepsSale : steps).map(
              (label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel StepIconComponent={CustomStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              }
            )}
          </Stepper>

          {/** Stepper content */}
          <Box
            sx={{
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "30px 0px",
              //justifyContent: 'space-between'
            }}
          >
            {activeStep === 0 && (
              <PaymentPlans
                onPlanSelect={handlePlanSelect}
                selectedPlan={plan}
                isInstallmental={product?.isInstallmental}
              />
            )}
            {activeStep === 1 &&
              (plan === "one-off" ? (
                <PaymentBreakdown
                  walletBalance={currentBalance}
                  amountToPay={Number(product?.price)}
                  totalPrice={Number(product?.totalPrice)}
                  otherPrices={Number(product?.otherPrices)}
                  isPropertyForSale={isPropertyForSale}
                  confirmPaymentCall={confirmPaymentCall}
                />
              ) : (
                <Installment
                  onStateChange={handleInstallmentChange}
                  price={Number(product?.price)}
                  additionalFee={additionalFee}
                  repaymentPeriod={product?.paymentDuration}
                  downPayment={product?.downPayment}
                  confirmPaymentCall={confirmPaymentCall}
                />
              ))}

            {activeStep === 2 && (
              <PaymentConfirmation
                property={product}
                plan={plan}
                additionalFee={additionalFee}
                installment={installmentState}
                
              />
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {activeStep !== 0 && (
                <button
                  onClick={handleBack}
                  className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                >
                  Back
                </button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep !== steps.length - 1 && !(activeStep === 1 && isPropertyForSale === true) && (
                <button
                  onClick={handleNext}
                  className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                >
                  {activeStep === 0 && "Next"}
                  {activeStep === 1 && "Proceed"}
                </button>
              )}
              {activeStep === steps.length - 1 && (
                <button
                  onClick={handleDialogOpen}
                  className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                >
                  Confirm
                </button>
              )}
            </Box>
          </Box>
        </div>
      </div>
    </section>
  );
};

export default PaymentSection;
