import React from "react";
import styles from "./modal-layout.module.scss";

const ModalLayout = ({
  modalOpen,
  children,
}: {
  modalOpen: boolean;
  children: React.ReactNode;
}) => {
  return (
    <>
      {modalOpen === true && (
        <div className={styles.backdrop}>
            {children}
        </div>
      )}
    </>
  );
};

export default ModalLayout;
