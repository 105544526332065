import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ProductImageCarousel from '../components/Carousels/ImageCarousel';
import Layout from '../components/Layout';
import LoadingScreen from '../components/LoadingScreen';
import NavHeader from '../components/NavHeader';
import propertyData from '../components/PropertyCard/defaultData';
import ScrollToTop from '../components/ScrollToTop';
import GeneralNotFound from '../sections/NotFound';
import ProductDetail from '../sections/ProductDetail';
import { AppDispatch, RootState } from '../state/store';
import { fetchPropertyById } from '../state/thunks/property';
import { marketProducts, MarketData } from '../views/MarketPlaceView/dataMarket';
import MarketProductDetails from '../sections/MarketProductDetails'

const ProductPage: React.FC<any> = ()=>{

    let params: { id: string } = useParams();

    const [product, setProduct] = useState({} as MarketData)
    const [loading, setLoading] = useState(true)

    // Fetch properties on page load
    React.useEffect(()=>{
        let oldProd = localStorage.getItem('property')
        let oldProduct =  oldProd ? JSON.parse(oldProd) : undefined
        let prod = marketProducts.find(prod => prod.id.toString() === params.id)
        if(oldProduct !== undefined){
            console.log(oldProd, 'I came here');
            
            setProduct(oldProduct)
            setLoading(false)
            localStorage.removeItem('property')
        }
        else if(prod){
            setProduct(prod)
            setLoading(false)
        }
        
        window.scrollTo(0, 0);
        
    },[]);

    

    return(
        <React.Fragment>
            <ScrollToTop/>
            <Layout>
                <NavHeader
                    pageTitle="Product Details"
                    routeName="product-details"
                />
                {loading && <LoadingScreen/>}
                {!product && !loading && <GeneralNotFound title="Product not Found!"/>}
                { !loading && <MarketProductDetails product={product}/>}
                {/* <ProductDetail property={propertyData}/> */}
            </Layout>
        </React.Fragment>
    )
}

export default ProductPage;