import axios, { AxiosRequestConfig } from 'axios';

interface IAxiosConfig extends AxiosRequestConfig {
  token: string;
  contentType?: string;
  headers: optionalHeadersValues;
}

interface optionalHeadersValues {
  applicationId?: string;
  subscriptionKey?: string;
}

const axiosCall = async ({
  url,
  data,
  method,
  token,
  contentType,
  headers,
}: IAxiosConfig) => {
  const result = await axios({
    url,
    method: method || 'GET',
    data,
    headers: {
      'Content-type': contentType || 'application/json',
      authorization: token,
      ...headers,
    },
  });
  
  return result.data;
};

export default axiosCall;
