import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import PinInput, { PinInputState } from "../../FormElements/PinInput";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingScreen from "../../LoadingScreen";
import ToastContext from "../../../contexts/ToastContext";
import { confirmPayment } from "../../../swr/wallet";
import { Link } from "react-router-dom";
import ResetPin from "../ResetPin/ResetPin";
import { payForProperty } from "../../../swr/properties";
import { generateId, getWalletId } from "../../../utils/generalUtils";
import { genMsgId, genTransactionRef } from "../../../utils/propertyUtils";
import LoadingContext from "../../../contexts/LoadingContext";
import { useHistory } from "react-router";

export interface PaymentConfirmDialogProps {
  open?: boolean;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
  onSuccess?: () => void;
  product?: any;
  isBalanceSufficient?: () => boolean | null;
}

const PaymentConfirmDialog: React.FC<PaymentConfirmDialogProps> = ({
  open = false,
  onClose,
  onSuccess,
  product,
  isBalanceSufficient,
}) => {
  const history = useHistory();

  let initState: PinInputState = {
    pin1: "",
    pin2: "",
    pin3: "",
    pin4: "",
  };

  const email = localStorage.getItem("email") as string;
  const token = localStorage.getItem("token") as string;
  const id = localStorage.getItem("id") as string;

  // Context
  const { openError, openSuccess } = React.useContext(ToastContext);
  const { setLoading } = React.useContext(LoadingContext);
  // State
  const [loader, setloader] = React.useState<boolean>(false);
  const [inputState, setInputState] = React.useState<PinInputState>(initState);
  const [isOpen, setIsOpen] = useState(false);
  const [close, setClose] = useState(false);

  // Validate pin Input
  const inputsValid = () => {
    let { pin1, pin2, pin3, pin4 } = inputState;
    if (pin1 && pin2 && pin3 && pin4) {
      return true;
    }
    return false;
  };
  // Handlers
  const handleConfirm = () => {
    if (inputsValid()) {
      // Simulate confirmation
      setloader(true);

      setTimeout(() => {
        setloader(false);
        onClose && onClose();
        onSuccess && onSuccess();
      }, 1500);
    } else {
      openError("Incomplete pin entry");
    }
  };

  const PayForProperty = async (pin: string) => {
    const paymentRequest = {
      amount:
        product && product.totalPrice
          ? product.totalPrice
          : product && product.price,
      narration: "",
      property_id: product.id,
      destination_user_id: product.userId,
    };

    const res = await payForProperty(paymentRequest, token, pin);
    return res;
  };

  const handleSuccess = () => {
    history.push("/account");
    openSuccess("Payment Successful");
  };

  const makePayment = async () => {
    if (!inputsValid()) {
      openError("Incomplete pin entry");
      return;
    }

    const pin =
      inputState.pin1 + inputState.pin2 + inputState.pin3 + inputState.pin4;
    if (!pin) {
      openError("please enter pin");
      return;
    }

    onClose();
    setLoading(true);

    try {
      const res = await PayForProperty(pin);
      res.succeeded === true
        ? handleConfirm()
        : openError(
            res.message ??
              res.Message.split(" ").slice(0, 10).join(" ")
          );

      setLoading(false);
      return;
    } catch (err) {
      // @ts-ignore
      let error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      openError(
        error.response.data.message ?? error.response.data.Message
      );
      setLoading(false);
      return;
    }
  };

  const handlePinChange = (state: PinInputState) => {
    setInputState(state);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {loader && <LoadingScreen />}
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ marginBottom: "10px", textAlign: "center" }}
        >
          Enter your transaction pin
        </DialogContentText>

        <PinInput onInputChange={handlePinChange} />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Link to={`/resetpin/${product && product.id}`}>
          <Button>
            Reset Pin
            {/* <ResetPin setIsOpen={setIsOpen} onClose={onClose}/> */}
          </Button>
        </Link>

        <Button onClick={() => makePayment()}>Confirm</Button>
      </DialogActions>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button sx={{ color: "var(--wrong)" }} onClick={onClose}>
          Cancel
        </Button>
        {/* <Button 
                    onClick={() => makePayment()}
                >
                    Confirm
                </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default PaymentConfirmDialog;
