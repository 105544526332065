import React from 'react';
import Layout from '../components/Layout';
import NavHeader from '../components/NavHeader';
import FAQSection from '../sections/FAQ';

const FAQPage: React.FC<any> = ()=>{

    return(
        <React.Fragment>
            <Layout>
                <NavHeader
                    pageTitle="Frequently asked questions"
                    routeName="FAQ"
                />
                <FAQSection/>
            </Layout>
        </React.Fragment>
    )
}

export default FAQPage;