import  BreadCrumb  from '../../components/BreadCrumb';
import ErrorImage from '../../img/others/error-1.png';

const NotFound = () => {
    return (
        <>
            <BreadCrumb pageName="404" pageTitle="404 Page" />
            <div className="ltn__404-area ltn__404-area-1 mb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="error-404-inner text-center">
                                <div className="error-img mb-30">
                                    <img src={ErrorImage} alt="#" />
                                </div>
                                <h1 className="error-404-title d-none">404</h1>
                                <h2>Page Not Found!</h2>

                                <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
                                <div className="btn-wrapper">
                                    <a href="/" className="btn btn-transparent">
                                        <i className="fas fa-long-arrow-alt-left"></i> BACK TO HOME
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;
