import React from "react";
import styles from "./rent-home.module.scss";
import firstLayerImg from "../../../assets/rent-first.jpg";
import firstLayerImgTop from "../../../assets/rent-first-top.jpg";
import firstLayerImgBottom from "../../../assets/rent-first-bottom.jpg";
import rentSecond1 from "../../../assets/rent-second-img1.jpg";
import rentSecond2 from "../../../assets/rent-second-img2.jpg";
import rentSecond3 from "../../../assets/rent-second-img3.jpg";
import rentBenefitImg1 from "../../../assets/rent-benefit-img1.jpg";
import rentBenefitImg2 from "../../../assets/rent-benefit-img2.jpg";
import rentBenefitImg3 from "../../../assets/rent-benefits-img3.jpg";
import rentBenefitImg4 from "../../../assets/rent-benefits-img4.jpg";
import { useHistory } from "react-router";

type Coverage = {
  img: any;
  head: string;
  text: string;
};

type Benefit = {
  img: any;
  text: string;
};

const coverageArr: Coverage[] = [
  {
    img: rentSecond1,
    head: "Legal Expenses",
    text: "We will cover the legal costs of regaining possession of your property sections, notices and professional legal advice, up to a N100,000 limit.",
  },
  {
    img: rentSecond2,
    head: "Unpaid Rent",
    text: "When tenants default on rent payments, we cover up to a specific limit and for an established duration, giving landlords steady income during this time, with an annual limit of N2,000,000.",
  },
  {
    img: rentSecond3,
    head: "Tenant's Default",
    text: "We also cover tenant’s defaults such as property abandonment, or lease agreement violation due to inability to meet up with their financial obligations, up to a limit of N2,000,000.",
  },
];

const benefitsArr: Benefit[] = [
  {
    img: rentBenefitImg1,
    text: "Single-family homes, townhomes, condos, and multi-family dwellings (i.e., long-term rentals)",
  },
  {
    img: rentBenefitImg2,
    text: "Tailored solutions are available for landlords with over 20 units",
  },
  {
    img: rentBenefitImg4,
    text: "No coverage for vacation, seasonal or short-term rental units",
  },
  {
    img: rentBenefitImg4,
    text: "No coverage when a tenant temporarily vacates the unit because it’s uninhabitable",
  },
  {
    img: rentBenefitImg3,
    text: "No coverage for property damage or liability: traditional property and casualty insurance plans provide coverage for loss of rent resulting from physical damage exposures such as fire or hurricane, but they do not address the risk of tenant default",
  },
];

const returnImgBg = (num: number) => {
  if (num === 0) {
    return "#FFFEFA";
  } else if (num === 1) {
    return "#FDF8F6";
  } else {
    return "#F6FDF6";
  }
};

const returnTxtBg = (num: number) => {
  if (num === 0) {
    return "#FDF5D8";
  } else if (num === 1) {
    return "#FAE9E5";
  } else {
    return "#E5FAE5";
  }
};

const RentHome = () => {
  const history = useHistory();
  const goToApplication = () => {
    history.push("/insurance/requestandbuy");
  };
  return (
    <div className={styles["home-inner"]}>
      <div className={styles["first-layer"]}>
        <div className={styles["first-layer-texts"]}>
          <span className={styles["first-layer-texts-para"]}>
            Rent Default Insurance
          </span>

          <span className={styles["first-layer-texts-span"]}>
            This policy safeguards you from financial loss caused by tenants who
            cannot fulfil their rental commitments, ensuring peace of mind for
            landlords.
          </span>

          <span className={styles["first-layer-texts-span"]}>
            We've got you covered when your tenants face difficulties meeting
            their rental obligations.
          </span>
        </div>

        <div className={styles["first-layer-img"]}>
          <img src={firstLayerImg} className={styles["first-layer-image1"]} />
          <div className={styles["first-layer-image-top"]}>
            <img
              src={firstLayerImgTop}
              className={styles["first-layer-image"]}
            />
          </div>

          <div className={styles["first-layer-image-bottom"]}>
            <img
              src={firstLayerImgBottom}
              className={styles["first-layer-image"]}
            />
          </div>
        </div>
      </div>

      <div className={styles["coverage-cont"]}>
        <span className={styles["rent-header"]}>What It Covers</span>

        {coverageArr.map((cover, index) => (
          <div className={styles["coverage"]} key={cover.head}>
            <div
              className={styles["coverage-img-cont"]}
              style={{ backgroundColor: returnImgBg(index) }}
            >
              <img
                src={cover.img}
                alt={cover.head}
                className={styles["coverage-img"]}
              />
            </div>
            <div
              className={styles["coverage-cont-text"]}
              style={{ backgroundColor: returnTxtBg(index) }}
            >
              <span className={styles["coverage-cont-text-p"]}>
                {cover.head}
              </span>
              <span className={styles["coverage-cont-text-span"]}>
                {cover.text}
              </span>
            </div>
          </div>
        ))}

        <div
          className={styles["black-but"]}
          role="button"
          onClick={() => goToApplication()}
        >
          Request & Buy
        </div>
      </div>

      <div className={styles["benefits"]}>
        <div className={styles["benefits-header"]}>Eligible For Coverage</div>
        <div className={styles["benefits-box-cont"]}>
          {/* <div className={styles["vertical-cross"]}></div> */}
          {/* <div className={styles["horizontal-cross"]}></div> */}
          {benefitsArr.slice(0, 2).map((benefit, index) => (
            <div
              className={styles["benefits-box"]}
              key={benefit.text.slice(0, 5)}
            >
              <img
                src={benefit.img}
                alt={benefit.text.split(" ")[0]}
                className={styles["benefits-img"]}
              />
              <span className={styles["benefits-text"]}>{benefit.text}</span>
            </div>
          ))}
        </div>
      </div>

      <div className={styles["benefits"]}>
        <div className={styles["benefits-header"]}>
          Not Eligible For Coverage
        </div>
        <div className={styles["benefits-box-cont"]}>
          {/* <div className={styles["vertical-cross"]}></div> */}
          {/* <div className={styles["horizontal-cross"]}></div> */}
          {benefitsArr.slice(2, 4).map((benefit) => (
            <div
              className={styles["benefits-box"]}
              key={benefit.text.slice(0, 5)}
            >
              <img
                src={benefit.img}
                alt={benefit.text.split(" ")[0]}
                className={styles["benefits-img"]}
              />
              <span className={styles["benefits-text"]}>{benefit.text}</span>
            </div>
          ))}
        </div>
        <div className={styles["benefits-box-span"]}>
          <img
            src={benefitsArr[4]?.img}
            alt={benefitsArr[4]?.text.split(" ")[0]}
            className={styles["benefits-img"]}
          />
          <span className={styles["benefits-text-I"]}>
            {benefitsArr[4]?.text}
          </span>
        </div>
      </div>
      <div className={styles["faq-cont"]}>
          <span className={styles["faq-hyper"]}>
            For more information regarding this policy, click on this
          </span>
          <span className={styles["faq-hyper-I"]}>
            <a
              href="https://drive.google.com/file/d/1jzbq72UCr2gU2BqswM-w2gDq0PCL2ar1/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQ
            </a>
          </span>
        </div>
    </div>
  );
};

export default RentHome;
