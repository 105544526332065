import React from 'react';
import emailLogo from '../../img/icons/10.png';
import phoneNumberLogo from '../../img/icons/11.png';
import addressLogo from '../../img/icons/12.png';

const Contact: React.FC<any> = ()=>{

    return(
        <section className="ltn__contact-address-area mb-90">
            <div className="container" >
                <div className="row">

                    {/** Email Address */}
                    <div className="col-lg-6">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                            <div className="ltn__contact-address-icon">
                                <img src={emailLogo} alt="Icon Image"/>
                            </div>
                            <h3 className="animated fadeIn">Email Address</h3>
                            <a href='mailto:admin@homebuddy.ng'>admin@homebuddy.ng</a>
                        </div>
                    </div>

                   

                    {/** Office Address */}
                    <div className="col-lg-6">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                            <div className="ltn__contact-address-icon">
                                <img src={addressLogo} alt="Icon Image"/>
                            </div>
                            <h3 className="animated fadeIn">Office Address</h3>
                            <span>1637 Adetokunbo Ademola Street Victoria Island, Lagos</span>
                        </div>
                    </div>

                     {/** Phone Number */}
                     <div className="col-lg-6">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                            <div className="ltn__contact-address-icon">
                                <img src={phoneNumberLogo} alt="Icon Image"/>
                            </div>
                            <h3 className="animated fadeIn">Phone Number</h3>
                            <span>+234 704 113 9868</span>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Contact;