import React from 'react'
import HomePageInsurance from '../../views/Insurance/Home'
import styles from "./insurance.module.scss"

const Insurance = () => {
  return (
    <div className={styles["home-container"]}>
        <HomePageInsurance/>
    </div>
  )
}

export default Insurance