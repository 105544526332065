import { PaymentFrequency, PaymentSchedule } from "../types/Savings";

function getFrequency(frequency: PaymentFrequency): number {
    switch (frequency) {
        case PaymentFrequency.Daily:
            return 1;

        case PaymentFrequency.Weekly:
            return 7;

        case PaymentFrequency.Biweekly:
            return 14;

        case PaymentFrequency.Monthly:
            return 30;

        case PaymentFrequency.Quarterly:
            return 30 * 3;
    }
}

export interface ScheduleOptions{
    targetedAmount: number;
    savingsAmount: number;
    paymentFrequency: PaymentFrequency;
    startDate: Date | string;
    creditAccount?: string
}

export function generatePaymentSchedule(options: ScheduleOptions): PaymentSchedule[]{
    
    const { 
        targetedAmount, savingsAmount,
        paymentFrequency, startDate, creditAccount
    } = options;
    // console.log(startDate);
    

    let frequency = getFrequency(paymentFrequency);
    
    let newStartDate = new Date(startDate).toISOString().slice(0, 10);


    let remainder = targetedAmount % savingsAmount;
    let numOfPayments = Math.ceil(targetedAmount / savingsAmount);
    const dates: Date[] = [new Date(newStartDate)];
    
    let paymentSchedules: PaymentSchedule[] = [];

    for (let i = 1; i < numOfPayments; i++) {
        let prevDate = dates[i-1];
        let payDate = new Date(prevDate.getTime() + frequency * 24 * 60 * 60 * 1000);
        dates.push(payDate);
    }

    // console.log(dates)
    

    for (let i = 0; i < dates.length; i++) {
        
        // If a remainder exists, that will be the last payment
        if (remainder !== 0) {
            if ((i+1) === dates.length) {
                let schedule: PaymentSchedule = {
                    debitAmount: remainder,
                    creditAccount: creditAccount ? creditAccount : "0",
                    currency: 0,
                    debitDate: dates[i]
                }
                paymentSchedules.push(schedule)
            }
            else{
                let schedule: PaymentSchedule = {
                    debitAmount: savingsAmount,
                    creditAccount: creditAccount ? creditAccount : "0",
                    currency: 0,
                    debitDate: dates[i]
                }
                paymentSchedules.push(schedule)
            }
        }
        else{
            let schedule: PaymentSchedule = {
                debitAmount: savingsAmount,
                creditAccount: creditAccount ? creditAccount : "0",
                currency: 0,
                debitDate: dates[i]
            }
            paymentSchedules.push(schedule)
        }
    }

    return paymentSchedules;
}