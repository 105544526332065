import React from 'react'
import Layout from '../../components/Layout'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'
import NavHeader from '../../components/NavHeader'
import ViewPensionsApplication from './page'

const ViewPensionsPage = () => {
  return (
    <Layout>
    <ScrollToTop />
    <SessionCheck shouldRedirect />
    <NavHeader pageTitle="View Pensions" routeName="View Pensions" />
    <ViewPensionsApplication />
  </Layout>
  )
}

export default ViewPensionsPage