import React, { useContext, useEffect, useState } from "react";
import styles from "./admin-rent.module.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { returnMsgFromAxiosError } from "../../../utils/generalUtils";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingContext from "../../../contexts/LoadingContext";
import ToastContext from "../../../contexts/ToastContext";
import { getAllInsurance, getAllRentInsurance } from "../../../swr/insurance";
import { InsuranceObj, PaginationRes } from "../../../types/Insurance";
import { User } from "../../../types/User";

type InsuraceObjRent = Omit<InsuranceObj, "user">;

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const AllRentInsurance = () => {
  const history = useHistory();
  const { openError } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const [allInsurances, setAllInsurances] = useState([] as InsuranceObj[]);
  const token = localStorage.getItem("token") as string;
  const [paginationParams, setPaginationParams] = useState<PaginationRes>({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: 0,
    totalPages: 0,
    hasPrevious: false,
    hasNext: false,
  });

  const columns: readonly Column[] = [
    { id: "email", label: "Email", minWidth: 170 },
    { id: "phoneNumber", label: "Phone Number", minWidth: 170 },
    { id: "firstName", label: "First Name", minWidth: 170 },
    { id: "lastName", label: "Last Name", minWidth: 170 },
    { id: "dateCreated", label: "Date", minWidth: 170 }
  ];

  const getAllInsurancesCall = async (page: number) => {
    setLoading(true);
    const { data, error } = await getAllRentInsurance(
      {
        PageNumber: page,
        PageSize: paginationParams.pageSize,
      },
      token
    );

    if (data && data.item1 && data.item2) {
      setLoading(false);
      setPaginationParams(data.item2);
      setAllInsurances(data.item1);
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "Unable to fetch insurance policies")
      );
      return;
    }

    openError("Unable to fetch insurance policies");
    setLoading(false);
    return;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationParams((prev) => ({ ...prev, pageNumber: newPage + 1 }));
    getAllInsurancesCall(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaginationParams((prev) => ({ ...prev, pageSize: +event.target.value }));
    setPaginationParams((prev) => ({ ...prev, pageNumber: 1 }));
    getAllInsurancesCall(1);
  };

  const returnValue = (key: string, row: InsuranceObj) => {
    const value = row[key as keyof InsuraceObjRent];
    const possibleUserValue = row.user[key as keyof User] as string
    if (key === "dateCreated") {
      return new Date(value as string).toLocaleString("NG");
    }
    return value ? value : possibleUserValue ? possibleUserValue : "";
  };

  const goToSingleInsurance = (id: string | number) => {
    history.push(`/viewrentinsurance/${id}`)
  }

  useEffect(() => {
    getAllInsurancesCall(paginationParams.pageNumber);
  }, [])

  return (
    <div className={styles.supercontainer}>
      <Paper sx={{ width: "90%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allInsurances &&
                allInsurances
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="button"
                        tabIndex={-1}
                        key={`${row.userId}-${index}`}
                        onClick={() => goToSingleInsurance(row.id)}
                      >
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {returnValue(column.id, row)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={paginationParams.totalRecords}
          rowsPerPage={paginationParams.pageSize}
          page={paginationParams.pageNumber - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default AllRentInsurance;
