import BreadCrumbImage from '../../img/bg/14.jpg';

interface IBreadCrumb {
    pageTitle?: string;
    homeLink?: string;
    pageName: string;
}

export const BreadCrumb: React.FC<IBreadCrumb> = ({ pageName, pageTitle = 'Account', homeLink = 'index.html' }) => {

    return (
        <>
            {/* <!-- BREADCRUMB AREA START --> */}
            <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image" data-bs-bg={BreadCrumbImage}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__breadcrumb-inner">
                                <h1 className="page-title">{pageTitle}</h1>
                                <div className="ltn__breadcrumb-list">
                                    <ul>
                                        <li>
                                            <a href={homeLink}>
                                                <span className="ltn__secondary-color">
                                                    <i className="fas fa-home"></i>
                                                </span>{' '}
                                                Home
                                            </a>
                                        </li>
                                        <li>{pageName}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- BREADCRUMB AREA END --> */}
        </>
    );
};

export default BreadCrumb;
