import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import PaymentScheduleSummaryDialog from '../../components/Dialogs/PaymentScheduleSummary';
import SaveForRentForm from '../../components/Forms/SaveForRentForm';
import LoadingContext from '../../contexts/LoadingContext';
import ToastContext from '../../contexts/ToastContext';
import { saveForRent } from '../../swr/saveForRent';
import { SavingsTowardsRentRequest } from '../../types/Savings';
import Radio from '@mui/material/Radio';
import { withStyles } from '@mui/styles';
import styles from './transfer.module.scss';
import ToBankAccountForm from '../../components/Forms/ToBankAccountForm';
import ToWalletForm from '../../components/Forms/ToWallet';

const TransferSection: React.FC<any> = ()=>{

    // State
    const [request, setRequest] = React.useState<SavingsTowardsRentRequest>();
    const [open, setOpen] = React.useState<boolean>(false);
    const [destination, setDesitination] = React.useState<'bank' | 'wallet'>('bank');

    // Contexts
    const { setLoading } = React.useContext(LoadingContext);
    const { openSuccess, openError } = React.useContext(ToastContext);

    // Handlers
    const handleDialogClose = ()=>{
        setOpen(false);
    }
    const handleBankDestination = (event: React.SyntheticEvent<Element, Event>, checked: boolean)=>{
        if (checked) {
            setDesitination('bank');
        }
    }
    const handleWalletDestination = (event: React.SyntheticEvent<Element, Event>, checked: boolean)=>{
        if (checked) {
            setDesitination('wallet');
        }
    }


    // Custom Components
    const MyRadio = withStyles({
        root: {
            '&$checked': {
              color: "var(--ltn__secondary-color)"
            }
          },
        checked: {}
    })(Radio);

    const handleFormSubmit = (state: SavingsTowardsRentRequest)=>{
        setRequest(state);
        setLoading(false);
        setOpen(true);
    }
    const handleConfirm = async ()=>{
        if (request) {
            setOpen(false);
            setLoading(true);
            const { data, error } = await saveForRent(request);
            if (data) {
                setLoading(false);
                openSuccess('Your savings plan was successfully created');
            }
            if (error) {
                setLoading(false);
                openError('An error occured while creating your savings plan')
            }
        }
    }

    return(
        <>
        <PaymentScheduleSummaryDialog 
            open={open}
            paymentSchedules={request?.paymentScheduleArray}
            onClose={handleDialogClose}
            onConfirm={handleConfirm}
        />
        <div className="ltn__checkout-area mb-105">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__checkout-inner">

                            {/** Create a Savings Target */}
                            <div className="ltn__checkout-single-content mt-50">
                                <h4 className="title-2">Send Money</h4>
                                <div className={styles.selectDiv}>
                                    <p>To:</p>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        sx={{ 
                                            '& span':{
                                                fontSize: '0.9rem'
                                            }
                                        }}
                                        value={destination}
                                        //onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="bank" control={<MyRadio/>} 
                                            label="Bank Account" 
                                            onChange={handleBankDestination}
                                        />
                                        <FormControlLabel
                                            value="wallet" control={<MyRadio />} 
                                            label="Wallet" 
                                            onChange={handleWalletDestination}
                                        />
                                    </RadioGroup>
                                </div>
                                {
                                    destination === 'bank' &&
                                    <div className="ltn__checkout-single-content-info">
                                        <ToBankAccountForm/>
                                    </div>
                                }
                                {
                                    destination === 'wallet' &&
                                    <div className="ltn__checkout-single-content-info">
                                        <ToWalletForm/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}

export default TransferSection;