import jwt,{ Jwt, JwtPayload } from "jsonwebtoken";


export function isTokenExpired(token?: string | null): boolean {
    if (!token) {
        return true;
    }

    const decodedToken = jwt.decode(token, { complete: true }) as { payload: JwtPayload } | null | undefined;

    if (!decodedToken) {
        return true;
    }

    const dateNow = new Date();
    if (decodedToken.payload.exp && decodedToken.payload.exp * 1000 < dateNow.getTime()) {
        return true;
    }

    return false;
}

// export function isTokenExpired(token?: string | null) {
//     // First check if there is a token in local storage
//     if (!token) {
//         return true;
//     }

//     if (token) {
//         // Check if token has expired, redirect to signin and remove the token
//         let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
//         let dateNow = new Date();
//         if (decodedToken?.payload.exp as JwtPayload){
//             if (decodedToken?.payload.exp * 1000 < dateNow.getTime()) {
//                return true;
//             }
//         }
//     }

//     return false;
// }