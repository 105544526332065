import React from "react";
import { formatAmount } from "../../utils/propertyUtils";
import styles from "./breakdown.module.scss";

interface PaymentBreakdownProps {
  walletBalance: number;
  amountToPay: number;
  otherPrices?: number;
  totalPrice?: number;
  isPropertyForSale: boolean;
  confirmPaymentCall: () => Promise<void>
}

// {isPropertyForSale === true ? (
//   <div className={styles.saleContainer}>
//     <p>To complete payment, please transfer required funds to the account details below:</p>
//   </div>
// ) : (

const PaymentBreakdown: React.FC<PaymentBreakdownProps | any> = ({
  walletBalance,
  amountToPay,
  otherPrices,
  totalPrice,
  isPropertyForSale,
  confirmPaymentCall
}) => {
  let platformFee = amountToPay && amountToPay * 0.005;
  let balance = totalPrice
    ? walletBalance - (totalPrice + platformFee)
    : walletBalance - (amountToPay + platformFee);
  let Total = totalPrice
    ? totalPrice + platformFee
    : amountToPay
    ? amountToPay + platformFee
    : "";
  return (
    <>
      {isPropertyForSale ? (
        <div className={styles.saleContainer}>
          <p>
            Please make payment to the account details below:
          </p>

          <div className={styles.saleTransfer}>
            <span className={styles.leftItem}>
              Account Name:
            </span>

            <span>HOMEBUDDY PAYMENT COLLECTION</span>
          </div>
          <div className={styles.saleTransfer}>
            <span className={styles.leftItem}>
              Bank:
            </span>

            <span>Access Bank</span>
          </div>
          <div className={styles.saleTransfer}>
            <span className={styles.leftItem}>
              Account Number:
            </span>

            <span>1855777206</span>
          </div>

          <div className={styles["confirm-button"]} >
          <div className={styles["confirm-but"]} role="button" onClick={() => confirmPaymentCall()}>
            confirm-payment
          </div>
        </div>
        </div>
      ) : (
        <div className={styles.container}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.headerRow}>
                <th>Breakdown</th>
              </tr>
              <tr>
                <td>Wallet Balance: </td>
                <td>₦ {formatAmount(walletBalance.toString())}</td>
              </tr>
              <tr>
                <td>Rent price: </td>
                <td className={styles.debit}>
                  - ₦ {formatAmount(amountToPay.toString())}
                </td>
              </tr>
              {otherPrices > 0 && (
                <tr>
                  <td>Agency Agreement et al: </td>
                  <td className={styles.debit}>
                    - ₦ {formatAmount(otherPrices.toString())}
                  </td>
                </tr>
              )}
              <tr>
                <td>Platform fee: </td>
                <td className={styles.debit}>
                  - ₦ {formatAmount(platformFee && platformFee.toString())}
                </td>
              </tr>
              {totalPrice > 0 && (
                <tr>
                  <td>Total Price: </td>
                  <td className={styles.debit}>
                    - ₦ {formatAmount(Total.toString())}
                  </td>
                </tr>
              )}

              <tr className={styles.balanceRow}>
                <td>Balance: </td>
                <td className={balance < 0 ? styles.debit : styles.credit}>
                  {balance < 0
                    ? `- ₦ ${formatAmount(
                        Math.abs(balance).toFixed(2).toString()
                      )}`
                    : `₦ ${formatAmount(balance.toFixed(2).toString())}`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PaymentBreakdown;

// const PaymentBreakdown: React.FC<any> = () =>{

//     let balance = 200000 - 100000;

//     return(
//         <div className={styles.container}>
//             <table className={styles.table}>
//                 <tbody>
//                 <tr className={styles.headerRow}>
//                     <th>Breakdown</th>
//                 </tr>
//                 <tr>
//                     <td>Wallet Balance: </td>
//                     <td>₦ {formatAmount((200000).toString())}</td>
//                 </tr>
//                 <tr>
//                     <td>Amount to pay: </td>
//                     <td className={styles.debit}>- ₦ {formatAmount((100000).toString())}</td>
//                 </tr>
//                 <tr className={styles.balanceRow}>
//                     <td>Balance: </td>
//                     <td className={balance < 0 ? styles.debit : styles.credit}>
//                         {
//                             balance < 0 ?
//                             `- ₦ ${formatAmount(Math.abs(balance).toString())}`
//                             :
//                             `₦ ${formatAmount(balance.toString())}`
//                         }
//                     </td>
//                 </tr>
//                 </tbody>
//             </table>

//         </div>
//     )
// }

// export default PaymentBreakdown;
