import { createUseStyles } from "react-jss";
import { breakpoints } from "../../utils/breakpoints";

const useStyles = createUseStyles({
    
    banner:{
        position: 'relative',
        '&:before':{
            position: 'absolute',
            content: '""',
            width: '100%',
            height: '50%',
            left: 0,
            bottom: 0,
            backgroundColor: '#0A0A35',
        }
    },

    bannerContainer:{
        paddingRight: '15px',
        paddingLeft: '15px',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '100%'
    },

    row:{
        marginRight: '-15px',
        marginLeft: '-15px',
    },

    buttonWrapper:{
        marginTop: 0,
        display: 'block',
    },

    bannerContent:{
        display: 'flex',
        justifyContent: 'space-between',
        '-webkit-box-pack': 'justify',
        '-webkit-box-align': 'center',
        alignItems: 'center',
        padding: '50px 60px',
        backgroundColor: 'var(--ltn_secondary-color)',
        marginTop: 0,
        position: 'relative',
    },

    bannerInfo:{
        '& h1':{
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: 700,
            lineHeight: 1.3,
        },
        '& p':{
            marginBottom: 0,
            marginTop: 15,
            color: 'white'
        }
    },

    bannerButton:{
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 'var(--ltn__box-shadow-3)',
        borderRadius: 0,
        zIndex: 1,
        position: 'relative',
        fontSize: 16,
        padding: '17px 40px',

        '&:last-child':{
            marginRight: 0
        },

        '& svg':{
            margin: '0 5px',
        },
        
        '&:after':{
            content: '""',
            position: 'absolute',
            zIndex: -1,
            width: '0%',
            height: '100%',
            top: 0,
            left: 0,
            background: 'black',
            transition: 'all 0.3s'
        },
        
        '&:hover':{
            color: 'white',
        },

        '&:hover:after':{
            width: '100%'
        }
    },

    /** Responsive styles */

    [breakpoints.sm('up')]:{

        bannerContainer:{
            maxWidth: '720px',
        },
    },

    [breakpoints.lg('up')]:{

        bannerContainer:{
            maxWidth: '960px',
        },
    },

    [breakpoints.xl('up')]:{
        bannerContainer:{
            maxWidth: '1200px',
        },
    },

    [breakpoints.lg()]:{
        bannerContent:{
            padding: '50px 25px',
            flexFlow: 'column',
        },

        bannerButton:{
            marginTop: 30,
            padding: '12px 25px',
        },
    },

    [breakpoints.sm()]:{

        bannerContainer:{
            maxWidth: '540px',
        },

        bannerButton:{
            fontSize: 14,
            padding: '10px 20px',
        },
    },
});

export default useStyles;