import React from 'react';
import { Attachment, Property } from '../../../types/Property';
import { formatAmount, ratingComparator } from '../../../utils/propertyUtils';
import { StyledRating } from '../../PropertyElements/Reviews';
import ratingProductImg from '../../../img/product/1.png';
import { useProperties } from '../../../swr/properties';
import { Link } from 'react-router-dom';

export interface RatedProductProps{
    rating?: number;
    title?: string; 
    price?: number;
    attachment?: Attachment;
    id?: number;
}

export const RatedProduct: React.FC<RatedProductProps> = ({
    rating = 5, title = 'Luxury House In Greenville',
    price = 300000, attachment, id
})=>{

    const imageInUse = attachment ? attachment.imageUrl : ratingProductImg;
    const alt = attachment ? attachment.alt : 'Default product image';
    return(
        <div className="top-rated-product-item clearfix">
            <div className="top-rated-product-img">
                <a href="product-details.html"><img src={imageInUse} alt={alt}/></a>
            </div>
            <div className="top-rated-product-info">
                <div className="product-ratting">
                    <StyledRating name="half-rating" 
                        value={rating} precision={0.5} size="small"
                        readOnly
                    />
                </div>
                <h6><Link to={`/properties/${id}`}>{title}</Link></h6>
                <div className="product-price">
                    <span>₦{formatAmount(price.toString())}</span>
                    {/* <del>₦350,000</del> */}
                </div>
            </div>
        </div>
    )
}

const defaultTopRated: RatedProductProps[] = [
    { title: 'Luxury House In Greenville', rating: 5 },
    { title: 'Apartment with Subunits', rating: 5 },
    { title: '3 Rooms Manhattan', rating: 3.5 },
];

export interface TopRatedProductsProps{
    properties?: Property[];
}

const TopRatedProductsWidget: React.FC<any> = ()=>{

    const token = localStorage.getItem('token') || undefined;
    const { properties, isLoading, isError } = useProperties(token, 1, 1000000);

    if (properties) {
        properties.sort(ratingComparator);
    }

    return(
        <div className="widget ltn__top-rated-product-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">Top Rated Product</h4>
            <ul>

            { properties && [properties[0]].map((item, index) => ( <li key={item.id}> <RatedProduct rating={item.rating} title={item.title} price={item.price} id={item.id} attachment={item.attachments ? item.attachments[0] : undefined} /> </li> )) } { !properties && defaultTopRated.map((item, index) => ( <li key={`topr${index}`}> <RatedProduct {...item} /> </li> )) }
                {/* {
                    properties &&
                    [properties[0], properties[1], properties[2]].map((item, index)=>(
                        <li key={item.id}>
                            <RatedProduct rating={item.rating} title={item.title}
                                price={item.price} id={item.id}
                                attachment={item.attachments ? item.attachments[0] : undefined} 
                            />
                        </li>
                    ))
                } */}
                {/* {
                    !properties &&
                    defaultTopRated.map((item, index)=>(
                        <li key={`topr${index}`}>
                            <RatedProduct {...item} />
                        </li>
                    ))
                } */}
            </ul>
        </div>
    )
}

export default TopRatedProductsWidget;