import React from "react";
import { Link, useLocation } from "react-router-dom";
import UserSavingHistory from "../../views/UserSavingHistory";
import UserAccountDashboard from "../../views/UserAccountDashboard";
import UserAccountDetails from "../../views/UserAccountDetails";
import UserAccountTargets from "../../views/UserAccountTargets";
import UserAccountPayment from "../../views/UserAccountPayment";
import jwt, { Jwt } from "jsonwebtoken";
import styles from "./account.module.scss";
import { useFundingSource } from "../../swr/funding";
import { useSavingsTowardsRent } from "../../swr/saveForRent";
import MySwipeableView from "../../components/MySwipeableView";
import UserPaymentSchedule from "../../views/UserPaymentSchedule";
import { SavingsTowardsRent } from "../../types/Savings";
import UserAccountBookings from "../../views/UserAccountBookings";
import { getUserInspections, useBookings } from "../../swr/inspection";
import UserAccountCreateWallet from "../../views/UserAccountCreateWallet";
import { getWalletId, hasWallet } from "../../utils/generalUtils";
import UserAccountWallet from "../../views/UserAccountWallet";
import { getWalletDetails } from "../../swr/wallet";
import ToastContext from "../../contexts/ToastContext";
import { GetWalletDetailsResponseData } from "../../types/Wallet";
import {
  useStateContext,
  StateContextProps,
} from "../../contexts/StateContext";
import { InspectionB } from "../../types/Billings";

const Account: React.FC<any> = () => {
  // Context
  const { openError } = React.useContext(ToastContext);

  // Refs
  const sectionRef = React.useRef<HTMLDivElement>(null);

  // Fetch funding sources
  let uid = localStorage.getItem("id") as string;
  let username = "";
  const token = localStorage.getItem("token") as string;
  let isTenant = false;
  if (token) {
    let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
    // if (decodedToken) {
    //     if (decodedToken?.payload.roles === 'Tenant'){
    //         isTenant = true;
    //     }
    //     const { payload } = decodedToken;
    //     uid = payload.uid;
    //     username = payload.uname;
    // }
  }
  const { data, isLoading, isError, mutate } = useFundingSource(uid);
  const savingsResult = useSavingsTowardsRent(uid, token);
  const savingTowardsRent = savingsResult && savingsResult.savings;
  // const reversedSavings = savingTowardsRent && [...savingTowardsRent].reverse();

  const bookingsResult = useBookings(token);

  // Display
  interface DisplayState {
    dashboard: boolean;
    accountDetails: boolean;
    billing: boolean;
    orders: boolean;
    payment: boolean;
    wallet: boolean;
    bookings: boolean;
  }

  // Initial State
  const initialState: DisplayState = {
    dashboard: false,
    accountDetails: false,
    billing: false,
    orders: true,
    payment: false,
    wallet: false,
    bookings: false,
  };

  // Location
  const location = useLocation();

  // Scroll
  const executeScroll = () => {
    window.scrollTo(0, 300);
  };

  // State
  const [displayState, setDisplayState] =
    React.useState<DisplayState>(initialState);
  const [planIndex, setPlanIndex] = React.useState<number>(0);
  const [plan, setPlan] = React.useState<SavingsTowardsRent>();
  const [walletDetails, setWalletDetails] =
    React.useState<GetWalletDetailsResponseData>();
  const [detailsLoading, setDetailsLoading] = React.useState<boolean>(true);
  const [email, setEmail] = React.useState<string | undefined>("");
  const [userBookings, setUserBookings] = React.useState<
    InspectionB[] | undefined
  >([]);
  const [hasWallet, setHasWallet] = React.useState(true)

  const { currentBalance, setCurrentBalance }: StateContextProps =
    useStateContext();

  const GetUserBookings = async () => {
    const response = await getUserInspections(token);

    if (!response) {
      openError("unknown error occurred fetching your bookings");
      return;
    }

    if (response.status !== 200) {
      openError(
        response.data && response.data.message
          ? response.data.message
          : response.Message
          ? response.Message
          : response.message
          ? response.message
          : "Network Error"
      );
      return;
    }

    if (response && response.data) {
      setUserBookings(response.data);
      return;
    } else {
      openError(response.Message ? response.Message : "Network Error");
      return;
    }
  };

  // Handlers
  const handlePaymentUpdate = () => {
    mutate();
  };

  // View Handlers
  const handleDashboardClick = () => {
    setDisplayState({
      dashboard: true,
      accountDetails: false,
      billing: false,
      orders: false,
      payment: false,
      wallet: false,
      bookings: false,
    });
  };
  const handleAccountClick = () => {
    setDisplayState({
      dashboard: false,
      accountDetails: true,
      billing: false,
      orders: false,
      payment: false,
      wallet: false,
      bookings: false,
    });
  };
  const handleBillingClick = () => {
    setDisplayState({
      dashboard: false,
      accountDetails: false,
      billing: true,
      orders: false,
      payment: false,
      wallet: false,
      bookings: false,
    });
  };
  const handleOrdersClick = () => {
    setDisplayState({
      dashboard: false,
      accountDetails: false,
      billing: false,
      orders: true,
      payment: false,
      wallet: false,
      bookings: false,
    });
  };
  const handlePaymentClick = () => {
    setDisplayState({
      dashboard: false,
      accountDetails: false,
      billing: false,
      orders: false,
      payment: true,
      wallet: false,
      bookings: false,
    });
  };
  const handleWalletClick = () => {
    setDisplayState({
      dashboard: false,
      accountDetails: false,
      billing: false,
      orders: false,
      payment: false,
      wallet: true,
      bookings: false,
    });
  };
  const handleBookingsClick = () => {
    setDisplayState({
      dashboard: false,
      accountDetails: false,
      billing: false,
      orders: false,
      payment: false,
      wallet: false,
      bookings: true,
    });
  };

  const handleSavingPlanSelect = (savingPlan?: SavingsTowardsRent) => {
    setPlan(savingPlan);
    if (planIndex === 0) {
      setPlanIndex(1);
    }
  };
  const handleSavingPlanDismiss = () => {
    if (planIndex === 1) {
      setPlanIndex(0);
    }
    setPlan(undefined);
  };

  const returnClassName = () => {

  }

  //Effects
  React.useEffect(() => {
    switch (location.hash) {
      case "#liton_tab_1_1":
        handleDashboardClick();
        break;

      case "#liton_tab_1_2":
        handleOrdersClick();
        break;

      case "#liton_tab_1_3":
        handleBillingClick();
        break;

      case "#liton_tab_1_4":
        handleAccountClick();
        break;

      case "#liton_tab_1_5":
        handlePaymentClick();
        break;

      case "#liton_tab_1_6":
        handleWalletClick();
        executeScroll();
        break;

      case "#liton_tab_1_7":
        handleBookingsClick();
        break;

      default:
        break;
    }
  }, [location.hash]);

  //On load get wallet details
  React.useEffect(() => {
    GetUserBookings();
    if (token) {
      getWalletDetails(token).then((result) => {
        // console.log(getWalletId(token as string));

        // if (result.data){
        //     setWalletDetails(result.data.response_data);
        //     console.log(result.data.response_data?.available_balance)
        //     setDetailsLoading(false);
        //     if(result.data.response_data?.available_balance){
        //     setCurrentBalance(result.data.response_data?.available_balance)
        //     }
        // }

        if (result.data) {
          setEmail(
            result &&
              result.data &&
              result.data.data &&
              result.data.data.customer_email
          );
        }

        if (result.error) {
          if (
            result.error.response &&
            result.error.response.data &&
            result.error.response?.data.Message === "01"
          ) {
            setHasWallet(false)
          }
          setDetailsLoading(false);
          // openError('An error occured while fetching wallet details');
        }
      });
    }
  }, []);

  return (
    <section
      className="liton__wishlist-area pb-70"
      id="homes_acct_section"
      ref={sectionRef}
      style={{
        ...(window.innerWidth <= 768 && {
          width: "25.5em",
          fontWeight: "thin",
        }),
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <!-- PRODUCT TAB AREA START --> */}
            <div className="ltn__product-tab-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="ltn__tab-menu-list mb-50">
                      <div className="nav">
                        <Link
                          className={
                            location.hash === "#liton_tab_1_1" ||
                            displayState.dashboard
                              ? "active show"
                              : ""
                          }
                          data-bs-toggle="tab"
                          to="#liton_tab_1_1"
                        >
                          Dashboard <i className="fas fa-home"></i>
                        </Link>
                        <Link
                          className={
                            location.hash === "#liton_tab_1_2 " ||
                            displayState.orders
                              ? "active show"
                              : ""
                          }
                          data-bs-toggle="tab"
                          to="#liton_tab_1_2"
                        >
                          Saving Targets <i className="fas fa-bullseye"></i>
                        </Link>
                        <Link
                          className={
                            location.hash === "#liton_tab_1_3"
                              ? "active show"
                              : ""
                          }
                          data-bs-toggle="tab"
                          to="#liton_tab_1_3"
                        >
                          Saving History{" "}
                          <i className="fas fa-map-marker-alt"></i>
                        </Link>
                        {!isTenant && (
                          <Link
                            className={
                              location.hash === "#liton_tab_1_7"
                                ? "active show"
                                : ""
                            }
                            data-bs-toggle="tab"
                            to="#liton_tab_1_7"
                          >
                            Bookings <i className="fas fa-book"></i>
                          </Link>
                        )}
                        <Link
                          className={
                        location.hash === "#liton_tab_1_6" || displayState.wallet
                              ? `active show`
                              : ""
                          }
                          // className={
                          //   location.hash === "#liton_tab_1_6"
                          //     ? `show ${styles.tabIsActive}`
                          //     : ""
                          // }
                          data-bs-toggle="tab"
                          to="#liton_tab_1_6"
                        >
                          Wallet <i className="fas fa-wallet"></i>
                        </Link>

                        {/* <Link className={location.hash === '#liton_tab_1_5' ? 'active show' : ''} 
                                                   data-bs-toggle="tab" to="#liton_tab_1_5">
                                                        Payment Method <i className="fas fa-credit-card"></i>
                                                </Link> */}
                        <Link
                          className={
                            location.hash === "#liton_tab_1_4"
                              ? "active show"
                              : ""
                          }
                          data-bs-toggle="tab"
                          to="#liton_tab_1_4"
                        >
                          Profile Details <i className="fas fa-user"></i>
                        </Link>
                        {/* <Link data-bs-toggle="tab" to="#liton_tab_1_5" className="">Account Details <i className="fas fa-user"></i></Link> */}
                        {/* <Link to="login.html">Logout <i className="fas fa-sign-out-alt"></i></Link> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="tab-content">
                      {displayState.dashboard && (
                        <UserAccountDashboard
                          userName={username}
                          firstName="firstName"
                          lastName="lastName"
                          phoneNumber="phone"
                          email={email}
                        />
                      )}
                      {/* { displayState.wallet && <UserAccountCreateWallet/> } */}
                      {displayState.wallet &&
                        (hasWallet ? (
                          <UserAccountWallet
                            walletDetails={walletDetails}
                            loading={detailsLoading}
                          />
                        ) : (
                          <UserAccountCreateWallet />
                        ))}
                      {displayState.billing && <UserSavingHistory />}
                      {displayState.accountDetails && (
                        <UserAccountDetails
                          email={""}
                          firstName={""}
                          lastName={""}
                          phoneNumber={""}
                          userName={""}
                        />
                      )}
                      {displayState.bookings && (
                        <UserAccountBookings
                          bookings={bookingsResult.bookings}
                          outgoingBookings={userBookings}
                        />
                      )}
                      {displayState.orders && (
                        <MySwipeableView currentIndex={planIndex}>
                          {/* <UserPaymentSchedule
                                                        onDismiss={handleSavingPlanDismiss}
                                                        savingPlan={targetData1}
                                                    /> */}
                          <UserAccountTargets
                            onSelect={handleSavingPlanSelect}
                            savingsTowardsRent={
                              savingTowardsRent && savingTowardsRent
                            }
                            loading={savingsResult && savingsResult.isLoading}
                          />
                          <UserPaymentSchedule
                            onDismiss={handleSavingPlanDismiss}
                            savingPlan={plan}
                          />
                        </MySwipeableView>
                      )}
                      {displayState.payment && (
                        <UserAccountPayment
                          sources={data?.data}
                          loading={isLoading}
                          onUpdate={handlePaymentUpdate}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- PRODUCT TAB AREA END --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
