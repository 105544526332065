import React from 'react';
import { expDateFormatter, formatCardNumber, isEmpty } from '../../../utils/formUtils';
import TextInput from '../../FormElements/TextInput';

export interface CardPaymentFormState{
    holderName: string;
    cardNumber: string;
    expiry: string;
    cvv: string;
}

interface ErrorFormState{
    holderName: boolean;
    cardNumber: boolean;
    expiry: boolean;
    cvv: boolean;
}

export interface CardPaymentFormProps{
    onSubmit?: (state: CardPaymentFormState)=> void;
    onSuccess?: (fdata: FormData) => void;
    ref?: React.LegacyRef<HTMLDivElement>;
    onCancel?: (e?: React.MouseEvent<HTMLButtonElement>)=> void;
    inputClass?: string;
    labelClass?: string;
    buttonClass?: string;
}

const CardPaymentForm: React.FC<CardPaymentFormProps> = ({ 
    onSubmit, ref, onSuccess, onCancel, inputClass,
    labelClass, buttonClass 
})=>{

    // Refs
    const formRef = React.createRef<HTMLFormElement>();

    // State
    let initErrorState: ErrorFormState = {
        holderName: false,
        cardNumber: false,
        expiry: false,
        cvv: false
    }
    const [errorState, setErrorState] = React.useState<ErrorFormState>(initErrorState);
    

    // Handlers
    const unhighlight = (event: React.ChangeEvent<HTMLInputElement>)=>{
        setErrorState((prevState)=>({...prevState, [event.target.name]: false}));
    }

    const handleExpiryDateChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        setErrorState((prevState)=>({...prevState, [event.target.name]: false}));
        //console.log(expDateFormatter(event.target.value));
        event.target.value = expDateFormatter(event.target.value);
    }

    const handleCardNumberChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        setErrorState((prevState)=>({...prevState, [event.target.name]: false}));
        event.target.value = formatCardNumber(event.target.value);
    } 

    const validateForm = (fdata: FormData): 'passed' | 'failed'=>{
        let result: 'passed' | 'failed' = 'passed';

        let cardNumber = fdata.get('cardNumber')?.toString();

        // Check if any field is empty
        for (const pairs of fdata.entries()) {
            //console.log(pairs);
            if (isEmpty(pairs[1].toString())) {
                setErrorState((prevState)=>({...prevState, [pairs[0]]: true}));
                result = 'failed';
            }
        }

        // Check card number
        if (cardNumber && cardNumber?.length < 16) {
            setErrorState((prevState)=>({...prevState, userName: true}));
            result = 'failed';
        }

        return result;
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>)=>{
        
        if (formRef.current) {
            const fdata = new FormData(formRef.current);

            if (validateForm(fdata) === 'passed') {
                let state: CardPaymentFormState = {
                    holderName: fdata.get('holderName')?.toString() || '',
                    cardNumber: fdata.get('cardNumber')?.toString() || '',
                    expiry: fdata.get('expiry')?.toString() || '',
                    cvv: fdata.get('cvv')?.toString() || '',
                }
                onSubmit && onSubmit(state);
                onSuccess && onSuccess(fdata);
            }
        }
    }
    

    return(
        <div ref={ref}>
            <form ref={formRef} onSubmit={(e)=> e.preventDefault()}>
                <div className='row'>
                    <div className="col-md-12">
                        <label className={labelClass}>Cardholder name</label>
                        <TextInput hideDefaultAdornment name='holderName'
                            error={errorState.holderName}
                            onChange={unhighlight} required={errorState.holderName}
                            className={inputClass}
                        />
                    </div>
                    <div className="col-md-12">
                        <label className={labelClass}>Card number</label>
                        <TextInput hideDefaultAdornment name='cardNumber'
                            error={errorState.cardNumber}
                            errorMessage="Invalid card number"
                            onChange={handleCardNumberChange} required={errorState.cardNumber}
                            className={inputClass}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className={labelClass}>Expiry date</label>
                        <TextInput hideDefaultAdornment name='expiry'
                            error={errorState.expiry}
                            onChange={handleExpiryDateChange} required={errorState.expiry}
                            placeholder="MM / YY"
                            maxLength={7}
                            className={inputClass}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className={labelClass}>Security code:</label>
                        <TextInput hideDefaultAdornment name='cvv'
                            error={errorState.cvv}
                            onChange={unhighlight} required={errorState.cvv}
                            maxLength={3}
                            className={inputClass}
                        />
                    </div>
                </div>
                <button className={`btn theme-btn-1 btn-effect-1 text-uppercase ${buttonClass}`} 
                    type="submit" onClick={handleSubmit}
                >
                    Save
                </button>
                <button name="cancel" onClick={onCancel}
                    className={`btn theme-btn-1 btn-effect-1 text-uppercase ${buttonClass}`}
                >
                    Cancel
                </button>
            </form>
        </div>
    )
}

export default CardPaymentForm;