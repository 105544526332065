import React, { useContext, useState } from "react";
import styles from "./household-form.module.scss";
import { formatAmount, returnMsgFromAxiosError } from "../../../../utils/generalUtils";
import ModalLayout from "../../../../components/ModalLayout";
import { RentDropDown } from "../../../../types/Insurance";
import SaveQuoteSuccess from "../../../../components/Insurance/RentDefault/SaveQuoteSuccess";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setRentDropValue } from "../../../../state/reducers/insuranceSlice";
import PaymentModal from "../../../../components/Insurance/RentDefault/PaymentModal";
import PaymentSuccessful from "../../../../components/Insurance/RentDefault/PaymentSuccessful";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import {householdMakePaymentApi } from "../../../../swr/insurance";
import LoadingContext from "../../../../contexts/LoadingContext";
import ToastContext from "../../../../contexts/ToastContext";

const GetQuoteSuccess = ({
  rentDropDownValue,
  closeAllDropDowns,
  walletBalance,
}: {
  rentDropDownValue: RentDropDown;
  closeAllDropDowns: () => void;
  walletBalance?: string;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { HouseHoldGetPolicyRes, ReapplyHouseHold} = useSelector(
    (state: RootState) => state.insurance
  );
  const { setLoading } = useContext(LoadingContext);
  const { openError, openSuccess } = useContext(ToastContext);

  const [pin, setPin] = useState("")
  const pinOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value)
  }

  const token = localStorage.getItem("token") as string;

  const viewSavedQuotes = () => {
    history.push("/insurance");
    closeAllDropDowns();
  };
  const goHome = () => {
    history.push("/");
    closeAllDropDowns();
  };

  const openSavedQuoteSuccess = () => {
    dispatch(setRentDropValue("SaveQuoteSuccess"));
  };

  const openPaymentModal = () => {
    dispatch(setRentDropValue("PaymentModal"));
  };

  const ProceedWithPayment = async () => {
    if(!pin || pin.length !== 4){
      openError("Please enter your transaction pin")
      return
    }
    closeAllDropDowns();
    setLoading(true)
    const { data, error } = await householdMakePaymentApi(
      {
        payAmount: HouseHoldGetPolicyRes?.amountToPay || ReapplyHouseHold?.premium,
        policyId: HouseHoldGetPolicyRes?.policyId || ReapplyHouseHold?.policyId,
        // paymentMethod: "HOMEBUDDY",
        // transId: HouseHoldGetPolicyRes?.policyId || ReapplyHouseHold?.policyId
      },
      pin,
      token
    );

    if (data) {
      setLoading(false);
      dispatch(setRentDropValue("PaymentSuccess"));
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "An error occurred while making payment")
      );
      return;
    }
    setLoading(false);
    openError("An error occurred while making payment");
  };

  const fundWallet = () => {
    history.push("/account#liton_tab_1_6");
    closeAllDropDowns();
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles["get-quote-cont"]}>
          <span className={styles["get-quote-header"]}>Congratulations!</span>

          <span className={styles["get-quote-text"]}>
            You have successfully gotten a quote for<br></br> Household
            Insurance policy.
          </span>

          <div className={styles["get-quote-card-I"]}>
          {HouseHoldGetPolicyRes?.policyNumber ||
            ReapplyHouseHold?.policyNo ? (
            <div className={styles["get-quote-card-cont"]}>
              <span className={styles["get-quote-card-p"]}>
                Insurance Policy Number
              </span>
              <span className={styles["get-quote-card-span-top"]}>
                ₦{" "}
                {HouseHoldGetPolicyRes && HouseHoldGetPolicyRes.policyNumber
                  ? HouseHoldGetPolicyRes.policyNumber
                  : ReapplyHouseHold?.policyNo}
              </span>
            </div>
             ) : null}

            <div className={styles["get-quote-card-cont"]}>
              <span className={styles["get-quote-card-p"]}>
                Payable Premium
              </span>
              <span className={styles["get-quote-card-span"]}>
                {HouseHoldGetPolicyRes && HouseHoldGetPolicyRes.amountToPay
                  ? formatAmount(HouseHoldGetPolicyRes.amountToPay)
                  : ReapplyHouseHold?.premium
                  ? formatAmount(ReapplyHouseHold.premium)
                : ""}
              </span>
            </div>
          </div>

          <div className={styles["get-quote-buttons"]}>
            <div
              className={styles["get-quote-but-green"]}
              role="button"
              onClick={() => openPaymentModal()}
            >
              <span>Pay Now</span>
            </div>
            <div
              className={styles["get-quote-but-white"]}
              role="button"
              onClick={() => openSavedQuoteSuccess()}
            >
              <span>Save Quote</span>
            </div>
          </div>
        </div>
      </div>

      <ModalLayout modalOpen={rentDropDownValue === "SaveQuoteSuccess"}>
        <SaveQuoteSuccess
          close={closeAllDropDowns}
          viewSavedQuotes={viewSavedQuotes}
          goHome={goHome}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "PaymentModal"}>
        <PaymentModal
          Proceed={ProceedWithPayment}
          FundWallet={fundWallet}
          closeAllModals={closeAllDropDowns}
          walletBalance={walletBalance}
          amountToPay=
        {HouseHoldGetPolicyRes && HouseHoldGetPolicyRes.amountToPay
         ? HouseHoldGetPolicyRes.amountToPay
         : ReapplyHouseHold?.premium
         ? ReapplyHouseHold?.premium
         : 0}
         pinOnChange={pinOnChange}
         pin={pin}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "PaymentSuccess"}>
        <PaymentSuccessful
          close={closeAllDropDowns}
          getNewPolicy={viewSavedQuotes}
          goHome={goHome}
        />
      </ModalLayout>
    </>
  );
};

export default GetQuoteSuccess;
