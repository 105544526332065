import React from 'react';
import styles from './swipeable.module.scss';

interface SlideProps{
    slides: number;
    index: number;
    activeIndex: number;
    children?: React.ReactNode;
    extraMargin?: number | string;
}
const Slide: React.FC<SlideProps> = ({ slides, index, children, activeIndex })=>{

    let width = 100 / slides;

    const getMargin = () =>{
        if (index === 0) {
            let margin = Math.abs(activeIndex - index) * 100 / slides;
            return margin;
        }
        return 0;
    }

    return(
        <div style={{ 
            width: `${width}%`,
            transition: '0.6s',
            marginLeft: `-${getMargin()}%`,
        }}>
            { children }
        </div>
    )
}

export interface MySwipeableViewProps{
    children?: React.ReactNode;
    currentIndex: number;
}

const MySwipeableView: React.FC<MySwipeableViewProps> = ({ children, currentIndex })=>{

   // State
   const [activeIndex, setActiveIndex] = React.useState<number>(currentIndex);

   // Effect
   React.useEffect(()=>{
       setActiveIndex(currentIndex);
   },[currentIndex])


    return(
        <div className={styles.slidershow}>
            <div style={{
                width: `${React.Children.count(children) * 100}%`,
                height: '100%',
                display: 'flex'
            }}>
                {
                    React.Children.map(children, (child, index)=>{
                        if (React.isValidElement(child)) {
                            return(
                                <Slide
                                    index={index}
                                    activeIndex={activeIndex}
                                    slides={React.Children.count(children)}
                                >
                                    { child }
                                </Slide>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}

export default MySwipeableView;