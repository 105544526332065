import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActivePolicyKeys,
  ApplyInsuranceI,
  ApplyInsuranceII,
  CreatePolicyRequestResponseData,
  CreateQuoteResponseData,
  FormType,
  GetRentInsuranceDocs,
  HomeOwnersGetQuoteReq,
  HouseHoldDetails,
  HouseHoldGetQuoteReq,
  LandlordDetails,
  QuoteInfoDetailsRent,
  ReapplyHomeOwnersType,
  ReapplyHouseHoldType,
  RentDropDown,
  TenantDetailsType,
} from "../../types/Insurance";
import {
  CreatePolicyRequest,
  QuoteInfoDetails,
  CreatePolicyRequestInitialValue,
  HandleCPHomeOwnersChange,
  HomeOwnersDetails,
} from "../../types/Insurance";
import { ExpectedJSONFromExcel } from "../../views/Insurance/HomeOwners/HomeOwnersForm/GetAQuote";

const initialState = {
  personalDetails: {} as ApplyInsuranceI,
  category: "",
  formType: "" as FormType,
  rentStep: 1,
  rentDropDownValue: "" as RentDropDown,
  getAQuoteReq: {} as ApplyInsuranceII,
  landLordDetails: {} as LandlordDetails,
  householdDetails: {} as HouseHoldDetails,
  tenantDetails: {} as TenantDetailsType,
  termsAgreement: false,
  HomeOwnersGetQuoteRequest: {} as HomeOwnersGetQuoteReq,
  HouseHoldGetQuoteRequest: {} as HouseHoldGetQuoteReq,
  homeOwnersDetails: {} as HomeOwnersDetails,
  excelFile: undefined as undefined | File,
  displayExcelData: [] as ExpectedJSONFromExcel[] | [],
  HomeOwnerGetQuoteRes: undefined as CreateQuoteResponseData | undefined,
  HomeOwnerGetPolicyRes: undefined as
    | CreatePolicyRequestResponseData
    | undefined,
  HouseHoldGetQuoteRes: undefined as CreateQuoteResponseData | undefined,
  HouseHoldGetPolicyRes: undefined as
    | CreatePolicyRequestResponseData
    | undefined,
  rentQuoteInfoDetails: {} as QuoteInfoDetails,
  RentDefaultInsuranceId: "",
  RentDefaultInsuranceQuote: undefined as number | undefined,
  ReapplyHomeowners: undefined as ReapplyHomeOwnersType | undefined,
  ActivePolicyKeysSlice: undefined as ActivePolicyKeys | undefined,
  rentInsuranceDocs: undefined as GetRentInsuranceDocs[] | undefined,
  ReapplyHouseHold: undefined as ReapplyHouseHoldType | undefined,
};

const insuranceSlice = createSlice({
  name: "insurance",
  initialState,
  reducers: {
    setPersonalDetails: (state, action: PayloadAction<ApplyInsuranceI>) => {
      state.personalDetails = action.payload;
    },

    setCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },

    setRentStep: (state, action: PayloadAction<number>) => {
      state.rentStep = action.payload;
    },

    setRentDropValue: (state, action: PayloadAction<RentDropDown>) => {
      state.rentDropDownValue = action.payload;
    },
    setFormType: (state, action: PayloadAction<FormType>) => {
      state.formType = action.payload;
    },

    setGetAQuoteReq: (state, action: PayloadAction<ApplyInsuranceII>) => {
      state.getAQuoteReq = action.payload;
    },

    setLandlordDetails: (state, action: PayloadAction<LandlordDetails>) => {
      state.landLordDetails = action.payload;
    },

    setHouseholdDetails: (state, action: PayloadAction<HouseHoldDetails>) => {
      state.householdDetails = action.payload;
    },

    setTenantDetails: (state, action: PayloadAction<TenantDetailsType>) => {
      state.tenantDetails = action.payload;
    },

    setTermsAgreement: (state, action: PayloadAction<boolean>) => {
      state.termsAgreement = action.payload;
    },

    setHomeOwnersGetQuoteRequest: (
      state,
      action: PayloadAction<HomeOwnersGetQuoteReq>
    ) => {
      state.HomeOwnersGetQuoteRequest = action.payload;
    },
    setHouseHoldGetQuoteRequest: (
      state,
      action: PayloadAction<HouseHoldGetQuoteReq>
    ) => {
      state.HouseHoldGetQuoteRequest = action.payload;
    },

    setExcelFile: (state, action: PayloadAction<File | undefined>) => {
      state.excelFile = action.payload;
    },

    setDisplayExcelData: (
      state,
      action: PayloadAction<ExpectedJSONFromExcel[] | []>
    ) => {
      state.displayExcelData = action.payload;
    },

    setHomeOwnersDetails: (state, action: PayloadAction<HomeOwnersDetails>) => {
      state.homeOwnersDetails = action.payload;
    },

    setHomeOwnerGetQuoteRes: (
      state,
      action: PayloadAction<CreateQuoteResponseData>
    ) => {
      state.HomeOwnerGetQuoteRes = action.payload;
    },

    setHomeOwnerGetPolicyRes: (
      state,
      action: PayloadAction<CreatePolicyRequestResponseData>
    ) => {
      state.HomeOwnerGetPolicyRes = action.payload;
    },
    setHouseHoldGetQuoteRes: (
      state,
      action: PayloadAction<CreateQuoteResponseData>
    ) => {
      state.HouseHoldGetQuoteRes = action.payload;
    },

    setHouseHoldGetPolicyRes: (
      state,
      action: PayloadAction<CreatePolicyRequestResponseData>
    ) => {
      state.HouseHoldGetPolicyRes = action.payload;
    },

    setRentQuoteInfoDetails: (
      state,
      action: PayloadAction<QuoteInfoDetailsRent>
    ) => {
      state.rentQuoteInfoDetails = action.payload;
    },

    setRentDefaultInsuranceId: (state, action: PayloadAction<string>) => {
      state.RentDefaultInsuranceId = action.payload
    },

    setRentDefaultInsuranceQuote: (state, action: PayloadAction<number>) => {
      state.RentDefaultInsuranceQuote = action.payload
    },

    setReapplyHomeowners: (state, action: PayloadAction<ReapplyHomeOwnersType>) => {
      state.ReapplyHomeowners = action.payload
    },
    setReapplyHouseHold: (state, action: PayloadAction<ReapplyHouseHoldType>) => {
      state.ReapplyHouseHold = action.payload
    },

    setActivePolicyKeysSlice: (state, action: PayloadAction<ActivePolicyKeys>) => {
      state.ActivePolicyKeysSlice = action.payload
    },

    setRentInsuranceDocs: (state, action: PayloadAction<GetRentInsuranceDocs[] | undefined>) => {
      state.rentInsuranceDocs = action.payload
    }
  },
});

export const {
  setPersonalDetails,
  setCategory,
  setRentStep,
  setRentDropValue,
  setGetAQuoteReq,
  setLandlordDetails,
  setHouseholdDetails,
  setTenantDetails,
  setTermsAgreement,
  setFormType,
  setHomeOwnersGetQuoteRequest,
  setHouseHoldGetQuoteRequest,
  setHouseHoldGetPolicyRes,
  setHouseHoldGetQuoteRes,
  setExcelFile,
  setDisplayExcelData,
  setHomeOwnersDetails,
  setHomeOwnerGetQuoteRes,
  setHomeOwnerGetPolicyRes,
  setRentQuoteInfoDetails,
  setRentDefaultInsuranceId,
  setRentDefaultInsuranceQuote,
  setReapplyHomeowners,
  setActivePolicyKeysSlice,
  setRentInsuranceDocs,
  setReapplyHouseHold
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
