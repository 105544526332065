import React from 'react';
import { useHistory } from 'react-router-dom';
import PromptDialog from '../Dialogs/PromptDialog';

export interface WalletCheckProps{
    children: any;
}

export const WalletCheck: React.FC<WalletCheckProps> = ({ children })=>{

    let history = useHistory();

    const onProceed = ()=>{
        history.push('/account#liton_tab_1_6')
    }

    let show = false;

    if (show) {
        return(
            <PromptDialog
                title="This service requires a wallet"
                open
                onProceed={onProceed}
            >
                It looks like you do not have a wallet. 
                Please create one before using this service
            </PromptDialog>
        )
    }

    return( children )
    
}

const WalletRequiredPrompt: React.FC<any> = ()=>{
    let history = useHistory();

    const onProceed = ()=>{
        history.push('/account#liton_tab_1_6')
    }

    return(
        <PromptDialog
            title="This service requires a wallet"
            open
            onProceed={onProceed}
        >
            It looks like you don't have a wallet. 
            Please create one before using this service
        </PromptDialog>
    )
}

export function withWalletCheck(Component: React.ComponentType){

    let show = true;

    if (show) {
        return( WalletRequiredPrompt )
    }

    return( Component );
}