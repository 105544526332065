import React, { SetStateAction, useState } from 'react'
import { Button } from '@mui/material'


interface OtpModalProps {
    closeModal: () => void,
    otp: string,
    setOtp: React.Dispatch<SetStateAction<string>>,
    processPayment: () => void
}

const OtpModal: React.FC<OtpModalProps> = ({closeModal, otp, setOtp, processPayment}) => {
    const [onFocus, setonFocus] = useState(false)

    const handleInputClick = () => {
      setonFocus(true)
    }
  return (
    <>
    <div style={{
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'flex-end'
      }}>
    <div className='icon-cancel' onClick={closeModal}></div>
    </div>
      
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'space-between',
          zIndex: '1',
        }}
      >
        
        <span
          style={{
            paddingBottom: '0',
            marginTop: '0',
            fontSize: '2em',
            color: 'var(--ltn__secondary-color)',
          }}
        >
          Enter OTP
        </span>
        
        <div>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            style={{
              height: '3em',
              border: '1px solid hsl(0, 5%, 80%)',
              backgroundColor: onFocus ? 'hsl(0, 5%, 95%)' : '',
            }}
            onFocus={handleInputClick}
            className={onFocus ? 'highlight' : ''}
            autoComplete="off"
          />
        </div>

        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: 'center',
            backgroundColor: 'var(--ltn__secondary-color)',
            '&:hover': {
              backgroundColor: 'var(--ltn__paragraph-color)',
            },
            // paddingTop: "1.8em",
            height: '3em',
            display: 'flex',
            alignItems: 'center',
            marginTop: '0.5em',
            justifyContent: 'center',
          }}
          onClick={() => {
            closeModal()
            processPayment()
          }}
        >
          Submit
        </Button>
      </div>
    </>
  )
}

export default OtpModal