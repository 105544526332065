import { Button } from "@mui/material";
import React, { SetStateAction, useContext, useState } from "react";
import { verifyOtp } from "../../../swr/wallet";
import ToastContext from "../../../contexts/ToastContext";
import LoadingContext from "../../../contexts/LoadingContext";

interface ResetProps {
  closeModal: () => void;
  resetPin: () => void;
  email: string;
  otp: string;
  setOtp: React.Dispatch<SetStateAction<string>>;
}

const OtpResetPin: React.FC<ResetProps> = ({
  closeModal,
  resetPin,
  email,
  otp,
  setOtp,
}) => {
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const token = localStorage.getItem("token") as string;
  const [onFocus, setonFocus] = useState(false);

  const handleInputClick = () => {
    setonFocus(true);
  };

  const handleOtpValid = () => {
    openSuccess("OTP Validated Successfully");
    closeModal();
    resetPin();
  };

  const handleInvalidOtp = () => {
    setLoading(false);
    openError("Error validating OTP");
  };

  const handleSubmit = async () => {
    if (otp.trim().length !== 6) {
      openError("OTP must be 6-digit");
      return;
    }
    setonFocus(false);
    setLoading(true);
    closeModal();
    resetPin();
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "left",
          justifyContent: "flex-end",
        }}
      >
        <div className="icon-cancel" onClick={closeModal}></div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "space-between",
          zIndex: "1",
        }}
      >
        <span
          style={{
            paddingBottom: "0",
            marginTop: "0",
            fontSize: "2em",
            color: "var(--ltn__secondary-color)",
          }}
        >
          Enter OTP
        </span>

        <div>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value.trim())}
            style={{
              height: "3em",
              border: "1px solid hsl(0, 5%, 80%)",
              backgroundColor: onFocus ? "hsl(0, 5%, 95%)" : "",
            }}
            onFocus={handleInputClick}
            className={onFocus ? "highlight" : ""}
            autoComplete="off"
          />
        </div>

        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__secondary-color)",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
            // paddingTop: "1.8em",
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
          }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default OtpResetPin;
