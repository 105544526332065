import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

export interface ProtectedRouteProps extends RouteProps{
    redirectPath?: string;
    // exact?: boolean | undefined;
    
}

const ProtectedAuthRoute: React.FC<ProtectedRouteProps> = ({ redirectPath = '/', ...props })=>{

    // First check if there is a token in local storage
    const token = localStorage.getItem('token');
    if (token) {
        return <Redirect to={redirectPath} />
    }

    return(
        <Route {...props} />
    )
}

export default ProtectedAuthRoute;