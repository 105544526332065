import React, { RefObject } from 'react';
import TextInput from '../../components/FormElements/TextInput';
import CardPaymentAccordion from '../../components/CardPaymentAccordion';
import styles from './userAcct.module.scss';
import BankTransferAccordion from '../../components/BankTransferAccordion';
import Dropdown from '../../components/FormElements/Dropdown';
import Button from '@mui/material/Button';
import { AddFundingSourceRequest, FundingSource } from '../../types/FundingSource';
import CardPaymentForm, { CardPaymentFormState } from '../../components/Forms/CardPaymentForm';
import jwt, { Jwt, JwtPayload } from 'jsonwebtoken';
import Skeleton from '@mui/material/Skeleton';
import { CardIssuer, FundingType } from '../../types/FundingType';
import { addFundingSource, deleteFundingSource } from '../../swr/funding';
import LoadingContext from '../../contexts/LoadingContext';
import CustomToast from '../../components/CustomToast';
import AccountSectionContext from '../../contexts/AccountSectionContext';
import { useHistory } from 'react-router-dom';
import BankAccountForm, { BankAccountFormState } from '../../components/Forms/BankAccountForm';

export interface UserAccountPaymentProps{
    sources?: FundingSource[] | null;
    loading?: boolean;
    onUpdate?: ()=> void;
}

const UserAccountPayment: React.FC<UserAccountPaymentProps> = ({ sources, loading, onUpdate })=>{

    // Contexts
    const { setLoading } = React.useContext(LoadingContext);
    const sectionRef = React.useContext(AccountSectionContext);

    // Refs
    const contentEl = React.useRef<HTMLDivElement>(null);
    const formEl = React.createRef<HTMLDivElement>();

    // Hooks
    const history = useHistory();

    // Token details
    let uid = '';
    let email = '';
    const token = localStorage.getItem('token');
    if (token) {
        let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
        if (decodedToken) {
            const { payload } = decodedToken as JwtPayload;
            uid = payload.uid
            email = payload.email
        }
    }

    // Initials
    let initErrorMessage = 'An error occurred while registering your card';
    let initSuccessMessage = 'Card successfully registered';

    // State
    const [cardActive, setCardActive] = React.useState<boolean>(false);
    const [bankActive, setBankActive] = React.useState<boolean>(false);
    const [success, setSuccessOpen] = React.useState<boolean>(false);
    const [error, setErrorOpen] = React.useState<boolean>(false);
    const [successMessage, setSuccessMessage] = React.useState<string>(initSuccessMessage);
    const [errorMessage, setErrorMessage] = React.useState<string>(initErrorMessage);

    // Toast Handlers
    const handleSuccessClose = ()=>{
        setSuccessOpen(false);
    }
    const handleErrorClose = ()=>{
        setErrorOpen(false);
    }

    // Handlers
    /** Togglers */
    const toggleCard = ()=>{ setCardActive(!cardActive) };
    const toggleBankAccount = ()=>{ setBankActive(!bankActive) };

    /** Card Handlers */
    const getCardIssuer = (cardNumber: string)=>{
        if (cardNumber.startsWith("5")) {
            return CardIssuer.Mastercard
        }
        if (cardNumber.startsWith('4')) {
            return CardIssuer.Visa
        }
        return CardIssuer.Verve
    }
    const handleCardSubmit = async (state: CardPaymentFormState)=>{
        setLoading(true);
        let request: AddFundingSourceRequest = {
            userId: uid,
            email: email,
            fundingType: FundingType.Card,
            issuerToken: getCardIssuer(state.cardNumber)
        }
        const { data, error } = await addFundingSource(request);
        if (data) {
            setLoading(false);
            setSuccessOpen(true);
            toggleCard();
            onUpdate && onUpdate();
            window.scrollTo(200, 200);
        }
        if (error) {
            setErrorMessage(initErrorMessage);
            setLoading(false);
            setErrorOpen(true);
        }
    }
    const handleCardCancel = (e?: React.MouseEvent<HTMLButtonElement>)=>{
        toggleCard();
    }
    const handleCardDelete = async (id?: number) =>{
        setLoading(true);
        const { data, error } = await deleteFundingSource(id || 0);
        if (data) {
            setLoading(false);
            onUpdate && onUpdate();
        }
        if (error) {
            setErrorMessage('An error occurred while deleting your card')
            setLoading(false);
            setErrorOpen(true);
        }
    }
    const clearForm = (fdata: FormData)=>{
        fdata.delete('holderName');
        fdata.delete('cardNumber');
        fdata.delete('expiry');
        fdata.delete('cvv');
    }

    /** Bank Handlers */
    const handleBankSubmit = async (state: BankAccountFormState)=>{
        setLoading(true);
        let request: AddFundingSourceRequest = {
            userId: uid,
            email: email,
            accountName: state.accountName,
            accountNumber: state.accountNumber,
            bankCode: state.bankCode,
            fundingType: FundingType['Direct Debit']
        }
        const { data, error } = await addFundingSource(request);
        if (data) {
            setSuccessMessage('Bank account successfully added');
            setLoading(false);
            setSuccessOpen(true);
            toggleBankAccount();
            onUpdate && onUpdate();
            //window.scrollTo(200, 400);
        }
        if (error) {
            setErrorMessage('An error occured while adding your bank account');
            setLoading(false);
            setErrorOpen(true);
        }
    }
    const handleBankCancel = (e?: React.MouseEvent<HTMLButtonElement>)=>{
        toggleBankAccount();
    }
    const handleBankDelete = async (id?: number) =>{
        setLoading(true);
        const { data, error } = await deleteFundingSource(id || 0);
        if (data) {
            setLoading(false);
            onUpdate && onUpdate();
        }
        if (error) {
            setErrorMessage('An error occurred while deleting your direct debit source')
            setLoading(false);
            setErrorOpen(true);
        }
    }
    const clearBankForm = (fdata: FormData)=>{
        fdata.delete('accountNumber');
        fdata.delete('accountName');
        fdata.delete('bankCode');
    }

    // Bank options
    const banks = [
        'Access Bank', 'Guaranty Trust Bank', 'Providus Bank',
        'United Bank of Africa', 'Wema Bank', 'Zenith Bank',
    ]

    // Funding Sources
    let cardSources: FundingSource[] = [];
    let directDebitSources: FundingSource[] = [];
    let virtualAccounts: FundingSource[] = [];

    if (sources) {
        cardSources = sources.filter((item)=> item.fundingType === 0);
        directDebitSources = sources.filter((item)=> item.fundingType === 1);
        virtualAccounts = sources.filter((item)=> item.fundingType === 2);
    }

    // Elements
    const cardPayments = (
        <div className={styles.cardPayment}>
        <h4 className="title-2">Card Paymemts</h4>
        <div className="ltn__form-box">
            {
                loading &&
                <Skeleton variant="rectangular" width="100%" >
                   <CardPaymentAccordion>
                        <CardPaymentForm/>
                    </CardPaymentAccordion>
                </Skeleton>
            }
            {
                cardSources.map((item, index)=>(
                    <CardPaymentAccordion
                        holderName='Ikemefuna Lawrence Ezechukwu'
                        issuerToken={item.issuerToken}
                        key={`cd${index}`}
                        onDelete={handleCardDelete}
                        cardId={item.id}
                    >
                        <CardPaymentForm/>
                    </CardPaymentAccordion>
                ))
            }
            <div className={styles.contentWrapper} ref={contentEl}
                style={
                    cardActive
                        ? { height: '490px', padding: '30px',  borderWidth: '1px'}
                        : {}
                } 
            >
                <CardPaymentForm 
                    onSubmit={handleCardSubmit} onSuccess={clearForm}
                    onCancel={handleCardCancel}
                />
            </div>
            {
                !cardActive && 
                <Button onClick={toggleCard}
                    sx={{
                        fontSize: '12px',
                        backgroundColor: 'var(--ltn_secondary-color)',
                        '&:hover':{
                            backgroundColor: 'var(--section-bg-2)'
                        }
                    }}
                    variant='contained'>
                    Add a card
                </Button>
            }
        </div>
        </div>
    )

    const banktransferPayment = (
        <>
        <h4 className="title-2">Direct Debit</h4>
        <div className="ltn__form-box">
            {
                loading &&
                <Skeleton variant="rectangular" width="100%" >
                    <BankTransferAccordion>
                        <BankAccountForm/>
                    </BankTransferAccordion>
                </Skeleton>
            }
            {
                directDebitSources.map((item, index)=>(
                    <BankTransferAccordion
                        accountName={item.accountName}
                        accountNumber={item.accountNumber}
                        bankCode={item.bankCode}
                        key={`dd${index}`}
                        onDelete={handleBankDelete}
                        debitId={item.id}
                    >
                        <CardPaymentForm/>
                    </BankTransferAccordion>
                ))
            }
            <div className={styles.contentWrapper} ref={contentEl}
                style={
                    bankActive
                        ? { height: '490px', padding: '30px',  borderWidth: '1px'}
                        : {}
                } 
            >
                <BankAccountForm 
                    onSubmit={handleBankSubmit} onSuccess={clearBankForm}
                    onCancel={handleBankCancel}
                />
            </div>
            {
                !bankActive &&
                <Button onClick={toggleBankAccount}
                sx={{
                    fontSize: '12px',
                    backgroundColor: 'var(--ltn_secondary-color)',
                    '&:hover':{
                        backgroundColor: 'var(--section-bg-2)'
                    }
                }}
                variant='contained'>
                Add a bank account
            </Button>}
        </div>
        </>
    )


    return(
        <>
        <CustomToast errOpen={error} successOpen={success}
            onErrClose={handleErrorClose} onSuccessClose={handleSuccessClose}
            errMessage={errorMessage}
            successMessage={successMessage}
        />
        <div className="tab-pane fade active show" id="liton_tab_1_5">
            <div className="ltn__myaccount-tab-content-inner">
                {/* <p>The following paymemt methods will be used on the checkout page by default.</p> */}
                { cardPayments }
                { banktransferPayment }
            </div>
        </div>
        </>
    )
}

export default UserAccountPayment;