import React from "react";
import styles from "../insurance-comp.module.scss";
import Close from "@mui/icons-material/Close";

const SaveQuoteSuccess = ({
  viewSavedQuotes,
  goHome,
  close,
}: {
  viewSavedQuotes: () => void;
  goHome: () => void;
  close: () => void;
}) => {
  return (
    <div className={styles["save-cont"]}>
      <div className={styles["inner-cont"]}>
        <div
          className={styles["close-cont"]}
          role="button"
          onClick={() => close()}
        >
          <div className="icon-cancel"></div>
        </div>

        <span className={styles["save-quote-header"]}>Success!</span>
        <span className={styles["save-quote-text"]}>
          You have successfully saved your policy quote. Kindly check your mail
          for more details.
        </span>

        <div
          className={styles["save-quote-but-green"]}
          role="button"
          onClick={() => viewSavedQuotes()}
        >
          <span>View Saved Quotes</span>
        </div>

        <span
          className={styles["save-home"]}
          role="button"
          onClick={() => goHome()}
        >
          Go Home
        </span>
      </div>
    </div>
  );
};

export default SaveQuoteSuccess;
