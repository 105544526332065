import React from 'react';

export interface CheckboxProps{
    label?: string;
    useBoolean?: boolean;
    name?: string;
    isChecked?: boolean;
    useValue?: any;
    val?: any;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, useBoolean, name, useValue, isChecked = false, val })=>{
    
    // Ref
    let inputRef = React.createRef<HTMLInputElement>();

    // State
    const [value, setValue] = React.useState<any>('');
    const [checked, setChecked] = React.useState<boolean>(isChecked);

    // Handlers
    const triggerChange = (eventValue: any, ischecked: boolean)=>{
        const customEvent = new CustomEvent('amenitycheck', { detail: { value: eventValue, checked: ischecked } });
        document.dispatchEvent(customEvent);
    }

    // Effect for update mode
    React.useEffect(()=>{
        if (useBoolean) {
            setValue(useValue)
        }
        else
        if (val){
            setValue(val);
        }else{
            setValue(label);
        }
        setChecked(isChecked)
    }, [useValue, isChecked, val])

    const handleChange = (event: React.FormEvent<HTMLInputElement>)=>{
        if (useBoolean) {
            if (event.currentTarget.checked) {
                setValue('yes');
                setChecked(true)
            }
            else{
                setValue('no');
                setChecked(false);
            }
        }
        else{
            if (event.currentTarget.checked) {
                setChecked(true)
                triggerChange(value, true);
            }
            else{
                setChecked(false);
                triggerChange(value, false);
            }
        }
    }


    return(
        <label className="checkbox-item">
            {label}
            <input type="checkbox" value={value} aria-checked={checked} checked={checked}
            ref={inputRef} name={name} onChange={handleChange} autoComplete="off"/>
            <span className="checkmark" aria-label="prop-checkbox" aria-valuetext={value}></span>
        </label>
    )
}

export default Checkbox;