import React from 'react';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import styles from './cardFund.module.scss';

export interface CardFundOptionProps{
    iconClass: string; title: string; subtitle: string;
    key?: any;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const CardFundOption: React.FC<CardFundOptionProps> = ({
    iconClass, title, subtitle, key, className, onClick
})=>{
    // State
    const [elevation, setElevation] = React.useState<number>(1)

    // Handlers
    const handleMouseOver = ()=>{
        setElevation(3);
    }
    const handleMouseOut = ()=>{
        setElevation(1);
    }

    return(
        <Paper key={key} className={`${styles.card} ${className}`} 
            elevation={elevation} onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={onClick}
        >
            <div className={styles.cardLeft}>
                {/**Icon */}
                <Avatar sx={{ backgroundColor: 'rgba(145, 234, 145, 0.6)' }}>
                    <i className={iconClass}></i>
                </Avatar>

                {/** Title and Subtitle */}
                <div className={styles.cardTitle}>
                    <p>{title}</p>
                    <p>{subtitle}</p>
                </div>
            </div>

            {/** Arrow icon */}
            <div className={styles.arrow}>
                <i className="fa fa-chevron-right"></i>
            </div>

        </Paper>
    )
}

export default CardFundOption;