import { useAppSelector } from '../../hooks/useSelector';
import { RootState } from '../../state/store';

const Dashboard = () => {
    const { email } = useAppSelector((state: RootState) => state.auth.user);

    return (
        <div className="ltn__404-area ltn__404-area-1 mb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="error-404-inner text-center">
                            <h1> WELCOME TO YOUR ACCESS HOMES DASHBOARD, {email}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
