import React from 'react'
import AboutUsImage from '../../img/others/7.png';
import styles from './AboutUsPage.module.scss';
import AboutImage from '../../img/others/aboutimg.png'
import AboutImages from '../../img/others/aboutus-imgs.png'
import Footer from '../../components/Footer';
const AboutUsPage = () => {
  return (
    <div className="ltn__about-us-area pt-10">
    <div className="container">
        <div className="row">
            <div className="col-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                    <img src={AboutImage} alt="About Us" />
                    <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                        {/* <div className="ltn__video-img ltn__animation-pulse1">
            <img src="img/others/8.png" alt="video popup bg image" />
            <a
              className="ltn__video-icon-2 ltn__video-icon-2-border---"
              href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
              data-rel="lightcase:myCollection"
            >
              <i className="fa fa-play"></i>
            </a>
          </div> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                    <div className="section-title-area ltn__section-title-2--- mb-20">
                        <h6 className="section-title section-subtitle ltn__secondary-color mb-40">Our Vision</h6>
                        <h1 className="section-title">
                           Create a world where a Perfect Home Exists<span>.</span>
                        </h1>
                        <p className={styles.visiontext}>
                        To create a world where finding, financing and managing the perfect home is not just a transaction, but a seamless and joyful journey. 
                        </p>
                        {/* <p>
                        HomeBuddy is the safest and most convenient online one-stop shop for all of your housing requirements. Nigerians can discover and pay for rental housing, as well as own a home. Currently, it takes an average of two to three months to find a suitable apartment that fulfills your requirements, and you must pay up to two years' rent in advance.
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                    <div className="section-title-area ltn__section-title-2--- mb-20">
                        <h6 className="section-title section-subtitle ltn__secondary-color mb-40">Our Mission</h6>
                        <h1 className="section-title">
                        Revolutionize the housing ecosystem<span>.</span>
                        </h1>
                        <p className={styles.visiontext}>
                        Our mission is to Revolutionize the housing ecosystem by creating a user-friendly platform using cutting-edge technology, connecting renters and buyers with property owners seamlessly. We aim to transform the home-finding, financing and living experience through innovation and transparency, fostering a sense of belonging and fulfillment in every home.
                        </p>
                        {/* <p>
                        HomeBuddy is the safest and most convenient online one-stop shop for all of your housing requirements. Nigerians can discover and pay for rental housing, as well as own a home. Currently, it takes an average of two to three months to find a suitable apartment that fulfills your requirements, and you must pay up to two years' rent in advance.
                        </p> */}
                    </div>
                </div>
            </div>
            <div className="col-6 align-self-center mt-40">
                <div className="about-us-img-wrap about-img-left">
                    <img src={AboutImages} alt="About Us" />
                    <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                        {/* <div className="ltn__video-img ltn__animation-pulse1">
            <img src="img/others/8.png" alt="video popup bg image" />
            <a
              className="ltn__video-icon-2 ltn__video-icon-2-border---"
              href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
              data-rel="lightcase:myCollection"
            >
              <i className="fa fa-play"></i>
            </a>
          </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default AboutUsPage