import React from 'react';
import TabNavAction, { TabNavActionProps } from '../TabNavAction';
import styles from './tabnavigation.module.scss';

export interface TabNavigationProps{
    children: React.ReactElement<TabNavActionProps>[];
    onChange?: (key: string) => any;
    index?: number;
}

const TabNavigation: React.FC<TabNavigationProps> = ({
    children,
    onChange = (key)=> console.log(''),
    index = 0
})=>{

    const [activeIndex, setActiveIndex] = React.useState(index);

    const handleClick = (event: React.MouseEvent, key: string, i: number)=>{
        setActiveIndex(i);
        onChange(key);
    }

    // Effect
    React.useEffect(()=>{
        setActiveIndex(index);
    }, [index]);

    return(
        <div className={styles.nav}>
            {React.Children.map(children, (child, index) =>{
                if (React.isValidElement(child)) {
                    return React.cloneElement(child as React.ReactElement<TabNavActionProps>, {
                       onClick: handleClick,
                       index: index,
                       activeIndex: activeIndex
                    });
                }
            })}
        </div>
    )
}

export default TabNavigation;