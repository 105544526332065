import { createUseStyles } from "react-jss";
import { breakpoints } from "../../utils/breakpoints";

const useStyles = createUseStyles({

    card:{
        backgroundColor: 'transparent',
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        margin: '0 0 -2px 0',
        border: '2px solid',
        borderColor: '#ebeeee',
        borderRadius: 0
    },

    expanded:{
        '&:before':{
            color: 'var(--ltn_secondary-color) !important',
            content: '"\\e904" !important',
        }
    },

    cardTitle:{
        padding: '20px 60px 20px 5px',
        fontWeight: 600,
        backgroundColor: 'transparent',
        cursor: 'pointer',
        position: 'relative',
        transition: '.3s',
        fontSize: '18px',
        marginBottom: 0,

        '&:before':{
            backgroundColor: '#F2F6F7',
            color: 'black',
            fontSize: 16,
            height: '40px',
            width: '40px',
            textAlign: 'center',
            lineHeight: '40px',

            position: 'absolute',
            content: '"\\e903"',
            fontFamily: 'icomoon',
            right: '15px',
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },

    icon:{
        width: '50px',
        height: '50px',
        lineHeight: '55px',
        backgroundColor: 'var(--ltn_secondary-color)',
        color: 'white',
        display: 'inline-block',
        textAlign: 'center',
        marginRight: '10px',
        fontSize: '26px',
    },

    contentWrapper:{
        height: 0,
        overflow: 'hidden',
        transition: 'height ease 0.35s',
    },

    cardBody:{
        padding: '15px 40px 25px',

        '& p':{
            color: 'var(--ltn_paragraph-color)',
            marginBottom: '1.5em',
        }
    }
    
})

export default useStyles;