import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { toDateString } from "../../utils/generalUtils";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import styles from "./bookingTable.module.scss";
import { Inspection } from "../../types/Inspection";
import { inspectionBool, useBookings } from "../../swr/inspection";
import LoadingContext from "../../contexts/LoadingContext";
import ToastContext from "../../contexts/ToastContext";
import Modal from "react-modal";
import CommentModal from "./CommentModal";
import SplashScreenIncentives from "../SplashScreen/SplashScreenIncentive";
import SplashScreenComingSoon from "../SplashScreen/SplashScreenComingSoon";
import { InspectionB } from "../../types/Billings";
import { useHistory } from "react-router";
import { Button } from "@mui/material";
import Overlay from "./CommentOverlay";
// import SplashScreenHome from "../SplashScreen/SplashScreenHome";

export interface BookingsTableProps {
  inspections?: Inspection[];
  title?: string;
  outgoingBookings?: InspectionB[];
}

const BookingsTable: React.FC<BookingsTableProps> = ({
  inspections,
  title,
  outgoingBookings,
}) => {
  
  const { setLoading } = React.useContext(LoadingContext);
  const { openError, openSuccess } = React.useContext(ToastContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [inspectionItem, setInspectionItem] = useState<Inspection>({
    id: 0,
    propertiesId: 0,
    date: new Date(),
    comments: "",
  });

  const [status, setStatus] = useState<string | undefined>("");
  const history = useHistory();

  // State
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const token = localStorage.getItem("token") as string;
  // Handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [isOutgoing, setIsOutgoing] = useState(false);
  const [isIncoming, setIsIncoming] = useState(true);

  const setCommentProps = (item: Inspection, status: string | undefined) => {
    setInspectionItem(item);
    openModal();
    setStatus(status);
  };

  const onClickOfRow = (item: Inspection, status: string | undefined) => {
    openModal();
    setCommentProps(item, status);
  };

  useBookings(token)

  const sendInspectionRes = async (
    id: number | undefined,
    email: string | undefined,
    accept: boolean,
    propertyId: string | undefined,
    landlordsComment?: string
  ) => {
    setLoading(true);
    const response = await inspectionBool(
      { id, email, accept, propertyId, landlordsComment },
      token
    );
    if (response && response.succeeded === true) {
      setLoading(false);
      openSuccess(response.message && response.message);
      return "success";
    } else {
      setLoading(false);
      openError("Error Sending Message");
      return "failure";
    }
  };

  const filler: Inspection[] = [
    {
      id: 0,
      propertiesId: 0,
      email: "Joseph.oluwole@gmail.com",
      date: new Date(),
      comments:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
  ];

  const returnInspectionStatus = (val: number | undefined) => {
    if (val === 1) {
      return "Accepted";
    }
    if (val === 2) {
      return "Rejected";
    }
    if (val === 3) {
      return "Pending";
    }
  };

  // Columns
  interface Column {
    label: string;
    align?: "right" | "left" | "inherit" | "center" | "justify";
    colSpan?: number;
  }
  const columns: Column[] = [
    { label: "Appointment" },
    { label: "Email" },
    { label: "Comment" },
    { label: "Status" },
  ];
  const columnsI: Column[] = [
    { label: "Appointment" },
    { label: "Property" },
    { label: "Status" },
  ];

  const setOutgoing = () => {
    setIsOutgoing(true);
    setIsIncoming(false);
  };
  const setIncoming = () => {
    setIsOutgoing(false);
    setIsIncoming(true);
  };

  const modalWidth = window.innerWidth > 768 ? "30%" : "70%";

  const [comments, setComments] = useState('')

  const [confirmReject, setConfirmReject] = useState(false)

  const closeConfirmReject = () => {
    setConfirmReject(false)
  }

  const openConfirmReject = () => {
    setConfirmReject(true)
  }

  const handleSubmit = async (action: boolean) => {
    closeModal();
    closeConfirmReject()
    const response = await sendInspectionRes(
      inspectionItem.id,
      inspectionItem.email,
      action,
      inspectionItem.propertiesId.toString(),
      comments
    );
    if (response === "success") {
      history.push("/account#liton_tab_1_7");
    }
  };

  return (
    <>
      {modalIsOpen ? (
        <CommentModal
          item={inspectionItem}
          closeModal={closeModal}
          sendInspectionRes={sendInspectionRes}
          status={status}
          openConfirmReject={openConfirmReject}
          comments={comments}
          setComments={setComments}
        />
      ) : (
        <>
          <div className={styles.title}>
            <h3>Bookings</h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              type="button"
              variant="contained"
              sx={{
                textAlign: "center",
                backgroundColor: isIncoming
                  ? "var(--ltn__secondary-color)"
                  : "var(--ltn__paragraph-color)",
                "&:hover": {
                  backgroundColor: isIncoming
                    ? "var(--ltn__paragraph-color)"
                    : "var(--ltn__secondary-color)",
                },
                // paddingTop: "1.8em",
                height: "3em",
                display: "flex",
                alignItems: "center",
                marginTop: "0.5em",
                justifyContent: "center",
                marginBottom: "0.5em",
                //   alignSelf: "flex-start",
              }}
              onClick={() => setIncoming()}
            >
              InComing
            </Button>

            <Button
              type="button"
              variant="contained"
              sx={{
                textAlign: "center",
                backgroundColor: isOutgoing
                  ? "var(--ltn__secondary-color)"
                  : "var(--ltn__paragraph-color)",
                "&:hover": {
                  backgroundColor: isOutgoing
                    ? "var(--ltn__paragraph-color)"
                    : "var(--ltn__secondary-color)",
                },
                // paddingTop: "1.8em",
                height: "3em",
                display: "flex",
                alignItems: "center",
                marginTop: "0.5em",
                justifyContent: "center",
                marginBottom: "0.5em",
                //   alignSelf: "flex-start",
              }}
              onClick={() => setOutgoing()}
            >
              OutGoing
            </Button>
          </div>
          {isIncoming && (
            <>
              {inspections && inspections.length > 0 ? (
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    sx={{ maxHeight: 440 }}
                    className={styles.tablehead}
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {columns.map((c, index) => (
                            <TableCell
                              colSpan={c.colSpan}
                              key={`cell${index}`}
                              sx={{
                                backgroundColor: "var(--section-bg-2)",
                                color: "white",
                                fontSize: "16px",
                              }}
                              align={c.align}
                            >
                              {c.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inspections
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .filter((item) => item.acceptOrRejectInspection !== 2)
                          .map((item, index) => (
                            <TableRow
                              hover
                              key={index}
                              title="open request"
                              role="button"
                              onClick={() =>
                                onClickOfRow(
                                  item,
                                  returnInspectionStatus(
                                    item.acceptOrRejectInspection
                                  )
                                )
                              }
                            >
                              <TableCell>
                                {toDateString(new Date(item.date))}
                              </TableCell>
                              <TableCell>{item.email}</TableCell>
                              <TableCell>
                                <div className={styles.lineClamp}>
                                  {item.comments}
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className={styles.lineClamp}>
                                  {returnInspectionStatus(
                                    item.acceptOrRejectInspection
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    sx={{
                      "& p": {
                        margin: "0px",
                      },
                    }}
                    component="div"
                    rowsPerPageOptions={[10, 20, 30]}
                    count={inspections?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              ) : (
                <div className={styles.error}>
                  <span
                    style={{
                      fontWeight: "600",
                      alignSelf: "center",
                    }}
                  >
                    You have not received any inspection request
                  </span>
                </div>
              )}
            </>
          )}

          {isOutgoing && (
            <>
              {outgoingBookings && outgoingBookings?.length > 0 ? (
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    sx={{ maxHeight: 440 }}
                    className={styles.tablehead}
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {columnsI.map((c, index) => (
                            <TableCell
                              colSpan={c.colSpan}
                              key={`cell${index}`}
                              sx={{
                                backgroundColor: "var(--section-bg-2)",
                                color: "white",
                                fontSize: "16px",
                              }}
                              align={c.align}
                            >
                              {c.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {outgoingBookings
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, index) => (
                            <TableRow
                              hover
                              key={index}
                              title="see property"
                              role="button"
                              onClick={() =>
                                history.push(`/properties/${item.propertiesId}`)
                              }
                            >
                              <TableCell>
                                {toDateString(new Date(item.date))}
                              </TableCell>
                              <TableCell>{item.propertyName}</TableCell>
                              {/* <TableCell> */}
                              {/* <div className={styles.lineClamp}>{item.acceptOrRejectInspection}</div> */}
                              {/* </TableCell> */}
                              <TableCell>
                                <div className={styles.lineClamp}>
                                  {item.acceptOrRejectInspection}
                                  {/* {returnInspectionStatus(
                              Number(item.acceptOrRejectInspection)
                            )} */}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    sx={{
                      "& p": {
                        margin: "0px",
                      },
                    }}
                    component="div"
                    rowsPerPageOptions={[10, 20, 30]}
                    count={inspections?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              ) : (
                <div className={styles.error}>
                  <span
                    style={{
                      fontWeight: "600",
                      alignSelf: "center",
                    }}
                  >
                    You have not made any bookings
                  </span>
                </div>
              )}
            </>
          )}
        </>
      )}
      {/* <Overlay> */}
      <Modal
        isOpen={confirmReject}
        onRequestClose={closeConfirmReject}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            zIndex: 9999,
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            width: modalWidth,
            height: "50%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Comments Modal"
      >
        <div className={styles.confirmRejectDiv}>
          <p>Are you sure you want to reject this request ?</p>

          <div className={styles.confirmRejectDivButtons}>
            <div className={styles.btn} onClick={() => handleSubmit(false)}>Yes</div>
            <div className={styles.btn1} onClick={() => closeConfirmReject()}>No</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BookingsTable;
