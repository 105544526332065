import { useContext, useEffect } from "react";
import styles from "./rent-form.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import PersonalDetails from "./PersonalDetails";
import {
  setRentDropValue,
  setRentStep,
} from "../../../../state/reducers/insuranceSlice";
import GetAQuote from "./GetAQuote";
import LandlordDetails from "./LandlordDetails";
import TenantDetailsComp from "./TenantDetails";
import TermsAndConditions from "./TermsAndConditions";
import GetQuoteSuccess from "./GetQuoteSuccess";
import { StateContext } from "../../../../contexts/StateContext";
import { useGetUser } from "../../../../swr/user";
import { UserTypeBackEnd } from "../../../../types/UserType";
import { useHistory } from "react-router";
import ToastContext from "../../../../contexts/ToastContext";



const RentForm = () => {
  const { rentStep, rentDropDownValue } = useSelector(
    (state: RootState) => state.insurance
  );
  const token = localStorage.getItem("token") as string | undefined;
  const email = localStorage.getItem("email") as string | undefined;
  const { user } = useGetUser(email, token);
  const { walletDetails } = useContext(StateContext);
  const { openError } = useContext(ToastContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const closeAllDropDowns = () => {
    dispatch(setRentDropValue(""));
  };

  const returnHeading = (step: number) => {
    switch (step) {
      case 1:
        return "Personal Details";
      case 2:
        return "Get A Quote";
      case 5:
        return "Terms and Conditions";
      default:
        return "Know Your Customer (KYC)";
    }
  };

  useEffect(() => {
    if (user?.userType !== UserTypeBackEnd.Landlord) {
      openError(
        "This rent default insurance package is only available for landlords"
      );
      history.push("/insurance");
    }
  }, [user?.userType]);

  const returnRentStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <PersonalDetails
            rentDropDownValue={rentDropDownValue}
            closeAllDropDowns={closeAllDropDowns}
            setRentStep={setRentStep}
            user={user}
          />
        );
      case 2:
        return (
          <GetAQuote
            rentDropDownValue={rentDropDownValue}
            closeAllDropDowns={closeAllDropDowns}
            setRentStep={setRentStep}
          />
        );
      case 3:
        return (
          <LandlordDetails
            rentDropDownValue={rentDropDownValue}
            closeAllDropDowns={closeAllDropDowns}
            setRentStep={setRentStep}
          />
        );
      case 4:
        return (
          <TenantDetailsComp
            rentDropDownValue={rentDropDownValue}
            closeAllDropDowns={closeAllDropDowns}
            setRentStep={setRentStep}
          />
        );
      case 5:
        return <TermsAndConditions setRentStep={setRentStep} />;
      default:
        return (
          <GetQuoteSuccess
            rentDropDownValue={rentDropDownValue}
            closeAllDropDowns={closeAllDropDowns}
            walletBalance={
              walletDetails?.available_balance
                ? walletDetails.available_balance.toString()
                : undefined
            }
          />
        );
    }
  };

  return (
    <div className={styles["form-container-in"]}>
      <span className={styles["heading-rent-I"]}>
        {returnHeading(rentStep)}
      </span>
      {rentStep < 5 && (
        <span className={styles["heading-rent-II"]}>
          Compulsory fields are asterisked (*)
        </span>
      )}

      {returnRentStep(rentStep)}
    </div>
  );
};

export default RentForm;
