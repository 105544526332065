import Avatar from '@mui/material/Avatar';
import React from 'react';
import styles from './utilBill.module.scss';

export interface UtilityBillCardProps{
    iconClass?: string;
    label: string;
    id: any;
    selected?: boolean;
    onSelect: (id: any)=> void;
    searchparam: string
}

const UtilityBillCard: React.FC<UtilityBillCardProps> = ({ 
    iconClass = 'fa fa-trash', label, id, selected = false,
    onSelect
})=>{

    // Handlers
    const handleOnSelect = ()=>{
        onSelect && onSelect(id);
    }

    return(
        <div className={`${styles.utilCard} ${selected && styles.selected}`} onClick={()=>onSelect(id)}>
            <Avatar
                sx={{ 
                    width: 56, 
                    height: 56, 
                    backgroundColor: 'var(--ltn__secondary-color)' 
                }}
            >
                <i className={iconClass}></i>
            </Avatar>
            <p>{label}</p>
        </div>
    )
}

export default UtilityBillCard;