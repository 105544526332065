import React from 'react';
import './Button.scss';

interface IButtonProps {
    label: string;
    styleProp?: string;
    disabled?: boolean;
    classes?: string;
    onClick?: (e: React.SyntheticEvent) => void;
    children?: React.ReactNode;
}

const Button: React.FC<IButtonProps> = ({ label, styleProp, disabled, classes, onClick, children }) => {
    return (
        <>
            {styleProp ? (
                <button className={styleProp ? `${styleProp} btn` : `custom-btn btn btn-primary`} type="submit">
                    {label}
                </button>
            ) : (
                <div className="btn-wrapper">
                    <button className={classes} onClick={onClick} disabled={disabled}>
                        {children}
                        {label}
                    </button>
                </div>
            )}
        </>
    );
};

export default Button;
