import { AmenityWithCount } from "../../../types/Property";

const defaultAmenities: AmenityWithCount[] = [
    { id: 0, amenity: 'Air Conditioning', number: 1 },
    { id: 1, amenity: 'Barbeque', number: 1 },
    { id: 2, amenity: 'Refrigerator', number: 1 },
    { id: 3, amenity: 'Gym', number: 1 },
    { id: 4, amenity: 'Recreation', number: 1 },
    { id: 5, amenity: 'Window Coverings', number: 1 },
    { id: 6, amenity: 'Microwave', number: 1 },
    { id: 7, amenity: 'Air Conditioning', number: 1 },
    { id: 8, amenity: 'Washer', number: 1 },
    { id: 9, amenity: 'Swimming Pool', number: 1 },
    { id: 10, amenity: 'Basketball Court', number: 1 },
    { id: 11, amenity: '24x7 Security', number: 1 },
    { id: 12, amenity: 'WiFi', number: 1 },
    { id: 13, amenity: 'Fireplace', number: 1 },
    { id: 14, amenity: 'Indoor Game', number: 1 },
]

export default defaultAmenities;