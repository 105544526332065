import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "../../components/FormElements/Dropdown";
import TextInput from "../../components/FormElements/TextInput";
import LoadingContext from "../../contexts/LoadingContext";
import ToastContext from "../../contexts/ToastContext";
import { setTempUser } from "../../state/reducers/authSlice";
import { registerUser } from "../../swr/user";
import { UserRegistrationRequest } from "../../types/User";
import { UserType } from "../../types/UserType";
import { isEmpty, isPasswordValid, isValidEmail } from "../../utils/formUtils";
import { errorMessage } from "../../utils/propertyUtils";
import styles from "./register.module.scss";
import signupImage from "../../assets/signupImage.png";
import HomeBuddyLogo from "../../assets/HomeBuddy_RequestedColour_PrimaryLockup.svg";

const RegisterSection: React.FC<any> = () => {
  // Hooks
  const history = useHistory();
  const dispatch = useDispatch();

  // Contexts
  const { setLoading } = React.useContext(LoadingContext);
  const { openError } = React.useContext(ToastContext);

  // Refs
  const formRef = React.createRef<HTMLFormElement>();

  const [image, setImage] = useState<Blob | string>("");

  interface ErrorFormState {
    firstName?: boolean;
    lastName?: boolean;
    email?: boolean;
    userName?: boolean;
    role?: boolean;
    phoneNumber?: boolean;
    password?: boolean;
    confirmPassword?: boolean;
  }
  let initialState: ErrorFormState = {
    firstName: false,
    lastName: false,
    email: false,
    userName: false,
    role: false,
    phoneNumber: false,
    password: false,
    confirmPassword: false,
  };

  // Variables
  let intMessage = "An unknown error occured while registering your account";

  // State
  const [errorState, setErrorState] =
    React.useState<ErrorFormState>(initialState);
  const [message, setMessage] = React.useState<string>(intMessage);
  const [open, setOpen] = React.useState<boolean>(false);
  const [consentChecked, setConsentChecked] = React.useState<boolean>(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] =
    React.useState<boolean>(false);
  const [regError, setRegError] = useState(false);

  const roleOptions = Object.values(UserType).filter(
    (item) => typeof item === "string"
  );

  // Handlers
  const unhighlight = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorState((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
    if (event.target.name === "Password") {
      checkPasswordIfCorrected();
    }
    if (event.target.name === "ConfirmPassword") {
      checkConfirmPassword();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConsentClick = () => {
    setConsentChecked(!consentChecked);
  };
  const handlePrivacyPolicyClick = () => {
    setPrivacyPolicyChecked(!privacyPolicyChecked);
  };

  type RegistrationInput = {
    placeholder: string;
    name: string;
    minlength?: number;
    isDropdown?: boolean;
    type?: React.HTMLInputTypeAttribute;
    options?: any[];
    id: string;
    error?: boolean;
    errorMessage?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    showPassword?: boolean;
    togglePasswordVisibility?: () => void;
    checkPasswordIfCorrected?: () => void;
    checkConfirmPassword?: () => void;
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // TODO
  /** Checkout api for proper form handling */
  const formInputs: RegistrationInput[] = [
    {
      placeholder: "First Name*",
      name: "FirstName",
      id: "reg_firstName",
      error: errorState.firstName,
    },
    {
      placeholder: "Last Name*",
      name: "LastName",
      id: "reg_lastName",
      error: errorState.lastName,
    },
    {
      placeholder: `Email*`,
      name: "Email",
      type: "email",
      id: "reg_email",
      error: errorState.email,
      errorMessage: "Enter a valid email address",
    },
    {
      placeholder: "UserName*",
      name: "UserName",
      minlength: 6,
      id: "reg_userName",
      error: errorState.userName,
      errorMessage: "UserName should be a minimum of 6 letters",
    },
    {
      placeholder: "Phone Number*",
      name: "PhoneNumber",
      id: "reg_phoneNumber",
      error: errorState.phoneNumber,
      minlength: 11,
    },
    {
      placeholder: `Password*`,
      name: "Password",
      type: "password",
      id: "reg_password",
      error: errorState.password,
      errorMessage:
        "Password should contain at least one Uppercase letter, one number, a special character, and must be a minimum length of 6",
    },

    {
      placeholder: "Select role*",
      name: "UserType",
      isDropdown: true,
      options: roleOptions,
      id: "reg_role",
    },
    {
      placeholder: `Confirm Password*`,
      name: "ConfirmPassword",
      type: "password",
      id: "reg_confirmPassword",
      error: errorState.confirmPassword,
      errorMessage:
        "Your confirmation password does not match with your password",
    },
    {
      placeholder: "Referral Code (Optional)",
      name: "ReferralCode",
      type: "password",
      id: "referral_code",
      // error: errorState.confirmPassword,
      // errorMessage:
      //   "Your confirmation password does not match with your password",
    },
  ];

  const checkPasswordIfCorrected = () => {
    const fdata = new FormData(formRef.current!);
    if (isPasswordValid(fdata.get("Password")?.toString() || "") === true) {
      setErrorState((prevState) => ({ ...prevState, password: false }));
      setRegError(false);
    } else {
      setErrorState((prevState) => ({ ...prevState, password: true }));
      setRegError(true);
    }
  };

  const checkConfirmPassword = () => {
    const fdata = new FormData(formRef.current!);
    if (
      fdata.get("Password")?.toString() ===
      fdata.get("ConfirmPassword")?.toString()
    ) {
      setErrorState((prevState) => ({ ...prevState, confirmPassword: false }));
      setRegError(false);
    } else {
      setErrorState((prevState) => ({ ...prevState, confirmPassword: true }));
      setRegError(true);
    }
  };

  // Validator
  const validateForm = (fdata: FormData): "passed" | "failed" => {
    let password = fdata.get("Password")?.toString();
    let confirmPassword = fdata.get("ConfirmPassword")?.toString();
    let username = fdata.get("UserName")?.toString();
    let email = fdata.get("Email")?.toString();

    let result: "passed" | "failed" = "passed";

    // Check if any field is empty
    for (const pairs of fdata.entries()) {
      if (isEmpty(pairs[1].toString())) {
        setErrorState((prevState) => ({ ...prevState, [pairs[0]]: true }));
        result = "failed";
      }
    }

    // Check Email
    if (email && isValidEmail(email) === false) {
      setErrorState((prevState) => ({ ...prevState, email: true }));
      result = "failed";
    }

    // Check username
    if (username && username?.length < 6) {
      setErrorState((prevState) => ({ ...prevState, userName: true }));
      result = "failed";
    }

    // Check password
    if (isPasswordValid(password || "") === false) {
      setErrorState((prevState) => ({ ...prevState, password: true }));
      result = "failed";
      result === "failed" ? setRegError(true) : setRegError(false);
    }

    // Check confirm password
    if (password !== confirmPassword) {
      setErrorState((prevState) => ({ ...prevState, confirmPassword: true }));
      result = "failed";
      result === "failed" ? setRegError(true) : setRegError(false);
    }

    return result;
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files![0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String: any = reader.result;
        setImage(base64String);
      };
    }
  };

  // Form handler
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (formRef.current) {
      const fdata = new FormData(formRef.current);

      let userType: any = fdata.get("UserType")?.toString();

      //   if (validateForm(fdata) === "passed") {

      // let request: UserRegistrationRequest = {

      const FirstName = fdata.get("FirstName")?.toString() || "";
      const LastName = fdata.get("LastName")?.toString() || "";
      const Email = fdata.get("Email")?.toString() || "";
      const PhoneNumber = fdata.get("PhoneNumber")?.toString() || "";
      const UserName = fdata.get("UserName")?.toString() || "";
      const Password = fdata.get("Password")?.toString() || "";
      const ConfirmPassword = fdata.get("ConfirmPassword")?.toString() || "";

      const UserType = userType;
      const Image = "";

      if (!FirstName) {
        openError("Please Enter First Name");
        return;
      }
      if (!LastName) {
        openError("Please Enter Last Name");
        return;
      }
      if (!Email) {
        openError("Please Enter Email");
        return;
      }
      if (!PhoneNumber) {
        openError("Please Enter Phone Number");
        return;
      }
      if (!UserName) {
        openError("Please Enter User Name");
        return;
      }
      if (!Password) {
        openError("Please Enter Password");
        return;
      }
      if (!ConfirmPassword) {
        openError("Please Enter Confirm Password");
        return;
      }
      if (!UserType) {
        openError("Please Enter User Type");
        return;
      }
      if (UserName.length < 6) {
        openError("User name must be at least six characters");
        return;
      }
      if (Password !== ConfirmPassword) {
        openError("Passwords do not match");
        return;
      }

      const formData = new FormData();

      if (!image) {
        openError("Your picture is mandatory");
        setLoading(false);
        return;
      }

      formData.append("FirstName", fdata.get("FirstName")?.toString() || "");
      formData.append("LastName", fdata.get("LastName")?.toString() || "");
      formData.append("Email", fdata.get("Email")?.toString() || "");
      formData.append(
        "PhoneNumber",
        fdata.get("PhoneNumber")?.toString() || ""
      );
      formData.append("Password", fdata.get("Password")?.toString() || "");
      formData.append("UserName", fdata.get("UserName")?.toString() || "");
      formData.append(
        "ConfirmPassword",
        fdata.get("ConfirmPassword")?.toString() || ""
      );
      formData.append("UserType", fdata.get("UserType")?.toString() || "");
      formData.append(
        "ReferralCode",
        fdata.get("ReferralCode")?.toString() || ""
      );
      // formData.append("Image", fdata.get('image')?.toString() || '');

      if (Password) {
        const lowercaseRegex = /^(?=.*[a-z])/;
        const uppercaseRegex = /^(?=.*[A-Z])/;
        const numericRegex = /^(?=.*\d)/;
        const specialCharRegex = /^(?=.*[-+_!@#$%^&*., ?])/;
        if (Password?.length < 6) {
          openError("Password must be at least six characters");
          return;
        }
        if (!lowercaseRegex.test(Password)) {
          openError("Password must contain at least one lower case letter");
          return;
        }
        if (!uppercaseRegex.test(Password)) {
          openError("Password must contain at least one upper case letter");
          return;
        }
        if (!numericRegex.test(Password)) {
          openError("Password must contain at least one number");
          return;
        }
        if (!specialCharRegex.test(Password)) {
          openError(
            "Password must contain at least one special character e.g -, +, _, !, @"
          );
          return;
        }
      } else {
        openError("Please enter password");
        return;
      }

      if (typeof image === "string") {
        const imageBlob = await fetch(image).then((response) =>
          response.blob()
        );

        formData.append("Image", imageBlob);
      }

      setLoading(true);
      const { data, error } = await registerUser(formData);
      if (data?.succeeded) {
        dispatch(
          setTempUser({
            succeeded: data.succeeded,
            message: data.message || "",
            errors: data.errors,
            data: data.data || "",
            meta: data.meta,
          })
        );
        setLoading(false);
        history.push("/signup/success");
      } else if (error) {
        if (error.response) {
          setLoading(false);
          setMessage(error.response.data.Message);
          setOpen(true);
        } else {
          setLoading(false);
          setMessage("An unknow error occurred while signing you up");
          setOpen(true);
        }
        // }
      }
    }
  };

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      >
        <Alert severity="error" onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
      <div className={styles.formContainer}>
        <div className={styles.inner}>
          {/* <div className="section-title-area text-center"> */}

          {/* </div> */}
          <div className={styles.logoContainer}>
            <div
              className={styles.logoImage}
              role="button"
              onClick={() => history.push("/")}
            >
              <img src={HomeBuddyLogo} alt="HomeBuddyLogo" />
            </div>
          </div>
          <div className={styles.titleContainer}>
            <span className={styles.title}>Sign up</span>
          </div>
          <div className="account-login-inner">
            <form
              className="ltn__form-box contact-form-box"
              ref={formRef}
              onSubmit={handleSubmit}
              noValidate
            >
              <div className="row">
                {formInputs.map((item, index) => {
                  if (item.isDropdown) {
                    return (
                      <div key={index}>
                        <Dropdown
                          placeholder={item.placeholder}
                          options={item.options}
                          name={item.name}
                          key={`reg${index}`}
                          variant={2}
                          selectProps={{ required: true }}
                        />
                      </div>
                    );
                  }

                  return (
                    <div key={index}>
                      <TextInput
                        placeholder={item.placeholder}
                        type={item.type}
                        name={item.name}
                        required={item.error}
                        minLength={item.minlength}
                        id={item.id}
                        error={item.error}
                        key={`reg${index}`}
                        className={styles.textInput}
                        hideDefaultAdornment
                        onChange={unhighlight}
                        errorMessage={item.errorMessage}
                        showPassword={showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        regError={regError}
                        checkConfirmPassword={checkConfirmPassword}
                        checkPasswordIfCorrected={checkPasswordIfCorrected}
                      />
                    </div>
                  );
                })}
                <label>Profile Picture</label>
                <input
                  style={{
                    marginBottom: "5em",
                    width: isMobile ? "70%" : "35%",
                  }}
                  type="file"
                  placeholder="Add a clear picture"
                  onChange={handleImageChange}
                />
              </div>
              <label className="checkbox-inline">
                <input
                  type="checkbox"
                  checked={consentChecked}
                  onClick={handleConsentClick}
                  autoComplete="off"
                />
                <span className={styles.consents}>
                  I consent to Homebuddy processing my personal data in order to
                  provide value added services.
                </span>
              </label>
              <label className="checkbox-inline">
                <input
                  type="checkbox"
                  checked={privacyPolicyChecked}
                  onClick={handlePrivacyPolicyClick}
                  autoComplete="off"
                />
                <span className={styles.consents}>
                  <span>
                    By clicking "create account", I consent to the Homebuddy's
                  </span>{" "}
                  <span className={styles.greentxt}>
                    terms and conditions, privacy policy
                  </span>{" "}
                  and{" "}
                  <span className={styles.greentxt}>
                    credit worthiness check
                  </span>{" "}
                  .
                </span>
              </label>

              <div className="btn-wrapper d-flex justify-content-center">
                <button
                  className="theme-btn-1 btn reverse-color btn-block"
                  type="submit"
                  disabled={!(consentChecked && privacyPolicyChecked)}
                >
                  CREATE ACCOUNT
                </button>
              </div>
            </form>

            <div className="by-agree text-center">
              <p>By creating an account, you agree to our:</p>
              <p>
                <span>
                  <a
                    href="https://drive.google.com/file/d/1tFHzJEkGdyVU-V2CcM3t4BssFAwGCMBj/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TERMS OF CONDITIONS
                  </a>
                  &nbsp; &nbsp; | &nbsp; &nbsp;
                  <a
                    href="https://drive.google.com/file/d/1h-NLngC_KS64Eix0tYaAUTFyyyT2OZkZ/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PRIVACY POLICY
                  </a>
                </span>
              </p>
              <div className="go-to-btn mt-50">
                <p>
                  ALREADY HAVE AN ACCOUNT ? <Link to="/signin">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sideImgContainer}>
          <img src={signupImage} alt="signupImage" className={styles.sideImg} />
        </div>
      </div>
    </>
  );
};

export default RegisterSection;
