import React, { useContext, useState } from "react";
import styles from "./rent-form.module.scss";
import {
  convertFilesToBlobs,
  formatAmount,
  returnMsgFromAxiosError,
} from "../../../../utils/generalUtils";
import ModalLayout from "../../../../components/ModalLayout";
import {
  BlobFormatImprovised,
  LandlordDetails,
  RentDropDown,
} from "../../../../types/Insurance";
import SaveQuoteSuccess from "../../../../components/Insurance/RentDefault/SaveQuoteSuccess";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setRentDefaultInsuranceId,
  setRentDropValue,
} from "../../../../state/reducers/insuranceSlice";
import PaymentModal from "../../../../components/Insurance/RentDefault/PaymentModal";
import PaymentSuccessful from "../../../../components/Insurance/RentDefault/PaymentSuccessful";
import {
  rentDefaultFinalApi,
  rentDefaultInsuranceDocUpload,
} from "../../../../swr/insurance";
import { RootState } from "../../../../state/store";
import LoadingContext from "../../../../contexts/LoadingContext";
import ToastContext from "../../../../contexts/ToastContext";
import { propertyTypeArr } from "./GetAQuote";
import MakePaymentSuccess from "../../../../components/Insurance/RentDefault/MakePaymentSuccess";

export const landLordDetailsImgKeys = [
  "PassportPhoto",
  "IdCard",
  "TitleDocument",
  "TenantDocument",
  "BuildingFrontPhoto",
  "BuildingBackPhoto",
  "BuildingRightPhoto",
  "BuildingLeftPhoto",
];

const GetQuoteSuccess = ({
  rentDropDownValue,
  closeAllDropDowns,
  walletBalance,
}: {
  rentDropDownValue: RentDropDown;
  closeAllDropDowns: () => void;
  walletBalance?: string;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token") as string;
  const {
    landLordDetails,
    personalDetails,
    tenantDetails,
    RentDefaultInsuranceQuote,
    getAQuoteReq,
    rentQuoteInfoDetails,
    RentDefaultInsuranceId,
  } = useSelector((state: RootState) => state.insurance);

  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);

  const [pin, setPin] = useState("");
  const pinOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value);
  };

  const viewSavedQuotes = () => {
    history.push("/insurance");
    closeAllDropDowns();
  };
  const goHome = () => {
    history.push("/");
    closeAllDropDowns();
  };

  const openSavedQuoteSuccess = () => {
    dispatch(setRentDropValue("SaveQuoteSuccess"));
  };

  const openPaymentSuccess = () => {
    dispatch(setRentDropValue("InsurancePaymentSuccess"));
  };

  const openPaymentModal = () => {
    dispatch(setRentDropValue("PaymentModal"));
  };

  const getFileValues = (llDetails: LandlordDetails, imgkeys: string[]) => {
    let files: File[] = [];
    for (const key in llDetails) {
      if (imgkeys.includes(key)) {
        // @ts-ignore
        files.push(llDetails[key as keyof LandlordDetails]);
      }
    }
    return files;
  };

  const getlLDetailsFiles = (
    llDetails: LandlordDetails,
    imgkeys: string[]
  ): File[] => {
    return getFileValues(llDetails, imgkeys);
  };

  // const changelLDetailsFilesToBlobs = async (
  //   llDetails: LandlordDetails,
  //   imgkeys: string[]
  // ) => {
  //   const files = getFileValues(llDetails, imgkeys);
  //   const blobs = await convertFilesToBlobs(files);
  //   return blobs;
  // };

  // const insertBlobKeys = (
  //   imgKeys: string[],
  //   blobs: Blob[]
  // ): { key: string; blob: Blob }[] => {
  //   const keyBlobObjArr = imgKeys.map((imgKey, index) => ({
  //     key: imgKey,
  //     blob: blobs[index],
  //   }));
  //   return keyBlobObjArr;
  // };
  

  const ProceedWithPayment = async () => {
    if (!pin || pin.length !== 4) {
      openError("Please enter your transaction pin");
      return;
    }
    closeAllDropDowns();
    makeFinalRentCall();
    // dispatch(setRentDropValue("PaymentSuccess"));
  };

  const rentDocUpload = async (
    RentDefaultInsuranceId: string,
    ImageFile: File[],
    // ImageFile: { key: string; blob: Blob }[],
    token: string
  ) => {
    const { data, error } = await rentDefaultInsuranceDocUpload(
      RentDefaultInsuranceId,
      ImageFile,
      token
    );

    if (data) {
      setLoading(false);
      openPaymentSuccess();
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "An error occurred uploading docs")
      );
      return;
    }

    setLoading(false);
    openError("An error occurred uploading docs");
  };

  const makeFinalRentCall = async () => {
    const fileArray = getlLDetailsFiles(
      landLordDetails,
      landLordDetailsImgKeys
    );

    if (RentDefaultInsuranceId !== "") {
      rentDocUpload(
        RentDefaultInsuranceId,
        [...fileArray, tenantDetails.PassportPhoto!, tenantDetails.IdCard!],
        token
      );
      return;
    }

    setLoading(true);
    const { data, error } = await rentDefaultFinalApi(
      {
        landLordTitle: personalDetails.Title + 1,
        landLordEmail: personalDetails.Email,
        landLordPhoneNumber: personalDetails.PhoneNumber,
        landLordFirstName: personalDetails.FirstName,
        landLordLastName: personalDetails.LastName,
        landLordMiddleName: personalDetails.MiddleName,
        tenantTitle: tenantDetails.Title + 1,
        tenantEmail: tenantDetails.Email,
        tenantPhoneNumber: tenantDetails.PhoneNumber,
        tenantFirstName: tenantDetails.FirstName,
        tenantLastName: tenantDetails.LastName,
        tenantMiddleName: tenantDetails.MiddleName,
        landLordDateofBirth: landLordDetails.DateOfBirth,
        landLordAddress: getAQuoteReq.PropertyAddress,
        landLordOccupation: landLordDetails.Occupation,
        landLordGender: landLordDetails.Gender + 1,
        tenantDateofBirth: tenantDetails.DateOfBirth,
        tenantAddress: tenantDetails.HomeAddress,
        tenantOccupation: tenantDetails.Occupation,
        tenantGender: tenantDetails.Gender + 1,
        duration: getAQuoteReq.DesiredCoveragePeriod,
        payAmount: RentDefaultInsuranceQuote,
        propertyTitle: propertyTypeArr[getAQuoteReq.PropertyType],
        propertyAddress: getAQuoteReq.PropertyAddress,
        units: getAQuoteReq.UnitsNumber ? Number(getAQuoteReq.UnitsNumber) : 0,
        annualRentalIncome: getAQuoteReq.AnnualRent
          ? Number(getAQuoteReq.AnnualRent.replace(/\D/g, ""))
          : 0,
        quote: RentDefaultInsuranceQuote,
        pin,
      },
      token
    );

    if (data) {
      dispatch(setRentDefaultInsuranceId(data.data));
      rentDocUpload(
        data.data?.trim(),
        [...fileArray, tenantDetails.PassportPhoto!, tenantDetails.IdCard!],
        token
      );
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "An error occurred saving quote")
      );
      return;
    }

    setLoading(false);
    openError("An error occurred saving quote");
  };

  const fundWallet = () => {
    history.push("/account#liton_tab_1_6");
    closeAllDropDowns();
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles["get-quote-cont"]}>
          <span className={styles["get-quote-header"]}>Congratulations!</span>

          <span className={styles["get-quote-text"]}>
            You have successfully gotten a quote for<br></br> the Rent Default
            Insurance policy.
          </span>

          <div className={styles["get-quote-card"]}>
            <span className={styles["get-quote-card-p"]}>
              Annual Payable Premium
            </span>
            <span className={styles["get-quote-card-span"]}>
              ₦
              {RentDefaultInsuranceQuote
                ? formatAmount(RentDefaultInsuranceQuote)
                : ""}
            </span>
          </div>

          <div className={styles["get-quote-buttons"]}>
            <div
              className={styles["get-quote-but-green"]}
              role="button"
              // onClick={() => makeFinalRentCall()}
              onClick={() => openPaymentModal()}
            >
              <span>Pay Now</span>
            </div>
            <div
              className={styles["get-quote-but-white"]}
              role="button"
              onClick={() => openSavedQuoteSuccess()}
            >
              <span>Save Quote</span>
            </div>
          </div>
        </div>
      </div>

      <ModalLayout modalOpen={rentDropDownValue === "SaveQuoteSuccess"}>
        <SaveQuoteSuccess
          close={viewSavedQuotes}
          viewSavedQuotes={viewSavedQuotes}
          goHome={goHome}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "InsurancePaymentSuccess"}>
        <MakePaymentSuccess
          close={viewSavedQuotes}
          viewSavedQuotes={viewSavedQuotes}
          goHome={goHome}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "PaymentModal"}>
        <PaymentModal
          Proceed={ProceedWithPayment}
          FundWallet={fundWallet}
          closeAllModals={closeAllDropDowns}
          walletBalance={walletBalance}
          amountToPay={
            RentDefaultInsuranceQuote ? RentDefaultInsuranceQuote : 0
          }
          pinOnChange={pinOnChange}
          pin={pin}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "PaymentSuccess"}>
        <PaymentSuccessful
          close={closeAllDropDowns}
          getNewPolicy={viewSavedQuotes}
          goHome={goHome}
        />
      </ModalLayout>
    </>
  );
};

export default GetQuoteSuccess;
