import React from 'react';
import Layout from '../components/Layout';
import NavHeader from '../components/NavHeader';
import ScrollToTop from '../components/ScrollToTop';
import PaymentSection from '../sections/Payment';

const PaymentPage: React.FC<any> = ()=>{

    return(
        <React.Fragment>
            <ScrollToTop/>
            <Layout>
                <NavHeader
                    pageTitle="Payment"
                    routeName="Payment"
                />
                <PaymentSection/>
            </Layout>
        </React.Fragment>
    )
}

export default PaymentPage;