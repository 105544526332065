import React, { SetStateAction, useState } from "react";
import { Button } from "@mui/material";
import { Customer } from "../../../types/Billings";

interface CustomerModalProps {
  closeModal: () => void;
  customer: Customer;
  minPayable: number | null;
}

const CustomerModal: React.FC<CustomerModalProps> = ({
  closeModal,
  customer,
  minPayable,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "space-between",
        zIndex: "1",
        width: "100%",
        height: "100%",
        alignSelf: 'center'
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "left",
          justifyContent: "flex-end",
          width: "95%",
          marginBottom: '3%',
          marginTop: '2%'
        }}
      >
        <div className="icon-cancel" onClick={closeModal}></div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "95%",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1%",
          height: '10%'
        }}
      >
        <span style={{ fontWeight: "bold" }}>Name</span>
        <span>{customer && `${customer.firstName} ${customer.lastName}`}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "95%",
          alignItems: "center",
          justifyContent: "space-between",
          height: 'auto',
          marginBottom: '1%'
        }}
      >
        <span style={{ fontWeight: "bold" }}>Address</span>
        <span>{customer && customer.address}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "95%",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1%",
          height: '10%'
        }}
      >
        <span style={{ fontWeight: "bold" }}>Phone Number</span>
        <span>{customer && customer.phoneNumber}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "95%",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1%",
          height: '10%'
        }}
      >
        <span style={{ fontWeight: "bold" }}>Arrears Balance</span>
        <span style={{width: '65%', textAlign: 'end'}}>{customer && customer.arrearsBalance}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "95%",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1%",
          height: '10%'
        }}
      >
        <span style={{ fontWeight: "bold" }}>Minimum Payable</span>
        <span>{minPayable && minPayable}</span>
      </div>
        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__secondary-color)",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
            // paddingTop: "1.8em",
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
          }}
          onClick={() => closeModal()}
        >
          Continue
        </Button>
      {/* </div> */}
    </div>
  );
};

export default CustomerModal;
