import { AxiosError } from "axios";
import { GeneralOperationResult } from "./GeneralResponse";
import { User } from "./User";
import { GetWalletDetailsResponse } from "./Wallet";

export interface ApplyInsuranceI {
  Title: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Email: string;
  PhoneNumber?: string;
}

export interface ApplyInsuranceII {
  PropertyType: number;
  PropertyAddress: string;
  UnitsNumber: string;
  AnnualRent: string;
  DesiredCoveragePeriod: number;
}

export type GeneralDropDown = {
  label: string;
  value: string;
};
export type FormType = "RentDefault" | "HouseHold" | "HomeOwners";

export type RentDropDown =
  | "PropertyType"
  | "CoveragePeriod"
  | "Title"
  | "Gender"
  | "MaritalStatus"
  | "Religion"
  | "Sector"
  | "SaveQuoteSuccess"
  | "PaymentModal"
  | "PaymentSuccess"
  | "PremiumPaymentFreq"
  | "DisplayPopHomeOwners"
  | "InsurancePaymentSuccess"
  | "DisplayPopHouseHold"
  | "";

export type LandlordDetails = {
  Gender: number;
  DateOfBirth?: Date;
  MaritalStatus: number;
  Religion: number;
  HomeAddress: string;
  City: string;
  State: string;
  Occupation: string;
  Sector: number;
  CoverStartDate?: Date;
  Bank: string;
  AccountNumber: string;
  BVN: string;
  PassportPhoto?: File;
  IdCard?: File;
  TitleDocument?: File;
  TenantDocument?: File;
  BuildingFrontPhoto?: File;
  BuildingBackPhoto?: File;
  BuildingRightPhoto?: File;
  BuildingLeftPhoto?: File;
  Title?: number;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  Email?: string;
  PhoneNumber?: string;
};
export type HouseHoldDetails = {
  Gender: number;
  DateOfBirth?: Date;
  MaritalStatus: number;
  Religion: number;
  HomeAddress: string;
  City: string;
  State: string;
  Occupation: string;
  Sector: number;
  CoverStartDate?: Date;
  Bank: string;
  AccountNumber: string;
  BVN: string;
  PremiumPaymentFreq: number;
  PassportPhoto?: File;
  IdCard?: File;
  ItemPhotos?: File;
};

export type HouseHoldGetQuoteReq = {
  PolicyDuration: string;
  ContactAddress?: string;
  Building?: GetQuoteCategoryPair;
  AirConditioner?: GetQuoteCategoryPair;
  Television?: GetQuoteCategoryPair;
  Refrigerator?: GetQuoteCategoryPair;
  Freezer?: GetQuoteCategoryPair;
  HomeTheatre?: GetQuoteCategoryPair;
  Table?: GetQuoteCategoryPair;
  GasCooker?: GetQuoteCategoryPair;
  Inverter?: GetQuoteCategoryPair;
  Bed?: GetQuoteCategoryPair;
  WaterDispenser?: GetQuoteCategoryPair;
  WashingMachine?: GetQuoteCategoryPair;
  LivingRoom?: GetQuoteCategoryPair;
  KitchenCabinets?: GetQuoteCategoryPair;
  Shoes?: GetQuoteCategoryPair;
  Bags?: GetQuoteCategoryPair;
  Clothing?: GetQuoteCategoryPair;
  StandingFan?: GetQuoteCategoryPair;
  WristWatch?: GetQuoteCategoryPair;
  Camera?: GetQuoteCategoryPair;
  Laptop?: GetQuoteCategoryPair;
  Phone?: GetQuoteCategoryPair;
  Jewelries?: GetQuoteCategoryPair;
  Other?: GetQuoteCategoryPair;
  Others?: GetQuoteCategoryPair;
};

export type HomeOwnersDetails = {
  Gender: number;
  DateOfBirth?: Date;
  MaritalStatus: number;
  Religion: number;
  HomeAddress: string;
  City: string;
  State: string;
  Occupation: string;
  Sector: number;
  CoverStartDate?: Date;
  PremiumPaymentFreq: number;
  Bank: string;
  AccountNumber: string;
  BVN: string;
  PassportPhoto?: File;
  ItemPhotos?: File[];
};

export type TenantDetailsType = {
  Gender: number;
  DateOfBirth?: Date;
  MaritalStatus: number;
  HomeAddress: string;
  City: string;
  State: string;
  Occupation: string;
  Sector: number;
  PassportPhoto?: File;
  IdCard?: File;
  Title: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Email: string;
  PhoneNumber: string;
};

export type HandleCPHomeOwnersChange = {
  name: keyof CreatePolicyRequest;
  value: string | Date;
};

export type DocumentsUploadHomeOwners = {
  ImageFile: Blob[];
  QuoteInfoId?: string;
  FileName: string;
};

export type GetQuoteCategoryPair = {
  NumberOfUnits: string;
  Value: string;
  selected: boolean;
};

export type RentDefaultInsuranceRequest = {
  landLordTitle?: number;
  landLordEmail?: string;
  landLordPhoneNumber?: string;
  landLordFirstName?: string;
  landLordLastName?: string;
  landLordMiddleName?: string;
  tenantTitle?: number;
  tenantEmail?: string;
  tenantPhoneNumber?: string;
  tenantFirstName?: string;
  tenantLastName?: string;
  tenantMiddleName?: string;
  landLordDateofBirth?: Date;
  landLordAddress?: string;
  landLordOccupation?: string;
  landLordGender?: number;
  tenantDateofBirth?: Date;
  tenantAddress?: string;
  tenantOccupation?: string;
  tenantGender?: number;
  premiumPaymentFrequency?: string;
  duration?: number;
  productCode?: string;
  refferrerEmail?: string;
  payAmount?: number;
  propertyTitle?: string;
  propertyAddress?: string;
  units?: number;
  annualRentalIncome?: number;
  quote?: number;
  pin?: string;
};

export type RentInsuranceRequestBody = {
  title?: number;
  middleName?: string;
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  dateofBirth?: Date;
  address?: string;
  occupation?: string;
  gender?: number;
  quoteId?: string;
  premiumPaymentFrequency?: string;
  duration?: number;
  productCode?: string;
  refferrerEmail?: string;
  insuranceQuoteinfodetail?: QuoteInfoDetails[];
  imageFile?: Blob[] | File[];
  quoteInfoId?: string;
  fileName?: string;
  payAmount?: number;
  policyId?: string;
  transId?: string;
  totalSumInsured?: number;
  totalPremium?: number;
};

export type HomeOwnersGetQuoteReq = {
  PolicyDuration: string;
  ContactAddress?: string;
  Building?: GetQuoteCategoryPair;
  LapTop?: GetQuoteCategoryPair;
  Phone?: GetQuoteCategoryPair;
  Jewelries?: GetQuoteCategoryPair;
  WristWatch?: GetQuoteCategoryPair;
  Camera?: GetQuoteCategoryPair;
  Others?: GetQuoteCategoryPair;
};

export type QuoteInfoDetails = {
  itemName: string;
  itemContactAddress: string;
  sumInsuredValue: number;
  assetType: string;
  category: string;
};

export type QuoteInfoDetailsRent = {
  itemName: string;
  itemContactAddress: string;
  sumInsuredValue: number;
  assetType: string;
  category: string;
  coverType: string;
};

export type QuoteInfoDetail = {
  itemName: string;
  itemContactAddress: string;
  sumInsuredValue: number;
  assetType: string;
  category: string;
  coverType: string;
};

export type HomeOwnerCreateQuoteRequestBody = {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  duration: number;
  title: number;
  quoteInfoDetails: QuoteInfoDetails[];
};

export type HouseholdCreateQuoteRequestBody = {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  middleName: string;
  duration: number;
  title: number;
  quoteInfoDetails: QuoteInfoDetail[];
};

export type CreateQuoteResponseData = {
  totalPremium: number;
  totalSumInsured: number;
  productName: string;
  quoteId: string;
  downloadUrl: any;
};

export enum AssetTypeList {
  All = "All-risk",
  Non = "Non-movable",
  Mov = "Movable",
}

export enum CategoryList {
  Con = "Content",
  Bui = "Building",
}

export const assetTypeList = [
  { item: "Building", type: AssetTypeList.Non },
  { item: "LapTop", type: AssetTypeList.Mov },
  { item: "Phone", type: AssetTypeList.Mov },
  { item: "Jewelries", type: AssetTypeList.Mov },
  { item: "WristWatch", type: AssetTypeList.Mov },
  { item: "Camera", type: AssetTypeList.Mov },
  { item: "Others", type: AssetTypeList.Mov },
];
export const assetTypeLists = [
  { item: "Building", type: AssetTypeList.Non },
  { item: "AirConditioner", type: AssetTypeList.Mov },
  { item: "Television", type: AssetTypeList.Mov },
  { item: "Refrigerator", type: AssetTypeList.Mov },
  { item: "Freezer", type: AssetTypeList.Mov },
  { item: "HomeTheatre", type: AssetTypeList.Mov },
  { item: "Table", type: AssetTypeList.Mov },
  { item: "GasCooker", type: AssetTypeList.Mov },
  { item: "Inverter", type: AssetTypeList.Mov },
  { item: "Bed", type: AssetTypeList.Mov },
  { item: "WaterDispenser", type: AssetTypeList.Mov },
  { item: "WashingMachine", type: AssetTypeList.Mov },
  { item: "KitchenCabinets", type: AssetTypeList.Mov },
  { item: "Shoes", type: AssetTypeList.Mov },
  { item: "Bags", type: AssetTypeList.Mov },
  { item: "Clothing", type: AssetTypeList.Mov },
  { item: "StandingFan", type: AssetTypeList.Mov },
  { item: "Laptop", type: AssetTypeList.Mov },
  { item: "Phone", type: AssetTypeList.Mov },
  { item: "Jewelries", type: AssetTypeList.Mov },
  { item: "WristWatch", type: AssetTypeList.Mov },
  { item: "Camera", type: AssetTypeList.Mov },
  { item: "Other", type: AssetTypeList.Mov },
  { item: "Others", type: AssetTypeList.Mov },
];

export const categoryList = [
  { item: "Building", type: CategoryList.Bui },
  { item: "LapTop", type: CategoryList.Con },
  { item: "Phone", type: CategoryList.Con },
  { item: "Jewelries", type: CategoryList.Con },
  { item: "WristWatch", type: CategoryList.Con },
  { item: "Camera", type: CategoryList.Con },
  { item: "Others", type: CategoryList.Con },
];

export const categoryLists = [
  { item: "Building", type: CategoryList.Con },
  { item: "AirConditioner", type: CategoryList.Con },
  { item: "Television", type: CategoryList.Con },
  { item: "Refrigerator", type: CategoryList.Con },
  { item: "Freezer", type: CategoryList.Con },
  { item: "HomeTheatre", type: CategoryList.Con },
  { item: "Table", type: CategoryList.Con },
  { item: "GasCooker", type: CategoryList.Con },
  { item: "Inverter", type: CategoryList.Con },
  { item: "Bed", type: CategoryList.Con },
  { item: "WaterDispenser", type: CategoryList.Con },
  { item: "WashingMachine", type: CategoryList.Con },
  { item: "LivingRoom", type: CategoryList.Con },
  { item: "KitchenCabinets", type: CategoryList.Con },
  { item: "Shoes", type: CategoryList.Con },
  { item: "Bags", type: CategoryList.Con },
  { item: "Clothing", type: CategoryList.Con },
  { item: "StandingFan", type: CategoryList.Con },
  { item: "Laptop", type: CategoryList.Con },
  { item: "Phone", type: CategoryList.Con },
  { item: "Jewelries", type: CategoryList.Con },
  { item: "WristWatch", type: CategoryList.Con },
  { item: "Other", type: CategoryList.Con },
  { item: "Others", type: CategoryList.Con },
];

export type CreatePolicyRequest = {
  dateofBirth?: Date;
  address?: string;
  occupation?: string;
  gender?: number;
  quoteId?: string;
  premiumPaymentFrequency?: string;
};

export type MakePaymentHomeOwners = {
  payAmount?: number;
  policyId?: string;
  paymentMethod: string;
  transId?: string;
};

export type MakePaymentHouseHold = {
  payAmount?: number;
  policyId?: string;
  // paymentMethod: string;
  // transId?: string;
};

export type CreatePolicyRequestResponseData = {
  policyId: string;
  policyNumber: string;
  amountToPay: number;
};

export const CreatePolicyRequestInitialValue: CreatePolicyRequest = {
  dateofBirth: undefined,
  address: undefined,
  occupation: undefined,
  gender: undefined,
  quoteId: undefined,
  premiumPaymentFrequency: undefined,
};

export type DocumentsUploadHouseHold = {
  ImageFile: Blob[];
  QuoteInfoId?: string;
  FileName: string;
};

export type BlobFormatImprovised = {
  uri: any;
  name: string;
  filename: string;
  type: string;
};

export type InsuranceObj = {
  id: number;
  title: number;
  email: string;
  phoneNumber: number;
  firstName: string;
  lastName: string;
  middleName: string;
  dateCreated: string;
  policyNumber: string;
  policyId: string;
  productPurchased: string;
  totalSumInsured: number;
  totalPremium: number;
  quoteId: string;
  isSuccessful: boolean;
  policyDocumentUrl: string;
  userId: string;
  gender?: number;
  address?: string;
  occupation?: string;
  dateofBirth?: string;
  duration?: number;
  propertyTitle?: string;
  units?: number;
  annualRentalIncome?: number;
  propertyAddress?: string;
  quote?: number;
  user: User;
};

export type SingleInsurance = {
  id: string;
  landLordTitle: number;
  landLordEmail: string;
  landLordPhoneNumber: string;
  landLordFirstName: string;
  landLordLastName: string;
  landLordMiddleName: string;
  tenantTitle: number;
  tenantEmail: string;
  tenantPhoneNumber: string;
  tenantFirstName: string;
  tenantLastName: string;
  tenantMiddleName: string;
  landLordDateofBirth: string;
  landLordAddress: string;
  landLordOccupation: string;
  landLordGender: number;
  tenantDateofBirth: string;
  tenantAddress: string;
  tenantOccupation: string;
  tenantGender: number;
  premiumPaymentFrequency: any;
  dateCreated: string;
  productPurchased: string;
  isSuccessful: boolean;
  policyDocumentUrl: any;
  paymentMethod: string;
  quote: number;
  duration: number;
  productCode: null;
  refferrerEmail: null;
  payAmount: number;
  propertyTitle: string;
  propertyAddress: string;
  units: number;
  annualRentalIncome: number;
  isDone: boolean;
  userId: string;
  user: User;
};

export type PaginationRes = {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
};

export type GetInsuranceResponseData = {
  item1: InsuranceObj[];
  item2: PaginationRes;
};

export type GetInsuranceResponse = {
  data?: GetInsuranceResponseData;
  error?: AxiosError<any>;
};

export type GetRentInsuranceDocs = {
  id: number;
  imageUrl: string;
  imageTitle: string;
  attachmentType: string;
  publicId: string;
};

export type GetRentInsuranceDocsResponse = {
  data?: GeneralOperationResult<GetRentInsuranceDocs[]>;
  error?: AxiosError<any>;
};

export type GetSingleInsuranceResponse = {
  data?: GeneralOperationResult<SingleInsurance>;
  error?: AxiosError<any>;
};

export type ReapplyHomeOwnersType = {
  premium: number;
  policyNo: string;
  policyId: string;
};

export type ActivePolicyKeys = {
  PolicyNumber?: string;
  PropertyType?: string;
  NameofPolicyHolder?: string;
  DateCreated?: string;
  DateofExpiry?: Date;
  PayablePremium?: number;
  TotalSumInsured?: number;
};

export type ReapplyHouseHoldType = {
  premium: number;
  policyNo: string;
  policyId: string;
};
