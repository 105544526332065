import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import { PaymentSchedule, SavingsTowardsRentRequest } from '../../../types/Savings';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { toDateString } from '../../../utils/generalUtils';
import styles from './scheduleDialog.module.scss'
import { log } from 'console';

export interface PaymentScheduleSummaryDialogProps{
    open?: boolean;
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
    onConfirm?: (state: SavingsTowardsRentRequest) => Promise<void>;
    paymentSchedules?: PaymentSchedule[];
    state?: SavingsTowardsRentRequest | any
}

const PaymentScheduleSummaryDialog: React.FC<PaymentScheduleSummaryDialogProps> = ({
    open = false, onClose, paymentSchedules, onConfirm, state
})=>{

    // Cell Render Function
    const renderPaymentCell = (date: Date, index: number, total: number)=>{
        
        return(
            <TableCell>
                <div className={styles.cellInfo}>
                    <p><b>{`Payment ${index + 1}/${total}`}</b></p>
                    <p>{toDateString(date)}</p>
                </div>
            </TableCell>
        )
    }
    const renderChargecell = (debitAmount: number) =>{

        return(
            <TableCell>
                <div className={styles.cellInfo}>
                    <p>Charge</p>
                    <p>₦{debitAmount}</p>
                </div>
            </TableCell>
        )
    }

    const scheduleTable = (
        <Table>
            {
                paymentSchedules?.map((schedule, index)=>(
                    <TableRow key={index}>
                        { renderPaymentCell(schedule.debitDate, index, paymentSchedules.length) }
                        { renderChargecell(schedule.debitAmount) }
                    </TableRow>
                ))
            }
        </Table>
    )

    const titleContent = (
        <div>
            <h4>{`Total Payments: ${paymentSchedules?.length}`}</h4>
        </div>
    )

    return(
        <Dialog
            maxWidth= 'lg'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '60%',
                    height: '100%'
                }
            }}
            aria-labelledby="delete-dialog-dialog-title"
            aria-describedby="delete-dialog-description"
        >
           <DialogTitle id="delete-dialog-dialog-title">
                Schedule Summary
            </DialogTitle>
            <DialogContent>
                { titleContent }
                { scheduleTable }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={()=>onConfirm && onConfirm(state)}>Confirm</Button>
            </DialogActions>
        
        </Dialog>
    )
}

export default PaymentScheduleSummaryDialog;