import { ArrowRight } from 'react-bootstrap-icons';
import React from 'react';
import useStyles from './callToAction-jss';
import { Link } from 'react-router-dom';


const CallToAction: React.FC<any> = ()=>{

    const styles = useStyles();
    return(
        /** Top Banner */
        <div className={styles.banner}>
            <div className={styles.bannerContainer}>
                <div className={`row ${styles.row}`}>
                    <div className={`col-lg-12`}>
                        {/** Call to action */}
                        <div className={`${styles.bannerContent}`}>
                            {/** Text */}
                            <div className={styles.bannerInfo}>
                                <h1>Looking for a dream home?</h1>
                                <p>We can help you realize your dream of a new home</p>
                            </div>
                            {/** Button */}
                            <div className={styles.buttonWrapper}>
                                <Link className={`btn ${styles.bannerButton}`} to="/properties">
                                    Explore Properties
                                    <ArrowRight/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CallToAction;