import Axios, { AxiosError, AxiosResponse } from "axios";
import useSwr from "swr";
import {
  BillResponse,
  CustomerLookUpRequestBody,
  CustomerLookupReponse,
  CustomerLookupRequest,
  InitiatePaymentRequest,
  InitiatePaymentResponse,
  PackageResponse,
  PackageResponseData,
  ProcessPaymentRequest,
  ProcessPaymentResponse,
} from "../types/Billings";
import { GeneralOperationResult } from "../types/GeneralResponse";
import api from "../axios";

// const baseUrl = "https://api.homebuddy.ng";
// const baseUrl = 'https://api.homebuddy.ng'

export const useDiscoBills = (slug: string) => {
  const fetcher = (url: string) =>
    api.get(url).then((res: AxiosResponse<BillResponse>) => res.data);

  const { data, error } = useSwr(`/api/UtilityBills/GetAllBillers`, fetcher);
  return {
    discoBills: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const fetchAllBillers = async (token: string): Promise<BillResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.get(`/api/UtilityBills/GetAllBillers`, config);
  return data;
};

export const fetchAllBillersPackages = async (
  token: string,
  id: string
): Promise<BillResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.get(
    `/api/UtilityBills/GetBillersByGroup/${id}`,
    config
  );
  return data;
};

export const fetchEachBillerPackages = async (
  token: string,
  id: string
): Promise<PackageResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.get(
    `/api/UtilityBills/GetPackagesByBiller/${id}`,
    config
  );
  return data;
};

export const customerLookUpApi = async (
  token: string,
  request: CustomerLookUpRequestBody
): Promise<CustomerLookupReponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.post(
    `/api/UtilityBills/CustomerLookup`,
    request,
    config
  );
  return data;
};

export const fetchDiscoPackage = (slug: string) => {
  const willFetchPackage = new Promise<GeneralOperationResult<PackageResponse>>(
    (resolve) => {
      api
        .get(`/api/UtilityBills/GetBillersByGroupSlug/${slug}`)
        .then((respone: AxiosResponse<PackageResponse>) => {
          resolve({ data: respone.data });
        })
        .catch((err: AxiosError) => {
          resolve({ error: err });
        });
    }
  );

  return willFetchPackage;
};

export const customerLookUp = (request: CustomerLookupRequest) => {
  const willPerformCustomerLookup = new Promise<
    GeneralOperationResult<CustomerLookupReponse>
  >((resolve) => {
    api
      .post(`/api/UtilityBills/CustomerLookup`, request)
      .then((respone: AxiosResponse<CustomerLookupReponse>) => {
        resolve({ data: respone.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });

  return willPerformCustomerLookup;
};

export const InitiatePayment = async (
  token: string,
  request: InitiatePaymentRequest
): Promise<InitiatePaymentResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.post(
    `/api/UtilityBills/InitiatePayment`,
    request,
    config
  );
  return data;
};

export const ProcessPayment = async (
  token: string,
  request: ProcessPaymentRequest
): Promise<ProcessPaymentResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.post(
    `/api/UtilityBills/ProcessPayment`,
    request,
    config
  );
  return data;
};
