import React from "react";
import styles from "./show-iteration.module.scss";
import CheckIcon from '@mui/icons-material/Check';

type ShowIterationProps = {
  numbers: number;
  selectedPosition: number;
};

const ShowIteration: React.FC<ShowIterationProps> = ({
  numbers,
  selectedPosition,
}) => {
  const returnCircles = (nums: number, sel: number) => {
    const circlesArr = [];
    for (let i = 0; i < nums; i++) {
      circlesArr.push(
        i + 1 <= sel ? (
          <div className={styles["show-circle-sel"]} key={i}>
            <CheckIcon sx={{color: "white"}}/>
          </div>
        ) : (
          <div className={styles["show-circle"]} key={i}>
            <span className={styles["txt"]}>{i + 1}</span>
          </div>
        )
      );
    }
    return circlesArr;
  };
  
  return (
    <div className={styles["show-it-container"]}>
        <div className={styles["show-line"]}></div>
      {returnCircles(numbers, selectedPosition)}
    </div>
  );
};

export default ShowIteration;
