import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import styles from "./household-form.module.scss";
import React, { useContext } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { setTermsAgreement } from "../../../../state/reducers/insuranceSlice";
import { useDispatch } from "react-redux";
import ToastContext from "../../../../contexts/ToastContext";

type Term = {
  head: string;
  body: string[];
};

const terms: Term[] = [
  {
    head: "Coverage Overview",
    body: [
      "Homebuddy Rent Default Insurance provides coverage for landlords in the event of tenant default on rent payments.",
    ],
  },
  {
    head: "Eligibility",
    body: [
      "To be eligible, landlords must conduct thorough tenant screenings, verify income, and adhere to Homebuddy's leasing guidelines.",
    ],
  },
  {
    head: "Coverage Period",
    body: [
      "The policy covers rent default up to a specified limit during the lease term.",
      "Coverage begins after a waiting period of 30 days from the first missed payment.",
    ],
  },
  {
    head: "Claim Process",
    body: [
      "Landlords must notify Homebuddy within 15 days of tenant default.",
      "A complete claim application, including relevant documentation, is required for processing.",
    ],
  },
  {
    head: "Exclusions",
    body: [
      "Coverage excludes willful damage, illegal activities, and breach of lease terms by the landlord.",
      "Coverage does not extend beyond the lease agreement.",
    ],
  },
  {
    head: "Premiums and Payments",
    body: [
      "Premiums are determined based on property value, rental history, and location.",
      "Premiums are payable monthly and are non-refundable.",
    ],
  },
  {
    head: "Cancellation",
    body: [
      "The policy can be canceled by the landlord with a 30-day notice.",
      "Refunds are not provided for the remaining coverage period.",
    ],
  },
  {
    head: "Policy Renewal",
    body: [
      "The policy can be renewed annually, subject to Homebuddy's review of the landlord's continued eligibility and adherence to leasing guidelines.",
    ],
  },
];

const TermsAndConditions = ({
  setRentStep,
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
}) => {
  const { termsAgreement } = useSelector((state: RootState) => state.insurance);
  const dispatch = useDispatch();
  const { openError } = useContext(ToastContext);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTermsAgreement(event.target.checked));
  };

  const saveAndContinue = () => {
    if (termsAgreement === false) {
      openError(
        "Please check the box below to agree to the terms and conditions"
      );
      return;
    }
    dispatch(setRentStep(6));
  };

  const goBack = () => {
    dispatch(setRentStep(3));
  };

  return (
    <div className={styles.forms}>
      {/* <span className={styles["head-terms"]}>
        Carefully read and accept the terms & conditions below before proceeding
        with payment.
      </span>

      {terms.map((term) => (
        <div key={term.head} className={styles["each-term-cont"]}>
          <span className={styles["each-terms-head"]}>{term.head}</span>
          {term.body.map((each, index) => (
            <span key={index} className={styles["terms-header"]}>
              {each}
            </span>
          ))}
        </div>
      ))} */}

      <FormGroup>
        <FormControlLabel
          required
          control={<Checkbox value={termsAgreement} onChange={handleChange} />}
          label="By purchasing Homebuddy Rent Default Insurance, you agree that all the information provided above is true and accurate."
        />
      </FormGroup>

      <div className={styles["container-bottom-butt"]}>
        <span onClick={() => goBack()} role="button">
          Back
        </span>

        <div
          className={styles["continue-butt"]}
          role="button"
          onClick={() => saveAndContinue()}
        >
          <span> Submit </span>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
