import React, { useContext } from 'react';
import { StyledRating } from '../../Reviews';
import Box from '@mui/material/Box';
import styles from './commentForm.module.scss';
import { Rating } from '../../../../types/Rating';
import { addRating } from '../../../../swr/ratings';
import { useSWRConfig } from 'swr';
import CustomToast from '../../../CustomToast';
import LoadingScreen from '../../../LoadingScreen';
import ToastContext from '../../../../contexts/ToastContext';


export interface CommentFormProps{
    onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    id?: string | number
}

const CommentForm: React.FC<CommentFormProps> = ({
    onSubmit = (event)=> event?.preventDefault(), id
})=>{

    // Refs
    const formRef = React.createRef<HTMLFormElement>();
    const textRef = React.createRef<HTMLTextAreaElement>();

    // Hooks
    const { mutate } = useSWRConfig();

    const {openError} = useContext(ToastContext)

    // State
    const [rating, setRating] = React.useState(0);
    const [errOpen, setErrOpen] = React.useState<boolean>(false);
    const [successOpen, setSucessOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    // Handlers
    const handleRatingChange = (event: React.SyntheticEvent, newValue: number | null)=>{
        if (newValue) {
            setRating(newValue);
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        setLoading(true);
        let formEl = formRef.current;

        if (formEl) {
            const fdata = new FormData(formEl);

            if(!rating){
                openError('Please add rating')
                setLoading(false)
                return
            }

            // Request
            let request: Rating = {
                propertiesId: id ? parseInt(id.toString()) : 0,
                comment: fdata.get("comment")?.toString() || '',
                rating: rating
            }

            let token = localStorage.getItem('token') || undefined;
            const { data, error } = await addRating(request, token);

            if (data && data.succeeded) {
                setLoading(false);
                setSucessOpen(true);
                mutate(`/api/Ratings/get-rating?id=${id}`);
                setRating(0);
                formEl.reset();
            } else if (error) {
                setLoading(false);
                setErrOpen(true);
            }
        }
    }
    return(
        <>
        {loading && <LoadingScreen/>}
        <CustomToast errOpen={errOpen} successOpen={successOpen}
            onErrClose={()=> setErrOpen(false)} onSuccessClose={()=> setSucessOpen(false)}
            errMessage={'An error occurred while adding your comment'}
            successMessage={'Comment successfully added'}
        />
        <div className="ltn__comment-reply-area ltn__form-box mb-30">
            <form onSubmit={handleSubmit} ref={formRef} id="comment-form">
                <h4>Add a Review</h4>
                <div className="mb-30">
                    <div className={`add-a-review ${styles.rating}`}>
                        <h6>Your Ratings:</h6>
                        <Box textAlign="end" position='relative'>
                            <StyledRating name="half-rating" 
                                value={rating} precision={1} size="large"
                                onChange={handleRatingChange}
                            />
                        </Box>
                    </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                    <textarea name="comment" placeholder="Type your comments...." ref={textRef}
                    ></textarea>
                </div>
                {/*<div className="input-item input-item-name ltn__custom-icon">
                    <input type="text" placeholder="Type your name...."/>
                </div>
                <div className="input-item input-item-email ltn__custom-icon">
                    <input type="email" placeholder="Type your email...."/>
                </div>
                <div className="input-item input-item-website ltn__custom-icon">
                    <input type="text" name="website" placeholder="Type your website...."/>
                </div>
                <label className="mb-0">
                <input type="checkbox" name="agree"/> 
                    {' Save my name, email, and website in this browser for the next time I comment.'}
                </label>*/}
                <div className="btn-wrapper">
                    <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
        </>
    )
}

export default CommentForm;