import { FooterProps } from ".";
import logo from '../../assets/HomeBuddyLogo_FullColour_PrimaryLockup.svg';

const footerData: FooterProps = {
    logo:{
        uri: logo,
        label: 'HomeBuddy'
    },

    description:`HomeBuddy is the safest and most convenient online one-stop shop for all of your housing requirements. Nigerians can discover and pay for rental housing, as well as own a home`,

    linksSections:[
        { 
            title: "Company",
            links: [
                // { uri: "#", text: "About" },
                // { uri: "#", text: "Blog" },
                { uri: "/properties", text: "All Properties" },
                // { uri: "#", text: "Locations Map" },
                //{ uri: "#", text: "FAQ" },
                //{ uri: "/contact", text: "Contacts" },
            ],

        },

        {
            title: "Customer Care",
            links: [
                { uri: "/signin", text: "Login" },
                { uri: "/account", text: "My account" },
                // { uri: "#", text: "Wish List" },
                // { uri: "#", text: "Order tracking" },
                { uri: "/faq", text: "FAQ" },
                { uri: "/contact", text: "Contact us" },
            ],
        },

    ]
}

export default footerData;