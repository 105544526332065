import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  ApplyInsuranceII,
  AssetTypeList,
  CategoryList,
  RentDropDown,
} from "../../../../types/Insurance";
import styles from "./rent-form.module.scss";
import EmptyDropDown from "../../../../components/Input/EmptyDropDown";
import PopUp from "../../../../components/Insurance/PopUp";
import ModalLayout from "../../../../components/ModalLayout";
import { useDispatch } from "react-redux";
import {
  setGetAQuoteReq,
  setHomeOwnerGetQuoteRes,
  setRentDropValue,
  setRentQuoteInfoDetails,
  setRentDefaultInsuranceQuote
} from "../../../../state/reducers/insuranceSlice";
import ToastContext from "../../../../contexts/ToastContext";
import {
  formatAmount,
  premiumPaymentFreqArr,
  returnMsgFromAxiosError,
} from "../../../../utils/generalUtils";
import { homeOwnersCreateQuoteApi, rentDefaultGenerateQuoteApi } from "../../../../swr/insurance";
import LoadingContext from "../../../../contexts/LoadingContext";

export const propertyTypeArr = [
  "Bungalow",
  "Storey Building",
  "Duplex",
  "Semi-detached",
];

const coverageArr = ["3 months", "6 months"];

const GetAQuote = ({
  setRentStep,
  closeAllDropDowns,
  rentDropDownValue,
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
  closeAllDropDowns: () => void;
  rentDropDownValue: RentDropDown;
}) => {
  const dispatch = useDispatch();
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const { getAQuoteReq, RentDefaultInsuranceQuote } = useSelector(
    (state: RootState) => state.insurance
  );

  const token = localStorage.getItem("token") as string;

  const [getAQuote, setgetAQuoteI] = useState<ApplyInsuranceII>({
    PropertyType: 6,
    PropertyAddress: "",
    UnitsNumber: "",
    AnnualRent: "",
    DesiredCoveragePeriod: 6,
  });

  const handleTypedOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setgetAQuoteI((prev: any) => ({
      ...prev,
      [name]:
        name === "AnnualRent"
          ? formatAmount(parseFloat(value.replace(/[^0-9.]/g, "")))
          : value.toUpperCase(),
    }));
  };

  const handleTypeSelection = (val: string) => {
    setgetAQuoteI((prev) => ({
      ...prev,
      PropertyType: propertyTypeArr.indexOf(val),
    }));
    closeAllDropDowns();
  };
  const handleCoverageSelection = (val: string) => {
    setgetAQuoteI((prev) => ({
      ...prev,
      DesiredCoveragePeriod: coverageArr.indexOf(val),
    }));
    closeAllDropDowns();
  };

  const openTitleDropDown = () => {
    dispatch(setRentDropValue("PropertyType"));
  };

  const openCoverageDropDown = () => {
    dispatch(setRentDropValue("CoveragePeriod"));
  };

  const validate = () => {
    if (
      !getAQuote.PropertyAddress ||
      !getAQuote.UnitsNumber ||
      !getAQuote.AnnualRent ||
      getAQuote.PropertyType === 6 ||
      getAQuote.DesiredCoveragePeriod === 6
    ) {
      openError("Please fill all required fields");
      return false;
    } else {
      return true;
    }
  };

  const saveAndContinue = () => {
    if (RentDefaultInsuranceQuote === undefined) {
      openError("Please create quote");
      return;
    }
    dispatch(setGetAQuoteReq(getAQuote));
    dispatch(setRentStep(3));
  };

  const goBack = () => {
    dispatch(setRentStep(1));
  };

  // {
  //   email: personalDetails.Email,
  //   phoneNumber: personalDetails.PhoneNumber!,
  //   firstName: personalDetails.FirstName,
  //   lastName: personalDetails.LastName,
  //   duration: getAQuote.DesiredCoveragePeriod === 0 ? 3 : 6,
  //   title: personalDetails.Title,
  //   quoteInfoDetails: [
  //     {
  //       itemName: propertyTypeArr[getAQuote.PropertyType],
  //       itemContactAddress: getAQuote.PropertyAddress,
  //       sumInsuredValue:
  //         getAQuote.UnitsNumber && getAQuote.AnnualRent
  //           ? (parseFloat(getAQuote.UnitsNumber) * parseFloat(getAQuote.AnnualRent.split(',').join('')))
  //           : 0,
  //       assetType: AssetTypeList.Non,
  //       category: CategoryList.Bui,
  //     },
  //   ],
  // }

  const createQuoteRent = async () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setLoading(true);
    console.log(getAQuote, "=======");
    
    const { data, error } = await rentDefaultGenerateQuoteApi(
      getAQuote.UnitsNumber && getAQuote.AnnualRent
            ? (Number(getAQuote.UnitsNumber.replace(/\D/g, "")) * Number(getAQuote.AnnualRent.replace(/\D/g, "")))
            : 0,
      token
    );

    dispatch(
      setRentQuoteInfoDetails({
        itemName: propertyTypeArr[getAQuote.PropertyType],
        itemContactAddress: getAQuote.PropertyAddress,
        sumInsuredValue:
          getAQuote.UnitsNumber && getAQuote.AnnualRent
            ? (Number(getAQuote.UnitsNumber) * Number(getAQuote.AnnualRent))
            : 0,
        assetType: AssetTypeList.Non,
        category: CategoryList.Bui,
        coverType: "Fire and Burglary"
      })
    );

    if (data) {
      dispatch(setRentDefaultInsuranceQuote(data.data));
      setLoading(false);
      openSuccess(data.message ? data.message : "Quote created successfully");
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "An error occurred creating quote")
      );
      return;
    }

    setLoading(false);
    openError("An error occurred creating quote");
  };

  useEffect(() => {
    if (Object.keys(getAQuoteReq).length !== 0) {
      setgetAQuoteI({ ...getAQuoteReq });
    }
  }, []);

  return (
    <>
      <div className={styles.form}>
        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              Property Title<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  propertyTypeArr[
                    getAQuoteReq.PropertyType ?? getAQuote.PropertyType
                  ]
                }
                click={openTitleDropDown}
              />
            </div>
          </div>

          <div className={styles["inputcolumn-65"]}>
            <span>
              Property Address<span className={styles.red}>*</span>
            </span>
            <input
              name="PropertyAddress"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.PropertyAddress || getAQuoteReq.PropertyAddress}
              defaultValue={getAQuoteReq.PropertyAddress}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              Number of units<span className={styles.red}>*</span>
            </span>
            <input
              name="UnitsNumber"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.UnitsNumber || getAQuoteReq.UnitsNumber}
              defaultValue={getAQuoteReq.UnitsNumber}
            />
          </div>

          <div className={styles["inputcolumn-65"]}>
            <span>
              Annual Rental Income (NGN)<span className={styles.red}>*</span>
            </span>
            <input
              name="AnnualRent"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.AnnualRent || getAQuoteReq.AnnualRent}
              defaultValue={getAQuote.AnnualRent}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Desired coverage period<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  coverageArr[
                    getAQuoteReq.DesiredCoveragePeriod ??
                      getAQuote.DesiredCoveragePeriod
                  ]
                }
                click={openCoverageDropDown}
              />
            </div>
          </div>
        </div>

        {RentDefaultInsuranceQuote === undefined ? (
          <div
            role="button"
            onClick={() => createQuoteRent()}
            className={styles["get-a-quote-button"]}
          >
            <span>Get a Quote</span>
          </div>
        ) : (
          <div className={styles["quote-box"]}>
            <p>Quote</p>
            <span>Annual Payable Premium</span>
            <div className={styles.amount}>
              ₦
              {RentDefaultInsuranceQuote 
                ? formatAmount(RentDefaultInsuranceQuote)
                : 0}
            </div>
          </div>
        )}

        <div className={styles["container-bottom-butt"]}>
          <span onClick={() => goBack()} role="button">
            Back
          </span>

          {RentDefaultInsuranceQuote !== undefined && (
            <div
              className={styles["continue-butt"]}
              role="button"
              onClick={() => saveAndContinue()}
            >
              <span>Save & Continue</span>
            </div>
          )}
        </div>
      </div>

      <ModalLayout modalOpen={rentDropDownValue === "PropertyType"}>
        <PopUp options={propertyTypeArr} click={handleTypeSelection} />
      </ModalLayout>

      <ModalLayout modalOpen={rentDropDownValue === "CoveragePeriod"}>
        <PopUp options={coverageArr} click={handleCoverageSelection} />
      </ModalLayout>
    </>
  );
};

export default GetAQuote;
