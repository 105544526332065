import Input from '../Input';

import Button from '../Button';
import TextInput from '../FormElements/TextInput';
import { useState } from 'react';

interface IResetPassword {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    formValues: { [key: string]: string };
    loading?: boolean;
    link: string;
    userName: string;
}

const ResetPasswordAccount: React.FC<IResetPassword> = ({ handleChange, onSubmit, formValues, loading}) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="account-login-inner">
                        <form onSubmit={onSubmit} action="POST" className="ltn__form-box contact-form-box center">
                            {/* <Input type="email" name="email" placeholder="Email*" required={true} onChange={handleChange} value={formValues.email} /> */}
                            <TextInput type="password" name="password" placeholder="Password*" required={true} onChange={handleChange} value={formValues.password} showPassword={showPassword} togglePasswordVisibility={togglePasswordVisibility}/>
                            <TextInput type="password" name="confirmPassword" placeholder="Confirm Password*" required={true} onChange={handleChange} value={formValues.confirmPassword} />
                            {/* <Button label="SUBMIT" classes={'theme-btn-1 btn btn-block'} disabled={loading} styleProp='theme-btn-1 btn btn-block' /> */}
                            <button className='theme-btn-1 btn btn-block' type='submit'>SUBMIT</button>
                            
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPasswordAccount;
