import React from "react";
import Insurance from "..";
import Layout from "../../../components/Layout";
import NavHeader from "../../../components/NavHeader";
import ScrollToTop from "../../../components/ScrollToTop";
import SessionCheck from "../../../components/SessionCheck";
import RentInsurance from "./Page";

const RentInsurancePage = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader
        pageTitle="Rent Default Insurance"
        routeName="Rent Default Insurance"
      />
      <RentInsurance />
    </Layout>
  );
};

export default RentInsurancePage;
