import React from 'react';
import { isEmpty } from '../../../utils/formUtils';
import TextInput from '../../FormElements/TextInput';

interface ErrorFormState{
    amount: boolean;
    accountNumber: boolean;
}

const ToWalletForm: React.FC<any> = ()=>{

    // Refs
    const formRef = React.createRef<HTMLFormElement>();

    let initErrorState: ErrorFormState = {
        amount: false,
        accountNumber: false
    }
    const [errorState, setErrorState] = React.useState<ErrorFormState>(initErrorState);

    // Handlers
    const handleSubmit = async (e: React.FormEvent)=>{
        e.preventDefault();

        if (formRef.current) {
            const fdata = new FormData(formRef.current);

            if (validateForm(fdata) === 'passed') {
                
            }
        }
    }
    const unhighlight = (event: React.ChangeEvent<HTMLInputElement>)=>{
        setErrorState((prevState)=>({...prevState, [event.target.name]: false}));
    }
    const validateForm = (fdata: FormData): 'passed' | 'failed'=>{
        let result: 'passed' | 'failed' = 'passed';

        // Check if any field is empty
        for (const pairs of fdata.entries()) {
            //console.log(pairs);
            if (isEmpty(pairs[1].toString())) {
                setErrorState((prevState)=>({...prevState, [pairs[0]]: true}));
                result = 'failed';
            }
        }

        return result;
    }

    return(
        <form ref={formRef} onSubmit={handleSubmit} >
            <div className="row">
                <div className="col-md-6">
                    <label>Amount</label>
                    <TextInput 
                        name="amount"
                        hideDefaultAdornment
                        variant="amount"
                        error={errorState.amount}
                        required={errorState.amount}
                        onChange={unhighlight}
                    />
                </div>
                <div className="col-md-6">
                    <label>Wallet ID</label>
                    <TextInput 
                        name="accountNumber"
                        hideDefaultAdornment
                        error={errorState.accountNumber}
                        required={errorState.accountNumber}
                        onChange={unhighlight}
                    />
                </div>
            </div>

            <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Send</button>
        </form>
    )
}

export default ToWalletForm;