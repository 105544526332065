import { Pagination, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductSidebar from "../../components/ProductSidebar";
import FilterWidget from "../../components/Widgets/Filter";
import { useProperties } from "../../swr/properties";
import PropertyCard from "../../components/PropertyCard";
import propertyData from "../../components/PropertyCard/defaultData";
import styles from "./marketplace.module.scss";
import unavailableImage from "../../assets/property_unavailable.png";
import { marketProducts } from "./dataMarket";
import { MarketData } from "./dataMarket";
import MarketProductCard from "../../components/MarketProductCard";
import FilterProducts from "../../components/Widgets/FilterProducts";

const Index = () => {
  const token = localStorage.getItem("token") as string;
  const [products, setProducts] = useState([] as MarketData[]);
  const { properties, isLoading, isError } = useProperties(token, 1, 1000000);

  const handleFilterChange = (param: string, isIncluded: boolean) => {
    if(isIncluded){
      console.log(param);
      
      setProducts( marketProducts.filter((prod) => prod.category === param));
      return
    }
   setProducts(marketProducts)
  };

  useEffect(() => {
    setProducts(marketProducts);
  }, []);

  return (
    <div className="ltn_product-area ltn_product-gutter mb-120">
      <div className="container">
        <div className="row">
          {/** Results Column */}
          <div className="col-lg-8">
            {/** Toolbar */}
            {/* <PropertyToolbar onStateChange={hadleToolbarChange} /> */}

            {/** Grid */}
            <div className="tab-content">
              <div
                className="tab-pane fade active show"
                id="liton_product_grid"
              >
                <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                  <div className="row">
                    {(!products || products.length === 0) && !isLoading && (
                      <div className="col-xl-12">
                        <div className={styles.unavailable}>
                          <img
                            src={unavailableImage}
                            alt="No properties available"
                          />
                        </div>
                      </div>
                    )}

                    {products &&
                      products.length > 0 &&
                      !isLoading &&
                      products.map((prop) => (
                        <div className="col-xl-6 col-sm-6 col-12">
                          <MarketProductCard product={prop} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            {/** Pagination */}
            {/* {(paginationProperties && paginationProperties).length > 0 && (
            <Stack
              spacing={2}
              sx={{ marginBottom: "30px", alignItems: "center" }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                size={"large"}
                variant="outlined"
                color="standard"
              />
            </Stack>
          )} */}
          </div>

          {/** Amenities Filter */}
          <div className="col-lg-4">
            <ProductSidebar>
              <h3 className="mb-10 animated fadeIn">Advance Information</h3>
              <label className="mb-30">
                <small>filter results</small>
              </label>
              <div className="widget ltn__menu-widget">
                <FilterProducts
                  handleFilterChange={handleFilterChange}
                  // properties={properties}
                />
              </div>
            </ProductSidebar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
