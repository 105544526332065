import React from 'react';
import { Cities } from '../../types/cities';
import { PropertyType, propertyTypeEnumKeysArray } from '../../types/propertyType';
import { States } from '../../types/states';
import Dropdown, { DropdownOption } from '../FormElements/Dropdown';
import TextInput from '../FormElements/TextInput';
import styles from './heroSearch.module.scss';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { enumToDropdownObj } from '../../utils/generalUtils';

export interface HeroSearchFilter{
    rentActive?: boolean;
    saleActive?: boolean
}

// Libraries for google script
const libraries: ("drawing" | "geometry" | "localContext" | "places" | "visualization")[] = ["places"];

const HeroSearchFilter: React.FC<HeroSearchFilter> = ({ rentActive, saleActive })=>{

    // Hooks
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
        libraries: libraries
    })

    const cities = Object.values(Cities).filter((city)=> typeof city !== 'number');
    const states = Object.values(States).filter((city)=> typeof city !== 'number');
    const typeOptions: DropdownOption[] = enumToDropdownObj(PropertyType);
    //const bedrooms = [0,1,2,3,4,5];

    const [cityAutocomplete, setCityAutoComplete] = React.useState<google.maps.places.Autocomplete>();
    const [city, setCity] = React.useState<string|undefined>('')

    const onCityLoad =  (autocomplete: google.maps.places.Autocomplete)=> {
        setCityAutoComplete(autocomplete);
    }

    const onCityChanged = ()=> {
        if (cityAutocomplete) {
          setCity(cityAutocomplete.getPlace().formatted_address);
        } else {
        }
    }

    return(
        <div className="tab-content pb-10">
            <div className={`tab-pane fade ${rentActive && 'active show'}` } id="ltn__form_tab_1_1">
                <div className="car-dealer-form-inner">
                    <form action={`/properties`} className="ltn__car-dealer-form-box row">
                    <div className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-sm-3 ${styles.formItem}`}>
                            <Dropdown placeholder="Property Type" 
                                options={propertyTypeEnumKeysArray && propertyTypeEnumKeysArray} 
                                name="type" 
                                optionsObj={typeOptions}
                                useObjectValues
                            />
                        </div>
                        <div className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-sm-3 ${styles.formItem}`}>
                            <Dropdown placeholder="State" options={states} name="state"/>
                        </div>
                         <div className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-2 col-sm-2 ${styles.formItem}`}>
                            {/* {
                                isLoaded ?
                                <Autocomplete
                                    onLoad={onCityLoad}
                                    onPlaceChanged={onCityChanged}
                                    options={{
                                        types: ['(cities)'],
                                        componentRestrictions: { country: "ng" }
                                    }}
                                > */}
                                    {/* <TextInput placeholder="Enter a location" 
                                        name="city"
                                        useValue={city}
                                        onChange={(e) => {setCity(e.target.value)}}
                                    /> */}
                                {/* </Autocomplete>
                                :
                                "...Loading"
                            } */}
                            {/* <Dropdown placeholder="City" options={cities} name="city" /> */}
                        </div>
                        <div className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-sm-3 ${styles.formItem}`}>
                            <div className="btn-wrapper text-center mt-0">
                                {/* <!-- <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> --> */}
                                {/* <a href="shop-right-sidebar.html" className="btn theme-btn-1 btn-effect-1 text-uppercase">
                                    Search
                                </a> */}
                                <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search</button>
                            </div>
                        </div>
                        <input type="hidden" name="status" value="Rent" />
                    </form>
                </div>
            </div>
            <div className={`tab-pane fade ${saleActive && 'active show'}`} id="ltn__form_tab_1_2">
                <div className="car-dealer-form-inner">
                <form action={`/properties`} className="ltn__car-dealer-form-box row">
                        <div className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-sm-3 ${styles.formItem}`}>
                            <Dropdown placeholder="Property Type" 
                                options={Object.keys(PropertyType)} 
                                name="type" 
                                optionsObj={typeOptions}
                                useObjectValues
                            />
                        </div>
                        <div className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-sm-3 ${styles.formItem}`}>
                            <Dropdown placeholder="State" options={states} name="state"/>
                        </div>
                        {/* <div className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-sm-3 ${styles.formItem}`}> */}
                            {/* {
                                isLoaded ?
                                <Autocomplete
                                    onLoad={onCityLoad}
                                    onPlaceChanged={onCityChanged}
                                    options={{
                                        types: ['(cities)'],
                                        componentRestrictions: { country: "ng" }
                                    }}
                                > */}
                                   {/* <TextInput placeholder="Enter a location" 
                                        name="city"
                                        useValue={city}
                                        onChange={(e) => {setCity(e.target.value)}}
                                    /> */}
                                {/* </Autocomplete>
                                :
                                "...Loading"
                            } */}
                            {/* <Dropdown placeholder="City" options={cities} name="city" /> */}
                        {/* </div> */}
                        <div className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-sm-3 ${styles.formItem}`}>
                            <div className="btn-wrapper text-center mt-0">
                                {/* <!-- <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> --> */}
                                {/* <a href="shop-right-sidebar.html" className="btn theme-btn-1 btn-effect-1 text-uppercase">
                                    Search
                                </a> */}
                                <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search</button>
                            </div>
                        </div>
                        <input type="hidden" name="status" value="Sale" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default HeroSearchFilter;