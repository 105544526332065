import React from 'react';
import Layout from '../components/Layout';
import NavHeader from '../components/NavHeader';
import ScrollToTop from '../components/ScrollToTop';
import RegisterSection from '../sections/Register';

const SignUpPage: React.FC<any> = ()=>{

    return(
        <React.Fragment>
            <ScrollToTop/>
            {/* <Layout> */}
                {/* <NavHeader
                    pageTitle="Account"
                    routeName="Register"
                /> */}
                <RegisterSection/>
            {/* </Layout> */}
        </React.Fragment>
    )
}

export default SignUpPage;