import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const amenities: string[] = [
  "EquippedKitchen",
  "Gym",
  "Laundry",
  "MediaRoom",
  "Backyard",
  "Pool",
  "Electricity",
  "WaterSupply",
  "AirConditioning",
  "WashingMachine",
  "WIFI",
];

function getStyles(name: string, amenityList: readonly string[], theme: Theme) {
  return {
    fontWeight:
      amenityList.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export interface ViewProps{
    onPrevStep?: (arg: any) => any;
    onNextStep?: (arg: any) => any;
    prevKey?: string;
    nextKey?: string;
    onSubmit?: (event: React.FormEvent<HTMLButtonElement>)=> void;
}

export interface AmenitiesViewProps{
  handleChange: (amenities: SelectChangeEvent<string[]>)=> void;
  dataList: string[];
}

const Amenities: React.FC<AmenitiesViewProps> = ({ dataList, handleChange })=>{
  const theme = useTheme();

  // Refs
  let amenityRef = React.createRef<HTMLDivElement>();

  return (
    <div className="ltn__product-tab-content-inner" ref={amenityRef}>
      <FormControl sx={{ mb: 4, width: 600 }}>
        <InputLabel id="amenities-label">Amenities</InputLabel>
        <Select
          labelId="amenities-label"
          id="amenities"
          multiple
          value={dataList}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {amenities.map((amenity) => (
            <MenuItem
              key={amenity}
              value={amenity}
              style={getStyles(amenity, dataList, theme)}
            >
              {amenity}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default Amenities;