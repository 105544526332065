import './sass/style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Modal from 'react-modal'
import "../node_modules/video-react/dist/video-react.css"
// import { Store } from '@reduxjs/toolkit';


ReactDOM.render(
    <React.StrictMode>
         <Provider  store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

Modal.setAppElement(document.getElementById('root') as HTMLElement);

reportWebVitals();
