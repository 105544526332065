import React from "react";

import Layout from "../../../components/Layout";
import NavHeader from "../../../components/NavHeader";
import ScrollToTop from "../../../components/ScrollToTop";
import SessionCheck from "../../../components/SessionCheck";
import HouseholdInsurance from "./Page";
const HouseHoldPage = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader
        pageTitle="HouseHold Insurance"
        routeName="HouseHold Insurance"
      />
      <HouseholdInsurance />
    </Layout>
  );
};

export default HouseHoldPage;
