import React from "react";
import { AccordionItemProps } from "../components/AccordionItem";


export interface FAQItem extends AccordionItemProps{
    list?: string[];
    orderedList?: string[];
    custom?: React.ReactNode | React.ReactNode[];
}
export const faqs: FAQItem[] = [
    { 
        title: 'What is HomeBuddy?',
        content: 
        `
        HomeBuddy is the safest and most convenient online one-stop shop for all of your housing requirements. Nigerians can discover and pay for rental housing, as well as own a home. Currently, it takes an average of two to three months to find a suitable apartment that fulfills your requirements, and you must pay up to two years' rent in advance.

        HomeBuddy evaluates homes and makes it feasible for young professionals and families to find and pay for them in one week.
        ` ,
    },

    { 
        title: 'Does HomeBuddy own the properties?',
        content: 
        `
        No, we do not own the rental property. We work as an agency for Property Owners/Landlords, assisting them in finding a suitable occupant for their property. On behalf of our partner Landlords, we also receive and remit rental income.
        ` ,
    },

    { 
        title: 'What services can I enjoy on HomeBuddy?',
        list:[
            'Rent a property',
            'Buy a property',
            'Creation of instant and scheduled savings to ease payment of rent',
            'Book a tour to visit the preferred property',
            'Utility bills payment',
            'Wallet to Wallet transfer',
            'Property owners/Agents can list their properties at no fee',
        ]
    },

    { 
        title: 'Can I use HomeBuddy for my current apartment?',
        content: 
        `
        No, HomeBuddy is only available in properties that have been thoroughly vetted and for which we have a written agreement with the landlord or property owner.
        ` ,
    },

    { 
        title: 'Why am I charged platform fee?',
        content: 
        `
        To provide top-notch service, we are charging this fee in order for us to maintain high standard of quality for both renters/buyers and property owners.
        This fee covers verifying property listings, ensuring accurate information, and taking away the stress of engaging different agents from you.
        ` ,
    },

    { 
        title: 'If I encounter any issue, who should I contact?',
        content: 
        `
        Our support platform is available 24/7, you can send a mail to admin@homebuddy.ng
        ` ,
    },

];