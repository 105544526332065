import React from "react";
import { items } from "./data";
import styles from "./artisans.module.scss";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { useHistory } from "react-router";

type CardMember = {
  id: number;
  name: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
};

const Index = () => {
  const history = useHistory();

  const handleNavigate = (id: string) => {
    history.push(`/artisans/${id}`);
  };

  const ItemCard: React.FC<CardMember> = ({ id, name, icon }) => {
    let Icon = icon;
    return (
      <div
        className={styles.card}
        onClick={() => handleNavigate(id.toString())}
        role="button"
      >
        <span className={styles.cardTxt}>{name}</span>
        <Icon sx={{ fontSize: 50, paddingTop: "10%", color: "#91EA91" }} />
      </div>
    );
  };

  return (
    <div className={styles.overallContainer}>
      <div className={styles.containerI}>
        {items &&
          items.map((item) => (
            <ItemCard
              id={item.id}
              icon={item.icon}
              name={item.name}
              key={item.id}
            />
          ))}
      </div>
    </div>
  );
};

export default Index;
