import React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import propertyData from '../../../PropertyCard/defaultData';
import { Property, PropertyRequest } from '../../../../types/Property';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import DashboardLayout from '../../Layout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useTheme } from '@mui/material/styles';
import { deleteProperty, updateProperty, useProperties } from '../../../../swr/properties';
import Skeleton from '@mui/material/Skeleton';
import { UpdatePropertyRequest } from '../../../../state/thunks/property';
import { useSWRConfig } from 'swr';
import LoadingScreen from '../../../LoadingScreen';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteProductDialog from '../../../Dialogs/DeleteProduct';

const ProductListing: React.FC<any> = ()=>{

    // State
    const [page, setPage] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(9);
    const [pageCount, setPageCount] = React.useState(0);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [pageNumber, setPageNumber] = React.useState<string | number>(1);
    const [pageSize, setPageSize] = React.useState<number>(10000000);

    let fetchToken = localStorage.getItem('token') || undefined;

    // Hooks
    const theme = useTheme();
    const { mutate } = useSWRConfig();
    const { properties, isLoading, isError  } = useProperties(fetchToken, pageNumber, pageSize);

    let testProperties: Property[] = [
        propertyData, propertyData, propertyData, propertyData, propertyData, propertyData,
        propertyData, propertyData, propertyData, propertyData, propertyData, propertyData,
        propertyData, propertyData, propertyData, propertyData, propertyData, propertyData,
        propertyData, propertyData, propertyData, propertyData, propertyData, propertyData,
        propertyData, propertyData, propertyData, propertyData, propertyData, propertyData,
        propertyData, propertyData, propertyData, propertyData, propertyData, propertyData,
        propertyData, propertyData, propertyData, propertyData, propertyData, propertyData,
        propertyData, propertyData, propertyData, propertyData, propertyData, propertyData,
    ];

    // Handlers
    const handlePageChange = (event: React.ChangeEvent<any>, newPage: number)=>{
        setPage(newPage);
    }

    const Row = ({ property }: { property: Property })=>{
        const [ active, setActive ] = React.useState<boolean|undefined>(property.isEnabled);

        let colour = active ? '#90EE90' : 'red'
        const toggleActive = async ()=>{
            setLoading(true);
            
            // Update the property enabled status
            let country = property.country ? property.country : 'Nigeria';
            let preRequest: any = { ...property, isEnabled: !active, country: country };


            if (property.id){
                const { data, error } = await updateProperty({
                    request: preRequest,
                    token: localStorage.getItem('token') || undefined,
                    id: property.id
                });

                if (data) {
                    // Trigger revalidation
                    mutate('/api/properties' + `?PageNumber=${pageNumber}&PageSize=${pageSize}`);
                    setLoading(false);
                }
                if (error){
                    alert('Error');
                    setLoading(false);
                }
            }
        }
        const handleDelete = async ()=>{
            if (window.confirm('Are you sure you want to delete this property?')) {
                setLoading(true);
                let token = localStorage.getItem('token') || undefined;
                if (property?.id) {
                    const { data, error } = await deleteProperty({ token, id: property.id });
                    if (data) {
                        // Trigger revalidation
                        mutate('/api/properties' + `?PageNumber=${pageNumber}&PageSize=${pageSize}`);
                        setLoading(false);
                    }
                }
            }
        }

        if (!property.availability) {
            return null; // Do not render if not available
          }
        return(
            <ListItem divider
                sx={{ marginBottom: 2, backgroundColor: 'white', width: '100%' }}
            >
                <ListItemIcon>
                    <Box sx={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: colour }}/>
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary={property.title} />
                <Switch
                    edge="end"
                    onChange={toggleActive}
                    checked={active}
                    inputProps={{
                        'aria-labelledby': 'switch-list-label-wifi',
                    }}
                />
                <IconButton aria-label="delete" onClick={handleDelete}>
                    <DeleteIcon/>
                </IconButton>
            </ListItem>
        )
    }

    // Subs and unsubs
    const subscribe = ()=>{
        window.addEventListener('resize', ()=>{
            if (window.innerHeight < 750) {
                setItemsPerPage(7);
            }
            if (window.innerHeight > 750) {
                setItemsPerPage(9)
            }
        })
    }
    const unsubscribe = ()=>{
        window.removeEventListener('resize', ()=>{
            if (window.innerHeight < 750) {
                setItemsPerPage(7)
            }
            if (window.innerHeight > 750) {
                setItemsPerPage(9)
            }
        })
    }

    // Effect
    React.useEffect(()=>{
        subscribe();
        if(properties){
            setPageCount(Math.ceil(properties.length / itemsPerPage));
        }
        return ()=>{ unsubscribe() }
    },[testProperties, itemsPerPage])

    


    return(
        <DashboardLayout backgroundColor='rgba(0, 0, 0, 0.075)'>
            {loading && <LoadingScreen/>}
            
           <Box sx={{height: '100%' }}>
            {
                isLoading &&
                [1,2,3,4,5].map((item)=>(
                    <div className="col-xl-6 col-sm-6 col-12">
                        <Skeleton variant="rectangular" width="100%" >
                            <Row property={testProperties[0]}/>
                        </Skeleton>
                    </div>
                ))
            }
           {
               !isLoading && properties?.slice((page-1) * itemsPerPage, (page-1) * itemsPerPage + itemsPerPage)
               .map((prop)=>(
                    <Row property={prop}/>
               ))
           }
           {/** Pagination */}
           {
                (properties && properties.length > 0) &&
                <Stack spacing={2} sx={{ marginBottom: '30px', alignItems: 'center' }}>
                    <Pagination count={pageCount} 
                        page={page} onChange={handlePageChange} size={'large'}
                        variant="outlined"
                    />
                </Stack>
            }
           </Box>
        </DashboardLayout>
    )
}

export default ProductListing;
