import React, { SetStateAction, useState } from "react";
import { Button } from "@mui/material";
import { Customer } from "../../../types/Billings";
import styles from "./disco.module.scss";

interface SuccessModalProps {
  goToTransactionHistory: () => void;
  response: string;
}

const SuccessModalCard: React.FC<SuccessModalProps> = ({
  response,
  goToTransactionHistory
}) => {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.fund}>
        <span
          style={{
            alignSelf: "center",
            margin: "1em",
          }}
        >
          {response}
        </span>
        <span
          style={{
            alignSelf: "center",
            margin: "1em",
          }}
        >
          {'Hereafter you can still find your token in the transaction history table by clicking on the transaction'}
        </span>
        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__secondary-color)",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
            marginBottom: "0.5em",
          }}
          onClick={() => goToTransactionHistory()}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default SuccessModalCard;
