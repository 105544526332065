import React, { SetStateAction } from "react";
import styles from "./mortgagesuccess.module.scss";
import { Button } from "@mui/material";
import { useHistory } from "react-router";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface IsFeaturedProps {
  closeModal: () => void;
}

const MortgageSuccess = ({ closeModal }: IsFeaturedProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerCancel}>
        <span>Congratulations</span>
        <CheckCircleIcon
          fontSize="large"
          sx={{ color: "var(--ltn__secondary-color)" }}
        />
      </div>

      <div className={styles.body}>
        Your application has been submitted and is being reviewed, you will be
        contacted by our access bank mortgage partner
      </div>

      <div className={styles.buttons}>
        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__secondary-color)",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
            // paddingTop: "1.8em",
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
            width: "90%",
          }}
          onClick={() => closeModal()}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default MortgageSuccess;
