import React from "react";
import styles from "../insurance-comp.module.scss";

const PaymentSuccessful = ({
  getNewPolicy,
  goHome,
  close,
}: {
  getNewPolicy: () => void;
  goHome: () => void;
  close: () => void;
}) => {
  return (
    <div className={styles["save-cont"]}>
      <div className={styles["inner-cont"]}>
        <div
          className={styles["close-cont"]}
          role="button"
          onClick={() => close()}
        >
          <div className="icon-cancel"></div>
        </div>

        <span className={styles["save-quote-header"]}>Success!</span>

        <div className={styles["success-pay-policy"]}>
          <span className={styles["policy-txt"]}>Policy Number:</span>
          <span className={styles["policy-no"]}>090567645464</span>
        </div>
        <span className={styles["save-quote-text"]}>
          Your payment has been received. Your certificate will be sent to your
          registered email.
        </span>

        <div
          className={styles["save-quote-but-green"]}
          role="button"
          onClick={() => getNewPolicy()}
        >
          <span>Get New Policy</span>
        </div>

        <span
          className={styles["save-home"]}
          role="button"
          onClick={() => goHome()}
        >
          Go Home
        </span>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
