import React from 'react';

export interface ProductSidebarProps{
    children?: React.ReactNode | React.ReactNode[];
}

const ProductSidebar: React.FC<any> = ({ children })=>{

    return(
        <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
          { children }
        </aside>
    )
}

export default ProductSidebar;