import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IFormLinkProps extends RouteComponentProps {
    text: string;
    link: string;
    classes: string;
}

const FormLink: React.FC<IFormLinkProps> = ({ text, history, link, classes }) => (
    <div
        className={classes}
        onClick={() => {
            link && history.push(link);
        }}
    >
        {text}
    </div>
);

export default withRouter(FormLink);
