export enum PropertyType {
  "General" = "Houses",
  "StudentPackage" = "Student Package",
  "Commercial" = "Commercial Property",
  // "Land" = "Land",
  "Flats" = "Flats & Apartments",
}

export enum PropertyTypeEnum {
  General = "Houses",
  Flats = "Flats & Apartments",
  StudentPackage = "Student Package",
  Commercial = "Commercial Property",
  Land = "Land",
}

export const propertyTypeEnumKeysArray: string[] = Object.keys(
  PropertyTypeEnum
) as string[];

export interface PaginationParamsPersonalizedProperties {
  hasNext: boolean;
  hasPrevious: boolean;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}
