import React from 'react';

export interface PropertyElementProps{
    title?: string;
    children?: React.ReactNode | React.ReactNode[]
}

const PropertyElement: React.FC<PropertyElementProps> =({ title, children })=>{

    return(
        <>
        <h4 className="title-2">{title}</h4>
        { children }
        </>
    )
}

export default PropertyElement;