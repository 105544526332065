import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React, { useContext, useEffect, useState } from "react";
import { HouseHoldDetails, LandlordDetails, RentDropDown } from "../../../../types/Insurance";
import styles from "./household-form.module.scss";
import EmptyDropDown from "../../../../components/Input/EmptyDropDown";
import {
  convertFilesToBlobs,
  genderArray,
  maritalStatusArray,
  premiumPaymentFreqArr,
  religionArray,
  returnMsgFromAxiosError,
  sectorArray,
} from "../../../../utils/generalUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import PopUp from "../../../../components/Insurance/PopUp";
import ModalLayout from "../../../../components/ModalLayout";
import {
  setHouseHoldGetPolicyRes,
  setLandlordDetails,
  setRentDropValue,
} from "../../../../state/reducers/insuranceSlice";
import EmptyDropCalendar from "../../../../components/Input/EmptyDropCalendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import UploadDocs from "../../../../components/Insurance/UploadDocs";
import ToastContext from "../../../../contexts/ToastContext";
import UploadSingleButton from "../../../../components/Insurance/UploadDocs/UploadSingleButton";
import { householdCreatePolicyApi, houseHoldDocUploadApi } from "../../../../swr/insurance";
import LoadingContext from "../../../../contexts/LoadingContext";
import DisplayPop from "../../../../components/Insurance/DisplayPop";

type DocsUpload = {
  buttonText: string;
  texts: string[];
  accept: string;
  name: string;
  value: File | File[] | undefined;
  multiple: boolean;
};

const requiredFields = [
  "DateOfBirth",
  "HomeAddress",
  "Occupation",
  "Gender",
  "quoteId",
  "PremiumPaymentFreq",
  "City",
  "IdCard",
  "PassportPhoto",
  "ItemPhotos",
];

const filterCompulsory = (data: HouseHoldDetails): HouseHoldDetails => {
  const filteredData: HouseHoldDetails = { ...data };

  // Remove keys with selected: false
  for (const key in filteredData) {
    if (!requiredFields.includes(key)) {
      delete filteredData[key as keyof HouseHoldDetails];
    }
  }

  return filteredData;
};
const KYCDetailsComp = ({
  setRentStep,
  closeAllDropDowns,
  rentDropDownValue,
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
  closeAllDropDowns: () => void;
  rentDropDownValue: RentDropDown;
}) => {
  const dispatch = useDispatch();
  const { householdDetails, HouseHoldGetQuoteRes } = useSelector(
    (state: RootState) => state.insurance
  );
  const { setLoading } = useContext(LoadingContext);
  const { openError, openSuccess } = useContext(ToastContext);
  const token = localStorage.getItem("token") as string;

  const [householdDetailsReq, sethouseholdDetailsReq] = useState<HouseHoldDetails>(
    {
      Gender: 10,
      DateOfBirth: undefined,
      MaritalStatus: 10,
      Religion: 10,
      HomeAddress: "",
      City: "",
      State: "",
      Occupation: "",
      Sector: 10,
      CoverStartDate: undefined,
      Bank: "",
      AccountNumber: "",
      BVN: "",
      PremiumPaymentFreq: 10,
      PassportPhoto: undefined,
      IdCard: undefined,
      ItemPhotos: undefined,
      
    }
  );

  const checkIfAnyUndefinedOrTenOrEmptyString = (
    obj: HouseHoldDetails
  ): boolean => {
    // Check if any key is undefined
    const hasUndefined = Object.values(obj).some(
      (value) => value === undefined
    );

    // Check if any key of type number is 10
    const hasNumberTen = Object.values(obj).some(
      (value) => typeof value === "number" && value === 10
    );

    // Check if any key is an empty string
    const hasEmptyString = Object.values(obj).some(
      (value) => typeof value === "string" && value === ""
    );

    // Return true if any of the conditions are true, otherwise return false
    return hasUndefined || hasNumberTen || hasEmptyString;
  };

  const openDropDown = (val: RentDropDown) => {
    dispatch(setRentDropValue(val));
  };

  const handleTypedOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    sethouseholdDetailsReq((prev: any) => ({
      ...prev,
      [name]: value.toUpperCase(),
    }));
  };

  const handleGenderSelection = (value: string) => {
    sethouseholdDetailsReq((prev) => ({
      ...prev,
      Gender: genderArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleMaritalSelection = (value: string) => {
    sethouseholdDetailsReq((prev) => ({
      ...prev,
      MaritalStatus: maritalStatusArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleReligionSelection = (value: string) => {
    sethouseholdDetailsReq((prev) => ({
      ...prev,
      Religion: religionArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleSectorSelection = (value: string) => {
    sethouseholdDetailsReq((prev) => ({
      ...prev,
      Sector: sectorArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleDateChange = (val: any) => {
    sethouseholdDetailsReq((prev: any) => ({
      ...prev,
      DateOfBirth: val,
    }));
  };

  const handleDateChangeI = (val: any) => {
    sethouseholdDetailsReq((prev: any) => ({
      ...prev,
      CoverStartDate: val,
    }));
  };

  const returnFormattedDate = (p: Date) => {
    return new Date(p).toLocaleString("NG").split(",")[0];
  };

  useEffect(() => {
    if (Object.keys(householdDetails).length !== 0) {
      sethouseholdDetailsReq({ ...householdDetails });
    }
  }, []);

  const documents: DocsUpload[] = [
    {
      buttonText: "Upload Your Passport Photo",
      texts: [
        "Only jpg and png are allowed",
        "Large files might take longer to be processed",
        "Upload at most one photo, minimum dimension of 500px by 500px",
      ],
      accept: ".jpg, .png, .gif, .jpeg, .mp4, .3gp" ,
      name: "PassportPhoto",
      value: householdDetailsReq.PassportPhoto,
      multiple: false,
    },
    {
      buttonText: "Upload Your ID",
      texts: [
        "Only jpg, png and pdf are allowed",
        "You may only upload NIN, Voter's card, or International Passport as valid ID",
        "Large files might take longer to be processed",
        "If uploading a photo, it must be a minimum dimension of 500px by 500px",
      ],
      accept: ".jpg, .png, .gif, .jpeg, .mp4, .3gp" , 
      name: "IdCard",
      value: householdDetailsReq.IdCard,
      multiple: false,
    },
    {
      buttonText: "Upload Item Photos",
      texts: [
        "Only jpg, png and pdf are allowed",
        "Large files might take longer to be processed",
        "Upload at most 1 photo, minimum dimension of 500px by 500px.",
      ],
      accept: ".jpg, .png, .gif, .jpeg, .mp4, .3gp" ,
      name: "ItemPhotos",
      value: householdDetailsReq.ItemPhotos,
      multiple: true,
    },
  ];

  const handleDocsChange= (name: string, value: File) => {
    sethouseholdDetailsReq((prev) => ({
        ...prev,
        [name]: value,
    }));
  }

  const handlePaymentFreqSelection = (value: string) => {
    sethouseholdDetailsReq((prev) => ({
      ...prev,
      PremiumPaymentFreq: premiumPaymentFreqArr.indexOf(value),
    }));
    closeAllDropDowns();
  };


  const removeDoc = (name: string, index?: number) => {
    const fileArray = householdDetailsReq[name as keyof HouseHoldDetails];
    if (index !== undefined && Array.isArray(fileArray)) {
      const toBeRemoved = fileArray[index];
      const newFileArray = fileArray.filter((arr) => arr !== toBeRemoved);
      sethouseholdDetailsReq((prev) => ({
        ...prev,
        [name]: newFileArray,
      }));
      return;
    }
    sethouseholdDetailsReq((prev) => ({
      ...prev,
      [name]: undefined,
    }));
  };


  // const uploadFile = async ({
  //   file,
  //   fileName,
  // }: {
  //   file: File | File[];
  //   fileName: string;
  // }) => {
  //   const blobReq = await convertFilesToBlobs(file)
  //   const { error } = await houseHoldDocUploadApi(
  //     {
  //       FileName: fileName,
  //       ImageFile: blobReq,
  //       QuoteInfoId: HouseHoldGetQuoteRes?.quoteId,
  //     },
  //     token
  //   );

  //   if (error) {
  //     setDocsUploadErr(true);
  //   }
  // };

  // const uploadAllDocs = async (
  //   files: {
  //     file: File | File[];
  //     fileName: string;
  //   }[]
  // ) => {
  //   setLoading(true);
  //   const uploadPromises = files.map((file) => uploadFile(file));

  //   return Promise.all(uploadPromises)
  //     .then(() => {
  //       if (docsUploadErr === true) {
  //         setLoading(false);
  //         return false;
  //       }
  //       setLoading(false);
  //       return true;
  //     }) // All files uploaded successfully
  //     .catch((error: any) => {
  //       setLoading(false);
  //       openError(returnMsgFromAxiosError(error, "an error occurred uploading documents"));
  //       return false; // Return false if any upload fails
  //     });
  // };

  const uploadDocumentCall = async (fileName: string, fileArray: File[]) => {
    const blobArray = await convertFilesToBlobs(fileArray);
    const { data, error } = await houseHoldDocUploadApi(
      {
        FileName: fileName,
        ImageFile: blobArray,
        QuoteInfoId: HouseHoldGetQuoteRes?.quoteId,
      },
      token
    );

    if (data) {
      setLoading(false);
      dispatch(setRentStep(4));
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "an error occurred uploading documents")
      );

      return;
    }

    setLoading(false);
    openError("an error occurred uploading documents");
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { data, error } = await householdCreatePolicyApi(
      {
        dateofBirth: householdDetailsReq.DateOfBirth,
        address: householdDetailsReq.HomeAddress,
        occupation: householdDetailsReq.Occupation,
        gender: householdDetailsReq.Gender,
        quoteId: HouseHoldGetQuoteRes?.quoteId,
        premiumPaymentFrequency:
        householdDetailsReq.PremiumPaymentFreq === 0 ? "S" : "A",
      },
      token
    );

    if (data) {
      dispatch(setHouseHoldGetPolicyRes(data.data));
      openSuccess(data.message ? data.message : "Policy created successfully");
      if(Array.isArray(householdDetailsReq.ItemPhotos)){
        uploadDocumentCall(
          "HouseHoldDocs",
          [
            ...householdDetailsReq.ItemPhotos,
            householdDetailsReq.IdCard,
            householdDetailsReq.PassportPhoto as File,
          ]);
        return;
      }
      uploadDocumentCall(
        "HouseHoldDocs",
        [
          //@ts-ignore
          householdDetailsReq.ItemPhotos,
          householdDetailsReq.IdCard as File,
          householdDetailsReq.PassportPhoto as File,
        ]);
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "An error occurred creating policy")
      );
      return false;
    }

    setLoading(false);
    openError("An error occurred creating policy");
    return false;
  };

  const saveAndContinue = async () => {
    const isValidationErr =
      checkIfAnyUndefinedOrTenOrEmptyString(householdDetailsReq);
    // if (isValidationErr === true) {
    //   openError("Please fill all required fields");
    //   return;
    // }
    dispatch(setLandlordDetails(householdDetailsReq));
    handleSubmit();
  };


  const goBack = () => {
    dispatch(setRentStep(2));
  };

  return (
    <>
      <div className={styles.form}>
        {/* <div className={styles.header}>
        </div> */}

        <div className={styles.columngrid}>
          <div className={styles.input2column}>
            <span>
              Gender<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  genderArray[
                    householdDetailsReq.Gender ?? householdDetails.Gender
                  ]
                }
                click={() => openDropDown("Gender")}
              />
            </div>
          </div>
          <div className={styles.input2column}>
            <span>
              Date Of Birth<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop-date"]}>
              <span>
                {householdDetailsReq.DateOfBirth
                  ? returnFormattedDate(householdDetailsReq.DateOfBirth)
                  : householdDetails.DateOfBirth
                  ? returnFormattedDate(householdDetails.DateOfBirth)
                  : ""}
              </span>
              <EmptyDropCalendar
                select={householdDetailsReq.DateOfBirth}
                click={handleDateChange}
              />

              <ArrowDropDownIcon
                sx={{
                  position: "absolute",
                  right: "5%",
                  fontSize: 35,
                  zIndex: 50,
                }}
              />
            </div>
          </div>
          {/* <div className={styles.inputcolumn}>
            <span>
              Marital Status<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  maritalStatusArray[
                    householdDetailsReq.MaritalStatus ||
                      householdDetails.MaritalStatus
                  ]
                }
                click={() => openDropDown("MaritalStatus")}
              />
            </div>
          </div> */}
        </div>

        {/* <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Religion<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  religionArray[
                    householdDetailsReq.Religion ?? householdDetails.Religion
                  ]
                }
                click={() => openDropDown("Religion")}
              />
            </div>
          </div>
        </div> */}

        <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Home Address<span className={styles.red}>*</span>
            </span>
            <input
              name="HomeAddress"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={
                householdDetailsReq.HomeAddress || householdDetails.HomeAddress
              }
              defaultValue={householdDetails.HomeAddress}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              Occupation<span className={styles.red}>*</span>
            </span>
            <input
              name="Occupation"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={
                householdDetailsReq.Occupation || householdDetails.Occupation
              }
              defaultValue={householdDetails.Occupation}
            />
          </div>
          <div
            className={styles["inputcolumn-65"]}
            style={{ position: "relative" }}
          >
            <span>
              Premium Payment Frequency<span className={styles.red}>*</span>
            </span>
            <div
              className={styles["exclam-but"]}
              onClick={() => openDropDown("DisplayPopHouseHold")}
              role="button"
            >
              <span>!</span>
            </div>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  premiumPaymentFreqArr[
                    householdDetailsReq.PremiumPaymentFreq ??
                    householdDetailsReq.PremiumPaymentFreq
                  ]
                }
                click={() => openDropDown("PremiumPaymentFreq")}
              />
            </div>
          </div>
        </div>

        <span className={styles["doc-header"]}>
          Media<span className={styles.red}>*</span>
        </span>
        {documents.map((doc) => (
            <UploadSingleButton 
            key={doc.buttonText}
            buttonTxt={doc.buttonText} 
            handleDocsChange={handleDocsChange} 
            accept={doc.accept} 
            instructions={doc.texts}
            name={doc.name} 
            removeDoc={removeDoc} 
            docValue={doc.value}
            multiple={doc.multiple}
            />
        ))}

        <div className={styles["container-bottom-butt"]}>
          <span onClick={() => goBack()} role="button">
            Back
          </span>

          <div
            className={styles["continue-butt"]}
            role="button"
            onClick={() => saveAndContinue()}
          >
            <span>Save & Continue</span>
          </div>
        </div>
      </div>
      <ModalLayout modalOpen={rentDropDownValue === "PremiumPaymentFreq"}>
        <PopUp
          options={premiumPaymentFreqArr}
          click={handlePaymentFreqSelection}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "DisplayPopHouseHold"}>
        <DisplayPop closeModal={closeAllDropDowns} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Gender"}>
        <PopUp options={genderArray} click={handleGenderSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "MaritalStatus"}>
        <PopUp options={maritalStatusArray} click={handleMaritalSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Religion"}>
        <PopUp options={religionArray} click={handleReligionSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Sector"}>
        <PopUp options={sectorArray} click={handleSectorSelection} />
      </ModalLayout>
    </>
  );
};

export default KYCDetailsComp;
