import Axios, { AxiosError, AxiosResponse } from "axios";
import { request } from "http";
import useSwr from "swr";
import { GeneralOperationResult } from "../types/GeneralResponse";
import {
  BankCodes,
  BillPaymentHistoryResponse,
  ChangePinRequest,
  ChangePinResponse,
  ConfirmPaymentI,
  ConfirmPaymentResult,
  CreateWalletRequest,
  CreateWalletResponse,
  FundWalletRequest,
  FundWalletResponse,
  GenerateOtpResponse,
  GetWalletDetailsResponse,
  NameEnquiryRequest,
  ResetWalletPinRequest,
  ResetWalletPinResponse,
  TransactionHistoryRequest,
  TransactionHistoryResponse,
  WalletToBankTransferRequest,
  WalletToBankTransferResponse,
  WalletToWalletTransferRequest,
  WalletToWalletTransferResponse,
} from "../types/Wallet";
import api from "../axios";

const baseUrl = process.env.REACT_APP_BASE_URL

const token = localStorage.getItem("token") as string;
const walletId = localStorage.getItem("walletId") as string;

export const createWallet = (request: CreateWalletRequest, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const willCreateWallet = new Promise<
    GeneralOperationResult<CreateWalletResponse>
  >((resolve) => {
    api.post(`/api/Payment/createWallet`, request, config)
      .then((response: AxiosResponse<CreateWalletResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });

  return willCreateWallet;
};

export const getWalletDetails = ( token: string) => {
  const willGetWalletDetails = new Promise<
    GeneralOperationResult<GetWalletDetailsResponse>
  >((resolve) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token && token}`,
      },
    };

    Axios.get(
      baseUrl + '/api/Payment/getWalletDetailsByUser',
      config
    )
      .then((response: AxiosResponse<GetWalletDetailsResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });

  return willGetWalletDetails;
};

export const fundWallet = (request: FundWalletRequest) => {
  const willFundWallet = new Promise<
    GeneralOperationResult<FundWalletResponse>
  >((resolve) => {
    Axios.post(baseUrl + `/api/Payment/fundWallet`, request)
      .then((response: AxiosResponse<FundWalletResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });

  return willFundWallet;
};

export const getTransactionHistoryByWalletId = (
  pageNumber: number,
  pageSize: any,
  walletId: string,
  token: string
) => {
  const TransactionHistory = new Promise<
    GeneralOperationResult<TransactionHistoryResponse>
  >((resolve) => {
    api.get(
        `/api/Payment/TransactionHistory/${walletId}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${token && token}`,
        },
      }
    )
      .then((response: AxiosResponse<TransactionHistoryResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return TransactionHistory;
};

interface ConfirmPayment {
  response_code: string;
  response_message: string;
  valid: boolean;
}

export interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

export const confirmPayment = async (
  email: string,
  pin: string,
  token: string
): Promise<ConfirmPayment> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await api.post(
    '/api/Payment/validatepin',
    { email, pin },
    config
  );

  return response.data;
};

export const ResetWalletPin = (request: ResetWalletPinRequest) => {
  const config = {
    headers: {
      Authorization: `Bearer ${request && request.token && request.token}`,
    },
  };
  const willResetWalletPin = new Promise<
    GeneralOperationResult<ResetWalletPinResponse>
  >((resolve) => {
    api.post(`/api/Payment/resetpin`, request, config)
      .then((response: AxiosResponse<ResetWalletPinResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return willResetWalletPin;
};

export const WalletToAccountTransfer = (
  request: WalletToBankTransferRequest,
  token: string,
  pin: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const AccountTransfer = new Promise<
    GeneralOperationResult<WalletToBankTransferResponse>
  >((resolve) => {
    api.post(`/api/Payment/WalletToBank?pin=${pin}`, request, config)
      .then((response: AxiosResponse<WalletToBankTransferResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return AccountTransfer;
};

export const WalletToWalletTransfer = (
  request: WalletToWalletTransferRequest,
  token: string,
  pin: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const WalletTransfer = new Promise<
    GeneralOperationResult<WalletToWalletTransferResponse>
  >((resolve) => {
    api.post(`/api/Payment/WalletTransfer?pin=${pin}`, request, config)
      .then((response: AxiosResponse<WalletToWalletTransferResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return WalletTransfer;
};

export const generateOtp = async (
  token: string
): Promise<GenerateOtpResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.post(
    `/api/Payment/generateOTP`,
    {},
    config
  );
  return data;
};

export const verifyOtp = async (
  otp: string,
  email: string,
  token: string
): Promise<GenerateOtpResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.post(
    `/api/Payment/validateOTP`,
    { otp, email },
    config
  );
  return data;
};

export const ChangePin = async (request: ChangePinRequest, token: string) => {
  const ChangeUserPin = new Promise<GeneralOperationResult>((resolve) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api.post(`/api/Payment/ChangePin`, request, config)
      .then((response: AxiosResponse<ChangePinResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });
  return ChangeUserPin;
};

export const getBankCodes = async (token: string): Promise<BankCodes> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await api.get(
    `/api/Payment/GetBankCodes`,
    config
  );
  return data;
};

export const GetBillPaymentDescription = async (req: {paymentReference: string, token: string}): Promise<BillPaymentHistoryResponse | any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${req.token}`
      },
    };
    const response = await api.get(
      `/api/UtilityBills/GetBillPaymentHistory?paymentReference=${req.paymentReference}`,
      config
    );

    return response.data;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

export const NameEnquiry = async (req: {requestBody: NameEnquiryRequest, token: string}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${req.token}`
      },
    };
    const response = await api.post(
      `/api/Payment/NameEnquiry`,
      req.requestBody,
      config
    );
    return response.data;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

export const confirmPaymentApi = async (
  propertyId: string,
  token: string
) => {
  const confirmPayment = new Promise<ConfirmPaymentResult>((resolve) => {
    api
      .post(
        `/api/Payment/ConfirmPayment?PropertyId=${propertyId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<ConfirmPaymentI>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return confirmPayment;
};

