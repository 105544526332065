import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AccordionItem from '../../components/AccordionItem';
import { FAQItem, faqs } from '../../data/faqs';

const FAQSection: React.FC<any> = ()=>{

    // Reneder helper
    const renderFaqs = (faqs: FAQItem[])=>{
        return faqs.map((item, index)=>{

            if (item.custom){
                return(
                    <AccordionItem key={`acd${index}`}
                        title={item.title}
                        generalPurpose
                    >
                        { item.custom }
                    </AccordionItem>
                )
            }

            if (item.list){
                return(
                    <AccordionItem key={`acd${index}`}
                        title={item.title}
                        generalPurpose
                    >
                        <ul>
                            {
                                item.list.map((listItem, i)=>(
                                    <li key={`lst${i}`}>
                                        {listItem}
                                    </li>
                                ))
                            }
                        </ul>
                    </AccordionItem>
                )
            }

            return(
                <AccordionItem key={`acd${index}`}
                    title={item.title}
                    generalPurpose
                >
                    <p>{ item.content }</p>
                </AccordionItem>
            )
        })
    }

    const handleScrollToTop = () => {
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        handleScrollToTop()
    }, [faqs.length])

    // Custom FAQs
    const otherFaqs: FAQItem[] = [
        {
            title: 'Are there any benefits of using the platform?',
            custom: (
                <>
                <p style={{color: 'black'}}>Definitely, we love to make our customers happy. Some of the incentives include:</p>
                <ul>
                    <li>Cashback 5% of rent amount capped at N100,000 for the first 5 customers to rent an apartment (for the next 2 years).</li>
                    <li>Landlords with high numbers of rented properties monthly would get properties displayed on featured properties for a month without charge.</li>
                    <li>Free Electricity token of N5,000 for the first 20 customers to make any bills payment worth 20,000.</li>
                    <li>Free 3 months DSTV/GOTV subscription for the 5 customers that pay their rent on the platform.</li>
                    <li>HomeBuddy Branded household items to be delivered to all customers who rent/ pay bills or create a savings plan  within the first three months as well as all landlords who list on the platform.</li>
                </ul>
                </>
            )
        },
        {
            title: 'Property Owner/Landlord',
            custom: (
                <>
                <ol>
                    <li>Create a user profile on our website</li>
                    <li>To complete your KYC, please provide evidence of property ownership or permission to list properties on the platform</li>
                    <li>List properties on the platform</li>
                </ol>
                <p>Once the listed properties are approved and confirmed fit by our Audit team, we would send you a confirmation of membership</p>
                </>
            )
        },
        
    ]

    return(
        <div className="ltn__faq-area mb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="ltn__faq-inner ltn__faq-inner-2">
                            <div id="accordion_2">

                                {/** Accordions */}
                                { renderFaqs(faqs) }
                                { renderFaqs(otherFaqs) }
                            </div>
                            {/* <div className="need-support text-center mt-100">
                                <h2>Still need help? Reach out to support 24/7:</h2>
                                <div className="btn-wrapper mb-30">
                                    <Link to="/contact" className="theme-btn-1 btn">Contact Us</Link>
                                </div>
                                <h3 className="animated fadeIn"><i className="fas fa-phone"></i> +0123-456-789</h3>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <aside className="sidebar-area ltn__right-sidebar">
                            
                            {/** Newsletter Widget */}
                            <div className="widget ltn__search-widget ltn__newsletter-widget">                            
                                <h6 className="ltn__widget-sub-title">// subscribe</h6>
                                <h4 className="ltn__widget-title">Get Newsletter</h4>
                                <form action="#">
                                    <input type="text" name="search" placeholder="Search" autoComplete="off"/>
                                    <button type="submit"><i className="fas fa-search"></i></button>
                                </form>
                                <div className="ltn__newsletter-bg-icon">
                                    <i className="fas fa-envelope-open-text"></i>
                                </div>
                            </div>
                            
                            {/**  Banner Widget */}
                            {/* <div className="widget ltn__banner-widget">
                                <a href="shop.html"><img src="img/banner/banner-3.jpg" alt="Banner Image"/></a>
                            </div> */}

                        </aside>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQSection;