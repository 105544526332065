export enum Cities{
    Aba, Abakaliki,Abeokuta, Abonnema, Abuja, "Ado Ekiti",
    Afikpo,Agbor, Agulu,Aku,Akure,Amaigbo,Ankpa,Asaba,Auchi,Awka,Azare,Bama,Bauchi,Bende,
    "Benin City",
    Bida,
    "Birin Kebbi",
    Biu,
    Buguma,
    Calabar,
    Damaturu,
    Daura,
    Dutse,
    Ede,
    Effium,
    "Effon Alaiye",
    "Eha Amufu",
    Ejigbo,
    Ekpoma,
    Enugu,
    "Enugu Ukwu",
    Epe,
    Etiti,
    "Ezza Inyimagu",
    Funtua,
    Gamboru,
    Gashua,
    Gboko,
    Gbongan,
    Gombe,
    Gusau,
    Hadejia,
    Ibadan,
    Idah,
    Ife,
    Ifo,
    Ifon,
    Igboho,
    "Igbo Ora",
    "Igbo Ukwu",
    Ihiala,
    "IjebucIgbo",
    "Ijebu Ode",
    Ijero,
    Ikare,
    Ikeja,
    Ikerre,
    Ikire,
    Ikirun,
    Ikom,
    Ikorodu,
    "Ikot Ekpene",
    "Ila Orangun",
    "Ilawe Ekiti",
    Ilesha,
    Ilobu,
    Ilorin,
    Inisa,
    Ise,
    Iseyin,
    Ishieke,
    Iwo,
    Jalingo,
    Jimeta,
    Jos,
    Kaduna,
    Kafanchan,
    Kagoro,
    Kano,
    Katsina,
    "Kaura Namoda",
    Keffi,
    Kishi,
    Kontagora,
    Kuroko,
    Lafia,
    Lagos,
    Lokoja,
    Maiduguri,
    Makurdi,
    Malumfashi,
    Minna,
    Modakeke,
    Mubi,
    Nguru,
    Nkpor,
    Nnewi,
    Nsukka,
    Numan,
    Obosi,
    Offa,
    Ogaminan,
    Ogbomosho,
    Ohafia,
    "Oka Akoko",
    Okene,
    Okigwi,
    Okitipupa,
    Okpogho,
    Okrika,
    Ondo,
    Onitsha,
    Oron,
    Oshogbo,
    Otukpo,
    Owerri,
    Owo,
    Oyo,
    Ozubulu,
    "Port Harcourt",
    Sagamu,
    "Sango Otta",
    Sapele,
    Shaki,
    Sokoto,
    Suleja,
    Uga,
    Ugep,
    Ughelli,
    Umuahia,
    Uromi,
    Uyo,
    Warri,
    Wukari,
    Yenagoa,
    Yola,
    Zaria
}