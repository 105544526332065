// react-router components
import ProtectedRoute from './components/ProtectedRoute';

// routes
import routes from './routes';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import SessionContext from './contexts/SessionContext';
import LoadingScreen from './components/LoadingScreen';
import LoadingContext from './contexts/LoadingContext';
import ToastContext, { ToastContextProps } from './contexts/ToastContext';
import CustomToast from './components/CustomToast';
import ProtectedAuthRoute from './components/ProtectedAuthRoute';
import Stack from '@mui/material/Stack';
import { generateId } from './utils/generalUtils';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import { ContextProvider } from './contexts/StateContext';
import { RouteProps } from 'react-router-dom';
// import { Route } from 'react-router';
import { Switch, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export interface IRoutes {
    type: string;
    name: string;
    key: string;
    route: string;
    component: React.ComponentType<any>;
    noCollapse: boolean;
    protected?: boolean;
    protectedAuth?: boolean;
    redirectPath?: string;
    privileges?: string[];
    
}

interface CustomAlert{
    message?: string;
    severity?: AlertColor;
    id: number;
}

const App: React.FC =()=>  {

    // State
    const [isExpired, setIsExpired] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [successOpen, setSuccessOpen] = React.useState<boolean>(false);
    const [successMessage, setSuccessMessage] = React.useState<string>('');
    const [errorOpen, setErrorOpen] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [alerts, setAlerts] = React.useState<CustomAlert[]>([]);

    // ID generator
    const idGeneratorObject: Generator<number> = generateId();

    // Handlers
    const handleClose = ()=>{
        setIsExpired(false);
    }
    const handleSuccessclose = ()=>{
        setSuccessOpen(false);
    }
    const handleErrorclose = ()=>{
        setErrorOpen(false);
    }
    const handleAlertClose = (id: number)=>{
        let filteredAlerts = alerts.filter((a)=> a.id !== id);
        setAlerts(filteredAlerts);
    }
    const handleClickAway = ()=>{
        setAlerts([]);
    }

    // Context values
    const toastValue: ToastContextProps = {
        openSuccess: (message)=>{
            setAlerts((prevAlerts)=>([
                ...prevAlerts,
                {
                    message: message,
                    severity: 'success',
                    id: idGeneratorObject.next().value
                }
            ]))
            setSuccessMessage(message);
            setSuccessOpen(true);
        },

        openError: (message)=>{
            //console.log(alerts)
            setAlerts((prevAlerts)=>([
                ...prevAlerts,
                {
                    message: message,
                    severity: 'error',
                    id: idGeneratorObject.next().value
                }
            ]))
            // setErrorMessage(message);
            // setErrorOpen(true);
        },
    }

    // Effect
    React.useEffect(()=>{
        if (isExpired) {
            localStorage.removeItem('paymentState');
            localStorage.removeItem('token');
            localStorage.removeItem('homezone_wallet')
        }
    }, [isExpired])


    const getRoutes = (allRoutes: IRoutes[]) =>
        allRoutes.map((route) => {
            if (route.route) {
                if (route.protected) {
                    return <ProtectedRoute exact path={route.route} render={() => <>{route}</>} component={route.component as any} key={route.key} 
                        redirectPath={route.redirectPath} privileges={route.privileges}
                    />
                }

                if (route.protectedAuth) {
                    return <ProtectedAuthRoute exact path={route.route} component={route.component as any} key={route.key} 
                        redirectPath={route.redirectPath}
                    />
                }
                
                return <Route exact path={route.route} component={route.component as any} key={route.key} />;
            }
            return null;
        });

    return (
        <>
        { loading && <LoadingScreen/>}
        <div className="body-wrapper">
        {
            alerts.length > 0 &&
            <ClickAwayListener onClickAway={handleClickAway}>
                <Stack 
                    sx={{ 
                        position: 'fixed',
                        zIndex: 1000000,
                        padding: '1.5%',
                        right: 0
                    }} 
                    spacing={2}
                >
                    {
                        alerts.map((a)=>(
                            <Grow in key={a.id}>
                                <Alert 
                                    severity={a.severity}
                                    onClose={()=> handleAlertClose(a.id)}
                                >
                                    { a.message }
                                </Alert>
                            </Grow>
                        ))
                    }
                </Stack>
            </ClickAwayListener>
        }

            <CustomToast
                successOpen={successOpen} successMessage={successMessage} onSuccessClose={handleSuccessclose}
                errOpen={errorOpen} errMessage={errorMessage} onErrClose={handleErrorclose}
            />
            <Snackbar
                open={isExpired}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handleClose}
            >
                <Alert severity="info" onClose={handleClose}>
                    {'Session Timed out'}
                </Alert>
            </Snackbar>
            <ToastContainer />
            <SessionContext.Provider value={{ isExpired, setIsExpired }}>
                <LoadingContext.Provider value={{ loading, setLoading }}>
                    <ToastContext.Provider value={toastValue}>
                        <ContextProvider>
                        <Switch>
                            {getRoutes(routes)}
                            <Redirect from="*" to="/notfound" />
                        </Switch>
                        </ContextProvider>
                    </ToastContext.Provider>
                </LoadingContext.Provider>
            </SessionContext.Provider>
        </div>
        </>
    );
}



export default App;
