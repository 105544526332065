import React from 'react';
import CallToAction from '../CallToAction';
import Footer from '../Footer';
import footerData from '../Footer/dataDefault';
import Header from '../Header';
import SessionCheck from '../SessionCheck';

export interface LayoutProps{
    children: React.ReactNode;
    showHeader?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children })=>{
    return(
        <div className={`d-flex flex-column justify-content-between min-vh-100`}>
            <SessionCheck/>
            
            {/* Aligned to the top */}
            <div className="flex-grow-1">
                <Header/>
                <div>{children}</div>
            </div>

            {/* Aligned to the bottom */}
            <CallToAction/>
            <Footer {...footerData} />
        </div>
    )
}

export default Layout;