import React from "react";
import Layout from "../../components/Layout";
import ScrollToTop from "../../components/ScrollToTop";
import SessionCheck from "../../components/SessionCheck";
import NavHeader from "../../components/NavHeader";
import SavedProperties from "../../sections/SavedProperties";

const Index = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader pageTitle="Saved Listings" routeName="savedProperties" />
      <SavedProperties />
    </Layout>
  );
};

export default Index;
