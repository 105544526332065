import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React, { useContext, useEffect, useState } from "react";
import {
  HomeOwnersDetails,
  LandlordDetails,
  RentDropDown,
} from "../../../../types/Insurance";
import styles from "./home-owners.module.scss";
import EmptyDropDown from "../../../../components/Input/EmptyDropDown";
import {
  convertFilesToBlobs,
  genderArray,
  maritalStatusArray,
  premiumPaymentFreqArr,
  religionArray,
  returnMsgFromAxiosError,
  sectorArray,
} from "../../../../utils/generalUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import PopUp from "../../../../components/Insurance/PopUp";
import ModalLayout from "../../../../components/ModalLayout";
import {
  setGetAQuoteReq,
  setHomeOwnerGetPolicyRes,
  setLandlordDetails,
  setRentDropValue,
} from "../../../../state/reducers/insuranceSlice";
import EmptyDropCalendar from "../../../../components/Input/EmptyDropCalendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UploadDocs from "../../../../components/Insurance/UploadDocs";
import ToastContext from "../../../../contexts/ToastContext";
import UploadSingleButton from "../../../../components/Insurance/UploadDocs/UploadSingleButton.tsx";
import DisplayPop from "../../../../components/Insurance/DisplayPop";
import {
  homeOwnersCreatePolicyApi,
  homeOwnersDocUploadApi,
} from "../../../../swr/insurance";
import LoadingContext from "../../../../contexts/LoadingContext";

type DocsUpload = {
  buttonText: string;
  texts: string[];
  accept: string;
  name: string;
  value: File | File[] | undefined;
  multiple: boolean;
};

const requiredFields = [
  "DateOfBirth",
  "HomeAddress",
  "Occupation",
  "Gender",
  "quoteId",
  "PremiumPaymentFreq",
  "City",
  "PassportPhoto",
  "ItemPhotos",
];

const filterCompulsory = (data: HomeOwnersDetails): HomeOwnersDetails => {
  const filteredData: HomeOwnersDetails = { ...data };

  for (const key in filteredData) {
    if (!requiredFields.includes(key)) {
      delete filteredData[key as keyof HomeOwnersDetails];
    }
  }

  return filteredData;
};

const LandlordDetailsComp = ({
  setRentStep,
  closeAllDropDowns,
  rentDropDownValue,
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
  closeAllDropDowns: () => void;
  rentDropDownValue: RentDropDown;
}) => {
  const dispatch = useDispatch();
  const { homeOwnersDetails, HomeOwnerGetQuoteRes, HomeOwnerGetPolicyRes } =
    useSelector((state: RootState) => state.insurance);
  const { setLoading } = useContext(LoadingContext);
  const { openError, openSuccess } = useContext(ToastContext);
  const token = localStorage.getItem("token") as string;

  const [homeOwnersDetailsReq, setHomeOwnersDetailsReq] =
    useState<HomeOwnersDetails>({
      Gender: 10,
      DateOfBirth: undefined,
      MaritalStatus: 10,
      Religion: 10,
      HomeAddress: "",
      City: "",
      State: "",
      Occupation: "",
      Sector: 10,
      CoverStartDate: undefined,
      PremiumPaymentFreq: 10,
      Bank: "",
      AccountNumber: "",
      BVN: "",
      PassportPhoto: undefined,
      ItemPhotos: undefined,
    });

  const checkIfAnyUndefinedOrTenOrEmptyString = (
    obj: HomeOwnersDetails
  ): boolean => {
    // Check if any key is undefined
    obj = filterCompulsory(obj);
    const hasUndefined = Object.values(obj).some(
      (value) => value === undefined
    );

    // Check if any key of type number is 10
    const hasNumberTen = Object.values(obj).some(
      (value) => typeof value === "number" && value === 10
    );

    // Check if any key is an empty string
    const hasEmptyString = Object.values(obj).some(
      (value) => typeof value === "string" && value === ""
    );

    // Return true if any of the conditions are true, otherwise return false
    return hasUndefined || hasNumberTen || hasEmptyString;
  };

  const openDropDown = (val: RentDropDown) => {
    dispatch(setRentDropValue(val));
  };

  const handleTypedOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setHomeOwnersDetailsReq((prev: any) => ({
      ...prev,
      [name]: value.toUpperCase(),
    }));
  };

  const handleGenderSelection = (value: string) => {
    setHomeOwnersDetailsReq((prev) => ({
      ...prev,
      Gender: genderArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handlePaymentFreqSelection = (value: string) => {
    setHomeOwnersDetailsReq((prev) => ({
      ...prev,
      PremiumPaymentFreq: premiumPaymentFreqArr.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleMaritalSelection = (value: string) => {
    setHomeOwnersDetailsReq((prev) => ({
      ...prev,
      MaritalStatus: maritalStatusArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleReligionSelection = (value: string) => {
    setHomeOwnersDetailsReq((prev) => ({
      ...prev,
      Religion: religionArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleSectorSelection = (value: string) => {
    setHomeOwnersDetailsReq((prev) => ({
      ...prev,
      Sector: sectorArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleDateChange = (val: any) => {
    setHomeOwnersDetailsReq((prev: any) => ({
      ...prev,
      DateOfBirth: val,
    }));
  };

  const returnFormattedDate = (p: Date) => {
    return new Date(p).toLocaleString("NG").split(",")[0];
  };

  useEffect(() => {
    if (Object.keys(homeOwnersDetails).length !== 0) {
      setHomeOwnersDetailsReq({ ...homeOwnersDetails });
    }
  }, []);

  const documents: DocsUpload[] = [
    {
      buttonText: "Upload Your Passport Photo",
      texts: [
        "Only jpg and png are allowed",
        "Large files might take longer to be processed",
        "Upload at most one photo, minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.jpeg,.png",
      name: "PassportPhoto",
      value: homeOwnersDetailsReq.PassportPhoto,
      multiple: false,
    },
    {
      buttonText: "Upload Items Photos",
      texts: [
        "Only jpg, and png are allowed",
        "Large files might take longer to be processed",
        "If uploading a photo, it must be a minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.png,.jpeg",
      name: "ItemPhotos",
      value: homeOwnersDetailsReq.ItemPhotos,
      multiple: true,
    },
  ];

  const handleDocsChange = (name: string, value: File) => {
    if (name === "ItemPhotos") {
      setHomeOwnersDetailsReq((prev) => ({
        ...prev,
        ItemPhotos:
          prev.ItemPhotos !== undefined ? [...prev.ItemPhotos, value] : [value],
      }));
      return;
    }

    setHomeOwnersDetailsReq((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const removeDoc = (name: string, index?: number) => {
    const fileArray = homeOwnersDetailsReq[name as keyof HomeOwnersDetails];
    if (index !== undefined && Array.isArray(fileArray)) {
      const toBeRemoved = fileArray[index];
      const newFileArray = fileArray.filter((arr) => arr !== toBeRemoved);
      setHomeOwnersDetailsReq((prev) => ({
        ...prev,
        [name]: newFileArray,
      }));
      return;
    }
    setHomeOwnersDetailsReq((prev) => ({
      ...prev,
      [name]: undefined,
    }));
  };

  const uploadDocumentCall = async (fileName: string, fileArray: File[]) => {
    const blobArray = await convertFilesToBlobs(fileArray);
    const { data, error } = await homeOwnersDocUploadApi(
      {
        FileName: fileName,
        ImageFile: blobArray,
        QuoteInfoId: HomeOwnerGetQuoteRes?.quoteId,
      },
      token
    );

    if (data) {
      setLoading(false);
      dispatch(setRentStep(4));
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "an error occurred uploading documents")
      );

      return;
    }

    setLoading(false);
    openError("an error occurred uploading documents");
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (HomeOwnerGetPolicyRes === undefined) {
      const { data, error } = await homeOwnersCreatePolicyApi(
        {
          dateofBirth: homeOwnersDetailsReq.DateOfBirth,
          address: homeOwnersDetailsReq.HomeAddress,
          occupation: homeOwnersDetailsReq.Occupation,
          gender: homeOwnersDetailsReq.Gender,
          quoteId: HomeOwnerGetQuoteRes?.quoteId,
          premiumPaymentFrequency:
            homeOwnersDetailsReq.PremiumPaymentFreq === 0 ? "S" : "A",
        },
        token
      );

      if (data) {
        dispatch(setHomeOwnerGetPolicyRes(data.data));
        if (Array.isArray(homeOwnersDetailsReq.ItemPhotos)) {
          uploadDocumentCall("HomeOwnersDocs", [
            ...homeOwnersDetailsReq.ItemPhotos,
            homeOwnersDetailsReq.PassportPhoto as File,
          ]);
          return;
        }
        uploadDocumentCall("HomeOwnersDocs", [
          //@ts-ignore
          homeOwnersDetailsReq.ItemPhotos,
          homeOwnersDetailsReq.PassportPhoto as File,
        ]);
        return;
      }

      if (error) {
        setLoading(false);
        openError(
          returnMsgFromAxiosError(error, "An error occurred creating policy")
        );
        return;
      }
    } else {
      if (
        homeOwnersDetailsReq.PassportPhoto === undefined ||
        homeOwnersDetailsReq.ItemPhotos === undefined
      ) {
        openError("Please pick item and passport photos");
        return;
      }
      if (Array.isArray(homeOwnersDetailsReq.ItemPhotos) === true) {
        uploadDocumentCall("HomeOwnersDocs", [
          ...homeOwnersDetailsReq.ItemPhotos,
          homeOwnersDetailsReq.PassportPhoto as File,
        ]);
      } else {
        uploadDocumentCall("HomeOwnersDocs", [
          //@ts-ignore
          homeOwnersDetailsReq.ItemPhotos,
          homeOwnersDetailsReq.PassportPhoto as File,
        ]);
      }
    }
    setLoading(false);
    openError("An error occurred creating policy");
    return;
  };

  const saveAndContinue = async () => {
    const isValidationErr =
      checkIfAnyUndefinedOrTenOrEmptyString(homeOwnersDetailsReq);
    if (isValidationErr === true) {
      openError("Please fill all required fields");
      return;
    }
    dispatch(setLandlordDetails(homeOwnersDetailsReq));
    handleSubmit();
  };

  const goBack = () => {
    dispatch(setRentStep(2));
  };

  return (
    <>
      <div className={styles.form}>
        {/* <div className={styles.header}>
          <div className={styles.headerblock}></div>
          <div className={styles.headertext}>Landlord Details</div>
        </div> */}

        <div className={styles.columngrid}>
          <div className={styles.input2column}>
            <span>
              Gender<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  genderArray[
                    homeOwnersDetailsReq.Gender ?? homeOwnersDetails.Gender
                  ]
                }
                click={() => openDropDown("Gender")}
              />
            </div>
          </div>
          <div className={styles.input2column}>
            <span>
              Date Of Birth<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop-date"]}>
              <span>
                {homeOwnersDetailsReq.DateOfBirth
                  ? returnFormattedDate(homeOwnersDetailsReq.DateOfBirth)
                  : homeOwnersDetails.DateOfBirth
                  ? returnFormattedDate(homeOwnersDetails.DateOfBirth)
                  : ""}
              </span>
              <EmptyDropCalendar
                select={homeOwnersDetailsReq.DateOfBirth}
                click={handleDateChange}
              />

              <ArrowDropDownIcon
                sx={{
                  position: "absolute",
                  right: "5%",
                  fontSize: 35,
                  zIndex: 50,
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Home Address<span className={styles.red}>*</span>
            </span>
            <input
              name="HomeAddress"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={
                homeOwnersDetailsReq.HomeAddress ||
                homeOwnersDetails.HomeAddress
              }
              defaultValue={homeOwnersDetails.HomeAddress}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              City<span className={styles.red}>*</span>
            </span>
            <input
              name="City"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={homeOwnersDetailsReq.City || homeOwnersDetails.City}
              defaultValue={homeOwnersDetails.City}
            />
          </div>

          <div className={styles["inputcolumn-65"]}>
            <span>
              State<span className={styles.red}>*</span>
            </span>
            <input
              name="State"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={homeOwnersDetailsReq.State || homeOwnersDetails.State}
              defaultValue={homeOwnersDetails.State}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              Occupation<span className={styles.red}>*</span>
            </span>
            <input
              name="Occupation"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={
                homeOwnersDetailsReq.Occupation || homeOwnersDetails.Occupation
              }
              defaultValue={homeOwnersDetails.Occupation}
            />
          </div>
          <div
            className={styles["inputcolumn-65"]}
            style={{ position: "relative" }}
          >
            <span>
              Premium Payment Frequency<span className={styles.red}>*</span>
            </span>
            <div
              className={styles["exclam-but"]}
              onClick={() => openDropDown("DisplayPopHomeOwners")}
              role="button"
            >
              <span>!</span>
            </div>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  premiumPaymentFreqArr[
                    homeOwnersDetailsReq.PremiumPaymentFreq ??
                      homeOwnersDetails.PremiumPaymentFreq
                  ]
                }
                click={() => openDropDown("PremiumPaymentFreq")}
              />
            </div>
          </div>
        </div>

        <span className={styles["doc-header"]}>
          Media <span className={styles.red}>*</span>
        </span>
        {documents.map((doc) => (
          <UploadSingleButton
            key={doc.buttonText}
            name={doc.name}
            buttonTxt={doc.buttonText}
            instructions={doc.texts}
            accept={doc.accept}
            handleDocsChange={handleDocsChange}
            removeDoc={removeDoc}
            docValue={doc.value}
            multiple={doc.multiple}
          />
        ))}

        <div className={styles["container-bottom-butt"]}>
          <span onClick={() => goBack()} role="button">
            Back
          </span>

          <div
            className={styles["continue-butt"]}
            role="button"
            onClick={() => saveAndContinue()}
          >
            <span>Save & Continue</span>
          </div>
        </div>
      </div>

      <ModalLayout modalOpen={rentDropDownValue === "PremiumPaymentFreq"}>
        <PopUp
          options={premiumPaymentFreqArr}
          click={handlePaymentFreqSelection}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "DisplayPopHomeOwners"}>
        <DisplayPop closeModal={closeAllDropDowns} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Gender"}>
        <PopUp options={genderArray} click={handleGenderSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "MaritalStatus"}>
        <PopUp options={maritalStatusArray} click={handleMaritalSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Religion"}>
        <PopUp options={religionArray} click={handleReligionSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Sector"}>
        <PopUp options={sectorArray} click={handleSectorSelection} />
      </ModalLayout>
    </>
  );
};

export default LandlordDetailsComp;
