import React from 'react';
import styles from './cardDisplay.module.scss';
import visaLogo from '../../assets/visa.svg';
import verveLogo from '../../assets/verve.svg';
import mastercardLogo from '../../assets/mastercard.svg';

export interface CardPaymentDisplayProps{
    holderName?: string;
    issuerToken?: string;
    cardId?: number;
    ref?: React.LegacyRef<HTMLDivElement>
    onSelect?: (id?: number) => void;
}

const CardPaymentDisplay: React.FC<CardPaymentDisplayProps> = ({
    issuerToken, holderName, cardId, ref, onSelect
})=>{

    const fetchImage = ()=>{
        switch (issuerToken) {
            case "0":
                return visaLogo;

            case "1":
                return mastercardLogo;
        
            default:
                return verveLogo;
        }
    }
    const fetchLastDigits = ()=>{
        switch (issuerToken) {
            case "0":
                return "1111";

            case "1":
                return "4151";
        
            default:
                return "3577";
        }
    }

    const handleClick = ()=>{
        onSelect && onSelect(cardId);
    }

    return(
        <div className={styles.cardArea} ref={ref} onClick={handleClick}>

            {/** Card and Name */}
            <div className={styles.cardLogo}>
                {/** Logo */}
                <img src={fetchImage()} alt="visa logo" />

                {/** Number and Name */}
                <div className={styles.nameNumber}>
                    <p>**** {fetchLastDigits()}</p>
                </div>
            </div>
        </div>
    )
}

export default CardPaymentDisplay;