import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextInput from '../../FormElements/TextInput';
import styles from './topupBalance.module.scss';
import CardFundOption, { CardFundOptionProps } from '../../CardFundOption';
import MySwipeableView from '../../MySwipeableView';
import { useFundingSource } from '../../../swr/funding';
import jwt, { Jwt, JwtPayload } from 'jsonwebtoken';
import CardPaymentDisplay from '../../CardPaymentDisplay';
import { FundingType } from '../../../types/FundingType';
import Skeleton from '@mui/material/Skeleton';
import { FundingSource } from '../../../types/FundingSource';
import { fetchImage, fetchLastDigits } from '../../../utils/cardUtils';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoadingScreen, { CircularLoader } from '../../LoadingScreen';
import ToastContext from '../../../contexts/ToastContext';
import { getWalletId } from '../../../utils/generalUtils';
import { FundWalletRequest } from '../../../types/Wallet';
import { fundWallet } from '../../../swr/wallet';


export interface TopUpBalanceDialogProps{
    open?: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
    title?: string;
}


const TopUpBalanceDialog: React.FC<TopUpBalanceDialogProps> = ({
    open = false, onClose, title = 'Topup Balance'
})=>{

   // Token details 
   let uid = '';
    let email = ''; 
    let uname = ''
     const token = localStorage.getItem('token'); 
     if (token) {
         let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
      if (decodedToken) {
         const { payload  } = decodedToken as JwtPayload;
          uid = payload.uid
           email = payload.email 
           uname = payload.uname
         }
         }
    const { data, isLoading, isError, mutate } = useFundingSource(uid);
    

    // Contexts
    const { openSuccess, openError } = React.useContext(ToastContext);
    
    // State
    const [currentIndex, setCurrentIndex] = React.useState<number>(0);
    const [chosenCard, setChosenCard] = React.useState<number>();
    const [bankChosen, setBankChosen] = React.useState<boolean>(false);
    const [cardChosen, setCardChosen] = React.useState<boolean>(false);
    const [directDebitChosen, setDirectDebitChosen] = React.useState<boolean>(false);
    const [chosenFundingSource, setFundingSource] = React.useState<FundingSource>();
    const [accountNumber, setAccountNumber] = React.useState<string>('');
    const [fundOtp, setFundOtp] = React.useState<string>('');
    const [loadingDebit, setLoadingDebit] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [spinClass, setSpinClass] = React.useState<string>('');
    const [amountToFund, setAmountToFund] = React.useState<string>('');

    // Handlers 
    const handleCardTopUp = () =>{
        setCardChosen(true);
        setCurrentIndex(currentIndex + 1);
    }
    const handleCardSelect = (id?: number) =>{
        setChosenCard(id);
        let source = data?.data?.find((item)=> item.id === id);
        setFundingSource(source);
    }
    const cancelCardSelect = ()=>{
        setChosenCard(undefined);
        setFundingSource(undefined);
    }
    const handleBack = ()=>{
        setBankChosen(false);
        setCardChosen(false);
        setDirectDebitChosen(false);
        setCurrentIndex(currentIndex - 1);
        cancelCardSelect();
    }
    const handleBackGeneral = ()=>{
        setCurrentIndex(currentIndex - 1);
    }
    const handleBankTransfer = ()=>{
        if (amountToFund.replace(/\s/g, "").length === 0) {
            openError('Please enter an amount to continue')
        }
        else{
            setBankChosen(true);
            setCurrentIndex(currentIndex + 1);
        }
    }
    const handleDirectDebit = () =>{
        if (amountToFund.replace(/\s/g, "").length === 0) {
            openError('Please enter an amount to continue')
        }
        else{
            setDirectDebitChosen(true);
            setCurrentIndex(currentIndex + 1);
        }
    }
    const handleAmountChange = (value: any)=>{
        setAmountToFund(value);
    }
    const handleSetAccountNumber = (value: any)=>{
        setAccountNumber(value);
    }
    const handleOtpChange = (value: any)=>{
        setFundOtp(value);
    }
    const handleNext = ()=>{
        // Simulate OTP generation
        setLoadingDebit(true)
        setTimeout(()=>{
            setLoadingDebit(false);
            setCurrentIndex(currentIndex + 1)
        }, 2500);
    }
    const handleResendLink = () =>{
        // Simulate resend link
        setSpinClass('fa-spin');
        setTimeout(()=>{
            setSpinClass('');
            openSuccess('OTP Resent');
        }, 2500);
    }

    const reset = ()=>{
        setBankChosen(false);
        setCardChosen(false);
        setDirectDebitChosen(false);
        setCurrentIndex(0);
        cancelCardSelect();
    }
    const handleFund = async ()=>{
        let request: FundWalletRequest = {
            msgId: new Date().getTime().toString(),
            debitAccountNumber: accountNumber,
            creditAccountNumber: getWalletId(token || ''),
            narration: 'HomeBuddy wallet funding',
            requestTime: new Date(),
            amount: Number.parseFloat(amountToFund),
            email: email
        }
        setLoading(true);
        const { data, error } = await fundWallet(request);

        if (data) {
            setLoading(false);
            openSuccess('Wallet successfully funded');
            reset();
            onClose && onClose()
        }
        if (error) {
            setLoading(false);
            if(error.response?.data){
                openError(error.response.data.responseMessage);
            }
            else{
                openError('An error occured while funding your wallet')
            }
            
        }

        // Simulat funding
        // setLoading(true);
        // setTimeout(()=>{
        //     setLoading(false);
        //     openSuccess('Wallet successfully funded');
        //     reset();
        //     onClose && onClose()
        // }, 2500);

    }

    // Elements
    const fundYourWallet = (
        <div className={styles.fundWallet}>
            <p>Fund your wallet</p>
            <p>How much do you want to fund ?</p>
            <TextInput 
                name="amount"
                hideDefaultAdornment
                containerClass={styles.amountField}
                variant="amount"
                disabled={currentIndex > 0}
                onValueChange={handleAmountChange}
                regex={/^[0-9]*$/}
            />
        </div>
    )

    const sources: CardFundOptionProps[] = [
        // {
        //     iconClass: 'fas fa-credit-card',
        //     title: "Top-up with Card",
        //     subtitle: "Add money directly from your bank card",
        //     onClick: handleCardTopUp 
        // },
        {
            iconClass: 'fa fa-university',
            title: "Bank Transfer",
            subtitle: "Add money via mobile or internet banking",
            onClick: handleBankTransfer
        },
        {
            iconClass: 'fa fa-university',
            title: "Direct Debit",
            subtitle: "Add money directly from your bank account",
            onClick: handleDirectDebit
        }
    ]
    const selectSource = (
        <div className={styles.source}>
            <p>Select source: </p>
            {
                sources.map((item, index)=>(
                    <CardFundOption key={index} {...item} className={styles.card}/>
                ))
            }
        </div>
    )

    const bankTransfer = (
        <div className={styles.bankTransfer}>
            <p>To fund your wallet</p>
            <p>
                Transfer the amount you want to fund to the account details below
                and your balance will be funded.
            </p>
            <div className={styles.accountDisplay}>
                <p>Access Bank PLC</p>
                <p>{getWalletId(token || '')}</p>
            </div>
            <div className={styles.btnContainer}>
                <button
                    onClick={handleBack} 
                    className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                >
                    Back
                </button>
            </div>
        </div>
    )

    const directDebit = (
        <div className={styles.debitDisplay}>
            {loadingDebit &&  <CircularLoader height="50px" width="50px"/>}
            <label>Please enter an access bank account number:</label>
            <TextInput
                name="account_number"
                hideDefaultAdornment
                onValueChange={handleSetAccountNumber}
                regex={/^[0-9]*$/}
                maxLength={10}
                useValue={accountNumber}
            />
            
           <div>
                <button
                    onClick={handleBack} 
                    className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                >
                    Back
                </button>
                { 
                    accountNumber.length >= 10 && 
                    <button
                        onClick={handleNext} 
                        className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                    >
                        Next
                    </button>
                }
           </div>
        </div>
    )

    const otp = (
        <div className={styles.debitDisplay}>
            <label>Enter OTP</label>
            <TextInput
                name="fund_otp"
                hideDefaultAdornment
                onValueChange={handleOtpChange}
                regex={/^[0-9]*$/}
                maxLength={6}
                useValue={fundOtp}
            />
          <span>
            <i className={`fa fa-sync ${spinClass}`}></i>
            <span className={styles.otpLink} onClick={handleResendLink}>
                Resend OTP
            </span>
          </span>

            <div>
                <button
                    onClick={handleBackGeneral} 
                    className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                >
                    Back
                </button>
                {
                    fundOtp.length >= 6 &&
                    <button
                        onClick={handleFund} 
                        className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                    >
                        Fund
                    </button>
                }
           </div>
        </div>
    )

    const sourceChoice = (
        <div>
             <p>Select card: </p>
            <div className={styles.cardDiv}>
                {
                    isLoading &&
                    <Skeleton variant="rectangular" width="100%" >
                        <CardPaymentDisplay/>
                    </Skeleton>
                }
                { !chosenCard &&
                    data?.data?.map((item, index)=>{
                        if (item.fundingType === FundingType.Card) {
                            return(
                                <CardPaymentDisplay 
                                    issuerToken={item.issuerToken}
                                    holderName={'Ikemefuna Ezechukwu'}
                                    cardId={item.id}
                                    onSelect={handleCardSelect}
                                    key={`cdo${index}`}
                                />
                            )
                        }
                    })
                }
                {/** Selected Card */}
                { chosenCard &&
                    <div className={styles.selectedPayment}>
                        <div className={styles.cardLogo}>
                            {/** Logo */}
                            <img src={fetchImage(chosenFundingSource?.issuerToken || "10")} alt="card logo" />

                            {/** Number and Name */}
                            <div className={styles.nameNumber}>
                                <p>**** {fetchLastDigits(chosenFundingSource?.issuerToken || "10")}</p>
                            </div>
                        </div>
                        <div>
                            <IconButton onClick={cancelCardSelect}>
                                <CloseIcon sx={{ fontSize: '16px' }} />
                            </IconButton>
                        </div>
                    </div>
                }
            </div>
            
            <div className={styles.btnContainer}>
                {!chosenCard &&
                    <button
                        onClick={handleBack} 
                        className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                    >
                        Back
                    </button>
                }
                { chosenCard &&
                    <button
                        onClick={handleBack} 
                        className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                    >
                        Fund
                    </button>
                }
            </div>
        </div>
    )

    const choiceDisplay = (
        <>
            { cardChosen && sourceChoice }
            { bankChosen && bankTransfer }
            { directDebitChosen && directDebit }
        </>
    )

    return(
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            PaperProps={{
                sx: {
                    height: '100%',
                    minWidth: '45%',
                    position: 'relative'
                }
            }}
        >
            { loading && <LoadingScreen/> }
            <DialogTitle id="delete-dialog-dialog-title"
                sx={{ 
                    textAlign: 'center', 
                    fontWeight: '600', 
                    borderBottom: '1px solid #e5e5e5' 
                }} 
            >
                { title }
            </DialogTitle>
            <DialogContent>
                {/**Close buttonn */}
                <IconButton
                    onClick={onClose}
                    sx={{ 
                        position: 'absolute',
                        top: 10,
                        right: 10
                    }}
                >
                    <CloseIcon/>
                </IconButton>

                { fundYourWallet }
               
                <MySwipeableView currentIndex={currentIndex}>
                    { selectSource }
                    { choiceDisplay }
                    { otp }
                </MySwipeableView>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    )
}

export default TopUpBalanceDialog;