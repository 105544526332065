import visaLogo from '../assets/visa.svg';
import verveLogo from '../assets/verve.svg';
import mastercardLogo from '../assets/mastercard.svg';

export const fetchImage = (issuerToken: string)=>{
    switch (issuerToken) {
        case "0":
            return visaLogo;

        case "1":
            return mastercardLogo;
    
        default:
            return verveLogo;
    }
}

export const fetchLastDigits = (issuerToken: string)=>{
    switch (issuerToken) {
        case "0":
            return "1111";

        case "1":
            return "4151";
    
        default:
            return "3577";
    }
}