import React from 'react';
import { Attachment } from '../../../types/Property';
import MySwipeableView from '../../MySwipeableView';
import styles from './imgCarousel.module.scss';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';

export interface MyImageCarouselProps{
    attachments?: Attachment[];
    currentIndex?: number;
}

const MyImageCarousel: React.FC<MyImageCarouselProps> = ({
    attachments, currentIndex = 0
})=>{

    const [imageIndex, setImageIndex] = React.useState<number>(currentIndex);
    

    // Handlers
    const nextImage = ()=>{
        if ((imageIndex + 1) !== attachments?.length) {
            setImageIndex(imageIndex + 1);
        }
    }

    const prevImage = ()=>{
        if (imageIndex !== 0) {
            setImageIndex(imageIndex - 1);
        }
    }

    return(
        <div className={styles.container}>
            {/** Buttons */}
            <div className={styles.btnGroup}>
                <IconButton
                    sx={{ 
                        backgroundColor: 'rgba(0,0,0,0.3)',  
                        '&:hover':{ backgroundColor: 'rgba(0,0,0,0.6)' } 
                    }} 
                    onClick={prevImage}
                >
                    <KeyboardArrowLeftIcon sx={{ fontSize: '32px', color: 'white' }}/>
                </IconButton>
                <IconButton  
                    sx={{ 
                        backgroundColor: 'rgba(0,0,0,0.3)',  
                        '&:hover':{ backgroundColor: 'rgba(0,0,0,0.6)' } 
                    }} 
                    onClick={nextImage}
                >
                    <ChevronRightIcon sx={{ fontSize: '32px', color: 'white' }}/>
                </IconButton>
            </div>
            <MySwipeableView currentIndex={imageIndex}>
                {
                    attachments?.map((item, index)=>(
                        <div key={index} className={styles.image}>
                            <img src={item.imageUrl} alt={item.alt} />
                        </div>
                    ))
                }
            </MySwipeableView>
        </div>
    )
}

export default MyImageCarousel;