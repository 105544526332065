import React from 'react';
import styles from './edit.module.scss';

export interface EditListingWidgetProps{
    onUpdate?: (arg: any) => void;
    onDelete?: (arg: any) => void;
    onImageUpdate?: (arg: any) => void;
    onRenewFeaturedSubscription?: () => void
}

const EditListingWidget: React.FC<EditListingWidgetProps> = ({
    onUpdate = ()=>{}, onDelete, onImageUpdate, onRenewFeaturedSubscription
})=>{

    return(
        <div className="widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">Edit Listing</h4>
            <div className={styles.buttonGroup} >
                <button type="submit" className="btn theme-btn-1" onClick={onUpdate}>Update Listing</button>
                <button type="submit" className="btn theme-btn-1" onClick={onImageUpdate}>Upload Images</button>
                {<button type="submit" className="btn theme-btn-1" onClick={onDelete}>Delete Listing</button>}
                {<button type="submit" className="btn theme-btn-1" onClick={onRenewFeaturedSubscription}>Make Listing Featured</button>}
            </div>
        </div>
    )
}

export default EditListingWidget;