import React from 'react'
import Layout from '../../components/Layout'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'
import NavHeader from '../../components/NavHeader'
import MyListingsView from '../../views/MyListingsView'

const index = () => {
  return (
    <Layout>
    <ScrollToTop/>
    <SessionCheck shouldRedirect/>
    <NavHeader 
        pageTitle="My Listing"
        routeName="mylistings" 
    />
    <MyListingsView/>
</Layout>
  )
}

export default index