import React from "react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import styles from "./input.module.scss"
import { Calendar } from "primereact/calendar";

const EmptyDropCalendar = ({
  select,
  click,
}: {
  select?: Date;
  click: (val: any) => void;
}) => {
  return (
    <Calendar
    value={select}
    onChange={(e) => click(e.value)
    }
    className={styles["empty-drop-down-date"]}
    panelClassName={styles.panel}
  />
  );
};

export default EmptyDropCalendar;
