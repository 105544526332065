import Button from "../Button";
import Input from "../Input";
import FormLink from "../FormLink";
import TextInput from "../FormElements/TextInput";
import { useState } from "react";
import signupImage from "../../assets/signupImage.png";
import styles from "../../sections/Register/register.module.scss";
import { Link, useHistory } from "react-router-dom";
import HomeBuddyLogo from "../../assets/HomeBuddy_RequestedColour_PrimaryLockup.svg";

interface ILoginAccount {
  handleChange: () => void;
  onSubmit: (e: React.FormEvent) => void;
  formValues: { [key: string]: string };
  loading: boolean;
  link: string;
  userName: string;
}

const LoginAccount: React.FC<ILoginAccount> = ({
  handleChange,
  onSubmit,
  formValues,
  loading,
  link,
  userName,
}) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={styles.formContainerI}>
      <div className={styles.loginform}>
        {/* <div className="section-title-area text-center">
            <h1 className="section-title">
              Login <br />
              To Your Account
            </h1>
          </div> */}
        {/* <div className="account-login-inner"> */}
        <div className={styles.logoContainerI}>
          <div
            className={styles.logoImage}
            role="button"
            onClick={() => history.push("/")}
          >
            <img src={HomeBuddyLogo} alt="HomeBuddyLogo" />
          </div>
        </div>

        <div className={styles.logoContainerWel}>
          <span className={styles.welcome}>Welcome Back!</span>
        </div>

        <form onSubmit={onSubmit} action="#" className={styles.innerLogin}>
          <TextInput
            type="email"
            name="email"
            placeholder="Email*"
            required={true}
            onChange={handleChange}
            value={formValues.email}
          />
          <TextInput
            type="password"
            name="password"
            placeholder="Password*"
            required={true}
            onChange={handleChange}
            value={formValues.password}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
          />
          <Button
            label="SIGN IN"
            classes={"theme-btn-1 btn btn-block self-center"}
            disabled={loading}
          />
          <div className="go-to-btn mt-20">
            <a href="/forgotPassword">
              <small>FORGOT YOUR PASSWORD?</small>
            </a>
          </div>
        </form>
        {/* </div> */}

        <div className={`${styles.noaccount} account-create pt-100`}>
          <h4>DON'T HAVE AN ACCOUNT?</h4>
          <p>
            Get access to convenient payment options for your housing needs, or
            list properties by signing up
          </p>
          <div className="btn-wrapper">
            <FormLink
              link={link}
              classes={"theme-btn-1 btn black-btn"}
              text="CREATE ACCOUNT"
            />
          </div>
        </div>
      </div>
      {/* <div className="col-lg-10"> */}

      <div className={styles.sideImgContainer}>
        <img src={signupImage} alt="signupImage" className={styles.sideImg} />
      </div>
    </div>
  );
};

export default LoginAccount;
