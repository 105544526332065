import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PropertyRequest } from '../../types/Property'

const baseUrl = process.env.BASE_URL || 'https://api-sandbox.homebuddy.ng' 

export interface AddPropertyRequest {
  request: PropertyRequest | FormData
  token?: string
}
export interface UpdatePropertyRequest {
  request: PropertyRequest | FormData
  id: string | number
  token?: string
}
export interface DeletePropertyRequest {
  id: string | number
  token?: string
}

export interface FetchPropertyRequest {
  id?: string | number
  token?: string
}

export const fetchProperties = createAsyncThunk(
  'api/properties/get',
  async (token?: string) => {
    const response = await axios.get(baseUrl + '/api/properties', {
      headers: { Authorization: `Bearer ${token}` },
    })
    return response.data
  }
)

export const addProperty = createAsyncThunk(
  'api/properties/post',
  async ({ request, token }: AddPropertyRequest) => {
    const response = await axios.post(baseUrl + '/api/properties', request, {
      headers: {
        Authorization: `Bearer ${token}`,
        //"content-type": "multipart/form-data"
      },
    })
    return response.data
  }
)

export const fetchPropertyById = createAsyncThunk(
  'api/properties/get/id',
  async ({ id, token }: FetchPropertyRequest) => {
    const response = await axios.get(baseUrl + '/api/properties/' + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return response.data
  }
)

export const updateProperty = createAsyncThunk(
  'api/properties/update',
  async ({ request, token, id }: UpdatePropertyRequest) => {
    const response = await axios.put(
      baseUrl + '/api/properties/' + id,
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // "content-type": "multipart/form-data"
        },
      }
    )
    return response.data
  }
)

export const deleteProperty = createAsyncThunk(
  'api/properties/delete',
  async ({ token, id }: DeletePropertyRequest) => {
    const response = await axios.post(baseUrl + '/api/Properties/DeleteProperty' + id, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  }
)

export const addPropertyRating = createAsyncThunk(
  'api/properties/ratings',
  async ({ token, id }: DeletePropertyRequest) => {
    const response = await axios.delete(baseUrl + '/api/properties/ra' + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  }
)
