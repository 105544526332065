import React from 'react';
import { formatDate } from '../../../../utils/propertyUtils';
import { StyledRating } from '../../Reviews';

export interface CommentCardProps{
    user?: string;
    comment?: string;
    dateAdded?: Date;
    rating?: number;
    firstName?: string;
    lastName?: string;
}

const CommentCard: React.FC<CommentCardProps> = ({
    user = 'Adam Smit', 
    comment = `
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, omnis fugit corporis iste magnam ratione.
    `,
    dateAdded = new Date(),
    rating = 3.5,
    firstName,
    lastName

})=>{




    return(
        <div className="ltn__comment-item clearfix">
            {/* <div className="ltn__commenter-img">
                <img src="img/testimonial/1.jpg" alt="Image"/>
            </div> */}
            <div className="ltn__commenter-comment">
                <h6><a href="#">{(firstName || lastName) ? `${firstName && firstName} ${lastName && lastName}` : 'HomeBuddy User'}</a></h6>
                <div className="product-ratting">
                    <StyledRating name="half-rating" value={rating} precision={0.5} size="small"/>
                </div>
                <p>{comment}</p>
                <span className="ltn__comment-reply-btn">{formatDate(dateAdded)}</span>
            </div>
        </div>
    )
}

export default CommentCard;