import React, { SetStateAction } from "react";
import { Link } from "react-router-dom";
import { PropertyStatus } from "../../../types/Property";
import styles from "./purchase.module.scss";
import { useHistory } from "react-router";
import ToastContext from "../../../contexts/ToastContext";
import { getWalletDetails } from "../../../swr/wallet";

export interface PurchaseWidgetProps {
  propertyId?: number;
  status?: PropertyStatus;
  availability?: boolean;
  handleNav: () => void;
}

const PurchaseWidget: React.FC<PurchaseWidgetProps> = ({
  availability,
  handleNav,
}) => {
  return (
    <div className="widget">
      {/* <h4 className="ltn__widget-title ltn__widget-title-border-2">Make Payment</h4> */}
      <div className={styles.buttonGroup}>
        <button
          className="btn theme-btn-1"
          disabled={!availability}
          onClick={() => {
            handleNav();
          }}
        >
          {availability ? "Make Payment" : "Property Paid For"}
        </button>
        {/* <Link to="/loan"><button type="submit" className="btn theme-btn-1">Apply for loan</button></Link> */}
        {/* {<button type="submit" className="btn theme-btn-1" onClick={onDelete}>Delete Listing</button>} */}
      </div>
    </div>
  );
};

export default PurchaseWidget;
