import React, { useContext, useEffect, useState } from "react";
import PensionCard from "../../components/ViewPensionApplications";
import styles from "./viewpension.module.scss";
import { getPensionApplicationsApi } from "../../swr/properties";
import LoadingContext from "../../contexts/LoadingContext";
import ToastContext from "../../contexts/ToastContext";
import { PaginationParamsPersonalizedProperties } from "../../types/propertyType";
import { PensionApplicant } from "../../types/Pension";
import Stack from "@mui/system/Stack";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ViewPensions = () => {
  const { openError } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const [paginationParams, setPaginationParams] = useState({
    PageNumber: 1,
    PageSize: 12,
  });
  const token = localStorage.getItem("token") as string;
  const [applicants, setApplicants] = useState<PensionApplicant[] | []>([]);
  const [pageParams, setPageParams] = useState(
    {} as PaginationParamsPersonalizedProperties
  );

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const closeDropdown = () => {
    setIsDropDownOpen(false);
  };

  const getPensionApplicants = async (status: number) => {
    setLoading(true);
    const { data, error } = await getPensionApplicationsApi(
      paginationParams,
      token,
      status
    );

    if (data && data.item1) {
      setLoading(false);
      setApplicants(data.item1);
      return;
    }

    if (data && data.item2) {
      setLoading(false);
      setPageParams(data.item2);
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        (error.response?.data?.message ?? error.message) ||
          "Unable to fetch mortgage properties"
      );
      return;
    }

    openError("Unable to fetch pension applicants");
    setLoading(false);
    return;
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPaginationParams((prev) => ({ ...prev, PageNumber: newPage }));
  };

  const returnApplicants = (mortgagedProperties: PensionApplicant[]) => {
    return mortgagedProperties.map((prop, index) => (
      <PensionCard applicant={prop} key={`${prop.title}=${index}`} />
    ));
  };

  useEffect(() => {
    getPensionApplicants(3);
    if (paginationParams.PageNumber > 0) {
      getPensionApplicants(3);
    }
  }, []);

  console.log(applicants.length, "=======-----=======-------9090");
  

  return (
    <div className={styles.supercontainer}>
      <div className={styles["profile-backII"]}>
        <div
          className={styles["profile-back-cont"]}
          onClick={() => history.push("/dashboardad")}
          role="button"
        >
          <ArrowBackIcon />
        </div>

        <div
          className={styles["filter-button"]}
          role="button"
          onClick={() => setIsDropDownOpen(!isDropDownOpen)}
        >
          {" "}
          Filter{" "}
        </div>

        {isDropDownOpen === true && (
          <div className={styles.dropdownfilter}>
            <div
              className={styles.dropdownoptions}
              role="button"
              onClick={() => {
                closeDropdown();
                getPensionApplicants(2);
              }}
            >
              Pending
            </div>
            <div
              className={styles.dropdownoptions}
              role="button"
              onClick={() => {
                closeDropdown();
                getPensionApplicants(1);
              }}
            >
              Treated
            </div>
            <div
              className={styles.dropdownoptions}
              role="button"
              onClick={() => {
                closeDropdown();
                getPensionApplicants(3);
              }}
            >
              All
            </div>
          </div>
        )}
      </div>
      <p className={styles.heading}>Access Pension Applicants</p>
      {applicants && applicants.length > 0 ? (
        <>
          <div className={styles.gridContainer}>
            {returnApplicants(applicants)}
          </div>
          <div className={styles.paginationDiv}>
            {!(
              paginationParams.PageNumber == 1 &&
              applicants &&
              applicants.length < paginationParams.PageSize
            ) && (
              <Stack
                spacing={2}
                sx={{ marginBottom: "30px", alignItems: "center" }}
              >
                <Pagination
                  count={pageParams && pageParams.totalPages}
                  page={paginationParams.PageNumber}
                  onChange={handlePageChange}
                  size={"large"}
                  variant="outlined"
                  color="standard"
                />
              </Stack>
            )}
          </div>
        </>
      ) : (
        <div className={styles.noListing}>
          <span className={styles.fonts}>No Pension Applicants To View</span>
        </div>
      )}
    </div>
  );
};

export default ViewPensions;
