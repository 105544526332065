export  enum UserType{
    Landlord,
    Agent,
    Tenant
}

export enum UserTypeBackEnd {
    Landlord = 0,
    Agent = 1,
    Tenant = 2,
  }


