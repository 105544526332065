import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGraduationCap, faHospital } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import useStyles from "./accordionItem-jss";

export interface AccordionItemProps{
  title?: string;
  icon?: React.ReactNode;
  content?: string;
  generalPurpose?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const AccordionItem: React.FC<AccordionItemProps> = ({ 
  title = '',
  icon = faGraduationCap,
  generalPurpose = false,
  children,
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque eleifend donec pretium vulputate sapien nec sagittis'
}) => {

  const contentEl = useRef<HTMLDivElement>(null);
  const styles = useStyles();

  const [active, setActive] = useState<boolean>(false);

  const toggle = ()=>{
    setActive(!active);
  }

  // For general purpose
  if (generalPurpose) {
    return(
      <div className={`card ${styles.card}`}>
      {/** Accordion Header */}
      <h6 className={`ltn__card-title ${styles.cardTitle} ${active ? styles.expanded : null}`} aria-expanded={active} onClick={toggle}>
          {title}
      </h6>

      {/** Accordion Collapsable content */}
      <div ref={contentEl}
        className={styles.contentWrapper}
        style={
          active
            ? { height: contentEl?.current?.scrollHeight }
            : { height: "0px" }
        }
      >
          <div className={`card-body ${styles.cardBody}`}>
              { children }
          </div>
      </div>

    </div>
    )
  }

  return (
    <div className={`card ${styles.card}`}>
      {/** Accordion Header */}
      <h6 className={`${styles.cardTitle} ${active ? styles.expanded : null}`} aria-expanded={active} onClick={toggle}>
          <i className={styles.icon}>
            <FontAwesomeIcon icon={["fas", "graduation-cap"]} />
          </i>
          {title}
      </h6>

      {/** Accordion Collapsable content */}
      <div ref={contentEl}
        className={styles.contentWrapper}
        style={
          active
            ? { height: contentEl?.current?.scrollHeight }
            : { height: "0px" }
        }
      >
          <div className={`card-body ${styles.cardBody}`}>
              <p>{content}</p>
          </div>
      </div>

    </div>
    
  );
};

export default AccordionItem;