import chair from '../../assets/chair.jpg'
import chisel from '../../assets/chisel.jpg'
import dangote from '../../assets/cement.jpg'
import bua from '../../assets/bua.jpg'

export const marketProducts: MarketData[] = [
  {
    id: 1,
    title: "Cement",
    description:
      "Build your dreams on a foundation of strength and durability with our premium cement – the cornerstone of excellence in construction. Our high-quality cement not only meets industry standards but exceeds expectations, ensuring a solid and lasting structure for generations to come. Trust in the power of our cement to lay the groundwork for your visions. Choose Dangote for construction that stands the test of time",
    price: 5500,
    weight: "50kg",
    recommendedUsage:
      "For general construction purposes",
    durability:
      "Resistant to chemical corrosion..",
    ratings: 4,
    warranty:
      "Manufacturer's warranty.",
    date: new Date(),
    manufacturer: "Dangote",
    category: 'Raw Materials',
    image: dangote
  },
  {
    id: 2,
    title: "Cement",
    description:
      "Build your dreams on a foundation of strength and durability with our premium cement – the cornerstone of excellence in construction. Our high-quality cement not only meets industry standards but exceeds expectations, ensuring a solid and lasting structure for generations to come. Trust in the power of our cement to lay the groundwork for your visions. Choose BUA for construction that stands the test of time",
    price: 5300,
    weight: "50kg",
    recommendedUsage:
      "Ideal for structural elements purposes",
    durability:
      "Resistant to adverse weather conditions.",
    ratings: 3,
    warranty:
      "Manufacturer's warranty.",
    date: new Date(),
    manufacturer: "BUA Cement",
    category: 'Raw Materials',
    image: bua
  },
  {
    id: 3,
    title: "Wooden chair",
    description:
      "Craft your space with our premium chairs – a perfect blend of style, comfort, and durability. Our high-quality chairs not only meet but exceed industry standards, promising a comfortable seating experience that stands the test of time. Elevate your space with Bedmate chairs, where craftsmanship meets excellence",
    price: 15000,
    weight: "50kg",
    recommendedUsage:
      "Versatile seating solution.",
    durability:
      "Built to last.",
    ratings: 5,
    warranty:
      "Manufacturer's warranty.",
    date: new Date(),
    manufacturer: "BedMate",
    category: 'Home Appliances',
    image: chair
  },
  {
    id: 4,
    title: "Chisel",
    description:
      "12 inches. Precision craftsmanship starts with the right tools, and our premium chisels are the epitome of excellence. Engineered for durability and precision, these chisels are crafted to exceed industry standards. Elevate your woodworking projects with the reliability and precision of African-Industries chisels, the essential companion for every artisan",
    price: 6000,
    weight: "0.5kg",
    recommendedUsage:
      "Ideal for woodworking projects.",
    durability:
      "Crafted from high-quality steel.",
    ratings: 4.5,
    warranty:
      "Manufacturer's warranty.",
    date: new Date(),
    manufacturer: "African-Industries",
    category: 'Building Equipment',
    image: chisel
  }
];

export interface MarketData {
  id: number;
  title: string;
  description: string;
  price: number;
  weight: string;
  recommendedUsage: string;
  durability: string;
  ratings: number;
  warranty: string;
  date: Date;
  manufacturer: string;
  category: string;
  image: string
}