import React from "react";
import Layout from "../components/Layout";
import PropertyCard from "../components/PropertyCard";
import propertyData from "../components/PropertyCard/defaultData";
import { Property } from "../types/Property";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/store";
import { fetchProperties } from "../state/thunks/property";
import NavHeader from "../components/NavHeader";
import PropertyGrid from "../sections/PropertyGrid";
import LoadingScreen from "../components/LoadingScreen";
import {
  searchProperty,
  useProperties,
  usePropertySearch,
} from "../swr/properties";
import { useLocation } from "react-router";
import ScrollToTop from "../components/ScrollToTop";
import { propertyTypeEnumKeysArray } from "../types/propertyType";
import { States } from "../types/states";
// import Modal from "react-modal";
// import SplashScreenComingSoon from '../components/SplashScreen/SplashScreenComingSoon';

const PropertyGridPage: React.FC<any> = () => {
  // const [modalIsOpen, setModalIsOpen] = React.useState(true);
  // const openModal = () => {
  //     setModalIsOpen(true);
  //   };

  //   const closeModal = () => {
  //     setModalIsOpen(false);
  //   };

  const modalWidth = window.innerWidth > 768 ? "40%" : "40%";

  const dispatch = useDispatch();
  const location = useLocation();
  //const { properties, loading, error } = useSelector((state: RootState)=> state.property);

  // swr
  const states = Object.values(States).filter(
    (city) => typeof city !== "number"
  );
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type") as string;
  const state = queryParams.get("state") as string;
  const city = queryParams.get("city") as string;
  const status = queryParams.get("status") as string;

  const [propertiestoUse, setPropertiestoUse] = React.useState<any[]>([]);

  const token = localStorage.getItem("token") || undefined;
  const { properties, isLoading, isError } = useProperties(token, 1, 1000000);
  const searchResult = usePropertySearch(location.search, token);

  let propertiesToUse: Property[];

  const statuses = ["Rent", "Sale", "RentToOwn", "Mortgage"];

  const Search = async () => {
    if(type || state || city || status){
      propertiesToUse = await searchProperty(
        propertyTypeEnumKeysArray.indexOf(type).toString(),
        states.indexOf(state).toString(),
        city,
        statuses.indexOf(status).toString()
      );
      setPropertiestoUse(propertiesToUse.filter(prop => prop.availability === true));
    }else{
      
      properties && setPropertiestoUse(properties.filter(prop => prop.availability === true) as [Property]);
    }
    // if (!type && !state && !city && !status) {
    //   console.log('here');
      
      
    //   return;
    // } else {
      
    // }
  };

  React.useEffect(() => {
    Search();
  }, [type, state, city, properties]);

  // let propertiesToUse = location.search.split(/\s+/).join('').length > 0 ? searchResult.properties : properties;
  let loadingState =
    location.search.split(/\s+/).join("").length > 0
      ? searchResult.isLoading
      : isLoading;

  // Fetch properties on page load
  // React.useEffect(()=>{
  //     dispatch(fetchProperties(localStorage.getItem('token') || undefined));
  // },[]);

  let testProperties: Property[] = [
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
  ];
  return (
    <React.Fragment>
      {/* <ScrollToTop /> */}
      <Layout>
        <NavHeader pageTitle="Properties" routeName="Properties" />
        <PropertyGrid
          properties={
            propertiestoUse && propertiestoUse.length > 0 ? propertiestoUse : []
          }
          loading={loadingState}
        />
      </Layout>
    </React.Fragment>
  );
};

export default PropertyGridPage;
