import { AxiosRequestConfig } from 'axios';
import { useEffect, useRef, useState } from 'react';
import axiosCall from '../utils/axiosCall';

interface IpostDataConfig extends AxiosRequestConfig {
    contentType?: string | undefined;
}

export const useFetch = () => {
    const [postData, setPostData] = useState<IpostDataConfig | undefined>(undefined);
    const [data, setData] = useState<any | null>(null);
    const [caller, setCaller] = useState<boolean>(false);
    const [error, setError] = useState<any>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef<boolean>(false);

    const recall = () => setCaller((prev) => !prev);

    const fetchApi = async () => {
        setLoading(true);
        setData(null);
        try {
            setError(false);
            let content = await axiosCall({
                url: postData && postData.url,
                data: postData && postData?.data,
                method: postData && postData.method,
                contentType: postData && postData.contentType,
                token: localStorage.token || '',
                headers: {
                    applicationId: '',
                    subscriptionKey: '',
                },
            });
            setLoading(false);
            setData(content);
            return;
        } catch (error: any) {
            setLoading(false);
            setError(error);
            if (error) {
                setData(error.message || error.response);
                return;
            }
            setData({ error });
        }
    };

    useEffect(() => {
        if (postData) {
            fetchApi();
        }
        return () => {
            cancelRequest.current = true;
        };
    }, [postData, caller]);

    return [setPostData, data, error, loading, recall];
};

export default useFetch;
