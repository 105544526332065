import React from "react";
import { Property } from "../../types/Property";
import { formatAmount, formatDate } from "../../utils/propertyUtils";
import styles from "./paymentConfirmation.module.scss";
import defaultHouseImg from "../../img/product-3/2.jpg";
import { AdditionalFee, InstallmentState } from "../Installment";
import { Plan } from "../PaymentPlans";

export interface PaymentConfirmationProps {
  property?: Property;
  installment?: InstallmentState;
  additionalFee?: AdditionalFee;
  plan?: Plan;
}

const PaymentConfirmation: React.FC<PaymentConfirmationProps> = ({
  property,
  plan = "one-off",
  additionalFee,
  installment,
}) => {
  let houseImage = defaultHouseImg;

  if (property?.attachments) {
    houseImage =
      property?.attachments.length > 0
        ? property.attachments[0].imageUrl
        : defaultHouseImg;
  }

  let balance = property?.price || 0;

  if (plan === "installment") {
    balance =
      balance + (additionalFee?.deposit || 0) + (additionalFee?.ourFee || 0);
  }

  // Calculate end date
  const getEndDate = () => {
    if (installment?.repayment && installment.startDate) {
      let payday = new Date(installment.startDate);
      payday.setDate(payday.getDate() + 365);
      return formatDate(payday);
    }
    return "";
  };

  return (
    <div className={styles.container}>
      <div className="row">
        {/** Breakdown */}
        <div className={`col-lg-6 col-sm-6 col-xs-12 ${styles.col}`}>
          <div className={styles.summary}>
            <table className={styles.table}>
              <tbody>
                <tr className={styles.headerRow}>
                  <th>Payment Summary</th>
                </tr>
                <tr>
                  <td>Rent/ Purchase:</td>
                  <td>₦ {formatAmount((property?.price || 0).toString())}</td>
                </tr>
                {plan === "installment" && (
                  <>
                    <tr>
                      <td>Refundable deposit (Caution fee for landlord):</td>
                      <td>
                        ₦{" "}
                        {formatAmount((additionalFee?.deposit || 0).toString())}
                      </td>
                    </tr>
                    <tr>
                      <td>Our Fee:</td>
                      <td>
                        ₦{" "}
                        {formatAmount((additionalFee?.ourFee || 0).toString())}
                      </td>
                    </tr>
                  </>
                )}
                <tr className={styles.balanceRow}>
                  <td>
                    <b>Total Amount:</b>
                    {""}
                  </td>
                  <td className={balance < 0 ? styles.debit : styles.credit}>
                    ₦{" "}
                    {formatAmount(
                      (property && property.totalPrice && property.price
                        ? property.totalPrice + property.price * 0.005
                        : property &&
                          property.price &&
                          property.price + property.price * 0.005
                      )?.toString() as string
                    )}
                  </td>
                </tr>
                {plan === "installment" && (
                  <>
                    <tr>
                      <td>
                        <b>Amount to pay:</b>{" "}
                      </td>
                      <td>
                        ₦
                        {balance % (installment?.repayment || 0) === 0
                          ? formatAmount(
                              (
                                balance / (installment?.repayment || 0)
                              ).toString()
                            )
                          : formatAmount(
                              (balance / (installment?.repayment || 0))
                                .toFixed(2)
                                .toString()
                            )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Start date:</b>{" "}
                      </td>
                      <td>
                        {installment?.startDate
                          ? formatDate(new Date(installment?.startDate))
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>End date:</b>{" "}
                      </td>
                      <td>{getEndDate()}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>
                    <b>Payment Method:</b>
                  </td>
                  <td>
                    {plan === "one-off" ? "Wallet" : "**** **** **** 1234"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/** Property Info */}
        <div className={`col-lg-6 col-sm-6 col-xs-12 ${styles.col}`}>
          <div className={`${styles.title}`}>
            <h4>{property?.title}</h4>
          </div>
          <div className={`row`}>
            <div className="col-lg-6">
              <table className={styles.propertyTable}>
                <tbody>
                  <tr>
                    <td>Price:</td>
                    <td>₦ {formatAmount((property?.price || 0).toString())}</td>
                  </tr>
                  <tr>
                    <td>Location:</td>
                    <td>
                      {property?.city}, {property?.state}
                    </td>
                  </tr>
                  <tr>
                    <td>Size:</td>
                    <td>{property?.size}</td>
                  </tr>
                  <tr>
                    <td>Bedrooms:</td>
                    <td>{property?.bedrooms}</td>
                  </tr>
                  <tr>
                    <td>Bathrooms:</td>
                    <td>{property?.bathrooms}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-6">
              <div className={styles.propertyImage}>
                <img src={houseImage} alt={property?.title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
