import React from 'react';
import Layout from '../../components/Layout';
import NavHeader from '../../components/NavHeader';
import ScrollToTop from '../../components/ScrollToTop';
import AboutUsPage from './AboutUsPage';


const OurAboutPage: React.FC<any> = ()=>{

    return(
        <React.Fragment>
            <ScrollToTop/>
            <Layout>
                <NavHeader
                    pageTitle="About Us"
                    routeName="About Us"
                />
                <AboutUsPage/>
                {/* <GetAQuote/> */}
            </Layout>
        </React.Fragment>
    )
}

export default OurAboutPage;