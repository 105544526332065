import React from 'react'
import Layout from '../../components/Layout'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'
import NavHeader from '../../components/NavHeader'
import ArtisansView from '../../views/Artisans'

const Artisans = () => {
  return (
    <Layout>
    <ScrollToTop />
    {/* <SessionCheck shouldRedirect /> */}
    <NavHeader pageTitle="Hire An Artisan" routeName="artisans" />
    <ArtisansView />
  </Layout>
  )
}

export default Artisans