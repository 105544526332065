import React from "react";
import styles from "./logout.module.scss";
import { useHistory } from "react-router-dom";
import HomeBuddyLogo from "../../../assets/HomeBuddy_RequestedColour_PrimaryLockup.svg";
import Lottie from "react-lottie";
import Logoutlottie from "../../../assets/Lotties/logoutAnimation.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Logoutlottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Logout = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack()
  }
  
  const logout = () => {
    localStorage.removeItem("paymentState");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    localStorage.removeItem("homezone_wallet");
    localStorage.removeItem("paymentState");
    history.push('/signin')
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <div
          className={styles.logoImage}
          role="button"
          onClick={() => history.push("/")}
        >
          <img src={HomeBuddyLogo} alt="HomeBuddyLogo" />
        </div>
      </div>

      <div className={styles.centerDiv}>
        <div>
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>

        <div className={styles.q1}>
          <span>ARE YOU SURE ? </span>
          <span className={styles.span}>You are about to leave HomeBuddy</span>
        </div>

        <div className={styles.q2}>
          <div className={styles.button} role="button" onClick={() => logout()}>
            <span className={styles.buttonspan}>Yes, Sign Out.</span>
          </div>
          <span className={styles.span}>
            No,{" "}
            <span role="button" style={{ color: "#91EA91" }} onClick={() => goBack()}>
              go back Home
            </span>{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Logout;
