import React, { useContext, useState } from "react";
import styles from "./openaccesspension.module.scss";
import Select, { ActionMeta, SingleValue, StylesConfig } from "react-select";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Calendar } from "primereact/calendar";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ApplyAccessPensions } from "../../swr/properties";
import { statesForSearch } from "../../types/states";
import ToastContext from "../../contexts/ToastContext";
import LoadingContext from "../../contexts/LoadingContext";

type GeneralDropDown = {
  label: string;
  value: string;
};

const OpenAccessPensionForm = () => {
  let params: { id: string } = useParams();
  const token = localStorage.getItem("token") as string;

  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();

  interface FormDataType {
    dateOfBirth: Date;
    todayDate: Date;
    haveRsaPIN: boolean;
    verifyInfo: boolean;
    FirstName: string;
    LastName: string;
    MiddleName: string;
    Title: string;
    Gender: string;
    MaritalStatus: string;
    StateOfResidence: string;
    ResidentialAddress: string;
    Phone: string;
    Email: string;
    EmployerName: string;
    EmployerAddress: string;
    PFA: string;
    RSAPIN: string;
    Signature: string;
  }

  const [formData, setFormData] = useState<FormDataType>({
    dateOfBirth: new Date(),
    todayDate: new Date(),
    haveRsaPIN: false,
    verifyInfo: false,
    FirstName: "",
    LastName: "",
    MiddleName: "",
    Title: "",
    Gender: "",
    MaritalStatus: "",
    StateOfResidence: "",
    ResidentialAddress: "",
    Phone: "",
    Email: "",
    EmployerName: "",
    EmployerAddress: "",
    PFA: "",
    RSAPIN: "",
    Signature: "",
  });

  const checkForErrors = (formData: FormDataType) => {
    const nonRequired = ["MiddleName", "RSAPIN", "PFA"];
    for (const key in formData) {
      if (
        !nonRequired.includes(key) &&
        typeof formData[key as keyof FormDataType] === "string" &&
        (formData[key as keyof FormDataType] as string).trim() == ""
      ) {
        return { bool: true, key };
      }
    }
    return { bool: false, key: "" };
  };

  const handleSubmit = async () => {
    let { bool, key } = checkForErrors(formData);
    if (bool === true) {
      openError(`${key} field is required`);
      return;
    }
    if (formData.haveRsaPIN === true && !formData.RSAPIN) {
      openError("Please enter your Retirement Savings Account Pin");
      return;
    }

    setLoading(true);
    const { data, error } = await ApplyAccessPensions(
      {
        title: handleTitleSelect(formData.Title),
        gender: handleGenderSelect(formData.Gender),
        maritalStatus: handleMaritalSelect(formData.MaritalStatus),
        firstName: formData.FirstName,
        lastName: formData.LastName,
        middleName: formData.MiddleName,
        dateOfBirth: formData.dateOfBirth,
        state: statesForSearch
          .map((item) => item.toLowerCase())
          .indexOf(formData.StateOfResidence.trim().toLowerCase()),
        address: formData.ResidentialAddress,
        phoneNumber: formData.Phone,
        email: formData.Email,
        employerName: formData.EmployerName,
        employerAddress: formData.EmployerAddress,
        currentPFA: formData.PFA,
        rsapin: formData.RSAPIN,
        hasRSAPIN: formData.haveRsaPIN,
        Signature: formData.Signature,
      },
      token
    );

    if (data && data.succeeded) {
      setLoading(false);
      openSuccess("Application was successful");
      history.push(`/properties/${params.id}`);
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        error.data?.response?.message ||
          "an error occurred creating your application"
      );
      return;
    }

    setLoading(false);
    openError("an error occurred creating your application");
  };

  const titleArr = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "Mr",
      value: "Mr",
    },
    {
      label: "Mrs",
      value: "Mrs",
    },
    {
      label: "Miss",
      value: "Miss",
    },
    {
      label: "Master",
      value: "Master",
    },
  ];

  const genderArr = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const maritalStatusArr = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "Single",
      value: "Single",
    },
    {
      label: "Married",
      value: "Married",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const hasRSAArr = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  const handleHasRsaSelection = (
    newValue: SingleValue<GeneralDropDown | null>,
    actionMeta: ActionMeta<GeneralDropDown | null>
  ) => {
    if (newValue?.label === "Yes") {
      setFormData((prev: any) => ({ ...prev, haveRsaPIN: true }));
    }
    if (newValue?.label === "No") {
      setFormData((prev: any) => ({ ...prev, haveRsaPIN: false }));
    }
  };

  const handleTitleSelect = (value: string) => {
    switch (value) {
      case "Mr":
        return 1;
      case "Mrs":
        return 2;
      case "Miss":
        return 3;
      default:
        return 4;
    }
  };

  const handleTitleSelection = (
    newValue: SingleValue<GeneralDropDown | null>,
    actionMeta: ActionMeta<GeneralDropDown | null>
  ) => {
    if (newValue) {
      setFormData((prev: any) => ({ ...prev, Title: newValue.value }));
    }
  };

  const handleGenderSelect = (value: string) => {
    switch (value) {
      case "Male":
        return 1;
      case "Female":
        return 2;
      default:
        return 3;
    }
  };

  const handleGenderSelection = (
    newValue: SingleValue<GeneralDropDown | null>,
    actionMeta: ActionMeta<GeneralDropDown | null>
  ) => {
    if (newValue) {
      setFormData((prev: any) => ({ ...prev, Gender: newValue.value }));
    }
  };

  const handleMaritalSelection = (
    newValue: SingleValue<GeneralDropDown | null>,
    actionMeta: ActionMeta<GeneralDropDown | null>
  ) => {
    if (newValue) {
      setFormData((prev: any) => ({ ...prev, MaritalStatus: newValue.value }));
    }
  };

  const handleMaritalSelect = (value: string) => {
    switch (value) {
      case "Single":
        return 1;
      case "Married":
        return 2;
      default:
        return 3;
    }
  };

  const handleTypedOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value.toUpperCase() }));
  };

  const radioOptions = [
    // {
    //   text: "I have an RSA PIN",
    //   onchange: () =>
    //     setFormData((prev: any) => ({ ...prev, haveRsaPIN: !prev.haveRsaPIN })),
    //   value: formData.haveRsaPIN,
    // },
    // {
    //   text: "I do not have an RSA PIN",
    //   onchange: () =>
    //     setFormData((prev: any) => ({ ...prev, haveRsaPIN: !prev.haveRsaPIN })),
    //   value: !formData.haveRsaPIN,
    // },
    {
      text: "I therefore verify that the information provided on this Expression of I ferret form is true and correct. I authorize Access Pensions to contact me with these information",
      onchange: () =>
        setFormData((prev: any) => ({ ...prev, verifyInfo: !prev.verifyInfo })),
      value: formData.verifyInfo,
    },
  ];

  return (
    <div className={styles.supercontainer}>
      <p className={styles.instructions1}>
        Please fill the form below to express your interest in the Access
        Pensions Mortgage
      </p>
      <div className={styles.instruction}>
        <span className={styles.instructions2}>
          Asterisks fields are compulsory
        </span>
        <span className={styles.instructions2}>
          Fill in capital letters only
        </span>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>
          <div className={styles.headerblock}></div>
          <div className={styles.headertext}>Section A: Personal Data</div>
        </div>

        <div className={styles.formoutter}>
          <div className={styles.form}>
            <div className={styles.columngrid}>
              <div className={styles.inputcolumn}>
                <span>
                  Title<span className={styles.red}>*</span>
                </span>
                <Select
                  defaultValue={null}
                  options={titleArr && titleArr.length > 0 ? titleArr : []}
                  name="title"
                  onChange={handleTitleSelection}
                  className={styles.selectinput}
                  placeholder="Select a preferred status"
                />
              </div>
              <div className={styles.inputcolumn}>
                <span>
                  Gender<span className={styles.red}>*</span>
                </span>
                <Select
                  defaultValue={null}
                  options={genderArr && genderArr.length > 0 ? genderArr : []}
                  name="title"
                  onChange={handleGenderSelection}
                  className={styles.selectinput}
                  placeholder="Select your gender"
                />
              </div>
              <div className={styles.inputcolumn}>
                <span>
                  Marital Status<span className={styles.red}>*</span>
                </span>
                <Select
                  defaultValue={null}
                  options={
                    maritalStatusArr && maritalStatusArr.length > 0
                      ? maritalStatusArr
                      : []
                  }
                  name="title"
                  onChange={handleMaritalSelection}
                  className={styles.selectinput}
                  placeholder="Select your marital status"
                />
              </div>
            </div>

            <div className={styles.columngrid}>
              <div className={styles.inputcolumn}>
                <span>
                  First Name<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type your first name"
                  name="FirstName"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.FirstName}
                />
              </div>
              <div className={styles.inputcolumn}>
                <span>
                  Last Name<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type your last name"
                  name="LastName"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.LastName}
                />
              </div>
              <div className={styles.inputcolumn}>
                <span>Middle Name</span>
                <input
                  placeholder="Type your middle name"
                  name="MiddleName"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.MiddleName}
                />
              </div>
            </div>

            <div className={styles.columngrid}>
              <div className={styles.input2column}>
                <span>
                  Date of Birth<span className={styles.red}>*</span>
                </span>
                <Calendar
                  value={formData.dateOfBirth}
                  onChange={(e) =>
                    setFormData((prev: any) => ({
                      ...prev,
                      dateOfBirth: e.value,
                    }))
                  }
                  className={styles.dateinput}
                  panelClassName={styles.panel}
                  inputStyle={{ height: "5em" }}
                />
              </div>
              <div className={styles.input2column}>
                <span>
                  State of Residence<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type your state of residence"
                  name="StateOfResidence"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.StateOfResidence}
                />
              </div>
            </div>
            <div className={styles.columngrid}>
              <div className={styles.input1column}>
                <span>
                  Residential Address<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type the address you live at"
                  name="ResidentialAddress"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.ResidentialAddress}
                />
              </div>
            </div>

            <div className={styles.columngrid}>
              <div className={styles.input2column}>
                <span>
                  Phone Number<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type your phone number"
                  name="Phone"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.Phone}
                />
              </div>
              <div className={styles.input2column}>
                <span>
                  Email Address<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type your email address"
                  name="Email"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.Email}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>
          <div className={styles.headerblock}></div>
          <div className={styles.headertext}>Other Information</div>
        </div>

        <div className={styles.formoutter}>
          <div className={styles.form}>
            <div className={styles.columngrid}>
              <div className={styles.input1column}>
                <span>
                  Employer's Name<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type your employer's name"
                  name="EmployerName"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.EmployerName}
                />
              </div>
            </div>
            <div className={styles.columngrid}>
              <div className={styles.input1column}>
                <span>
                  Employer's Address<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type the address of your employer"
                  name="EmployerAddress"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.EmployerAddress}
                />
              </div>
            </div>
            <div className={styles.columngrid}>
              <div className={styles.input1column}>
                <span>
                  Name of Current PFA<span className={styles.red}>*</span>
                </span>
                <input
                  placeholder="Type the name of your current Pension Funds Administrator"
                  name="PFA"
                  onChange={handleTypedOnchange}
                  className={styles.inputfield}
                  autoComplete="off"
                  value={formData.PFA}
                />
              </div>
            </div>
            <div className={styles.columngrid}>
              <div className={styles.input1column}>
                <span>
                  Do you have a Retirement Saving Account Pin ?
                  <span className={styles.red}>*</span>
                </span>
                <Select
                  defaultValue={null}
                  options={hasRSAArr && hasRSAArr.length > 0 ? hasRSAArr : []}
                  name="hasRsa"
                  onChange={handleHasRsaSelection}
                  className={styles.selectinput}
                  placeholder="Select your answer"
                />
              </div>
            </div>
            {formData.haveRsaPIN === true && (
              <div className={styles.columngrid}>
                <div className={styles.input1column}>
                  <span>RSA PIN</span>
                  <input
                    placeholder="Type your Retirement Savings Account Pin"
                    name="RSAPIN"
                    onChange={handleTypedOnchange}
                    className={styles.inputfield}
                    autoComplete="off"
                    value={formData.RSAPIN}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.radiocontainer}>
        {radioOptions.map((radio) => (
          <div className={styles.radiorow} key={radio.text}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={radio.value}
                  onChange={() => radio.onchange()}
                  color="success"
                />
              }
              label={radio.text}
            />
          </div>
        ))}
      </div>

      <div
        className={styles.columngrid}
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        <div className={styles.input1column}>
          <span>
            Signature<span className={styles.red}>*</span>
          </span>
          <input
            placeholder="Input your first, middle and last name"
            name="Signature"
            onChange={handleTypedOnchange}
            className={styles.inputfield}
            autoComplete="off"
            value={formData.Signature}
          />
        </div>
      </div>

      <div
        className={styles.columngrid}
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        <div className={styles.input1column}>
          <span>
            Date<span className={styles.red}>*</span>
          </span>
          <Calendar
            value={formData.todayDate}
            onChange={(e) =>
              setFormData((prev: any) => ({
                ...prev,
                todayDate: e.value,
              }))
            }
            className={styles.dateinput}
            panelClassName={styles.panel}
            inputStyle={{ height: "5em" }}
            placeholder="input today's date"
          />
        </div>
      </div>

      <div
        className={styles.button}
        onClick={() => handleSubmit()}
        role="button"
      >
        Submit
      </div>
    </div>
  );
};

export default OpenAccessPensionForm;
