import { useEffect, useState, useContext } from 'react';
import LoginAccount from '../../../components/Form/LoginAccount';
import FormComp from '../../../components/Form';
import useFetch from '../../../hooks/useFetch';
import useForm from '../../../hooks/useForm';
import backendRoutes from '../../../utils/backendRoutes';
import { useHistory, useLocation } from 'react-router-dom';
import { setError, setUser, setToken, User } from '../../../state/reducers/authSlice';
import { useAppDispatch } from '../../../hooks/useSelector';
import LoadingScreen from '../../../components/LoadingScreen';
import SessionContext from '../../../contexts/SessionContext';
import ToastContext from '../../../contexts/ToastContext';
import { useQuery } from '../../../hooks/useQuery';
import ResetPasswordAccount from '../../../components/Form/ResetPassword';
import { resetUserPassword } from '../../../swr/user';
import LoadingContext from '../../../contexts/LoadingContext';

const ResetPassword = () => {
    const dispatch: any = useAppDispatch();
    const [resetSuccess, setResetSuccess] = useState<boolean>(false);
    // const { formValues, handleChange } = useForm({ email: '', password: '', confirmPassword: '' });
    const [formValues, setFormValues] = useState({password: '', confirmPassword: '', email: '', token: '' })
    const [resetPostData, resetRes, resetErr] = useFetch();
    const [resetPasswordCalled, setResetPasswordCalled] = useState(false)
    const { push, goBack } = useHistory();
    const query = useQuery();

    // Context
    const { setIsExpired } = useContext(SessionContext);
    const { openError, openSuccess } = useContext(ToastContext);
    const { setLoading } = useContext(LoadingContext);


    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token') as string;
    const email = queryParams.get('email') as string;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value})
    }
//     data
// : 
// {succeeded: true, message: 'Password Resetted.', errors: null, data: 'olufadevictor97@gmail.com'

    const handleResetPasswordSuccess = () => {
        openSuccess('Password has been reset')
        setLoading(false)
        push('/signin')
    }

    const handleResetPasswordFailure = () => {
        openError('an error occured while resetting password')
        setLoading(false)
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(formValues.password !== formValues.confirmPassword){
            openError('Passwords must match')
            return;
        }
        setLoading(true)
        formValues.token = token;
        formValues.email = email;
        try {
            const response = await resetUserPassword(formValues);
            if(response && response.data){
                let res = response.data;
                res.succeeded === true ?
                handleResetPasswordSuccess()
                :
                handleResetPasswordFailure()
            }else{
                handleResetPasswordFailure()
            }
        } catch (error) {
            handleResetPasswordFailure() 
        }
        
    };

    // const ResetPasswordUser = async (payload: { [key: string]: string }) => {
    //     setResetSuccess(false);
    //     const response = await resetPostData({
    //         url: backendRoutes.resetPassword,
    //         data: payload,
    //         method: 'POST',
    //     });

    //     return response;
    // };

    // const setLocalStorageToken = (token: string) => {
    //     localStorage.setItem('token', token);
    //     dispatch(setToken(token));
    // };

    // const setResetPasswordUser = (user: User, token: string) => {
    //     setResetSuccess(true);
    //     setLocalStorageToken(token);
    //     setIsExpired(false);
    //     dispatch(setUser({ ...user }));
    // };

   

    // useEffect(() => {
    //     if(resetPasswordCalled === true){
    //         if (resetRes) {
    //             if (!resetErr && resetRes?.succeeded) {
    //                 setResetPasswordUser(resetRes.data, resetRes.data.jwToken);
    //                 console.log(resetRes.message || 'Reset Password was successful.');
    //                 query.get('redirect') ? push(`/${query.get('redirect')}`) : push('/');
            
    //             } else {
    //                 setResetSuccess(false);
    //                 console.log(resetErr.response?.data.Message|| 'Operation failed. Something went wrong');
    //                 openError(resetErr.response?.data.Message|| 'An unknown error occured while reseting your password');
    //                 dispatch(setError());
    //             }
    //         }
    //     }
  
    // }, [resetRes, resetPasswordCalled]);

    return (
        <>
        {/** Added Loading screen */}
        {/* {isLoading && <LoadingScreen/>} */}
        <FormComp
            title="Reset Password"
            textContent=""
            paddingSize="pb-65"
            pageName="Reset Password"
            pageTitle="Reset Password Page"
        >
            {resetSuccess}
            <ResetPasswordAccount formValues={formValues} handleChange={handleChange} onSubmit={handleSubmit} link="/account" userName={''} />
        </FormComp>
        </>
    );
};

export default ResetPassword;


