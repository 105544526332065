import React, { useState } from "react";
import styles from "./marketpdetails.module.scss";
import MarketProductMetaData from "../../components/MarketProductMetaData";
import { MarketData } from "../../views/MarketPlaceView/dataMarket";
import PropertyElement from "../../components/PropertyElements/Element";
import Description from "../../components/PropertyElements/Description";
import Gallery from "../../components/PropertyElements/Gallery";
import Detail from "../../components/PropertyElements/Detail";
import { Skeleton } from "@mui/material";
import Reviews from "../../components/PropertyElements/Reviews";
import MarketPdtDetail from "../../components/PropertyElements/Detail/marketPdtDetail";
import Modal from "react-modal";
import PaymentModal from "./PaymentModal";

const Index = ({ product }: { product: MarketData }) => {
  const [isMakePayment, setIsMakePayment] = useState(false);
  const closeMakePayment = () => {
    setIsMakePayment(false);
  };
  const openMakePayment = () => {
    setIsMakePayment(true);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          {/** Left side */}
          <div className="container">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              {/** Metadata */}

              <div className={styles.makePayment}>
                <MarketProductMetaData
                  category={product?.category}
                  date={product?.date && new Date(product.date)}
                  id={product?.id || 0}
                  manufacturer={product.manufacturer}
                />
                <button
                  onClick={() => {
                    setIsMakePayment(true)
                  }}
                  className={`btn theme-btn-${1} btn-effect-1 text-uppercase ${
                    styles.btn
                  }`}
                >
                  Make Payment
                </button>
              </div>

              {/** Heading with Address */}
              <h1>{product?.title}</h1>

              {/** Description */}
              <PropertyElement title="Description">
                <Description description={product?.description} />
              </PropertyElement>

              {/** Property Detail */}
              {/* <div className="container"> */}
              <PropertyElement title="Property Detail">
                <MarketPdtDetail {...product} />
              </PropertyElement>
              {/* </div> */}

              {/** Gallery */}
              <div className="col-lg-8">
                <PropertyElement title="From Our Gallery">
                  {/* <Gallery attachments={product?.image} /> */}
                  <div className={styles.imgContainer}>
                    <img
                      src={product?.image}
                      srcSet={product?.image}
                      alt={"item"}
                      loading="lazy"
                    />
                  </div>
                </PropertyElement>
              </div>

              {/** Ratings and review */}
              <div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 ltn__product-details-review-inner mb-60">
                <PropertyElement title="Customer Reviews">
                  <Reviews rating={product.ratings} count={5} />
                </PropertyElement>

                <hr />

                {/** Comments Area */}

                {/* <h4 className="title-2">Related Properties</h4>
                <RelatedProperties isMortgageProp={isMortgageProp}/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isMakePayment}
        onRequestClose={closeMakePayment}
        style={{
          content: {
            top: "30%",
            left: "30%",
            right: "30%",
            bottom: "auto",
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            backgroundColor: "white",
            height: "50%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Customer Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <PaymentModal price={product.price} close={closeMakePayment} />
      </Modal>
    </>
  );
};

export default Index;
