import { Tooltip } from '@mui/material';
import React from 'react'
import NavHeader from '../../../components/NavHeader';
import styles from './payment.module.scss';

const SignUpSuccess = () => {
  return (
    <>
    <section>
      <NavHeader
        pageTitle="SignUp-Success"
        routeName="sign-up-success"
      />
      <div style={{margin: "auto", width: "75%", display: "flex", flexDirection: "column"}}>
      <b style={{paddingBottom: "2em"}}>You have successfully registered</b> 
      <p>Please check your email and follow the sent link</p>
      </div>
    </section>
    </>
  )
}

export default SignUpSuccess
