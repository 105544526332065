import React, { useContext, useState } from "react";
import styles from "./home-owners.module.scss";
import {
  formatAmount,
  returnMsgFromAxiosError,
} from "../../../../utils/generalUtils";
import ModalLayout from "../../../../components/ModalLayout";
import { RentDropDown } from "../../../../types/Insurance";
import SaveQuoteSuccess from "../../../../components/Insurance/RentDefault/SaveQuoteSuccess";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setRentDropValue } from "../../../../state/reducers/insuranceSlice";
import PaymentModal from "../../../../components/Insurance/RentDefault/PaymentModal";
import PaymentSuccessful from "../../../../components/Insurance/RentDefault/PaymentSuccessful";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { homeOwnersMakePaymentApi } from "../../../../swr/insurance";
import LoadingContext from "../../../../contexts/LoadingContext";
import ToastContext from "../../../../contexts/ToastContext";

const GetQuoteSuccess = ({
  rentDropDownValue,
  closeAllDropDowns,
  walletBalance,
}: {
  rentDropDownValue: RentDropDown;
  closeAllDropDowns: () => void;
  walletBalance?: string;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { HomeOwnerGetPolicyRes, ReapplyHomeowners } = useSelector(
    (state: RootState) => state.insurance
  );
  const { setLoading } = useContext(LoadingContext);
  const { openError, openSuccess } = useContext(ToastContext);

  const [pin, setPin] = useState("")
  const pinOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value)
  }

  const token = localStorage.getItem("token") as string;

  const viewSavedQuotes = () => {
    closeAllDropDowns();
    history.push("/insurance");
  };
  const goHome = () => {
    history.push("/");
    closeAllDropDowns();
  };

  const openSavedQuoteSuccess = () => {
    dispatch(setRentDropValue("SaveQuoteSuccess"));
  };

  const openPaymentModal = () => {
    dispatch(setRentDropValue("PaymentModal"));
  };

  const ProceedWithPayment = async () => {
    if(!pin || pin.length !== 4){
      openError("Please enter your transaction pin")
      return
    }
    closeAllDropDowns();
    setLoading(true);
    const { data, error } = await homeOwnersMakePaymentApi(
      {
        payAmount:
          HomeOwnerGetPolicyRes?.amountToPay || ReapplyHomeowners?.premium,
        policyId:
          HomeOwnerGetPolicyRes?.policyId || ReapplyHomeowners?.policyId,
        paymentMethod: "HOMEBUDDY",
        transId: HomeOwnerGetPolicyRes?.policyId || ReapplyHomeowners?.policyId,
      },
      pin,
      token
    );

    if (data) {
      setLoading(false);
      dispatch(setRentDropValue("PaymentSuccess"));
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "An error occurred making payment")
      );
      return;
    }
    setLoading(false);
    openError("An error occurred making payment");
  };

  const fundWallet = () => {
    history.push("/account#liton_tab_1_6");
    closeAllDropDowns();
  };

  const closePaymentSuccessModal = () => {
    closeAllDropDowns()
    history.push("/insurance");
  }

  return (
    <>
      <div className={styles.form}>
        <div className={styles["get-quote-cont"]}>
          <span className={styles["get-quote-header"]}>Congratulations!</span>

          <span className={styles["get-quote-text"]}>
            You have successfully gotten a quote for<br></br> HomeOwners
            Insurance policy.
          </span>

          <div className={styles["get-quote-card-I"]}>
            {HomeOwnerGetPolicyRes?.policyNumber ||
            ReapplyHomeowners?.policyNo ? (
              <div className={styles["get-quote-card-cont"]}>
                <span className={styles["get-quote-card-p"]}>
                  Insurance Policy Number
                </span>
                <span className={styles["get-quote-card-span-top"]}>
                  {HomeOwnerGetPolicyRes && HomeOwnerGetPolicyRes.policyNumber
                    ? HomeOwnerGetPolicyRes.policyNumber
                    : ReapplyHomeowners?.policyNo}
                </span>
              </div>
            ) : null}

            <div className={styles["get-quote-card-cont"]}>
              <span className={styles["get-quote-card-p"]}>
                Payable Premium
              </span>
              <span className={styles["get-quote-card-span"]}>
                ₦
                {HomeOwnerGetPolicyRes && HomeOwnerGetPolicyRes.amountToPay
                  ? formatAmount(HomeOwnerGetPolicyRes.amountToPay)
                  : ReapplyHomeowners?.premium
                  ? formatAmount(ReapplyHomeowners.premium)
                  : ""}
              </span>
            </div>
          </div>

          <div className={styles["get-quote-buttons"]}>
            <div
              className={styles["get-quote-but-green"]}
              role="button"
              onClick={() => openPaymentModal()}
            >
              <span>Pay Now</span>
            </div>
            <div
              className={styles["get-quote-but-white"]}
              role="button"
              onClick={() => openSavedQuoteSuccess()}
            >
              <span>Save Quote</span>
            </div>
          </div>
        </div>
      </div>

      <ModalLayout modalOpen={rentDropDownValue === "SaveQuoteSuccess"}>
        <SaveQuoteSuccess
          close={closeAllDropDowns}
          viewSavedQuotes={viewSavedQuotes}
          goHome={goHome}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "PaymentModal"}>
        <PaymentModal
          Proceed={ProceedWithPayment}
          FundWallet={fundWallet}
          closeAllModals={closeAllDropDowns}
          walletBalance={walletBalance}
          amountToPay={
            HomeOwnerGetPolicyRes && HomeOwnerGetPolicyRes.amountToPay
              ? HomeOwnerGetPolicyRes.amountToPay
              : ReapplyHomeowners?.premium
              ? ReapplyHomeowners?.premium
              : 0
          }
          pinOnChange={pinOnChange}
          pin={pin}
        />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "PaymentSuccess"}>
        <PaymentSuccessful
          close={closePaymentSuccessModal}
          getNewPolicy={viewSavedQuotes}
          goHome={goHome}
        />
      </ModalLayout>
    </>
  );
};

export default GetQuoteSuccess;
