import React, { useContext, useEffect } from "react";
import { Property } from "../../types/Property";
import { formatAmount, simulateStatus } from "../../utils/propertyUtils";
import styles from "./propertyCard.module.scss";
import defaultUserImg from "../../assets/anne.jpeg";
import housingImage from "../../assets/housing.jpeg";
import defaultUserImg2 from "../../img/blog/author.jpg";
import defaultHouseImg from "../../img/product-3/2.jpg";
import { Link } from "react-router-dom";
import { Player } from "video-react";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router";
import ToastContext from "../../contexts/ToastContext";
import jwt, { Jwt } from "jsonwebtoken";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { saveProperty } from "../../swr/properties";
import { isTokenExpired } from "../../utils/tokenUtils";

export interface PropertyCardProps {
  property: Property;
}

const PropertyCard: React.FC<PropertyCardProps> = ({ property }) => {
  const history = useHistory();
  const { openError, openSuccess } = useContext(ToastContext);
  let houseImage = defaultHouseImg;
  let userImage = defaultUserImg2;
  const token = localStorage.getItem("token") as string;

  const IsTokenExpired = isTokenExpired(token);

  if (property && property.attachments) {
    houseImage =
      property.attachments.length > 0
        ? property.attachments[0].imageUrl
        : defaultHouseImg;
  }

  // Simulated Status
  let num = property && property.isEnabled === true ? 1 : 0;
  let status = simulateStatus(num);

  let ownerIsLoggedIn: boolean = false;

  if (token) {
    let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken.payload === "object") {
      ownerIsLoggedIn = decodedToken.payload.email === property?.user?.email;
    }
  }

  const handleNavigateToProperty = (available?: boolean, id?: number) => {
    if (available === true || ownerIsLoggedIn === true) {
      history.push(`/properties/${id}`);
    } else {
      openError(`${property.title} is not available`);
    }
  };

  const handleSaveProperty = async (id: number | undefined) => {
    const { data, error } = await saveProperty({ id: id?.toString(), token });
    if (data) {
      openSuccess(`${property.title} saved successfully`);
    }

    if (error) {
      openError(
        error.response.data.Message
          ? error.response.data.Message
          : `error saving ${property.title}`
      );
    }
  };

  const isMortgage = property.status === "Mortgage";

  return (
    <>
      {property && (
        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
          {/** Image */}
          <div className="product-img">
            {/* <Link to={`/properties/${property.id}`} > */}
            <div
              className={styles.image}
              onClick={() => handleNavigateToProperty(property.availability, property.id)}
            >
              {houseImage.endsWith("mp4") ? (
                <div className={styles.video}>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    autoPlay={true}
                    muted={true}
                  >
                    <source src={houseImage} />
                  </video>
                </div>
              ) : (
                <img src={houseImage} alt="#" />
              )}
            </div>
            {/* </Link> */}
            {/* <div className={`real-estate-agent`}>
              <div className={`agent-img ${styles.agentImage} `} onClick={() => handleNavigateToProperty(property.availability)}>
                <a href="team-details.html">
                  <img src={userImage} alt="#" />
                </a>
              </div>
            </div> */}
          </div>

          {/** Body */}
          <div className="product-info">
            {/** Status */}
            <div className="product-badge">
              <ul>
                <li className="sale-badg">For {property.status}</li>
              </ul>
            </div>

            {/** Title */}
            <h2
              className="product-title"
              onClick={() => handleNavigateToProperty(property.availability, property.id)}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {/* <Link to={`/properties/${property.id}`}> */}
              {property.title}
              {/* </Link> */}
            </h2>

            {/** Location */}
            <div className="product-img-location">
              <ul>
                <li>
                  <a href={`/properties/${property?.id}`}>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                    >
                      <i className="flaticon-pin"></i> {property.city},{" "}
                      {property.state}
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            {/** Amenities */}
            <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
              <li>
                <span>{property.bedrooms || 0} </span>
                Bed
              </li>
              <li>
                <span>{property.bathrooms || 0} </span>
                Bath
              </li>
              <li>
                <span>{property.size} </span>
                Square Ft
              </li>
            </ul>

            {/** Hover Section */}
            <div className="product-hover-action">
              <ul>
                {/* <li className={`${styles.status} ${styles.amber}`}>
                              <a href="#" title="Unverified" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
                                  <i className="flaticon-heart-1"></i>
                              </a> 
                          </li> */}

                <li className={`${styles.status} ${styles[status.status]}`}>
                  <Tooltip title={status.label}>
                    <i className={status.icon} aria-hidden="true"></i>
                  </Tooltip>
                </li>
                <li
                  onClick={() =>
                    handleNavigateToProperty(property.availability, property.id)
                  }
                >
                  <i className="flaticon-add"></i>
                  {/* </Link> */}
                </li>
                {!IsTokenExpired && !ownerIsLoggedIn && (
                  <li
                    title="save"
                    role="button"
                    onClick={() => handleSaveProperty(property?.id)}
                  >
                    <BookmarkBorderIcon sx={{ color: "#4BB543" }} />
                  </li>
                )}

                <li className={styles.buttonItem}>
                  {property.status === "Sale" ? (
                    <div
                      //   to={`/properties/${property?.id}`}
                      title="View Property"
                      role="button"
                      onClick={() =>
                        handleNavigateToProperty(property.availability, property.id)
                      }
                      className={styles.applyButton}
                    >
                      View
                    </div>
                  ) : (
                    <div
                      //   to={`/properties/${property?.id}`}
                      title="View Property"
                      role="button"
                      onClick={() =>
                        handleNavigateToProperty(property.availability, property.id)
                      }
                      className={styles.applyButton}
                    >
                      View
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>

          {/* <div className={styles.saveDiv}>
           <BookmarkBorderIcon sx={{ color: '#91EA91' }} />
          </div> */}

          {/** Price */}
          <div className="product-info-bottom">
            <div className="product-price">
              <span>
                ₦{formatAmount(property?.price?.toString())}
                {property.status !== "Sale" && property.duration && (
                  <label>({property.duration})</label>
                )}
              </span>

              <span className={styles.realEstate} >
                {
                  property && property.realEstateName ? property.realEstateName : ''
                }
              </span>
            </div>
          </div>

          <div className={styles.realEstateDiv}>
            {property && property.realEstateName && (
              <span className={styles.realEstate}>
                {property.realEstateName}
              </span>
            )}

            {property.status !== "Rent" && (
              <span className={styles.completed}>
                {property && property.isCompleted === true
                  ? "Completed"
                  : "Ongoing"}
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyCard;
