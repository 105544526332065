import { motion, TargetAndTransition } from 'framer-motion';
import React from 'react';
import styles from './textarea.module.scss';

export interface TextAreaProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>{
    error?: boolean;
    errorMessage?: string;
    hideDefaultAdornment?: boolean;
    clear?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
    placeholder, name, value, error = false, 
    onChange, id, hideDefaultAdornment, clear,
    errorMessage = 'This field is required',
    ...props
})=>{

    // State
    const [textValue, setValue] = React.useState<string|number|readonly string[]>();
    const [isError, setError] = React.useState<boolean>(error);
    
    let shake: TargetAndTransition = {
        x:[0, -10, 10, 0, -10, 10, 0],
        transition:{
            duration: 0.25
        }
    }

    let animation = isError ? shake : undefined;

    
    // Effect
    React.useEffect(()=>{
        setError(error)
    }, [error]);


    React.useEffect(()=>{
        setValue(value);
    }, [value])

    React.useEffect(()=>{
        if (clear) {
            setValue('');
        }
    }, [clear])

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>)=>{
        let inputValue = event.currentTarget.value;
        setValue(inputValue);
        onChange && onChange(event);
    }

    return(
        <motion.div animate={animation}
            className={`input-item input-item-textarea ${hideDefaultAdornment ? '' : 'ltn__custom-icon'} ${styles.textArea}`}
        >
            <textarea {...props} id={id} name={name} placeholder={placeholder} value={textValue} onChange={handleChange} ></textarea>
            {isError && <label htmlFor={id}>{errorMessage}</label>}
        </motion.div>
    )
}

export default TextArea;