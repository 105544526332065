import React from 'react';
import styles from './payment.module.scss';
import visaLogo from '../../assets/visa.svg';
import verveLogo from '../../assets/verve.svg';
import mastercardLogo from '../../assets/mastercard.svg';
import Tooltip from '@mui/material/Tooltip';

export interface CardPaymentAccordionProps{
    children?: React.ReactNode | React.ReactNode[];
    issuerToken?: string;
    holderName?: string;
    onDelete?: (id?: number)=> void;
    cardId?: number;
}
const CardPaymentAccordion: React.FC<CardPaymentAccordionProps> = ({ 
    children, issuerToken, holderName, onDelete, cardId
})=>{

    const contentEl = React.useRef<HTMLDivElement>(null);
    const [active, setActive] = React.useState<boolean>(false);

    // Handler
    const toggle = ()=>{
        setActive(!active);
    }
    const fetchImage = ()=>{
        switch (issuerToken) {
            case "0":
                return visaLogo;

            case "1":
                return mastercardLogo;
        
            default:
                return verveLogo;
        }
    }
    const fetchLastDigits = ()=>{
        switch (issuerToken) {
            case "0":
                return "1111";

            case "1":
                return "4151";
        
            default:
                return "3577";
        }
    }

    return(
        <div className={styles.accordion}>
            <div className={styles.cardArea}>

                {/** Card and Name */}
               <div className={styles.cardLogo}>
                    {/** Logo */}
                    <img src={fetchImage()} alt="visa logo" />

                    {/** Number and Name */}
                    <div className={styles.nameNumber}>
                        <p>**** {fetchLastDigits()}</p>
                        <p>{holderName}</p>
                    </div>
               </div>

               {/** Action Buttons */}
               <div className={styles.cardAction}>
                    <Tooltip title="Edit">
                        <i className="far fa-edit" onClick={toggle}></i>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <i className="far fa-trash-alt" onClick={()=> onDelete && onDelete(cardId)}></i>
                    </Tooltip>
               </div>
           </div>

            <div className={`ltn_coupon-code-form ltn__form-box ${styles.contentWrapper}`}
                ref={contentEl}
                style={
                    active
                        ? { height: '490px', padding: '30px', borderWidth: '1px'}
                        : { height: "0px" }
                } 
            >
                { children }
            </div>
        </div>
    )
}

export default CardPaymentAccordion;