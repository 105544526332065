import React from 'react'
import { BillResponseData } from '../../types/Billings'
import DiscoFormBill from '../../components/Forms/DiscoBillForm'
import AirTimeForm from '../../components/Forms/AirTimeForm'
import PaidTvForm from '../../components/Forms/PaidTvForm'

interface UtilityFormsProps {
  selectedBiller: BillResponseData
}

const UtilityForms: React.FC<UtilityFormsProps> = ({selectedBiller}) => {
  return (
    <>
    {
      selectedBiller.id.toString() === '2' && (
        <DiscoFormBill selectedBiller={selectedBiller}/>
      )
    }
    {
      selectedBiller.id.toString() === '6' && (
        <AirTimeForm selectedBiller={selectedBiller}/>
      )
    }
    {
      selectedBiller.id.toString() === '3' && (
        <PaidTvForm selectedBiller={selectedBiller}/>
      )
    }
    </>
  )
}

export default UtilityForms