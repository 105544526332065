// // import React from 'react';
// // import CommentCard, { CommentCardProps } from '../CommentCard';

// // export interface CommentSectionProps{
// //     comments?: CommentCardProps[];
// //     demo?: boolean;
// // }

// // const CommentSection: React.FC<CommentSectionProps> = ({ comments, demo = false })=>{

// //     return(
// //         <div className="ltn__comment-area mb-30">
// //             <div className="ltn__comment-inner">
// //                 <ul>
// //                     {
// //                         comments &&
// //                         comments.map((props, index)=>(
// //                             <li key={`comm${index}`}>
// //                                 <CommentCard {...props} />
// //                             </li>
// //                         ))
// //                     }
// //                     {
// //                         demo &&
// //                         [0,1,2].map((item, index)=>(
// //                             <li key={`comm${index}`}>
// //                                 <CommentCard />
// //                             </li>
// //                         ))
// //                     }
// //                 </ul>
// //             </div>
// //         </div>
// //     )
// // }

// // export default CommentSection;

// import React from 'react';
// import CommentCard, { CommentCardProps } from '../CommentCard';

// export interface CommentSectionProps {
//   comments?: CommentCardProps[];
//   demo?: boolean;
// }

// const CommentSection: React.FC<CommentSectionProps> = ({ comments = [], demo = false }) => {
//   if (!comments || comments.length === 0) {
//     return <div>No comments available.</div>;
//   }

//   return (
//     <div className="ltn__comment-area mb-30">
//       <div className="ltn__comment-inner">
//         <ul>
//           {comments && comments.map((props, index) => (
//             <li key={`comm${index}`}>
//               <CommentCard {...props} />
//             </li>
//           ))}
//           {demo &&
//             [0, 1, 2].map((item, index) => (
//               <li key={`comm${index}`}>
//                 <CommentCard />
//               </li>
//             ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default CommentSection;
import React from 'react'
import CommentCard, { CommentCardProps } from '../CommentCard'
import { usePropertyRating } from '../../../../swr/ratings'
import Stack from '@mui/system/Stack'
import Pagination from '@mui/material/Pagination'

export interface CommentSectionProps {
  comments?: CommentCardProps[]
  demo?: boolean
  propertyId: number
}

const CommentSection: React.FC<CommentSectionProps> = ({
  comments = [],
  demo = false,
  propertyId,
}) => {
  const {
    data: rating,
    isLoading: ratingLoading,
    isError: ratingError,
  } = usePropertyRating(propertyId, 1, 10, false)
  // console.log(rating)

  let pageCount = comments && Math.ceil(comments.length / 2)
  const firstTwoSavings = comments? (comments.slice(0, 2) as CommentCardProps[]) : []

  const [page, setPage] = React.useState(1)

  const twoPerPage = (n: number) => {
    return { first: n * 2 - 1, last: n * 2 + 1 }
  }

  const [paginatedComments, setpaginatedComments] = React.useState<
    CommentCardProps | any
  >([])

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
    const { first, last } = twoPerPage(newPage)
    let l: number =
      comments && comments.length
        ? comments.length
        : 1

    if (last > l) {
      let commentList = comments && comments.slice(first - 1)
      
      setpaginatedComments(commentList)
      return
    }
    let commentList = comments && comments.slice(first, last)
    
    setpaginatedComments(commentList)
  }

  if (ratingLoading) {
    return <div>Loading ratings...</div>
  }

  if (ratingError) {
    return <div>Error loading ratings: {ratingError.message}</div>
  }

  if (!comments || comments.length === 0) {
    return <div>No comments available.</div>
  }

  

  return (
    <div className="ltn__comment-area mb-30">
      <div className="ltn__comment-inner">
        <h3>Property Rating</h3>
        {/* {rating && rating.data && rating.data.length > 0 ? (
          rating.data.map((rate, index) => (
            <div key={index}>
              <p>Average rating: {rate.averageRating}</p>
              <p>Number of ratings: {rate.count}</p>
              <p>comments: {rate.comment}</p>
            </div>
          ))
        ) : (
          <p>There are no ratings</p>
        )} */}
        <ul>
          {comments && comments.length > 0 ? (
            page === 1 ? (
              firstTwoSavings.map((props, index) => (
                <li key={`comm${index}`}>
                  <CommentCard {...props} />
                </li>
              ))
            )
            :
            (
              paginatedComments.map((props: any, index: any) => (
                <li key={`comm${index}`}>
                  <CommentCard {...props} />
                </li>
              ))
            )
          ):
          <div>No comments available.</div>
          }
        </ul>
        {comments && comments.length > 0 ? (
            <Stack
              spacing={2}
              sx={{ marginBottom: '30px', alignItems: 'center' }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                size={'large'}
                variant="outlined"
                color="standard"
              />
            </Stack>
          )
          :
          ""
        }
      </div>
    </div>
  )
}

export default CommentSection


// {comments &&
//   comments.map((props, index) => (
//     <li key={`comm${index}`}>
//       <CommentCard {...props} />
//     </li>
//   ))}
/* {demo &&
  [0, 1, 2].map((item, index) => (
    <li key={`comm${index}`}>
      <CommentCard />
    </li>
  ))} */