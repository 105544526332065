import React from 'react';
import { Amenity } from '../../../types/amenity';
import { AmenityWithCount } from '../../../types/Property';
import Checkbox from '../../FormElements/Checkbox';
import defaultAmenities from './defaultAmenities';

export interface AmenitiesElementProps{
    amenities?: AmenityWithCount[];
}

const Amenities: React.FC<AmenitiesElementProps> = ({
    amenities = defaultAmenities
})=>{

    let amenityHash: { [index:string] : any } = {
        AirConditioning: Amenity.AirConditioning,
        Backyard: Amenity.Backyard,
        Electricity: Amenity.Electricity,
        EquippedKitchen: Amenity.EquippedKitchen,
        Gym: Amenity.Gym,
        Laundry: Amenity.Laundry,
        MediaRoom: Amenity.MediaRoom,
        Pool: Amenity.Pool,
        WIFI: Amenity.WIFI,
        WashingMachine: Amenity.WashingMachine,
        WaterSupply: Amenity.WaterSupply,
        ParkingSpace: Amenity.ParkingSpace
    }

    return(
        <div className="property-details-amenities mb-60">
            <div className="row">
                {
                    amenities?.map((item)=>(
                        <div className="col-lg-4 col-md-6" key={item.id}>
                            <Checkbox isChecked={true} 
                            label={amenityHash[item.amenity] || 'unregistered'} /> 
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Amenities;