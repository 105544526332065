import React from "react";
import styles from "./viewmortgage.module.scss";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { useHistory } from "react-router";
import Avatar from "../../assets/avatar.jpeg";
import { MortageApplicant } from "../../types/Property";

const MortgageCard = ({ applicant }: { applicant: MortageApplicant }) => {
  const history = useHistory();
  const reduceNameLength = (name: string) => {
    return name.slice(0, 14) + (name.length > 14 ? "..." : "");
  };
  const returnSalaryStructure = (sal: number) => {
    if (sal === 1) {
      return "Salary Earner";
    } else {
      return "Self Employed";
    }
  };
  const imgUrl =
    applicant && applicant.user && applicant.user.imageUrl
      ? applicant.user.imageUrl
      : Avatar;
  const goToApplicant = (id: string) => {
    history.push(`/viewmortgage/${id}`);
  };

  return (
    <div
      className={styles.containercard}
      role="button"
      onClick={() => goToApplicant(applicant.id.toString())}
      title={applicant && applicant.attendedTo === true ? 'Treated' : 'Pending'}
    >
      {applicant && applicant.attendedTo === true ? (
        <div className={styles["indicator-treated"]}></div>
      ) : (
        <div className={styles["indicator-pending"]}></div>
      )}
      <div className={styles["card-avatar"]}>
        {imgUrl !== undefined && <img src={imgUrl} alt="applicant image" />}
      </div>

      <span className={styles["card-title"]}>
        {reduceNameLength(
          `${applicant && applicant.user && applicant.user.firstName} ${
            applicant && applicant.user && applicant.user.lastName
          }`
        )}
      </span>

      <span className={styles["card-subtitle"]}>
        {returnSalaryStructure(applicant && applicant.salaryStructure)}
      </span>
      <span className={styles["card-subtitle"]}>
        {applicant && applicant.user && applicant.user.phoneNumber}
      </span>

      <div className={styles.cardpointer}>
        <HighlightAltIcon />
      </div>
    </div>
  );
};

export default MortgageCard;
