import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import jwt, { Jwt, JwtPayload } from 'jsonwebtoken';

export interface ProtectedRouteProps extends RouteProps{
    redirectPath?: string;
    privileges?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
    redirectPath = '/signin', privileges, ...props 
})=>{
    
    // redirect path
    let from = (props.path as string).replace('/', '');

    // First check if there is a token in local storage
    const token = localStorage.getItem('token');
    if (!token) {
        return <Redirect to={`${redirectPath}?redirect=${from}`} />
    }

    if (token) {
        // Check if token has expired, redirect to signin and remove the token
        let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
        let dateNow = new Date();
        let payload : JwtPayload | undefined = decodedToken?.payload as JwtPayload
        if (payload.exp){
            if (payload?.exp * 1000 < dateNow.getTime()) {
            
                localStorage.removeItem('token')
                return <Redirect to={`${redirectPath}?redirect=${from}`}/>
            }

            if (privileges) {
                if (!privileges.includes(payload.roles)) {
                    return <Redirect to={`${redirectPath}?redirect=${from}`}/>
                }
            }
        }
    }

    return(
        <Route {...props} />
    )
}

export default ProtectedRoute;