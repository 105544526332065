import AboutUsImage from '../../img/others/7.png';

const AboutUs = () => {
    return (
        // <!-- ABOUT US AREA START -->
        <div className="ltn__about-us-area pt-120 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-img-wrap about-img-left">
                            <img src={AboutUsImage} alt="Our Services" />
                            <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                                {/* <div className="ltn__video-img ltn__animation-pulse1">
                    <img src="img/others/8.png" alt="video popup bg image" />
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play"></i>
                    </a>
                  </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Services</h6>
                                <h1 className="section-title">
                                    The Leading Real Estate Rental Marketplace<span>.</span>
                                </h1>
                                <p>
                                HomeBuddy is the safest and most convenient online one-stop shop for all of your housing requirements. Nigerians can discover and pay for rental housing, as well as own a home. Currently, it takes an average of two to three months to find a suitable apartment that fulfills your requirements, and you must pay up to two years' rent in advance.
                                </p>
                            </div>
                            <ul className="ltn__list-item-half clearfix">
                                <li>
                                    <i className="flaticon-home-2"></i>
                                    Smart Home Design
                                </li>
                                <li>
                                    <i className="flaticon-mountain"></i>
                                    Beautiful Scene Around
                                </li>
                                <li>
                                    <i className="flaticon-heart"></i>
                                    Exceptional Lifestyle
                                </li>
                                <li>
                                    <i className="flaticon-secure"></i>
                                    Complete 24/7 Security
                                </li>
                            </ul>
                            <div className="ltn__callout bg-overlay-theme-05 mt-30">
                                <p>
                                    "HomeBuddy evaluates homes and makes it feasible for young professionals and families to find and pay for them in one week."
                                </p>
                            </div>
                            <div className="btn-wrapper animated">
                                <a href="/our-services" className="theme-btn-1 btn btn-effect-1">
                                    OUR SERVICES
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ABOUT US AREA END --> */}
        </div>
    );
};

export default AboutUs;