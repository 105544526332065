import React, { useContext, useState } from "react";
import TextInput from "../../components/FormElements/TextInput";
import UserAccountDashboard, {
  UserAccountDashboardProps,
} from "../UserAccountDashboard";
import { ChangePassword, updateUserDetails, useGetUser } from "../../swr/user";
import ToastContext from "../../contexts/ToastContext";
import { useHistory } from "react-router";
import LoadingContext from "../../contexts/LoadingContext";
import { UserChangePasswordRequest } from "../../types/User";
import { current } from "@reduxjs/toolkit";
import { ChangePinRequest } from "../../types/Wallet";
import { ChangePin } from "../../swr/wallet";
import styles from "./details.module.scss";
interface UserAccountDetailsState {
  image: string | Blob;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
}

const UserAccountDetails: React.FC<UserAccountDashboardProps> = () => {
  const email = localStorage.getItem("email") as string;
  const token = localStorage.getItem("token") as string;
  const { user } = useGetUser(email, token);

  const [state, setState] = useState<UserAccountDetailsState>({
    image: user?.image ? user?.image : "",
    firstName: user?.firstName ? user?.firstName : "",
    lastName: user?.lastName ? user?.lastName : "",
    userName: user?.userName ? user?.userName : "",
    email,
  });

  const [changePin, setChangePin] = useState<ChangePinRequest>({
    oldPin: "",
    newPin: "",
    confirmNewPin: "",
  });

  const [changePassword, setChangePassword] =
    useState<UserChangePasswordRequest>({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);

  const formData = new FormData();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files![0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String: any = reader.result;
        setState((prevState) => ({ ...prevState, image: base64String }));
      };
    }
  };

  //   const goToResetPin = () => {
  //     history.push('/resetpin')
  // }

  const history = useHistory();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePasswordChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChangePassword({
      ...changePassword,
      [e.target.name]: e.target.value,
    });
  };

  const ChangePasscode = async () => {
    if(!changePassword.confirmPassword || !changePassword.currentPassword || !changePassword.newPassword){
      openError('All fields are required')
      return
    }
    setLoading(true)
    const changePasswordResponse = await ChangePassword(changePassword, token);

    if (changePasswordResponse && changePasswordResponse.data) {
      setLoading(false)
      openSuccess(changePasswordResponse.data.message);
    } else {
      setLoading(false)
      // openError(changePasswordResponse.data.message ? changePasswordResponse.data.message : changePasswordResponse.error?.Message ? changePasswordResponse.error?.Message : changePasswordResponse.error?.message ? changePasswordResponse.error.message : 'an error occurred')
        //@ts-ignore
        openError(changePasswordResponse.error?.response?.data.Message ? changePasswordResponse.error?.response?.data.Message : changePasswordResponse.error?.message ? changePasswordResponse.error?.message : 'an error occurred' )
      }
    setChangePassword({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setLoading(false)
    return;
  };

  const handlePinChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setChangePin({
      ...changePin,
      [e.target.name]: e.target.value,
    });
  };

  const ChangeUserPin = async () => {
    if(!changePin.confirmNewPin || !changePin.newPin || !changePin.oldPin){
      openError('All fields are required')
      return
    }
    setLoading(true)
    const changePinResponse = await ChangePin(changePin, token);
    if (changePinResponse && changePinResponse.data) {
      setLoading(false)
      openSuccess(changePinResponse.data.message);
    } else {
      //@ts-ignore
      openError(changePinResponse.error?.response?.data.Message ? changePinResponse.error?.response?.data.Message : changePinResponse.error?.message ? changePinResponse.error?.message : 'an error occurred')
      setLoading(false)
    }
    setChangePin({
      oldPin: "",
      newPin: "",
      confirmNewPin: "",
    });
    setLoading(false)
    return;
  };

  const handleUpdateSuccess = (msg: string) => {
    setLoading(false);
    setState({
      image: "",
      firstName: "",
      lastName: "",
      userName: "",
      email,
    });
    state.image = "";
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    openSuccess(msg);
    history.push("/account");
  };

  const handleError = () => {
    setLoading(false)
    state.image = "";
    openError("Update failed");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    formData.append("email", state.email);
    formData.append("firstName", state.firstName);
    formData.append("lastName", state.lastName);
    formData.append("userName", state.userName);
    if (typeof state.image === "string") {
      const imageBlob = await fetch(state.image).then((response) =>
        response.blob()
      );

      formData.append("Image", imageBlob);
    } else {
      formData.append("Image", state.image);
    }

    const response = await updateUserDetails(formData, email);
    if (response && response.data) {
      let res = response.data;

      res.data === true &&
      res.succeeded === true
        ? handleUpdateSuccess(res.message)
        : handleError();
    }
    setLoading(false)
  };

  return (
    <div className="tab-pane fade active show" id="liton_tab_1_5">
      <div className="ltn__myaccount-tab-content-inner">
        <p>You can edit your profile details here.</p>
        <div className="ltn__form-box">
          <form onSubmit={handleSubmit}>
            <div className="row mb-50">
              {/* <div className="col-md-6 ">
                <label>First name:</label>
                <input
                  type="text"
                  placeholder="First Name"
                  required={true}
                  value={state.firstName}
                  className={styles.input}
                  name="firstName"
                  disabled
                />
              </div> */}
              {/* <div className="col-md-6">
                <label>Last name:</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  required={true}
                  value={state.lastName}
                  className={styles.input}
                  name="lastName"
                  disabled
                />
              </div> */}
              {/* <div className="col-md-6">
                <label>User Name:</label>
                <input
                  type="text"
                  placeholder={state.userName}
                  required={true}
                  value={state.userName}
                  className={styles.input}
                  name="userName"
                  disabled
                />
              </div> */}

              {/* <div className="col-md-6">
                  <label>Email:</label>
                  <TextInput name="email" placeholder='example@gmail.com' value={state.email} onChange={handleInputChange} /> 
                </div> */}
              <div className="col-md-6">
                <label> Display Image:</label>
                <input
                  type="file"
                  name="image"
                  placeholder="image"
                  onChange={(e) => handleImageChange(e)}
                  accept="image/*"
                  ref={fileInputRef}
                />
              </div>

              <div className="btn-wrapper">
                <button
                  type="submit"
                  className="btn theme-btn-1 btn-effect-1 text-uppercase"
                >
                  Save
                </button>
              </div>
            </div>
            <fieldset>
              <legend>Password change</legend>
              <div className="row">
                <div className="col-md-12">
                  <label>
                    Current password (leave blank to leave unchanged):
                  </label>
                  <input
                    type="password"
                    name="currentPassword"
                    onChange={handlePasswordChange}
                    value={changePassword.currentPassword}
                    autoComplete="off"
                  />
                  <label>New password (leave blank to leave unchanged):</label>
                  <input
                    type="password"
                    name="newPassword"
                    onChange={handlePasswordChange}
                    value={changePassword.newPassword}
                    autoComplete="off"
                  />
                  <label>Confirm new password:</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    onChange={handlePasswordChange}
                    value={changePassword.confirmPassword}
                    autoComplete="off"
                  />
                  <button
                    type="button"
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    onClick={() => ChangePasscode()}
                  >
                    Submit{" "}
                  </button>
                </div>
              </div>
            </fieldset>

            <fieldset style={{ marginTop: "3em" }}>
              <legend>Change Transaction Pin</legend>
              <div className="row">
                <div className="col-md-12">
                  <label>Current pin (leave blank to leave unchanged):</label>
                  <input
                    type="password"
                    name="oldPin"
                    onChange={handlePinChange}
                    value={changePin.oldPin}
                    autoComplete="off"
                  />
                  <label>New pin (leave blank to leave unchanged):</label>
                  <input
                    type="password"
                    name="newPin"
                    onChange={handlePinChange}
                    value={changePin.newPin}
                    autoComplete="off"
                  />
                  <label>Confirm new pin:</label>
                  <input
                    type="password"
                    name="confirmNewPin"
                    onChange={handlePinChange}
                    value={changePin.confirmNewPin}
                    autoComplete="off"
                  />
                  <button
                    type="button"
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    onClick={() => ChangeUserPin()}
                  >
                    Submit{" "}
                  </button>
                </div>
              </div>
            </fieldset>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                {/* <div className="btn-wrapper">
              <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={goToResetPin}>
                reset transaction pin
              </button>
            </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserAccountDetails;
