import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import ToastContext from "../../contexts/ToastContext";
import styles from "./admin.module.scss";

const DashboardOptions = () => {
  const history = useHistory();
  const { openError } = useContext(ToastContext);

  const goToPensions = () => {
    history.push('/viewpensions')
  }

  const goToMortgage = () => {
    history.push('/viewmortgage')
  }

  useEffect(() => {
    const email = localStorage.getItem("email") as string;
    const admin = process.env.REACT_APP_ADMIN_EMAIL;
    if (email !== admin) {
      openError("You are not authorized to view this page");
      history.push("/");
    }
  }, []);

  return (
    <div className={styles.containerAdmin}>
      <div className={styles["container-card"]} role="button" onClick={() => goToPensions()}>
        <div className={styles["card-avatar"]}></div>
        <div className={styles["card-titles"]}>
          <div className={styles["card-title"]}>Pension Applications</div>
          <div className={styles["card-subtitle"]}>View Pension Applicants</div>
        </div>
        <div className={styles["card-bar"]}></div>
      </div>
      <div className={styles["container-card"]} role="button" onClick={() => goToMortgage()}>
        <div className={styles["card-avatarI"]}></div>
        <div className={styles["card-titles"]}>
          <div className={styles["card-title"]}>Mortgage Applications</div>
          <div className={styles["card-subtitle"]}>View Mortgage Applicants</div>
        </div>
        <div className={styles["card-barI"]}></div>
      </div>
    </div>
  );
};

export default DashboardOptions;
