import React, { createContext, ReactNode, useContext, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  GetWalletDetailsResponse,
  GetWalletDetailsResponseData,
} from "../types/Wallet";
import { getWalletDetails } from "../swr/wallet";
import { GeneralOperationResult } from "../types/GeneralResponse";

export interface StateContextProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  ownerId: string;
  setOwnerId: React.Dispatch<React.SetStateAction<string>>;
  currentBalance: number;
  setCurrentBalance: React.Dispatch<React.SetStateAction<number>>;
  hasWallet: boolean | undefined;
  walletDetails: GetWalletDetailsResponseData | undefined
}

interface Props {
  children: ReactNode;
}

export const StateContext = createContext<StateContextProps>({
  currentBalance: 0,
  setCurrentBalance: () => {},
  email: "",
  setEmail: () => {},
  ownerId: "",
  setOwnerId: () => {},
  hasWallet: undefined,
  walletDetails: {} as GetWalletDetailsResponseData
});

export const ContextProvider = ({ children }: Props) => {
  const [currentBalance, setCurrentBalance] = useState<number>(0);
  const [email, setEmail] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [hasWallet, setHasWallet] = useState<boolean | undefined>(undefined);
  const [walletDetails, setWalletDetails] =
    React.useState<GetWalletDetailsResponseData | undefined>();

  const token = localStorage.getItem("token") as string;

  React.useEffect(() => {
    if (token && token.length > 0) {
      getWalletDetails(token).then(
        (result: GeneralOperationResult<GetWalletDetailsResponse>) => {
          if (result.data) {
            setWalletDetails(result.data.data);
          }

          if (result.error) {
            if (
              result.error.response &&
              result.error.response.data &&
              result.error.response?.data.Message === "01"
            ) {
              setHasWallet(false);
            }
          }
        }
      );
    }
  }, [token]);

  return (
    <StateContext.Provider
      value={{
        email,
        setEmail,
        currentBalance,
        setCurrentBalance,
        ownerId,
        setOwnerId,
        hasWallet,
        walletDetails
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {children}
      </LocalizationProvider>
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContextProps =>
  useContext(StateContext);