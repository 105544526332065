import React, { useEffect, useState } from 'react';
import styles from './ImageSlider.module.scss'; // Import your CSS or use styled-components
import bg from '../../assets/home1.jpg'
import bg1 from '../../assets/home2.jpg'
import bg2 from '../../assets/home3.jpg'
import bg3 from '../../assets/home4.jpg'
import bg4 from '../../assets/home5.jpg'
import bg5 from '../../assets/home6.jpg'
import bg6 from '../../assets/home7.jpg'
import bg7 from '../../assets/home8.jpg'
import bg8 from '../../assets/home9.jpg'
interface ImageSliderProps {
  images?: string[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({  }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    bg,
    bg1,
    bg2,
    bg3,
    bg4,
    bg5,
    bg6,
    bg7,
    bg8
  ]

  let interval = 3000

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      // Calculate the next index, and loop back to 0 if at the end
      const nextIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(nextIndex);
    }, interval);

    // Clean up the interval when the component unmounts
    return () => clearInterval(sliderInterval);
  }, [currentIndex, images.length, interval]);

//   const bg = images[0]

  return (
    <div className={styles.imageslider}>
      {/* <button className={styles.sliderbutton} onClick={goToPrev}>
        Prev
      </button> */}
      <img className={styles.sliderimage} src={images[currentIndex]} alt={`Image ${currentIndex}`} />
      {/* <button className={styles.sliderbutton} onClick={goToNext}>
        Next
      </button> */}
    </div>
  );
};

export default ImageSlider;
