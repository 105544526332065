import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

export interface PromptDialogProps{
    open?: boolean;
    onProceed?: React.MouseEventHandler<HTMLButtonElement>;
    children: React.ReactNode;
    proceedText?: string;
    title: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PromptDialog: React.FC<PromptDialogProps> = ({
    open = false, proceedText = 'Proceed',
    children, onProceed, title
}) =>{

    return(
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            //onClose={onClose}
            aria-describedby="prompt-description"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
            <DialogContentText id="prompt-description">
                { children }
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={onProceed}>{proceedText}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PromptDialog;