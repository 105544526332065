import React from 'react';
import { isEmpty } from '../../../utils/formUtils';
import Dropdown, { DropdownOption } from '../../FormElements/Dropdown';
import TextInput from '../../FormElements/TextInput';

export interface BankAccountFormState{
    accountName: string;
    accountNumber: string;
    bankCode: string;
}

interface ErrorFormState{
    accountName: boolean;
    accountNumber: boolean;
    bankCode: boolean;
}

export interface BankAccountFormProps{
    onSubmit?: (state: BankAccountFormState)=> void;
    onSuccess?: (fdata: FormData) => void;
    onCancel?: (e?: React.MouseEvent<HTMLButtonElement>)=> void;
}

const BankAccountForm: React.FC<BankAccountFormProps> = ({
    onSubmit, onSuccess, onCancel
})=>{

    // Refs
    const formRef = React.createRef<HTMLFormElement>();

    // State
    let initErrorState: ErrorFormState = {
        accountName: false,
        accountNumber: false,
        bankCode: false,
    }
    const [errorState, setErrorState] = React.useState<ErrorFormState>(initErrorState);

    // Handlers
    const unhighlight = (event: React.ChangeEvent<HTMLInputElement>)=>{
        setErrorState((prevState)=>({...prevState, [event.target.name]: false}));
    }

    const validateForm = (fdata: FormData): 'passed' | 'failed'=>{
        let result: 'passed' | 'failed' = 'passed';

        let accountNumber = fdata.get('accountNumber')?.toString();

        // Check if any field is empty
        for (const pairs of fdata.entries()) {
            //console.log(pairs);
            if (isEmpty(pairs[1].toString())) {
                setErrorState((prevState)=>({...prevState, [pairs[0]]: true}));
                result = 'failed';
            }
        }

        // Check Account number
        if (accountNumber && accountNumber?.length < 9) {
            setErrorState((prevState)=>({...prevState, accountNumber: true}));
            result = 'failed';
        }

        return result;
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>)=>{
        
        if (formRef.current) {
            const fdata = new FormData(formRef.current);

            if (validateForm(fdata) === 'passed') {
                let state: BankAccountFormState = {
                    accountName: fdata.get('accountName')?.toString() || '',
                    accountNumber: fdata.get('accountNumber')?.toString() || '',
                    bankCode: fdata.get('bankCode')?.toString() || '',
                }
                onSubmit && onSubmit(state);
                onSuccess && onSuccess(fdata);
            }
        }
    }

    // Options
    const bankOptions: DropdownOption[] = [
        { value: '044', label: 'Access Bank' }, { value: '058', label: 'Guaranty Trust Bank' }, 
        { value: '035', label: 'WEMA Bank Plc' }, { value: '011', label: 'First Bank of Nigeria Plc' }, 
        { value: '039', label: 'Stanbic IBTC Plc' }, { value: '232', label: 'Sterling Bank Plc' }, 
    ]

    return(
        <form ref={formRef} onSubmit={(e)=> e.preventDefault()}>
            <div className='row'>
                <div className="col-md-12">
                    <label>Bank</label>
                    <Dropdown name='bankCode' optionsObj={bankOptions} useObjectValues
                        selectProps={{
                            required: true
                        }}
                    />
                </div>
                <div className="col-md-12">
                    <label>Account number</label>
                    <TextInput hideDefaultAdornment name='accountNumber'
                        error={errorState.accountNumber}
                        errorMessage="Enter a valid account number"
                        onChange={unhighlight} required={errorState.accountNumber}
                    />
                </div>
                <div className="col-md-12">
                    <label>Account name</label>
                    <TextInput hideDefaultAdornment name='accountName'
                        error={errorState.accountName}
                        onChange={unhighlight} required={errorState.accountName}
                    />
                </div>
            </div>
            <button className="btn theme-btn-1 btn-effect-1 text-uppercase" 
                type="submit" onClick={handleSubmit}
            >
                Save
            </button>
            <button name="cancel" onClick={onCancel}
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
            >
                Cancel
            </button>
        </form>
    )
}

export default BankAccountForm;