import Button from "@mui/material/Button";
import React from "react";
import styles from "./walletDisplay.module.scss";
import AddIcon from "@mui/icons-material/Add";
import TopUpBalanceDialog from "../Dialogs/TopUpBalance";
import Skeleton from "@mui/material/Skeleton";
import {
  StateContextProps,
  useStateContext,
} from "../../contexts/StateContext";
import { Link, useHistory } from "react-router-dom";
import { formatNumberToCurrency } from "../../utils/generalUtils";
import CallMadeIcon from "@mui/icons-material/CallMade";

export interface WalletDisplayProps {
  balance?: number;
  walletID?: string;
  loading?: boolean;
  netBalance?: number;
  onAdd?: () => void;
}

const WalletDisplay: React.FC<WalletDisplayProps> = ({
  balance,
  walletID,
  loading,
  netBalance,
}) => {
  // Hooks
  const history = useHistory();

  // State
  const [open, setOpen] = React.useState<boolean>(false);

  const handleTopup = () => {
    setOpen(true);
    history.push("/resetpin");
  };

  const handleTopupI = () => {
    setOpen(true);
    history.push("/wallet-transfer");
  };
  const handleClose = (
    event?: any,
    reason?: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick") return;
    setOpen(false);
  };

  const handleSendMoney = () => {
    history.push("/transfer");
  };

  return (
    <>
      <TopUpBalanceDialog open={open} onClose={handleClose} />
      <div className={styles.display}>
        {/** Balance and wallet ID */}

        <div className={styles.balanceDisplay}>
          {!loading && (
            <h4
              style={{
                ...(window.innerWidth <= 768 && {
                  fontSize: "1.5em",
                }),
              }}
            >
              ₦{formatNumberToCurrency(balance!)}
            </h4>
          )}
          {!loading && (
            <h4
              style={{
                fontWeight: "normal",
                fontSize: "20px",
                ...(window.innerWidth <= 768 && {
                  fontSize: "small",
                }),
              }}
            >
              ₦{formatNumberToCurrency(netBalance!)}
            </h4>
          )}
          {loading && (
            <h4>
              ₦ <Skeleton variant="rectangular" width="50%"></Skeleton>
            </h4>
          )}
          <p>Wallet ID: {` ${walletID}`}</p>
        </div>

        {/** Add button */}

        <div className={styles.bothButtons}>
          <Button
            variant="contained"
            onClick={handleTopup}
            sx={{
              width: "100%",
              marginBottom: "0.6em",
              textTransform: "none",
              backgroundColor: "var(--ltn__secondary-color)",
              "&:hover": {
                backgroundColor: "var(--ltn__paragraph-color)",
              },
            }}
          >
            <AddIcon fontSize="small" sx={{ marginRight: "0.3em" }} />
            Reset Pin
          </Button>

          <Button
            variant="contained"
            onClick={handleTopupI}
            sx={{
              width: "100%",
              textTransform: "none",
              backgroundColor: "white",
              color: "#4AB34A",
              border: 1.5,
              borderColor: "#4AB34A",
              "&:hover": {
                backgroundColor: "var(--ltn__secondary-color)",
                color: "white",
                border: "none",
              },
            }}
          >
            <CallMadeIcon fontSize="small" sx={{ marginRight: "0.3em" }} />
            Transfer
          </Button>
        </div>
      </div>
    </>
  );
};

export default WalletDisplay;
