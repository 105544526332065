import { createUseStyles } from "react-jss";
import { breakpoints } from "../../utils/breakpoints";

const useStyles = createUseStyles({

    /** For main area */
    main:{
        padding: 0,
    },

    logo:{
    },

    logoImage:{
        height: '45px',
        padding: 0,
        //marginRight: 5,
        '& img':{
            height: "100%",
            objectFit: 'contain',
        }
    },

    logoLabel:{
        fontFamily: 'Poppins',

        '& h5':{
           paddingTop: '.65rem',
           fontSize: 20,
           color: 'white',
        }
    },

    description:{
        fontSize: 14,
        wordWrap: 'break-word',
        
        '& p':{
            color: 'white',
        }
    },

    linkSection:{
        marginBottom: '25px',
        fontFamily: 'Poppins',
        
        '& h4':{
            paddingTop: '.65rem',
            fontSize: 20,
            color: 'white',
        }
    },

    linkList:{
        listStyle: 'none',
        padding: 0,
        fontFamily: 'Nunito Sans',
        fontSize: 14,

        '& li':{
            marginBottom: 15,
        },
        
        '& a':{
            textDecoration: 'none',
            color: 'white',

            '&:hover':{
                color: 'var(--ltn_secondary-color)',
            }
        }
    },

    newsletterHeading:{
        fontFamily: 'Poppins',
        '& h4':{
            paddingTop: '.65rem',
            fontSize: 20,
            color: 'white',
        }
    },

    newsletterSubheading:{
        '& p':{
            fontSize: 14,
            color: 'white',
        }
    },

    newsletterInput:{
        '& input[type="email"]':{
            height: 45,
            width: '100%',
            borderRadius: 0,
            paddingLeft: 15,
            border: '2px solid',
            borderColor: '#e4ecf2',
            fontSize: 14,
            boxShadow: 'none',
            color: 'var(--ltn_paragraph-color)',
            
            '&:focus':{
                borderColor: 'var(--ltn_secondary-color)',
                border: '1px solid #ddd',
            }
        }
    },

    buttonWrapper:{
        '& button':{
            padding: '0px 14px',
            height: 45,
            fontSize: 14,
            verticalAlign: 'middle',
            textAlign: 'center',
            zIndex: 1,
            textDecoration: 'none',
            border: '1px solid transparent',
            display: 'inline-block',
            borderRadius: 0,
            color: 'white',
            backgroundColor: 'var(--ltn_secondary-color)',
            transition: 'all 0.3s',


            '&:after':{
                content: '""',
                position: 'absolute',
                zIndex: -1,
                transition: 'all 0.3s',
            },

            '&:hover':{
                backgroundColor: 'black',
                color: 'white'
            }

        }
    },

    /** For bottom area */
    bottomArea:{
        paddingTop: '25px',
        paddingBottom: '25px',
        backgroundColor: '#030320'
    },

    bottomContainer:{
        paddingLeft: '15px',
        paddingRight: '15px',
    },

    bottomRow:{
        marginLeft: '-15px',
        marginRight: '-15px',
    },

    copyRight:{
        color: 'white',
        '& p':{
            marginBottom: 0,
        }
    },

    copyRightMenu:{
        '& ul':{
            margin: 0,
            padding: 0,
        },

        '& li':{
            listStyle: 'none',
            display: 'inline-block',
            marginTop: 0,
            marginRight: '20px',
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: 600,
        },

        '& a':{
            textDecoration: 'none',
            margin: 0,
            color: 'white',

            '&:hover':{
                color: 'var(--ltn_secondary-color)',
            }
        }
    },

    [breakpoints.xl()]:{
        bottomArea:{
            paddingLeft: '15px',
            paddingRight: '15px',
        }
    },

    [breakpoints.md()]:{
        copyRight:{
            textAlign: 'center !important',
        },
        copyRightMenu:{
            textAlign: 'center !important',
            marginTop: '25px',
        },
    },

    /** For Footer component */
    footer:{
        backgroundColor: '#0A0A35',
        color: 'white',
        fontFamily: 'Nunito Sans',
        padding: '92px 15px 20px 15px',
    },
})

export default useStyles;