import React from "react";
import styles from "./insurance-comp.module.scss";

const PopUp = ({
  options,
  click,
}: {
  options: string[];
  click: (selection: string) => void;
}) => {
  return (
    <div className={styles["out-contain"]}>
      <div className={styles.contain}>
        <div className={styles["sel-contain"]}>
          <span>SELECT</span>
        </div>
        {options &&
          options.map((option) => (
            <div key={option} className={styles["item-contain"]} role="button" onClick={() => click(option)}>
              <span>{option}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PopUp;
