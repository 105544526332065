import React, { Dispatch, SetStateAction } from "react";
import {
  AmenityWithCount,
  Property,
  PropertyStatus,
} from "../../../types/Property";
import CheckboxCustom from "../../FormElements/Checkbox";
import Dropdown, { DropdownOption } from "../../FormElements/Dropdown";
import TextArea from "../../FormElements/TextArea";
import TextInput, { TextInputProps } from "../../FormElements/TextInput";
import { ViewProps } from "../Amenities/index.old";
import { PropertyType } from "../../../types/propertyType";
import styles from "./details.module.scss";
import { enumToDropdownObj } from "../../../utils/generalUtils";
import { SelectChangeEvent } from "@mui/material/Select";
import Amenities from "../Amenities";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export interface DetailsErrorState {
  size: boolean;
  bedrooms: boolean;
  bathrooms: boolean;
  type: boolean;
  status: boolean;
  downPayment: boolean;
}

export interface DeatilsProps extends ViewProps {
  errorState?: DetailsErrorState;
  unhighlight?: (e: React.ChangeEvent<any>) => void;
  handleAmenityChange: (amenities: SelectChangeEvent<string[]>) => void;
  amenities: string[];
  confirmAvailability?: boolean;
  setConfirmAvailability?: Dispatch<SetStateAction<boolean>>;
  isInstallment?: boolean;
  setIsInstallment?: Dispatch<SetStateAction<boolean>>;
  isAvailable?: boolean;
  setIsAvailable?: Dispatch<SetStateAction<boolean>>;
}

const Details: React.FC<DeatilsProps> = ({
  onPrevStep,
  onNextStep,
  onSubmit,
  prevKey,
  nextKey,
  errorState,
  unhighlight,
  handleAmenityChange,
  amenities,
  confirmAvailability,
  setConfirmAvailability,
  setIsInstallment,
  isInstallment,
  setIsAvailable,
  isAvailable
}) => {
  // State
  const [property, setProperty] = React.useState<Property | undefined>();
  const [show, setShow] = React.useState<boolean>(false);

  // Helpers
  const showPopup = () => {
    setShow(true);
  };
  const hidePopup = () => {
    setShow(false);
  };

  // Listeners
  const subscribe = () => {
    document.addEventListener("updateProduct", (event: CustomEventInit) => {
      setProperty(event.detail.value);
    });
  };
  const unsubscribe = () => {
    document.removeEventListener("updateProduct", (event: CustomEventInit) => {
      setProperty(event.detail.value);
    });
  };

  // Effect for update mode
  React.useEffect(() => {
    subscribe();
    return unsubscribe();
  }, [property]);

  const handlePrevStep = () => {
    if (onPrevStep && prevKey) {
      onPrevStep(prevKey);
    }
  };
  const handleNextStep = () => {
    if (onNextStep && nextKey) {
      onNextStep(nextKey);
    }
  };

  const oldList = [
    "Size in ft2 (*only numbers)",
    "Lot Size in ft2 (*only numbers)",
    "Rooms (*only numbers)",
    "Bedrooms (*only numbers)",
    "Bathrooms (*only numbers)",
    "Custom ID (*text)",
    "Garages (*text)",
    "Year Built (*numeric)",
    "Garage Size (*text)",
    "Available from (*date)",
    "Basement (*text)",
    "Extra Details (*text)",
    "Roofing (*text)",
    "Exterior Material (*text)",
  ];

  const typeOptions: DropdownOption[] = enumToDropdownObj(PropertyType);

  const completionOptions: DropdownOption[] = [
    {
      label: "Completed",
      value: true,
    },
    {
      label: "Ongoing",
      value: false,
    },
  ];

  let detailInputs: TextInputProps[] = [
    {
      placeholder: "Size in ft2 (*only numbers)",
      name: "size",
      type: "number",
      useValue: property?.size,
      error: errorState?.size,
      required: errorState?.size,
      onChange: unhighlight,
    },
    {
      placeholder: "Bedrooms (*only numbers)",
      name: "bedrooms",
      type: "text",
      useValue: property?.bedrooms,
      error: errorState?.bedrooms,
      required: errorState?.bedrooms,
      onChange: unhighlight,
    },
    {
      placeholder: "Bathrooms (*only numbers)",
      name: "bathrooms",
      type: "text",
      useValue: property?.bathrooms,
      error: errorState?.bathrooms,
      required: errorState?.bathrooms,
      onChange: unhighlight,
    },
  ];

  // Availability value should be yes or no
  let availabilityValue = property?.availability ? "yes" : "no";
  let installmentValue = property?.isInstallmental ? "yes" : "no";
  let featuredValue = property?.isFeatured ? "yes" : "no";

  return (
    <div className="tab-pane fade active show" id="liton_tab_3_4">
      <div className="ltn__product-tab-content-inner">
        {/** Listing details */}
        <h6>Listing Details</h6>
        <div className="row">
          {detailInputs.map((item, index) => (
            <div className="col-md-6" key={index}>
              <TextInput {...item} key={`det${index}`} />
            </div>
          ))}
          <div className="col-md-6">
            <Dropdown
              placeholder="Select property type..."
              name="type"
              value={property?.type}
              selectProps={{ required: true }}
              optionsObj={typeOptions}
              useObjectValues
            />
          </div>

          <div className="col-md-6">
            <Dropdown
              placeholder="Select completion status"
              name="completion"
              value={property?.isCompleted}
              selectProps={{ required: true }}
              optionsObj={completionOptions}
              useObjectValues
            />
          </div>

          {/* <div className="col-md-6">
            <FormControlLabel
              label="Is installmental payment available ?"
              control={
                <Checkbox
                  defaultChecked={property?.isInstallmental}
                  checked={isInstallment}
                  onChange={() => setIsInstallment && setIsInstallment(!isInstallment)}
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                  sx={{}}
                />
              }
            />
          </div> */}

          {isInstallment === true && (
            <div className="col-md-6">
              <TextInput
                placeholder="Minimum acceptable down payment"
                variant="amount"
                name="downpayment"
                useValue={property?.downPayment}
                error={errorState?.downPayment}
                required={errorState?.downPayment}
                onChange={unhighlight}
              />
            </div>
          )}

          <div className="col-md-6" style={{marginBottom: '1em'}}>
            {/* <CheckboxCustom
              label="Is property currently available ?"
              useBoolean
              name="availability"
              useValue={availabilityValue}
              isChecked={property?.availability}
            /> */}

            <FormControlLabel
              label="Is property currently available ?"
              control={
                <Checkbox
                  defaultChecked={property?.availability}
                  checked={isAvailable}
                  onChange={() => setIsAvailable && setIsAvailable(!isAvailable)}
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                  sx={{}}
                />
              }
            />
          </div>
        
          <div
            className={`col-md-3 ${styles.featured}`}
            // onMouseOver={showPopup}
            // onMouseLeave={hidePopup}
          >
            <span className={`${styles.popuptext} ${show && styles.show}`}>
              Please note that selecting this will come with a recurring charge
              of ₦5000 every month
            </span>
            {/* <Checkbox label="Featured ?" useBoolean name="isFeatured" 
                            useValue={featuredValue} isChecked={property?.isFeatured}
                        /> */}
          </div>

          <div className="col-md-12">
            <Amenities
              dataList={amenities}
              handleChange={handleAmenityChange}
            />
          </div>

          <div
            className="col-md-8"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            {/* <Checkbox label="" useBoolean
                             isChecked={confirmAvailability} 
                        /> */}
            <input
              type="checkbox"
              aria-checked={confirmAvailability}
              checked={confirmAvailability}
              onChange={() =>
                setConfirmAvailability && setConfirmAvailability(true)
              }
              autoComplete="off"
            />
            <label htmlFor="myCheckbox">
              I confirm I will inform the HomeBuddy team on property
              availability when the status changes
            </label>
          </div>
        </div>
        
        

        <div className="btn-wrapper text-center--- mt-0">
          <button
            className="btn theme-btn-1 btn-effect-1 text-uppercase"
            onClick={handlePrevStep}
          >
            Prev Step
          </button>
          <button
            className="btn theme-btn-1 btn-effect-1 text-uppercase"
            type="submit"
            onClick={onSubmit}
          >
            Submit Property
          </button>
        </div>
      </div>
    </div>
  );
};

export default Details;
