import React from 'react'
import Layout from '../../../components/Layout'
import ScrollToTop from '../../../components/ScrollToTop'
import SessionCheck from '../../../components/SessionCheck'
import NavHeader from '../../../components/NavHeader'
import ViewMortgageApplicant from './page'

const ViewPensionsPage = () => {
  return (
    <Layout>
    <ScrollToTop />
    <SessionCheck shouldRedirect />
    <NavHeader pageTitle="View Mortgage Applicant" routeName="View Mortgage" />
    <ViewMortgageApplicant />
  </Layout>
  )
}

export default ViewPensionsPage