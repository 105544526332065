import React from 'react';
import styles from './tabnav.module.scss';

export interface TabNavActionProps{
    onClick?: (event: React.MouseEvent, key: string, index: number) => any;
    label?: string;
    index?: number;
    activeIndex?: number;
}

const TabNavAction: React.FC<TabNavActionProps> = ({
    onClick = (event, key, index)=> console.log(''),
    label = "Description",
    index = 1, 
    activeIndex
})=>{

    const [isActive, setIsActive] = React.useState(false);

    React.useEffect(()=>{
        if (index === activeIndex) {
            setIsActive(true);
        }
        else{
            setIsActive(false);
        }
    },[activeIndex, index]);

    let activeStyle = isActive? styles.active : "";

    const handleClick = (event: React.MouseEvent)=>{
        onClick(event, label, index);
    }

    return(
        <div className={`${styles.tab} ${activeStyle}`} onClick={handleClick}>
            {(index + 1) + `. ${label}`}
        </div>
    )
}


export default TabNavAction;