import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../upload.module.scss";

export type UploadDocsProps = {
  buttonTxt: string;
  instructions?: string[];
  handleDocsChange: (name: string, value: File) => void;
  accept: string;
  name: string;
  removeDoc: (name: string, index?: number) => void;
  docValue: File | File[] | undefined;
  multiple?: boolean;
};

const UploadSingleButton: React.FC<UploadDocsProps> = ({
  buttonTxt,
  instructions,
  handleDocsChange,
  accept,
  name,
  removeDoc,
  docValue,
  multiple,
}) => {
  const docRef = useRef<HTMLInputElement | null>(null);
  const [currentDoc, setCurrentDoc] = useState<File | undefined>();
  const [currentDocs, setCurrentDocs] = useState<File | File[] | undefined>();
  const handleClick = () => {
    if (docRef.current) docRef.current.click();
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const files: FileList = event.target.files; // Get the FileList object from the event
    const filesArray: File[] = Array.from(files); // Convert FileList to Array
  
    // Set the current files state
    setCurrentDocs(filesArray);
  
    // Handle each file individually (optional)
    filesArray.forEach(file => {
      handleDocsChange(name, file);
    });

    if (docRef.current) {
      docRef.current.value = "";
    }
  };

  const handleDocDelete = (file: string, index?: number) => {
    setCurrentDoc(undefined);
    setCurrentDocs(undefined)
    removeDoc(name, index);
  };

  useEffect(() => {
    if (Array.isArray(docValue)) {
      setCurrentDocs(docValue);
    } else {
      setCurrentDoc(docValue);
    }
  }, [docValue]);

  return (
    <div className={styles["upload-container"]}>
      <div className={styles["upload-container-top"]}>
        <div
          className={styles["upload-but"]}
          role="button"
          onClick={handleClick}
        >
          <span>{buttonTxt}</span>
          <input
            type="file"
            name="docs"
            accept={accept}
            onChange={(e) => handleImageChange(e)}
            ref={docRef}
            required
            multiple={multiple && multiple}
          />
        </div>
        {currentDocs && Array.isArray(currentDocs) && currentDocs.length ? (
          <div className={styles["doc-cont-out"]}>
            {currentDocs.map((doc, index) => (
              <div className={styles["doc-cont"]} key={doc.name}>
                <span>{doc.name}</span>
                <div onClick={() => handleDocDelete(name, index)}>
                  <CloseIcon fontSize="small" sx={{ color: "#CB1212" }} />
                </div>
              </div>
            ))}
          </div>
        ) : currentDoc && currentDoc.name ? (
          <div className={styles["doc-cont"]}>
            <span>{currentDoc.name}</span>
            <div onClick={() => handleDocDelete(name)}>
              <CloseIcon fontSize="small" sx={{ color: "#CB1212" }} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {instructions &&
        instructions.length > 0 &&
        instructions.map((text) => <span key={text}>{text}</span>)}
    </div>
  );
};

export default UploadSingleButton;
