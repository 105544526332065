import React, { useEffect } from "react";
import styles from "./apply-insurance.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import ShowIteration from "../../../components/ShowIteration";
import { useHistory } from "react-router";
import RentForm from "../RentDefault/RentForm";
import HouseHoldForm from "../HouseHold/HouseHoldForm";
import HomeOwnersForm from "../HomeOwners/HomeOwnersForm";
import { FormType } from "../../../types/Insurance";

const ApplyForInsurance = () => {
  const history = useHistory()
  const { category, rentStep, formType} = useSelector(
    (state: RootState) => state.insurance
  );

  useEffect(() => {
    if(!category){
        history.push('/insurance')
    }
  }, [])

  const returnFormType = (type: FormType) => {
    switch (type) {
      case "RentDefault":
        return (
          <RentForm/>
        );
      case "HouseHold":
        return (
          <HouseHoldForm/>
        );
      default:
        return (
          <HomeOwnersForm/>
        );
    }
  };

  return (
    <div className={styles["apply-insurance-container"]}>
      <div className={styles["apply-insurance-form"]}>
        <span className={styles["header"]}>{category}</span>
        <div className={styles["iterator-cont"]}>
          <ShowIteration numbers={3} selectedPosition={rentStep} />
        </div>
        {returnFormType(formType)}
      </div>
    </div>
  );
};

export default ApplyForInsurance;
