import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./upload.module.scss";
import { LandlordDetails, TenantDetailsType } from "../../../types/Insurance";

export type LandlordOrTenantType = keyof LandlordDetails | keyof TenantDetailsType;

export type UploadDocsProps = {
  buttonTxt: string;
  instructions: string[];
  isTenant?: boolean;
  handleDocsChange: (name: LandlordOrTenantType, value: File) => void;
  accept: string;
  name: LandlordOrTenantType;
  removeDoc: (name: LandlordOrTenantType) => void;
  landLordDetails?: LandlordDetails;
  tenantDetails?: TenantDetailsType;
};

const UploadDocs: React.FC<UploadDocsProps> = ({
  buttonTxt,
  instructions,
  handleDocsChange,
  accept,
  name,
  removeDoc,
  landLordDetails,
  tenantDetails,
}) => {
  const docRef = useRef<HTMLInputElement | null>(null);
  const [currentDoc, setCurrentDoc] = useState<File | undefined>();
  const handleClick = () => {
    if (docRef.current) docRef.current.click();
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    setCurrentDoc(file);
    handleDocsChange(name, file);
    if (docRef.current) {
      docRef.current.value = ''; 
    }
    // const fileReader = new FileReader();

    // fileReader.onload = () => {
    //   setPickedImage(fileReader.result);
    // };

    // fileReader.readAsDataURL(file);
  };

  const handleDocDelete = (file: LandlordOrTenantType) => {
    setCurrentDoc(undefined);
    removeDoc(file);
  };

  useEffect(() => {
    if (tenantDetails === undefined && landLordDetails !== undefined) {
      setCurrentDoc(landLordDetails[name] as File);
    } else {
      setCurrentDoc(tenantDetails![name as keyof TenantDetailsType] as File);
    }
  }, []);

  return (
    <div className={styles["upload-container"]}>
      <div className={styles["upload-container-top"]}>
        <div
          className={styles["upload-but"]}
          role="button"
          onClick={handleClick}
        >
          <span>{buttonTxt}</span>
          <input
            type="file"
            accept={accept}
            onChange={(e) => handleImageChange(e)}
            ref={docRef}
            required
            multiple={false}
          />
        </div>
        {currentDoc && currentDoc.name ? (
          <div className={styles["doc-cont"]}>
            <span>{currentDoc.name}</span>
            <div onClick={() => handleDocDelete(name)}>
              <CloseIcon fontSize="small" sx={{ color: "#CB1212" }} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {instructions &&
        instructions.length > 0 &&
        instructions.map((text) => <span key={text}>{text}</span>)}
    </div>
  );
};

export default UploadDocs;
