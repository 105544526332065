import { Property } from "../../types/Property";
import ProductCarousel from "../Carousels/ProductCarousel";


export interface ProductSliderProps{
    properties?: Property[];
    loading?: boolean;
    title?: string;
    featured?: boolean;
}
const ProductSlider = ({ properties, loading, title = 'Latest Listings', featured }: ProductSliderProps) => {

    // let featuredProperties: Property[] = [];
    // if (featured && properties){
    //     featuredProperties = properties?.filter((p)=> p.availability === true);
    // }
    // console.log(properties && properties[0] && properties[0].title, 'available');
    
    return (
        // <!-- PRODUCT SLIDER AREA START -->
        // To add greyish background use section-bg-1
        <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-70 ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Property</h6>
                            <h1 className="section-title">{title}</h1>
                        </div>
                    </div>
                </div>
                <div className="row ltn__product-slider-item-three-active slick-arrow-1">

                    <ProductCarousel 
                        properties={properties && properties} 
                        loading={loading}
                    />
                    
                    {/* <!-- ltn__product-item --> 
                    <div className="col-xl-4 col-sm-6 col-12">
                        
                    </div>*/}
            
                </div>
            </div>
            {/* <!-- PRODUCT SLIDER AREA END --> */}
        </div>
    );
};
export default ProductSlider;
