import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Attachment } from '../../../types/Property';
import MyImageCarousel from '../../Carousels/MyImageCarousel';

export interface ImageCarouselDialogProps{
    open?: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
    index?: number;
    attachments?: Attachment[];
}

const ImageCarouselDialog: React.FC<ImageCarouselDialogProps> = ({
    open = false, onClose, attachments, index = 0
})=>{

    return(
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx:{ backgroundColor: 'transparent', width: '100%', height: '100%' },
                elevation: 0,
            }}
        >
           <MyImageCarousel attachments={attachments} currentIndex={index}/>
        </Dialog>
    )
}

export default ImageCarouselDialog;