export enum States{
    Abia,
    Abuja,
    Adamawa,
    "Akwa Ibom",
    Anambra,
    Bauchi,
    Bayelsa,
    Benue,
    Bornu,
    "Cross River",
    Delta,
    Ebonyi,
    Edo,
    Ekiti,
    Enugu,
    Gombe,
    Imo,
    Jigawa,
    Kaduna,
    Kano,
    Katsina,
    Kebbi,
    Kogi,
    Kwara,
    Lagos,
    Nasarawa,
    Niger,
    Ogun,
    Ondo,
    Osun,
    Oyo,
    Plateau,
    Rivers,
    Sokoto,
    Taraba,
    Yobe,
    Zamfara
}

export const statesForSearch = [
    "Abia",
    "Adamawa",
    "AkwaIbom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Bornu",
    "CrossRiver",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Abuja",
  ];