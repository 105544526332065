import { useState } from 'react';
import NavigationBar from '../NavigationBar';
import { UtilizeMobileMenu } from '../UtilizeMobileMenu/menu2';
import { HeaderMiddleArea } from './HeaderMiddleArea';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  return (
    <>
      {/** I changed the classes */}
      <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2" >
        <NavigationBar open={isMobileMenuOpen} setOpen={setMobileMenuOpen} />
        {/*<HeaderMiddleArea open={isMobileMenuOpen} setOpen={setMobileMenuOpen} />*/}
      </header>
      <UtilizeMobileMenu open={isMobileMenuOpen} setOpen={setMobileMenuOpen} />
    </>
  );
};

export default Header;
