import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import BillPaymentConfirmDialog, {
  BillPaymentMeta,
} from "../../components/Dialogs/BillPaymentConfirmDialog";
import ElectricBillForm from "../../components/Forms/ElectricBillForm";
import LoadingScreen from "../../components/LoadingScreen";
import UtilityBillCard, {
  UtilityBillCardProps,
} from "../../components/UtilityBillCard";
import LoadingContext from "../../contexts/LoadingContext";
import ToastContext from "../../contexts/ToastContext";
import {
  customerLookUp,
  fetchAllBillers,
  useDiscoBills,
} from "../../swr/bills";
import { BillResponseData, CustomerLookupRequest } from "../../types/Billings";
import styles from "./utilBill.module.scss";
import { log } from "console";
import UtilityForms from "./UtilityForms";
import { getWalletDetails } from "../../swr/wallet";
import { useHistory } from "react-router";

const UtilityBill: React.FC<any> = () => {
  const [allBillers, setAllBillers] = useState<BillResponseData[]>([]);
  const token = localStorage.getItem("token") as string;
  const history = useHistory()

  // Context
  const { openError } = React.useContext(ToastContext);
  const { setLoading } = React.useContext(LoadingContext);

  // Bills
  //   const { discoBills, isLoading, isError } = useDiscoBills("");

  // Ref
  const contentEl = React.useRef<HTMLDivElement>(null);

  interface SelectedHash {
    power: boolean;
    water: boolean;
  }
  let initSelectedHash: SelectedHash = {
    power: false,
    water: false,
  };
  const [selectedState, setSelectedState] =
    React.useState<SelectedHash>(initSelectedHash);
  const [open, setOpen] = React.useState<boolean>(false);
  const [meta, setMeta] = React.useState<BillPaymentMeta>();
  const [selected, setSelected] = useState<BillResponseData>({
    "id": 0,
    "name": 'string',
    "slug": 'string',
    "groupId": 0,
  });

  // Handlers
  const getAllBillers = async () => {
    setLoading(true)
    const billers = await fetchAllBillers(token);
    if (billers && billers.response_data) {
      setAllBillers(billers.response_data);
      setLoading(false)
    } else {
      openError("Could not load the discos");
      setLoading(false)
    }
  };

  const handleBillSelect = (id: keyof SelectedHash) => {
    setSelectedState((prevState) => ({
      power: false,
      water: false,
      [id]: !prevState[id],
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (
    request: CustomerLookupRequest,
    amount: number | string
  ) => {
    setLoading(true);
    const { data, error } = await customerLookUp(request);

    if (data) {
      setLoading(false);

      if (data.response_data) {
        setMeta({
          ...data.response_data,
          amount: amount,
        });
      }
      setOpen(true);
    }

    if (error) {
      setLoading(false);
      openError("An error occured while processing this request");
    }
  };

  // Effect
  React.useEffect(() => {
    getAllBillers();

    getWalletDetails(token).then((result) => {

      if (result.error) {
        if (
          result.error.response &&
          result.error.response.data &&
          result.error.response?.data.Message === "01"
        ) {
          openError('You need to create a wallet to purchase utilities')
          history.push('/account#liton_tab_1_6')
        }
      }
    });
  }, []);

  const isActive = React.useCallback(() => {
    for (const key in selectedState) {
      if (Object.prototype.hasOwnProperty.call(selectedState, key)) {
        const element = selectedState[key as keyof SelectedHash];

        if (element) {
          return true;
        }
      }
    }
    return false;
  }, [selectedState]);

  interface UtilityBillCard {
    id: string;
    iconClass: string;
    label: string;
    selected: boolean;
    searchparam: string;
  }

  const bills: UtilityBillCard[] = [
    {
      id: "2",
      iconClass: "fa fa-bolt",
      label: "Electricity Bill",
      selected: selectedState.power,
      searchparam: "ELECTRIC_DISCO",
    },
    {
      id: "General",
      iconClass: "fa fa-shopping-bag",
      label: "General Bill",
      selected: selectedState.water,
      searchparam: "",
    },
    {
      id: "6",
      iconClass: "fa fa-phone",
      label: "Airtime & Data",
      selected: selectedState.water,
      searchparam: "AIRTIME_AND_DATA",
    },
    {
      id: "3",
      iconClass: "fa fa-tv",
      label: "PaidTV",
      selected: selectedState.water,
      searchparam: "PAY_TV",
    },
    // {
    //   id: "5",
    //   iconClass: "fa fa-bus",
    //   label: "Transport",
    //   selected: selectedState.water,
    //   searchparam: "TRANSPORT_AND_TOLL_PAYMENT",
    // },
    // {
    //   id: "6",
    //   iconClass: "fa fa-shopping-cart",
    //   label: "Collections",
    //   selected: selectedState.water,
    //   searchparam: "COLLECTIONS",
    // },
    // {
    //   id: "7",
    //   iconClass: "fa fa-dice",
    //   label: "Betting & Lottery",
    //   selected: selectedState.water,
    //   searchparam: "BETTING_AND_LOTTERY",
    // },
    // {
    //   id: "10",
    //   iconClass: "fa fa-shopping-basket",
    //   label: "Food",
    //   selected: selectedState.water,
    //   searchparam: "FOOD",
    // },
  ];

  const findMatchingDisco = (param: string) => {
    const matching = bills.find((bill) => bill.searchparam === param);
    return matching;
  };

  const handleSelection = (id: string) => {
    if (selected.id.toString() === id) {
      return;
    }
    const select =
      allBillers.find(
        (biller) => biller.id.toString() == id
      );
      
    if (select) setSelected(select);
  };

  const onClick = () => {};
  

  //   useEffect(()=>{
  //     handleSelection(selected.id)
  //   }, [])

  return (
    <div className="ltn__checkout-area mb-105">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__checkout-inner">
              <BillPaymentConfirmDialog
                open={open}
                onClose={handleClose}
                meta={meta}
              />

              {/** Create a Savings Target */}
              <div className="ltn__checkout-single-content mt-50">
                <h4 className="title-2">Pay for utility bills</h4>

                <div className="ltn__checkout-single-content-info">
                  <Grid container spacing={2}>
                    {allBillers &&
                      allBillers.filter((biller)=> bills.some(bill=> bill.id === biller.id.toString())).map((b, index) => (
                        <Grid item xs={4} md={3} lg={2} key={`bill${index}`}>
                          <UtilityBillCard
                            iconClass={findMatchingDisco(b.slug)?.iconClass}
                            id={b.id}
                            label={findMatchingDisco(b.slug)?.label!}
                            onSelect={handleSelection}
                            searchparam=""
                          />
                        </Grid>
                      ))}
                  </Grid>

                  <div
                  // className={`row mt-30 ${styles.contentWrapper}`}
                  // ref={contentEl}
                  // style={
                  //   isActive()
                  //     ? { height: contentEl?.current?.scrollHeight }
                  //     : { height: "0px" }
                  // }
                  >
                      {/* <ElectricBillForm
                        // isLoading={isLoading}

                        billData={allBillers}
                        onSubmit={handleSubmit}
                      /> */}
                      <UtilityForms selectedBiller={selected}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilityBill;
