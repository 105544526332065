import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React from "react";
import { Attachment } from "../../../types/Property";
import styles from "./imageUpload.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  DeletePropertyImages,
  addPropertyImage,
  addPropertyProofs,
  deletePropertyImages,
} from "../../../swr/properties";
import Axios from "axios";
import ToastContext from "../../../contexts/ToastContext";
import LoadingContext from "../../../contexts/LoadingContext";
import LoadingScreen from "../../LoadingScreen";

export interface ImageUploadDialogProps {
  propertyId: number;
  attachments?: Attachment[];
  open?: boolean;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
  uploadCallback?: () => void;
  deleteCallback?: () => void;
}

const ImageUploadDialog: React.FC<ImageUploadDialogProps> = ({
  attachments = [],
  open = false,
  onClose,
  propertyId,
  uploadCallback,
  deleteCallback,
}) => {
  // Context
  const { openSuccess, openError } = React.useContext(ToastContext);
  // const { setLoading } = React.useContext(LoadingContext);
  const token = localStorage.getItem("token") || undefined;
  // State
  const [images, setImages] = React.useState<Attachment[]>(attachments);
  const [deletedImages, setDeletedImages] = React.useState<Attachment[]>([]);
  const [inputValue, setInputValue] = React.useState<File[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Refs
  const inputRef = React.createRef<HTMLInputElement>();

  // Handlers
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    console.log("tyest");
    let files = event.currentTarget.files;
    let filesToAdd: File[] = [];

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        filesToAdd.push(file);
      }
    }

    setInputValue((prevFiles) => [...prevFiles, ...filesToAdd]);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleAttachmentDelete = async (id?: number) => {
    if (id) {
      let filteredImages = images.filter((img) => img.id !== id);
      let deletedImages = attachments.filter((img) => img.id === id);

      setImages(filteredImages);
      setDeletedImages(deletedImages);
      await DeletePropertyImages({
        token,
        id,
      });
    }
  };

  const handleFileDelete = (name: string) => {
    let filteredFiles = inputValue.filter((img: File) => img.name !== name);
    setInputValue(filteredFiles);
  };

  const handleClose = () => {
    setImages(attachments);
    onClose && onClose();
  };

  // const handleDeleteAll = async()=>{
  //     setLoading(true);
  //     const token = localStorage.getItem('token');
  //     const { data, error } = await deletePropertyImages(propertyId, token || undefined);

  //     if (data) {
  //         setLoading(false);
  //         openSuccess('Images successfully deleted');
  //         deleteCallback && deleteCallback();
  //         onClose && onClose()
  //     }

  //     if (error){
  //         setLoading(false);
  //         openError('An error occured while deleteing the images')
  //     }
  // }

  // console.log(images, 'images', inputValue, 'inputValue');

  const addImages = async () => {
    console.log(images, "=====", inputValue);
    
    const returnInBlobFormat = images
      .map((image) => image.imageUrl)
      .map((path, index) => {
        return {
          uri: path,
          name: `photo.png${index}`,
          filename: `imageName.png${index}`,
          type: "image/png",
        };
      });

    const { data, error } = await addPropertyImage({
      id: propertyId,
      files:
        inputValue && inputValue.length > 0 ? inputValue : returnInBlobFormat,
      token: token || undefined,
      skipAppend: true,
    });

    if (data) {
      setLoading(false);
      openSuccess("Changes saved");
      // uploadCallback && uploadCallback();
      onClose && onClose();
    }

    if (error) {
      setLoading(false);
      onClose && onClose();
      openSuccess("request has been processed");
    }
  };

  const addPropertyProofDoc = async (id: number, token: string, images: File[]) => {
    const {data, error} = await addPropertyProofs(id, token, images)
    if(error){
      openError("There was an error adding the property document")
    }
  }

  const handleUpload = async () => {
    setLoading(true);
    const token = localStorage.getItem("token") || undefined;

    try {
      // if (deletedImages.length < 1) {
      //     await addImages();
      //     return;
      // };
      // const uploadResult = await addPropertyImage({id: propertyId, files: inputValue, token});
      // if (uploadResult.data) {
      //     console.log('Images successfully deleted');
      //     if (inputValue.length  < 1) {
      //         setLoading(false);
      //         openSuccess('Changes saved');
      //         uploadCallback && uploadCallback();
      //         return;
      //     }
      //     await addImages();
      // }
      // console.log(uploadResult);
    } catch (error) {
      setLoading(false);
      openError("Could not delete Images");
    }
    // let file = new File([response.data], images[i].alt || 'file');
  };

  return (
    <Dialog
      //fullScreen={fullScreen}
      maxWidth="lg"
      PaperProps={{
        sx: {
          width: "100%",
          height: "100%",
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="update-dialog-dialog-title"
      aria-describedby="update-dialog-description"
    >
      {loading && <LoadingScreen />}
      <DialogTitle id="alert-dialog-title">Update Images</DialogTitle>
      <DialogContent>
        <>
          <input
            type="file"
            id="myFile"
            name="file"
            multiple
            className="btn theme-btn-3 mb-10"
            onChange={handleChange}
            style={{ display: "none" }}
            ref={inputRef}
            //value={inputValue}
          />
          <div className={`btn theme-btn-3 mb-10`}>
            <label htmlFor="myFile" role="button" className={styles.btn}>
              Choose Files
            </label>
          </div>
          <ImageList variant="standard" cols={5} rowHeight={150}>
            {images.map((item) => (
              <ImageListItem key={`IU${item.id}`}>
                <div className={styles.image}>
                  <div className={styles.imageOverlay}>
                    <IconButton
                      onClick={() => handleAttachmentDelete(item.id)}
                      sx={{
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "16px", color: "white" }} />
                    </IconButton>
                  </div>
                  <img
                    src={item.imageUrl}
                    srcSet={item.imageUrl}
                    alt={item.alt}
                    loading="lazy"
                  />
                </div>
              </ImageListItem>
            ))}
            {inputValue?.map((item: File, index: number) => (
              <ImageListItem key={`IF${index}${item.name}`}>
                <div className={styles.image}>
                  <div className={styles.imageOverlay}>
                    <IconButton
                      onClick={() => handleFileDelete(item.name)}
                      sx={{
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "16px", color: "white" }} />
                    </IconButton>
                  </div>
                  <img
                    src={URL.createObjectURL(item)}
                    srcSet={URL.createObjectURL(item)}
                    alt={item.name}
                    loading="lazy"
                  />
                </div>
              </ImageListItem>
            ))}
          </ImageList>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => addImages()}>Save Changes</Button>
        {/* <Button color="warning" onClick={handleDeleteAll}>Delete all images</Button> */}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageUploadDialog;
