import React from 'react';
import Layout from '../components/Layout';
import NavHeader from '../components/NavHeader';
import ScrollToTop from '../components/ScrollToTop';
// import SplashScreenIncentives from '../components/SplashScreen/SplashScreenIncentive';
import { WalletCheck } from '../components/WalletCheck';
import UtilityBill from '../sections/UtilityBill';
// import Modal from "react-modal";

const UtilityBillPage: React.FC<any> = ()=>{
    // const [modalIsOpen, setModalIsOpen] = React.useState(true);
    // const openModal = () => {
    //     setModalIsOpen(true);
    //   };
    
    //   const closeModal = () => {
    //     setModalIsOpen(false);
    //   };

    //   const modalWidth = window.innerWidth > 768 ? '50%' : '70%'
    return(
        <React.Fragment>
            <ScrollToTop/>
            <Layout>
                <NavHeader
                    pageTitle="Utility Bills"
                    routeName="Utility Bills"
                />
                <WalletCheck>
                    <UtilityBill/>
                    {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            zIndex: 9999,
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            width: modalWidth,
            height: "70%"
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Comments Modal"
      >
        <SplashScreenIncentives closeModal={closeModal}/>
      </Modal> */}
                </WalletCheck>
            </Layout>
        </React.Fragment>
    )
}

export default UtilityBillPage;