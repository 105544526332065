import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ViewProps } from "../Amenities/index.old";
import styles from "./media.module.scss";
import { Attachment } from "../../../types/Property";
import { Player } from "video-react";

export interface MediaProps extends ViewProps {
  onMediaChange: (files: File[], del?: string) => void;
  selectedMedia: File[];
  onProofChange?: (files?: File[]) => void;
  attachments?: Attachment[];
  ownershipProof: File[] | undefined;
}

const Media: React.FC<MediaProps> = ({
  onPrevStep,
  onNextStep,
  prevKey,
  nextKey,
  onMediaChange,
  selectedMedia,
  onProofChange,
  ownershipProof,
}) => {
  const handlePrevStep = () => {
    if (onPrevStep && prevKey) {
      onPrevStep(prevKey);
    }
  };
  const handleNextStep = () => {
    if (onNextStep && nextKey) {
      onNextStep(nextKey);
    }
  };

  const [proof, setProof] = React.useState<File>();
  console.log(proof, "prooves===");

  // Refs
  const inputRef = React.createRef<HTMLInputElement>();
  const proofInputRef = React.createRef<HTMLInputElement>();

  const videoRef = useRef<any>();
  const handleClick = () => {
    if (videoRef.current) videoRef.current.click();
  };

  const [videoSrc, setVideoSrc] = useState("");

  const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const url = URL.createObjectURL(file);
        setVideoSrc(url);
      };

      reader.readAsDataURL(file);
      // onMediaChange && onMediaChange([...selectedMedia, file]);
    }

    if (videoRef.current) {
      videoRef.current.value = "";
    }

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    let files = event.currentTarget.files;
    let filesToAdd: File[] = [];

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        filesToAdd.push(file);
      }
    }
    onMediaChange(filesToAdd);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileDelete = (name: string) => {
    onMediaChange([], name);
  };

  const handleProofChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      setProof(event.currentTarget.files[0]);
      let files = event.currentTarget.files;
      let filesToAdd: File[] = [];

      if (files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          filesToAdd.push(file);
        }
      }
      onProofChange && onProofChange(filesToAdd);
    }
  };

  const handleDeleteProf = (proof: File, proofs: File[]) => {
    const newFiles = proofs.filter((doc) => doc !== proof);
    onProofChange && onProofChange(newFiles);
  };

  const handleProofDelete = () => {
    setProof(undefined);
    onProofChange && onProofChange(undefined);

    if (proofInputRef.current) {
      proofInputRef.current.value = "";
    }
  };
  

  return (
    <div className="tab-pane fade active show" id="liton_tab_3_2">
      <div className={`ltn__product-tab-content-inner ${styles.tabContent}`}>
        {/** Listing Media */}
        <h6>Listing Media</h6>
        <input
          type="file"
          id="myFile"
          name="file"
          multiple
          className="btn theme-btn-3 mb-10"
          onChange={handleChange}
          style={{ display: "none" }}
          ref={inputRef}
          accept="image/*"
        />
        <div className={`btn theme-btn-3 mb-10`}>
          <label htmlFor="myFile" role="button" className={styles.btn}>
            Choose Files
          </label>
        </div>
        <ImageList variant="standard" cols={5} rowHeight={150}>
          {selectedMedia?.map((item: File, index: number) => (
            <ImageListItem key={`IF${index}${item.name}`}>
              <div className={styles.image}>
                <div className={styles.imageOverlay}>
                  <IconButton
                    onClick={() => handleFileDelete(item.name)}
                    sx={{
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "16px", color: "white" }} />
                  </IconButton>
                </div>
                <img
                  src={URL.createObjectURL(item)}
                  srcSet={URL.createObjectURL(item)}
                  alt={item.name}
                  loading="lazy"
                />
              </div>
            </ImageListItem>
          ))}
        </ImageList>

        <br />
        <p>
          <small>
            * At least 1 image is required for a valid submission.Minimum size
            is 500/500px.
          </small>
          <br />
          {/* <small>* PDF files upload supported as well.</small><br/> */}
          <small>* Images might take longer to be processed.</small>
        </p>

        <h6>Certificate of ownership/Property management agreement</h6>
        {(ownershipProof == undefined || ownershipProof?.length < 1) && (
          <>
            <input
              type="file"
              id="myProof"
              name="video"
              multiple
              className="btn theme-btn-3 mb-10"
              onChange={handleProofChange}
              style={{ display: "none" }}
              //ref={inputRef}
            />
            <div className={`btn theme-btn-3 mb-10`}>
              <label htmlFor="myProof" role="button" className={styles.btn}>
                Choose File
              </label>
            </div>
          </>
        )}
        {ownershipProof &&
          ownershipProof.length > 0 &&
          ownershipProof.map((proof) => (
            <div className={styles.proofDiv}>
              <div className={styles.imageOverlay}>
                <IconButton
                  onClick={() => handleDeleteProf(proof, ownershipProof)}
                  sx={{
                    position: "absolute",
                    right: 0,
                  }}
                >
                  <CloseIcon sx={{ fontSize: "16px", color: "white" }} />
                </IconButton>
              </div>
              <InsertDriveFileIcon />
              <label>{proof.name}</label>
            </div>
          ))}
        <p>
          <small>* This is required for a valid submission.</small>
          <br />
        </p>

        {/** Video Option */}
        {/* <h6>Video Option</h6> */}
        {/* <div className="row"> */}
        {/* <div className="col-md-6">
                    <Dropdown/>
                </div> */}
        {/* <div className="col-md-6"> */}
        {/* <input
              type="file"
              id="myVideo"
              name="video"
              className="btn theme-btn-3 mb-10"
              onChange={handleVideoChange}
              style={{ display: "none" }}
              ref={videoRef}
              accept="video/*"
              autoCorrect="off"
            /> */}
        {/* <TextInput placeholder="Embed Video ID" /> */}
        {/* <div className={`btn theme-btn-3 mb-10`}>
              <label
                htmlFor="File"
                role="button"
                onClick={handleClick}
                className={styles.btn}
              >
                Choose Video
              </label>
            </div> */}

        {/* {videoSrc && (
              <div className={styles.videoPlayer}>
                <Player
                  // playsInline
                  // src={videoSrc}
                  fluid={false}
                  //@ts-ignore
                  width={"100%"}
                  //@ts-ignore
                  height={"100%"}
                >
                  <source src={videoSrc} />
                </Player>
              </div>
            )} */}
        {/* </div> */}
        {/* </div> */}

        {/** Virtual Tour */}
        {/**<h6>Virtual Tour</h6>
            <TextArea placeholder="Virtual Tour:" />*/}
        <div className="btn-wrapper text-center--- mt-0">
          <button
            className="btn theme-btn-1 btn-effect-1 text-uppercase"
            onClick={handlePrevStep}
          >
            Prev Step
          </button>
          <button
            className="btn theme-btn-1 btn-effect-1 text-uppercase"
            onClick={handleNextStep}
          >
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default Media;
