import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PaymentScheduleSummaryDialog from "../../components/Dialogs/PaymentScheduleSummary";
import PromptDialog from "../../components/Dialogs/PromptDialog";
import ReleaseSavingsForm from "../../components/Forms/ReleaseSavingsForm/ReleaseSavingsForm";
import SaveForRentForm from "../../components/Forms/SaveForRentForm";
import LoadingContext from "../../contexts/LoadingContext";
import ToastContext from "../../contexts/ToastContext";
import {
  CreateScheduledSavingsApi,
  callRecurrentJob,
  saveForRent,
} from "../../swr/saveForRent";
import { SavingsTowardsRentRequest } from "../../types/Savings";
import { hasWallet } from "../../utils/generalUtils";
import CreateSavingsForm from "./CreateSavingsForm";
import styles from "./saveRent.module.scss";
import { getWalletDetails } from "../../swr/wallet";

const SaveForRent: React.FC<any> = () => {
  const token = localStorage.getItem("token") || "";

  // State
  const [request, setRequest] =
    React.useState<SavingsTowardsRentRequest | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [promptOpen, setPropmtOpen] = React.useState<boolean>(false);
  const [scheduleSavings, setScheduleSavings] = React.useState(true);
  const [instantSavings, setInstantSavings] = React.useState(false);
  const [hasWallet, setHasWallet] = React.useState(false)

  // Contexts
  const { setLoading } = React.useContext(LoadingContext);
  const { openSuccess, openError } = React.useContext(ToastContext);

  // Hooks
  const history = useHistory();

  // Handlers
  const handleDialogClose = () => {
    setOpen(false);
  };
  const handlePromptClose = () => {
    setLoading(false);
    setPropmtOpen(false);
    history.push("/account#liton_tab_1_6");
  };

  const handleSaveForRent = async (state: SavingsTowardsRentRequest) => {
    setRequest(state);
    if (state) {
      setOpen(false);
      setLoading(true);

      const response = await CreateScheduledSavingsApi(
        {
          name: state.name,
          description: state.description,
          userEmail: state.userEmail,
          paymentFrequency: state.paymentFrequency,
          targetedAmount: state.targetedAmount,
          savingsAmount: state.savingsAmount,
          paymentStartDate: state.paymentStartDate,
          creditAccount: state.creditAccount,
        },
        token
      );

      if (response.status === 200) {
        const resObject = response.data && response.data;
        if (resObject && resObject.succeeded === true) {
          setLoading(false);
          openSuccess(
            resObject.message
              ? resObject.message
              : "Your savings plan was successfully created"
          );
          history.push("/account#liton_tab_1_2");
        } else {
          setLoading(false);
          openError(
            resObject.message
              ? resObject.message
              : resObject.errors && resObject.errors[0]
              ? resObject.errors[0]
              : "an error occurred"
          );
        }
      } else {
        setLoading(false);
        openError(
          response.message
            ? response.message
            : response.Message
            ? response.Message
            : "an error occurred"
        );
      }
    }
  };

  useEffect(() => {
    getWalletDetails(token).then((result) => {
      if (result.data) {
          result &&
            result.data &&
            result.data.data &&
            result.data.data.customer_email && setHasWallet(true)
      }

      if (result.error) {
        if (
          result.error.response &&
          result.error.response.data &&
          result.error.response?.data.Message === "01"
        ) {
          setHasWallet(false)
          openError('You need to create a wallet before create savings')
          history.push('/account#liton_tab_1_6')
        }
      }
    });
  }, [])

  const handleFormSubmit = (state: SavingsTowardsRentRequest, cb: any) => {
    // Check if user has a wallet
    setRequest(state);
    if (!hasWallet) {
      setPropmtOpen(true);
      return;
    }
    setLoading(false);
    setOpen(true);
  };

  const handleConfirm = async (state: SavingsTowardsRentRequest) => {
    setOpen(false);
    handleSaveForRent(state);
  };

  return (
    <>
      <PromptDialog
        title="This service requires a wallet"
        open={promptOpen}
        onProceed={handlePromptClose}
        proceedText="close"
      >
        It looks like you do not have a wallet. Please create one before using
        this service
      </PromptDialog>
      <PaymentScheduleSummaryDialog
        open={open}
        paymentSchedules={request?.paymentScheduleArray}
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
        state={request}
      />
      <div className="ltn__checkout-area mb-105">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__checkout-inner">
                {/** Create a Savings Target */}
                <div
                  className="ltn__checkout-single-content mt-50"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    onClick={() => {
                      setScheduleSavings(true);
                      setInstantSavings(false);
                    }}
                    className={`btn theme-btn-${scheduleSavings ? 1 : 3} btn-effect-1 text-uppercase ${styles.btn}`}
                  >
                    schedule savings
                  </button>
                  <button
                    onClick={() => {
                      setInstantSavings(true);
                      setScheduleSavings(false);
                    }}
                    className={`btn theme-btn-${instantSavings ? 1 : 3} btn-effect-1 text-uppercase ${styles.btn}`}
                  >
                    Instant savings
                  </button>
                </div>
                {scheduleSavings && (
                  <div className="ltn__checkout-single-content-info">
                    <SaveForRentForm onSubmit={handleFormSubmit} />
                  </div>
                )}
                {instantSavings && (
                  <div className="ltn__checkout-single-content-info">
                    <CreateSavingsForm />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveForRent;
