import React from "react";
import styles from "../insurance-comp.module.scss";
import { formatAmount } from "../../../utils/generalUtils";

const PaymentModal = ({
  Proceed,
  FundWallet,
  closeAllModals,
  walletBalance,
  amountToPay,
  pin,
  pinOnChange,
}: {
  Proceed: () => void;
  FundWallet: () => void;
  closeAllModals: () => void;
  walletBalance?: string;
  amountToPay?: number;
  pin?: string;
  pinOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className={styles["save-cont"]}>
      <div className={styles["inner-cont"]}>
        <div
          className={styles["close-cont"]}
          role="button"
          onClick={() => closeAllModals()}
        >
          <div className="icon-cancel"></div>
        </div>

        <span className={styles["save-quote-header"]}>
          Pay ₦ {amountToPay !== undefined ? formatAmount(amountToPay) : ""}
        </span>
        <div className={styles["payment-modal-line"]}></div>

        <div className={styles["payment-wallet-bal"]}>
          <span className={styles["payment-text-w"]}>Wallet Balance</span>

          <span className={styles["payment-header"]}>
            ₦{formatAmount(walletBalance ? parseFloat(walletBalance) : 0)}
          </span>
        </div>

        <div className={styles.inputcolumn}>
          <span>
            PIN<span className={styles.red}>*</span>
          </span>
          <input
            name="pin"
            onChange={pinOnChange}
            className={styles.inputfield}
            autoComplete="off"
            value={pin}
          />
        </div>

        <div
          className={styles["payment-but-green"]}
          role="button"
          onClick={() => Proceed()}
        >
          <span>Proceed</span>
        </div>

        <div className={styles["payment-bottom"]}>
          <span className={styles["payment-text"]}>InsufficientFunds?</span>
          <span
            className={styles["payment-header"]}
            onClick={() => FundWallet()}
            role="button"
          >
            Fund Wallet
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
