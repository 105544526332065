import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavHeader from "../NavHeader";
import styles from "./walletTransfer.module.scss";
import Input from "../Input";
import {
  confirmPayment,
  NameEnquiry,
  WalletToAccountTransfer,
  WalletToWalletTransfer,
} from "../../swr/wallet";
import {
  WalletToBankTransferRequest,
  WalletToWalletTransferRequest,
} from "../../types/Wallet";
import { getUserId, getWalletId } from "../../utils/generalUtils";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ToastContext from "../../contexts/ToastContext";
import LoadingContext from "../../contexts/LoadingContext";
import { getUser, useGetUser } from "../../swr/user";
import { genTransactionRef } from "../../utils/propertyUtils";
import Dropdown from "../FormElements/Dropdown";
import { BankCodes, EachBankObj } from "../../types/Wallet";
import { getBankCodes } from "../../swr/wallet";
import Select, { ActionMeta, SingleValue } from "react-select";
import CircularProgress from "@mui/material";
import TextInput from "../FormElements/TextInput";
import { BsEyeSlash, BsEye } from "react-icons/bs";

const WalletTransfer = () => {
  const token = localStorage.getItem("token") || "";
  const email = localStorage.getItem("email") || "";
  let uid = localStorage.getItem("id") as string;
  const { user, isLoading, isError } = useGetUser(email as string, token!);
  const ref = genTransactionRef();

  const [codeObjects, setCodeObjects] = useState<EachBankObj[]>([
    {
      bankCode: "",
      bankName: "",
    },
  ]);

  const fetchBankCodes = async () => {
    try {
      const codeObj = await getBankCodes(token);
      if (codeObj && codeObj.succeeded === true) {
        codeObj.data && setCodeObjects(codeObj.data);
      } else {
        openError(
          codeObj.message ? codeObj.message : "error loading banks' list"
        );
      }
      return;
    } catch (err) {
      // @ts-ignore
      let error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      openError(error.response.data.message ?? "error loading banks' list");
    }
  };

  useEffect(() => {
    fetchBankCodes();
  }, []);

  let bankOptions: string[] = [];
  codeObjects.filter(
    (item) => item.bankName && bankOptions.push(item.bankName)
  );

  type BankDropDown = {
    label: string;
    value: string;
  };

  let bankDropDown: BankDropDown[] = [];
  codeObjects &&
    codeObjects.map((obj) =>
      bankDropDown.push({
        label: obj.bankName,
        value: obj.bankCode,
      })
    );

  const genMsgId = () => {
    let msg_id = Math.floor(Math.random() * 1000000000);
    return msg_id.toString();
  };

  const { openError, openSuccess } = React.useContext(ToastContext);
  const { setLoading } = React.useContext(LoadingContext);

  const history = useHistory();
  const id = getWalletId(token);
  const userId = user && user.id;
  const [accountName, setAccountName] = useState("");
  const [wall2walldestNo, setWall2walldestNo] = useState("");
  const [isAccNoFocus, setIsAccNoFocus] = useState(false);
  const [isWalletIdFocus, setIsWalletIdFocus] = useState(false);
  const [creditBankCode, setCreditBankCode] = useState("");
  const [walletToWallet, setWalletToWallet] = useState(false);
  const [walletToAccount, setWalletToAccount] = useState(false);
  const [pin, setPin] = useState("");
  const [formData, setFormData] = useState<WalletToWalletTransferRequest>({
    destination_wallet_id: "",
    amount: "",
    narration: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formDatas, setFormDatas] = useState<WalletToBankTransferRequest>({
    credit_account: "",
    transaction_amount: "",
    message_id: genMsgId(),
    transaction_narration: "",
    debit_virtual_account: id,
    source_email: email,
    customer_id: email,
    credit_bank_code: "",
    transaction_reference: ref,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormDatas({
      ...formDatas,
      [e.target.name]: e.target.value,
    });
  };

  const handleBankSelection = (
    newValue: SingleValue<BankDropDown | null>,
    actionMeta: ActionMeta<BankDropDown | null>
  ) => {
    if (newValue) {
      console.log(newValue.value);
      
      setFormDatas((prev) => ({
        ...prev,
        credit_bank_code: newValue.value,
      }));
      setCreditBankCode(newValue.value);
    }
  };

  const EnquireAccountName = async () => {
    const response = await NameEnquiry({
      requestBody: {
        credit_bank_code: creditBankCode,
        credit_account: formDatas.credit_account,
      },
      token,
    });
    if (response && response.succeeded) {
      setAccountName(response.data && response.data);
      return;
    } else if (!response.succeeded) {
      openError(
        response.message
          ? response.message
          : "Unable to fetch credit account details"
      );
      return;
    } else {
      openError(
        response.message
          ? response.message
          : response.Message
          ? response.Message
          : "Unable to fetch credit account details"
      );
    }
  };

  const EnquireAccountNameII = async () => {
    const response = await NameEnquiry({
      requestBody: {
        credit_bank_code: "044",
        credit_account: formData.destination_wallet_id,
      },
      token,
    });
    if (response && response.succeeded) {
      setWall2walldestNo(response.data && response.data);
      return;
    } else if (!response.succeeded) {
      openError(
        response.message
          ? response.message
          : "Unable to fetch credit account details"
      );
      return;
    } else {
      openError(
        response.message
          ? response.message
          : response.Message
          ? response.Message
          : "Unable to fetch credit account details"
      );
    }
  };

  useEffect(() => {
    if (formDatas.credit_account && formDatas.credit_account.length === 10) {
      EnquireAccountName();
    }

    if (
      formData.destination_wallet_id &&
      formData.destination_wallet_id.length === 10
    ) {
      EnquireAccountNameII();
    }
  }, [formDatas.credit_account, formData.destination_wallet_id]);

  const formRef = React.useRef<HTMLFormElement | null>(null);

  const handleSubmit = async () => {
    setLoading(true);
    if (!pin) {
      openError("please enter pin");
      return;
    }

    if (pin.length !== 4) {
      openError("please enter your 4-digit pin");
      return;
    }
    if (walletToWallet) {
      const res = await WalletToWalletTransfer(formData, token, pin);
      if (res && res.data) {
        let response = res.data;
        if (response.succeeded === true) {
          setLoading(false);
          setFormData({
            destination_wallet_id: "",
            amount: "",
            narration: "",
          });
          openSuccess("Transfer successful");
          history.push("/account#liton_tab_1_6");
        } else {
          setLoading(false);
          openError(response.message);
        }
      } else {
        setLoading(false);
        setFormData({
          destination_wallet_id: "",
          amount: "",
          narration: "",
        });
        openError(res.error?.response?.data.Message ?? "an error occurred");
      }
    }
    if (walletToAccount) {
      if (!formDatas.credit_account || formDatas.credit_account.length !== 10) {
        openError("Please enter valid 10-digit account number");
        setLoading(false);
        return;
      }
      if (accountName === "") {
        openError("Name enquiry failed");
        setLoading(false);
        return;
      }
      const res = await WalletToAccountTransfer(formDatas, token, pin);
      if (res && res.data) {
        let response = res.data;
        if (response.succeeded === true) {
          setLoading(false);
          setFormDatas({
            credit_account: "",
            transaction_amount: "",
            message_id: genMsgId(),
            transaction_narration: "",
            debit_virtual_account: id,
            source_email: email,
            customer_id: "",
            credit_bank_code: "",
            transaction_reference: "",
          });
          setAccountName("");
          setPin("");
          openSuccess("Transfer successful");
          history.push("/account#liton_tab_1_6");
        } else {
          setLoading(false);
          openError(response.message);
        }
      } else {
        setLoading(false);
        setFormDatas({
          credit_account: "",
          transaction_amount: "",
          message_id: genMsgId(),
          transaction_narration: "",
          debit_virtual_account: id,
          source_email: email,
          customer_id: "",
          credit_bank_code: "",
          transaction_reference: "",
        });
        setAccountName("");
        setPin("");
        openError(res.error?.response?.data.Message ?? "an error occurred");
      }
    }
  };

  return (
    <>
      <NavHeader pageTitle="Wallet Transfer" />
      <section className={styles.section}>
        <h4 className="title-2">Wallet Transfer </h4>
        <div className={styles.buttondiv}>
          <Button
            onClick={() => {
              setWalletToWallet(true);
              setWalletToAccount(false);
            }}
            type="button"
            variant="contained"
            sx={{
              textAlign: "center",
              backgroundColor: walletToWallet
                ? "var(--ltn__secondary-color)"
                : "var(--ltn__paragraph-color)",
              "&:hover": {
                backgroundColor: "var(--ltn__secondary-color)",
              },
            }}
          >
            Wallet to Wallet
          </Button>
          <Button
            onClick={() => {
              setWalletToWallet(false);
              setWalletToAccount(true);
            }}
            type="button"
            variant="contained"
            sx={{
              textAlign: "center",
              backgroundColor: walletToAccount
                ? "var(--ltn__secondary-color)"
                : "var(--ltn__paragraph-color)",
              "&:hover": {
                backgroundColor: "var(--ltn__secondary-color)",
              },
            }}
          >
            Withdraw from Wallet
          </Button>
        </div>

        {walletToWallet && (
          <>
            <div
              style={{
                width: "40em",
                height: "3em",
                marginBottom: "2em",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                borderRadius: "3%",
                fontSize: 13,
                color: "#00A86B",
              }}
            >
              Transfer from your wallet to the HomeBuddy wallet of another
              customer
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  fontSize: 13,
                  color: "#00A86B",
                  alignSelf: "flex-end",
                }}
              >
                {formData.destination_wallet_id.length > 0 &&
                formData.destination_wallet_id.length !== 10
                  ? "10 digits required"
                  : wall2walldestNo}
              </span>
              <input
                type="text"
                placeholder="Beneficiary's WalletId*"
                required={true}
                onChange={handleChange}
                value={formData.destination_wallet_id}
                className={styles.input}
                name="destination_wallet_id"
              />
            </div>
            {/* </div> */}

            {/* <div className="col-md-6"> */}
            <Input
              type="text"
              name="amount"
              placeholder="Amount*"
              required={true}
              onChange={handleChange}
              value={formData.amount}
            />
            {/* </div> */}

            {/* <Input type="email" name="destination_email" placeholder="Receiver's-email*" required={true} onChange={handleChange} value={formData.destination_email} /> */}

            {/* <div className="col-md-6"> */}
            <Input
              type="text"
              name="narration"
              placeholder="Description*"
              required={true}
              onChange={handleChange}
              value={formData.narration}
            />
            {/* </div> */}

            {/* <div className="col-md-6"> */}
            {/* <TextInput
              type="password"
              placeholder="Transaction Pin*"
              required={true}
              onChange={(e) => setPin(e.target.value)}
              name="password"
              value={pin}
              showPassword={showPassword}
              togglePasswordVisibility={togglePasswordVisibility}
            /> */}

            <div className={styles.ref}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Transaction Pin*"
                required={true}
                onChange={(e) => setPin(e.target.value.trim())}
                value={pin}
                className={styles.input}
              />

              <div
                className={styles.eyeIcon}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </div>
            </div>

            {/* <div className="col-md-2"> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <button
                className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                type="button"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>

            {/* </div> */}
          </>
        )}
        {walletToAccount && (
          <form ref={formRef}>
            {/* <div className="col-md-12"> */}

            <div
              style={{
                width: "100%",
                marginBottom: "10%",
              }}
            >
              <Select
                defaultValue={null}
                options={
                  bankDropDown && bankDropDown.length > 0
                    ? bankDropDown
                        .slice()
                        .sort((a, b) => a.label.localeCompare(b.label))
                    : []
                }
                name="bankcode"
                placeholder="Select Bank"
                onChange={handleBankSelection}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "3em",
                marginBottom: "2em",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                borderRadius: "3%",
                fontSize: 13,
                color: "#00A86B",
              }}
            >
              Transfer from your wallet to any bank account linked with your
              BVN; Transfer Charge Per Transaction: ₦50
            </div>

            {/* </div> */}
            {/* <div className="col-md-12"> */}
            <div
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
               <span
                style={{
                  fontSize: 13,
                  color: "#00A86B",
                  alignSelf: "flex-end",
                }}
              >
                {formDatas.credit_account.length > 0 &&
                formDatas.credit_account.length !== 10
                  ? "10 digits required"
                  : accountName}
              </span>
              <Input
                type="text"
                name="credit_account"
                placeholder="Account-no*"
                required={true}
                onChange={handleChanges}
                value={formDatas.credit_account}
              />
            </div>

            {/* </div> */}

            {/* <div className="col-md-12"> */}
            <Input
              type="text"
              name="transaction_amount"
              placeholder="Amount*"
              required={true}
              onChange={handleChanges}
              value={formDatas.transaction_amount}
            />
            {/* </div> */}

            {/* <Input type="email" name="destination_email" placeholder="Receiver's-email*" required={true} onChange={handleChanges} value={formDatas.destination_email}/> */}
            {/* <div className="col-md-12"> */}
            <Input
              type="text"
              name="transaction_narration"
              placeholder="Description*"
              required={true}
              onChange={handleChanges}
              value={formDatas.transaction_narration}
            />
            {/* </div> */}

            {/* <div className="col-md-12"> */}
            <div className={styles.ref}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Transaction Pin*"
                required={true}
                onChange={(e) => setPin(e.target.value.trim())}
                value={pin}
                className={styles.input}
              />

              <div
                className={styles.eyeIcon}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </div>
            </div>
            {/* </div> */}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <button
                className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
                type="button"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
          </form>
        )}

        {/* </form> */}
        <div className={styles.confirmdiv}>
          <Link to="/account">
            <button
              className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
            >
              Back
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default WalletTransfer;
