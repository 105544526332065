import React from 'react'
import Layout from '../../components/Layout'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'
import NavHeader from '../../components/NavHeader'
import DeleteAccount from './page'

const DeleteAccountPage = () => {
  return (
    <Layout>
    <ScrollToTop />
    <SessionCheck shouldRedirect />
    <NavHeader pageTitle="Applications Dashboard" routeName="Dashboard Options" />
    <DeleteAccount />
  </Layout>
  )
}

export default DeleteAccountPage