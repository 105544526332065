import React from "react";
import Insurance from "..";
import Layout from "../../../components/Layout";
import NavHeader from "../../../components/NavHeader";
import ScrollToTop from "../../../components/ScrollToTop";
import SessionCheck from "../../../components/SessionCheck";
import HomeOwnersInsurance from "./Page";

const HomeOwnersInsurancePage = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader
        pageTitle="Home Owners Insurance"
        routeName="Home Owners Insurance"
      />
      <HomeOwnersInsurance />
    </Layout>
  );
};

export default HomeOwnersInsurancePage;
