import React, { useEffect, useState } from "react";
import HamburgerToggle from "../../svg/HamburgerToggle";
import { IHeaderMiddleAreaProps } from "../Header/HeaderMiddleArea";
import styles from "./navbar.module.scss";
// import siteLogo from "../../assets/siteLogo.svg";
// C:\Users\OlufadeV\access_homes_frontend\src\assets\HomeBuddyLogo_OnGreeC:\Users\OlufadeV\access_homes_frontend\src\assets\HomeBuddyLogo_FullColour_PrimaryLockup.svg
// src/assets/HomeBuddyLogo_FullColour_SecondaryLockup.svg
// src\assets\
import AppLogo from "../../assets/HomeBuddy_RequestedColour_PrimaryLockup.svg";
import { ReactComponent as AppLogo1 } from "../../assets/Homebuddy1_white.svg";
import { Link } from "react-router-dom";
import SessionContext from "../../contexts/SessionContext";
import jwt, { Jwt, JwtPayload } from "jsonwebtoken";
import { menuItems } from "../../data/menuItems";
import { useGetUser } from "../../swr/user";
import { UserType, UserTypeBackEnd } from "../../types/UserType";
import { BsFillHouseAddFill } from "react-icons/bs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

// Menu items
export interface NavMenuItem {
  label: string;
  href: string;
  subItems?: NavMenuItem[];
}

const NavigationBar: React.FC<IHeaderMiddleAreaProps> = ({ open, setOpen }) => {
  const [navBarType, setNavBarType] = React.useState<string>("");
  const [listType, setListType] = React.useState<string>(styles.ahMenuItem);
  const [isPensionAdmin, setIsPensionAdmin] = useState(false);
  const [isMortgageAdmin, setIsMortgageAdmin] = useState(false);
  const [isGeneralAdmin, setIsGeneralAdmin] = useState(false);
  const [isInsuranceAdmin, setIsInsuranceAdmin] = useState(false);

  const admin = process.env.REACT_APP_ADMIN_EMAIL;

  const insuranceAdminMail = process.env.REACT_APP_ADMIN_INSURANCE_EMAIL;

  const [showList, setShowList] = useState(false);

  // Context
  const { isExpired, setIsExpired } = React.useContext(SessionContext);
  // Token
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const { user, isLoading, isError } = useGetUser(email as string, token!);

  let isTenant = false;
  let isAdmin = false;
  if (token && email) {
    if (user && user.userType === UserTypeBackEnd.Tenant) {
      isTenant = true;
    }

    if (email === admin) {
      isAdmin = true;
    }
    // Check if token has expired, redirect to signin and remove the token
    // let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
    // if (decodedToken) {
    //   const payload = decodedToken.payload as JwtPayload;
    //   if (payload.roles === "Tenant") {
    //     isTenant = true;
    //   }
    // }
  }

  const handleMenuToggle = () => setOpen(!open);

  const handleScroll = () => {
    const position: boolean = window.scrollY > 445;
    if (position) {
      setNavBarType("sticky-active");
      setListType("");
    } else {
      setNavBarType("");
      setListType(`${styles.ahMenuItem}`);
    }
  };

  const logout = () => {
    localStorage.removeItem("paymentState");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    localStorage.removeItem("homezone_wallet");
    localStorage.removeItem("paymentState");
  };

  const returnFixedLengthName = (name: string): string => {
    if (window.innerWidth < 768) {
      if (name.length > 8) {
        return name.slice(0, 5) + "...";
      }
      return name;
    }
    return name;
  };

  // Effects
  React.useEffect(() => {
    const pensionAdmin = process.env.REACT_APP_ADMIN_PENSION_EMAIL;
    const mortgageAdmin = process.env.REACT_APP_ADMIN_MORTGAGE_EMAIL;
    const generalAdmin = process.env.REACT_APP_ADMIN_EMAIL;

    if (pensionAdmin === email) {
      setIsPensionAdmin(true);
    }

    if (mortgageAdmin === email) {
      setIsMortgageAdmin(true);
    }

    if (generalAdmin === email) {
      setIsGeneralAdmin(true);
    }

    if (insuranceAdminMail === email) {
      setIsInsuranceAdmin(true);
    }

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let userImgAvailable =
    token && user && user.imageURL !== undefined ? true : false;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Nav content
  const navContent = (
    <div className={styles.container}>
      {/** Logo */}
      <div className="site-logo">
        <Link to="/">
          <div className={`d-flex align-items-center pb-2 pt-2`}>
            <div className={styles.logoImage}>
              <img src={AppLogo} alt="HomeBuddyLogo" />
            </div>
          </div>
        </Link>
      </div>

      {/** Menu */}
      <div className={`header-menu d-xl-block ${styles.mainMenu}`}>
        <nav>
          <div className="ltn__main-menu">
            <ul>
              {menuItems.map((item, index) => {
                if (item.subItems) {
                  return (
                    <li key={`men${index}`} className={styles["li-nav-menu"]}>
                      <Link to={item.href} className={listType}>
                        {item.label}
                      </Link>
                      <ul className="sub-menu menu-pages-img-show">
                        {item.subItems?.map((s, index) => (
                          <li key={`${s.label}${index}`}>
                            <Link to={s.href}>{s.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                }

                return (
                  <li key={`men${index}`} className={styles["li-nav-menu"]}>
                    <Link to={item.href} className={listType}>
                      {item.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </div>

      <div
        className={`ltn__header-options ltn__header-options-2 mb-sm-20 ${styles.toggleAndPerson}`}
      >
        <div
          className={styles.picturedropdowncontainer}
          role="button"
          onClick={() => setShowList(!showList)}
        >
          {userImgAvailable ? (
            <img
              src={user!.imageURL}
              alt="logged in user"
              className={styles.picture}
              loading="lazy"
            />
          ) : (
            <a href="#" className={styles.noAuthPicture}>
              <i className="icon-user"></i>
            </a>
          )}
          <span
            style={{
              color: navBarType === "" ? "" : "#3A3A3A",
              fontSize: "14px",
            }}
          >
            {" "}
            {user && user.firstName
              ? returnFixedLengthName(user.firstName)
              : ""}
          </span>
          {showList ? (
            <ArrowDropUpIcon sx={{ color: "white" }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: "white" }} />
          )}
        </div>

        {token && !isTenant && (
          <Link className={styles.addproperty} to="/add-listing">
            <BsFillHouseAddFill style={{ fontSize: "2em" }} />
          </Link>
        )}

        {showList && (
          <div className={styles.listUnderPicture}>
            {!token && (
              <span>
                <Link to="/signin">Sign in</Link>
              </span>
            )}

            {!token && (
              <span>
                <Link to="/signup">Register</Link>
              </span>
            )}
            {token && (
              <span>
                <Link to="/account">My Account</Link>
              </span>
            )}
            {token && !isTenant && (
              <span>
                <Link to="/mylistings">My Listings</Link>
              </span>
            )}
            {token && !isTenant && (
              <span>
                <Link to="/add-listing">Add Property</Link>
              </span>
            )}
            {token && (
              <span>
                <Link to="/savedProperties">Saved Properties</Link>
              </span>
            )}
            {token && isAdmin === true && (
              <span>
                <Link to="/dashboardad">Admin Dashboard</Link>
              </span>
            )}
            {token && isPensionAdmin === true && (
              <span>
                <Link to="/viewpensions">Pension Applications</Link>
              </span>
            )}
            {token && isMortgageAdmin === true && (
              <span>
                <Link to="/viewmortgage">Mortgage Applications</Link>
              </span>
            )}
            {token &&
              (isGeneralAdmin === true || isInsuranceAdmin === true) && (
                <span>
                  <Link to="/viewrentinsurance">
                    Rent Insurance Applications
                  </Link>
                </span>
              )}
            {token && (
              <span>
                <Link to="/logout">Sign out</Link>
              </span>
            )}
          </div>
        )}
        {/* Mobile Menu Button */}
        <div
          className={`mobile-menu-toggle d-xl-none ${styles.mobileMenu}`}
          onClick={handleMenuToggle}
        >
          <a href="#" className={`${open ? "close" : ""} ltn__utilize-toggle`}>
            {<HamburgerToggle />}
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white ${navBarType}`}
    >
      {navContent}
    </div>
  );
};

export default NavigationBar;

{
  /* 
      <div
        className={`ltn__header-options ltn__header-options-2 mb-sm-20 ${styles.secondaryMenu}`}
      > */
}

{
  /* <div className="ltn__drop-menu user-menu">
          <ul>
            <li>
              {userImgAvailable ? (
                <img
                  src={user!.imageURL}
                  alt="logged in user"
                  className={styles.picture}
                  loading="lazy"
                />
              ) : (
                <a href="#">
                  <i className="icon-user"></i>
                </a>
              )}

              <ul>
                {!token && (
                  <li>
                    <Link to="/signin">Sign in</Link>
                  </li>
                )}

                {!token && (
                  <li>
                    <Link to="/signup">Register</Link>
                  </li>
                )}
                {token && (
                  <li>
                    <Link to="/account">My Account</Link>
                  </li>
                )}
                {token && !isTenant && (
                  <li>
                    <Link to="/mylistings">My Listings</Link>
                  </li>
                )}
                {token && (
                  <li>
                    <Link to="#" onClick={logout}>
                      Sign out
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div> */
}

{
  /* {token && !isTenant && !isMobile && (
          <span
            className="special-link"
            style={{
              fontWeight: "bold",
              ...(window.innerWidth <= 768 && {
                width: "10%",
                marginTop: "0",
              }),
            }}
          >
            <Link to="/add-listing">
              <BsFillHouseAddFill style={{ fontSize: "2em" }} />
            </Link>
          </span>
        )} */
}
