import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import HouseIcon from '@mui/icons-material/House';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const drawerWidth = 245;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export interface SideNaVProps{
  extend?: boolean;
  onCloseDrawer?: (arg?: any)=> any;
}

export default function SideNav({ extend = false, onCloseDrawer }: SideNaVProps) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(extend);

    // const toggleDrawer = () =>{
    //    setOpen(!open)
    // }

    const closeDrawer = ()=>{
      setOpen(false);
      onCloseDrawer && onCloseDrawer();
    }

    // Effect for setting open using props
    React.useEffect(()=>{
      setOpen(extend);
    }, [extend])

    // List items
    interface NavItem{
      label: string; icon: JSX.Element
    }
    const navItems: NavItem[] = [
      { label: 'Properties', icon: <HouseIcon/> },
      { label: 'Users', icon: <PeopleIcon/> }
    ];

    const bottomNavItems: NavItem[] = [
      { label: 'Requests', icon: <InboxIcon/> },
      { label: 'Settings', icon: <SettingsIcon/> },
    ]

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={closeDrawer} sx={{ '& img':{ width: 30, height: 30 } }}  >
                    <img src="/logoNoText.svg" alt="Access Bank logo" />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
            {navItems.map((item, index) => (
                <ListItem button key={item.label}>
                <ListItemIcon>
                    { item.icon }
                </ListItemIcon>
                <ListItemText primary={item.label} />
                </ListItem>
            ))}
            </List>
            <Divider />
            <List>
            {bottomNavItems.map((item, index) => (
                <ListItem button key={item.label}>
                <ListItemIcon>
                   {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
                </ListItem>
            ))}
            </List>
        </Drawer>
    );
}
