import PlumbingIcon from '@mui/icons-material/Plumbing';
import KitchenIcon from '@mui/icons-material/Kitchen';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import PestControlIcon from '@mui/icons-material/PestControl';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';

export const items = [
    {
        id: 1,
        name: 'Plumbing',
        icon: PlumbingIcon
    },
    {
        id: 2,
        name: 'AC & Refrigeration',
        icon: KitchenIcon
    },
    {
        id: 3,
        name: 'Electrical',
        icon: TipsAndUpdatesIcon
    },
    {
        id: 4,
        name: 'Kitchen Appliance',
        icon: MicrowaveIcon
    },
    {
        id: 5,
        name: 'Carpentry',
        icon: CarpenterIcon
    },
    {
        id: 6,
        name: 'Fumigation',
        icon: PestControlIcon
    },
    {
        id: 7,
        name: 'Cleaning',
        icon: CleaningServicesIcon
    },
    {
        id: 8,
        name: 'Generator',
        icon: ElectricalServicesIcon
    }
]