import { motion, TargetAndTransition } from 'framer-motion';
import React, { useState } from 'react';
import { formatAmount, formatCurrency } from '../../../utils/propertyUtils';
import styles from './textInput.module.scss';
import { BsEyeSlash, BsEye } from 'react-icons/bs';

export interface TextInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
    variant?: 'amount' | 'date';
    useValue?: string | number;
    disabled?: boolean;
    hideDefaultAdornment?: boolean;
    containerClass?: string;
    errorMessage?: string;
    error?: boolean;
    regex?: RegExp;
    clear?: boolean;
    onValueChange?: (value: any) => void;
    defaultValue?: string | number;
    showPassword?: boolean;
    togglePasswordVisibility?: () => void;
    regError?: boolean;
    onBlur?: () => void;
    checkConfirmPassword?: () => void;
    checkPasswordIfCorrected?: () => void
}

const TextInput: React.FC<TextInputProps> = ({
    placeholder,
    type = "text",
    variant, useValue, disabled = false,
    hideDefaultAdornment,
    containerClass,
    error = false, errorMessage = 'This field is required',
    id, regex, clear,
    onChange, onValueChange, defaultValue, showPassword, togglePasswordVisibility, regError, checkConfirmPassword, checkPasswordIfCorrected, 
    ...props

})=>{

    // State
    const [isError, setError] = React.useState<boolean>(error);
    const [passwordFocus, setPasswordFocus] = useState<boolean>(false);
    const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false)
    const [showFirstPassword, setShowFirstPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [isLoginInput, setIsLoginInput] = useState(false)
    
    let shake: TargetAndTransition = {
        x:[0, -10, 10, 0, -10, 10, 0],
        transition:{
            duration: 0.25
        }
    }

    let animation = isError ? shake : undefined;
   
    
    // Effect
    React.useEffect(()=>{
        setError(error)
    }, [error]);

    // Init state
    let initValue = useValue || "";

    if (variant === 'amount' && useValue) {
        initValue = formatAmount(useValue.toString()) + ".00";
    }

    // State
    const [value, setValue] = React.useState<string|number>("");

    // React.useEffect(()=>{
    //     setValue(initValue);
    // }, [initValue])

     //defaultValue
     React.useEffect(()=>{
        if(defaultValue){
            setValue(defaultValue);
        } else {
            setValue(initValue);
        }
    }, [defaultValue, initValue])

    React.useEffect(()=>{
        if (clear) {
            setValue('');
        }
    }, [clear])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        onChange && onChange(event);
        let inputValue = event.currentTarget.value;
        // if (variant === 'amount') {
        //     setValue(formatCurrency(inputValue));
        //     onValueChange && onValueChange(formatCurrency(inputValue))
        // }
        if (regex){
            inputValue = regex.test(inputValue) ? inputValue : (value as string || '');
            setValue(inputValue);
            onValueChange && onValueChange(inputValue);
        }
        else{
            setValue(inputValue);
            onValueChange && onValueChange(inputValue);
        }
    }

    

    // Amount style
    let amountStyle = variant === 'amount'? `${styles.textInputAmount} ${props.className}` : props.className

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const toggleShowFirstPassword = () => {
        setShowFirstPassword(!showFirstPassword)
    }
    

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if(e.target.name === 'Password'){
            setPasswordFocus(true)
            setConfirmPasswordFocus(false)
            checkPasswordIfCorrected && checkPasswordIfCorrected()  
        }

        if(e.target.name === 'password'){
            setPasswordFocus(true)
            setConfirmPasswordFocus(false)
            setIsLoginInput(true)
        }

        if(e.target.name === 'ConfirmPassword'){
            setConfirmPasswordFocus(true)
            setPasswordFocus(false)
            checkConfirmPassword && checkConfirmPassword()
        }
    }

    const handleTypeSelection = () => {
        if(showFirstPassword){
            return 'text'
        }
        if(showConfirmPassword){
            return 'text'
        }

        return type;
    }

    const handleBlur = () => {
        setError(false)
    }

    return(
        <motion.div animate={animation}
            className={`${!hideDefaultAdornment ? 'input-item input-item-textarea ltn__custom-icon': ''} ${styles.textInput} ${containerClass || ''}`}>
           { variant === 'amount' && <div className={styles.naira} >₦</div>}
           
            <input id={id} type={handleTypeSelection()} placeholder={placeholder} 
                value={value} onChange={handleChange} disabled={disabled} {...props}
                className={amountStyle} defaultValue={defaultValue} onFocus={handleFocus} onBlur={handleBlur} autoComplete="off"
            />

            {type === 'password' && passwordFocus && !isLoginInput && (
                <span className={regError ? styles.passwordToggle2 : styles.passwordToggle} onClick={toggleShowFirstPassword}>
                    {showFirstPassword ? <BsEyeSlash /> : <BsEye />}
                </span>
            )}

            {type === 'password' && passwordFocus && isLoginInput && (
                <span className={styles.passwordToggleLogin} onClick={toggleShowFirstPassword}>
                    {showFirstPassword ? <BsEyeSlash /> : <BsEye />}
                </span>
            )}

            {type === 'password' && confirmPasswordFocus && (
                <span className={regError ? styles.passwordToggle2 : styles.passwordToggle} onClick={toggleConfirmPassword}>
                    {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                </span>
            )}
            
            {isError && <label htmlFor={id}>{errorMessage}</label>}
        </motion.div>
    )
}

export default TextInput;