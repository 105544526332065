import React, { useContext, useEffect, useState } from "react";
import styles from "./home.module.scss";
import firstLayerImg from "../../../assets/homeInsurance-first-layer.jpg";
import firstLayerImgTop from "../../../assets/insurance-first-layer-top.svg";
import firstLayerImgBottom from "../../../assets/insurance-first-layer-bottom.svg";
import second1 from "../../../assets/second-layer-img-1.jpg";
import second2 from "../../../assets/second-layer-img-2.jpg";
import second3 from "../../../assets/second-layer-img-3.jpg";
import emptyThird from "../../../assets/insurance-empty-box.png";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {
  setCategory,
  setFormType,
  setReapplyHomeowners,
  setRentStep,
  setActivePolicyKeysSlice,
} from "../../../state/reducers/insuranceSlice";
import {
  FormType,
  InsuranceObj,
  PaginationRes,
} from "../../../types/Insurance";
import listimg from "../../../assets/insuranceList.png";
import savedquotelist from "../../../assets/SavedQuote.png";
import { getAllInsurance } from "../../../swr/insurance";
import LoadingContext from "../../../contexts/LoadingContext";
import ToastContext from "../../../contexts/ToastContext";
import {
  formatAmount,
  returnMsgFromAxiosError,
} from "../../../utils/generalUtils";
import CircularProgress from "@mui/material/CircularProgress";

type Option = {
  heading: string;
  text: string;
  image: any;
  button: (param: string, type: FormType) => void;
  type: FormType;
};

const startHere = () => {
  window.scrollBy(0, 800);
};

const explorePackages = () => {
  window.scrollBy(0, -800);
};

const returnTxt = (num: number) => {
  if (num === 0) {
    return "Rent Default Insurance";
  } else if (num === 1) {
    return "Household Insurance";
  } else {
    return "Home Owners Insurance";
  }
};

const HomePageInsurance = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openError } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  // const { setLoading } = useContext(LoadingContext);
  const token = localStorage.getItem("token") as string;
  const [paginationParams, setPaginationParams] = useState<PaginationRes>({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: 0,
    totalPages: 0,
    hasPrevious: false,
    hasNext: false,
  });

  const [allInsurances, setAllInsurances] = useState([] as InsuranceObj[]);
  const [activePolicies, setActivePolicies] = useState([] as InsuranceObj[]);
  const [savedQuotes, setSavedQuotes] = useState([] as InsuranceObj[]);

  const [SQorAP, setSQorAP] = useState<"AP" | "SQ">("AP");

  const SetSQorAP = (sqorap: "AP" | "SQ") => {
    setSQorAP(sqorap);
  };

  const returnClassName = (sqorap: "AP" | "SQ", div: "AP" | "SQ") => {
    switch (sqorap) {
      case "AP":
        return div === "AP"
          ? styles["has-insurance-a-2"]
          : styles["has-insurance-a-1"];
      default:
        return div === "SQ"
          ? styles["has-insurance-a-2"]
          : styles["has-insurance-a-1"];
    }
  };

  const gotToRentInsurance = (str: string, type: FormType) => {
    dispatch(setCategory(str));
    dispatch(setFormType(type));
    history.push("/insurance/rent");
  };

  const gotToHouseHoldInsurance = (str: string, type: FormType) => {
    dispatch(setCategory(str));
    dispatch(setFormType(type));
    history.push("/insurance/household");
  };

  const gotToHomeOwnersInsurance = (str: string, type: FormType) => {
    dispatch(setCategory(str));
    dispatch(setFormType(type));
    history.push("/insurance/homeOwners");
  };

  const viewSavedQuote = (
    type: string,
    premium: number,
    policyNo: string,
    policyId: string
  ) => {
    if (type === "HOUSEOWNER INSURANCE") {
      dispatch(setReapplyHomeowners({ premium, policyNo, policyId }));
      dispatch(setCategory("Home Owners Insurance"));
      dispatch(setFormType("HomeOwners"));
      dispatch(setRentStep(5));
      history.push("/insurance/requestandbuy");
    }
  };

  const viewActivePolicies = (policy: InsuranceObj) => {
    dispatch(
      setActivePolicyKeysSlice({
        PolicyNumber: policy.policyNumber,
        PropertyType: "",
        NameofPolicyHolder: `${policy.firstName} ${policy.lastName}`,
        DateCreated: policy.dateCreated,
        DateofExpiry: undefined,
        PayablePremium: policy.totalPremium,
        TotalSumInsured: policy.totalSumInsured,
      })
    );
    dispatch(setCategory("Home Owners Insurance"));
    dispatch(setFormType("HomeOwners"));
    dispatch(setRentStep(0));
    history.push("/insurance/requestandbuy");
  };

  const getAllInsurancePackages = async () => {
    setLoading(true);
    const { data, error } = await getAllInsurance(
      {
        PageNumber: paginationParams.pageNumber,
        PageSize: paginationParams.pageSize,
      },
      token
    );

    if (data && data.item1 && data.item2) {
      setLoading(false);
      setPaginationParams(data.item2);
      setAllInsurances(data.item1);
      setActivePolicies(
        data.item1.filter((item) => item.isSuccessful === true)
      );
      setSavedQuotes(data.item1.filter((item) => item.isSuccessful === false));
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "Unable to fetch insurance policies")
      );
      return;
    }

    openError("Unable to fetch insurance policies");
    setLoading(false);
    return;
  };

  const returnActiveOrSavedPolicies = (
    activeOrSaved: "AP" | "SQ",
    active: InsuranceObj[],
    saved: InsuranceObj[]
  ) => {
    if (activeOrSaved === "AP") {
      return active && active.length > 0 ? (
        active.map((policy, index) => (
          <div
            className={styles["has-insurance-b-each"]}
            key={index}
            role="button"
            onClick={() => viewActivePolicies(policy)}
          >
            <div className={styles["has-insurance-b-each-l"]}>
              <img src={listimg} alt="list-icon" />

              <div className={styles["has-insurance-b-each-l-texts"]}>
                <span>
                  Policy Number: {policy.policyNumber && policy.policyNumber}
                </span>
                <span>
                  {policy.productPurchased && policy.productPurchased}
                </span>
                <span>
                  Premium: NGN{" "}
                  {policy.totalPremium && formatAmount(policy.totalPremium)}
                </span>
              </div>
            </div>

            <span className={styles["has-insurance-b-each-r"]}>View</span>
          </div>
        ))
      ) : (
        <span className={styles["no-active-policies"]}>No Active Policies</span>
      );
    } else {
      return saved && saved.length > 0 ? (
        saved.map((policy, index) => (
          <div
            className={styles["has-insurance-b-each"]}
            key={index}
            role="button"
            onClick={() =>
              viewSavedQuote(
                "HOUSEOWNER INSURANCE",
                policy.totalPremium,
                policy.policyNumber,
                policy.policyId
              )
            }
          >
            <div className={styles["has-insurance-b-each-l"]}>
              <img src={savedquotelist} alt="list-icon" />

              <div className={styles["has-insurance-b-each-l-texts"]}>
                <span>
                  {policy.productPurchased && policy.productPurchased}
                </span>
                <span>
                  Quote: NGN{" "}
                  {policy.totalPremium && formatAmount(policy.totalPremium)}
                </span>
              </div>
            </div>

            <span className={styles["has-insurance-b-each-r"]}>View</span>
          </div>
        ))
      ) : (
        <span className={styles["no-active-policies"]}>No Saved Quotes</span>
      );
    }
  };

  const insuranceOptions: Option[] = [
    {
      heading: "Rent Default Insurance",
      text: "Our rent default insurance is designed to protect landlords against loss of rental income due to tenant’s inability to pay their rent or meets their rental obligations.",
      image: second1,
      button: gotToRentInsurance,
      type: "RentDefault",
    },
    {
      heading: "Household Insurance",
      text: "We provide cover for risks associated with your residential buildings and their contents. We have the right insurance for your household items.",
      image: second2,
      button: gotToHouseHoldInsurance,
      type: "HouseHold",
    },
    {
      heading: "Home Owners Insurance",
      text: "You can always count on us to cover you against losses or damage caused by fire & other allied perils, including damage to the building as a result of attempted theft.",
      image: second3,
      button: gotToHomeOwnersInsurance,
      type: "HomeOwners",
    },
  ];

  const goNext = () => {
    if (paginationParams.hasNext === true) {
      setPaginationParams((prev) => ({
        ...prev,
        pageNumber: prev.pageNumber + 1,
      }));
    }
  };

  const goPrev = () => {
    if (paginationParams.hasPrevious === true) {
      setPaginationParams((prev) => ({
        ...prev,
        pageNumber: prev.pageNumber - 1,
      }));
    }
  };

  useEffect(() => {
    getAllInsurancePackages();
    if (paginationParams.pageNumber > 1) {
      getAllInsurancePackages();
    }
  }, [paginationParams.pageNumber]);

  return (
    <div className={styles["home-inner"]}>
      <div className={styles["first-layer"]}>
        <div className={styles["first-layer-texts"]}>
          <span className={styles["first-layer-texts-para"]}>
            Protect your home with the right, affordable and reliable insurance
            solutions
          </span>

          <span className={styles["first-layer-texts-span"]}>
            Discover our various insurance plans carefully curated to sustain
            you in crucial times
          </span>

          <div role="button" onClick={() => startHere()}>
            <span>Start Here</span>
          </div>
        </div>

        <div className={styles["first-layer-img"]}>
          <img src={firstLayerImg} className={styles["first-layer-image1"]} />
          <div className={styles["first-layer-image-top"]}>
            <img
              src={firstLayerImgTop}
              className={styles["first-layer-image"]}
            />
          </div>

          <div className={styles["first-layer-image-bottom"]}>
            <img
              src={firstLayerImgBottom}
              className={styles["first-layer-image"]}
            />
          </div>
        </div>
      </div>

      <div className={styles["our-offers"]}>
        <p className={styles["our-offers-header"]}>Our Offers</p>
        <div className={styles["our-offers-options"]}>
          {insuranceOptions.map((option, index) => (
            <div key={option.heading} className={styles["offer-option"]}>
              <div className={styles["offer-option-image-container"]}>
                <img
                  src={option.image}
                  alt="rent insurance"
                  className={styles["first-layer-image"]}
                />
              </div>

              <div className={styles["offer-option-texts"]}>
                <span className={styles["our-offers-text"]}>
                  {option.heading}
                </span>
                <span className={styles["offer-option-texts-p"]}>
                  {option.text}
                </span>
                <div
                  role="button"
                  onClick={() => option.button(returnTxt(index), option.type)}
                >
                  <span>Learn More</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles["has-insurance"]}>
        {allInsurances && allInsurances.length > 0 && (
          <span className={styles["third-layer-header"]}>
            Existing Insurance Policies
          </span>
        )}

        {loading === true ? (
          <CircularProgress />
        ) : allInsurances && allInsurances.length > 0 ? (
          <>
            <div className={styles["has-insurance-a"]}>
              <div
                role="button"
                onClick={() => SetSQorAP("SQ")}
                className={returnClassName(SQorAP, "SQ")}
              >
                <span>Saved Quotes</span>
              </div>

              <div
                role="button"
                onClick={() => SetSQorAP("AP")}
                className={returnClassName(SQorAP, "AP")}
              >
                <span>Active Policies</span>
              </div>
            </div>

            <div className={styles["has-insurance-b"]}>
              {returnActiveOrSavedPolicies(SQorAP, activePolicies, savedQuotes)}
            </div>

            <div className={styles["pagination-div"]}>
              {paginationParams.hasPrevious === true ? (
                <div
                  className={styles["pagination-div-but"]}
                  role="button"
                  onClick={() => goPrev()}
                >
                  <span>Prev</span>
                </div>
              ) : null}

              {paginationParams.hasNext === true ? (
                <div
                  className={styles["pagination-div-but"]}
                  role="button"
                  onClick={() => goNext()}
                >
                  <span>Next</span>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div className={styles["third-layer"]}>
            <span className={styles["third-layer-header"]}>
              Existing Insurance Policies
            </span>

            <img
              src={emptyThird}
              alt="no insurance"
              className={styles["third-layer-img"]}
            />

            <span className={styles["third-layer-p"]}>
              You currently have no existing insurance policy. Click the button
              below to choose your preferred insurance plan.
            </span>
            <div role="button" onClick={() => explorePackages()}>
              Explore Plans
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePageInsurance;
