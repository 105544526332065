import React, { useEffect, useState,useContext } from 'react';
import { useHistory } from 'react-router-dom';
import FormComp from '../../../components/Form';
import LoadingScreen from '../../../components/LoadingScreen';
import useFetch from '../../../hooks/useFetch';
import useForm from '../../../hooks/useForm';
import backendRoutes from '../../../utils/backendRoutes';
import { useAppDispatch } from '../../../hooks/useSelector';
import { useQuery } from '../../../hooks/useQuery';
import ForgotPasswordAccount from '../../../components/Form/ForgotPasswordAccount';
import { setError, setUser, setToken, User } from '../../../state/reducers/authSlice';
import SessionContext from '../../../contexts/SessionContext';
import ToastContext from '../../../contexts/ToastContext';
import LoadingContext from '../../../contexts/LoadingContext';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const [ForgotPasswordSuccess, setForgotPasswordSuccess] = useState<boolean>(false);
  const { formValues, handleChange } = useForm({ email: ''});
  const [ForgotPasswordPostData, ForgotPasswordRes, ForgotPasswordErr, isLoading] = useFetch();
  const { push, goBack, location } = useHistory();
  const query = useQuery();

  // Context
  const { setIsExpired } = useContext(SessionContext);
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);

  const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      ForgotPasswordUser(formValues);
  };

  const ForgotPasswordUser = (payload: { [key: string]: string }) => {
      setForgotPasswordSuccess(false);
      ForgotPasswordPostData({
          url: backendRoutes.forgotPassword,
          data: payload,
          method: 'POST',
      });
  };

  const setLocalStorageToken = (token: string) => {
      localStorage.setItem('token', token);
      dispatch(setToken(token));
  };

  const setForgotPasswordUser = (user: User, token: string) => {
      setForgotPasswordSuccess(true);
      setLocalStorageToken(token);
      setIsExpired(false);
      dispatch(setUser({ ...user }));
  };


  useEffect(() => {
    if (ForgotPasswordRes) {
        if (ForgotPasswordRes && ForgotPasswordRes.succeeded === true && ForgotPasswordRes.message === 'Your reset token has been sent to your email') {
          // setForgotPasswordUser(ForgotPasswordRes.data, ForgotPasswordRes.data.jwToken);
          // query.get('redirect') ? push(`/${query.get('redirect')}`) : push('/reset-password');
          setLoading(false)
          openSuccess('Reset password link has been sent to your email')
          formValues.email = ''
        } else {
          setForgotPasswordSuccess(false);
          const errorMessage =
            ForgotPasswordErr.response?.data.message || 'An unknown error occurred while setting forget password';
          openError(errorMessage);
          dispatch(setError());
        }
      }
  }, [ForgotPasswordRes]);

  return (
    <>
        {/** Added Loading screen */}
        {isLoading && <LoadingScreen/>}
        <FormComp
            title="Forget Password"
            textContent=""
            paddingSize="pb-110"
            pageTitle="Forget Password Page"
            pageName="Forgot Password"
        >
            <ForgotPasswordAccount formValues={formValues} handleChange={handleChange} onSubmit={handleSubmit} link="/signin" loading={isLoading} email={''} />
        </FormComp>
        </>
  )
}

export default ForgotPassword
