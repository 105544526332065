import React from 'react';
import backgroundImage from '../../img/bg/14.jpg';
import styles from './navHeader.module.scss';

export interface NavHeaderProps{
    pageTitle?: string;
    routeName?: string;
    homeRoute?: string;
    bottomMargin?: boolean;
    centerNav?: boolean;
}

const NavHeader: React.FC<NavHeaderProps> = ({
    pageTitle, routeName,
    homeRoute = "/",
    bottomMargin = true,
    centerNav
})=>{

    let marginStyle = bottomMargin ? '' : styles.noBottomMargin;

    return(
        <div className={`ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image ${styles.navHeader} ${marginStyle}`} 
            data-bs-bg="img/bg/14.jpg" 
        >
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className={`ltn__breadcrumb-inner ${centerNav === true && styles.pageTitle}`}>
                        <h1 className="page-title">{pageTitle}</h1>
                        <div className="ltn__breadcrumb-list">
                            <ul>
                                <li><a href={homeRoute}>
                                    <span className="ltn__secondary-color">
                                        <i className="fas fa-home"></i>
                                        </span> Home</a></li>
                                <li>{routeName}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default NavHeader;