import { useState } from 'react';

const useForm = formInput => {
  const [formValues, setFormValues] = useState(formInput);
  const [error, setError] = useState({});

  const resetForm = resetInput => {
    if (resetInput) setFormValues(prevVal => ({ ...prevVal, ...resetInput }));
    else {
      setFormValues(prevVal => {
        const newVals = {};
        Object.keys(prevVal).forEach(key => {
          newVals[key] = '';
        });
        return newVals;
      });
    }
  };

  const checkAllRequired = (except = []) => {
    let allRequired = true;
    Object.keys(formValues).forEach(key => {
      if (!except.includes(key) && !formValues[key]) {
        allRequired = false;
      }
    });
    return allRequired;
  };

  const handleChange = ({ target: { name, value } }) => {
    setFormValues(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // The following method requires a callback, may need refactoring in the near future
  const handleOnChange = callback => async event => {
    const { target: { name, value } } = event;
    // const currentValue = !formValues ? value : formValues[name];
    callback(value, (newValue, isError) => {
      setFormValues(prev => ({
        ...prev,
        [name]: newValue || value,
      }));
      setError(prev => ({
        ...prev,
        [name]: isError,
      }));
    }, event);
  };

  const enableSubmitButton = () => {
    // check if all fields has been filled false if not filled
    // check if fields has an error false if error exist
    const isEmptyFieldExist = Object.keys(formValues).find(key => !formValues[key]);
    const isError = Object.keys(error).find(key => error[key]);
    return (isError || isEmptyFieldExist);
  };

  const noSubmitReload = event => {
    event.preventDefault();
    return false;
  };
  return {
    handleChange, formValues, resetForm, checkAllRequired, handleOnChange, error, enableSubmitButton, noSubmitReload,
  };
};

export default useForm;
