interface InputProps {
    name: string;
    labelName?: string;
    classes?: string;
    type: string;
    placeholder?: string;
    value?: string;
    required?: boolean;
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void);
    disabled?: boolean
}

const Input: React.FC<InputProps> = ({ name, labelName, classes, type, placeholder, value, required, onChange, disabled }) => {
    return (
        <>
            {labelName ? (
                <label htmlFor={name} className={classes}>
                    <input type={type || 'checkbox'} value={value} autoComplete="off" disabled={disabled}/> {labelName}
                </label>
            ) : (
                <input autoComplete="off" name={name} type={type || 'text'} placeholder={placeholder || name} required={required} onChange={onChange} value={value} disabled={disabled}/>
            )}
        </>
    );
};

export default Input;
