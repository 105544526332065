import React from  'react';
import ErrorImage from '../../img/others/error-1.png';

export interface GeneralNotFoundProps{
    title?: string;
    message?: string;
}

const GeneralNotFound: React.FC<GeneralNotFoundProps> = ({
    title='Page Not Found!',
    message='Oops! The page you are looking for does not exist. It might have been moved or deleted.'
})=>{

    return(
        <div className="ltn__404-area ltn__404-area-1 mb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="error-404-inner text-center">
                            <div className="error-img mb-30">
                                <img src={ErrorImage} alt="#" />
                            </div>
                            <h1 className="error-404-title d-none">404</h1>
                            <h2>{title}</h2>

                            <p>{message}</p>
                            <div className="btn-wrapper">
                                <a href="/" className="btn btn-transparent">
                                    <i className="fas fa-long-arrow-alt-left"></i> BACK TO HOME
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralNotFound;