import React from 'react';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import FillingBottle from 'react-cssfx-loading/lib/FillingBottle';
import styles from './loadingscreen.module.scss';

export interface CircularLoaderProps{
    color?: string;
    width?: string | number;
    height?: string | number;
}
export const CircularLoader: React.FC<CircularLoaderProps>  = ({
    color = "#91EA91", width = "70px", height = "70px"
})=>{

    return(
        <div className={styles.localBackrop}>
            <CircularProgress color={color} width={width} height={height}/>
        </div>
    )
}


const LoadingScreen: React.FC<any> = () =>{

    return(
        <div className={styles.backdrop}>
            {/* <FillingBottle color="#FF5A3C" width="70px" height="70px" /> */}
            <FillingBottle color="#91EA91" width="70px" height="70px" />
        </div>
    )
}

export default LoadingScreen;