import React from 'react';
import styles from './bankTransfer.module.scss';
import visaLogo from '../../assets/visa.svg';
import verveLogo from '../../assets/verve.svg';
import mastercardLogo from '../../assets/mastercard.svg';
import Tooltip from '@mui/material/Tooltip';

export interface BankTransferAccordionProps{
    children?: React.ReactNode | React.ReactNode[];
    accountName?: string;
    accountNumber?: string;
    bankCode?: string;
    onDelete?: (id?: number)=> void;
    debitId?: number;
}
const BankTransferAccordion: React.FC<BankTransferAccordionProps> = ({ 
    children, accountName, accountNumber, bankCode, onDelete, debitId
})=>{

    const contentEl = React.useRef<HTMLDivElement>(null);
    const [active, setActive] = React.useState<boolean>(false);

    // Banks (For Debug)
    const banks = {
        '044': 'Access Bank', '058': 'Guaranty Trust Bank', 
        '035': 'WEMA Bank Plc', '011': 'First Bank of Nigeria Plc', 
        '039': 'Stanbic IBTC Plc', '232': 'Sterling Bank Plc', 
    }

    // Handler
    const toggle = ()=>{
        setActive(!active);
    }

    return(
        <div className={styles.accordion}>
            <div className={styles.cardArea}>

                {/** Card and Name */}
               <div className={styles.cardLogo}>
                    <h6>{bankCode && banks[bankCode as keyof typeof banks]}</h6>

                    {/** Number and Name */}
                    <div className={styles.nameNumber}>
                        <p>{accountNumber}</p>
                        <p>{accountName}</p>
                    </div>
               </div>

               {/** Action Buttons */}
               <div className={styles.cardAction}>
                    <Tooltip title="Edit">
                        <i className="far fa-edit" onClick={toggle}></i>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <i className="far fa-trash-alt" onClick={()=> onDelete && onDelete(debitId)}></i>
                    </Tooltip>
               </div>
           </div>

            <div className={`ltn_coupon-code-form ltn__form-box ${styles.contentWrapper}`}
                ref={contentEl}
                style={
                    active
                        ? { height: (contentEl?.current?.scrollHeight || 0), padding: '30px', borderWidth: '1px'}
                        : { height: "0px" }
                } 
            >
                { children }
            </div>
        </div>
    )
}

export default BankTransferAccordion;