import React from "react";
import Layout from "../../components/Layout";
import ScrollToTop from "../../components/ScrollToTop";
import SessionCheck from "../../components/SessionCheck";
import NavHeader from "../../components/NavHeader";
import Mortgage from "../../views/MortgagePropertiesView";

const index = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader pageTitle="Mortgage" routeName="mortgage" />
      <Mortgage />
    </Layout>
  );
};

export default index;
