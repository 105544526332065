import React from "react";
import { MarketData } from "../../views/MarketPlaceView/dataMarket";
import styles from "./marketproduct.module.scss";
import Tooltip from "@mui/material/Tooltip";
import { formatAmount } from "../../utils/propertyUtils";
import { useHistory } from "react-router";

const Index = ({ product }: { product: MarketData }) => {
  const history = useHistory();
  const handleNavProduct = (id: number) => {
    history.push(`/product-details/${id}`);
  };
  return (
    <>
      {product && (
        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
          {/** Image */}
          <div className="product-img">
            {/* <Link to={`/properties/${property.id}`} > */}
            <div
              className={styles.image}
              onClick={() =>  handleNavProduct(product.id)}
            >
              <img src={product.image} alt="#" />
            </div>
            {/* </Link> */}
            <div className={`real-estate-agent`}>
              <div className={`agent-img ${styles.agentImage} `}>
                <a href="team-details.html">
                  {/* <img src={defaultUserImg2} alt="#" /> */}
                </a>
              </div>
            </div>
          </div>

          {/** Body */}
          <div className="product-info">
            {/** Status */}
            <div className="product-badge">
              <ul>
                <li className="sale-badg">From {product.manufacturer}</li>
              </ul>
            </div>

            {/** Title */}
            <h2
              className="product-title"
              onClick={() =>  handleNavProduct(product.id)}
            >
              {/* <Link to={`/properties/${property.id}`}> */}
              {product.title}
              {/* </Link> */}
            </h2>

            {/** Location */}
            {/* <div className="product-img-location">
            <ul>
              <li>
                <a href="locations.html">
                  <i className="flaticon-pin"></i> {property.city},{" "}
                  {property.state}
                </a>
              </li>
            </ul>
          </div> */}

            {/** Amenities */}
            {/* <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief"> */}
            {/* <li>
              <span>{property.bedrooms || 0} </span>
              Bed
            </li>
            <li>
              <span>{property.bathrooms || 0} </span>
              Bath
            </li>
            <li>
              <span>{property.size} </span>
              Square Ft
            </li> */}
            <div className={styles.oneliner}></div>
            <span>{product.category}</span>
            {/* </ul> */}

            {/** Hover Section */}
            <div className="product-hover-action">
              <ul>
                {/* <li className={`${styles.status} ${styles.amber}`}>
                            <a href="#" title="Unverified" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
                                <i className="flaticon-heart-1"></i>
                            </a>
                        </li> */}

                <li className={`${styles.status} ${styles["green"]}`}>
                  <Tooltip title={"Verified"}>
                    <i className={"fa fa-check"} aria-hidden="true"></i>
                  </Tooltip>
                </li>
                <li
                onClick={() =>
                    handleNavProduct(product.id)
                }
                >
                  {/* <Link to={`/properties/${property.id}`} title="Product Details"> */}
                  <i className="flaticon-add"></i>
                  {/* </Link> */}
                </li>
                <li className={styles.buttonItem}>
                  <div
                    title="View Property"
                    role="button"
                    onClick={() =>
                        handleNavProduct(product.id)
                    }
                    className={styles.applyButton}
                  >
                    View Details
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/** Price */}
          <div className="product-info-bottom">
            <div className="product-price">
              <span>₦{formatAmount(product.price.toString())}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
