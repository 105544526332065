import React from "react";
import styles from "./input.module.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type ClickType = (() => void | ((val: string) => void));

const EmptyDropDown = ({ select, click }: { click: ClickType, select: string }) => {
  return (
    <div
      className={styles["empty-drop-down"]}
      role="button"
      onClick={() => click()}
    >
      <ArrowDropDownIcon
        sx={{ position: "absolute", right: "5px", fontSize: 35,  }}
      />
      <span>{select}</span>
    </div>
  );
};

export default EmptyDropDown;
