import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Layout from '../components/Layout';
import NavHeader from '../components/NavHeader';
import ScrollToTop from '../components/ScrollToTop';
import SessionCheck from '../components/SessionCheck';
import TransferSection from '../sections/Transfer';

const TransferPage: React.FC<any> = ()=>{

    let params: { id: string } = useParams();
    let history =  useHistory();

    const dispatch = useDispatch();

    return(
        <React.Fragment>
            <ScrollToTop/>
            <Layout>
                <SessionCheck shouldRedirect/>
                <NavHeader
                    pageTitle="Transfer"
                    routeName="Transfer"
                />
                <TransferSection/>
            </Layout>
        </React.Fragment>
    )
}

export default TransferPage;