import React from 'react';
import { PropertyStatus } from '../../types/Property';
import { PropertyType } from '../../types/propertyType';
import { formatDate, simulateStatus } from '../../utils/propertyUtils';
import styles from './metadata.module.scss';

export interface PropertyMetadataProps{
    category?: string;
    status?: string | PropertyStatus;
    dateAdded?: Date;
    comments?: string | number;
    id?: number;
    verified?: boolean
}

const PropertyMetadata: React.FC<PropertyMetadataProps> = ({
    category = 'Residential', status = 'Rent', comments = 0,
    dateAdded = new Date(), id, verified
})=>{

    // let num = id ? id % 2 : 0;
    let num = verified ? 1 : 0;
    let verificationStatus = simulateStatus(num);
    
    

    return(
        <div className="ltn__blog-meta">
            <ul>
                <li className="ltn__blog-category">
                    <a href="#">{PropertyType[category as keyof typeof PropertyType]}</a>
                </li>
                <li className="ltn__blog-category">
                    <a className="bg-orange" href="#">For {status === 'RentToOwn' ? 'Rent To Own' : status}</a>
                </li>
                <li className="ltn__blog-category">
                    <a className={styles[verificationStatus.status]} href="#">
                        { verificationStatus.tag }
                    </a>
                </li>
                <li className="ltn__blog-date">
                    <i className="far fa-calendar-alt"></i>
                    { formatDate(dateAdded) }
                </li>
                <li>
                    <a href="#"><i className="far fa-comments"></i>{`${comments} Comments`}</a>
                </li>
            </ul>
        </div>
    )
}

export default PropertyMetadata;