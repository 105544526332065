import React from 'react'
import Layout from '../../components/Layout'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'
import NavHeader from '../../components/NavHeader'
import ViewMortgageApplication from './page'

const ViewMortgagePage = () => {
  return (
    <Layout>
    <ScrollToTop />
    <SessionCheck shouldRedirect />
    <NavHeader pageTitle="View Mortgage Applicants" routeName="View Mortgage" />
    <ViewMortgageApplication />
  </Layout>
  )
}

export default ViewMortgagePage