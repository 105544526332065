import React, { SetStateAction, useContext, useEffect, useState } from "react";
import styles from "./mortgageView.module.scss";
import { ApplyMortgage, Property } from "../../types/Property";
import Input from "../../components/Input";
import AppLogo from "../../assets/HomeBuddyLogo_Monochrome I.png";
import HomeWorkIcon from "@mui/icons-material/HomeWorkOutlined";
import Checkbox from "@mui/material/Checkbox";
import { Button, Tooltip } from "@mui/material";
import ToastContext from "../../contexts/ToastContext";
import { ApplyMortgageApi } from "../../swr/properties";
import LoadingContext from "../../contexts/LoadingContext";
import Select, { ActionMeta, SingleValue, StylesConfig } from "react-select";
import Modal from "react-modal";
import { MortgageModal } from "./mortgageModal";
import { useHistory } from "react-router";
import FormControlLabel from "@mui/material/FormControlLabel";

const Mortgage = ({
  propertyId,
  price,
  setMortgageSuccess,
  property,
}: {
  propertyId: number | undefined;
  price: number | undefined;
  setMortgageSuccess: React.Dispatch<SetStateAction<boolean>>;
  property: Property | undefined;
}) => {
  let mortgageRequest = localStorage.getItem("mortgageReq");
  let mortgageReq = mortgageRequest ? JSON.parse(mortgageRequest) : undefined;
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const [finalWAccess, setFinalWAccess] = useState(false);
  const [request, setRequest] = useState<ApplyMortgage>({
    amountPerMonth: undefined,
    downPayment: "",
    willUseRSA: finalWAccess,
    RSANo: undefined,
    threeYearsEmployed: false,
    age: "",
  });

  const token = localStorage.getItem("token") as string;

  const [deductable, setDeductable] = useState(0);

  const [haveAccPensions, setHaveAccPensions] = useState(false);
  const [nothaveAccPensions, setNotHaveAccPensions] = useState(false);
  const [monthlyEst, setMonthlyEst] = useState(0);
  const [noOfMonths, setNoOfMonths] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const [monthlyIncome, setMonthlyIncome] = useState("600000");
  const [annualNetIncome, setAnnualNetIncome] = useState("");
  const [annualFocus, setAnnualFocus] = useState(false);
  const [useAccess, setUseAccess] = useState(false);

  const [interestRate, setInterestRate] = useState("");

  const [isEarner, setIsEarner] = useState(true);

  const [hasChosen, setHasChosen] = useState("");

  const [confirmedStaff, setConfirmedStaff] = React.useState(false);

  const handleFinalWillAccess = (p: boolean) => {
    setFinalWAccess(p);
    closeModal();
  };

  const [tempForm, setTempForm] = useState({
    salaryStructure: "",
    repaymentPeriod: "",
    monthlyIncome: "",
    annualNetIncome: "",
    confirmedStaff: false,
    threeYearsEmployed: false,
    usesAccess: false,
  });

  const [formDatas, setFormDatas] = useState({
    repaymentPeriod: "",
    salaryStructure: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupI, setShowPopupI] = useState(false);
  const [showPopupII, setShowPopupII] = useState(false);

  const [totalP, setTotalP] = useState(0);

  function formatAmount(
    number: number,
    decimals: number = 2,
    locale: string = "en-NG"
  ): string {
    // Check if the number is a valid finite number
    if (!isFinite(number)) {
      throw new Error("Invalid number");
    }

    // Use toFixed to set the number of decimals
    const formattedNumber = number.toFixed(decimals);

    // Use toLocaleString to add commas for thousands separator
    const formattedAmount = parseFloat(formattedNumber).toLocaleString(locale);

    return formattedAmount;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const inputValue = value.replace(/[^0-9.]/g, "");
    setRequest((prev) => ({
      ...prev,
      [name]: inputValue,
    }));
  };

  const inputs = [
    // {
    //   name: "amountPerMonth",
    //   display: "How much are you willing to pay per month for a mortgage (₦) ?",
    // },
    {
      name: "downPayment",
      display: "How much down-payment are you willing to make upfront (₦) ?",
    },
  ];

  const returnPlaceholders = (name: string) => {
    switch (name) {
      case "amountPerMonth":
        return "Amount Per Month";
      case "downPayment":
        return "Down Payment";
      default:
        return "Please Fill";
    }
  };

  const renderInputs = (inputArr: { name: string; display: string }[]) => {
    return inputArr.map((item) => (
      <div className={styles.inputII} key={item.name}>
        <div className={styles.inputTitle}>{item.display}</div>
        <Input
          type="text"
          name={item.name}
          required={true}
          onChange={handleChange}
          value={
            (request[item.name as keyof ApplyMortgage] as string)
              ? formatAmount(
                  parseFloat(
                    request[item.name as keyof ApplyMortgage] as string
                  ),
                  2,
                  "en-NG"
                )
              : ""
          }
          placeholder={returnPlaceholders(item.name)}
        />
      </div>
    ));
  };

  type RepaymentDropDown = {
    label: string;
    value: string;
  };

  const repaymentArr = [
    {
      label: "",
      value: "",
    },
    {
      label: "3 years",
      value: "3",
    },
    {
      label: "5 years",
      value: "5",
    },
    {
      label: "7 years",
      value: "7",
    },
    {
      label: "10 years",
      value: "10",
    },
    {
      label: "15 years",
      value: "15",
    },
    {
      label: "20 years",
      value: "20",
    },
  ];

  const salaryArr = [
    {
      label: "",
      value: "",
    },
    {
      label: "Salary Earner",
      value: "Salary Earner",
    },
    {
      label: "Self Employed",
      value: "Self Employed",
    },
  ];

  const isAccessArr = [
    {
      label: "",
      value: "",
    },
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  const inBusiness3yrs = [
    {
      label: "",
      value: "",
    },
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  const confirmedStaffArr = [
    {
      label: "",
      value: "",
    },
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  const SetNoOfYears = (val: string) => {
    switch (val) {
      case "3":
        return 36;
      case "5":
        return 60;
      case "7":
        return 84;
      case "10":
        return 120;
      case "15":
        return 180;
      case "20":
        return 240;
      default:
        return 0;
    }
  };

  const handleRepaymentSelection = (
    newValue: SingleValue<RepaymentDropDown | null>,
    actionMeta: ActionMeta<RepaymentDropDown | null>
  ) => {
    if (newValue) {
      if (newValue.label === "Repayment Period") {
        return;
      }

      setNoOfMonths(SetNoOfYears(newValue.value));

      setFormDatas((prev) => ({
        ...prev,
        repaymentPeriod: newValue.value,
      }));
      setTempForm((prev) => ({
        ...prev,
        repaymentPeriod: newValue.value,
      }));
    }
  };

  const handleSalarySelection = (
    newValue: SingleValue<RepaymentDropDown | null>,
    actionMeta: ActionMeta<RepaymentDropDown | null>
  ) => {
    if (newValue) {
      if (newValue.label === "Select Salary") {
        setInterestRate("");
        return;
      }
      if (newValue.label === "Salary Earner") {
        setIsEarner(true);
        setInterestRate("23%");
        setTotalP(price ? price + 0.23 * price : 0);
      }
      if (newValue.label === "Self Employed") {
        setIsEarner(false);
        setInterestRate("25%");
        setTotalP(price ? price + 0.25 * price : 0);
      }
      setFormDatas((prev) => ({
        ...prev,
        salaryStructure: newValue.value,
      }));
      setTempForm((prev) => ({
        ...prev,
        salaryStructure: newValue.value,
      }));
    }
  };

  const handleAccessSelection = (
    newValue: SingleValue<RepaymentDropDown | null>,
    actionMeta: ActionMeta<RepaymentDropDown | null>
  ) => {
    if (newValue) {
      setHasChosen(newValue.label);
      if (newValue.value === "Yes") {
        setDeductable(0);
        // setRequest((prev) => ({ ...prev, downPayment: "" }));
        setUseAccess(true);
        setTempForm((prev) => ({
          ...prev,
          usesAccess: true,
        }));
        openModal();
      } else {
        setDeductable(0);
        // setRequest((prev) => ({ ...prev, downPayment: "" }));
        setUseAccess(false);
        setTempForm((prev) => ({
          ...prev,
          usesAccess: false,
        }));
        openModal();
      }
    }
  };

  const handleInBusiness3years = (
    newValue: SingleValue<RepaymentDropDown | null>,
    actionMeta: ActionMeta<RepaymentDropDown | null>
  ) => {
    if (newValue) {
      if (newValue.value === "Yes") {
        setRequest((prev) => ({ ...prev, threeYearsEmployed: true }));
        setTempForm((prev) => ({ ...prev, threeYearsEmployed: true }));
      } else {
        setRequest((prev) => ({ ...prev, threeYearsEmployed: false }));
        setTempForm((prev) => ({ ...prev, threeYearsEmployed: false }));
      }
    }
  };

  const handleConfirmedStaff = (
    newValue: SingleValue<RepaymentDropDown | null>,
    actionMeta: ActionMeta<RepaymentDropDown | null>
  ) => {
    if (newValue) {
      if (newValue.value === "Yes") {
        setConfirmedStaff(true);
        setTempForm((prev) => ({ ...prev, confirmedStaff: true }));
      } else {
        setConfirmedStaff(false);
        setTempForm((prev) => ({ ...prev, confirmedStaff: false }));
      }
    }
  };

  const [mortgageSuccess, setMortgageSuccessI] = useState(false);

  const [Response, setResponse] = useState<any>({
    downPayment: 0,
    estimatedMonthlyPayment: 0,
    repaymentPeriod: 0,
  });

  const setMortgageSuccessF = () => {
    setMortgageSuccessI(false);
  };

  const applyMortgage = async () => {
    if (
      !monthlyIncome ||
      !formDatas.repaymentPeriod ||
      !formDatas.salaryStructure
    ) {
      openError("All fields are required");
      return;
    }

    if (!finalWAccess && !request.downPayment) {
      openError("Enter Down Payment");
      return;
    }

    if (request.RSANo && !finalWAccess) {
      openError(
        "You've indicated your RSA balance; Please indicate your willingness"
      );
      return;
    }

    if (Number(monthlyIncome) < 600000) {
      openError(
        "Only income earners of ₦600,000 and above are eligible for the HomeBuddy mortgage"
      );
      return;
    }

    setLoading(true);
    const response = await ApplyMortgageApi(
      {
        propertyId: propertyId!,
        // amountPerMonth: Number(request.amountPerMonth),
        monthlyIncome: Number(monthlyIncome),
        downPaymentFromPension: finalWAccess,
        downPayment: Number(request.downPayment),
        hasAccessPension: useAccess,
        rsaPin: "TENTATIVE",
        annualNetIncome: Number(annualNetIncome),
        pensionBalance: request.RSANo ? Number(request.RSANo) : 0,
        salaryStructure: isEarner ? 1 : 2,
        repaymentPeriod: Number(formDatas.repaymentPeriod),
        confirmedStaff,
        threeYearsEmployed: request.threeYearsEmployed,
        age: request.age,
      },
      token
    );

    if (!response) {
      setLoading(false);
      openError("An unknown error occurred");
      return;
    }

    if (response.status === 500) {
      setLoading(false);
      openError(
        (response.data && response.data.Message) || "An error occurred"
      );
      return;
    }

    if (response.status === 200) {
      setResponse(response.data);
      setLoading(false);
      setRequest((prev) => ({
        ...prev,
        amountPerMonth: "",
        downPayment: "",
        RSANo: "",
        willUseRSA: false,
      }));
      setFormDatas((prev) => ({
        ...prev,
        repaymentPeriod: "",
        salaryStructure: "",
      }));
      setMonthlyIncome("0");
      setUseAccess(false);
      setHasChosen("");
      setHaveAccPensions(false);
      setNotHaveAccPensions(false);
      setMortgageSuccessI(true);
      return;
    }

    openError((response.data && response.data.message) || "an error occurred");
    setLoading(false);
  };

  const setPropertyCb = () => {
    localStorage.setItem("property", JSON.stringify(property));
    localStorage.setItem("mortgageReq", JSON.stringify(tempForm));
  };

  //   annualNetIncome
  // :
  // ""
  // confirmedStaff
  // :
  // false
  // monthlyIncome
  // :
  // ""
  // repaymentPeriod
  // :
  // "15"
  // salaryStructure
  // :
  // "Self Employed"
  // threeYearsEmployed
  // :
  // true
  // usesAccess
  // :
  // false

  useEffect(() => {
    if (mortgageReq !== undefined) {
      setMonthlyIncome(
        mortgageReq.monthlyIncome === "" ? "600000" : mortgageReq.monthlyIncome
      );
      setAnnualNetIncome(mortgageReq.annualNetIncome);

      if (typeof mortgageReq.usesAccess === "boolean") {
        setUseAccess(mortgageReq.usesAccess);
      }

      if (typeof mortgageReq.confirmedStaff === "boolean") {
        setConfirmedStaff(mortgageReq.confirmedStaff);
      }

      if (typeof mortgageReq.threeYearsEmployed === "boolean") {
        setRequest((prev) => ({
          ...prev,
          threeYearsEmployed: mortgageReq.threeYearsEmployed,
        }));
      }

      if (
        typeof mortgageReq.salaryStructure === "string" &&
        mortgageReq.salaryStructure.length > 1
      ) {
        setFormDatas((prev) => ({
          ...prev,
          salaryStructure: mortgageReq.salaryStructure,
        }));
        if (mortgageReq.salaryStructure === "Self Employed") {
          setIsEarner(false);
          setInterestRate("25%");
        } else {
          setIsEarner(true);
          setInterestRate("23%");
        }
      }

      if (
        typeof mortgageReq.repaymentPeriod === "string" &&
        mortgageReq.repaymentPeriod.length > 0
      ) {
        setFormDatas((prev) => ({
          ...prev,
          repaymentPeriod: mortgageReq.repaymentPeriod,
        }));
      }
    }

    localStorage.removeItem("mortgageReq");
  }, []);

  useEffect(() => {
    if (Number(request.downPayment) > 0 && noOfMonths > 0) {
      setMonthlyEst(
        totalP > 0
          ? Number(
              ((totalP - Number(request.downPayment)) / noOfMonths).toFixed(2)
            )
          : 0
      );
    }

    if (Number(request.downPayment) === 0) {
      setMonthlyEst(0);
    }

    if (deductable > 0 && price && noOfMonths > 0) {
      let estimate = (totalP - deductable) / noOfMonths;
      setMonthlyEst(estimate);
    }

    // if (Number(request.RSANo) > 0 && totalP && finalWAccess) {
    //   let bal = Number(request.RSANo);
    //   let tfive = 0.25 * bal;
    //   let twPercent = 0.2 * totalP;
    //   twPercent > tfive ? setDeductable(tfive) : setDeductable(twPercent);
    //   setRequest((prev) => ({ ...prev, downPayment: deductable.toString() }));
    //   let percent = (deductable / bal) * 100;
    //   setPercentage(percent);
    // }

    if (Number(request.RSANo) === 0) {
      setPercentage(0);
      setDeductable(0);
    }

    return () => {
      setMortgageSuccess(false);
    };
  }, [
    request.willUseRSA,
    finalWAccess,
    request.downPayment,
    request.RSANo,
    deductable,
    noOfMonths,
  ]);

  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "40px", // Adjust the height as needed
      height: "auto",
    }),
    menu: (provided, state) => ({
      ...provided,
      maxHeight: "200px", // Adjust the max height of the menu as needed
    }),
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.cal}>
          <span className={styles.calTxt}>Monthly Cost Calculator</span>
        </div>
        <div className={styles.form}>
          <div className={styles.inputII}>
            <div className={styles.inputTitle}>Property Price (₦)</div>
            <input
              type="text"
              name={"Price"}
              value={price?.toString()}
              style={{ height: "5em" }}
              disabled
            />

            {Number(monthlyIncome) < 600000 && (
              <span className={styles.errMsg}>
                Ooops! Only income earners of ₦600,000 and above are eligible
                for the HomeBuddy mortgage
              </span>
            )}
          </div>
          <div className={styles.inputI}>
            <div className={styles.inputTitle}>Salary Structure</div>
            <div style={{ width: "100%" }}>
              <Select
                options={salaryArr && salaryArr.length > 0 ? salaryArr : []}
                name="salary"
                onChange={handleSalarySelection}
                value={salaryArr.find(
                  (item) => item.value === formDatas.salaryStructure
                )}
              />
            </div>
          </div>
          <div className={styles.inputI}>
            <div className={styles.inputTitle}>
              Do you use Access Pensions For Retirement Savings ?
              <span
                className={`${styles.status} ${styles.green}`}
                onClick={() => {
                  setShowPopupII(!showPopupII);
                }}
              >
                <Tooltip title={"info"}>
                  <i className={"fa fa-exclamation"} aria-hidden="true"></i>
                </Tooltip>
              </span>
            </div>

            {showPopupII && (
              <div className={styles.popI}>
                <div className={styles.cancelIcon}>
                  <div
                    className="icon-cancel"
                    onClick={() => setShowPopupII(false)}
                    style={{ marginTop: "0.2em" }}
                  ></div>
                </div>
                <span
                  style={{
                    fontSize: 11,
                    color: "black",
                    marginLeft: 5,
                    fontWeight: "bold",
                    textAlign: "justify",
                  }}
                >
                  Enjoy a discounted interest rate as an Access Pension customer
                </span>
              </div>
            )}
            {/* <div className={styles.inputTitle}>
              
            </div> */}
            <div style={{ width: "100%" }}>
              <Select
                value={
                  isAccessArr.find(
                    (item) => item.value === (useAccess === true ? "Yes" : "No")
                  ) || null
                }
                options={
                  isAccessArr && isAccessArr.length > 0 ? isAccessArr : []
                }
                name="isAccess"
                onChange={handleAccessSelection}
              />
            </div>
          </div>
          <div className={styles.inputII}>
            <div className={styles.inputTitle}>Interest Rate</div>
            <input
              type="text"
              name={"Price"}
              value={useAccess && finalWAccess ? "19%" : interestRate}
              placeholder="Your interest rate will be displayed here"
              style={{ height: "5em" }}
              disabled
            />
          </div>
          <div className={styles.inputII}>
            <div className={styles.inputTitle}>Age</div>
            <input
              type="text"
              name={"age"}
              value={request.age}
              placeholder="Enter your age"
              onChange={handleChange}
              style={{ height: "5em" }}
            />
          </div>
          <div className={styles.inputI}>
            <div className={styles.inputTitle}>Repayment Period</div>
            <div style={{ width: "100%" }}>
              <Select
                options={
                  repaymentArr && repaymentArr.length > 0
                    ? repaymentArr.filter(
                        (each) => 60 - Number(request.age) >= Number(each.value)
                      )
                    : []
                }
                name="repayment"
                onChange={handleRepaymentSelection}
                value={repaymentArr.find(
                  (item) => item.value === formDatas.repaymentPeriod
                )}
                isDisabled={request.age === ""}
              />
            </div>
          </div>
          <div className={styles.inputIII}>
            <div className={styles.inputTitle}>
              {isEarner ? "Monthly Net Income (₦)" : "Monthly Turn-Over (₦)"}
              <span
                className={`${styles.status} ${styles.green}`}
                onClick={() => {
                  console.log("I clicked");

                  setShowPopupI(true);
                }}
              >
                <Tooltip title={"info"}>
                  <i className={"fa fa-exclamation"} aria-hidden="true"></i>
                </Tooltip>
              </span>
            </div>

            {showPopupI && (
              <div className={styles.popI}>
                <div className={styles.cancelIcon}>
                  <div
                    className="icon-cancel"
                    onClick={() => setShowPopupI(false)}
                    style={{ marginTop: "0.2em" }}
                  ></div>
                </div>
                <span
                  style={{
                    fontSize: 11,
                    color: "black",
                    marginLeft: 5,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Only income earners of ₦600,000 and above are eligible for the
                  HomeBuddy mortgage
                </span>
              </div>
            )}

            <input
              type="text"
              name="monthlyIncome"
              placeholder="Type your monthly net income"
              required={true}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except for the decimal point
                setMonthlyIncome(inputValue);
                setTempForm((prev) => ({ ...prev, monthlyIncome: inputValue }));
              }}
              value={
                monthlyIncome
                  ? formatAmount(parseFloat(monthlyIncome), 2, "en-NG")
                  : ""
              }
              // defaultValue={
              //   tempForm.monthlyIncome
              //     ? formatAmount(parseFloat(tempForm.monthlyIncome), 2, "en-NG")
              //     : ""
              // }
              style={{
                height: "5em",
                borderColor:
                  Number(monthlyIncome) < 600000
                    ? "rgb(212, 125, 125)"
                    : "rgba(0, 0, 0, 0.1)",
              }}
            />

            {Number(monthlyIncome) < 600000 && !mortgageSuccess && (
              <span className={styles.errMsg}>
                Ooops! Only income earners of ₦600,000 and above are eligible
                for the HomeBuddy mortgage
              </span>
            )}
          </div>

          {isEarner && (
            <div className={styles.inputIII}>
              <div className={styles.inputTitle}>Annual Salary (₦)</div>

              <input
                type="text"
                name="annualNetIncome"
                placeholder="Type your annual income"
                required={true}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except for the decimal point
                  setAnnualNetIncome(inputValue);
                  setTempForm((prev) => ({
                    ...prev,
                    annualNetIncome: inputValue,
                  }));
                }}
                value={
                  annualNetIncome
                    ? formatAmount(parseFloat(annualNetIncome), 2, "en-NG")
                    : ""
                }
                // defaultValue={
                //   tempForm.annualNetIncome
                //     ? formatAmount(
                //         parseFloat(tempForm.annualNetIncome),
                //         2,
                //         "en-NG"
                //       )
                //     : ""
                // }
                onFocus={() => setAnnualFocus(true)}
                onBlur={() => setAnnualFocus(false)}
                style={{
                  height: "5em",
                  borderColor:
                    Number(annualNetIncome) < 600000 * 12 && annualFocus
                      ? "rgb(212, 125, 125)"
                      : "rgba(0, 0, 0, 0.1)",
                }}
                autoComplete="off"
              />
            </div>
          )}

          {isEarner && (
            <div className={styles.inputI}>
              <div className={styles.inputTitle}>
                Are you a confirmed employee of your organization ?
              </div>
              <div style={{ width: "100%" }}>
                <Select
                  value={
                    confirmedStaffArr.find(
                      (item) =>
                        item.value === (confirmedStaff === true ? "Yes" : "No")
                    ) || null
                  }
                  options={
                    confirmedStaffArr && confirmedStaffArr.length > 0
                      ? confirmedStaffArr
                      : []
                  }
                  name="confirmedStaffArr"
                  onChange={handleConfirmedStaff}
                />
              </div>
              {/* <FormControlLabel
                label="Are you a confirmed staff ?"
                control={
                  <Checkbox
                    checked={confirmedStaff}
                    onChange={handleConfirmedStaff}
                  />
                  
                }
                sx={{alignSelf: 'flex-start'}}
              /> */}
            </div>
          )}

          {!isEarner && (
            <div className={styles.inputI}>
              <div className={styles.inputTitle}>
                Have you been in business for up to 3 years ?
              </div>
              <div style={{ width: "100%" }}>
                <Select
                  value={
                    inBusiness3yrs.find(
                      (item) =>
                        item.value ===
                        (request.threeYearsEmployed === true ? "Yes" : "No")
                    ) || null
                  }
                  options={
                    inBusiness3yrs && inBusiness3yrs.length > 0
                      ? inBusiness3yrs
                      : []
                  }
                  name="inBusiness3yrs"
                  onChange={handleInBusiness3years}
                />
              </div>
              {/* <FormControlLabel
                label="Have you been in business for up to 3 years ?"
                control={
                  <Checkbox
                    checked={request.threeYearsEmployed}
                    onChange={handleInBusiness3years}
                  />
                }
                sx={{alignSelf: 'flex-start'}}
              /> */}
            </div>
          )}
          {/* {!finalWAccess && hasChosen !== "" && renderInputs(inputs)} */}
          {useAccess && finalWAccess && hasChosen !== "" ? (
            <div className={styles.inputIII}>
              <div className={styles.inputTitle}>
                What is your Retirement Savings Balance (₦) ?
                <span
                  className={`${styles.status} ${styles.green}`}
                  onClick={() => setShowPopup(!showPopup)}
                >
                  <Tooltip title={"label"}>
                    <i className={"fa fa-exclamation"} aria-hidden="true"></i>
                  </Tooltip>
                </span>
              </div>

              {showPopup === true && (
                <div className={styles.pop}>
                  <div className={styles.cancelIcon}>
                    <div
                      className="icon-cancel"
                      onClick={() => setShowPopup(false)}
                    ></div>
                  </div>
                  <span>
                    You can get your RSA pins and account balance via any of the
                    following digital channels:
                  </span>
                  <ul>
                    <li>Access Buddy Mobile App</li>
                    <li>
                      Virtual Pension Assistant: Nafi - 0805 303 9999 (Whatsapp)
                    </li>
                    <li>USSD Shortcode - *7737*2#</li>
                  </ul>
                </div>
              )}

              <input
                type="text"
                name={"RSANo"}
                placeholder="Retirement Savings Account Balance"
                required={true}
                onChange={handleChange}
                value={
                  request.RSANo
                    ? formatAmount(parseFloat(request.RSANo), 2, "en-NG")
                    : ""
                }
                style={{ height: "5em" }}
              />
            </div>
          ) : (
            renderInputs(inputs)
          )}

          <div className={styles.noticeButton}>
            <Button
              type="button"
              variant="contained"
              sx={{
                textAlign: "center",
                backgroundColor: "var(--ltn__secondary-color)",
                "&:hover": {
                  backgroundColor: "var(--ltn__paragraph-color)",
                },
                height: "3em",
                display: "flex",
                alignItems: "center",
                marginTop: "0.5em",
                justifyContent: "center",
                marginBottom: "0.5em",
                alignSelf: "flex-start",
              }}
              onClick={() => applyMortgage()}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "54%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            backgroundColor: "white",
            height: "50%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Customer Modal"
      >
        <MortgageModal
          willAccess={useAccess}
          close={closeModal}
          handleFinalWillAccess={handleFinalWillAccess}
          id={propertyId}
          setPropertyCb={setPropertyCb}
        />
      </Modal>
      <Modal
        isOpen={mortgageSuccess}
        onRequestClose={setMortgageSuccessF}
        style={{
          content: {
            top: "54%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "0.7em",
            zIndex: "9999",
            height: "35%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Customer Modal"
      >
        <div className={styles.mortgageResDiv}>
          <div className={styles.mortgageResDivPair}>
            <span className={styles.pairL}>Down Payment:</span>
            <span className={styles.pairR}>
              {" "}
              ₦{formatAmount(Response && Response.downPayment)}
            </span>
          </div>
          <div className={styles.mortgageResDivPair}>
            <span className={styles.pairL}>Monthly Payment:</span>
            <span className={styles.pairR}>
              {" "}
              ₦{formatAmount(Response && Response.estimatedMonthlyPayment)}
            </span>
          </div>
          <div className={styles.mortgageResDivPair}>
            <span className={styles.pairL}>Repayment Period:</span>
            <span className={styles.pairR}>
              {" "}
              {Response && Response.repaymentPeriod * 12} Months
            </span>
          </div>

          <Button
            type="button"
            variant="contained"
            sx={{
              textAlign: "center",
              backgroundColor: "var(--ltn__secondary-color)",
              "&:hover": {
                backgroundColor: "var(--ltn__paragraph-color)",
              },
              height: "3em",
              width: "15em",
              display: "flex",
              alignItems: "center",
              marginTop: "3em",
              justifyContent: "center",
              marginBottom: "0.5em",
              alignSelf: "center",
            }}
            onClick={() => {
              setMortgageSuccessF();
              setMortgageSuccess(true);
            }}
          >
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Mortgage;
