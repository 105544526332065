import React from "react";
import { PropertyStatus, PropertyType } from "../../../types/Property";
import { formatAmount, formatDate } from "../../../utils/propertyUtils";
import styles from "./detail.module.scss";
import { MarketData } from "../../../views/MarketPlaceView/dataMarket";

const MarketPdtDetail: React.FC<MarketData> = ({
  id = "HZ29",
  title,
  weight,
  durability,
  warranty,
  date,
  manufacturer,
  category,
  price,
}) => {
  return (
    <div className={`property-detail-info-list section-bg-1 clearfix mb-60`}>
      <ul>
        <li>
          <label>Product:</label> <span>{title}</span>
        </li>
        <li>
          <label>Weight: </label> <span>{weight} </span>
        </li>
        <li>
          <label>Manufacturer:</label> <span>{manufacturer}</span>
        </li>
        <li>
          <label>Date Added:</label> <span>{formatDate(date)}</span>
        </li>
      </ul>
      <ul>
        <li>
          <label>Category:</label> <span>{category}</span>
        </li>
        <li>
          <label>Price:</label> <span>₦ {formatAmount(price.toString())}</span>
        </li>
        <li>
          <label>Durability:</label> <span>{durability}</span>
        </li>
        <li>
          <label>Warranty:</label> <span>For {warranty}</span>
        </li>
      </ul>
    </div>
  );
};

export default MarketPdtDetail;
