import React from 'react';
import styles from './bankDisplay.module.scss';

export interface BankTransferDisplayProps{
    accountName?: string;
    accountNumber?: string;
    bankCode?: string;
    debitId?: number;
    onSelect?: (id?: number) => void;
}

const BankTransferDisplay: React.FC<BankTransferDisplayProps> = ({
    accountName, accountNumber, bankCode, debitId, onSelect
})=>{

    // Banks (For Debug)
    const banks = {
        '044': 'Access Bank', '058': 'Guaranty Trust Bank', 
        '035': 'WEMA Bank Plc', '011': 'First Bank of Nigeria Plc', 
        '039': 'Stanbic IBTC Plc', '232': 'Sterling Bank Plc', 
    }

    const handleClick = ()=>{
        onSelect && onSelect(debitId);
    }

    return(
        <div className={styles.cardArea} onClick={handleClick}>

            {/** Name */}
            <div className={styles.cardLogo}>
                <h6>{bankCode && banks[bankCode as keyof typeof banks]}</h6>

                {/** Number and Name */}
                <div className={styles.nameNumber}>
                    <p>{accountNumber}</p>
                    <p>{accountName}</p>
                </div>
            </div>

        </div>
    )
}

export default BankTransferDisplay;