import BreadCrumb from '../BreadCrumb';
import { FormHeader } from '../FormHeader';
import NavHeader from '../NavHeader';

interface IFormProps {
    paddingSize: string;
    title: string;
    textContent: string;
    children: React.ReactNode;
    pageName?: string;
    pageTitle?: string;
}
const FormComp: React.FC<IFormProps> = ({ paddingSize, title, textContent, children, pageName, pageTitle }) => {
    return (
        <>
            {/** Changed BreadCrumb to NavHeader */}
            {/*<BreadCrumb pageName={pageName} pageTitle={pageTitle} />*/}
            <NavHeader pageTitle={pageTitle} routeName={pageTitle}  />
            <div className={`ltn__login-area ${paddingSize}`}>
                <div className="container">
                    {/* <FormHeader title={title} leadingText={textContent} /> */}
                    {children}
                </div>
            </div>
        </>
    );
};

export default FormComp;
