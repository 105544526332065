import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React, { useContext, useEffect, useState } from "react";
import { LandlordDetails, RentDropDown } from "../../../../types/Insurance";
import styles from "./rent-form.module.scss";
import EmptyDropDown from "../../../../components/Input/EmptyDropDown";
import {
  genderArray,
  maritalStatusArray,
  religionArray,
  sectorArray,
} from "../../../../utils/generalUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import PopUp from "../../../../components/Insurance/PopUp";
import ModalLayout from "../../../../components/ModalLayout";
import {
  setGetAQuoteReq,
  setLandlordDetails,
  setRentDropValue,
} from "../../../../state/reducers/insuranceSlice";
import EmptyDropCalendar from "../../../../components/Input/EmptyDropCalendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UploadDocs from "../../../../components/Insurance/UploadDocs";
import ToastContext from "../../../../contexts/ToastContext";

type DocsUpload = {
  buttonText: string;
  texts: string[];
  accept: string;
  name: keyof (LandlordDetails);
};

const requiredFields = [
  "Gender",
  "DateOfBirth",
  "City",
  "State",
  "Occupation",
  "PassportPhoto",
  "IdCard",
  "TitleDocument",
  "TenantDocument",
  "BuildingFrontPhoto",
  "BuildingBackPhoto",
  "BuildingRightPhoto",
  "BuildingLeftPhoto",
]

const LandlordDetailsComp = ({
  setRentStep,
  closeAllDropDowns,
  rentDropDownValue,
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
  closeAllDropDowns: () => void;
  rentDropDownValue: RentDropDown;
}) => {
  const dispatch = useDispatch();
  const { landLordDetails } = useSelector(
    (state: RootState) => state.insurance
  );
  const { openError } = useContext(ToastContext);

  const [landlordDetailsReq, setLandlordDetailsReq] = useState<LandlordDetails>(
    {
      Gender: 10,
      DateOfBirth: undefined,
      MaritalStatus: 10,
      Religion: 10,
      HomeAddress: "",
      City: "",
      State: "",
      Occupation: "",
      Sector: 10,
      CoverStartDate: undefined,
      Bank: "",
      AccountNumber: "",
      BVN: "",
      PassportPhoto: undefined,
      IdCard: undefined,
      TitleDocument: undefined,
      TenantDocument: undefined,
      BuildingFrontPhoto: undefined,
      BuildingBackPhoto: undefined,
      BuildingRightPhoto: undefined,
      BuildingLeftPhoto: undefined,
    }
  );

  const filterCompulsory = (data: LandlordDetails): LandlordDetails => {
    const filteredData: LandlordDetails = { ...data };
  
    for (const key in filteredData) {
      if (!requiredFields.includes(key)) {
        delete filteredData[key as keyof LandlordDetails];
      }
    }
  
    return filteredData;
  };

  const checkIfAnyUndefinedOrTenOrEmptyString = (
    obj: LandlordDetails
  ): boolean => {
    obj = filterCompulsory(obj)
    // Check if any key is undefined
    const hasUndefined = Object.values(obj).some(
      (value) => value === undefined
    );

    // Check if any key of type number is 10
    const hasNumberTen = Object.values(obj).some(
      (value) => typeof value === "number" && value === 10
    );

    // Check if any key is an empty string
    const hasEmptyString = Object.values(obj).some(
      (value) => typeof value === "string" && value === ""
    );

    // Return true if any of the conditions are true, otherwise return false
    return hasUndefined || hasNumberTen || hasEmptyString;
  };

  const openDropDown = (val: RentDropDown) => {
    dispatch(setRentDropValue(val));
  };

  const handleTypedOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLandlordDetailsReq((prev: any) => ({
      ...prev,
      [name]: value.toUpperCase(),
    }));
  };

  const handleGenderSelection = (value: string) => {
    setLandlordDetailsReq((prev) => ({
      ...prev,
      Gender: genderArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleMaritalSelection = (value: string) => {
    setLandlordDetailsReq((prev) => ({
      ...prev,
      MaritalStatus: maritalStatusArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleReligionSelection = (value: string) => {
    setLandlordDetailsReq((prev) => ({
      ...prev,
      Religion: religionArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleSectorSelection = (value: string) => {
    setLandlordDetailsReq((prev) => ({
      ...prev,
      Sector: sectorArray.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const handleDateChange = (val: any) => {
    setLandlordDetailsReq((prev: any) => ({
      ...prev,
      DateOfBirth: val,
    }));
  };

  const handleDateChangeI = (val: any) => {
    setLandlordDetailsReq((prev: any) => ({
      ...prev,
      CoverStartDate: val,
    }));
  };

  const returnFormattedDate = (p: Date) => {
    return new Date(p).toLocaleString("NG").split(",")[0];
  };

  useEffect(() => {
    if (Object.keys(landLordDetails).length !== 0) {
      setLandlordDetailsReq({ ...landLordDetails });
    }
  }, []);



  const documents: DocsUpload[] = [
    {
      buttonText: "Upload Your Passport Photo",
      texts: [
        "Only jpg and png are allowed",
        "Large files might take longer to be processed",
        "Upload at most one photo, minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.jpeg,.png",
      name: "PassportPhoto",
    },
    {
      buttonText: "Upload Your ID",
      texts: [
        "Only jpg, png and pdf are allowed",
        "You may only upload NIN, Voter's card, or International Passport as valid ID",
        "Large files might take longer to be processed",
        "If uploading a photo, it must be a minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.png,.pdf,.jpeg",
      name: "IdCard",
    },
    {
      buttonText: "Upload Title Document",
      texts: [
        "Only pdf is allowed",
        "Large files might take longer to be processed",
        "Upload as a single file, if you have multiple documents",
      ],
      accept: ".pdf",
      name: "TitleDocument",
    },
    {
      buttonText: "Upload Tenancy Agreement",
      texts: [
        "Only pdf is allowed",
        "Large files might take longer to be processed",
        "Upload as a single file, if you have multiple documents",
      ],
      accept: ".pdf",
      name: "TenantDocument",
    },
    {
      buttonText: "Upload Building Front Photo",
      texts: [
        "Only jpg and png are allowed",
        "Large files might take longer to be processed",
        "Upload at most one photo, minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.png,.jpeg",
      name: "BuildingFrontPhoto",
    },
    {
      buttonText: "Upload Building Back Photo",
      texts: [
        "Only jpg and png are allowed",
        "Large files might take longer to be processed",
        "Upload at most one photo, minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.jpeg,.png",
      name: "BuildingBackPhoto",
    },
    {
      buttonText: "Upload Building Right Side Photo",
      texts: [
        "Only jpg and png are allowed",
        "Large files might take longer to be processed",
        "Upload at most one photo, minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.jpeg,.png",
      name: "BuildingRightPhoto",
    },
    {
      buttonText: "Upload Building Left Side Photo",
      texts: [
        "Only jpg and png are allowed",
        "Large files might take longer to be processed",
        "Upload at most one photo, minimum dimension of 500px by 500px",
      ],
      accept: ".jpg,.jpeg,.png",
      name: "BuildingLeftPhoto",
    },
  ];

  const handleDocsChange = (name: keyof LandlordDetails, value: File) => {
    setLandlordDetailsReq((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const removeDoc = (name: keyof LandlordDetails) => {
    setLandlordDetailsReq((prev) => ({
      ...prev,
      [name]: undefined,
    }));
  };

  const saveAndContinue = () => {
    const isValidationErr =
      checkIfAnyUndefinedOrTenOrEmptyString(landlordDetailsReq);
    if (isValidationErr === true) {
      openError("Please fill all required fields");
      return;
    }
    dispatch(setLandlordDetails(landlordDetailsReq));
    dispatch(setRentStep(4));
  };

  const goBack = () => {
    dispatch(setRentStep(2));
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.header}>
          <div className={styles.headerblock}></div>
          <div className={styles.headertext}>Details</div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.input2column}>
            <span>
              Gender<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  genderArray[
                    landlordDetailsReq.Gender ?? landLordDetails.Gender
                  ]
                }
                click={() => openDropDown("Gender")}
              />
            </div>
          </div>
          <div className={styles.input2column}>
            <span>
              Date Of Birth<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop-date"]}>
              <span>
                {landlordDetailsReq.DateOfBirth
                  ? returnFormattedDate(landlordDetailsReq.DateOfBirth)
                  : landLordDetails.DateOfBirth
                  ? returnFormattedDate(landLordDetails.DateOfBirth)
                  : ""}
              </span>
              <EmptyDropCalendar
                select={landlordDetailsReq.DateOfBirth}
                click={handleDateChange}
              />

              <ArrowDropDownIcon
                sx={{
                  position: "absolute",
                  right: "5%",
                  fontSize: 35,
                  zIndex: 50,
                }}
              />
            </div>
          </div>
          {/* <div className={styles.inputcolumn}>
            <span>
              Marital Status<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  maritalStatusArray[
                    landlordDetailsReq.MaritalStatus ??
                      landLordDetails.MaritalStatus
                  ]
                }
                click={() => openDropDown("MaritalStatus")}
              />
            </div>
          </div> */}
        </div>

        {/* <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Religion<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  religionArray[
                    landlordDetailsReq.Religion ?? landLordDetails.Religion
                  ]
                }
                click={() => openDropDown("Religion")}
              />
            </div>
          </div>
        </div> */}

        {/* <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Home Address<span className={styles.red}>*</span>
            </span>
            <input
              name="HomeAddress"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={
                landlordDetailsReq.HomeAddress || landLordDetails.HomeAddress
              }
              defaultValue={landLordDetails.HomeAddress}
            />
          </div>
        </div> */}

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              City<span className={styles.red}>*</span>
            </span>
            <input
              name="City"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={landlordDetailsReq.City || landLordDetails.City}
              defaultValue={landLordDetails.City}
            />
          </div>

          <div className={styles["inputcolumn-65"]}>
            <span>
              State<span className={styles.red}>*</span>
            </span>
            <input
              name="State"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={landlordDetailsReq.State || landLordDetails.State}
              defaultValue={landLordDetails.State}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Occupation<span className={styles.red}>*</span>
            </span>
            <input
              name="Occupation"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={
                landlordDetailsReq.Occupation || landLordDetails.Occupation
              }
              defaultValue={landLordDetails.Occupation}
            />
          </div>

          {/* <div className={styles.inputcolumn}>
            <span>
              Sector<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  sectorArray[
                    landlordDetailsReq.Sector ?? landLordDetails.Sector
                  ]
                }
                click={() => openDropDown("Sector")}
              />
            </div>
          </div> */}
        </div>
{/* 
        <div className={styles.columngrid}>
          <div className={styles["input-column-full"]}>
            <span>
              Cover Start Date<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop-date"]}>
              <span>
                {landlordDetailsReq.CoverStartDate
                  ? returnFormattedDate(landlordDetailsReq.CoverStartDate)
                  : landLordDetails.CoverStartDate
                  ? returnFormattedDate(landLordDetails.CoverStartDate)
                  : ""}
              </span>
              <EmptyDropCalendar
                select={landlordDetailsReq.CoverStartDate}
                click={handleDateChangeI}
              />

              <ArrowDropDownIcon
                sx={{
                  position: "absolute",
                  right: "10px",
                  fontSize: 35,
                  zIndex: 50,
                }}
              />
            </div>
          </div>
        </div> */}

        {/* <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              Bank<span className={styles.red}>*</span>
            </span>
            <input
              name="Bank"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={landlordDetailsReq.Bank || landLordDetails.Bank}
              defaultValue={landLordDetails.Bank}
            />
          </div>
          <div className={styles.inputcolumn}>
            <span>
              Account Number<span className={styles.red}>*</span>
            </span>
            <input
              name="AccountNumber"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={
                landlordDetailsReq.AccountNumber ||
                landLordDetails.AccountNumber
              }
              defaultValue={landLordDetails.AccountNumber}
            />
          </div>
          <div className={styles.inputcolumn}>
            <span>Bank Verification Number</span>
            <input
              name="BVN"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={landlordDetailsReq.BVN || landLordDetails.BVN}
              defaultValue={landLordDetails.BVN}
            />
          </div>
        </div> */}

        <span className={styles["doc-header"]}>
          Media (Landlord)<span className={styles.red}>*</span>
        </span>
        {documents.map((doc) => (
          <UploadDocs
            key={doc.buttonText}
            name={doc.name}
            buttonTxt={doc.buttonText}
            instructions={doc.texts}
            accept={doc.accept}
            handleDocsChange={handleDocsChange}
            removeDoc={removeDoc}
            landLordDetails={landLordDetails}
            tenantDetails={undefined}
          />
        ))}

        <div className={styles["container-bottom-butt"]}>
          <span onClick={() => goBack()} role="button">
            Back
          </span>

          <div
            className={styles["continue-butt"]}
            role="button"
            onClick={() => saveAndContinue()}
          >
            <span>Save & Continue</span>
          </div>
        </div>
      </div>

      <ModalLayout modalOpen={rentDropDownValue === "Gender"}>
        <PopUp options={genderArray} click={handleGenderSelection} />
      </ModalLayout>
      {/* <ModalLayout modalOpen={rentDropDownValue === "MaritalStatus"}>
        <PopUp options={maritalStatusArray} click={handleMaritalSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Religion"}>
        <PopUp options={religionArray} click={handleReligionSelection} />
      </ModalLayout>
      <ModalLayout modalOpen={rentDropDownValue === "Sector"}>
        <PopUp options={sectorArray} click={handleSectorSelection} />
      </ModalLayout> */}
    </>
  );
};

export default LandlordDetailsComp;
