import React, { useContext, useEffect, useState } from "react";
import { getPersonalizedListingsApi } from "../../swr/properties";
import { Property } from "../../types/Property";
import { PaginationParamsPersonalizedProperties } from "../../types/propertyType";
import ToastContext from "../../contexts/ToastContext";
import styles from "./myListings.module.scss";
import { useHistory } from "react-router";
import Stack from "@mui/system/Stack";
import Pagination from "@mui/material/Pagination";
import LoadingContext from "../../contexts/LoadingContext";
import { Tooltip } from "@mui/material";

const Index = () => {
  const history = useHistory();
  const { openError } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const [paginationParams, setPaginationParams] = useState({
    PageNumber: 1,
    PageSize: 5,
  });

  const [properties, setProperties] = useState<Property[] | []>([]);
  const [pageParams, setPageParams] = useState(
    {} as PaginationParamsPersonalizedProperties
  );

  const token = localStorage.getItem("token") as string;
  const getPersonalizedListingsCall = async () => {
    setLoading(true);
    const response = await getPersonalizedListingsApi(
      {
        PageNumber: paginationParams.PageNumber,
        PageSize: paginationParams.PageSize,
      },
      token
    );

    if (response && response.item1 && response.item2) {
      setProperties(response.item1);
      setPageParams(response.item2);
      setLoading(false);
    } else {
      openError(
        response.Message || response.message || "error fetching your properties"
      );
      setLoading(false);
    }

    setLoading(false);
  };

  const metaHeadings = [
    "Title",
    "Address",
    "Price",
    "Description",
    "isEnabled",
    "isFeatured",
  ];

  const returnVerification = (check: boolean) => {
    return (
      <div className={styles.statusContainer}>
        <span className={`${styles.status} ${styles[check ? 'green' : 'amber']}`}>
          <Tooltip title={"label"}>
            <i
              className={check ? "fa fa-check" : "fa fa-exclamation"}
              aria-hidden="true"
            ></i>
          </Tooltip>
        </span>

        <span>{check ? "Verified" : "Unverified"}</span>
      </div>
    );
  };

  const returnFeatured = (check: boolean) => {
    return (
      <div className={styles.statusContainerI}>
        <span className={`${styles.status} ${styles[check ? 'green' : 'amber']}`}>
          <Tooltip title={"label"}>
            <i
              className={check ? "fa fa-check" : "fa fa-exclamation"}
              aria-hidden="true"
            ></i>
          </Tooltip>
        </span>

        <span>{check ? "Featured" : "Unfeatured"}</span>
      </div>
    );
  };

  const returnEachSub = (heading: string, property: Property) => {
    switch (heading) {
      case "Price":
        return `₦${
          property[heading.toLowerCase() as keyof Property] as string
        }`;
      case "isEnabled":
        return returnVerification(property.isEnabled!);
      case "isFeatured":
        return returnFeatured(property.isFeatured!);
      default:
        return property[heading.toLowerCase() as keyof Property] as string;
    }
  };

  const returnMetaData = (property: Property) => {
    return metaHeadings.map((heading) => (
      <div className={styles.eachData} key={heading}>
        <span className={heading !== "Title" ? styles.data : styles.fontsTopic}>
          {property.hasOwnProperty(heading.toLowerCase()) ||
          heading === "isEnabled" || heading === "isFeatured"
            ? returnEachSub(heading, property)
            : ""}
        </span>
      </div>
    ));
  };

  const handleClick = (id: number | undefined) => {
    history.push(`/properties/${id}`);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPaginationParams((prev) => ({ ...prev, PageNumber: newPage }));
  };

  useEffect(() => {
    getPersonalizedListingsCall();
    if (paginationParams.PageNumber > 0) {
      getPersonalizedListingsCall();
    }
  }, [paginationParams.PageNumber]);

  return (
    <div className={styles.superContainer}>
      {properties && properties.length > 0 ? (
        <div className={styles.container}>
          {properties.map((property) => (
            <div
              className={styles.eachProperty}
              title="See Property"
              role="button"
              onClick={() => handleClick(property.id)}
              key={property.id}
            >
              <div className={styles.imgContainer}>
                {property.attachments &&
                  property.attachments[0] &&
                  property.attachments[0].imageUrl && (
                    <img
                      src={property.attachments[0].imageUrl}
                      alt="property image"
                      className={styles.image}
                    />
                  )}
              </div>

              <div className={styles.metaData}>{returnMetaData(property)}</div>
            </div>
          ))}
          <div className={styles.paginationDiv}>
            {!(
              paginationParams.PageNumber == 1 &&
              properties &&
              properties.length < paginationParams.PageSize
            ) && (
              <Stack
                spacing={2}
                sx={{ marginBottom: "30px", alignItems: "center" }}
              >
                <Pagination
                  count={pageParams && pageParams.totalPages}
                  page={paginationParams.PageNumber}
                  onChange={handlePageChange}
                  size={"large"}
                  variant="outlined"
                  color="standard"
                />
              </Stack>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.noListing}>
          <span className={styles.fonts}>No Listed Properties</span>
        </div>
      )}
    </div>
  );
};

export default Index;
