import React from 'react';


export interface LocationProps{
    address?: string;
    
}

const Location: React.FC<LocationProps> = ({ address })=>{
    return(
        <div className="property-details-google-map mb-60">
           <iframe 
                width="100%" 
                height="100%"
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade" 
                frameBorder="0" allowFullScreen={true} aria-hidden="false" 
                tabIndex={0}
               
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY }&q=${address!}`}
            >
            </iframe>
            
        </div>
    )
}

export default Location;