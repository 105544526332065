import { IconfirmEmailQuery } from '../pages/authentication/confirm-email'
//const base = process.env.BASE_URL || 'https://24f13d76-27ce-4207-a325-ce724047f307.mock.pstmn.io';
const base = process.env.REACT_APP_BASE_URL || 'https://api.homebuddy.ng'
const baseUrl = `${base}/api/`

interface IformatConfirmEmail extends IconfirmEmailQuery {
  baseApiUrl: string
}

const formatConfirmEmail = ({
  baseApiUrl,
  userId,
  code,
}: IformatConfirmEmail) => `${baseApiUrl}?userId=${userId}&code=${code}`

const backendRoutes = {
  register: `${baseUrl}Account/register`,
  confirmEmail: (query: IconfirmEmailQuery) =>
    formatConfirmEmail({
      baseApiUrl: `${baseUrl}Account/confirm-email`,
      ...query,
    }),
  newConfirmEmail: (querystring: string) =>
    `${baseUrl}Account/confirm-email/${querystring}`,
  authenticate: `${baseUrl}Account/authenticate`,
  resetPassword: `${baseUrl}Account/reset-password?token`,
  forgotPassword: `${baseUrl}Account/forgot-password`,
}

export default backendRoutes
