import React, { useContext } from "react";
import styles from "./household-form.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import PersonalDetails from "./PersonalDetails";
import {
  setRentDropValue,
  setRentStep,
} from "../../../../state/reducers/insuranceSlice";
import GetAQuote from "./GetAQuote";
import TermsAndConditions from "./TermsAndConditions";
import GetQuoteSuccess from "./GetAQuoteSuccess";
import KYCDetailsComp from "./KYCForm";
import { StateContext } from "../../../../contexts/StateContext";

const HouseHoldForm = () => {
  const { rentStep, rentDropDownValue } = useSelector(
    (state: RootState) => state.insurance
  );
  const dispatch = useDispatch();
  const { walletDetails } = useContext(StateContext);
  const closeAllDropDowns = () => {
    dispatch(setRentDropValue(""));
  };

  const returnHeading = (step: number) => {
    switch (step) {
      case 1:
        return "Personal Details";
      case 2:
        return "Get A Quote";
      case 5:
        return "Terms and Conditions";
      default:
        return "Know Your Customer (KYC)";
    }
  };

  const returnRentStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <PersonalDetails
            rentDropDownValue={rentDropDownValue}
            closeAllDropDowns={closeAllDropDowns}
            setRentStep={setRentStep}
          />
        );
      case 2:
        return (
          <GetAQuote
            rentDropDownValue={rentDropDownValue}
            closeAllDropDowns={closeAllDropDowns}
            setRentStep={setRentStep}
          />
        );
      case 3:
        return (
          <KYCDetailsComp
            rentDropDownValue={rentDropDownValue}
            closeAllDropDowns={closeAllDropDowns}
            setRentStep={setRentStep}
          />
        );
      case 4:
        return <TermsAndConditions setRentStep={setRentStep} />;
        default:
            return (
              <GetQuoteSuccess
                rentDropDownValue={rentDropDownValue}
                closeAllDropDowns={closeAllDropDowns}
                walletBalance={
                  walletDetails?.available_balance
                    ? walletDetails.available_balance.toString()
                    : undefined
                }
              />
            );
    }
  };

  return (
    <div className={styles["form-container-in"]}>
      <span className={styles["heading-rent-I"]}>
        {returnHeading(rentStep)}
      </span>
      {rentStep < 5 && (
        <span className={styles["heading-rent-II"]}>
          Compulsory fields are asterisked (*)
        </span>
      )}

      {returnRentStep(rentStep)}
    </div>
  );
};

export default HouseHoldForm;
