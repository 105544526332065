import React, { useState } from "react";
import styles from "./filterProducts.module.scss";
import Checkbox from "@mui/material/Checkbox";
// Building Equipment
// Home Appliances
// Raw Materials

export const returnFilterName = (filter: string) => {
  switch (filter) {
    case "isBE":
      return "Building Equipment";
    case "isHA":
      return "Home Appliances";
    default:
      return "Raw Materials";
  }
};

const FilterProducts = ({
  handleFilterChange,
}: {
  handleFilterChange: (param: string, isIncluded: boolean) => void;
}) => {
  const [checked, setChecked] = useState({
    isBE: false,
    isHA: false,
    isRM: false,
  });

  const params = Object.keys(checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    console.log(name, checked);
    
    handleFilterChange(returnFilterName(name), checked);
    setChecked((prev) => ({ ...prev, [name]: checked}));
    params.forEach(key => {
        if (key !== name) {
          setChecked((prev) => ({ ...prev, [key]: false }));
        }
      });
  };

  const returnFilter = () => {
    return (
      params &&
      params.map((p) => (
        <div className={styles.eachFilter} key={p}>
          <Checkbox
            checked={checked[p as keyof typeof checked]}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
            name={p}
            style={{
                marginRight: '1em'
            }}
          />
          <span>{returnFilterName(p)}</span>
        </div>
      ))
    );
  };

  return <div className={styles.filter}>{returnFilter()}</div>;
};

export default FilterProducts;
