import React from 'react';
import useStyles from './footer-jss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import siteLogo from '../../assets/HomeBuddy_RequestedColour_PrimaryLockup.svg';
import { Link } from 'react-router-dom';

export interface FooterProps{
    logo?: { uri?: string; label?: string; alt?: string };
    description?: string;
    linksSections?: {
        title: string;
        links: { text: string; uri: string }[];
    }[],
    termsLink?: string;
    claimLink?: string;
    privacyPoliciyLink?: string;
}

const Footer: React.FC<FooterProps> = ({
    logo, description, linksSections, termsLink,
    claimLink, privacyPoliciyLink
}) =>{

    // Stylesheet
    const styles = useStyles();

    /** Main Footer Area */
    let mainClasses = `row ${styles.main}`; 
    const Main = (
        <div className={mainClasses} >

            {/** Access Homes description */}
            <div className={`col-lg-3 col-sm-6 d-flex flex-column`}>
                <div className={`d-flex align-items-center pb-1 ${styles.logo}`}>
                    <div className={styles.logoImage}><img src={siteLogo} alt="HomeBuddy" /></div>
                    {/* <div className={styles.logoLabel} ><h5>{logo?.label}</h5></div> */}
                </div>
                <div className={styles.description}>
                    <p>{description}</p>
                </div>
            </div>

            {/** Links */}
            {
                linksSections?.map((section, index)=>(
                    <div key={section.title + index.toString()} 
                        className={`col-lg-2 col-md-3 col-sm-6 d-flex`}
                    >
                        <div className={styles.linkSection}>
                            <h4>{section.title}</h4>
                            <ul className={styles.linkList}>
                                { section.links.map((link)=>(
                                    <li key={link.text}>
                                        <Link to={link.uri}>{link.text}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))
            }

            {/** Newslettter */}
            {/* <div className={`col-lg-3 col-sm-6 d-flex flex-column`}>
                <div className={styles.newsletterHeading}><h4>Newsletter</h4></div>
                <div className={styles.newsletterSubheading}>
                    <p>
                        Subscribe to our weekly Newsletter and receive updates via email
                    </p>
                </div>
                <div className={styles.newsletterInput}>
                   // Form
                    <form action="#" className={`d-flex`}>
                        <input type="email" name="subscriber-email" id="subscriber-email"
                         placeholder="Email*"
                         />
                        <div className={styles.buttonWrapper}>
                            <button>
                                <FontAwesomeIcon icon={faLocationArrow} />
                            </button>
                        </div>
                    </form>
                </div>
            </div> */}
        </div>
    )

    /** Footer Bottom - CopyRight Area */
    const Bottom = (
        <div className={styles.bottomArea}>
            <div className={`container-fluid ${styles.bottomContainer}`}>
                <div className={`row align-items-center ${styles.bottomRow}`}>

                    {/** Rights Reserved column */}
                    <div className={`col-md-6 col-12`}>
                        <div className={styles.copyRight}>
                            <p>All Rights Reserved @ HomeBuddy <span>{new Date().getFullYear()}</span></p>
                        </div>
                    </div>

                    {/** Links column */}
                    <div className={`col-md-6 col-12 align-self-center`}>
                        <div className={`text-end ${styles.copyRightMenu}`}>
                            <ul>
                                {
                                    [
                                        { text: 'Terms & Condition', link: termsLink },
                                        // { text: 'Claim', link: claimLink },
                                        { text: 'Privacy & Policy', link: privacyPoliciyLink },
                                    ].map((item, index)=>(
                                        <li key={`fl${index}`}>
                                            <Link to={item.link || '#'}>
                                                {item.text}
                                            </Link>
                                        </li>
                                        
                                    ))
                                }
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

    return(
        <>
        {/** Footer Component */}
        <footer className={styles.footer}>
            <div className="container-fluid">
                {Main}
            </div>
        </footer>
        {Bottom}
        </>
    )
}

export default Footer;