import BreadCrumb from '../../../components/BreadCrumb';
import useFetch from '../../../hooks/useFetch';
import { useAppDispatch, useAppSelector } from '../../../hooks/useSelector';
import { RootState } from '../../../state/store';
// import ConfirmImage from '../../../img/bg/18.png';
import ConfirmImage from '../../../assets/pointing.png';
import backendRoutes from '../../../utils/backendRoutes';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {Link, useLocation} from 'react-router-dom';
import { loadingStart, setError } from '../../../state/reducers/authSlice';
import Button from '../../../components/Button';
import NavHeader from '../../../components/NavHeader';
import LoadingScreen from '../../../components/LoadingScreen';
import styles from './email.module.scss';
import Axios from 'axios';
import { confirmEmail } from '../../../swr/user';

export interface IconfirmEmailQuery {
    userId: string;
    code: string;
}

const ConfirmEmail = () => {
    // const dispatch = useAppDispatch();
    // const { message } = useAppSelector((state: RootState) => state.auth.tempUser);
    // const [confirmEmailPostData, confirmEmailRes, confirmEmailErr, isLoading] = useFetch();
    const [confirmEmailSuccess, setConfirmEmailSuccess] = useState<boolean>(false);

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const useRId = queryParams.get('userId') as string;
    const userCode = queryParams.get('code') as string;

    /** Had to rewrite this, because query params were not being properly fetched */ 
    // const confirmEmailData = message?.split('URL')[1]?.trim();

    // Custom logic here
    // let startIndex = confirmEmailData?.indexOf('?') || 0;
    // const emailData = confirmEmailData?.slice(startIndex);

    // const [, userId, code] = confirmEmailData?.split('=') || ['', ''];
    const { push, replace } = useHistory();

    const handleClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        // console.log('Confirming Email...');

        // ConfirmUserEmail({ userId, code });
    };

    // const ConfirmUserEmail = async () => {
    //     try {
    //         const {data} = await Axios.get(`/api/Account/confirm-email?userId=${useRId}&code=${userCode}`)
    //         console.log(data)
    //         if(data && data.succeeded){
    //             setConfirmEmailSuccess(true)
    //             console.log(useRId);
    //             localStorage.setItem('userId', useRId as string)
    //         }
    //     } catch (error) {
            
    //     }
        
    //     // /api/Account/confirm-email userId code
    //     /** Had to rewrite this, because query params were not being properly fetched */
    //     //confirmEmailPostData({ url: backendRoutes.confirmEmail(query) });
    //     // confirmEmailPostData({ url: backendRoutes.newConfirmEmail(emailData) });
    // };

    useEffect(()=>{
        const cEmail = async()=>{
            const data = await confirmEmail(useRId, userCode)
            if(data && data.succeeded){
                setConfirmEmailSuccess(true)
                localStorage.setItem('userId', useRId as string)
            }
        }

        cEmail()
        
    }, [])

    

    // useEffect(()=>{
    //     if (!message) {
    //         replace('/signup')
    //     }
    // },[])

    // useEffect(() => {
    //     dispatch(loadingStart());
    //     if (confirmEmailRes) {
    //         if (!confirmEmailErr && confirmEmailRes?.succeeded) {
    //             setConfirmEmailSuccess(true);
    //             console.log(confirmEmailRes.message || 'Operation successful. Account Confirmed. You can now proceed to login your account');
    //             push('/signin');
    //         } else {
    //             setConfirmEmailSuccess(false);
    //             console.log(confirmEmailRes.error || 'Operation failed. Something went wrong');
    //             dispatch(setError());
    //         }
    //     }
    // }, [confirmEmailRes]);

    return (
        <>
        {
            !confirmEmailSuccess ? <LoadingScreen/> :
            (<>
             {/* <BreadCrumb pageName="Confirm Email" pageTitle="Confirm Email Page" /> */}
             <NavHeader pageTitle="Confirm Email" routeName="Confirm Email Page"  /> 
                <div className="ltn__404-area ltn__404-area-1 mb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="error-404-inner text-center">
                                    <div className={`error-img mb-30 ${styles.image}`}>
                                        <img src={ConfirmImage} alt="#" />
                                        <p>You have successfully verified your account.</p>
                                        <Link to='/signin'>
                                        <p style={{color: 'green'}}>Proceed to login</p>
                                        </Link>
                                        
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </>)
        }
            {/** Added Loading screen */}
            {/* {isLoading && <LoadingScreen/>}
            {/** Changed BreadCrumb to NavHeader */}
            
        </>
    );
};

export default ConfirmEmail;
