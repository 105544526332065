import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { formatAmount } from '../../../utils/propertyUtils';
import { CustomerLookupReponseData } from '../../../types/Billings';
import Button from '@mui/material/Button';
import styles from './bill.module.scss';

export interface BillPaymentMeta extends CustomerLookupReponseData{
    amount?: string | number;
}

export interface BillPaymentConfirmDialogProps{
    open?: boolean;
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => any;
    onConfirm?: (e?: React.MouseEvent<HTMLButtonElement>)=> void;
    meta?: BillPaymentMeta;
}

const BillPaymentConfirmDialog: React.FC<BillPaymentConfirmDialogProps> = ({
    open = false, onClose, meta, onConfirm
})=>{

    // Elements
    const customerInfo = (
        <div className={styles.customerInfo}>
            <p>Customer Details</p>
            <div className={styles.customerDetail}>
                <p>Name:</p>
                <p>{meta?.customer.customerName}</p>
            </div>
            <div className={styles.customerDetail}>
                <p>Phone Number:</p>
                <p>{meta?.customer.phoneNumber}</p>
            </div>
            <div className={styles.customerDetail}>
                <p>Email:</p>
                <p>{meta?.customer.emailAddress}</p>
            </div>
            <div className={styles.customerDetail}>
                <p>Address:</p>
                <p>{meta?.customer.address}</p>
            </div>
        </div>
    )

    const billInfo = (
        <div className={`${styles.customerInfo} ${styles.billInfo}`}>
            <p>Billing Details</p>
            <div className={styles.customerDetail}>
                <p>Biller:</p>
                <p>{meta?.billerName}</p>
            </div>
            <div className={styles.customerDetail}>
                <p>Meter Number:</p>
                <p>{meta?.customer.meterNumber}</p>
            </div>
            <div className={styles.customerDetail}>
                <p>Amount:</p>
                <p>₦ {formatAmount(meta?.amount?.toString() || '0')}</p>
            </div>
        </div>
    )

    return(
        <Dialog
            maxWidth= 'lg'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '60%',
                    height: '100%'
                }
            }}
            aria-labelledby="delete-dialog-dialog-title"
            aria-describedby="delete-dialog-description"
        >
           <DialogTitle id="delete-dialog-dialog-title">
                Bill Summary
            </DialogTitle>
            <DialogContent>
                <div className={styles.amount}>
                    <p>Amount</p>
                    <h4>₦ {formatAmount(meta?.amount?.toString() || '0')}</h4>
                </div>
                { customerInfo }
                { billInfo }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onConfirm}>Confirm</Button>
            </DialogActions>
        
        </Dialog>
    )
}

export default BillPaymentConfirmDialog;