export enum Amenity{
    EquippedKitchen = "EquippedKitchen",
    Gym = "Gym",
    Laundry = "Laundry",
    MediaRoom = "MediaRoom",
    Backyard = "Backyard",
    Pool = "Pool",
    Electricity = "24hrElectricity",
    WaterSupply = "WaterSupply",
    AirConditioning = "AirConditioning",
    WashingMachine = "WashingMachine",
    WIFI = "WIFI",
    ParkingSpace="ParkingSpace"
}