import React from 'react'
import Layout from '../../components/Layout'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'
import NavHeader from '../../components/NavHeader'
import OpenAccessPensionForm from './form'

const OpenAccessPensions = () => {
  return (
    <Layout>
    <ScrollToTop />
    <SessionCheck shouldRedirect />
    <NavHeader pageTitle="Open Access Pension Account" routeName="Open Acess Pensions" />
    <OpenAccessPensionForm />
  </Layout>
  )
}

export default OpenAccessPensions