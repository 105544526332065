import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React, { useContext, useEffect, useState } from "react";
import styles from "./rent-form.module.scss";
import { ApplyInsuranceI, RentDropDown } from "../../../../types/Insurance";
import ModalLayout from "../../../../components/ModalLayout";
import EmptyDropDown from "../../../../components/Input/EmptyDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  setPersonalDetails,
  setRentDropValue,
} from "../../../../state/reducers/insuranceSlice";
import PopUp from "../../../../components/Insurance/PopUp";
import ToastContext from "../../../../contexts/ToastContext";
import { RootState } from "../../../../state/store";
import { UserInfo } from "../../../../types/User";

const titleArr = ["Mr", "Mrs", "Miss", "Master"];

const PersonalDetails = ({
  setRentStep,
  closeAllDropDowns,
  rentDropDownValue,
  user
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
  closeAllDropDowns: () => void;
  rentDropDownValue: RentDropDown;
  user: UserInfo | undefined
}) => {
  const dispatch = useDispatch();
  const { personalDetails } = useSelector(
    (state: RootState) => state.insurance
  );
  const { openError } = useContext(ToastContext);
  const [PersonalDetails, setPersonalDetailsI] = useState<ApplyInsuranceI>({
    Title: 5,
    FirstName: "",
    LastName: "",
    MiddleName: "",
    Email: "",
    PhoneNumber: "",
  });

  const handleTypedOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPersonalDetailsI((prev: any) => ({
      ...prev,
      [name]: value.toUpperCase(),
    }));
  };

  const handleTitleSelection = (value: string) => {
    setPersonalDetailsI((prev) => ({
      ...prev,
      Title: titleArr.indexOf(value),
    }));
    closeAllDropDowns();
  };

  const openTitleDropDown = () => {
    dispatch(setRentDropValue("Title"));
  };

  const saveAndContinue = () => {
    if (
      !PersonalDetails.Email ||
      !PersonalDetails.FirstName ||
      !PersonalDetails.LastName ||
      PersonalDetails.Title === 5
    ) {
      openError("Please fill all required fields");
      return;
    }
    dispatch(setPersonalDetails(PersonalDetails));
    dispatch(setRentStep(2));
  };

  useEffect(() => {
    if (Object.keys(personalDetails).length !== 0) {
      setPersonalDetailsI({ ...personalDetails });
    }
  }, []);

  useEffect(() => {
    if(user?.email){
      setPersonalDetailsI((prev) => ({...prev, Email: user.email!}))
    }
    if(user?.firstName){
      setPersonalDetailsI((prev) => ({...prev, FirstName: user.firstName!}))
    }
    if(user?.lastName){
      setPersonalDetailsI((prev) => ({...prev, LastName: user.lastName!}))
    }
    if(user?.phoneNumber){
      setPersonalDetailsI((prev) => ({...prev, PhoneNumber: user.phoneNumber!}))
    }
  }, [user?.email, user?.firstName, user?.lastName, user?.phoneNumber])

  return (
    <>
      <div className={styles.form}>
        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              Title<span className={styles.red}>*</span>
            </span>
            <div className={styles["inputfield-drop"]}>
              <EmptyDropDown
                select={
                  titleArr[personalDetails.Title || PersonalDetails.Title]
                }
                click={openTitleDropDown}
              />
            </div>
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              First Name<span className={styles.red}>*</span>
            </span>
            <input
              name="FirstName"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={PersonalDetails.FirstName || personalDetails.FirstName}
              defaultValue={personalDetails.FirstName}
            />
          </div>
          <div className={styles.inputcolumn}>
            <span>
              Last Name<span className={styles.red}>*</span>
            </span>
            <input
              name="LastName"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={PersonalDetails.LastName || personalDetails.LastName}
              defaultValue={personalDetails.LastName}
            />
          </div>
          <div className={styles.inputcolumn}>
            <span>Middle Name</span>
            <input
              name="MiddleName"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={PersonalDetails.MiddleName || personalDetails.MiddleName}
              defaultValue={personalDetails.MiddleName}
            />
          </div>
        </div>

        <div className={styles.columngrid}>
          <div className={styles["input2column"]}>
            <span>
              Email Address<span className={styles.red}>*</span>
            </span>
            <input
              name="Email"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={PersonalDetails.Email || personalDetails.Email}
              defaultValue={personalDetails.Email}
            />
          </div>
          <div className={styles["input2column"]}>
            <span>
              Phone Number<span className={styles.red}>*</span>
            </span>
            <input
              name="PhoneNumber"
              onChange={handleTypedOnchange}
              className={styles.inputfield}
              autoComplete="off"
              value={PersonalDetails.PhoneNumber || personalDetails.PhoneNumber}
              defaultValue={personalDetails.PhoneNumber}
            />
          </div>
        </div>

        <div
          className={styles["continue-butt"]}
          role="button"
          onClick={() => saveAndContinue()}
        >
          <span>Save & Continue</span>
        </div>
      </div>

      <ModalLayout modalOpen={rentDropDownValue === "Title"}>
        <PopUp options={titleArr} click={handleTitleSelection} />
      </ModalLayout>
    </>
  );
};

export default PersonalDetails;
