import React from "react";
import {useHistory} from 'react-router'
import styles from "./mortgageView.module.scss";
import { Link } from "react-router-dom";

export const MortgageModal = ({
  willAccess,
  close,
  handleFinalWillAccess,
  id,
  setPropertyCb
}: {
  willAccess: boolean;
  close: () => void;
  handleFinalWillAccess: (p: boolean) => void;
  id: number | undefined;
  setPropertyCb: () => void;
}) => {
  const history = useHistory();
  return (
    <div className={styles.modal}>
      <div className={styles.cancel} onClick={() => close()}>
        <div className="icon-cancel"></div>
      </div>

      {willAccess === true ? (
        <>
          <span style={{ textAlign: "center", fontWeight: "bold" }}>
            Are you willing to use 25% of your pension as down payment ?
          </span>
          <div className={styles.btn} onClick={() => handleFinalWillAccess(true)}>Yes</div>
          <div className={styles.btn1} onClick={() => handleFinalWillAccess(false)}>No</div>
        </>
      ) : (
        <div className={styles.modalI}>
          <span style={{ textAlign: "center", fontWeight: "bold" }}>
          Do you know you can use 25% of your pension for mortgage in Access 
          pensions at a discounted interest rate of 19% ?
          </span>
          <Link
          to={`/openaccesspension/${id}`}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              textDecoration: "underline",
              color: "#91EA91",
            }}
            onClick={() => setPropertyCb()}
          >
            Click here to open an access pension account
          </Link>
        </div>
      )}
    </div>
  );
};
