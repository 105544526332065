import React from 'react';
import BookingsTable from '../../components/BookingsTable';
import { useBookings } from '../../swr/inspection';
import { Inspection } from '../../types/Inspection';
import { InspectionB } from '../../types/Billings';

export interface UserAccountBookingsProps{
    bookings?: Inspection[];
    loading?: boolean;
    outgoingBookings?: InspectionB[]
}

const UserAccountBookings: React.FC<UserAccountBookingsProps> = ({
    bookings, outgoingBookings
})=>{
    
    return(
        <div>
            <BookingsTable inspections={bookings} outgoingBookings={outgoingBookings}/>
        </div>
    )
}

export default UserAccountBookings;