import { Attachment } from "../../../types/Property";

const galleryData: Attachment[] = [
    {
        imageUrl: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
        alt: 'Bed',
        id: 0,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
        alt: 'Books',
        id: 1,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
        alt: 'Sink',
        id: 2,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
        alt: 'Kitchen',
        id: 3,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
        alt: 'Blinds',
        id: 4,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
        alt: 'Chairs',
        id: 5,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
        alt: 'Laptop',
        id: 6,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
        alt: 'Doors',
        id: 7,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
        alt: 'Coffee',
        id: 8,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
        alt: 'Storage',
        id: 9,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
        alt: 'Candle',
        id: 10,
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
        alt: 'Coffee table',
        id: 11,
      },
]

export default galleryData;