import React, { useContext, useState } from "react";
import styles from "./deleteaccount.module.scss";
import ToastContext from "../../contexts/ToastContext";
import { DeleteAccountApi } from "../../swr/user";
import LoadingContext from "../../contexts/LoadingContext";
import { useHistory } from "react-router";

const DeleteAccount = () => {
  const history = useHistory()
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const [text, setText] = useState("");
  const [firstCheck, setFirstCheck] = useState(false);
  const token = localStorage.getItem("token") as string;
  const firstDel = (txt: string) => {
    if (!txt) {
      openError("Please fill text field");
      return;
    }

    if (txt.trim() !== "DELETE") {
      openError("Please type in exact word");
      return;
    }

    setFirstCheck(true);
    openError("Click button again");
  };

  const logout = () => {
    localStorage.removeItem("paymentState");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    localStorage.removeItem("homezone_wallet");
    localStorage.removeItem("paymentState");
    history.push('/signin')
  };

  const permanentDel = async (confirm: boolean, token: string) => {
    setLoading(true);
    const { data, error } = await DeleteAccountApi(confirm, token);
    
    if (data) {
      setLoading(false);
      openSuccess(
        data.message || data.Message || "Account deleted successfully"
      );
      logout()
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        error.response?.data.message ||
          error.response?.data.Message ||
          "An error occurred deleting account"
      );
      return;
    }

    setLoading(false);
    openError("An error occurred deleting account");
  };

  return (
    <div className={styles["del-container"]}>
      <div className={styles["inner-container"]}>
        <p>Delete Account</p>
        <span>
          Deleting your account will remove all your information from our
          database. This cannot be undone.
        </span>
        <span id={styles["del-text"]}>To confirm this type "DELETE"</span>
        <div className={styles["input-button"]}>
          <input
            value={text}
            onChange={(e) => setText(e.target.value)}
            autoComplete="off"
            required
          />
          <div
            role="button"
            onClick={
              firstCheck === true
                ? () => permanentDel(firstCheck, token)
                : () => firstDel(text)
            }
          >
            {firstCheck === true ? "Delete Now" : "Delete Account"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
