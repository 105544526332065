import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Dropdown from "../../components/FormElements/Dropdown";
import TextInput from "../../components/FormElements/TextInput";
import LoadingContext from "../../contexts/LoadingContext";
import ToastContext from "../../contexts/ToastContext";
import { CreateSavings } from "../../swr/saveForRent";
import { CreateSavingsRequest, SavingsPurpose } from "../../types/Savings";
import { getWalletId } from "../../utils/generalUtils";

const CreateSavingsForm = () => {
  const genMsgId = () => {
    let msg_id = Math.floor(Math.random() * 1000000000);
    return msg_id.toString();
  };

  const token = localStorage.getItem("token") as string;
  const email = localStorage.getItem("email") as string;
  const { openSuccess, openError } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);

  const history = useHistory();

  let purposeOfSavings = Object.values(SavingsPurpose).filter(
    (item) => typeof item === "string"
  );

  const setDescriptionGeneralToNull = (option: any | string) => {
    if(option === 'GENERAL'){
        return ''
    }else{
        return option
    }
}

  const walletId = getWalletId(token);
  const [formData, setFormData] = React.useState<CreateSavingsRequest>({
    savings_amount: "",
    savings_remark: "",
    // msg_id: genMsgId(),
    description: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSuccess = () => {
    setLoading(false);
    openSuccess("Savings created successfully");
    history.push("/account#liton_tab_1_3");
  };

  const handleFailure = (msg: string) => {
    setLoading(false);
    openError(msg);
  };

  const formRef = React.useRef<HTMLFormElement | null>(null);

  const handleSubmit = async () => {
    if (formRef.current) {
      const Form = new FormData(formRef.current);
      let Sd = Form.get("description");
    
      if(!Sd){
        openError('Pick a purpose for saving')
        return
      }

      if(!formData.savings_amount){
        openError('Please enter amount')
        return
      }
      
      formData.description = setDescriptionGeneralToNull(Form.get('description') as string);
      setLoading(true);
      
      try {
        const res = await CreateSavings(formData, token);
        
        if (res && res.succeeded === true) {
           handleSuccess() 
        }else{
          handleFailure(res.message)
        }
      } catch (error) {
         // @ts-ignore
         let err: AxiosError<ValidationErrors> = error; // cast the error for access
         if (!err.response) {
           throw err;
         }
         setLoading(false);
         openError(err.response.data.message ?? err.response.data.Message);
      }
    }
  };

  return (
    <>
      <form ref={formRef}>
        <TextInput
          type="text"
          name="savings_amount"
          placeholder="Amount"
          onChange={handleChange}
          value={formData.savings_amount}
        />
        <TextInput
          type="text"
          name="savings_remark"
          placeholder="Savings Remark"
          onChange={handleChange}
          value={formData.savings_remark}
        />
        {/* <div className="col-md-6"> */}
        <label>What's this for ?</label>
        <Dropdown
          placeholder=""
          name="description"
          options={purposeOfSavings}
          selectProps={{ required: true }}
        />
        {/* </div>  */}
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="button"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default CreateSavingsForm;
