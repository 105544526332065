import React from 'react'
import Insurance from './page'
import Layout from '../../components/Layout'
import NavHeader from '../../components/NavHeader'
import ScrollToTop from '../../components/ScrollToTop'
import SessionCheck from '../../components/SessionCheck'

const InsurancePage = () => {
  return (
     <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader pageTitle="Insurance" routeName="Insurance" />
      <Insurance />
    </Layout>
  )
}

export default InsurancePage