import React from 'react';
import { useLocation } from 'react-router-dom';


const ScrollToTop: React.FC<any> = ()=>{

    const location = useLocation()

    // Scroll to top on mount
    React.useEffect(()=>{
        window.scrollTo(0, 0);
    }, [location])

    return null;
}

export default ScrollToTop;