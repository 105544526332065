import React from 'react';
import AmenityCard, { AmenityCardProps } from '../../components/AmenityCard';
import { Amenity } from '../../types/Property';
import useStyles from './aminities-jss';

export interface AmenitiesProp{
    aminities?: AmenityCardProps[];
    demo?: boolean;
}

const Amenities: React.FC<AmenitiesProp> = ({ aminities, demo })=>{

    const styles = useStyles();

    // Amenities
    interface AmenityToDisplay extends AmenitiesProp{
        title: Amenity;
    }
    const amenities: AmenityToDisplay[] = [
        { 
            title: 'Air Conditioning', 
        }
    ]

    return(
       <section className={styles.section}>
           <div className={`container ${styles.container}`}>
                {/** Title and Subtitle */}
                <div className={`row ${styles.row}`}>
                        <div className={`col-lg-12`}>
                            <div className={`text-center ${styles.titleArea}`}>
                                <h6 className={styles.subtitle}>Our Amenities</h6>
                                <h1 className={styles.sectionTitle}>Building Amenities</h1>
                            </div>
                        </div>
                </div>

                {/** Aminities Section */}
                <div className={`row justify-content-start ${styles.row}`}>
                    {/* {
                        demo && [1,2,3,4,5,6,7,8].map((aminity, index)=>(
                            <div className={`col-lg-3 col-md-4 col-sm-6 col-6`} key={`${index}a`}>
                                <AmenityCard index={index+1} {...{aminity}} />
                            </div>
                        ))
                    } */}
                    {
  demo &&
  [1, 2, 3, 4, 5, 6, 7, 8].map((aminity, index) => (
    <div className={`col-lg-3 col-md-4 col-sm-6 col-6`} key={`${index}a`}>
      <AmenityCard index={index + 1} {...{ aminity }} />
    </div>
  ))
}

                    {
                        !demo && aminities?.map((aminity, index)=>(
                            <div className={`col-lg-3 col-md-4 col-sm-6 col-6`} key={`${index}a`}>
                                <AmenityCard index={index+1} {...aminity} />
                            </div>
                        ))
                    }
                </div>
           </div>
       </section>
    )
}

export default Amenities;