import React, { useEffect, useState } from 'react';
import avatar from "../../assets/avatar.jpeg";
import './userAccountDash.scss';
import { getUser, useGetUser } from '../../swr/user';
import { useStateContext } from '../../contexts/StateContext';
import { Link } from 'react-router-dom';


export interface UserAccountDashboardProps {
  email: string | undefined;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  userName: string;

}

const UserAccountDashboard: React.FC<UserAccountDashboardProps> = () => {
  
  const token = localStorage.getItem('token')
  const userEmail = localStorage.getItem('email')
  
  const {email} = useStateContext()
  const {user, isLoading, isError } = useGetUser(userEmail as string, token!);
  // console.log(user)
  
  // const img = reader.readAsDataURL(user!.image as Blob);
  // const [image, setImage] = useState<string | null>('');
  // console.log(user);
  // if(user && user.imageURL){
  //   // const binaryString = window.atob(user.image);
  //   // const blob = new Blob([binaryString], { type: 'image/jpeg' });
  //   // const imageData = URL.createObjectURL(blob);
  //   setImage(user.imageURL)
  // }

  // useEffect(() => {
  //   const storedImage = localStorage.getItem("photo");
   
  //   if (storedImage && !user?.image) {
      
  //     setImage(storedImage);
  //   }
  // }, []);


  const logout = () => {
    localStorage.removeItem("paymentState");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    localStorage.removeItem("homezone_wallet");
    localStorage.removeItem("paymentState");
  };
  

  return (
    <div className="tab-pane fade active show" id="liton_tab_1_1">
      <div className="ltn__myaccount-tab-content-inner">
        <div className='profile'>
          {user && user.imageURL ? <img className="demo_logo_last" src={user.imageURL} alt="Avatar" /> : <img className="demo_logo_last" src={avatar} alt="Avatar" />}
          <h3>{user?.userName}</h3>
        </div>
        <p>Hello <strong>{user?.userName}</strong> (not <strong>{user?.userName}</strong>? <small><a href="/logout">Log out</a></small> )</p>
        <p>From your account dashboard you can view your <span>information</span></p>
        <p>Username: {user?.userName}</p>
        <p>First Name: {user?.firstName}</p>
        <p>Last Name: {user?.lastName}</p>
        <p>Phone Number: {user?.phoneNumber}</p>
        <p>Email: {user?.email}</p>
        <p>Referral Code: {user?.referralCode}</p>
        <p><Link to='/deletehomebuddyaccount'>Delete Account</Link></p>
      </div>
    </div>
  )
}

export default UserAccountDashboard;
