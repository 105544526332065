import Input from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import React from 'react';
import { IndexKind } from 'typescript';
import { Amenity } from '../../../types/amenity';
import { Property, PropertyStatus } from '../../../types/Property';
import { PropertyType } from '../../../types/propertyType';
import { countPropertyType, countStatusType, formatAmount } from '../../../utils/propertyUtils';
import Checkbox from '../../FormElements/Checkbox';

export interface FilterOption{
    //type: 'type' | 'amenities' | 'price' | 'bedroom' | 'bathroom' | 'category';
    amenities?: string[];
    types?: any[];
    priceRange?: number[];
    bedrooms?: number | string;
    bathrooms?: number | string;
    status?: PropertyStatus[];
}

export interface FilterWidgetProps{
    onStateChange?: (state: FilterOption)=> any;
    properties?: Property[]
}

const FilterWidget: React.FC<FilterWidgetProps> = ({ properties, onStateChange }) =>{

    let initState: FilterOption = {
        amenities: [], types: [],
    };
    const [state, setState] = React.useState<FilterOption>(initState);
    const [priceRange, setPriceRange] = React.useState<number[]>([150000, 5000000]);

    // Handlers
    const handleChange = (event: Event, newValue: number | number[]) => {
        setPriceRange(newValue as number[]);
    };
    const handleFirstInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let range = event.target.value.replaceAll(",", "");
        let newRange = range.trim() === '' ? 0 : Number.parseInt(range);
        setPriceRange((prevRange)=>([newRange, prevRange[1]]));
    };
    const handleSecondInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let range = event.target.value.replaceAll(",", "");
        let newRange = range.trim() === '' ? 0 : Number.parseInt(range);
        setPriceRange((prevRange)=>([prevRange[0], newRange]));
    };
    const handleFilterPriceClick = ()=>{
        onStateChange && onStateChange({ ...state, priceRange: priceRange });
    }

    // Price range value
    function valuetext(value: number) {
        return `₦${value}`;
    }


    const amenityCheckListener = (event: CustomEventInit)=>{
        const types = ['general', 'studentpackage', 'commercial', 'land', 'flats'];
        const status: PropertyStatus[] = ['Rent', 'Rent To Own', 'Sale', 'Mortgage'];
        const rooms = ['double', 'single', 'up to 3', 'up to 5'];
        let amenityVal: string = event.detail.value;
        if (types.includes(event.detail.value.toLowerCase())) {
            if (event.detail.checked) {
                let prevTypes = state.types || [];
                setState({
                    ...state,
                    types: [...prevTypes, event.detail.value]
                })
                onStateChange && onStateChange({...state, types: [...prevTypes, event.detail.value]})
            }
            else{
                let prevTypes = state.types || [];
                let newTypes = prevTypes.filter((item)=> item !== event.detail.value);
                setState((prevState)=>({
                    ...prevState,
                    types: newTypes
                }))
                onStateChange && onStateChange({...state, types: newTypes})
            }
        }
        else if(status.includes(event.detail.value)){
            if (event.detail.checked) {
                let prevStatus = state.status || []
                setState({ ...state, status: [...prevStatus, event.detail.value] });
                onStateChange && onStateChange({ ...state, status: [...prevStatus, event.detail.value] });
            }
            else{
                let preStatus = state.status || [];
                let newStatus = preStatus.filter((item)=> item !== event.detail.value);
                setState((prevState)=>({
                    ...prevState,
                    status: newStatus
                }))
                onStateChange && onStateChange({...state, status: newStatus})
            }
        }
        else if(rooms.includes(event.detail.value.toLowerCase())){
            
        }
        else{
            if (event.detail.checked) {
                let prevAmenities = state.amenities || [];
                setState({
                    ...state,
                    amenities: [...prevAmenities, amenityVal]
                })
                onStateChange && onStateChange({...state, amenities: [...prevAmenities, amenityVal] })
            }
            else{
                let prevAmenities = state.amenities || [];
                let newAmenities = prevAmenities.filter((item)=> item !== event.detail.value);
                setState((prevState)=>({
                    ...prevState,
                    amenities: newAmenities
                }))
                onStateChange && onStateChange({...state, amenities: newAmenities})
                
            }
        }
    }


    // Event listener for amenity filter
    const subscribe = ()=>{
        document.addEventListener('amenitycheck', (event: CustomEventInit)=>{
            const types = ['general', 'student package', 'commercial', 'land', 'flats'];
            const status: PropertyStatus[] = ['Rent', 'Rent To Own', 'Sale', 'Mortgage'];
            const rooms = ['double', 'single', 'up to 3', 'up to 5'];
            let amenityVal: string = event.detail.value;
            if (types.includes(event.detail.value.toLowerCase())) {
                if (event.detail.checked) {
                    let prevTypes = state.types || [];
                    setState({
                        ...state,
                        types: [...prevTypes, event.detail.value]
                    })
                    onStateChange && onStateChange({...state, types: [...prevTypes, event.detail.value]})
                }
                else{
                    let prevTypes = state.types || [];
                    let newTypes = prevTypes.filter((item)=> item !== event.detail.value);
                    setState((prevState)=>({
                        ...prevState,
                        types: newTypes
                    }))
                    onStateChange && onStateChange({...state, types: newTypes})
                }
            }
            else if(status.includes(event.detail.value)){
                if (event.detail.checked) {
                    let prevStatus = state.status || []
                    setState({ ...state, status: [...prevStatus, event.detail.value] });
                    onStateChange && onStateChange({ ...state, status: [...prevStatus, event.detail.value.replace(/\s/g, '')] });
                }
                else{
                    let preStatus = state.status || [];
                    let newStatus = preStatus.filter((item)=> item !== event.detail.value);
                    setState((prevState)=>({
                        ...prevState,
                        status: newStatus
                    }))
                    onStateChange && onStateChange({...state, status: newStatus})
                }
            }
            else if(rooms.includes(event.detail.value.toLowerCase())){
                
            }
            else{
                if (event.detail.checked) {
                    let prevAmenities = state.amenities || [];
                    setState({
                        ...state,
                        amenities: [...prevAmenities, amenityVal]
                    })
                    onStateChange && onStateChange({...state, amenities: [...prevAmenities, amenityVal] })
                }
                else{
                    let prevAmenities = state.amenities || [];
                    let newAmenities = prevAmenities.filter((item)=> item !== event.detail.value);
                    setState((prevState)=>({
                        ...prevState,
                        amenities: newAmenities
                    }))
                    onStateChange && onStateChange({...state, amenities: newAmenities})
                    
                }
            }
        })
    }

    const unsubscribe = ()=>{
        document.removeEventListener('amenitycheck', amenityCheckListener)
    }

    // Effects
    React.useEffect(()=>{
        subscribe();
        return unsubscribe();
    })



    return(
        <div className="widget ltn__menu-widget">

            {/** Property Type */}
            <h4 className="ltn__widget-title">Property Type</h4>
            <ul>
                {
                    Object.keys(PropertyType).map((item, index)=>(
                        <li key={item}>
                            <Checkbox label={item.toString()} name={item.toString()}/>
                            <span className="categorey-no">
                                {countPropertyType(item, properties)}
                            </span>
                        </li>
                    ))
                }
            </ul>
            <hr/>

            {/** Amenities */}
            <h4 className="ltn__widget-title">Amenities</h4>
            <ul>
                {
                    Object.keys(Amenity).map((key, index)=>(
                        <li key={key}>
                            <Checkbox label={Amenity[key as keyof typeof Amenity]} name={key} val={key}/>
                            {/* <span className="categorey-no">3,924</span> */}
                        </li>
                    ))
                }
                
            </ul>
            <hr/>
            
            {/** Price Filter */}
            <div className="widget--- ltn__price-filter-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border---">Filter by price</h4>
                <div className="price_filter">
                    <div className="price_slider_amount">
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
                            <button className="btn btn-transparent" onClick={handleFilterPriceClick}> 
                                Your range: 
                            </button>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div className="price_slider_amount" style={{ display: 'flex' }}>
                                 <span style={{ marginRight: '5px', lineHeight: '25px' }}> ₦ </span>
                                <input type="text" className="amount" name="price" 
                                    value={formatAmount(priceRange[0].toString())}
                                    onChange={handleFirstInputChange} autoComplete="off"
                                /> 
                            </div>
                            <div className="price_slider_amount" style={{ display: 'flex' }}>
                                <span style={{ marginRight: '5px', lineHeight: '25px' }}> ₦ </span> 
                                <input type="text" className="amount" name="price" 
                                    value={formatAmount(priceRange[1].toString())}
                                    onChange={handleSecondInputChange} autoComplete="off"
                                />
                            </div>
                        </div>
                        
                    </div>
                    <Slider
                        getAriaLabel={() => 'Price range'}
                        value={priceRange}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        min={0} max={10000000}
                        step={1000}
                        sx={{ color: 'var(--ltn__secondary-color)' }}
                    />
                </div>
            </div>
            <hr/>

            {/* <h4 className="ltn__widget-title">Bed/bath</h4>
            <ul>
                {
                    ["Single", "Double", "Up To 3", "Up To 5"].map((item)=>(
                        <li key={item}>
                            <Checkbox label={item} name={item}/>
                            <span className="categorey-no">3,924</span>
                        </li>
                    ))
                }
            </ul>

            <hr/> */}
            <h4 className="ltn__widget-title">Category</h4>
            <ul>
                {
                    ["Rent", "Sale", "Rent To Own", "Mortgage"].map((item)=>(
                        <li key={item}>
                            <Checkbox label={item} name={item}/>
                            <span className="categorey-no">{countStatusType(item, properties)}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default FilterWidget;