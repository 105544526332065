import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import WalletDisplay from "../../components/WalletDisplay";
import TransactionsTable from "../../components/TransactionsTable";
import { dummyTransactions } from "./sampleTransactions";
import {
  GetWalletDetailsResponseData,
  TransactionHistoryRequest,
  TransactionHistoryResponse,
} from "../../types/Wallet";
import { getWalletId } from "../../utils/generalUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loadingStart,
  setError,
  setUser,
} from "../../state/reducers/authSlice";
import Axios, { AxiosError, AxiosResponse } from "axios";
import {
  getTransactionHistoryByWalletId,
  getWalletDetails,
} from "../../swr/wallet";
import { GeneralOperationResult } from "../../types/GeneralResponse";
import {
  StateContextProps,
  useStateContext,
} from "../../contexts/StateContext";
import ToastContext from "../../contexts/ToastContext";
const baseUrl =
  process.env.REACT_APP_BASE_URL || "https://api.homebuddy.ng";

export interface UserAccountWalletProps {
  walletDetails?: GetWalletDetailsResponseData;
  loading?: boolean;
}

const UserAccountWallet: React.FC<UserAccountWalletProps> = ({
  walletDetails,
  loading,
}) => {
  const [transactions, setTransactions] = React.useState<any>([]); // Rename state variable to "transactions"

  const { openError } = React.useContext(ToastContext);

  const token = localStorage.getItem("token") || "";
  const wallet_id = getWalletId(token || "");
  const [walletIdState, setWalletIdState] = useState("");

  const fetchTransactions = async (
    pageNumber: any,
    pageSize: any,
    token: string
  ) => {
    if (transactions && transactions.length < 1) {
      await getTransactionHistoryByWalletId(
        pageNumber,
        pageSize,
        wallet_id,
        token
      ).then((response) => {
        setTransactions(response.data);
      });
    } // Update state variable with transaction data
  };
  const page = 1;
  const rows = 1000;
  // const page = Number(localStorage.getItem('page'))
  // const rows = Number(localStorage.getItem('rows'))

  const [detailsLoading, setDetailsLoading] = React.useState<boolean>(true);
  const [currentBalance, setcurrentBalance] = useState(0);
  const [netBalance, setnetBalance] = useState(0);

  useEffect(() => {
    if (wallet_id || walletIdState) {
      fetchTransactions(page, rows, token);
    }
  }, []);

  useEffect(() => {
    getWalletDetails(token).then((result) => {
      if (result.data) {
        setDetailsLoading(false);
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.available_balance
        ) {
          setWalletIdState(result.data.data.virtual_account_no);
          setcurrentBalance(
            result &&
              result.data &&
              result.data.data &&
              result.data.data.available_balance
          );
          setnetBalance(
            result &&
              result.data &&
              result.data.data &&
              result.data.data.net_balance
          );
        }
      }

      if (result.error) {
        setDetailsLoading(false);
        if (
          result.error.response &&
          result.error.response.data &&
          result.error.response?.data.Message === "01"
        ) {
          openError("Please create a wallet to perform transactions");
        }
      }
    });
  }, []);

  return (
    <Box>
      {/** Wallet Balance Display */}
      <WalletDisplay
        balance={currentBalance && currentBalance}
        netBalance={netBalance && netBalance}
        walletID={getWalletId(token)}
        loading={detailsLoading}
      />

      {/** Transaction Table */}
      <Box mt={4}>
        <TransactionsTable
          //transactions={dummyTransactions }
          transactions={transactions}
          walletID={getWalletId(token)}
        />{" "}
        {/* Pass transactions state variable */}
      </Box>
    </Box>
  );
};

export default UserAccountWallet;

// import React, { useEffect, useState } from 'react';
// import Box from '@mui/material/Box';
// import WalletDisplay from '../../components/WalletDisplay';
// import TransactionsTable from '../../components/TransactionsTable';
// import { dummyTransactions } from './sampleTransactions';
// import { GetWalletDetailsResponseData, TransactionHistoryResponse } from '../../types/Wallet';
// import { getWalletId } from '../../utils/generalUtils';
// // import { transactionsHistory } from '../../swr/wallet';
// import { getTransactionHistory } from '../../swr/wallet';

// export interface UserAccountWalletProps{
//     walletDetails?: GetWalletDetailsResponseData;
//     loading?: boolean
// }

// const UserAccountWallet: React.FC<UserAccountWalletProps> = ({
//     walletDetails, loading
// })=>{
//     const [transact, setTransact] = useState([]);
//     const token = localStorage.getItem('token') || '';

//     const fetchTransactions = async () => {
//         const response = await getTransactionHistory();
//         const {data} = response;
//         console.log(response);
//         const setTransact= response.data
//     }
//     // const {data} : TransactionHistoryResponse = fetchTransactions()

//     // const {TransactionHistory} = getTransactionHistory()

//     useEffect(() => {
//         fetchTransactions()

//       }, [])

//     return(
//        <Box>

//            {/** Wallet Balance Display */}
//            <WalletDisplay
//                 balance={walletDetails?.net_balance}
//                 walletID={getWalletId(token)}
//                 loading={loading}
//            />

//            {/** Transaction Table */}
//            <Box mt={4}>
//             <TransactionsTable
//             transactions={transact}
//                 //transactions={dummyTransactions}

//             />
//            </Box>
//        </Box>
//     )
// }

// export default UserAccountWallet;
