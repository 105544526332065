import React from "react";
import styles from "./home-owners.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { formatAmount } from "../../../../utils/generalUtils";
import { useHistory } from "react-router";

const leftTexts = [
  "Policy Number",
  "Property Type",
  "Name of Policy Holder",
  "Date Created",
  "Date of Expiry",
  "Payable Premium",
  "Total Sum Insured",
  //   "License",
  //   "Status",
];

const ActivePolicyView = () => {
  const { ActivePolicyKeysSlice } = useSelector(
    (state: RootState) => state.insurance
  );
  const history = useHistory();
  const rightTexts = [
    ActivePolicyKeysSlice?.PolicyNumber,
    ActivePolicyKeysSlice?.PropertyType,
    ActivePolicyKeysSlice?.NameofPolicyHolder,
    ActivePolicyKeysSlice?.DateCreated
      ? new Date(ActivePolicyKeysSlice.DateCreated).toLocaleString("NG")
      : "",
    ActivePolicyKeysSlice?.DateofExpiry
      ? new Date(ActivePolicyKeysSlice.DateofExpiry).toLocaleString("NG")
      : "",
    ActivePolicyKeysSlice?.PayablePremium
      ? `NGN ${formatAmount(ActivePolicyKeysSlice.PayablePremium)}`
      : "",
    ActivePolicyKeysSlice?.TotalSumInsured
      ? `NGN ${formatAmount(ActivePolicyKeysSlice.TotalSumInsured)}`
      : "",
  ];

  const goBack = () => {
    history.push("/insurance");
  };

  return (
    <>
      <div className={styles["active-policy-view"]}>
        <div className={styles["active-policy-view-a"]}>
          {leftTexts.map((text) => (
            <span key={text}>{text}</span>
          ))}
        </div>
        <div className={styles["active-policy-view-line"]}></div>
        <div className={styles["active-policy-view-b"]}>
          {rightTexts.map((text) => (
            <span key={text}>{text}</span>
          ))}
        </div>
      </div>
      <div className={styles["button-cont-active-pol"]}>
        <div className={styles["button-cont-active-pol-d"]} role="button" onClick={() => goBack()}>
          <span>Back</span>
        </div>
      </div>
    </>
  );
};

export default ActivePolicyView;
