import Axios, { AxiosError, AxiosResponse } from 'axios'
import {
  AddFundingSourceRequest,
  FundingSourceResponse,
} from '../types/FundingSource'
import useSwr, { useSWRConfig } from 'swr'

const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api.homebuddy.ng'

interface AddFundingSourceResult {
  data?: FundingSourceResponse
  error?: any
}

export const useFundingSource = (userId: string) => {
  const fetcher = (url: string) =>
    Axios.get(baseUrl + url).then(
      (response: AxiosResponse<FundingSourceResponse>) => response.data
    )
  const { data, error, mutate } = useSwr(
    `/api/FundingSource/get-fundingsource?userId=${userId}`,
    fetcher
  )
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export const addFundingSource = (request: AddFundingSourceRequest) => {
  const willAddFundingSource = new Promise<AddFundingSourceResult>(
    (resolve) => {
      Axios.post(baseUrl + '/api/FundingSource/add-fundingsource', request)
        .then((response: AxiosResponse<FundingSourceResponse>) => {
          resolve({ data: response.data })
        })
        .catch((err: AxiosError | any) => {
          resolve({ error: err })
        })
    }
  )

  return willAddFundingSource
}

export const deleteFundingSource = (id: number) => {
  const willDeleteFundingSource = new Promise<AddFundingSourceResult>(
    (resolve) => {
      Axios.delete(baseUrl + `/api/FundingSource/delete-fundingsource?id=${id}`)
        .then((response: AxiosResponse<FundingSourceResponse>) => {
          resolve({ data: response.data })
        })
        .catch((err: AxiosError | any) => {
          resolve({ error: err })
        })
    }
  )

  return willDeleteFundingSource
}
