import * as React from "react";
import styles from "./plans.module.scss";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";

// Custom mui
const CustomRadio = styled(Radio)<RadioProps>(({ theme }) => ({
  "&.Mui-checked": {
    color: "var(--ltn__secondary-color)",
  },
}));

export type Plan = "one-off" | "installment";

export interface PaymentPlansProps {
  onPlanSelect?: (plan?: "one-off" | "installment") => void;
  selectedPlan?: Plan;
  isInstallmental?: boolean;
}

const PaymentPlans: React.FC<PaymentPlansProps> = ({
  isInstallmental,
  onPlanSelect,
  selectedPlan,
}) => {
  // State
  const [plan, setPlan] = React.useState<Plan | undefined>(selectedPlan);

  // Handlers
  const handleOneOffClick = () => {
    if (plan === "one-off") {
      setPlan(undefined);
      onPlanSelect && onPlanSelect(undefined);
    } else {
      setPlan("one-off");
      onPlanSelect && onPlanSelect("one-off");
    }
  };

  const handleInstallmentfClick = () => {
    if (plan === "installment") {
      setPlan(undefined);
      onPlanSelect && onPlanSelect(undefined);
    } else {
      setPlan("installment");
      onPlanSelect && onPlanSelect("installment");
    }
  };

  return (
    <div className={styles.container}>
      {/** One off Payment */}
      <div className={styles.cardArea} onClick={handleOneOffClick}>
        <p>ONE OFF PAYMENT</p>
        <CustomRadio checked={plan === "one-off"} onClick={handleOneOffClick} />
      </div>

      {/** Installment / Loans */}
      {isInstallmental && isInstallmental === true && (
        <div className={styles.cardArea} onClick={handleInstallmentfClick}>
          <p>INSTALLMENT</p>
          <CustomRadio
            checked={plan === "installment"}
            onClick={handleInstallmentfClick}
          />
        </div>
      )}
    </div>
  );
};

export default PaymentPlans;
