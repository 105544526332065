import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Attachment } from '../../../types/Property';
import images from './defaultImages';
import ImageCarouselDialog from '../../Dialogs/ImageCarouselDialog';

export interface GalleryElementProps{
    attachments?: Attachment[];
}

export default function Gallery({ attachments = images }: GalleryElementProps) {

  // State
  const [open, setOpen] = React.useState<boolean>(false);
  const [imageIndex, setImageIndex] = React.useState<number>(0);

  // Handlers
  const handleClick = (id?: number) =>{
    // Get index of selceted image
    let index = attachments.map((a)=> a.id).indexOf(id);
    setImageIndex(index);
    setOpen(true);
  }
  const handleClose = ()=>{
    setOpen(false)
  }

  return (
    <>
    <ImageCarouselDialog 
      attachments={attachments} 
      open={open} 
      onClose={handleClose}
      index={imageIndex}
    />
    <Box sx={{ width: '100%', maxHeight: 450, overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {attachments.map((item) => (
          <ImageListItem key={item.id} onClick={()=> handleClick(item.id)}>
            <img
              src={item.imageUrl}
              srcSet={item.imageUrl}
              alt={item.alt}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
    </>
  );
}
