import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import LoadingScreen from "../components/LoadingScreen";
import NavHeader from "../components/NavHeader";
import AddListingsView from "../sections/AddListingsView";
import { RootState } from "../state/store";
import { errorMessage } from "../utils/propertyUtils";
import { useSWRConfig } from 'swr';
import SessionCheck from "../components/SessionCheck";
import ScrollToTop from "../components/ScrollToTop";

const AddListingPage: React.FC<any> = ()=>{

    const dispatch = useDispatch();
    const { loading, error, response } = useSelector((state: RootState)=> state.property);

    // State
    // const [open, setOpen] = React.useState<boolean>(false);
    // const [successOpen, setSuccessOpen] = React.useState<boolean>(false);

    // // Hooks
    // const { mutate } = useSWRConfig();

    // // Effect
    // React.useEffect(()=>{
    //     // If error, set open to true
    //     if (error) {
    //         setOpen(true);
    //         console.log(JSON.stringify(error));
    //     }

    //     // If response, set open to true
    //     if (response) {
    //         mutate(`/api/properties?PageNumber=1&PageSize=1000000`)
    //         setSuccessOpen(true);
    //     }
    // }, [error, response]);

    // // Handlers
    // const handleClose = ()=>{
    //     setOpen(false);
    // }
    // const handleSuccessClose = ()=>{
    //     setSuccessOpen(false);
    // }

    return(
        <Layout>
            <ScrollToTop/>
            <SessionCheck shouldRedirect/>
            <NavHeader 
                pageTitle="Add Listing"
                routeName="Add Listing" 
            />
            <AddListingsView/>
        </Layout>
    )
}

export default AddListingPage;