export const isEmpty = (value: string)=>{
    if (/^\s*$/.test(value)){
        return true;
    }
    if (value.length === 0){
        return true;
    }
    return false;
}

export const isPasswordValid = (value: string)=>{
    /**
     * ^ represents the starting of the string.
     * (?=.*[a-z]) represent at least one lowercase character.
     * (?=.*[A-Z]) represents at least one uppercase character.
     * (?=.*\\d) represents at least one numeric value.
     * (?=.*[-+_!@#$%^&*., ?]) represents at least one special character.
     * . represents any character except line break.
     * {.6,} represents minimum six in length.
     */
    // let regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\\w\\s]).{6,}$');
    
    let regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\\w\\s]).{6,}$");
    return regex.test(value);

}

export const isValidEmail = (value: string)=>{
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export function expDateFormatter(value: string){
    let result = value;
    if (value.length === 5){
        result = value.replace(/\//g, "");
        result = result.replace(/\s/g, "");
        return result;
    }
    if (value.length === 4){
        result = value.replace(/\//g, "");
        result = result.replace(/\s/g, "");
        return result;
    }
    if (value.length == 2){
        result = value + " " + "/ ";
        return result;
    }
    return result;
}

export function formatCardNumber(value: string): string{
    let input = value.replace(/\s/g, "").match(/.{1,4}/g)?.join(' ') || value;
    return input;
}