import Skeleton from '@mui/material/Skeleton'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { CreateSavingsRequest, SavingsTowardsRent, TargetSaving } from '../../types/Savings'
import { formatAmount, formatDate } from '../../utils/propertyUtils'
import styles from './savingPlans.module.scss'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import { getTargetSavings, getTotalSavings } from '../../swr/saveForRent'
import { formatNumberToCurrency, getWalletId } from '../../utils/generalUtils'
import ToastContext from '../../contexts/ToastContext'
import LoadingContext from '../../contexts/LoadingContext'

export interface UserAccountTargetsProps {
  savingsTowardsRent?: SavingsTowardsRent[]
  loading?: boolean
  onSelect?: (savingPlan?: SavingsTowardsRent) => void
}

const UserAccountTargets: React.FC<UserAccountTargetsProps> = ({
  savingsTowardsRent,
  loading, 
  onSelect,
}) => {
  const history = useHistory()
  const {openError} = useContext(ToastContext) 
  const {setLoading} = useContext(LoadingContext)

  const [aggregateSavings, setAggregateSavings] = useState(0)
  const [aggregateTarget, setAggregateTarget] = useState(0)
  
  const [targetSavings, setTargetSavings] = useState<TargetSaving[] | undefined>()

  const token = localStorage.getItem('token') as string;
  const walletId = getWalletId(token) as string;
  const email = localStorage.getItem('email') as string;
  const hold_id = 'RENT';
  
 
  const GetTargetSavings = async () => {
    const response = await getTargetSavings(token)
    if(!response){
      openError('Error fetching target savings')
      return
    }
    if(response.status !== 200 || !response.data){
      openError(response.Message ?? (response.data && response.data.message))
      return
    }
    if(response.data){
      const res = response.data;
      setTargetSavings(res.data)
    }
  }

  const getAggregrateTargetAndSaved = () => {
    if(targetSavings){
      let target = 0;
      let saved = 0;
      for(let item of targetSavings!){
        target += item.totalAmount;
        saved += item.amountSaved;
      }
      setAggregateTarget(target)
      setAggregateSavings(saved)
    }
  }

  useEffect(() => {
    GetTargetSavings()
  }, [])

  useEffect(()=>{
    if(targetSavings && targetSavings.length > 0)
    getAggregrateTargetAndSaved()
  }, [targetSavings])

  // Handlers
  const handleSelect = (id: string) => {
    // let savingPlan = targetSavings?.find((s) => s.savings_Id === id)
    // onSelect && onSelect(savingPlan)
    history.push(`/savings/${id}`)
  }

  let pageCount =
    targetSavings && Math.ceil(targetSavings.length / 10)
  const firstTenSavings = targetSavings
    ? (targetSavings.slice(0, 10) as TargetSaving[])
    : []

  const [page, setPage] = useState(1)

  const tenPerPage = (n: number) => {
    return { first: n * 10 - 10, last: n * 10 - 1 };
  }

  const [paginatedSavings, setpaginatedSavings] = useState<
    TargetSaving[] | undefined
  >([],)


  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
    const { first, last } = tenPerPage(newPage)
    let l: number =
      targetSavings && targetSavings.length
        ? targetSavings.length
        : 1

    if (last > l) {
      let savings = targetSavings && targetSavings.slice(first)
      setpaginatedSavings(savings)
      return
    }
    let savings = targetSavings && targetSavings.slice(first, last)
    setpaginatedSavings(savings)
  }

  // You need the 'active show' classes
  return (
    <div className="tab-pane fade active show" id="liton_tab_1_2">
      <div className="ltn__myaccount-tab-content-inner">
        <div className="table-responsive">
          <div className={styles.display} style={{
            marginBottom: '1em',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <div className={styles.balanceDisplay}>
                Total Target: <h4>₦{aggregateTarget ? formatNumberToCurrency(aggregateTarget) : 0}</h4>
            </div>
            <div className={styles.balanceDisplay}>
                Total Saved: <h4>₦{aggregateSavings ? formatNumberToCurrency(aggregateSavings) : 0}</h4>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                {/* <th>ID</th> */}
                <th>Start Date</th>
                <th>Title</th>
                <th>Target</th>
                <th>Paid</th>
                <th>Next Debit</th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {loading &&
                [0, 1, 2, 3, 4, 5].map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton variant="rectangular" width="100%"></Skeleton>{' '}
                    </td>
                    <td>
                      <Skeleton variant="rectangular" width="100%"></Skeleton>{' '}
                    </td>
                    <td>
                      <Skeleton variant="rectangular" width="100%"></Skeleton>{' '}
                    </td>
                    <td>
                      <Skeleton variant="rectangular" width="100%"></Skeleton>{' '}
                    </td>
                    <td>
                      <Skeleton variant="rectangular" width="100%"></Skeleton>{' '}
                    </td>
                  </tr>
                ))}
              {targetSavings && targetSavings.length > 0 && paginatedSavings ? (
                page === 1 ? (
                  firstTenSavings.map((s: any, index: any) => (
                    <tr key={index} onClick={() => handleSelect(s.idForUpdate)} title='edit saving'>
                      <td>{formatDate(new Date(s.startDate))}</td>
                      <td>{s.title}</td>
                      <td>₦ {formatAmount(s.totalAmount.toString())}</td>
                      <td>₦ {formatAmount(s.amountSaved.toString())}</td>
                      <td>₦ {formatAmount(s.totalAmount === s.amountSaved ? 0 : s.amountPerCycle.toString())}</td>
                    </tr>
                  ))
                ) : (
                  paginatedSavings.map((s: any, index: any) => (
                    <tr key={index} onClick={() => handleSelect(s.idForUpdate)} title='edit saving'>
                    <td>{formatDate(new Date(s.startDate))}</td>
                    <td>{s.title}</td>
                    <td>₦ {formatAmount(s.totalAmount.toString())}</td>
                    <td>₦ {formatAmount(s.amountSaved.toString())}</td>
                    <td>₦ {formatAmount(s.totalAmount === s.amountSaved ? 0 : s.amountPerCycle.toString())}</td>
                  </tr>
                  ))
                )
              ) : (
                <b></b>
              )}
            </tbody>
          </table>

          {targetSavings && targetSavings.length > 0 && (
            <Stack
              spacing={2}
              sx={{ marginBottom: '30px', alignItems: 'center' }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                size={'large'}
                variant="outlined"
                color="standard"
              />
            </Stack>
          )}

          <Link to={'/save-for-rent'} style={{ color: 'green' }}>
            {' '}
            <button
              className={`btn theme-btn-1 btn-effect-1 text-uppercase ${styles.btn}`}
            >
              create savings
            </button>{' '}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default UserAccountTargets
