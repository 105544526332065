import React from "react";
import styles from "./insurance-comp.module.scss";
import Close from "@mui/icons-material/Close";

const DisplayPop = ({closeModal}: {closeModal: () => void}) => {
  return (
    <div className={styles["display-pop"]}>
      <div className={styles["display-pop-in"]}>
        <div className={styles["display-cancel"]} onClick={() => closeModal()}>
            <Close />
        </div>

        <span className={styles["display-pop-txt"]}><span className={styles["display-pop-txt-green"]}>Single</span>
         payment frequency is the one off payment for the whole policy duration.
        </span>

        <span className={styles["display-pop-txt"]}><span className={styles["display-pop-txt-green"]}>Annual</span>
        payment frequency is the payment for that year only & subsequently.
        </span>
      </div>
    </div>
  );
};

export default DisplayPop;
