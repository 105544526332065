import React from 'react';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';
import styles from './reviews.module.scss';

export const StyledRating = styled(Rating)({
    '& .MuiRating-iconHover': {
      color: '#ff5a3c',
    },
  });

export interface ReviewsProps{
    rating?: number;
    count?: number;
}

const Reviews: React.FC<ReviewsProps> = ({ rating, count })=>{
    return(
        <div className={`product-ratting ${styles.rating}`}>
            <ul>
                <li>
                    <StyledRating name="half-rating" value={rating} precision={0.5} size="small" readOnly />
                </li>
                <li className="review-total"> <a href="#">{`( ${count} Reviews )`}</a></li>
            </ul>
        </div>
    )
}

export default Reviews;