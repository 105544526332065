import React from "react";
import Insurance from "..";
import Layout from "../../../components/Layout";
import NavHeader from "../../../components/NavHeader";
import ScrollToTop from "../../../components/ScrollToTop";
import SessionCheck from "../../../components/SessionCheck";
import RentInsurance from "./page";
import InsuranceApplication from "./page";

const InsuranceApplicationPage = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader
        pageTitle="Request & Buy"
        routeName="Request & Buy"
      />
      <InsuranceApplication />
    </Layout>
  );
};

export default InsuranceApplicationPage;
