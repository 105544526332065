import React from "react";
import Layout from "../../components/Layout";
import ScrollToTop from "../../components/ScrollToTop";
import SessionCheck from "../../components/SessionCheck";
import NavHeader from "../../components/NavHeader";
import Mortgage from "../../views/MortgagePropertiesView";
import MarketPlaceView from "../../views/MarketPlaceView";

const index = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader pageTitle="Market Place" routeName="marketplace" />
      <MarketPlaceView />
    </Layout>
  );
};

export default index;